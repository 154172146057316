import './TileVisualization.scss';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { LegacyByzzerButton, ByzzerLink } from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@/components/Spinner/Spinner';
import { fetchColorsForGrade } from '@/views/scorecard/fetchColorsForGrade';
import { truncateString, individualDescription, formGraphData } from '@/utils/homePageUtil';
import { zingChartConfig } from '@/config';
import classNames from 'classnames';
import ByzzerOverallScoreDial from '@/components/ByzzerCharts/ByzzerOverallScoreDial';
import trendUpArrow from '@images/icons/homePage/trendUpArrow.svg';
import trendFlat from '@images/icons/homePage/trendFlat.svg';
import trendDownArrow from '@images/icons/homePage/trendDownArrow.svg';
import trendDownYellowArrow from '@images/icons/homePage/trendDownYellowArrow.svg';
import trendUpYellowArrow from '@images/icons/homePage/trendUpYellowArrow.svg';
import trendUpDownYellowArrow from '@images/icons/homePage/trendUpDownYellowArrow.svg';
import PdfThumbnail from '@images/pdf-image-16by9.png';
// zingchart
import zingchart from 'zingchart/es6';
import ZingChart from 'zingchart-react';
// EXPLICITLY IMPORT MODULE
import 'zingchart/modules-es6/zingchart-depth.min.js';
import 'zingchart/modules-es6/zingchart-waterfall.min.js';
import { HomepageContext, useHomePageContext } from '@/contexts/HomepageContext';
import { MyHomepageContext, TILE_TYPES } from '@/contexts/MyHomePageContext';
import { TileFooter } from './TileFooter';
import { ByzzerPDFViewer } from '../ByzzerPDFViewer';
import { useBetterNavigate } from '@/utils';
import { useUser } from '@/contexts/UserContext';
import { alert } from '@/components/form/ByzzerModal';
import { TrackClick } from '@/analytics';
import MyHomePagePDFViewer from '@/pages/MyHomePage/MyHomePagePDFViewer';
import {useTenantApi} from '@/hooks/useTenantApi';
zingchart.LICENSE = [zingChartConfig.appLicense];
import { isEmpty } from 'lodash';
import { useIntercom } from 'react-use-intercom';
import useIsMobile from '@/hooks/useIsMobile';

const baseClassName = 'viz-tile';

interface TileVisualizationProps {
    tile: any;
    className?: string;
    refreshTile?: number;
    handleRefresh?: () => void;
}

function WistiaPlayer({ videoId, wrapper }) {
    const { getCompanyReportRuns, getMySubscriptionUsage } = useTenantApi();
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
        script1.async = true;
        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
        const div = document.createElement('div');
        div.innerHTML = `<div class="wistia_responsive_padding" 
                              style="padding:56.25% 0 0 0;position:relative;">
                              <div class="wistia_responsive_wrapper" 
                                   style="height:100%;left:0;position:absolute;top:0;width:100%;">
                                        <div class="wistia_embed wistia_async_${videoId} 
                                            seo=false 
                                            videoFoam=true" 
                                            style="height:100%;position:relative;width:100%"
                                        >
                                            <div class="wistia_swatch" 
                                                style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                                            >
                                                <img src="https://fast.wistia.com/embed/medias/${videoId}/swatch" 
                                                    style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" 
                                                    alt="" 
                                                    aria-hidden="true" 
                                                    onload="this.parentNode.style.opacity=1;" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                            </div>`;
        const container: any = document.getElementById(wrapper);
        container.appendChild(script1);
        container.appendChild(script2);
        container.appendChild(div);
        return () => {
            container.innerHTML = '';
        };
    }, []);

    return <div id={`${wrapper}`}></div>;
}

export const TileVisualization = React.memo(({ tile, className, refreshTile }: TileVisualizationProps) => {
    const navigate = useBetterNavigate();
    const { tilesLoadingState } = useContext(MyHomepageContext);
    const { defaultRunConfig, subscribedSkus } = useUser();
    const { getCompanyReportRuns, getMySubscriptionUsage } = useTenantApi();
    const { startTour: startTour } = useIntercom();
    const isMobile = useIsMobile();

    const goToScoreCard = useCallback(() => {
        navigate('/dashboard/my_scorecard');
    }, [navigate]);
    const NO_DATA_MESSAGE = `You may have selected a market or category that doesn't carry your brand. Go to your \u00A0`;
    // Todo: Check the View Tile content after changing the backend changes
    const hasDescription = tile?.data?.description?.length ? true : false;
    const viewTileContent = hasDescription ? tile?.data?.description?.[0]?.isShow : false;

    const dataContent = tile?.data?.content;
    const isErrorData = tile?.data?.isError || false;
    const isLoading = tile?.isLoading || false;
    const isDataExpired = tile?.dataExpired || false;
    const tileType = tile?.type || TILE_TYPES.DYNAMIC;

    const grayedChart = (tile) => (
        <div className={`${baseClassName}__default_content`}>
            <div className={`${baseClassName}__default_content__message`}></div>
        </div>
    );

    const spinnerChart = (tile) => (
        <div key={tile} className={`${baseClassName}__tile_center`}>
            <span className={`${baseClassName}__tile__spinner`}>
                &nbsp;
                <Spinner />
            </span>
        </div>
    );

    const tileDescription = useCallback(() => {
        if (tile?.id in tilesLoadingState && tilesLoadingState[tile?.id]) {
            return null;
        }
        return (
            <div className={`${baseClassName}__description`}>
                {descriptions(
                    Array.isArray(tile?.data?.description) ? tile?.data?.description : [tile?.data?.description]
                )}
            </div>
        );
    }, [tile, tilesLoadingState]);

    const descriptions = useCallback(
        (descriptions) => {
            const isDescriptionsArray = Array.isArray(descriptions);
            if (isDescriptionsArray) {
                descriptions?.map((description, index) => {
                    if (description?.isShow) {
                        return (
                            <div key={index + 'descriptions'} className={`${baseClassName}__description_text`}>
                                {individualDescription(description, navigate)}
                            </div>
                        );
                    }
                    return null;
                });
            } else if (descriptions?.isShow) {
                return (
                    <div key={'descriptions_object'} className={`${baseClassName}__description_text`}>
                        {individualDescription(descriptions, navigate)}
                    </div>
                );
            }
            return null;
        },
        [navigate]
    );

    const getBrandScoreTableTooltip = useCallback(
        (overlayClassName, title, value, position) => (
            <Tooltip
                align={{ offset: [0, -12] }}
                overlayClassName={`${overlayClassName}--tooltip`}
                placement={position}
                title={title}
            >
                <td>{value}</td>
            </Tooltip>
        ),
        []
    );

    const getTrendIcon = useCallback((item) => {
        if (item.symbol === 'up' && item.color === 'green') return trendUpArrow;
        else if (item.symbol === 'up' && item.color === 'yellow') return trendUpYellowArrow;
        else if (item.symbol === 'down' && item.color === 'red') return trendDownArrow;
        else if (item.symbol === 'down' && item.color === 'yellow') return trendDownYellowArrow;
        else if (item.symbol === 'updown' && item.color === 'yellow') return trendUpDownYellowArrow;
        else if (item.symbol === 'flat') return trendFlat;
        return null;
    }, []);

    const showLearnMoreButton = !isMobile;

    const scoreTile = useCallback(() => {
        const subClasses = classNames('score-tile');
        if (tile?.data?.isDefaultBrandPresent) {
            return (
                <div className={`${subClasses}-content`}>
                    {/* {homePageBrandScore(subClasses, tile?.data?.overallScore?.value, tile?.data?.overallScore?.grade)} */}
                    <ByzzerOverallScoreDial
                        className={subClasses}
                        value={dataContent?.overallScore?.value}
                        grade={dataContent?.overallScore?.grade}
                    />
                    <div className={`${subClasses}-content__stats`}>
                        <span className={`${subClasses}-content__stats-message`}>Change since last month:</span>
                        <div className={`${subClasses}-content__stats-value`}>
                            <img
                                className={`${subClasses}_card-icon`}
                                src={
                                    dataContent?.overallScore?.text &&
                                    Number(dataContent?.overallScore?.text.split(' ')[0]) >= 0
                                        ? trendUpArrow
                                        : trendDownArrow
                                }
                                alt={'up'}
                            />
                            <span>{dataContent?.overallScore?.text}</span>
                        </div>
                        {showLearnMoreButton && (
                            <LegacyByzzerButton
                                label={'Learn More'}
                                onClick={goToScoreCard}
                                style={{ width: '20px' }}
                                trackClick={undefined}
                                onDisabledClick={undefined}
                                disabled={undefined}
                                className={undefined}
                                objPopover={undefined}
                            />
                        )}
                    </div>
                </div>
            );
        } else if (!tile?.data?.isDefaultBrandPresent && dataContent?.topGains.length > 0) {
            return (
                <table className={`${subClasses}-table`}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Brand</th>
                            <th>Category</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataContent?.topGains?.map((row, index) => (
                            <tr key={index}>
                                <td className={`score ${row?.change_in_score ? 'green' : ''}`}>
                                    {row?.change_in_score ? '+' + row?.change_in_score : '-'}
                                </td>
                                {row?.brand !== null && getBrandScoreTableTooltip(
                                    subClasses,
                                    truncateString(row?.brand, 26).includes('...') ? row?.brand : undefined,
                                    truncateString(row?.brand, 26),
                                    'bottomLeft'
                                )}
                                {row?.category !== null && getBrandScoreTableTooltip(
                                    subClasses,
                                    truncateString((row?.category || '').split(',').join(', '), 11).includes('...')
                                        ? (row?.category || '').split(',').join(', ')
                                        : undefined,
                                    truncateString((row?.category || '').split(',').join(', '), 11),
                                    'bottomRight'
                                )}
                                <td className={`score ${fetchColorsForGrade(row?.score?.grade)}`}>
                                    {row?.score?.grade ? row?.score?.grade : '-'} (
                                    {row?.score?.value ? row?.score?.value : '-'})
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        } else if (!tile?.data?.isDefaultBrandPresent && dataContent?.topGains.length == 0) {
            return (
                <div className={`${subClasses}-content-nodata-message`}>
                    <p>
                        No brands in your subscription category(s) have improved their NIQ Brand Score in the last
                        month.
                    </p>
                </div>
            );
        }
        return grayedChart(tile);
    }, [tile, getBrandScoreTableTooltip, getTrendIcon, showLearnMoreButton]);

    const trendTile = useCallback(() => {
        return (
            <div className={`${baseClassName}__trend_items`}>
                <div className={`${baseClassName}__trend`}>
                    {dataContent?.map((item, index) => (
                        <div className={`${baseClassName}__trend_item`} key={index + item?.value}>
                            <b className={`${baseClassName}__trend_title`}>{item.key}</b>
                            <div className={`${baseClassName}__trend_value`}>
                                <img
                                    className={`${baseClassName}__trend_card-icon${(item.symbol === 'updown' ? '-2x': '')}`}
                                    src={getTrendIcon(item)}
                                    alt={item.symbol}
                                />
                                <span>{item.value}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [tile]);
    const goToProfile = () => {
        navigate('/dashboard/user_profile/preferences');
    };
    const onReportClick = async (report) => {
        let [usage] = await Promise.all([getMySubscriptionUsage()]);
        if (usage.basicReports.limit - usage.basicReports.used) {
                navigate(`/dashboard/configure_report`, {
                    params: {
                        runType: 'subscription',
                        sku: report.sku,
                    },
                });
        }else{
            let [reportRuns] = await Promise.all([getCompanyReportRuns(subscribedSkus)]);
            const filteredReports =  reportRuns.filter(reportRun => reportRun.sku === report.sku );
            navigate(`/report/${filteredReports[0].id}`, {replace: true});
        }
    };
    const onCallActionClick = async (tile) => {
        if(tile.reportData){
            return onReportClick(tile.reportData);
        }
        if(tile.callToAction.type && tile.callToAction.type == 'tour'){
            startTour(Number(tile.callToAction.link));
        }
        if(tile.callToAction.type && tile.callToAction.type == 'meet'){
            window.open(tile.callToAction.link, "_blank", "noreferrer");
        }
    };
    const reportTile = useCallback(() => {
        return (
            <div className={`${baseClassName}__report_items`} onClick={() => onReportClick(tile.reportData)}>
                <div className={`${baseClassName}__report_items__image_container`}>
                    <img src={tile.reportData.thumbnailUrl ?? tile.reportData.sampleUrl} />
                </div>
            </div>
        );
    }, [tile]);
    const videoTile = useCallback(() => {
        return (
            <div className={`${baseClassName}__video_items`}>
                <div className={`${baseClassName}__video_items__video`}>
                    <WistiaPlayer videoId={tile.content.link} wrapper={'video_wrapper'} />
                </div>
            </div>
        );
    }, [tile]);
    const pdfTile = useCallback(() => {
        const [openPDF, setOpenPDF] = useState(false);
        function handlePDFModal(openPDF) {
            setOpenPDF(!openPDF);
        }

        return (
            <div className={`${baseClassName}__pdf_items`}>
                <div className={`${baseClassName}__pdf_items__pdf`}>
                    <img
                        src={PdfThumbnail}
                        onClick={() => {
                            handlePDFModal(openPDF);
                        }}
                    />

                    {openPDF && <MyHomePagePDFViewer handlePDFModal={handlePDFModal} />}
                </div>
            </div>
        );
    }, [tile]);

    const renderChartTile = useCallback(
        (tileContent) => {
            const chartConfig = formGraphData(tileContent);
            if (isEmpty(chartConfig)) {
                return grayedChart(tileContent);
            }
            return (
                <div className={`${baseClassName}__chart__trend`}>
                    <ZingChart data={chartConfig} height={tile?.vizConfig?.height ?? 250} />
                </div>
            );
        },
        [tile]
    );

    const contentTile = useCallback(() => {
        if (tile?.id in tilesLoadingState && tilesLoadingState[tile?.id]) {
            return spinnerChart('refreshTile');
        }
        if (tileType !== TILE_TYPES.STATIC && (isErrorData || isDataExpired || isEmpty(dataContent))) {
            return grayedChart(tile);
        }

        if (isLoading) {
            return spinnerChart(tile);
        }

        switch (tile?.vizType) {
            case 'score':
                return scoreTile();
            case 'trend':
                return trendTile();
            case 'video':
                return videoTile();
            case 'pdf':
                return pdfTile();
            case 'report':
                return reportTile();
            case 'chart':
            case 'pie':
            case 'line':
            case 'bar':
            case 'waterfall':
                return renderChartTile(tile);
            default:
                return grayedChart(tile);
        }
    }, [tile, tilesLoadingState]);

    const tileFooterContent = useCallback(() => {
        return <TileFooter className={baseClassName} tile={tile} goToScoreCard={goToScoreCard} />;
    }, [tile, tilesLoadingState, goToScoreCard]);

    const callToAction = useCallback(() => {
        // TODO: This image will be replaced by an its SVG
        return (
            <div className={`${baseClassName}__call_to_action`} onClick={() => onCallActionClick(tile)}>
                <div className={`${baseClassName}__call_to_action__section`}>
                    <p className={`${baseClassName}__call_to_action__label`}>{tile.callToAction.description}</p>
                    <div className={`${baseClassName}__call_to_action__arrow`}>
                        <img
                            src="https://img.icons8.com/ios-filled/50/FFFFFF/long-arrow-right.png"
                            alt="long-arrow-right"
                        />
                    </div>
                </div>
            </div>
        );
    }, [tile]);
    return (
        <TrackClick name={`(${tile.type}) type tile was clicked`}>
            <div className={`${baseClassName} ${className}`} key={refreshTile}>
                <div className={`${baseClassName}`}>
                    {tile.vizConfig && <h2 className={`${baseClassName}__title`}>{tile?.vizConfig?.title}</h2>}
                    {tile?.data && tileDescription()}
                    {contentTile()}
                    {tile?.data && tileFooterContent()}
                </div>
                {tile.callToAction && callToAction()}
            </div>
        </TrackClick>
    );
});

export default TileVisualization;
