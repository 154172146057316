import './OfferViewer.scss';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {DashboardNav} from "@/components/dashboard";
import DashboardContent from "@/components/dashboard/DashboardContent";
import DashboardHeader from '@/components/dashboard/DashboardHeader';

import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import DashboardFooter from "../../components/dashboard/DashboardFooter";
import {useParams} from "react-router-dom";
import {useTenantApi} from "@/hooks/useTenantApi";
import {LegacyByzzerButton} from "@/components/form";
import {useBetterNavigate} from "@/utils";
import {useUser} from "@/contexts/UserContext";
import {alert} from "@/components/form";

const baseClassName = 'offer-viewer'
export default function OfferViewer({className, ...props}) {
    const {getOffer, updateMySettings} = useTenantApi();

    const {user} = useUser();
    const navigate = useBetterNavigate();
    const {offerId} = useParams();
    const {refreshUser} = useUser();
    const [offer, setOffer] = useState({});
    const [url, setUrl] = useState();

    useEffect(() => {
        loadOffer();
    }, [offerId])

    async function loadOffer() {
        try {
            const {postAcceptUrl, ...offer} = await getOffer(offerId);
            if (!postAcceptUrl) {
                markOfferCompleteAndExit();
            }
            setOffer(offer);
            const params = new URLSearchParams({
                firstName: user?.firstName,
                lastName: user?.lastName
            });
            if(postAcceptUrl.includes('?')) {
                setUrl(`${postAcceptUrl}&${params}`);
            } else {
                setUrl(`${postAcceptUrl}?${params}`);
            }
        } catch(err) {
            switch(err.code) {
                case 'offer_not_found':
                    await alert({
                        title: 'Offer Not Found',
                        content: `Couldn't find offer ${offerId}`,
                    })
                    break;
                case 'offer_expired':
                    await alert({
                        title: 'Offer Expired',
                        content: `Offer ${offerId} expired.`
                    })
                    break;
            }

            markOfferCompleteAndExit();
        }
    }

    async function markOfferCompleteAndExit() {
        await updateMySettings({
            showOffer: false
        });
        await refreshUser();
        navigate('/dashboard', {replace: true})
    }

    return (
        <div className="dashboard">
            <DashboardNav disabled={true}/>
            <DashboardContent title={offer.title} subTitle={offer.message}
                              className={classnames(baseClassName, className)}>
                {url && (
                    <iframe className={`${baseClassName}__iframe`} src={url}></iframe>
                )}
                <div className={`${baseClassName}__actions`}>
                    <LegacyByzzerButton onClick={markOfferCompleteAndExit}>Close</LegacyByzzerButton>
                </div>
            </DashboardContent>
            <DashboardHeader disabled={true}/>
        </div>
    );
}
