import './DodProductFiltersStep.scss';
import React, { ReactNode, useMemo, useState } from 'react';
import classnames from 'classnames';
import { StepRef, WizardContent, WizardStep } from '@byzzer/ui-components';
import { DodProductFilterBuilder, DodProductFilterBuilderProps } from '@/components/DodConfigEditor/builders';
import { DodWizardActions } from '../DodWizardActions';

export type DodProductFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
    helpIconTip: string | ReactNode;
} & DodProductFilterBuilderProps;

const baseClassName = 'dod-product-filters-step';

export const DodProductFiltersStep = React.forwardRef<StepRef, DodProductFiltersStepProps>(
    ({ className, enabled, exclude, title = 'Products', onValidityChange, helpIconTip, ...props }, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo(() => {
            const { value } = props;
            let count = 0;

            for (let key in value) {
                if (key === 'markets' || key === 'timePeriods') continue;

                if (key === 'characteristics' || key === 'customCharacteristics' || key === 'ppgs') {
                    const length = Object.keys(value[key]).length;
                    if (length > 0) {
                        count += length;
                    }
                } else {
                    const { values, summedSelections } = value[key];
                    if (values.length > 0 || summedSelections.length > 0) {
                        count++;
                    }
                }
            }
            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                ref={ref}
                id={'products'}
                exclude={exclude}
                title={`${title} ${count}`}
                enabled={enabled}
            >
                <WizardContent>
                    <DodProductFilterBuilder {...props} onValidityChange={handleValidationChange} />
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    helpIconTip={helpIconTip}
                    nextDisabledTip={'Please choose at least one category to continue.'}
                />
            </WizardStep>
        );
    }
);
