import './SchedulePreview.scss';
import classnames from 'classnames';
import { useContext, useEffect, useMemo } from 'react';
import { TimePeriod } from '@/utils/timePeriod/TimePeriod';
import { formatInTimeZone } from 'date-fns-tz';
import { addDays } from 'date-fns';
import { useTimePeriod } from '@/hooks/useTimePeriod';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import { ReportRunConfigWizardContext } from '../ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext';

export interface SchedulePreviewProps {
    value: Schedule;
    className?: string;
    onChange: ByzzerChangeEventHandler<Schedule>;
}

const daysMap: Record<string, number> = {
    'rms': 10,
    'cps': 17,
    'omni': 10,
};

const baseClassName = 'schedule-preview';

interface DeliveryDateSet {
    endDate: string;
    deliveryDate: string;
}

const deliveryDays: ScheduleDeliveryDay[] = [
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
    'monday',
];

export function SchedulePreview({ value, className, onChange }: SchedulePreviewProps) {
    const { value: runConfig } = useContext(ReportRunConfigWizardContext);
    const { datatype } = runConfig;
    const {
        futureQuarterlyOptions,
        futureWeeklyOptions,
        future444Options,
        future445Options,
        maxFutureWeeks,
    } = useTimePeriod(datatype);

    const rows = useMemo<DeliveryDateSet[]>(() => {
        const { duration = 1, frequency, timePeriods, startingTimePeriod, deliveryDay } = value;
        if (!frequency || (!startingTimePeriod && !timePeriods?.length) || !deliveryDay) {
            return [];
        }
        let availablePeriods: string[] = [];
        switch (frequency) {
            case 'one_time':
                availablePeriods = [
                    futureWeeklyOptions.find((v) => v.value === new TimePeriod(startingTimePeriod!).toString())
                        ?.value as string,
                ];
                break;
            case 'weekly':
                availablePeriods = futureWeeklyOptions.map((v) => v.value);
                break;
            case 'quarterly':
                availablePeriods = futureQuarterlyOptions.map((v) => v.value);
                break;
            case 'monthly_444':
                availablePeriods = future444Options.map((v) => v.value);
                break;
            case 'monthly_445':
                availablePeriods = future445Options.map((v) => v.value);
                break;
        }

        const deliveryOffset = deliveryDays.indexOf(deliveryDay) + daysMap[datatype];
        let deliveryTimePeriods: TimePeriod[];
        if (timePeriods) {
            deliveryTimePeriods = timePeriods.sort().map((v) => new TimePeriod(v));
        } else {
            const firstTimePeriod = new TimePeriod(startingTimePeriod!);
            const startIndex = availablePeriods.findIndex((v) => v === firstTimePeriod.toString());
            if (startIndex === -1) {
                deliveryTimePeriods = [];
            } else {
                const endIndex = startIndex + duration;
                deliveryTimePeriods = availablePeriods.slice(startIndex, endIndex).map((v) => new TimePeriod(v));
            }
        }

        return deliveryTimePeriods.map((tp) => ({
            endDate: formatInTimeZone(tp.toGmtDate(), 'GMT', 'MM-dd-yyyy'),
            deliveryDate: formatInTimeZone(addDays(tp.toGmtDate(), deliveryOffset), 'GMT', 'MM-dd-yyyy'),
        }));
    }, [
        value?.duration,
        value?.frequency,
        value?.timePeriods,
        value?.startingTimePeriod,
        value?.deliveryDay,
    ]); // Dependency should be on specific values of value, It will cause infinite calling issue when dependency will be value directly because In the below useEffect we are tracking rows and that is again changing value in context

    useEffect(() => {
        onChange?.({
            value: {
                ...value,
                scheduleDates: rows,
            },
        });
    }, [rows]);

    return (
        <div className={classnames(baseClassName, className)}>
            <table className={`${baseClassName}__preview-table`}>
                <tbody>
                    <tr>
                        <th>
                            <div className={`${baseClassName}__preview-table-header-content`}>Week Ending</div>
                        </th>
                        <th>
                            <div className={`${baseClassName}__preview-table-header-content`}>Deliver On</div>
                        </th>
                    </tr>
                    {rows?.map((row) => (
                        <tr key={`${row.deliveryDate}${row.deliveryDate}`}>
                            <td>{row.endDate}</td>
                            <td>{row.deliveryDate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SchedulePreview;
