import './ReportRunOverlayConfigEditor.scss';
import React, { useEffect, useState } from 'react';
import { ReportRunConfig } from '@/types/ReportRun';
import { ByzzerButton } from '@byzzer/ui-components';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { showErrorModal, toRunsText } from '@/utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerModal, alert } from '@/components/form/ByzzerModal';
import { getRunConfigOptionsBySku, getProductBySku } from '@/services/product.service';
import { RunConfigOptions } from '@/types/RunConfigOptions';
import { useUser } from '@/contexts/UserContext';
import { ReportRunConfigWizard } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard';
import { useReportRunService } from '@/services/reportRun.service';
import SubscriptionReport from '@/views/MyReports/SubscriptionReports';
import { openCreateReportScheduleModal } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ScheduleReportStep/ScheduleModal';

export type ReportRunOverlayConfigEditorProps = React.HTMLAttributes<HTMLDivElement> & {
    reportSku: string;
    onComplete?: (reportId: string, isScheduleReportComplete?: boolean) => void;
    reportId?: number;
    reportRunConfig?: Partial<ReportRunConfig>;
    enableScheduleReportStep?: boolean;
};

// I don't think I will need this long term
export type ReportConfigurationValue = {};

type ReportType = 'core' | 'smart';

const baseClassName = 'report-run-config-overlay-editor';

export function ReportRunOverlayConfigEditor({
    className,
    reportSku,
    onComplete,
    reportId,
    reportRunConfig,
    enableScheduleReportStep,
    ...props
}: ReportRunOverlayConfigEditorProps) {
    const {
        createAdHicReportRun,
        createSubscriptionReportRun,
        getMySubscriptionUsage,
        getReportRunById,
        getReportRunConfigById,
        getScheduledReportSeriesNames,
        createScheduleReport
    } = useTenantApi();

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { getDefaultRunConfigBySku, getReportRunConfig } = useReportRunService();
    const [report, setReport] = useState<any>();
    const { subscribedSkus } = useUser();
    const [runConfigOptions, setRunConfigOptions] = useState<RunConfigOptions[]>();
    const [defaultRunConfig, setDefaultRunConfig] = useState<Partial<ReportRunConfig>>({});
    const [remainingRuns, setRemainingRuns] = useState<number>();
    const [reportType, setReportType] = useState<ReportType>('core');
    const [generating, setGenerating] = useState<boolean>(false);
    const [showChangeReportModal, setShowChangeReportModal] = useState(false);
    const [schedule, setSchedule] = useState<Schedule>({
        frequency: 'one_time',
        deliveryDay: 'tuesday',
        deliveryGroupType: 'me',
        deliverToUserIds: [],
        duration: 1,
        scheduleDates: [],
        startingTimePeriod: '',
        timePeriods: [],
    });
    const [scheduledSeriesNames, setScheduledSeriesNames] = useState<string[]>([]);

    const runType: RunType = 'subscription';
    const [sku, setSku] = useState<string>(searchParams.get('sku')??reportSku);
    const reportRunId = searchParams.get('reportId')??reportId;
    const runCreditId = null;

    useEffect(() => {
        (async () => {
            if (await validateOrExit()) {
                await loadConfig();
            }
        })();
    }, [sku]);

    useEffect(() => {        
        if (enableScheduleReportStep) {            
            fetchScheduledSeriesNames();
        }
    }, [enableScheduleReportStep]);

    async function loadConfig() {
        try {
            const report = getProductBySku(sku);
            // todo: show message if there is no matching config for he sku
            if (!report) {
                await alert({
                    // @ts-ignore
                    title: 'Product Not Found',
                    content: `We were unable to find the product you are trying to configure.`,
                });

                return navigate(-1);
            }

            // todo: add support normalizing reports so this can just be check of single type instead of all this
            setReportType(report.metadata.reportOptions?.reportType.startsWith('smart') ? 'smart' : 'core');
            setReport(report);
            if(reportRunConfig){
                setDefaultRunConfig(reportRunConfig);
            }else if(reportRunId) {
                try {
                    let reportRun = await getReportRunConfigById(Number(reportRunId));
                    if (reportRun?.sku === sku) {
                        setDefaultRunConfig(reportRun?.configuration);
                    } else {
                        setDefaultRunConfig(getReportRunConfig(sku,reportRun?.configuration));
                    }
                } catch (err) {
                    console.error(err);
                }
            } else {
                setDefaultRunConfig(getDefaultRunConfigBySku(sku));
            }

            if (runType !== 'adhoc') {
                const credits = await getMySubscriptionUsage();

                if (report.metadata.reportOptions?.reportType.startsWith('smart')) {
                    setRemainingRuns(Math.max(0, credits.premiumReports?.limit - credits.premiumReports?.used));
                } else {
                    setRemainingRuns(Math.max(0, credits.basicReports?.limit - credits.basicReports.used));
                }
            }

            setRunConfigOptions(getRunConfigOptionsBySku(sku!));
        } finally {
            // setLoading(false);
        }
    }

    async function validateOrExit(): Promise<boolean> {
        if (!['subscription', 'adhoc'].includes(runType)) {
            await alert({
                // @ts-ignore
                title: 'Invalid Report Run',
                content: 'Only subscription and adhoc runs are supported.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'subscription' && !subscribedSkus.includes(sku as string)) {
            await alert({
                // @ts-ignore
                title: 'Product Not Available',
                content: 'The product you selected is not available in your current subscription.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'adhoc' && !runCreditId) {
            await alert({
                // @ts-ignore
                title: 'Invalid Credit',
                content: 'You have not purchased this ad hoc report.',
            });
            navigate(-1);
            return false;
        }

        return true;
    }

    async function generateReport(runConfig: ReportRunConfig): Promise<void> {
        try {
            setGenerating(true);
            let reportId: string | undefined;
            if (runType === 'subscription' && sku) {
                reportId = await createSubscriptionReportRun(sku, runConfig);
            } else if (runType === 'adhoc' && runCreditId) {
                reportId = await createAdHicReportRun(runCreditId, runConfig);
            }

            if (reportId) {
                onComplete?.(reportId);
            }
        } catch (err) {
            showErrorModal(err);
            setGenerating(false);
        }
    }

    const fetchScheduledSeriesNames = async () => {
        try {
            const response = await getScheduledReportSeriesNames();
            setScheduledSeriesNames(response.scheduledSeriesNames);
        } catch (error) {
            console.error('error in fetchScheduledSeriesNames');
        }
    };

    const onCreateSchedule = async (runConfig: ReportRunConfig, scheduleData: Schedule): Promise<void> => {
        try {
            const createScheduleResp = await openCreateReportScheduleModal({
                seriesName: '', // we are handling seriesName in modal itself but type is expecting that so it's a hack
                schedule: scheduleData,
                runConfig,
                seriesNames: scheduledSeriesNames,
                sku: sku!,
                createScheduleReport
            });

            if (createScheduleResp) {
                onComplete?.(String(reportRunId), true);
            }
        } catch (err) {
            console.error('error in report run config editor', err);
            alert({
                type: 'error',
                title: 'Run Failed',
                content: (
                    <>
                        <p>There was an unexpected error during this run creation.</p>
                        <p>Please contact the support team for additional assistance.</p>
                    </>
                ),
            });
        } finally {
            setGenerating(false);
        }
    };

    let extras = (
        <div className={`${baseClassName}__change-report`}>
            <ByzzerButton type={'negative'} label={'Change Report'} onClick={() => setShowChangeReportModal(true)} />
        </div>
    );

    function handleChangeReport(reportType: string, sku: string) {
        setSku(sku);
        setShowChangeReportModal(false);
    }

    
    const handleScheduleDataChange = (e: ByzzerChangeEvent<Schedule>): void => {
        setSchedule(e.value);
    };

    return (
        // @ts-ignore
        <DashboardContent
            title={<>Design Your {report?.title} Report</>}
            className={`${baseClassName}__container`}
            loading={!runConfigOptions}
            subTitle={
                <>
                    {/*<p>Define the focus and comparative products and markets, to tailor the data to your need.</p>*/}
                    <ReportSubtitle runType={runType} remainingRuns={remainingRuns} reportType={reportType} />
                </>
            }
            extras={extras}
            key={sku}
        >
            {runConfigOptions && (
                <ReportRunConfigWizard
                    sku={sku!}
                    onComplete={generateReport}
                    runType={runType}
                    defaultValues={defaultRunConfig}
                    busy={generating}
                    runConfigOptions={runConfigOptions}
                    enableScheduleReport={!!enableScheduleReportStep}
                    scheduleData={schedule}
                    onScheduleDataChange={handleScheduleDataChange}
                    onCreateSchedule={onCreateSchedule}
                    navigateStepIndex={enableScheduleReportStep ? report?.metadata?.configOptions?.length + 1 : undefined}
                />
            )}

            {showChangeReportModal && (
                <ByzzerModal
                    show={showChangeReportModal}
                    onClose={() => setShowChangeReportModal(false)}
                    heading={'My Report'}
                    size={'large'}
                    headerType={'normal'}
                    className= {`${baseClassName}__report-list-container`}
                >
                    <SubscriptionReport
                        changeReport={true}
                        handleChangeReport={(reportType, sku) => handleChangeReport(reportType, sku)}
                    />
                </ByzzerModal>
            )}
        </DashboardContent>
    );
}

type ReportSubtitleProps = {
    reportType: ReportType;
    runType: RunType;
    remainingRuns?: number;
};

function ReportSubtitle({ reportType, runType, remainingRuns }: ReportSubtitleProps) {
    if (runType === 'adhoc') return <></>;

    return (
        <p className="report-run-selector__accordion-report-count">
            {`You have ${toRunsText(remainingRuns)} ${reportType} report runs left. `}
            {Number.isFinite(remainingRuns) && remainingRuns! > 0 && (
                <>{`After running this report, your team will have ${toRunsText(remainingRuns! - 1)} remaining.`}</>
            )}
        </p>
    );
}

export default ReportRunOverlayConfigEditor;
