import React, { useState, useEffect } from 'react';
import './ProductCodingRequestSuccess.scss';
import classNames from 'classnames';
import InlineSVG from 'react-inlinesvg';
import tickIcon from '@/images/icons/tick-icon.svg';
import { LegacyByzzerButton } from '@/components/form';

function ProductCodingRequestSuccess({ onComplete }) {
    const wrapperClasses = classNames('product-coding-request-success');
    async function onReSubmit() {
        await onComplete('reSubmit');
    }
    async function onDone() {
        await onComplete('done');
    }

    return (
        <div className={wrapperClasses}>
            <div className="product-coding-request-success__tick">
                <i>
                    <InlineSVG src={tickIcon} />
                </i>
            </div>
            <div className="product-coding-request-success__message">
                Thank you for submitting a product coding request!
            </div>
            <div className="product-coding-request-success__sub-message">
                Your feedback is valuable to us. We appreciate your time!
            </div>
            <div className="product-coding-request-success__sub-message-desc">
                If the Category, Subcategory or Brand request is submitted by the end of day Wednesday, the update will reflect the following Tuesday.  If we are unable to complete your request, a member of the Byzzer Support Team will contact you.
            </div>
            <div className="product-coding-request-success__btns">
                <LegacyByzzerButton onClick={onReSubmit} label={'Submit Another'} />
            </div>
            <div className="product-coding-request-success__btns">
                <LegacyByzzerButton onClick={onDone} label={'I’m Done'} />
            </div>
        </div>
    );
}

export default ProductCodingRequestSuccess;
