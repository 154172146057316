import React from 'react';
import classNames from 'classnames';

interface EditIconProps {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const EditIcon: React.FC<EditIconProps> = ({ className, onClick, ...props }) => {
    const classes = classNames(className, 'byzzer-icon', 'byzzer-icon--edit');

    const onIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        onClick?.(e);
    };

    return <div className={classes} onClick={onIconClick} {...props} />;
};

export default EditIcon;
