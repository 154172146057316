import React, {useState} from 'react';
import './UserPassword.scss';
import ByzzerInput from '@/components/form/ByzzerInput';
import LegacyByzzerButton from '@/components/form/LegacyByzzerButton';
import {ByzzerErrorModal} from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { ByzzerMask } from './ByzzerMask/ByzzerMask';
import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks/useTenantApi";
import { useAccountService } from '@/hooks/useAccountService';

const UserPassword = ({ shouldUpdateResetPasswordFlag }) => {
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [currPass, setCurrPass] = useState('');
    const [valid, setValid] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const regexPattern = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d-!@#$%^&*()_+|~=`{}[\]:";'<>?,./]{8,21}$/;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { refreshUser } = useUser();
    const { updatePassword } = useAccountService();
    const { updateResetPasswordFlag } = useTenantApi();

    const handleChangePassword = async () => {
        try {
            setIsLoading(true);
            const validatePass: any = matchPasswords(pass1, pass2);
            if (validatePass.status) {
                let credentials: any = {};
                credentials.oldPass = currPass;
                credentials.newPass = pass1;
                await updatePassword(credentials);

                if (shouldUpdateResetPasswordFlag) {
                    await updateResetPasswordFlag();
                    await refreshUser();
                    navigate('/dashboard', {replace: true})
                }
            } else {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(validatePass.msg);
            }
            setIsLoading(false);
        } catch (err: any) {
            setShowWarning(true);
            setIsLoading(false);
            setContentType('error');
            setWarningMessage(err?.message || err?.error?.message || 'Something went wrong');
        }
    };

    const confirmPassword = (e) => {
        if (regexPattern.test(e.target.value)) {
            setPass1(e.target.value);
            pass2 && matchPasswords(e.target.value, pass2);
        }
    };

    const confirmSecondPassword = (e) => {
        if (regexPattern.test(e.target.value)) {
            setPass2(e.target.value);
            matchPasswords(pass1, e.target.value);
        }
    };

    const matchPasswords = (passw1, passw2) => {
        if (passw1 && passw2) {
            if (passw1 !== passw2) {
                // message.destroy();
                // message.warning('Your password and confirmation password do not match.', 2);
                setValid(false);
                return {status: false, msg: 'Your password and confirmation password do not match.'};
            } else {
                if (currPass === pass1) {
                    // message.warning('The new password cannot be the same as the current password', 2);
                    setValid(false);
                    return {status: false, msg: 'The new password cannot be the same as the current password'};
                } else {
                    setValid(true);
                    return {status: true};
                }
            }
        }
    };

    const currentPassword = (e) => {
        setCurrPass(e.target.value);
        matchPasswords(pass1, pass2);
    };
    return (
        <div className="user-password">
            <ByzzerMask show={isLoading} loading={isLoading}/>
            <h2 className="user-password__heading">Change Your Password</h2>
            <div className="user-password__form">
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Current Password'}
                    className={'password'}
                    name={'oldPassword'}
                    onChange={(e) => currentPassword(e)}
                    onBlur={currentPassword}
                    placeholder="Current Password"
                    type={'password'}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'New Password'}
                    className={'password'}
                    name={'password'}
                    placeholder="New Password"
                    type={'password'}
                    onChange={confirmPassword}
                    onBlur={confirmPassword}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Confirm New Password'}
                    className={'password'}
                    name={'confirm'}
                    placeholder="Confirm New Password"
                    type={'password'}
                    onChange={confirmSecondPassword}
                    onBlur={confirmSecondPassword}
                />
            </div>
            <div className="user-password__actions">
                <LegacyByzzerButton
                    key={valid + currPass}
                    disabled={valid === false || currPass === '' ? true : false}
                    onClick={!(valid === false || currPass === '') ? () => handleChangePassword() : () => {
                    }}
                >Update Password</LegacyByzzerButton>
            </div>
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
        </div>
    );
};

export default UserPassword;
