import './SchedulePicker.scss';
import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    ByzzerSelectValueTypes,
} from '@byzzer/ui-components';
import { arrayRange } from '@/utils/utils';
import { useTimePeriod } from '@/hooks/useTimePeriod';
import { ReportRunConfigWizardContext } from '../ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard/ReportRunConfigWizardContext';

const baseClassName = 'schedule-picker';

export type SchedulePickerProps = {
    className?: string;
    name?: string;
    value: Schedule;
    onChange: ByzzerChangeEventHandler<Schedule>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type FrequencyOption = {
    display: string;
    value: ScheduleFrequency;
};

export enum FrequencyValueLabelMapping {
    one_time = 'One-Time',
    weekly = 'Weekly',
    monthly_444 = 'Monthly (4-4-4)',
    monthly_445 = 'Monthly (4-4-5)',
    quarterly = 'Quarterly',
    custom = 'Custom',
}

const FREQUENCY_OPTIONS: FrequencyOption[] = [
    {
        display: 'One-Time',
        value: 'one_time',
    },
    {
        display: 'Weekly',
        value: 'weekly',
    },
    {
        display: 'Monthly (4-4-4 Cadence)',
        value: 'monthly_444',
    },
    {
        display: 'Monthly (4-4-5 Cadence)',
        value: 'monthly_445',
    },
    {
        display: 'Quarterly',
        value: 'quarterly',
    },
    {
        display: 'Custom',
        value: 'custom',
    },
];

type DeliveryDayOption = {
    display: string;
    value: ScheduleDeliveryDay;
};

const DELIVERY_DAY_OPTIONS: DeliveryDayOption[] = [
    {
        display: 'Tuesday Of Availability',
        value: 'tuesday',
    },
    {
        display: 'Wednesday Following Availability',
        value: 'wednesday',
    },
    {
        display: 'Thursday Following Availability',
        value: 'thursday',
    },
    {
        display: 'Friday Following Availability',
        value: 'friday',
    },
    {
        display: 'Saturday Following Availability',
        value: 'saturday',
    },
    {
        display: 'Sunday Following Availability',
        value: 'sunday',
    },
    {
        display: 'Monday Following Availability',
        value: 'monday',
    },
];

export function ReportSchedulePicker({
    className,
    name,
    value,
    onChange,
    onValidityChange,
    ...props
}: SchedulePickerProps) {
    const { value: runConfig } = useContext(ReportRunConfigWizardContext);
    const { datatype } = runConfig;

    const {
        futureQuarterlyOptions,
        futureWeeklyOptions,
        future444Options,
        future445Options,
        maxFutureWeeks,
    } = useTimePeriod(datatype);
    const [deliveryOptions, setDeliveryOptions] = useState<ByzzerSelectOption[]>(() =>
        arrayRange(1, maxFutureWeeks, 1).map((entry) => ({
            display: `${entry}`,
            value: entry,
        }))
    );
    const [startPeriodOptions, setStartPeriodOptions] = useState<ByzzerSelectOption[]>(futureWeeklyOptions);

    useEffect(() => {
        const frequency = value?.frequency;
        switch (frequency) {
            case 'quarterly':
                setStartPeriodOptions(futureQuarterlyOptions);
                break;
            case 'monthly_444':
                setStartPeriodOptions(future444Options);
                break;
            case 'monthly_445':
                setStartPeriodOptions(future445Options);
                break;
        }
    }, []);

    const setDeliveryOptionData = (range: Number) => {
        setDeliveryOptions(() => {
            return arrayRange(1, range, 1).map((entry) => ({
                display: `${entry}`,
                value: entry,
            }));
        });
    };
    function handleFrequencyChange(e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>) {
        const frequency: ScheduleFrequency = e.value as ScheduleFrequency;
        let startingTimePeriod: string | undefined;
        let timePeriods: string[] | undefined;
        let noOfDeliveries = 1;
        switch (frequency) {
            case 'one_time':
            case 'weekly':
                setStartPeriodOptions(futureWeeklyOptions);
                startingTimePeriod = futureWeeklyOptions[0].value;
                noOfDeliveries = Math.min(52, maxFutureWeeks);
                setDeliveryOptionData(maxFutureWeeks);
                break;
            case 'custom':
                setStartPeriodOptions(futureWeeklyOptions);
                timePeriods = [futureWeeklyOptions[0].value];
                break;
            case 'quarterly':
                setStartPeriodOptions(futureQuarterlyOptions);
                startingTimePeriod = futureQuarterlyOptions[0].value;
                noOfDeliveries = Math.min(4, futureQuarterlyOptions.length);

                setDeliveryOptionData(futureQuarterlyOptions.length);
                break;
            case 'monthly_444':
                setStartPeriodOptions(future444Options);
                startingTimePeriod = future444Options[0].value;
                noOfDeliveries = Math.min(13, future444Options.length);
                setDeliveryOptionData(future444Options.length);
                break;
            case 'monthly_445':
                setStartPeriodOptions(future445Options);
                startingTimePeriod = future445Options[0].value;
                noOfDeliveries = Math.min(12, future445Options.length);
                setDeliveryOptionData(future445Options.length);
                break;
        }
        onChange?.({
            value: {
                ...value,
                frequency,
                duration: noOfDeliveries,
                startingTimePeriod,
                timePeriods,
            },
        });
    }

    function handleDeliveryDayChange(e: ByzzerChangeEvent<ScheduleDeliveryDay>) {
        onChange?.({
            value: {
                ...value,
                deliveryDay: e.value,
            },
        });
    }

    function handleDurationChange(e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>) {
        const duration: number = e.value as number;
        onChange?.({
            value: {
                ...value,
                duration,
            },
        });
    }

    function handleStartingTimePeriodChange(e: ByzzerChangeEvent<string | undefined>) {
        onChange?.({
            value: {
                ...value,
                startingTimePeriod: e.value as string,
                timePeriods: undefined,
            },
        });
    }

    function handleTimePeriodsChange(e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>) {
        onChange?.({
            value: {
                ...value,
                startingTimePeriod: undefined,
                timePeriods: e.value as string[],
            },
        });
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <ByzzerSelect
                allowClear={false}
                className={`${baseClassName}__frequency-picker`}
                label="Frequency"
                value={value.frequency}
                options={FREQUENCY_OPTIONS}
                onChange={handleFrequencyChange}
            />
            <ByzzerSelect
                allowClear={false}
                className={`${baseClassName}__delivery-day-picker`}
                label="Delivery On"
                value={value.deliveryDay}
                options={DELIVERY_DAY_OPTIONS}
                onChange={handleDeliveryDayChange}
            />
            {value.frequency !== 'custom' && (
                <ByzzerSelect
                    allowClear={false}
                    className={`${baseClassName}__weekending-day-picker`}
                    label="Starting with Data Week Ending"
                    value={value.startingTimePeriod}
                    options={startPeriodOptions}
                    onChange={handleStartingTimePeriodChange}
                />
            )}
            {value.frequency === 'custom' && (
                <ByzzerSelect
                    className={`${baseClassName}__weekending-day-picker`}
                    label="Starting with Data Week Ending"
                    value={value.timePeriods}
                    options={startPeriodOptions}
                    onChange={handleTimePeriodsChange}
                    allowMultiple
                />
            )}
            {!['custom', 'one_time'].includes(value.frequency) && (
                <ByzzerSelect
                    allowClear={false}
                    className={`${baseClassName}__repetition-picker`}
                    label="Number of Deliveries"
                    value={value.duration}
                    options={deliveryOptions}
                    onChange={handleDurationChange}
                />
            )}
        </div>
    );
}

export default ReportSchedulePicker;
