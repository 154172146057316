import './AlertsList.scss';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import ByzzerStep from '../form/ByzzerStep';
import ByzzerStepper from '../form/ByzzerStepper';
import {LegacyByzzerButton, ByzzerModal} from '../form';
import {Collapse} from 'antd';
import {useTenantApi} from '@/hooks/useTenantApi';
import AlertProductSelector from '../AlertProductSelector/AlertProductSelector';
import AlertMarketSelector from '../AlertMarketSelector/AlertMarketSelector';
import {useSetState} from '@/utils';
import {alertConfigs} from '@/config/alertDetails.config';
import { alertsDescriptionUrl, selectorStates, marketTypeValues, selectorLabels } from '@/config/globalVars';
import ReportInformation from '@/views/ReportInformation';
import {ByzzerMask} from '@/components/ByzzerMask/ByzzerMask';
import classnames from 'classnames';
import {useUser} from '@/contexts/UserContext';
import {getAllAlerts, getLegacyConfigOptionsBySku} from '@/services/product.service';
import {useBetterNavigate} from '@/utils/utils';
import back from '@images/icons/back-icon.svg';
import AlertRecipientSelector from '../AlertRecipientSelector/AlertRecipientSelector';
import {useLocation} from 'react-router-dom';
import {warn} from "@/components/form/ByzzerModal";
import StackedReportFilters from '@/views/MyReports/StackedReportFilters';
import { MyAlertsProps } from '@/pages/MyAlertsPage';
import useIsMobile from '@/hooks/useIsMobile';
import { ByzzerCard } from '../ByzzerCard';
import {TipIcon} from '@/components/icons';

const baseClassName = 'alert-setup-wizard';
const AlertsList = forwardRef<any, MyAlertsProps>(({
    alertLimits: {
        totalConfiguredAlerts,
        maxTotalAlerts,
        canAddAlerts,
        ...alertLimits
    },
    ...props
}, ref) => {
    const {createAlerts, getMarketKeyFromMarkets, getUserPreferences, updateAlerts} = useTenantApi();
    const isMobile = useIsMobile();
    const {subscribedSkus, isCsrObo, subscription, user} = useUser();
    const [loading, setLoading] = useState(false);
    const [showAlertFilters, setShowAlertFilters] = useState(true);
    const {Panel} = Collapse;
    const {state: editData} = useLocation() as any;
    const defaultSelectorValue = {
        focusMarket: editData?.focusMarket ?? '',
        selectorsStatus: [
            {
                labelName: selectorLabels.product,
                status: selectorStates.pending,
                selectorType: 'product',
            },
            {
                labelName: selectorLabels.focusMarket,
                status: selectorStates.pending,
                selectorType: 'market',
            },
        ],
        focusProductSelections: [
            {
                brand: '',
                category: [] || '',
            },
        ],
        categoryAlias: '',
        focusMarketSelections: [],
        selectedItems: [],
        isMarketValidFormItems: [],
        focusMarketKeys: [],
        focusMarketsSelectedData: [],
        selectedAlertDetails: {},
        characteristicFilters: [],
        alertConfigurations: {},
        edit: false,
        notificationType: 'just-me',
        notificationEmail: [],
        userEmail: [],
        activeCollapsePanel: [],
        refreshMarkets: false,
        comparisonMarketTypeSelection: '',
        marketTypeUpdated: false,
        clearFocusMarket: false,
        clearMarketsToWatch: false
    };
    // const [prefData, setPrefData] = useState(null);
    const [selectorState, setSelectorState] = useSetState(defaultSelectorValue);
    const [alertsData, setAlertsData] = useState<any>([]);
    const [activeStep, setActiveStep] = useState(1);
    const [alertInformationView, setAlertInformationView] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState<any>();
    const navigate = useBetterNavigate();
    const [fromBack, setFromBack] = useState(false);
    const [skuFilter, setSkuFilter] = useState([]);
    const [filteredAlerts, setFilteredAlerts] = useState<any>([]);
    const Steps = [
        {
            name: 'SelectAlertType',
            title:
                alertsData.length > 0
                    ? 'Select Alert Type'
                    : loading
                        ? 'Getting Available Alerts...'
                        : 'No Alerts Found',
        },
        {name: 'ConfigureAlert', title: `Configure ${selectorState?.selectedAlertDetails?.title}`},
        {name: 'ConfigureEmailRecipient', title: `Configure ${selectorState?.selectedAlertDetails?.title}`},
    ];
    const collapseClick = (index) => {
        setSelectorState({activeCollapsePanel: index || []});
    };

    useEffect(() => {
        loadData();
        // fetchPreferenceData();
    }, []);

    useImperativeHandle(ref, () => ({
        step: activeStep,
        next,
        prev
    }));

    useEffect(() => {
        filterBySkus(skuFilter);
    }, [alertsData,skuFilter]);

    // fetching user preference data to get the default market data ==> TODO: Commenting this code, should be fixed in alerts refactoring
    // const fetchPreferenceData = async () => {
    //     const response = await getUserPreferences();
    //     response && setPrefData(response)
    // }

    function filterBySkus(skus) {
        const filteredAlertsList = alertsData?.filter(({sku}) => skus?.includes(sku));
        setFilteredAlerts(filteredAlertsList);
    }

    async function loadData() {
        try {
            setLoading(true);
            if (editData?.config && (await setSkuConfigs(editData.config))) {

                let status = JSON.parse(JSON.stringify(selectorState.selectorsStatus));
                status.forEach((val) => {
                    val.status = selectorStates.completed;
                });
                let comparisonMarketTypeValue =
                    editData.config?.marketsToWatchSelection === undefined ||
                    Object.keys(editData.config?.marketsToWatchSelection).length === 0
                        ? ''
                        : editData.config?.marketsToWatchSelection;
                setSelectorState({
                    focusProductSelections: editData.config?.productSelections,
                    focusMarketSelections: editData.config?.marketSelections,
                    selectedItems: editData.config?.selectedItems,
                    isMarketValidFormItems: editData.config?.isMarketValidFormItems,
                    focusMarketKeys: editData.config?.focusMarketKeys,
                    focusMarketsSelectedData: editData.config?.focusMarketsSelectedData,
                    characteristicFilters: editData.config?.characteristicFilters,
                    categoryAlias: editData.config?.categoryAlias,
                    id: editData?.id,
                    edit: true,
                    selectorsStatus: status,
                    notificationType: editData.config?.notificationType ?? 'just-me',
                    notificationEmail: editData.config?.notificationEmail ?? [],
                    comparisonMarketTypeSelection: comparisonMarketTypeValue,
                });
                setFromBack(true);
                // setSelectorState({edit: true});
                // renderStepUI(2);
                setActiveStep(2);

                return;
            }

            const allAlerts: any = getAllAlerts();
            const allSku = allAlerts.map(({sku}) => sku);
            if (skuFilter) {
                setSkuFilter(allSku);
            }
            allAlerts.forEach((alert) => {
                alert.subscribed = subscribedSkus.includes(alert.sku)
            });
            allAlerts.sort((a, b) => a.title.localeCompare(b.title));
            setAlertsData(allAlerts);
        } catch (err) {
            console.error(`AlertsList - loadData error - 'err' ===>>`, err);
        } finally {
            setLoading(false);
        }
    }

    const statusLabels = (status) => (
        <div className={classnames('story-selector__status', `story-selector__status--${status}`)}>{status}</div>
    );

    const renderCollapseExpandIcon = (isActive) => (
        <div
            className={classnames('story-selector__section-toggle', {
                'story-selector__section-toggle--open': isActive,
            })}
        />
    );

    const renderSelector = (stepNo, totalSelectorCount, selector, userData) => {
        switch (selector.selectorType) {
            case 'product':
                return (
                    <AlertProductSelector
                        selectorState={selectorState}
                        fromBack={fromBack}
                        setSelectorData={(obj) => setSelectorsData(obj)}
                        sku={selectorState?.selectedAlertDetails?.sku}
                    />
                );

            case 'market':
            case 'comparisonMarket':
                return (
                    <AlertMarketSelector
                        selectorState={selectorState}
                        fromBack={fromBack}
                        setSelectorData={(obj) => {
                            setSelectorsData(obj);
                        }}
                        type={selector.selectorType}
                        // preferenceData={prefData}  // TODO: Commenting this code, should be fixed in alerts refactoring
                    />
                );

            default:
                break;
        }
    };

    const setSelectorPanel = () => {
        const selectorPanels = JSON.parse(JSON.stringify(selectorState.selectorsStatus));
        const panelStatus =
            // alerts without markets to watch section
            selectorState?.alertConfigurations?.marketSelectors !== undefined &&
            selectorState?.alertConfigurations?.marketSelectors?.requireComparisonMarketType &&
            selectorState?.comparisonMarketTypeSelection !== '';

        selectorPanels.push({
            labelName: selectorLabels.marketsToWatch,
            status: selectorState.edit && panelStatus ? selectorStates.completed : selectorStates.pending,
            selectorType: 'comparisonMarket',
        });
        setSelectorState({ selectorsStatus: selectorPanels });
    };

    const renderSelectorPanels = () => {
        const isMarketstoWatchRequired = JSON.parse(
            JSON.stringify(selectorState?.alertConfigurations?.marketSelectors?.requireComparisonMarketType ?? false)
        );

        //check if markets to watch panel is required and add
        if (isMarketstoWatchRequired && selectorState.selectorsStatus.length !== 3) {
            setSelectorPanel();
        }

        let makeMarketPanelPending =
            // old assortment alerts without comparative markets
            selectorState?.alertConfigurations?.marketSelectors !== undefined &&
            selectorState?.alertConfigurations?.marketSelectors?.storeAllMarketData &&
            selectorState?.selectorsStatus[1].status === selectorStates.completed &&
            selectorState?.alertConfigurations?.marketSelectors?.comparativeMarketsRequired &&
            (!selectorState?.focusMarketsSelectedData?.length);
        if (makeMarketPanelPending) {
            selectorState.selectorsStatus[1].status = selectorStates.pending;
        }
        
        return (
            <div className={`${baseClassName}__options`}>
                {/* @ts-ignore */}
                <Collapse
                    activeKey={selectorState.activeCollapsePanel}
                    onChange={(index) => collapseClick(index)}
                    expandIcon={({isActive}) => renderCollapseExpandIcon(isActive)}
                    accordion
                >
                    {selectorState.selectorsStatus &&
                        selectorState.selectorsStatus.map((selector, stepNo) => {
                            return (<>
                                {/* @ts-ignore */}
                                <Panel
                                    header={selector.labelName}
                                    key={stepNo}
                                    extra={statusLabels(selectorState.selectorsStatus[stepNo]?.status)}
                                    collapsible={stepNo !== 0 && selectorState.selectorsStatus[stepNo - 1]?.status === selectorStates.pending ? "disabled" : undefined}
                                    forceRender={true}
                                >
                                    {/* @ts-ignore */}
                                    {renderSelector(stepNo, selectorState.selectorsStatus.length, selector)}
                                </Panel>
                            </>);
                        })}
                </Collapse>
            </div>
        );
    };

    const renderStepUI = (activeStepNumber) => {
        switch (activeStepNumber) {
            case 1:
                return renderAlertsList();
            case 2:
                return renderSelectorPanels();
            case 3:
                return (
                    <AlertRecipientSelector
                        selectorState={selectorState}
                        setSelectorData={(obj) => {
                            setSelectorsData(obj);
                        }}
                        fromBack={fromBack}
                    />
                );

            default:
                return renderAlertsList();
        }
    };

    const next = () => {
        setActiveStep(step => Math.min(3, step + 1));
    };

    const prev = () => {

        // setActiveStep(step => Math.max(1, step - 1));

        if (activeStep === 1 || (activeStep === 2 && selectorState.edit)) {
            // onBack?.();
        } else {
            if (activeStep === 3) {
                setSelectorState({ marketTypeUpdated: false });
                setFromBack(true);
            }
            if (activeStep === 2) {
                if (fromBack) {
                    setFromBack(false);
                }
                setSelectorState(defaultSelectorValue);
            }
            setActiveStep(activeStep - 1);
        }
    };

    const setSkuConfigs = async (alert) => {
        const alertDetails = await getLegacyConfigOptionsBySku(alert?.sku);
        if (Object.keys(alertDetails).length > 0) {
            setSelectorsData({selectedAlertDetails: alert, alertConfigurations: alertDetails || {}});
            return true;
        } else {

            await warn('Configurations not found for selected alert.')
            return false;
        }
    };

    const alertSelected = async ({subscribed, sku}) => {
        if (subscribed) {
            navigate(`/dashboard/configure_alert`, {
                params: {
                    runType: 'alert',
                    sku,
                },
            });
        } else if (!isCsrObo) {
            window.open('https://store.byzzer.com/')
        }
    };

    // fetch market keys from market names 
    const getMarketKeys = async (markets) => {
        try {
            let req = {
                "markets": markets
            };
            let resp = await getMarketKeyFromMarkets(req);
            let marketKeysList = resp.map((item) => item.mrkt_key_max);
            return marketKeysList;
        } catch (err) {
            console.log("Error in fetching Market Keys ", err);
        }
    }

    const getSeparateMarkets = (allMarketData: any) => {
        let mainMarkets: any = [];
        let comparisonMarkets: any = [];
        let mainMarketKeysList: any = [];
        let comparisonMarketKeysList: any = [];

        allMarketData?.forEach((market) => {
            if (market?.marketType === marketTypeValues.REGULAR) {
                mainMarketKeysList.push(market?.mrkt_key);
                mainMarkets.push(market.name);
            } else if (market?.marketType === marketTypeValues.CHANNEL) {
                comparisonMarketKeysList.push(market?.rm_channel_mrkt_key);
                comparisonMarkets.push(market.rm_channel_mrkt_disp_name);
            } else if (market?.marketType === marketTypeValues.RETAILER) {
                comparisonMarketKeysList.push(market?.rm_xaoc_mrkt_key);
                comparisonMarkets.push(market.rm_xaoc_mrkt_disp_name);
            } else if (market?.marketType === marketTypeValues.COMPARATIVE || market?.marketType === marketTypeValues.COMPARITIVE) {
                comparisonMarketKeysList.push(market?.comparative_mrkt_key);
                comparisonMarkets.push(market.comparative_mkt_disp_name);
            }
        });
        return {mainMarkets, comparisonMarkets, mainMarketKeysList, comparisonMarketKeysList}
    };

    const alertsDTO = async (data, marketData) => {
        let marketSelectionsValue = marketData?.focusMarketSelections ?? data?.focusMarketSelections;
        let newMarketSelectionsValue = marketSelectionsValue?.length === 1 ? [marketSelectionsValue[0].split('<-')[0]] : marketSelectionsValue;
        const dataObject: any = new Object();
        if (data?.edit) {
            dataObject.id = data?.id;
        }
        dataObject.title = data?.selectedAlertDetails?.title;
        dataObject.sku = data?.selectedAlertDetails?.sku;
        dataObject.productSelections = data?.focusProductSelections;
        dataObject.categoryAlias = data?.categoryAlias;
        dataObject.marketSelections = newMarketSelectionsValue;
        dataObject.characteristicFilters = data?.characteristicFilters;
        dataObject.characteristicsFiltersData = data?.characteristicsFiltersData;
        dataObject.focusMarketsSelectedData = data.focusMarketsSelectedData;
        dataObject.selectedItems = data?.selectedItems;
        dataObject.isMarketValidFormItems = data?.isMarketValidFormItems;
        dataObject.marketsToWatchSelection =
            data?.comparisonMarketTypeSelection !== '' ? data?.comparisonMarketTypeSelection : {};
        
        if (data?.focusMarketsSelectedData?.length > 0) {
            // New assortment alerts only 
            let {mainMarkets, comparisonMarkets, mainMarketKeysList, comparisonMarketKeysList} = getSeparateMarkets(data?.focusMarketsSelectedData);
            dataObject.mainMarkets = mainMarkets;
            dataObject.comparisonMarkets = comparisonMarkets;
            dataObject.mainMarketKeys = mainMarketKeysList;
            dataObject.comparisonMarketKeys = comparisonMarketKeysList;
            dataObject.focusMarketKeys = [...mainMarketKeysList, ...comparisonMarketKeysList];
        } else {
            // other alerts 
            let focusMarketKeysList = await getMarketKeys(newMarketSelectionsValue);
            dataObject.focusMarketKeys = focusMarketKeysList;
        }
        return dataObject;
    };

    const setSelectorsData = (dataObj) => {
        setSelectorState(dataObj);
    };

    const createAlert = async (dataObj) => { // pizza
        setLoading(true);
        const emailAlertsData = await alertsDTO(selectorState, dataObj);
        const configObject = {
            emailAlerts: [emailAlertsData],
            alertRecipients: {
                notificationType: selectorState.notificationType,
                notificationEmail: selectorState?.notificationEmail?.filter(
                    (item) => item !== selectorState.userEmail[0]
                ),
                userEmail: selectorState.userEmail,
            },
        }
        try {
            let alertCreate;
            if (selectorState.edit) {
                // @ts-ignore
                alertCreate = await updateAlerts(configObject);
            } else {
                // @ts-ignore
                alertCreate = await createAlerts(configObject);
            }
            if (alertCreate) {
                // onComplete?.()
            }
        } catch (err) {
        }
        setLoading(false);
    };

    const renderSmartTag = (alertDetail) => {
        if (alertDetail.sku) {
            const getAlertConfigs = alertConfigs.find((val) => val.productSku === alertDetail.sku);
            if (getAlertConfigs) {
                if (getAlertConfigs.alertType === 'Smart') {
                    return 'Smart';
                }
            }
        }
        return ''
    };

    const renderAlertsList = () => {
        return (
            <div className='alert-groups'>
                {selectedAlert?.title && (
                    <ByzzerModal
                        show={alertInformationView}
                        onClose={() => setAlertInformationView(!alertInformationView)}
                        heading={selectedAlert?.title}
                        size={'large'}
                        headerType={'normal'}
                    >
                        {/* @ts-ignore */}
                        <ReportInformation
                            sku={selectedAlert?.sku}
                            url={selectedAlert?.detailsUrl || `${alertsDescriptionUrl}/${selectedAlert?.sku}.html`}
                        />
                    </ByzzerModal>
                )}
                <div className={'alerts-list-grid-container'} key={filteredAlerts.length}>
                    {filteredAlerts.map((item, index, arrData) => {
                        const itemLocked = (
                            !item.subscribed ||
                            !canAddAlerts || 
                            !subscription?.active ||
                            user?.role === 'viewer'
                        );
                        return (
                            item.sku !== '453' && (
                                <ByzzerCard
                                    key={item.sku}
                                    onClick={() => alertSelected(item)}
                                    shouldTrackClick={true}
                                    trackClickName={`My Reports Report (${item.title}) clicked`}
                                    title={item.title}
                                    description={item.description}
                                    thumbnailUrl={item.thumbnailUrl}
                                    thumbnailImageSize={'xs'}
                                    thumbnailAreaSize={'lg'}
                                    isLocked={itemLocked}
                                    actions={
                                        <>
                                            <TipIcon
                                                trackClick={`Report Info (${item.title}) clicked`} 
                                                className={undefined} 
                                                children={undefined} 
                                                onClick={() => {
                                                    setAlertInformationView(!alertInformationView);
                                                    setSelectedAlert(item);
                                                }}
                                            />
                                        </>
                                    }
                                    badges={[renderSmartTag(item)]}
                                />
                            )
                        );
                    })}
                </div>
            </div>
        );
    };

    let extras = (
        <div className="alerts-list__back-button">
            <div className="alerts-list__back">
                <div className="alerts-list__back-container" onClick={prev}>
                    <div className="alerts-list__back-container-image">
                        <img src={back} alt="back"/>
                    </div>
                    <div className="alerts-list__back-container-text">Back</div>
                </div>
            </div>
        </div>
    );

    const subTitle = useMemo(() => {
        return (
            <>
                <div className="alerts-list__stepper">
                    <div className="alerts-list__stepper-steps">
                        <ByzzerStepper>
                            {Steps.map((label, index) => {
                                const indexVal = index + 1;
                                return (
                                    // @ts-ignore
                                    <ByzzerStep
                                        key={index}
                                        stepNumber={indexVal}
                                        active={activeStep === indexVal}
                                        done={activeStep > indexVal}
                                    ></ByzzerStep>
                                );
                            })}
                        </ByzzerStepper>
                    </div>
                </div>
            </>
        );
    }, [activeStep]);

    const nextButtonValidation = () => {
        return selectorState?.selectorsStatus.some((item) => item.status !== selectorStates.completed);
    };

    const createButtonValidation = () => {
        return selectorState?.notificationEmail?.length === 0
    };

    function handlePurchaseMoreAlerts() {
        if (!isCsrObo) {
            window.open('https://store.byzzer.com/')
        }
    }

    function alertFiltersShowHide() {
        setShowAlertFilters((current) => !current);
    }

    const showFiltersPanel = !isMobile;

    return <div className={classnames(baseClassName)}>
        <ByzzerMask loading={loading}/>
        <header className={`${baseClassName}__header`}>
            <div className={`${baseClassName}__header-text`}>
                <h2 className={`${baseClassName}__step-title`}>{Steps[activeStep - 1].title}</h2>
                <div className={'alert-configuration-heading__description'}>
                    Your subscription comes with {maxTotalAlerts || 'no'} weekly
                    alert{maxTotalAlerts === 1 ? '' : 's'}.  Configure your alerts to start 
                    receiving them right away - this includes selecting which types of alerts to receive, and 
                    setting the data scope for each of them.

                    <br/>
                    {canAddAlerts &&
                        `You can configure ${maxTotalAlerts - totalConfiguredAlerts} additional alert${maxTotalAlerts - totalConfiguredAlerts === 1 ? '' : 's'}.`
                    }
                </div>
            </div>

            {!canAddAlerts && (
                <div className={classnames(`${baseClassName}__purchase-button`)}>
                    <LegacyByzzerButton
                        label={Boolean(maxTotalAlerts) ? 'Purchase More Alerts' : 'Purchase Alerts'}
                        onClick={handlePurchaseMoreAlerts}
                    />
                </div>
            )}
        </header>

        <div className={classnames(`${baseClassName}__step`, 'alerts-list-container')}>
            {renderStepUI(activeStep)}
            {showFiltersPanel&& (
                <StackedReportFilters 
                    onFilter={setSkuFilter}
                    appliesTo='alert'
                    className="alerts-list-options-wrapper"
                    changeReport={false}
                />
            )}
        </div>
        <div className={`${baseClassName}__actions`}>
            {activeStep > 1 && (
                <LegacyByzzerButton
                    label={'Back'}
                    onClick={prev}
                />
            )}
            {activeStep === 2 && (
                <LegacyByzzerButton
                    label={'Next step'}
                    onClick={next}
                    disabled={nextButtonValidation()}
                />
            )}
            {activeStep === 3 && (
                <LegacyByzzerButton
                    label={`${selectorState.edit ? 'Update Alert' : 'Add Alert'}`}
                    // @ts-ignore
                    onClick={() => createAlert()}
                    disabled={createButtonValidation()}
                />
            )}
        </div>
    </div>
});

export default AlertsList;
