import { useUser } from '@/contexts/UserContext';
import { ReactNode, useMemo } from 'react';
import { addDays, differenceInWeeks, format as formatDate } from 'date-fns';
import { timePeriodToString } from '@/services/timePeriod.service';
import { TimePeriod } from '@/utils/timePeriod/TimePeriod';
import { TimePeriodRange } from '@/utils/timePeriod/TimePeriodRange';
import { ByzzerSelectOption } from '@byzzer/ui-components';
import { formatInTimeZone } from 'date-fns-tz';

type ValueOption = {
    display?: ReactNode;
    /**
     * Used for searching and sorting.  Will be used for display if display is omitted.
     */
    text: string;
    value: string;
    disabled?: boolean;    
};

const daysMap: Record<string, number> = {
    'rms': 10,
    'cps': 17,
    'omni': 10,
};

export function useTimePeriod(dataType: datatype = 'rms') {
    const { features, maxDataDates, subscription } = useUser();

    const maxDate = useMemo<TimePeriod>(() => {
        if (maxDataDates[dataType] instanceof Date) {
            return new TimePeriod(formatDate(maxDataDates[dataType]!, 'yyyyMMdd'));
        }
        return TimePeriod.Latest;
    }, [maxDataDates]);

    const maxFutureWeeks = useMemo<number>(() => {
        const remainingSubscriptionWeeks = differenceInWeeks(new Date(subscription?.endDate!), new Date());
        return Math.max(52 * 5, remainingSubscriptionWeeks);
    }, [subscription]);

    const maxPeriods = useMemo<number>(() => {
        return 52 * (features?.extendedDataYears || 3);
    }, [features?.extendedDataYears]);

    const maxYears = useMemo<number>(() => {
        return features?.extendedDataYears || 3;
    }, [features?.extendedDataYears]);

    const earliestEpochOffset = useMemo<number>(() => {
        return maxDate.valueOf() - maxPeriods.valueOf();
    }, [maxPeriods]);

    const latestDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        for (let i = 0; i < maxYears; i++) {
            options.push(
                {
                    text: `${new TimePeriodRange(`pe:1:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:1:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pe:4:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:4:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pe:12:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:12:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pe:13:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:13:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pe:26:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:26:${i}`,
                }
            );

            options.push(
                {
                    text: `${new TimePeriodRange(`pe:52:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe:52:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pe1y:52:${i}`)} Ending ${maxDate.subtract(i * 52).toDateString()}`,
                    value: `pe1y:52:${i}`,
                }
            );
        }

        return options;
    }, [maxDate, maxPeriods]);

    const previousPeriodDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        for (let i = 0; i < maxYears; i++) {
            options.push(
                {
                    text: `${new TimePeriodRange(`pp:1:${i}`)} Ending ${maxDate.subtract(i * 52 + 1).toDateString()}`,
                    value: `pp:1:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pp:4:${i}`)} Ending ${maxDate.subtract(i * 52 + 4).toDateString()}`,
                    value: `pp:4:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pp:12:${i}`)} Ending ${maxDate.subtract(i * 52 + 12).toDateString()}`,
                    value: `pp:12:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pp:13:${i}`)} Ending ${maxDate.subtract(i * 52 + 13).toDateString()}`,
                    value: `pp:13:${i}`,
                },
                {
                    text: `${new TimePeriodRange(`pp:26:${i}`)} Ending ${maxDate.subtract(i * 52 + 26).toDateString()}`,
                    value: `pp:26:${i}`,
                }
            );

            options.push({
                text: `${new TimePeriodRange(`pp:52:${i}`)} Ending ${maxDate.subtract(i * 52 + 52).toDateString()}`,
                value: `pp:52:${i}`,
            });
        }

        return options;
    }, [maxDate, maxPeriods]);

    const weekEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < 52 * maxYears; i++) {
            const timePeriod = new TimePeriod(lastTpIndex - i);
            if (timePeriod.isFullyCovered('week', earliestEpochOffset)) {
                options.push({
                    text: `1 Week Ending ${new TimePeriod(lastTpIndex - i).toDateString()}`,
                    value: `1w:1:${lastTpIndex - i}`,
                });
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const fourWeeksEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < 52 * maxYears; i += 4) {
            const timePeriod = new TimePeriod(lastTpIndex - i);
            if (timePeriod.isFullyCovered('4weeks', earliestEpochOffset)) {
                options.push({
                    text: `4 Weeks Ending ${new TimePeriod(lastTpIndex - i).toDateString()}`,
                    value: `4w:4:${lastTpIndex - i}`,
                });
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const fourWeeksStaticEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.getOffsetToNearestFourWeekStaticPeriod();

        for (let i = 0; i < 52 * maxYears; i += 4) {
            const timePeriod = new TimePeriod(lastTpIndex - i);
            if (timePeriod.isFullyCovered('4weeks', lastTpIndex - maxPeriods.valueOf())) {
                options.push({
                    text: `4 Weeks Ending ${new TimePeriod(lastTpIndex - i).toDateString()}`,
                    value: `4s:4:${lastTpIndex - i}`,
                });
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const monthEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < 52 * maxYears; i++) {
            if (TimePeriod.is445(lastTpIndex - i)) {
                const timePeriod = new TimePeriod(lastTpIndex - i);
                if (timePeriod.isFullyCovered('month', earliestEpochOffset)) {
                    const weeks = TimePeriod.is4455(lastTpIndex - i) ? 5 : 4;
                    const value = `1m:${weeks}:${lastTpIndex - i}`;
                    options.push({
                        text: timePeriodToString(value),
                        value,
                    });
                }
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const quarterEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < 52 * maxYears; i++) {
            if ((lastTpIndex - i) % 13 === 0) {
                const timePeriod = new TimePeriod(lastTpIndex - i);
                if (timePeriod.isFullyCovered('quarter', earliestEpochOffset)) {
                    const value = `1q:13:${lastTpIndex - i}`;
                    options.push({
                        text: timePeriodToString(value),
                        value,
                    });
                }
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const yearEndingDates = useMemo<ValueOption[]>(() => {
        const options: ValueOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < 52 * maxYears; i++) {
            const timePeriod = new TimePeriod(lastTpIndex - i);
            if (timePeriod.isYearEnd() && timePeriod.isFullyCovered('year', earliestEpochOffset)) {
                const value = `1y:52:${lastTpIndex - i}`;
                options.push({
                    text: timePeriodToString(value),
                    value,
                });
            }
        }

        return options;
    }, [maxDate, maxPeriods]);

    const futureWeeklyOptions = useMemo<ByzzerSelectOption[]>(() => {
        return generateFutureTimePeriodOptions(() => true);
    }, [maxDate]);

    const futureQuarterlyOptions = useMemo<ByzzerSelectOption[]>(() => {
        return generateFutureTimePeriodOptions((tp) => tp.valueOf() % 13 === 0);
    }, [maxDate]);

    const future445Options = useMemo<ByzzerSelectOption[]>(() => {
        return generateFutureTimePeriodOptions((tp) => tp.is445());
    }, [maxDate]);

    const future444Options = useMemo<ByzzerSelectOption[]>(() => {
        return generateFutureTimePeriodOptions((tp) => tp.valueOf() % 4 === 0);
    }, [maxDate]);

    /**
     * Common function that generates selection option that match a criteria
     * @param filter
     */
    function generateFutureTimePeriodOptions(filter: (timePeriod: TimePeriod) => boolean): ByzzerSelectOption[] {
        const options: ByzzerSelectOption[] = [];
        const lastTpIndex = maxDate.valueOf();

        for (let i = 0; i < maxFutureWeeks; i++) {
            const timePeriod = new TimePeriod(lastTpIndex + i);
            if (filter(timePeriod)) {
                const endDate = timePeriod.toGmtDate();
                const availableOn = addDays(endDate, daysMap[dataType]);
                options.push({
                    display: `${formatInTimeZone(endDate, 'GMT', 'MM-dd-yyyy')} available ${formatInTimeZone(
                        availableOn,
                        'GMT',
                        'MM-dd-yyyy'
                    )}`,
                    value: timePeriod.toString(),
                });
            }
        }

        return options;
    }

    return {
        latestDates,
        weekEndingDates,
        fourWeeksEndingDates,
        monthEndingDates,
        quarterEndingDates,
        yearEndingDates,
        future444Options,
        future445Options,
        futureWeeklyOptions,
        futureQuarterlyOptions,
        maxFutureWeeks,
        fourWeeksStaticEndingDates,
        previousPeriodDates,
    };
}
