import './ByzzerSearchableSelect.scss';
import React, { useEffect, useRef, useState } from 'react';
import { LegacyByzzerCheckbox, LegacyByzzerRadio, ByzzerModal } from '@/components/form/index';
import LegacyByzzerSearch from '@/components/form/LegacyByzzerSearch';
import classNames from 'classnames';
import { useClickOutsideHandler } from '@/utils/utils';
import ByzzerLink from './ByzzerLink';
import MultiSelectMarkets from '../MultiSelectMarkets';
import classnames from 'classnames';
import { selectorStates } from '@/config/globalVars';

const baseClassName = 'byzzer-searchable-select';
const searchTypesArray = ['marketSingleSearch', 'marketSearch', 'brandSearchMultiSelect', 'searchBrandForProductSetMultiSelect', 'searchItemForFocusProduct', 'upcCodeMultiSelect', 'manufactureFromCategoriesSearch', 'zipCodes'];

export function ByzzerSearchableSelect({
    label,
    name,
    value,
    placeholder,
    onChange,
    options = [],
    optionKey,
    className,
    disabled,
    enableToolTip = false,
    searchPlaceholder,
    searchOnChange,
    searchType,
    searchValue,
    categories,
    seperator,
    selectedMarkets,
    productType,
    multiBrandSelect,
    direction = 'down',
    optionalLabel = false,
    selectedMultiSelectOptions = [],
    productLevel = '',
    maxLimit = false,
    marketType = "",
    toolTipText = "",
    fromFilter = false,
    variant = 'default',
    ...props
}) {
    const ref = useRef();
    const [filter, setFilter] = useState('');
    const [selection, setSelection] = useState();
    const [expanded, setExpanded] = useState(false);
    const [$options, setOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [showProductScopeModal, updateshowProductScopeModal] = useState(false);

    const [singleMarket, setSingleMarket] = useState({
        focusMarket: '',
    });

    useEffect(() => {
        const filterRegex = new RegExp(
            filter
                ?.trim()
                ?.split(/\s+/)
                .map((v) => regEscape(v))
                .join('.*') || '.*',
            'gi'
        );
        const matcher = isAMatch(filterRegex);

        const remainingMarketFilterOption = $options.map((option, index) => {
            return (
                <div key={option.name} className={'byzzer-select-option'}>
                    <LegacyByzzerCheckbox
                        key={option.name}
                        label={option.name}
                        checked={true}
                        onChange={() => handleRemainingMarketCheckboxChange(option, index)}
                    />
                </div>
            );
        });

        const filteredOptions = $options.filter(matcher).map((option, index) => {
            if (
                searchTypesArray?.includes(searchType)
            ) {
                return (
                    <div key={option.value} className={'byzzer-select-option'}>
                        {(productType !== 'story' || (productType === 'story' && multiBrandSelect)) && (
                            <LegacyByzzerCheckbox
                                key={option.value}
                                label={option.display}
                                checked={true}
                                onChange={() => handleMarketCheckboxChange(option, index)}
                            />
                        )}
                        {productType === 'story' && (searchType === 'manufactureFromCategoriesSearch' || !multiBrandSelect) && (
                            <LegacyByzzerRadio
                                key={option.value}
                                label={option.display}
                                checked={true}
                                onChange={() => handleMarketCheckboxChange(option, index)}
                            />
                        )}
                    </div>
                );
            } else if (searchType === 'marketSingleSearch' && !props.remainingMarkets) {
                return null;
            } else {
                return (
                    <div
                        key={optionKey ? option.value?.[optionKey] : option.value}
                        className={'byzzer-select-option'}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option.display}
                    </div>
                );
            }
        });
        if (props.remainingMarkets) {
            setFilteredOptions(remainingMarketFilterOption);
        } else {
            setFilteredOptions(filteredOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, $options]);

    useEffect(() => {
        if (props.remainingMarkets) {
            let optionList = [];
            optionList = options;
            setSelection(optionList);
            setOptions(optionList);
        } else {
            let optionList;
            if (options?.[0]?.hasOwnProperty('display')) {
                optionList = options;
            } else {
                if (!Array.isArray(options)) options = [options];
                optionList = options.map((v) => ({
                    display: v,
                    value: v,
                }));
            }
            setSelection(optionList.find((option) => option.value === value));
            setOptions(optionList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, value]);
    useClickOutsideHandler(ref, () => {
        setExpanded(false);
    });

    const handleMarketCheckboxChange = (market, index) => {
        onChange(market.value, index);
    };
    const handleRemainingMarketCheckboxChange = (market) => {
        onChange(market);
    };

    function handleOptionClick(selection) {
        setFilter('');
        setSelection(selection);

        if (onChange) {
            onChange(selection?.value);
        }
    }

    function toggle() {
        setExpanded(!expanded);
    }

    function updateFilter({ target }) {
        setExpanded(true);
        setFilter(target.value);
    }

    function isAMatch(filterRegex) {
        return (entry) => {
            return !filter || filterRegex.test(entry.display);
        };
    }

    function regEscape(str) {
        return str.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
    }

    function openMarketList() {
        updateshowProductScopeModal(true);
    }

    function submitMarketSelector(obj, e) {
        if (obj?.status === selectorStates.completed) {
            if (searchOnChange) {
                if (searchType === 'marketSingleSearch') {
                    searchOnChange(obj?.focusMarketSelections[0] || []);
                    setExpanded(!expanded);
                } else {
                    if (onChange) {
                        onChange(obj?.focusMarketSelections || [], 'multi');
                    }
                }
            }
            updateshowProductScopeModal(false);
        } else {
            if (searchType === 'marketSingleSearch') {
                setSingleMarket(obj);
            }
        }
    }

    return (
        <div
            className={classNames(baseClassName, 'byzzer-select', className, {
                'byzzer-select--expanded': expanded,
                'byzzer-select--disabled': disabled,
                'byzzer-select--has-value': $options.length,
            })}
            ref={ref}
        >
            {label && (
                <div className={'byzzer-select__label'}>
                    {label}
                    {optionalLabel && <span> (Optional)</span>}
                </div>
            )}
            <label className={classnames(`${baseClassName}__control byzzer-select__control`, {
                [`${baseClassName}__control--${variant}`]: variant
            })}>
                <input
                    type={'text'}
                    name={name}
                    className={classnames('byzzer-select__input')}
                    onChange={updateFilter}
                    disabled={disabled}
                    onClick={toggle}
                    placeholder={
                        props.remainingMarkets
                            ? $options.length > 0
                                ? $options.length === 1
                                    ? $options[0].name
                                    : $options[0].name + ', +' + ($options.length - 1)
                                : placeholder
                            : searchType === 'marketSearch'
                                ? $options.length > 0
                                    ? $options.length === 1
                                        ? $options[0].value
                                        : $options[0].value + ', +' + ($options.length - 1)
                                    : placeholder
                                : searchType === 'marketSingleSearch'
                                    ? $options.length > 0
                                        ? $options[0].value
                                        : placeholder
                                    : selection?.display || placeholder
                    }
                    value={filter}
                    autoCorrect={'off'}
                    autoComplete={'off'}
                    spellCheck={'off'}
                    readOnly
                />
                {
                    enableToolTip && toolTipText && (
                        <span className="tooltiptext">
                            {toolTipText}
                        </span>
                    )
                }
                {enableToolTip && !toolTipText && (selection?.display || placeholder) && (
                    <span className="tooltiptext">
                        {selection?.display
                            ? seperator
                                ? selection?.display?.join
                                    ? selection?.display?.join(' ' + seperator + ' ')
                                    : []
                                : selection?.display
                            : seperator
                                ? placeholder?.join
                                    ? placeholder?.join(' ' + seperator + ' ')
                                    : []
                                : placeholder}
                    </span>
                )}
                <div className={'byzzer-select__trigger'} />
                <div className={classnames('byzzer-select-options', `byzzer-select__options--open-${direction}`)}>
                    <div className={'byzzer-select-options__scrollarea'}>{filteredOptions}</div>
                    <LegacyByzzerSearch
                        placeholder={searchPlaceholder}
                        onChange={searchOnChange}
                        type={searchType}
                        value={searchValue}
                        remainingMarkets={props.remainingMarkets}
                        searchProps={props}
                        byzzerSearchableSelectExpanded={expanded}
                        productType={productType}
                        multiBrandSelect={multiBrandSelect}
                        categories={categories}
                        selectedMultiSelectOptions={options}
                        productLevel={productLevel}
                        productSet={categories}
                        maxLimit={maxLimit}
                        marketType={marketType}
                    />
                    {(searchType === 'marketSingleSearch' || searchType === 'marketSearch') && (
                        <ByzzerLink label={'View Full Market List'} onClick={openMarketList} />
                            )}
                </div>
                {showProductScopeModal && (
                    <ByzzerModal
                        show={showProductScopeModal}
                        onClose={() => updateshowProductScopeModal(false)}
                        size={'small'}
                        type={'info'}
                        headerType={'none'}
                        className={'multi-select-markets__popup'}
                    >
                        <MultiSelectMarkets
                            folder={marketType}
                            reportType="subscription"
                            marketGroup="rms"
                            setSelectorsData={(obj) => submitMarketSelector(obj, props)}
                            from="reportSelector"
                            fromFilter={fromFilter}
                            selectorState={{
                                focusMarket: singleMarket?.focusMarket,
                                focusProductSelections: {
                                    competitiveProductSet: {
                                        categories: categories,
                                    },
                                },
                            }}
                            multiSelect={searchType !== 'marketSingleSearch'}
                            saveBtnType={'scorecardSelector'}
                            subSelectors={{
                                markets: {
                                    marketSections: {
                                        embodyTotalMarkets: true,
                                        embodyFmcgRetailers: true,
                                        embodySpecialityRetailers: true,
                                        embodyGeographyMarkets: true,
                                        subMarketType: marketType
                                    },
                                },
                            }}
                            actionConfig={{
                                focusMarket: singleMarket?.focusMarket,
                                focusProductSelections: {
                                    competitiveProductSet: {
                                        categories: categories,
                                    },
                                },
                                focusMarketSelections: {
                                    focusMarket: {
                                        byMarketName: {
                                            markets: searchValue,
                                        },
                                    },
                                },
                            }}
                        />
                    </ByzzerModal>
                )}
            </label>
        </div>
    );
}

export default ByzzerSearchableSelect;
