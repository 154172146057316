// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { useTenantApi } from '@/hooks/useTenantApi';
import classnames from 'classnames';
import Loader from './Spin';
import TotalUSMarket from '@/components/MultiSelectTotalUSMarkets';
import MarketChannel from '@/components/MultiSelectMarketsByChannel';
import Geography from '@/components/MultiSelectMarketsByGeography';
import { LegacyByzzerButton, LegacyByzzerCheckbox, LegacyByzzerRadio, ByzzerErrorModal, ByzzerLink } from '@/components/form';
import { marketTypeValues, selectorLabels, selectorStates } from '@/config/globalVars';
import { totalMarketSubHeadline, subMarketSubHeadline } from '@/config/marketSelectorVars.config';
import classNames from 'classnames';
import './MultiSelectMarkets.scss';
import { DebounceInput } from 'react-debounce-input';
import searchIcon from '@images/icons/temporary/search.svg';
import closeIcon from '@images/icons/close_icon.svg';
import InlineSVG from 'react-inlinesvg';
import { Switch, Tooltip } from 'antd';
import { ByzzerInput, ByzzerModal } from '@/components/form';
import infoIcon from '@images/icons/info-black-rebrand-new.svg'; //TODO: define image src in css instead
import { useUser } from '@/contexts/UserContext';
import { checkUserSegmentPermissions } from '@/utils/extractUtil';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { storyAppendMore } from '@/utils/storiesUtil';
import { switchMarketSelector } from '@/utils/reportSelectorUtil';
import thumbsUp from '@/images/icons/marketSelector/thumbsUp.svg';
import premium from '@/images/icons/marketSelector/premium.svg';
import limitedProductScope from '@/images/icons/marketSelector/limitedProductScope.svg';
import hasExtraInfoIcon from '@/images/icons/info-black-rebrand-new.svg';
import limitedPromo from '@/images/icons/marketSelector/limitedPromotion.svg';
import { MrktEntitlementType } from '@/utils';
import MarketSelectionLayoutOption, { ViewEnums, VisibilityEnums } from '@/views/Extract/Market/MarketSelectionLayoutOption';
import { MrktInfoIconCopy } from '@/utils/MarketEntitlementMessages';
import { pickBy } from 'lodash';
/**
 * show icons for market
 */
export function displayIcon(icon, colorClass) {
    return <InlineSVG className={`hover-icons icon-colors-${colorClass}`} src={icon} />;
}

/** BYZ_7377: display info-icons to all FMCG markets and unique channels */
export function toolTipInfoIconMessage(mrktName, showIcon) {
    if (showIcon) {
        return displayIcon(hasExtraInfoIcon, 'rebrand');
    } else {
        switch (mrktName) {
            case 'Total FMCG Retailers':
                return MrktInfoIconCopy.TOTAL_FMCG_RETAILERS;
            case 'Total FMCG Retailers + Convenience':
                return MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_CONVENIENCE;
            case 'Total FMCG Retailers + Liquor + Convenience':
                return MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_LIQUOR_CONVENIENCE;
            case 'Total FMCG Retailers + Pet Stores':
                return MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_PET_STORES;
            case 'Total Pet Stores':
                return MrktInfoIconCopy.TOTAL_PET_STORES;
            case 'Total Neighborhood Pet Retail':
                return MrktInfoIconCopy.TOTAL_NEIGHBORHOOD_PET_RETAIL;
            case 'Giant Food Total':
                return MrktInfoIconCopy.GIANT_FOOD_TOTAL;
            case 'The Giant Company':
                return MrktInfoIconCopy.THE_GIANT_COMPANY;
            case 'Alliance Retail Group Total': 
            return MrktInfoIconCopy.ALLIANCE_RETAIL_GROUP_TOTAL; 

        }
    }
}

/**
 * show tooltip message
 */
export function toolTipMessage(type, company, showIcon = false, item = {}, condition = 0) {
    // logic to show limited promo tag and hover text

    let marktType = type;

    switch (marktType) {
        case 'limitedPromoData':
            if (showIcon) {
                return displayIcon(limitedPromo, 'blue');
            } else {
                return 'This market contains limited promotion data. For promotion reporting, consider using Total FMCG Retailers.';
            }
        case MrktEntitlementType.PRIOR_APPROVAL:
            if (showIcon) {
                return displayIcon(thumbsUp, 'green');
            } else {
                return 'You have prior approval to run this retailer\'s account-level data for the category(s) selected.';
            }
        case MrktEntitlementType.PARTIAL_PRIOR_APPROVAL:
            if (showIcon) {
                return displayIcon(thumbsUp, 'yellow');
            } else {
                return `You do not have prior approval to run this retailer's account-level data for all of the category(s) selected. Some category(s) will be excluded from the report.`;
            }
        // case MrktEntitlementType.NOT_PRIOR_APPROVAL:
        //     if (showIcon) {
        //         return displayIcon(thumbsUp, 'grey');
        //     } else {
        //         return 'You do not have prior approval to run this retailer\'s account-level data for all of the category(s) selected.';
        //     }

        case MrktEntitlementType.NOT_PRIOR_APPROVAL:

            if (company.substr(0, 7) === 'Walmart' || company.substr(0, 4) === 'Sams' || company.substr(0, 9) === 'Thorntons'
            ) {
                if (showIcon) {
                    return displayIcon(thumbsUp, 'grey');
                } else {
                    return `This market requires prior approval by category from the retailer followed by an incremental purchase. Please reach out to your Byzzer sales rep.`;
                }
            } else {
                if (showIcon) {
                    return displayIcon(thumbsUp, 'grey');
                } else {
                    return `This market requires prior approval by category from the retailer. Reach out to your account rep for assistance submitting a request.`;
                }
            }
        case MrktEntitlementType.REV_SHARE:
            if (showIcon) {
                return displayIcon(premium, 'green');
            } else {
                return ' You have purchased access to this premium retailer.';
            }
        case MrktEntitlementType.NOT_REV_SHARE:
            if (showIcon) {
                return displayIcon(premium, 'grey');
            } else {               
                return(
                    <>
                        <p>This is a premium market.</p>
                        <p>Contact your NIQ Representative to purchase.</p>
                    </>
                )          
            }

        case MrktEntitlementType.CATEGORY_NOT_COVERED:
            if (showIcon) {
                return displayIcon(limitedProductScope, 'grey');
            } else {
                return company + ' market does not have coverage in your selected category.';
            }

        case 'Amazon':
            if (showIcon) {
                return displayIcon(infoIcon, '');
            } else {
                return 'Amazon markets report POS data from Amazon physical stores including: Amazon Fresh, Amazon Go, Amazon Go Grocery formats, and any sales through Amazon Fresh.com and Amazon Prime Now not fulfilled by Amazon.com or Whole Foods. They do not include Amazon.com sales or any sales fulfilled through Whole Foods.com.';
            }
        case '7-Eleven Defined Geography':
            if (showIcon) {
                return displayIcon(infoIcon, '');
            } else {
                return '7-Eleven Defined Geography contains both 7-Eleven and competitive Convenience Retailers';
            }
        case 'SuperValu':
            if (showIcon) {
                return displayIcon(infoIcon, '');
            } else {
                return 'The UNFI Total market includes Cub and Shopper Food Warehouse, as well as independent stores that UNFI supplies to and does not actually own. For details on the UNFI Supplied Independents, reach out to customer support.'
            }
        default:
            if (type !== MrktEntitlementType.CATEGORY_NOT_COVERED) {
                if (
                    company === 'Total FMCG Retailers + Pet Stores' ||
                    company === 'Total Pet Stores' ||
                    company === 'Total Neighborhood Pet Retail'
                ) {
                    if (showIcon) {
                        return displayIcon(limitedProductScope, 'blue');
                    } else {
                        return 'Limited product scope: This market only contains data for pet-specific categories.';
                    }
                }

                if (company === 'Total FMCG Retailers + Convenience') {
                    if (showIcon) {
                        return displayIcon(limitedProductScope, 'blue');
                    } else {
                        return 'Limited product scope: This market does not contain data for random weight categories.';
                    }
                }

                if (company === 'Total Convenience Stores') {
                    if (showIcon) {
                        return displayIcon(limitedProductScope, 'blue');
                    } else {
                        return 'Limited product scope: This market does not contain data for random weight categories.';
                    }
                }

                if (
                    company === 'Total FMCG Retailers + Liquor + Convenience' ||
                    company === 'Total Liquor Plus'
                ) {
                    if (showIcon) {
                        return displayIcon(limitedProductScope, 'blue');
                    } else {
                        return 'Limited product scope: This market only contains data for alcohol categories.';
                    }
                }
            }

    }
}

export function toolTipMessageUtility(arrArg, mrktEntitlementArg, itemArg) {
    let elementsArr = arrArg;
    let baseClassName = 'multi-select-markets-by-channel';

    elementsArr.push(
        <Tooltip
            overlayInnerStyle={{ width: '300px' }}
            overlayClassName={`${baseClassName} .ant-tooltip-inner`}
            placement="bottomLeft"
            title={toolTipMessage(mrktEntitlementArg, itemArg?.name, false, itemArg, 1)}
        >
            {toolTipMessage(mrktEntitlementArg, itemArg?.name, true, itemArg, 1)}
        </Tooltip>
    );
    return elementsArr;
}

export const showTooltip = (item) => {
    //TODO: common repeated code needs to be in one method, code fine-tuning    
    const baseClassName = 'multi-select-markets-by-channel';
    let elements: any[] = [];
    let mrktEntitlement = '';

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL)) {
        mrktEntitlement = MrktEntitlementType.PRIOR_APPROVAL;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL)) {
        mrktEntitlement = MrktEntitlementType.NOT_PRIOR_APPROVAL;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.PARTIAL_PRIOR_APPROVAL)) {
        mrktEntitlement = MrktEntitlementType.PARTIAL_PRIOR_APPROVAL;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.REV_SHARE)) {
        mrktEntitlement = MrktEntitlementType.REV_SHARE;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE)) {
        mrktEntitlement = MrktEntitlementType.NOT_REV_SHARE;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_COVERED)) {
        mrktEntitlement = MrktEntitlementType.CATEGORY_COVERED;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED)) {
        mrktEntitlement = MrktEntitlementType.CATEGORY_NOT_COVERED;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.causal_block === 'Y') {
        mrktEntitlement = 'limitedPromoData';
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if ((item.master_company === 'Amazon' || item.master_company === '7-Eleven Defined Geography' || item.master_company === 'SuperValu') && item?.mrkt_type === 'Company') {
        mrktEntitlement = item.master_company;
        elements = toolTipMessageUtility(elements, mrktEntitlement, item);
    }

    if (item?.name === 'Total FMCG Retailers' || item?.name === 'Total FMCG Retailers + Convenience' || item?.name === 'Total FMCG Retailers + Liquor + Convenience' || item?.name === 'Total FMCG Retailers + Pet Stores' || item?.name === 'Total Pet Stores' || item?.name === 'Total Neighborhood Pet Retail'
    || item?.name === 'Giant Food Total'  || item?.name === 'The Giant Company'  || item?.name === 'Alliance Retail Group Total') { 
        elements.push(
            <Tooltip
                overlayInnerStyle={{ width: '10px' }}
                overlayClassName={`${baseClassName} .ant-tooltip-inner`}
                placement="bottomLeft"
                title={toolTipInfoIconMessage(item?.name, false)}
            >
                {toolTipInfoIconMessage('', true)}
            </Tooltip>
        );
    }

    if (elements.length > 0) {
        return elements.map((tooltipElement, index) => React.cloneElement(tooltipElement, { key: item?.name + index }));
    }
    return (
        <Tooltip
            overlayInnerStyle={{ width: '300px' }}
            overlayClassName={`${baseClassName} .ant-tooltip-inner`}
            placement="bottomLeft"
            title={toolTipMessage(mrktEntitlement, item?.name)}
        >
            {toolTipMessage(mrktEntitlement, item?.name, true, item)}
        </Tooltip>
    );
};

function MultiSelectMarkets(props) {
    const { marketLookup, dodMarketLookup, marketSearch, dodMarketSearch, marketAncestry, marketSearchByMrktType, marketSearchByAccount, getMySubscriptionUsage, getSubscriptionSpecificMarkets, getMarketKeyFromMarkets, isPriorApprovalMarket,getMarketsByAdvancedFilters,getMarketsByAdvancedFiltersForDodSelections } = useTenantApi();
    const [isLoading, setIsLoading] = useState(false);
    const [marketSearchKeyword, setMarketSearchKeyword] = useState('');
    const [marketSearchResults, setMarketSearchResults] = useState({});
    const [displayMarketTree, setDisplayMarketTree] = useState(true);
    const [searchResultMarkets, setsearchResultMarkets] = useState([]);
    const [searchResultWholeList, setSearchResultWholeList] = useState([]);
    const [isSearchMarketSelected, setIsSearchMarketSelected] = useState(false);
    const [totalUSMarket, setTotalUSMarket] = useState([]);
    const [marketChannel, setMarketChannel] = useState({});
    const [geography, setGeography] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState([]);
    const selectedMarketRef = useRef<any>();
    selectedMarketRef.current = selectedMarket;
    const [focusMarketsData, setFocusMarketsData] = useState([]);
    const [comparisonMarketsData, setComparisonMarketsData] = useState([]);
    const comparisonMarketsDataRef = useRef<any>();
    comparisonMarketsDataRef.current = comparisonMarketsData;
    const [refreshComparisonMarketStatus, setRefreshComparisonMarketStatus] = useState(false);
    const refreshComparisonMarketStatusRef = useRef<any>();
    refreshComparisonMarketStatusRef.current = refreshComparisonMarketStatus;
    const [selectedRemainingMarket, setSelectedRemainingMarket] = useState([]);
    const [isMarketSelected, setIsMarketSelected] = useState(false);
    const [fmLimit, setfmLimit] = useState(0);
    const [rmLimit, setrmLimit] = useState(0);
    const [searchError, setSearchError] = useState(false);
    const [isValidFormItems, setIsValidFormItems] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const subSelectorTypeVal = props.subSelectors?.subSelectorType || '';
    const [showRemainingMarkets, setRemainingMarketsVisibility] = useState(
        props?.subSelectors?.markets?.requiredRemMarketForEachRetailer ? true : false
    );
    const [showWarning, setShowWarning] = useState(false);
    const [showWarning1, setShowWarning1] = useState(false);
    const [isWarning1Displayed, setIsWarning1Displayed] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningMessage1, setWarningMessage1] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [contentType1, setContentType1] = useState('warning');
    const [focusMarketAlias, setFocusMarketAlias] = useState('');
    const [selectedMarketItemLvlVal, setSelectedMarketItemLvlVal] = useState({});
    const [showUpdate, setShowUpdate] = useState(false);
    const [statusInfo, setStatusInfo] = useState(false);
    const [limitedMarketSubscription, setLimitedMarketSubscription] = useState(false);
    const { user, subscription, features, categories: subscriptionCategories, company } = useUser();
    const userSegment = company?.accessLevel.toLowerCase();
    const noMatchMessage = limitedMarketSubscription
        ? 'No markets to list.'
        : 'No match found. Please refine your search.';
    const alertWithAllMarketData =
        props.saveBtnType === 'alertSelector' &&
        props?.selectorState?.alertConfigurations?.marketSelectors?.storeAllMarketData;
    const retainAlertData = props?.actionConfig?.edit || props?.fromBack;
    const [selectedMasterCompany, setSelectedMasterCompany] = useState('');
    const isMultiMarketsStory = props?.subSelectors?.selectorFunction === 'multiMarketsStory' ?? false;
    const { isSubMarket } = props;
    const [layout, setLayout] =
        useState({ view: ViewEnums.TreeView, marketOptions: [] });
    const [allMarketList, setAllMarketList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [allSelectedMarkets, setAllSelectedMarkets] = useState([]);
    // TO DO: Integrate NEW market selector module components wherever this file was referred
    const TOTAL_US = 'Total US';
    const BY_CHANNEL_FMCG = 'By Channel FMCG';
    const BY_CHANNEL_SPECIALITY = 'By Channel Speciality';
    const BY_GEOGRAPHY = 'By Geography';
    const PANEL_BY_CHANNEL = 'Panel By Channel';
    const SELECT_BY_CHANNEL = 'Select By Channel';

    useEffect(() => {
        initComponent();
        if (props.actionConfig && !props.multiSelect) {
            // #769
            if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0) {
                props.setSelectorsData({
                    focusMarket: props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets[0],
                });
                if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketKey?.keys?.length > 0) {
                    setSelectedItems([
                        {
                            name: props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets[0],
                            marketKey: props.actionConfig.focusMarketSelections?.focusMarket?.byMarketKey?.keys[0],
                        },
                    ]);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (props.refreshMarkets) {
            let currentSelectorStatusState = [...props.selectorState.selectorsStatus];
            const mrktSelIndex = currentSelectorStatusState.findIndex(
                (element) =>
                    element.selectorName === selectorLabels.focusMarket ||
                    element.selectorName === selectorLabels.focusMarkets ||
                    element.selectorName === selectorLabels.channelOutlets ||
                    element.selectorName === selectorLabels.outlets ||
                    element.selectorName === 'Select Total Market' ||
                    element.selectorName === 'Select Sub-Market' ||
                    element.labelName === selectorLabels.focusMarket
            );
            currentSelectorStatusState[mrktSelIndex].status = selectorStates.pending;
            const mktToWatchIndex = currentSelectorStatusState.findIndex(
                (element) => element.labelName === selectorLabels.marketsToWatch
            );
            if (mktToWatchIndex >= 0) {
                currentSelectorStatusState[mktToWatchIndex].status = selectorStates.pending;
            }
            setShowUpdate(true);
            props.setSelectorsData({ selectorsStatus: currentSelectorStatusState, refreshMarkets: false });
            initComponent(true);
            if (
                props.actionConfig &&
                !props.multiSelect &&
                props?.productType !== 'story' &&
                props.saveBtnType !== 'alertSelector'
            ) {
                const selectedMarketsList =
                    props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || [];
                // #769
                if (selectedMarketsList.length > 0) {
                    props.setSelectorsData({
                        focusMarket: selectedMarketsList[0],
                    });
                    const selectedMarketKey =
                        props.actionConfig.focusMarketSelections?.focusMarket?.byMarketKey?.keys || [];
                    if (selectedMarketKey.length > 0) {
                        setSelectedItems([
                            {
                                name: selectedMarketsList[0],
                                marketKey: selectedMarketKey[0],
                            },
                        ]);
                    }
                }
            }

            if (props?.productType === 'story') {
                props?.setNextButtonStatus?.(true);
                setRemainingMarketsVisibility(props.subSelectors?.markets?.showRemainingMarketsEnabled); // remaining markets toggle on
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshMarkets]);

    /**
     * it will be used to retain data for extracts functionality
     */
    useEffect(() => {
        if (props.extracts) {
            retainDataAfterDeleteInExtract();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        clearFocusMarketPanel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectorState?.clearFocusMarket]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (props.refreshComparisonMarkets === true) {
            let currentSelectorStatusState = [...props.selectorState.selectorsStatus];
            if (props.productType === 'story') {
                if (props.marketType && props.marketType === 'comparisonMarket') {
                    const mrktSelIndex = currentSelectorStatusState.findIndex(
                        (element) => element.selectorName === selectorLabels.comparisonMarket
                    );
                    if (mrktSelIndex >= 0) {
                        currentSelectorStatusState[mrktSelIndex].status = selectorStates.pending;
                    }
                    setRemainingMarketsVisibility(props?.subSelectors?.markets?.showRemainingMarketsEnabled); // remaining markets toggle
                    props.setSelectorsData({
                        selectorsStatus: currentSelectorStatusState,
                        comparisonMarketSelections: {},
                        focusMarket: '',
                        refreshComparisonMarkets: false,
                    });
                    setRefreshComparisonMarketStatus(true); // panel is in refreshed state
                    let selectedMarkets =
                        props.selectorState?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets || [];
                    setSelectedMarket([]);
                    setSelectedItems([]);
                    setComparisonMarketsData([]); // clear comparisonMarketsData on refresh
                    let isMarketSelected = selectedMarkets?.length > 0 ? true : false;
                    setIsMarketSelected(isMarketSelected);
                    if (props.subSelectors?.markets?.marketBehavior?.preSelect_RemainingMarkets) {
                        preSelectComparisonRemainingMarket();
                    }
                    props.generateReport();
                    setMarketSearchKeyword('');
                    setsearchResultMarkets([]);
                    setDisplayMarketTree(true);
                }
            }
            initComponent(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.refreshComparisonMarkets]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if ((props.productType === 'story' && props.fromBack === true) || props.reportViewPage) {
            if (!props.reportViewPage) retainData(); // retain market alias only
            let selectedMarkets;
            if (
                props?.marketType === 'comparisonMarket' &&
                props.selectorState.comparisonMarketSelections?.focusMarket?.byMarketName?.markets !== undefined &&
                !props.refreshComparisonMarkets
            ) {
                selectedMarkets = props.selectorState?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets;
                let previousSelectedMarkets = [...selectedMarkets];
                setSelectedMarket(previousSelectedMarkets);
                setSelectedItemsForStoryRetention(props.selectorState?.comparisonMarketsSelectedData || []);
                let isMarketSelected = previousSelectedMarkets?.length > 0 ? true : false;
                setIsMarketSelected(isMarketSelected);
                setRemainingMarketsVisibility(props.subSelectors?.markets?.showRemainingMarketsEnabled);
                if (props.selectorState?.comparisonMarketsSelectedData?.length > 0)
                    setComparisonMarketsData(props.selectorState?.comparisonMarketsSelectedData);
                if (
                    props.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
                    props.selectorState?.comparisonMarketSelections?.isMarketValidFormItems !== undefined
                )
                    setIsValidFormItems(props.selectorState?.comparisonMarketSelections?.isMarketValidFormItems); // retains requiredRemMarketForEachRetailer condition
            }
            if (
                props?.marketType === 'focusMarket' &&
                props.selectorState.focusMarketSelections?.focusMarket?.byMarketName?.markets !== undefined &&
                !isMultiMarketsStory
            ) {
                selectedMarkets = props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets;
                let previousSelectedMarkets = [...selectedMarkets];
                setSelectedMarket(previousSelectedMarkets);
                setSelectedItemsForStoryRetention(props.selectorState?.focusMarketsSelectedData || []);
                let isMarketSelected = previousSelectedMarkets?.length > 0 ? true : false;
                setIsMarketSelected(isMarketSelected);
                if (props.selectorState?.focusMarketsSelectedData?.length > 0) {
                    setFocusMarketsData(props.selectorState?.focusMarketsSelectedData);
                }
                if (
                    props.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
                    props.selectorState?.focusMarketSelections?.isMarketValidFormItems !== undefined
                )
                    setIsValidFormItems(props.selectorState?.focusMarketSelections?.isMarketValidFormItems); // retains requiredRemMarketForEachRetailer condition
                if (props.selectorState?.focusMarketSelections?.showRemainingMarkets !== undefined)
                    setRemainingMarketsVisibility(props.selectorState?.focusMarketSelections?.showRemainingMarkets);
            }
            if (
                props?.marketType === 'focusMarket' &&
                props.selectorState.marketPerformanceSelections?.focusMarket?.byMarketName?.markets !== undefined &&
                isMultiMarketsStory
            ) {
                selectedMarkets = props.selectorState?.marketPerformanceSelections?.focusMarket?.byMarketName?.markets || [];
                let previousSelectedMarkets = [...selectedMarkets];
                setSelectedMarket(previousSelectedMarkets);
                setSelectedItemsForStoryRetention(props.selectorState?.focusMarketsPerformanceSelectedData || []);
                let isMarketSelected = previousSelectedMarkets?.length > 0 ? true : false;
                setIsMarketSelected(isMarketSelected);
                if (props.selectorState?.focusMarketsPerformanceSelectedData?.length > 0) {
                    setFocusMarketsData(props.selectorState?.focusMarketsPerformanceSelectedData);
                }
                if (
                    props.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
                    props.selectorState?.marketPerformanceSelections?.isMarketValidFormItems !== undefined
                )
                    setIsValidFormItems(props.selectorState?.marketPerformanceSelections?.isMarketValidFormItems); // retains requiredRemMarketForEachRetailer condition
            }
        }
    }, [props.fromBack, props.reportViewPage]);

    useEffect(() => {
        // Retain New Alerts
        if (alertWithAllMarketData && retainAlertData) {
            setSelectedItems(props.actionConfig?.selectedItems || []);
            let selectedMarkets = props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || [];
            setSelectedMarket(selectedMarkets);
            let isMarketSelected = selectedMarkets?.length > 0 ? true : false;
            setIsMarketSelected(isMarketSelected);
            setFocusMarketsData(props.actionConfig?.focusMarketsSelectedData || []);
            setIsValidFormItems(props.selectorState?.isMarketValidFormItems || {}); // retains requiredRemMarketForEachRetailer condition
        }
    }, [retainAlertData]);

    // this will give us selected layout options from the popover
    const setMarketLayoutSelection = (e) => {
        if (e.checked) {
            if (e.value === VisibilityEnums.ShowRemainingMarkets) {
                setRemainingMarketsVisibility(true)
            }
            setLayout(prevState => ({
                ...prevState,
                marketOptions: [...prevState?.marketOptions, e.value]
            }))
        } else {
            if (e.value === VisibilityEnums.ShowRemainingMarkets) {
                setRemainingMarketsVisibility(false)
            }
            setLayout(prevState => ({
                ...prevState,
                marketOptions: prevState.marketOptions.filter(option => option !== e.value)
            }))
        }
    }

    /**
     * 
     * @param {*} filters 
     * @param {*} init 
     * @returns 
     */
    const getAdvancedMarketsFolderList = async (categoryData) => {
        // console.log
        // default filter as Channel marketType to populate the initial list of markets
        const reqBody = pickBy({
            reportType: "subscription",
            categories: props.requestFromDod ? undefined : categoryData || [],
        })
        // setLoading(true);
        if(props.requestFromDod) {
            return await getMarketsByAdvancedFiltersForDodSelections(reqBody, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection).then((data) => {
                setAllMarketList(data?.marketDetails ?? [])
            })
        } else {
            return await getMarketsByAdvancedFilters(reqBody).then((data) => {
                setAllMarketList(data?.marketDetails ?? [])
            })
        }
    }
    const filterNonSelectableMarkets = (item) => {
        if (item.is_releasable_mrkt) {
            return true;
        }
        return false;
    }
    const filterNonPromoMarkets = (item) => {
        if (item.causal_block !== 'Y') {
            return true;
        }
        return false;
    }
    const checkIfMarketCanBeLoaded = (item) => {
        if (layout.marketOptions.includes(VisibilityEnums.HideNonPromoDataAccounts) && layout.marketOptions.includes(VisibilityEnums.HideNonSelectorMarkets)) {
            if (filterNonSelectableMarkets(item) && filterNonPromoMarkets(item)) {
                return true;
            }
            return false;
        } else if (layout.marketOptions.includes(VisibilityEnums.HideNonPromoDataAccounts)) {
            return filterNonPromoMarkets(item);
        } else if (layout.marketOptions.includes(VisibilityEnums.HideNonSelectorMarkets)) {
            return filterNonSelectableMarkets(item);
        } else {
            return true;
        }
    }
    const retainDataAfterDeleteInExtract = () => {
        let totalUSMarketTemp = JSON.parse(JSON.stringify(totalUSMarket));
        let geographyTemp = JSON.parse(JSON.stringify(geography));

        const newSelectedMarkets = props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets;
        // For Total us markets
        totalUSMarketTemp.forEach((item) => {
            if (!newSelectedMarkets.includes(item.name)) {
                if (item.selected === true) {
                    item.selected = false;
                }
            }
        });

        // for geography

        if (props.folder === 'All' || setMarketType(props.folder) === 'Account') {
            geographyTemp.forEach((val) => {
                val?.markets?.forEach((e) => {
                    if (!newSelectedMarkets.includes(e.name)) {
                        if (e.selected === true) {
                            e.selected = false;
                        }
                    } else {
                        e.selected = true;
                    }
                });
            });
        } else {
            geographyTemp.forEach((e) => {
                if (!newSelectedMarkets.includes(e.name)) {
                    if (e.selected === true) {
                        e.selected = false;
                    }
                } else {
                    e.selected = true;
                }
            });
        }

        searchResultMarkets.forEach((e) => {
            if (!newSelectedMarkets.includes(e.name)) {
                if (e.selected === true) {
                    e.selected = false;
                }
            }
        });
        if (!newSelectedMarkets || newSelectedMarkets?.length <= 0) {
            setSelectAll(false);
        }

        retainDataForMarketChannel(newSelectedMarkets);
        setTotalUSMarket(totalUSMarketTemp);
        setGeography(geographyTemp);
        checkLimit('', false, '', props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets, props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketObjects);
        setSelectedMarket(props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || []);
    };


    const setSelectedItemsForStoryRetention = (marketData) => {
        let items = [];
        let uniqueMarketName = '';
        let marketKey = '';
        let marketSelType = '';
        let marketType = '';
        marketData?.forEach((e) => {
            if (e?.marketType === marketTypeValues.CHANNEL) {
                uniqueMarketName = `${e?.rm_channel_mrkt_disp_name}$$${e?.rm_channel_mrkt_key}$$${e?.mrkt_key}`;
                marketSelType = e.mrkt_sel_typ;
                marketKey = e.rm_channel_mrkt_key;
                marketType = e.marketType;
            } else if (e?.marketType === marketTypeValues.RETAILER) {
                uniqueMarketName = `${e?.rm_xaoc_mrkt_disp_name}$$${e?.rm_xaoc_mrkt_key}$$${e?.mrkt_key}`;
                marketSelType = e.mrkt_sel_typ;
                marketKey = e.rm_xaoc_mrkt_key;
                marketType = e.marketType;
            } else if (
                e?.marketType === marketTypeValues.COMPARATIVE ||
                e?.marketType === marketTypeValues.COMPARITIVE
            ) {
                uniqueMarketName = `${e.comparative_mkt_disp_name}$$${e.comparative_mrkt_key}$$${e.mrkt_key}`;
                marketSelType = e.mrkt_sel_typ;
                marketKey = e.comparative_mrkt_key;
                marketType = e.marketType;
            } else if (e?.marketType === marketTypeValues.REGULAR) {
                uniqueMarketName = e.name;
                marketSelType = e.mrkt_sel_typ;
                marketKey = e.mrkt_key;
                marketType = e.marketType;
            }

            items.push({
                name: uniqueMarketName,
                marketSelType,
                marketKey,
                marketType,
            });
        });
        setSelectedItems(items);
    };
    const retainDataForMarketChannel = (newSelectedMarkets) => {
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        marketChannelTemp?.FMCGRetailers?.forEach(async (folders, foldersIndex, foldersArr) => {
            if (folders.companies !== undefined) {
                folders.companies.forEach(async (company) => {
                    // market
                    if (!newSelectedMarkets.includes(company.displayName)) {
                        if (company.selected === true) {
                            company.selected = false;
                        }
                    } else {
                        company.selected = true;
                    }
                    // channel
                    if (!newSelectedMarkets.includes(company.rm_channel_mrkt_disp_name)) {
                        if (company.selectedChannel === true) {
                            company.selectedChannel = false;
                        }
                    } else {
                        company.selectedChannel = true;
                    }
                    // retailer
                    if (!newSelectedMarkets.includes(company.rm_xaoc_mrkt_disp_name)) {
                        if (company.selectedRetailer === true) {
                            company.selectedRetailer = false;
                        }
                    } else {
                        company.selectedRetailer = true;
                    }
                    if (company.market !== undefined) {
                        company.market.forEach(async (marketIn) => {
                            // market
                            if (!newSelectedMarkets.includes(marketIn.name)) {
                                if (marketIn.selected === true) {
                                    marketIn.selected = false;
                                }
                            } else {
                                marketIn.selected = true;
                            }
                            // channel
                            if (!newSelectedMarkets.includes(marketIn.rm_channel_mrkt_disp_name)) {
                                if (marketIn.selectedChannel === true) {
                                    marketIn.selectedChannel = false;
                                }
                            } else {
                                marketIn.selectedChannel = true;
                            }
                            // retailer
                            if (!newSelectedMarkets.includes(marketIn.rm_xaoc_mrkt_disp_name)) {
                                if (marketIn.selectedRetailer === true) {
                                    marketIn.selectedRetailer = false;
                                }
                            } else {
                                marketIn.selectedRetailer = true;
                            }
                            if (marketIn.subFolders !== undefined) {
                                marketIn.subFolders.forEach(async (subfolder) => {
                                    // market
                                    if (!newSelectedMarkets.includes(subfolder.name)) {
                                        if (subfolder.selected === true) {
                                            subfolder.selected = false;
                                        }
                                    } else {
                                        subfolder.selected = true;
                                    }
                                    // channel
                                    if (!newSelectedMarkets.includes(subfolder.rm_channel_mrkt_disp_name)) {
                                        if (subfolder.selectedChannel === true) {
                                            subfolder.selectedChannel = false;
                                        }
                                    } else {
                                        subfolder.selectedChannel = true;
                                    }
                                    // retailer
                                    if (!newSelectedMarkets.includes(subfolder.rm_xaoc_mrkt_disp_name)) {
                                        if (subfolder.selectedRetailer === true) {
                                            subfolder.selectedRetailer = false;
                                        }
                                    } else {
                                        subfolder.selectedRetailer = true;
                                    }
                                    if (subfolder.markets !== undefined) {
                                        subfolder.markets.forEach((lvl2markets) => {
                                            // market
                                            if (!newSelectedMarkets.includes(lvl2markets.name)) {
                                                if (lvl2markets.selected === true) {
                                                    lvl2markets.selected = false;
                                                }
                                            } else {
                                                lvl2markets.selected = true;
                                            }
                                            // channel
                                            if (!newSelectedMarkets.includes(lvl2markets.rm_channel_mrkt_disp_name)) {
                                                if (lvl2markets.selectedChannel === true) {
                                                    lvl2markets.selectedChannel = false;
                                                }
                                            } else {
                                                lvl2markets.selectedChannel = true;
                                            }
                                            // retailer
                                            if (!newSelectedMarkets.includes(lvl2markets.rm_xaoc_mrkt_disp_name)) {
                                                if (lvl2markets.selectedRetailer === true) {
                                                    lvl2markets.selectedRetailer = false;
                                                }
                                            } else {
                                                lvl2markets.selectedRetailer = true;
                                            }
                                            if (lvl2markets.lvl2markets !== undefined) {
                                                lvl2markets.lvl2markets.forEach((lvl2market) => {
                                                    // market
                                                    if (!newSelectedMarkets.includes(lvl2market.name)) {
                                                        if (lvl2market.selected === true) {
                                                            lvl2market.selected = false;
                                                        }
                                                    } else {
                                                        lvl2market.selected = true;
                                                    }
                                                    // channel
                                                    if (!newSelectedMarkets.includes(lvl2market.rm_channel_mrkt_disp_name)) {
                                                        if (lvl2market.selectedChannel === true) {
                                                            lvl2market.selectedChannel = false;
                                                        }
                                                    } else {
                                                        lvl2market.selectedChannel = true;
                                                    }
                                                    // retailer
                                                    if (!newSelectedMarkets.includes(lvl2market.rm_xaoc_mrkt_disp_name)) {
                                                        if (lvl2market.selectedRetailer === true) {
                                                            lvl2market.selectedRetailer = false;
                                                        }
                                                    } else {
                                                        lvl2market.selectedRetailer = true;
                                                    }

                                                    if (lvl2market.lvl3markets !== undefined) {
                                                        lvl2market.lvl3markets.forEach((lvl3market) => {
                                                            // market
                                                            if (!newSelectedMarkets.includes(lvl3market.name)) {
                                                                if (lvl3market.selected === true) {
                                                                    lvl3market.selected = false;
                                                                }
                                                            } else {
                                                                lvl3market.selected = true;
                                                            }
                                                            // channel
                                                            if (!newSelectedMarkets.includes(lvl3market.rm_channel_mrkt_disp_name)) {
                                                                if (lvl3market.selectedChannel === true) {
                                                                    lvl3market.selectedChannel = false;
                                                                }
                                                            } else {
                                                                lvl3market.selectedChannel = true;
                                                            }
                                                            // retailer
                                                            if (!newSelectedMarkets.includes(lvl3market.rm_xaoc_mrkt_disp_name)) {
                                                                if (lvl3market.selectedRetailer === true) {
                                                                    lvl3market.selectedRetailer = false;
                                                                }
                                                            } else {
                                                                lvl3market.selectedRetailer = true;
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        marketChannelTemp?.specialtyRetailers?.forEach(async (folders, foldersIndex, foldersArr) => {
            if (folders.companies !== undefined) {
                folders.companies.forEach(async (company) => {
                    // market
                    if (!newSelectedMarkets.includes(company.displayName)) {
                        if (company.selected === true) {
                            company.selected = false;
                        }
                    } else {
                        company.selected = true;
                    }
                    // channel
                    if (!newSelectedMarkets.includes(company.rm_channel_mrkt_disp_name)) {
                        if (company.selectedChannel === true) {
                            company.selectedChannel = false;
                        }
                    } else {
                        company.selectedChannel = true;
                    }
                    // retailer
                    if (!newSelectedMarkets.includes(company.rm_xaoc_mrkt_disp_name)) {
                        if (company.selectedRetailer === true) {
                            company.selectedRetailer = false;
                        }
                    } else {
                        company.selectedChannel = true;
                    }
                    if (company.market !== undefined) {
                        company.market.forEach(async (marketIn) => {
                            // market
                            if (!newSelectedMarkets.includes(marketIn.name)) {
                                if (marketIn.selected === true) {
                                    marketIn.selected = false;
                                }
                            } else {
                                marketIn.selected = true;
                            }
                            // channel
                            if (!newSelectedMarkets.includes(marketIn.rm_channel_mrkt_disp_name)) {
                                if (marketIn.selectedChannel === true) {
                                    marketIn.selectedChannel = false;
                                }
                            } else {
                                marketIn.selectedChannel = true;
                            }
                            // retailer
                            if (!newSelectedMarkets.includes(marketIn.rm_xaoc_mrkt_disp_name)) {
                                if (marketIn.selectedRetailer === true) {
                                    marketIn.selectedRetailer = false;
                                }
                            } else {
                                marketIn.selectedRetailer = true;
                            }
                            if (marketIn.subFolders !== undefined) {
                                marketIn.subFolders.forEach(async (subfolder) => {
                                    // market
                                    if (!newSelectedMarkets.includes(subfolder.name)) {
                                        if (subfolder.selected === true) {
                                            subfolder.selected = false;
                                        }
                                    } else {
                                        subfolder.selected = true;
                                    }
                                    // channel
                                    if (!newSelectedMarkets.includes(subfolder.rm_channel_mrkt_disp_name)) {
                                        if (subfolder.selectedChannel === true) {
                                            subfolder.selectedChannel = false;
                                        }
                                    } else {
                                        subfolder.selectedChannel = true;
                                    }
                                    // retailer
                                    if (!newSelectedMarkets.includes(subfolder.rm_xaoc_mrkt_disp_name)) {
                                        if (subfolder.selectedRetailer === true) {
                                            subfolder.selectedRetailer = false;
                                        }
                                    } else {
                                        subfolder.selectedRetailer = true;
                                    }
                                    if (subfolder.markets !== undefined) {
                                        subfolder.markets.forEach((lvl2markets) => {
                                            // market
                                            if (!newSelectedMarkets.includes(lvl2markets.name)) {
                                                if (lvl2markets.selected === true) {
                                                    lvl2markets.selected = false;
                                                }
                                            } else {
                                                lvl2markets.selected = true;
                                            }
                                            // channel
                                            if (!newSelectedMarkets.includes(lvl2markets.rm_channel_mrkt_disp_name)) {
                                                if (lvl2markets.selectedChannel === true) {
                                                    lvl2markets.selectedChannel = false;
                                                }
                                            } else {
                                                lvl2markets.selectedChannel = true;
                                            }
                                            // retailer
                                            if (!newSelectedMarkets.includes(lvl2markets.rm_xaoc_mrkt_disp_name)) {
                                                if (lvl2markets.selectedRetailer === true) {
                                                    lvl2markets.selectedRetailer = false;
                                                }
                                            } else {
                                                lvl2markets.selectedRetailer = true;
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        setMarketChannel(marketChannelTemp);
    };
    const retainData = () => {
        if (props.productType === 'story' && props.fromBack === true) {
            // set alias only once
            let alias =
                props?.marketType === 'comparisonMarket'
                    ? props.selectorState?.comparisonMarketSelections?.marketAlias
                    : props.selectorState?.focusMarketSelections?.marketAlias;
            setFocusMarketAlias(alias);

            // set market level and name only once
            let prevSelectedData = props.selectorState?.selectedMarketItemLvlData;

            if (prevSelectedData?.marketLevel !== undefined) {
                setSelectedMarketItemLvlVal(prevSelectedData);
            }
        }
    };

    const clearFocusMarketPanel = () => {
        if (props.selectorState.clearFocusMarket) {
            // clear all values
            setSelectedItems([]);
            setIsMarketSelected(false);
            setSelectedMarket([]);
            setMarketSearchKeyword('');
            setsearchResultMarkets([]);
            setSelectedMarketItemLvlVal({});
            setIsSearchMarketSelected(false);
            setMarketSearchResults({});
            setDisplayMarketTree(true);
            setIsValidFormItems({});
            props.setSelectorsData({
                focusMarketSelections: [],
                focusMarketKeys: [],
                focusMarket: '',
                comparisonMarketTypeSelection: '',
                clearFocusMarket: false,
                clearMarketsToWatch: false
            });
        }
    };
    //Method added for Stories to filter out markets already selected as focus markets
    const filterComparisonMarket = (marketName) => {
        return props?.productType === 'story' &&
            props?.marketType === 'comparisonMarket' &&
            props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0 &&
            props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(marketName)
            ? true
            : false;
    };

    const retainTotalUSMarkets = (totalUSMarketTemp, data = {}) => {
        let allMarketData = data?.previousSelectedFilteredMarketsData?.length
            ? data?.previousSelectedFilteredMarketsData
            : getCurrentAllMarketData(true);
        let selectedMarkets = [];
        if (data?.previousSelectedMarkets?.length) {
            selectedMarkets = data?.previousSelectedMarkets;
        } else {
            if (
                props?.marketType === 'comparisonMarket' &&
                props.selectorState?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets !== undefined &&
                !props.refreshComparisonMarkets
            ) {
                selectedMarkets = props.selectorState?.comparisonMarketSelections?.focusMarket?.byMarketName?.markets;
            }
            if (
                (props?.marketType === 'focusMarket' &&
                    props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets !== undefined) ||
                (alertWithAllMarketData && retainAlertData)
            ) {
                selectedMarkets = props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets;
            }
            if (
                (props?.marketType === 'focusMarket' &&
                    props.selectorState?.marketPerformanceSelections?.focusMarket?.byMarketName?.markets !== undefined)
            ) {
                selectedMarkets = props.selectorState?.marketPerformanceSelections?.focusMarket?.byMarketName?.markets;
            }
        }

        totalUSMarketTemp.forEach((e) => {
            if (
                selectedMarkets.includes(e.name) &&
                allMarketData?.some(
                    (elem) => elem.name === e.name && elem.marketType === 'regular' && elem.selectedComparitive !== true
                )
            ) {
                e.selected = true;
            }
            if (
                selectedMarkets.includes(e.comparative_mkt_disp_name) &&
                allMarketData?.some(
                    (elem) =>
                        elem.name === e.name &&
                        elem.comparative_mkt_disp_name === e.comparative_mkt_disp_name &&
                        (elem.marketType === marketTypeValues.COMPARATIVE ||
                            elem.marketType === marketTypeValues.COMPARITIVE) &&
                        elem.selectedComparitive === true
                )
            ) {
                e.selectedComparitive = true;
            }
        });
    };

    const preSelectComparisonRemainingMarket = () => {
        let preselectedComparisonMarkets = [];
        let preselectedComparisonMarketsList = []; // holds entire market data
        let selectedItemsTemp = [];
        props.setSelectorsData({ comparisonMarketSelections: {}, focusMarket: '' });
        let focusMarketsSelectedDataTemp = JSON.parse(JSON.stringify(props.selectorState?.focusMarketsSelectedData));
        if (
            props.productType === 'story' &&
            props?.marketType === 'comparisonMarket' &&
            focusMarketsSelectedDataTemp?.length > 0 &&
            props.subSelectors?.markets?.multi
        ) {
            // selecting corresponding remaining markets
            for (let i = 0; i < focusMarketsSelectedDataTemp?.length; i++) {
                let item = focusMarketsSelectedDataTemp[i];
                if (
                    item?.rm_xaoc_mrkt_disp_name !== null &&
                    item?.rm_xaoc_mrkt_disp_name !== undefined &&
                    !filterComparisonMarket(item?.rm_xaoc_mrkt_disp_name)
                ) {
                    preselectedComparisonMarkets.push(item?.rm_xaoc_mrkt_disp_name);
                    item.marketType = marketTypeValues.RETAILER;
                    item.selectedRetailer = true;
                    item.selectedMarket = true;
                    item.uniqueMarketName = `${item?.rm_xaoc_mrkt_disp_name}$$${item?.rm_xaoc_mrkt_key}$$${item?.mrkt_key}`;
                    selectedItemsTemp.push({
                        name: item.uniqueMarketName,
                        marketKey: item?.rm_xaoc_mrkt_key,
                        marketSelType: item?.mrkt_sel_typ,
                        marketType: 'retailer',
                    });
                    preselectedComparisonMarketsList.push(item);
                } else if (
                    item?.rm_channel_mrkt_disp_name !== null &&
                    item?.rm_channel_mrkt_disp_name !== undefined &&
                    !filterComparisonMarket(item?.rm_channel_mrkt_disp_name)
                ) {
                    preselectedComparisonMarkets.push(item?.rm_channel_mrkt_disp_name);
                    item.marketType = marketTypeValues.CHANNEL;
                    item.selectedChannel = true;
                    item.uniqueMarketName = `${item?.rm_channel_mrkt_disp_name}$$${item?.rm_channel_mrkt_key}$$${item?.mrkt_key}`;
                    selectedItemsTemp.push({
                        name: item.uniqueMarketName,
                        marketKey: item?.rm_channel_mrkt_key,
                        marketSelType: item?.mrkt_sel_typ,
                        marketType: 'channel',
                    });
                    preselectedComparisonMarketsList.push(item);
                } else if (
                    item?.comparative_mkt_disp_name !== null &&
                    item?.comparative_mkt_disp_name !== undefined &&
                    !(item?.name === 'Total FMCG Retailers' && item?.mrkt_sel_typ === 'Total US')
                ) {
                    preselectedComparisonMarkets.push(item?.comparative_mkt_disp_name);
                    item.marketType = marketTypeValues.COMPARATIVE;
                    item.selectedComparitive = true;
                    item.uniqueMarketName = `${item?.comparative_mkt_disp_name}$$${item?.comparative_mrkt_key}$$${item?.mrkt_key}`;
                    selectedItemsTemp.push({
                        name: item.uniqueMarketName,
                        marketKey: item?.comparative_mrkt_key,
                        marketSelType: item?.mrkt_sel_typ,
                        marketType: 'comparative',
                    });
                    preselectedComparisonMarketsList.push(item);
                }
            }
        }
        if (preselectedComparisonMarkets?.length > 0) {
            setSelectedMarket(preselectedComparisonMarkets); // push preselected markets to const selectedMarkets array
            setSelectedItems(selectedItemsTemp); // push preselected market items to const selectedItems
            setComparisonMarketsData(preselectedComparisonMarketsList); // push preselected entire market data to const comparisonMarketsData array
            setIsMarketSelected(true);
            setShowUpdate(true);
        }
    };

    const preSelectTotalUSRemainingMarkets = (totalUSMarketTemp) => {
        let preselectedComparisonMarkets = JSON.parse(JSON.stringify(selectedMarketRef.current));
        let preselectedComparisonMarketsList = JSON.parse(JSON.stringify(comparisonMarketsDataRef.current));
        let preselectedComparisonMarketsListTemp = [];

        if (
            props.productType === 'story' &&
            props?.marketType === 'comparisonMarket' &&
            preselectedComparisonMarketsList?.length > 0 &&
            props.subSelectors?.markets?.multi
        ) {
            // selecting corresponding remaining markets
            for (let i = 0; i < preselectedComparisonMarketsList?.length; i++) {
                let item = preselectedComparisonMarketsList[i];
                totalUSMarketTemp.forEach((market) => {
                    if (
                        market?.name === item?.name &&
                        market?.comparative_mkt_disp_name === item?.comparative_mkt_disp_name &&
                        market.name !== 'Total FMCG Retailers'
                    ) {
                        market.selectedComparitive = true;
                        market.selected = false;
                        item.selectedComparitive = true;
                        item.selected = false;
                        item.marketType = marketTypeValues.COMPARATIVE;
                    }
                });
                preselectedComparisonMarketsListTemp.push(item);
            }
        }
        if (preselectedComparisonMarkets?.length > 0) {
            setComparisonMarketsData(preselectedComparisonMarketsListTemp); // push preselected entire market data to const comparisonMarketsData array
            setIsMarketSelected(true);
            setShowUpdate(true);
        }
    };


    const initComponent = (refresh = false) => {

        let categoryData = [];
        if (props.saveBtnType === 'alertSelector') {
            if (
                props?.selectorState?.focusProductSelections?.length !== undefined &&
                props?.selectorState?.focusProductSelections?.length !== 0
            ) {
                categoryData = props?.selectorState?.focusProductSelections?.map((val, indx) => {
                    if (val?.category) {
                        return val?.category;
                    }
                });
                categoryData = categoryData.flat();
            }
        } else if (props.from === 'userPref') {
            if (props?.userPrefState?.primaryCategory) {
                categoryData = props?.userPrefState?.primaryCategory;
            }
        } else {
            if (props?.selectorState?.focusProductSelections) {
                categoryData = props?.selectorState?.focusProductSelections?.competitiveProductSet?.categories || [];
            }
        }
        setSelectedCategories(categoryData);
        getAdvancedMarketsFolderList(categoryData);
        if (props.extracts) {
            if (validateAccessLevel()) {
                return true;
            }

            if (props.folder !== 'All') {
                getMarketByMarketType(props.folder, categoryData);
                return true;
            }
        }
        if (props.saveBtnType === "scorecardSelector") {
            if (props.folder !== 'All' && props.folder !== "") {
                getMarketByMarketType(props.folder, categoryData);
                return true;
            }
        }
        listMarkets(categoryData, refresh);

    };

    async function listMarkets(categoryData, refresh) {
        setIsLoading(true);
        const { markets } = await getMySubscriptionUsage();
        if (
            !props?.multiSelect &&
            typeof props?.userPrefState?.primaryMarket === 'string' &&
            props?.userPrefState?.primaryMarket !== '' && props.marketGroup !== 'omni'
        ) {
            // Preselect default marketkey
            const primaryMarketDataResp = props.requestFromDod ?
                await dodMarketSearch({
                    searchTerm: props?.userPrefState?.primaryMarket?.toLowerCase(),
                    marketType: props.marketGroup.toUpperCase() || 'RMS',
                    reportType: props.reportType,
                    ...props?.subSelectors?.markets?.marketSections,
                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                : await marketSearch({
                    searchTerm: props?.userPrefState?.primaryMarket?.toLowerCase(),
                    marketType: props.marketGroup.toUpperCase() || 'RMS',
                    reportType: props.reportType,
                    categories: selectedCategories || [],
                    ...props?.subSelectors?.markets?.marketSections,
                });
            if (primaryMarketDataResp?.data?.error === undefined && primaryMarketDataResp?.data?.length === 1) {
                const primMrktObj = primaryMarketDataResp.data[0];
                setSelectedItems([
                    {
                        name: primMrktObj.name,
                        marketKey: primMrktObj.mrkt_key,
                    },
                ]);
            }
        }
        setIsLoading(false);
        if (
            ((features.enableLimitedMarketSubscription && props.reportType !== 'adhoc') ||
                props.marketGroup === 'omni') && categoryData.length > 0
        ) {
            // #3714 - check whether account is limited markets subscription
            let searchResultMarketsTemp = [];
            let marketSearchResultsTemp = {};
            const marketRequestBody = props.marketGroup === 'omni' ? {
                reportType: props.reportType,
                categories: categoryData,
            } : {
                marketType: props.marketGroup.toUpperCase(),
                categories: categoryData,
                ...props?.subSelectors?.markets?.marketSections,
            }
            setIsLoading(true);
            const limitedMarketsResp = await getSubscriptionSpecificMarkets(props.marketGroup, marketRequestBody);
            setLimitedMarketSubscription(true);
            setDisplayMarketTree(false);
            if (!limitedMarketsResp) {
                setSearchError('Unable to fetch markets');
                setIsLoading(false);
                setMarketSearchResults({});
                setsearchResultMarkets([]);
            } else if (limitedMarketsResp?.length === 0) {
                setSearchError(true);
                setIsLoading(false);
                setMarketSearchResults({});
                setsearchResultMarkets([]);
            } else {
                marketSearchResultsTemp = [...limitedMarketsResp];
                if (marketSearchResultsTemp.length > 0) {
                    marketSearchResultsTemp.forEach((market) => {
                        searchResultMarketsTemp.push({
                            name: market.name,
                            mrkt_key: market?.mrkt_key,
                            rm_channel_mrkt_key: market?.rm_channel_mrkt_key,
                            rm_xaoc_mrkt_key: market?.rm_xaoc_mrkt_key,
                            comparative_mrkt_key: market.comparative_mrkt_key,
                            marketSelType: market.mrkt_sel_typ,
                            raw_mrkt_name: market.raw_mrkt_name,
                            selected: props.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.includes(
                                market.name
                            ),
                            selectedChannel: '',
                            selectedMarket: '',
                            selectedComparitive: '',
                            stores: market.rm_channel_mrkt_disp_name,
                            fmcgRet: market.rm_xaoc_mrkt_disp_name,
                            marketLevel: market?.lvl ? getMarketLevel(market) : '',
                            comparativeMarket: market.comparative_mkt_disp_name
                                ? `${market.comparative_mkt_disp_name}$$${market.comparative_mrkt_key}$$${market.mrkt_key}`
                                : null,
                            comparative_mkt_disp_name: market.comparative_mkt_disp_name,
                            mrkt_entitlements: market?.mrkt_entitlements,
                            is_prior_approval: market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL),
                            causal_block: market.causal_block,
                            is_releasable_mrkt: market.is_releasable_mrkt,
                        });
                    });
                }
                setIsLoading(false);
                setMarketSearchResults(marketSearchResultsTemp);
                setSearchError(false);
                setsearchResultMarkets(searchResultMarketsTemp);
                setSearchResultWholeList(searchResultMarketsTemp); // For Omni local search reset purpose
            }
        } else {
            getMarketLookup(categoryData, refresh);
        }
    }

    const setMarketType = (mrktType) => {
        if (mrktType.includes('Division')) {
            return 'Division';
        } else if (mrktType.includes('Region')) {
            return 'Region';
        } else if (mrktType.includes('Stateline')) {
            return 'Stateline';
        } else if (mrktType.includes('Major Market')) {
            return 'Major Market';
        } else if (mrktType.includes('Accounts')) {
            return 'Account';
        } else if (mrktType.includes('Channels')) {
            return 'Channel';
        } else {
            return mrktType;
        }
    };
    async function getMarketByMarketType(mrktType, catgoryData) {
        let totalUSMarketTemp = [];
        let geographyTemp = [];
        let FMCGRetailers = [];
        let specialtyRetailers = [];
        setIsLoading(true);
        if (setMarketType(mrktType) === 'Account') {
            try {
                const searchByAccountResp = await marketSearchByAccount();
                const response = searchByAccountResp.data;
                if (response) {
                    response.forEach((val) => {
                        if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0) {
                            if (
                                props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                    val.name
                                )
                            ) {
                                val.selected = true;
                            }
                        }
                        if (val.mrkt_sel_typ === 'By Geography') {
                            geographyTemp.push(val);
                        } else if (val.mrkt_sel_typ === 'By Channel FMCG') {
                            FMCGRetailers.push(val);
                        } else if (val.mrkt_sel_typ === 'By Channel Speciality') {
                            specialtyRetailers.push(val);
                        } else if (val.mrkt_sel_typ === 'Total US') {
                            totalUSMarketTemp.push(val);
                        }
                    });
                    setTotalUSMarket(totalUSMarketTemp);
                    let marketChannel = {
                        FMCGRetailers,
                        specialtyRetailers,
                    };
                    setMarketChannel(marketChannel);
                    setGeography(geographyTemp);
                    if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0) {
                        setSelectedMarket(
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || []
                        );
                        checkLimit(
                            '',
                            false,
                            '',
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets,
                            props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketObjects
                        );
                    }
                }
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        } else {
            try {
                const searchByMarketResp = await marketSearchByMrktType({
                    marketType: setMarketType(mrktType),
                    categories: catgoryData || selectedCategories || [],
                    reportType: 'subscription',
                });
                const response = searchByMarketResp.data;
                if (response) {
                    response.forEach((val) => {
                        if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0) {
                            if (
                                props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                    val.name
                                )
                            ) {
                                val.selected = true;
                            }
                        }
                        if (val.mrkt_sel_typ === 'By Geography') {
                            geographyTemp.push(val);
                        } else if (val.mrkt_sel_typ === 'By Channel FMCG') {
                            FMCGRetailers.push(val);
                        } else if (val.mrkt_sel_typ === 'By Channel Speciality') {
                            specialtyRetailers.push(val);
                        } else if (val.mrkt_sel_typ === 'Total US') {
                            totalUSMarketTemp.push(val);
                        }
                    });
                    setTotalUSMarket(totalUSMarketTemp);
                    let marketChannel = {
                        FMCGRetailers,
                        specialtyRetailers,
                    };
                    setMarketChannel(marketChannel);
                    setGeography(geographyTemp);
                    if (props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0) {
                        setSelectedMarket(
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || []
                        );
                        checkLimit(
                            '',
                            false,
                            '',
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets,
                            props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketObjects
                        );
                    }
                }
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
            }
        }
    }
    function checkLimit(mrktObj, status, marketSelType, preselectedItems = null, preSelectedMrktObjectsInDod = []) {
        let items;
        let allMarketData = getCurrentAllMarketData();
        if (mrktObj !== '') {
            mrktObj.uniqueMarketName = mrktObj?.uniqueMarketName ?? mrktObj?.name;
            mrktObj.marketType = mrktObj?.marketType ?? 'regular';
            mrktObj.mrkt_key_selected = mrktObj?.mrkt_key_selected ?? mrktObj?.mrkt_key;
        }

        if (preselectedItems) {
            if(props?.requestFromDod) {
                items = [];
                for (let index = 0; index < preSelectedMrktObjectsInDod?.length; index++) {
                    const folderObj = preSelectedMrktObjectsInDod[index]?.selections; 
                    for(let mrktNameIndex = 0; mrktNameIndex < folderObj?.length; mrktNameIndex++) {
                        const element = folderObj[mrktNameIndex]?.split("$$");
                        items.push({
                            name: element[0],
                            marketSelType: element?.length > 0 ? element[1] : "",
                            disableLayout: preSelectedMrktObjectsInDod[index]?.disableLayout
                        });
                    }  
                }
            } else {
                items = [];
                // const marketKeySelections =
                //     props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.marketKeySelections;
                for (let index = 0; index < preselectedItems.length; index++) {
                    const element = preselectedItems[index]?.split("$$");
                    let marketKey = null;
                    // if (marketKeySelections) {
                    //     marketKey = marketKeySelections.find((mrktData) => mrktData.name === element)?.key;
                    // }
                    items.push({
                        name: element[0],
                        marketSelType: element?.length > 0 ? element[1] : "",
                        // marketKey: marketKey,
                    });
                }
            }
        } else {
            items = [...selectedItems];
            if (status) {
                const checkIfCurrentNameIsFMCG = items.findIndex(
                    (mrkt) => mrkt?.name === mrktObj?.name && mrkt?.name === 'Total FMCG Retailers'
                );
                if (checkIfCurrentNameIsFMCG !== -1) {
                    items.splice(checkIfCurrentNameIsFMCG, 1);
                }
                // if (!items.map((item) => item.name).includes(name))
                items.push({
                    name: mrktObj !== '' ? mrktObj?.uniqueMarketName : '',
                    marketSelType,
                    disableLayout: mrktObj?.mrkt_entitlements,
                    marketKey: mrktObj !== '' ? mrktObj?.mrkt_key_selected : '',
                    marketType: mrktObj !== '' ? mrktObj?.marketType : '',
                });

                if (mrktObj !== '' && mrktObj?.marketType === marketTypeValues.CHANNEL) {
                    mrktObj.selectedChannel = true;
                } else if (mrktObj !== '' && mrktObj?.marketType === marketTypeValues.RETAILER) {
                    mrktObj.selectedRetailer = true;
                    mrktObj.selectedMarket = true;
                } else if (
                    (mrktObj !== '' && mrktObj?.marketType === marketTypeValues.COMPARATIVE) ||
                    mrktObj?.marketType === marketTypeValues.COMPARITIVE
                ) {
                    mrktObj.selectedComparitive = true;
                } else if (mrktObj !== '' && mrktObj?.marketType === marketTypeValues.REGULAR) {
                    mrktObj.selected = true;
                    if (mrktObj.mrkt_sel_typ === 'Total US') {
                        mrktObj.selectedComparitive = false;
                    }
                }

                allMarketData.push(mrktObj);
            } else {
                setSelectAll(false);
                const checkIfMrkNameHasSplit = mrktObj?.uniqueMarketName.split("$$");
                const findIndx = items.findIndex((x) => x.name?.includes("$$") ? (x.name === mrktObj?.uniqueMarketName) : (x.name === checkIfMrkNameHasSplit[0]));
                items.splice(findIndx, 1);
                let findIndx1 = -1;
                if (mrktObj?.marketType === marketTypeValues.CHANNEL) {
                    findIndx1 = allMarketData.findIndex(
                        (x) =>
                            `${x.rm_channel_mrkt_disp_name}$$${x.rm_channel_mrkt_key}$$${x.mrkt_key}` ===
                            mrktObj?.uniqueMarketName && x.marketType === marketTypeValues.CHANNEL
                    );
                } else if (mrktObj?.marketType === marketTypeValues.RETAILER) {
                    findIndx1 = allMarketData.findIndex(
                        (x) =>
                            `${x.rm_xaoc_mrkt_disp_name}$$${x.rm_xaoc_mrkt_key}$$${x.mrkt_key}` ===
                            mrktObj?.uniqueMarketName && x.marketType === marketTypeValues.RETAILER
                    );
                } else if (
                    mrktObj?.marketType === marketTypeValues.COMPARATIVE ||
                    mrktObj?.marketType === marketTypeValues.COMPARITIVE
                ) {
                    findIndx1 = allMarketData.findIndex(
                        (x) =>
                            `${x.comparative_mkt_disp_name}$$${x.comparative_mrkt_key}$$${x.mrkt_key}` ===
                            mrktObj?.uniqueMarketName &&
                            (x.marketType === marketTypeValues.COMPARATIVE ||
                                x.marketType === marketTypeValues.COMPARITIVE)
                    );
                } else if (mrktObj?.marketType === marketTypeValues.REGULAR) {
                    findIndx1 = allMarketData.findIndex(
                        (x) => x.name === mrktObj?.uniqueMarketName && x.marketType === marketTypeValues.REGULAR
                    );
                }

                allMarketData.splice(findIndx1, 1);
            }
        }
        if (validateExtractLimits(items.length || 0) && props.extracts) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        setSelectedItems(items);
        if ((props?.productType === 'story' && props?.marketType === 'focusMarket') || alertWithAllMarketData) {
            setFocusMarketsData(allMarketData);
        } else if (props?.productType === 'story' && props?.marketType === 'comparisonMarket') {
            setComparisonMarketsData(allMarketData);
        }

        if (props.setExtractsMarketSelection) {
            let extractItems = [];
            items.map((val) => {
                let marketName = val.name?.split('$$');
                marketName = marketName.length > 0 ? marketName[0] : marketName;
                let mrktType = val.marketSelType;
                if (!mrktType) {
                    const treeViewIndex = props.extractsMarketTreeView.findIndex((val2) =>
                        val2.selections.includes(marketName)
                    );
                    if (treeViewIndex !== -1) {
                        mrktType = props.extractsMarketTreeView[treeViewIndex].folder;
                    }
                }
                const findIndex = extractItems.findIndex((val1) => val1.folder === mrktType);
                if (findIndex === -1) {
                    if (mrktType) {
                        extractItems.push({
                            folder: mrktType,
                            selections: [marketName],
                            disableLayout: val.disableLayout
                        });
                    }
                } else {
                    extractItems[findIndex].selections.push(marketName);
                }
            });
            props.setExtractsMarketSelection(extractItems);
        }
    }

    const validateAccessLevel = () => {
        switch (checkUserSegmentPermissions(userSegment)) {
            case 2:
                return true;
            case 3:
                if (props.folder === 'Accounts') {
                    return true;
                }
                break;
            case 4:
                if (props.folder === 'Accounts') {
                    return true;
                }
                break;
            default:
                break;
        }
        return false;
    };
    const searchMarketItems = async (e) => {
        let searchResultMarketsTemp = JSON.parse(JSON.stringify(searchResultMarkets));
        let marketSearchResultsTemp = JSON.parse(JSON.stringify(marketSearchResults));
        searchResultMarketsTemp = []; // Making sure array is empty
        marketSearchResultsTemp = {}; // Marking sure object is empty
        let allMarketData = getCurrentAllMarketData();
        let data = e.target.value;
        data.length >= 3 || props.marketGroup === 'omni' ? setDisplayMarketTree(false) : setDisplayMarketTree(true);
        setMarketSearchKeyword(data);
        if (data.length > 2 || (props.marketGroup === 'omni' && data.length === 0)) {
            // For Omni we show full list when search word is cleared
            // Search only when we have atleast 3 characters to search
            if (validateAccessLevel() && props.extracts) {
                return true;
            }
            setIsLoading(true);
            let searchKeyword = data.replace(/\//g, ' ');
            try {
                if (
                    props.folder === 'Statelines' ||
                    props.folder === 'Major Markets' ||
                    props.folder === 'Regions' ||
                    props.folder === 'Divisions'
                ) {
                    const filterData = geography.filter(
                        (val) => val.name.toUpperCase().indexOf(searchKeyword.toUpperCase()) > -1
                    );
                    if (filterData.length > 0) {
                        setIsLoading(false);
                        setSearchError(false);
                        setMarketSearchResults({});
                        setsearchResultMarkets(filterData);
                    } else {
                        setSearchError(true);
                        setIsLoading(false);
                        setMarketSearchResults({});
                        setsearchResultMarkets([]);
                    }
                    return true;
                }
                let searchMrktResp;
                if (props.marketGroup === 'omni') {
                    let localSearchResultTemp = [...searchResultWholeList];
                    if (data.length > 2) {
                        localSearchResultTemp = localSearchResultTemp.filter((item) =>
                            item.name?.toLowerCase().includes(searchKeyword.toLowerCase())
                        );
                    }
                    searchMrktResp = {
                        data: [...localSearchResultTemp],
                    };
                } else {
                    searchMrktResp = props.requestFromDod ? await dodMarketSearch({
                        searchTerm: searchKeyword.toLowerCase(),
                        marketType: props.marketGroup.toUpperCase() || 'RMS',
                        reportType: props.reportType,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketSearch({
                        searchTerm: searchKeyword.toLowerCase(),
                        marketType: props.marketGroup.toUpperCase() || 'RMS',
                        reportType: props.reportType,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                }
                if (searchMrktResp?.error !== undefined && Object.keys(searchMrktResp?.error)?.length > 0) {
                    setSearchError(searchMrktResp.error);
                    setIsLoading(false);
                    setMarketSearchResults({});
                    setsearchResultMarkets([]);
                } else if (searchMrktResp?.length === 0) {
                    setSearchError(true);
                    setIsLoading(false);
                    setMarketSearchResults({});
                    setsearchResultMarkets([]);
                } else {
                    marketSearchResultsTemp = searchMrktResp;
                    if (marketSearchResultsTemp?.length > 0) {
                        marketSearchResultsTemp.forEach((market) => {
                            // Capture market
                            let searchMarketData = {
                                name: market.name,
                                marketSelType: market.mrkt_sel_typ,
                                raw_mrkt_name: market.raw_mrkt_name,
                                mrkt_key: market?.mrkt_key,
                                rm_channel_mrkt_key: market?.rm_channel_mrkt_key,
                                rm_xaoc_mrkt_key: market?.rm_xaoc_mrkt_key,
                                stores: market.rm_channel_mrkt_disp_name,
                                fmcgRet: market.rm_xaoc_mrkt_disp_name,
                                marketLevel: market?.lvl ? getMarketLevel(market) : '',
                                comparativeMarket: market.comparative_mkt_disp_name
                                    ? `${market.comparative_mkt_disp_name}$$${market.comparative_mrkt_key}$$${market.mrkt_key}`
                                    : null,
                                comparative_mkt_disp_name: market.comparative_mkt_disp_name,
                                parent_folder_path: market?.parent_folder_path,
                                mrkt_sel_typ: market?.mrkt_sel_typ,
                                comparative_mrkt_key: market.comparative_mrkt_key,
                                rm_xaoc_mrkt_disp_name: market?.rm_xaoc_mrkt_disp_name,
                                rm_channel_mrkt_disp_name: market?.rm_channel_mrkt_disp_name,
                                mrkt_entitlements: market?.mrkt_entitlements,
                                is_prior_approval: market?.mrkt_entitlements?.includes(MrktEntitlementType.PRIOR_APPROVAL),
                                causal_block: market.causal_block,
                                is_releasable_mrkt: market.is_releasable_mrkt,
                            };

                            if (props.productType === 'story' || alertWithAllMarketData) {
                                if (market.mrkt_sel_typ === 'Total US') {
                                    let selectedMarket = selectedItems?.map((val) => {
                                        return val.name.split('$$')[0];
                                    });
                                    searchMarketData.selected =
                                        selectedMarket.includes(market.name) &&
                                        allMarketData?.some(
                                            (elem) => elem.name === market.name && elem.selectedComparitive !== true
                                        );
                                    searchMarketData.selectedComparitive =
                                        selectedMarket.includes(market.comparative_mkt_disp_name) &&
                                        allMarketData?.some(
                                            (elem) =>
                                                elem.name === market.name &&
                                                elem.comparative_mkt_disp_name === market.comparative_mkt_disp_name &&
                                                elem.selectedComparitive === true
                                        );
                                } else {
                                    addSelectedStatetoMarket(searchMarketData);
                                }
                            } else {
                                searchMarketData.selected = selectedMarket.includes(market.name) || extractsCheckCondition();
                                searchMarketData.selectedChannel = selectedMarket.includes(
                                    market.rm_channel_mrkt_disp_name
                                ) || extractsCheckCondition();
                                searchMarketData.selectedMarket = selectedMarket.includes(
                                    market.rm_xaoc_mrkt_disp_name
                                ) || extractsCheckCondition();
                                searchMarketData.selectedComparitive =
                                    selectedItems.findIndex(
                                        (x) =>
                                            x.name ===
                                            `${market.comparative_mkt_disp_name}$$${market.comparative_mrkt_key}$$${market.mrkt_key}`
                                    ) !== -1 || extractsCheckCondition();
                                // console.log(selectedItems.findIndex((x) => x.name === `${market.comparative_mkt_disp_name}$$${market.comparative_mrkt_key}$$${market.mrkt_key}`));
                                //  selectedMarket.includes(market.comparative_mkt_disp_name);
                            }
                            searchResultMarketsTemp.push(searchMarketData);
                        });
                    }
                    setIsLoading(false);
                    setMarketSearchResults(marketSearchResultsTemp);
                    setSearchError(false);
                    setsearchResultMarkets(searchResultMarketsTemp);
                }
            } catch (err) {
                setSearchError(true);
                setIsLoading(false);
                setMarketSearchResults({});
                setsearchResultMarkets([]);
            }
        }
    };

    //Added for stories
    function getMarketLevel(market) {
        let marketLevel = '';
        // Market level to be picked from API response only, default market level added in stories for now
        if (market.mrkt_sel_typ === 'Total US' && (market?.lvl === undefined || market.lvl === 'null')) {
            marketLevel = 'level_1';
        } else if (market.mrkt_sel_typ === 'By Geography') {
            marketLevel = 'level_1';
        } else if (market.mrkt_sel_typ === 'By Channel FMCG' || market.mrkt_sel_typ === 'By Channel Speciality') {
            marketLevel = 'level_' + (market.lvl - 2);
        } else {
            marketLevel = 'level_' + market.lvl;
        }
        return marketLevel;
    }

    async function getMarketLookup(categoryData, refresh = false) {
        let allMarketData = getCurrentAllMarketData(true);
        let totalUSMarketTemp = JSON.parse(JSON.stringify(totalUSMarket));
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        let FMCGRetailers = marketChannel.FMCGRetailers;
        let specialtyRetailers = marketChannel.specialtyRetailers;
        if (
            totalUSMarketTemp === undefined ||
            FMCGRetailers === undefined ||
            specialtyRetailers === undefined ||
            geographyTemp === undefined ||
            refresh
        ) {
            try {
                setIsLoading(true);
                const lookupMrktsResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        categories: categoryData,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        categories: categoryData,
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let marketsResponse = lookupMrktsResp.data;
                if (marketsResponse !== undefined && Object.keys(marketsResponse).length > 0) {
                    // TO DO: Integrate new market selector module components wherever this file was referred
                    totalUSMarketTemp = marketsResponse[TOTAL_US];
                    totalUSMarketTemp.forEach((item) => {
                        const marketInHistory =
                            props.actionConfig &&
                            props.multiSelect &&
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                item.name
                            );
                        if (
                            (props.saveBtnType === 'alertSelector' && props.selectorState?.clearFocusMarket) ||
                            alertWithAllMarketData
                        ) {
                            item.selected = false;
                        } else {
                            item.selected = marketInHistory;
                        }
                    });
                    if (
                        (props.productType === 'story' && props.fromBack === true) ||
                        (alertWithAllMarketData && retainAlertData)
                    ) {
                        retainTotalUSMarkets(totalUSMarketTemp);
                    }
                    if (refreshComparisonMarketStatusRef.current) {
                        preSelectTotalUSRemainingMarkets(totalUSMarketTemp);
                    }
                    if (props?.productType === 'story' && props.refreshMarkets) {
                        await setSelectedMarketsList(totalUSMarketTemp);
                    }
                    if (props.marketGroup !== 'cps') {
                        // TO DO: Integrate new market selector module components wherever this file was referred
                        FMCGRetailers = marketsResponse[SELECT_BY_CHANNEL]?.[BY_CHANNEL_FMCG];
                    } else {
                        FMCGRetailers = marketsResponse[SELECT_BY_CHANNEL]?.[PANEL_BY_CHANNEL];
                    }
                    FMCGRetailers.forEach((item) => {
                        const marketInHistory =
                            props.actionConfig &&
                            props.multiSelect &&
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                item.name
                            );
                        item.expanded = marketInHistory;
                        if (props.saveBtnType === 'alertSelector' && props.selectorState?.clearFocusMarket) {
                            item.selected = false;
                        } else {
                            item.selected = marketInHistory;
                        }
                    });
                    let specialtyRetailers = props.marketGroup !== 'cps' ? marketsResponse[SELECT_BY_CHANNEL]?.[BY_CHANNEL_SPECIALITY] : [];
                    specialtyRetailers.forEach((item) => {
                        const marketInHistory =
                            props.actionConfig &&
                            props.multiSelect &&
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                item.name
                            );
                        item.expanded = marketInHistory;
                        if (props.saveBtnType === 'alertSelector' && props.selectorState?.clearFocusMarket) {
                            item.selected = false;
                        } else {
                            item.selected = marketInHistory;
                        }
                    });
                    let geographyTemp = props.marketGroup !== 'cps' ? marketsResponse[BY_GEOGRAPHY] : [];
                    geographyTemp.forEach((item) => {
                        const marketInHistory =
                            props.actionConfig &&
                            props.multiSelect &&
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets.includes(
                                item.name
                            );
                        item.expanded = marketInHistory;
                        if (props.saveBtnType === 'alertSelector' && props.selectorState?.clearFocusMarket) {
                            item.selected = false;
                        } else {
                            item.selected = marketInHistory;
                        }
                    });
                    // this.setState({
                    // 	totalUSmarket,
                    // 	marketChannel: {
                    // 		FMCGRetailers,
                    // 		specialtyRetailers
                    // 	},
                    // 	geography,
                    // 	isLoading: false
                    // })
                    setTotalUSMarket(totalUSMarketTemp);
                    let marketChannel = {
                        FMCGRetailers,
                        specialtyRetailers,
                    };
                    setMarketChannel(marketChannel);
                    setGeography(geographyTemp);
                    // const selectedTotalMrkts = totalUSMarketTemp.filter(item => item.selected === true) || [];
                    if (
                        props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0 &&
                        !(props.saveBtnType === 'alertSelector' && props.selectorState.clearFocusMarket) &&
                        !alertWithAllMarketData
                    ) {
                        setSelectedMarket(
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets || []
                        );
                        checkLimit(
                            '',
                            false,
                            '',
                            props.actionConfig.focusMarketSelections?.focusMarket?.byMarketName?.markets,
                            props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketObjects
                        );
                    }
                    setIsLoading(false);
                }
            } catch (err) {
                setIsLoading(false);
            }
        }
    }

    const setSelectedMarketsList = async (totalUSMarketTemp) => {
        let allMarketData = [];
        if (props?.productType === 'story' && props?.marketType === 'focusMarket') {
            allMarketData = JSON.parse(JSON.stringify(focusMarketsData));
        }

        if (allMarketData?.length > 0) {
            let [previousSelectedFilteredMarkets, previousSelectedFilteredMarketsData] = await filterOpenMarkets(
                allMarketData
            );
            let previousSelectedMarkets = [...previousSelectedFilteredMarkets];
            setSelectedMarket(previousSelectedMarkets); // pass only name here
            setFocusMarketsData(previousSelectedFilteredMarketsData);
            let isMarketSelected = previousSelectedMarkets?.length > 0 ? true : false;
            setIsMarketSelected(isMarketSelected);
            setSelectedItemsForStoryRetention(previousSelectedFilteredMarketsData || [])      // selectedItems of filtered markets only
            let data = { previousSelectedMarkets, previousSelectedFilteredMarketsData };
            retainTotalUSMarkets(totalUSMarketTemp, data);
            if (previousSelectedMarkets?.length === 0) {
                setSelectedMarketItemLvlVal({});
                setSelectedItems([]);
                setIsValidFormItems({});
            }
        }
        setMarketSearchKeyword('');
        setsearchResultMarkets([]);
        setDisplayMarketTree(true);
    };

    // fetch market keys from market names
    const getMarketKeys = async (markets) => {
        try {
            let req = {
                markets: markets,
            };
            let resp = await getMarketKeyFromMarkets(req);
            return resp;
        } catch (err) {
            console.log('Error in fetching Market Keys ', err);
        }
    };

    const filterAlertMarket = async () => {
        // Remove prior approval markets if present in Selections
        let filteredMarkets = [];
        let focusMarketKeysList = await getMarketKeys(selectedMarket);

        for (let i = 0; i < focusMarketKeysList?.length; i++) {
            let currentMarket = focusMarketKeysList[i]?.market;
            let currentMarketKey = focusMarketKeysList[i]?.mrkt_key_max;

            let marketObj = {
                market_key: currentMarketKey,
                suppressed_market: 'Y',
                categories: subscriptionCategories,
            };

            let { is_prior_approved_market } = await isPriorApprovalMarket(marketObj);
            if (is_prior_approved_market) {
                filteredMarkets.push(currentMarket);
            }
        }
        return filteredMarkets;
    };
    const filterOpenMarkets = async (selectedMarketsData) => {
        setIsLoading(true);
        let categoryData = [];
        if (props.saveBtnType === 'alertSelector') {
            if (props?.selectorState?.focusProductSelections) {
                categoryData = props?.selectorState?.focusProductSelections.map((val, indx) => {
                    if (val?.category) {
                        return val?.category;
                    }
                });
                categoryData = categoryData.flat();
            }
        } else if (props.from === 'userPref') {
            if (props?.userPrefState?.primaryCategory) {
                categoryData = props?.userPrefState?.primaryCategory;
            }
        } else {
            if (props?.selectorState?.focusProductSelections) {
                categoryData = props?.selectorState?.focusProductSelections?.competitiveProductSet?.categories || [];
            }
        }

        let filteredMarkets = [];
        let filteredMarketsData = [];
        let selectedMarkets = props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets;

        for (let i = 0; i < selectedMarketsData?.length; i++) {
            let market = selectedMarketsData[i];

            let currentMarketName =
                market.marketType === marketTypeValues.REGULAR
                    ? market?.name
                    : market.marketType === marketTypeValues.CHANNEL
                        ? market?.rm_channel_mrkt_disp_name
                        : market?.marketType === marketTypeValues.RETAILER
                            ? market?.rm_xaoc_mrkt_disp_name
                            : market?.comparative_mkt_disp_name;

            let response = await getMarketData(market, false, categoryData);

            if (market?.mrkt_sel_typ === 'Total US') {
                response.data.markets = response?.data['Total US'];
            }

            if (market?.mrkt_sel_typ === 'Panel Total US Markets') {
                response.data.markets = response?.data['Panel Total US Markets'];
            }
            response?.data?.markets?.forEach((val, index) => {
                if (market?.name === val?.name) {
                    // TODO : looks like the below condition is never met because marketTypeValues doesn't hold any of these CONSTANTS today, should be deleted as part of code cleanup 
                    if (
                        !(
                            val?.mrkt_entitlements?.includes(marketTypeValues.PRIOR_APPROVAL) ||
                            val?.mrkt_entitlements?.includes(marketTypeValues.REV_SHARE) ||
                            val?.mrkt_entitlements?.includes(marketTypeValues.CATEGORY_NOT_COVERED)
                        )
                    ) {
                        if (selectedMarkets?.includes(currentMarketName)) {
                            filteredMarkets.push(currentMarketName);
                            filteredMarketsData.push(market);
                        }
                    }
                }
            });
        }

        setIsLoading(false);
        return [filteredMarkets, filteredMarketsData];
    };

    async function getMarketData(item, currentMarketData = true, categoryData = []) {
        let data = props.requestFromDod ?
            await dodMarketLookup({
                reportType: props.reportType,
                mrktHier: currentMarketData ? `${item.parent_folder_path}\\${item.raw_mrkt_name}` : item.parent_folder_path,
                mrktGrp: props.marketGroup.toUpperCase(),
                mrktSelType: item.mrkt_sel_typ || undefined,
                ...props?.subSelectors?.markets?.marketSections,
            }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                reportType: props.reportType,
                mrktHier: currentMarketData ? `${item.parent_folder_path}\\${item.raw_mrkt_name}` : item.parent_folder_path,
                mrktGrp: props.marketGroup.toUpperCase(),
                mrktSelType: item.mrkt_sel_typ || undefined,
                categories: categoryData?.length === 0 ? selectedCategories || [] : categoryData,
                ...props?.subSelectors?.markets?.marketSections,
            });
        return data;
    }

    function sublevelsSelection(
        item,
        selected,
        selectedChannel,
        selectedRetailer,
        selectedMarketTemp,
        formValidationItems,
        selectedComparitive
    ) {
        let validForm = false;
        item.disabled = true;
        let name = item.name;
        if (selected) {
            let findIndex = selectedMarketTemp.findIndex((val) => val === name);
            if (findIndex === -1) selectedMarketTemp.push(name);
        } else {
            selectedMarketTemp.forEach((market, marketNumber) => {
                if (market === name) {
                    selectedMarketTemp.splice(marketNumber, 1);
                }
            });
        }
        selected ? (item.selected = true) : (item.selected = false);
        if (selectedChannel) {
            if (item.rm_channel_mrkt_disp_name) {
                item.selectedChannel = true;
            } else if (item.rm_xaoc_mrkt_disp_name) {
                item.selectedRetailer = true;
                // uncomment if comparitive markets need to be enabled - 3071
            } else if (item.comparative_mkt_disp_name) {
                item.selectedComparitive = true;
            }
        }
        if (selectedRetailer) {
            if (item.rm_xaoc_mrkt_disp_name) {
                item.selectedRetailer = true;
            } else if (item.rm_channel_mrkt_disp_name) {
                item.selectedChannel = true;
                // uncomment if comparitive markets need to be enabled - 3071
            } else if (item.comparative_mkt_disp_name) {
                item.selectedComparitive = true;
            }
        }
        // uncomment if comparitive markets need to be enabled - 3071
        if (selectedComparitive) {
            if (item.comparative_mkt_disp_name) {
                item.selectedComparitive = true;
            } else if (item.rm_channel_mrkt_disp_name) {
                item.selectedChannel = true;
            } else if (item.rm_xaoc_mrkt_disp_name) {
                item.selectedRetailer = true;
            }
        }

        let selectedName = null;
        if (item.selectedRetailer && item.rm_xaoc_mrkt_disp_name !== null) {
            selectedName = item.rm_xaoc_mrkt_disp_name;
        } else if (item.selectedChannel && item.rm_channel_mrkt_disp_name !== null) {
            selectedName = item.rm_channel_mrkt_disp_name;
            // uncomment if comparitive markets need to be enabled - 3071
        } else if (item.selectedComparitive && item.comparative_mkt_disp_name !== null) {
            selectedName = item.comparative_mkt_disp_name;
        }
        if (
            item.selected &&
            selectedName !== null &&
            (item.selectedRetailer || item.selectedChannel || item.selectedComparitive)
        ) {
            if (item.selected) {
                validForm = true;
                formValidationItems[selectedName] = true;
                formValidationItems[name] = true;
            } else {
                validForm = false;
                formValidationItems[selectedName] = false;
                formValidationItems[name] = false;
            }
        } else if (selectedName !== null) {
            if (item.selected || item.selectedRetailer || item.selectedChannel || item.selectedComparitive) {
                formValidationItems[selectedName] = false;
                formValidationItems[name] = false;
            } else {
                delete formValidationItems[selectedName];
                delete formValidationItems[name];
            }
        }

        if (selectedName) selectedRemainingMarket.push(selectedName);
        if (item.selectedRetailer || item.selectedChannel || item.selectedComparitive) {
            let findIndex = selectedMarketTemp.findIndex((val) => val === selectedName);
            if (findIndex === -1) selectedMarketTemp.push(selectedName);
        } else {
            selectedMarketTemp.forEach((market, marketNumber) => {
                if (selectedRemainingMarket.findIndex((val) => val === market) !== -1) {
                    selectedMarketTemp.splice(marketNumber, 1);
                }
            });
        }
    }

    async function loadMarkets(
        focusMarket,
        mrkt_channel,
        isValidForm,
        category,
        index,
        index2,
        index3,
        mrktType,
        selectedMrktTemp
    ) {
        let marketChannelTemp = JSON.parse(JSON.stringify(mrkt_channel));
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMrktTemp));
        let formValidationItems = JSON.parse(JSON.stringify(isValidForm));
        let selected = props?.subSelectors?.markets?.noSelection ? false : focusMarket[index3].selected;
        let selectedChannel = props?.subSelectors?.markets?.noSelection ? false : focusMarket[index3].selectedChannel;
        let selectedRetailer = props?.subSelectors?.markets?.noSelection ? false : focusMarket[index3].selectedRetailer;
        setIsLoading(true);

        await Promise.all(
            focusMarket.map(async (item) => {
                if (item.has_children === 'Y' || selectedChannel || selectedRetailer) {
                    let res = await getMarketData(item);
                    item.expanded = true;
                    let sublevel1Response = res.data;
                    if (sublevel1Response !== undefined) {
                        await Promise.all(
                            sublevel1Response.markets.map(async (item2) => {
                                item.subFolders = sublevel1Response.markets;
                                if (item2.has_children === 'Y') {
                                    let res1 = await getMarketData(item2);
                                    item2.expanded = true;
                                    item2.disabled = true;
                                    let sublevel2Response = res1.data;
                                    if (sublevel2Response !== undefined) {
                                        await Promise.all(
                                            sublevel2Response.markets.map(async (item3) => {
                                                item2.markets = sublevel2Response.markets;
                                                if (item3.has_children === 'Y') {
                                                    let res3 = await getMarketData(item3);
                                                    item3.expanded = true;
                                                    item3.disabled = true;
                                                    let sublevel3Response = res3.data;
                                                    item3.lvl2markets = sublevel3Response.markets;
                                                    await Promise.all(
                                                        sublevel3Response.markets.map(async (item4) => {
                                                            if (item4.is_mrkt === 'Y') {
                                                                sublevelsSelection(
                                                                    item4,
                                                                    selected,
                                                                    selectedChannel,
                                                                    selectedRetailer,
                                                                    selectedMarketTemp,
                                                                    formValidationItems
                                                                );
                                                                item4.disabled = true;
                                                            }
                                                        })
                                                    );
                                                }
                                                if (item3.is_mrkt === 'Y') {
                                                    sublevelsSelection(
                                                        item3,
                                                        selected,
                                                        selectedChannel,
                                                        selectedRetailer,
                                                        selectedMarketTemp,
                                                        formValidationItems
                                                    );
                                                }
                                            })
                                        );
                                    }
                                }
                                if (item2.is_mrkt === 'Y') {
                                    sublevelsSelection(
                                        item2,
                                        selected,
                                        selectedChannel,
                                        selectedRetailer,
                                        selectedMarketTemp,
                                        formValidationItems
                                    );
                                }
                            })
                        );
                    }
                }
            })
        );

        setIsLoading(false);
        if (category === 'FMCGRetailers-subfolders') {
            marketChannelTemp.FMCGRetailers[index].companies[index2].market = focusMarket;
        }
        if (category === 'SpecialityRetailers-subfolders') {
            marketChannelTemp.specialtyRetailers[index].companies[index2].market = focusMarket;
        }

        let isMarketSelected = selectedMarketTemp.length > 0 && (selectedChannel || selectedRetailer) ? true : false;
        setIsMarketSelected(isMarketSelected);
        setMarketChannel(marketChannelTemp);
        setSelectedMarket(selectedMarketTemp);
        setSelectedRemainingMarket(selectedRemainingMarket);
        setIsValidFormItems(formValidationItems);
    }

    const getCurrentAllMarketData = (fetchFromProps = false) => {
        let allMarketData = [];
        if (fetchFromProps) {
            if ((props?.marketType === 'focusMarket' && !isMultiMarketsStory) || alertWithAllMarketData) {
                allMarketData = JSON.parse(JSON.stringify(props.selectorState?.focusMarketsSelectedData || []));
            }
            else if (props?.marketType === 'focusMarket' && isMultiMarketsStory) {
                allMarketData = JSON.parse(JSON.stringify(props.selectorState?.focusMarketsPerformanceSelectedData || []));
            } else if (props?.marketType === 'comparisonMarket') {
                allMarketData = JSON.parse(JSON.stringify(props.selectorState?.comparisonMarketsSelectedData));
            }
        } else {
            if (props?.marketType === 'focusMarket' || alertWithAllMarketData) {
                allMarketData = JSON.parse(JSON.stringify(focusMarketsData));
            } else if (props?.marketType === 'comparisonMarket') {
                allMarketData = JSON.parse(JSON.stringify(comparisonMarketsData));
            }
        }
        return allMarketData;
    };

    const selectMarketsInArray = (array) => {
        if (props?.productType === 'story' || alertWithAllMarketData) {
            // this adds the selected flag to specific markets
            array.forEach(addSelectedStatetoMarket);
        }
        return array;
    };

    const addSelectedStatetoMarket = (val) => {
        let allMarketData = getCurrentAllMarketData();
        let selectedMarket = selectedItems.map((val) => {
            return val.name.split('$$')[0];
        });

        if (
            selectedMarket.includes(val.rm_channel_mrkt_disp_name) &&
            allMarketData?.some(
                (elem) =>
                    elem.name === val.name &&
                    elem.rm_channel_mrkt_disp_name === val.rm_channel_mrkt_disp_name &&
                    elem?.marketType === marketTypeValues.CHANNEL
            )
        ) {
            val.selectedChannel = true;
        }
        if (
            selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) &&
            allMarketData?.some(
                (elem) =>
                    elem.name === val.name &&
                    elem.rm_xaoc_mrkt_disp_name === val.rm_xaoc_mrkt_disp_name &&
                    elem?.marketType === marketTypeValues.RETAILER
            )
        ) {
            val.selectedRetailer = true;
            val.selectedMarket = true;
        }
        if (
            selectedMarket.includes(val.comparative_mkt_disp_name) &&
            allMarketData?.some(
                (elem) =>
                    elem.name === val.name &&
                    elem.comparative_mkt_disp_name === val.comparative_mkt_disp_name &&
                    (elem?.marketType === marketTypeValues.COMPARATIVE ||
                        elem?.marketType === marketTypeValues.COMPARITIVE)
            )
        ) {
            val.selectedComparitive = true;
        }

        if (selectedMarket.includes(val.raw_mrkt_name) && allMarketData?.some((elem) => elem.name === val.name)) {
            val.selected = true;
        }
    };

    async function showCompanies(index) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType = marketChannelTemp?.FMCGRetailers[index]?.mrkt_sel_typ || '';
        if (marketChannelTemp.FMCGRetailers[index].expanded) {
            marketChannelTemp.FMCGRetailers[index].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            if (marketChannelTemp.FMCGRetailers[index].companies === undefined) {
                setIsLoading(true);
                const mrktCompaniesLookupResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: folderName,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: props.requestFromDod ? undefined : selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: folderName,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: props.requestFromDod ? undefined : selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = mrktCompaniesLookupResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showSpecialityRetailersCompanies(index) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType = marketChannelTemp?.specialtyRetailers[index]?.mrkt_sel_typ || '';
        if (marketChannelTemp.specialtyRetailers[index].expanded) {
            marketChannelTemp.specialtyRetailers[index].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.specialtyRetailers[index].raw_mrkt_name;
            if (marketChannelTemp.specialtyRetailers[index].companies === undefined) {
                setIsLoading(true);
                const speclRetlCompLookupResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: folderName,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: folderName,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = speclRetlCompLookupResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.specialtyRetailers[index].expanded = true;
                    marketChannelTemp.specialtyRetailers[index].companies = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.specialtyRetailers[index].expanded = true;
                marketChannelTemp.specialtyRetailers[index].companies?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    const extractsCheckCondition = () => {
        return (props?.extracts && selectAll)
    }
    async function showMarkets(index, index1) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType = marketChannelTemp?.FMCGRetailers[index].companies[index1]?.mrkt_sel_typ || '';
        if (marketChannelTemp.FMCGRetailers[index].companies[index1].expanded) {
            marketChannelTemp.FMCGRetailers[index].companies[index1].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.FMCGRetailers[index].companies[index1].raw_mrkt_name;
            if (marketChannelTemp.FMCGRetailers[index].companies[index1].market === undefined) {
                setIsLoading(true);
                const showMrktsLookupResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = showMrktsLookupResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].companies[index1].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].companies[index1].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies[index1].market?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
            if (props?.subSelectors?.markets?.marketoptions && props?.subSelectors?.markets?.noSelection) {
                let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
                let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
                loadMarkets(
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market,
                    marketChannelTemp,
                    formValidationItems,
                    'FMCGRetailers-subfolders',
                    index,
                    index1,
                    null,
                    null,
                    selectedMarketTemp
                );
            }
        }
    }

    async function showSpecialityRetailersMarkets(index, index1) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType = marketChannelTemp?.specialtyRetailers[index]?.companies[index1]?.mrkt_sel_typ || '';
        if (marketChannelTemp.specialtyRetailers[index].companies[index1].expanded) {
            marketChannelTemp.specialtyRetailers[index].companies[index1].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.specialtyRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.specialtyRetailers[index].companies[index1].raw_mrkt_name;
            if (marketChannelTemp.specialtyRetailers[index].companies[index1].market === undefined) {
                setIsLoading(true);
                const showSpeclRetlMrktsLookupResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = showSpeclRetlMrktsLookupResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.specialtyRetailers[index].companies[index1].expanded = true;
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.specialtyRetailers[index].companies[index1].expanded = true;
                marketChannelTemp.specialtyRetailers[index].companies[index1].market?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
        if (props?.subSelectors?.markets?.marketoptions && props?.subSelectors?.markets?.noSelection) {
            let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
            let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
            loadMarkets(
                marketChannelTemp.specialtyRetailers[index].companies[index1].market,
                marketChannelTemp,
                formValidationItems,
                'SpecialityRetailers-subfolders',
                index,
                index1,
                null,
                null,
                selectedMarketTemp
            );
        }
    }

    async function showSubFolders(index, index1, index2) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp?.FMCGRetailers[index]?.companies[index1]?.market[index2]?.mrkt_sel_typ || '';
        if (marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].expanded) {
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.FMCGRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            if (marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders === undefined) {
                setIsLoading(true);
                const showSubFoldResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = showSubFoldResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders =
                        response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showSpecialityRetailersSubFolders(index, index1, index2) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp?.specialtyRetailers[index].companies[index1].market[index2]?.mrkt_sel_typ || '';
        if (marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].expanded) {
            marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.specialtyRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.specialtyRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            if (marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders === undefined) {
                setIsLoading(true);
                const spclRetlSubfoldResp = props.requestFromDod ?
                await dodMarketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    ...props?.subSelectors?.markets?.marketSections,
                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                : await marketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    categories: selectedCategories || [],
                    ...props?.subSelectors?.markets?.marketSections,
                });
                let response = spclRetlSubfoldResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].expanded = true;
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders =
                        response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].expanded = true;
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showSubfolderMarkets(index, index1, index2, index3) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp?.FMCGRetailers[index]?.companies[index1]?.market[index2]?.subFolders[index3]
                ?.mrkt_sel_typ || '';
        if (marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].expanded) {
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.FMCGRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            let subFolderName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .raw_mrkt_name;
            if (
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets ===
                undefined
            ) {
                setIsLoading(true);
                const subFoldMrktsResp = props.requestFromDod ?
                await dodMarketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    ...props?.subSelectors?.markets?.marketSections,
                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                : await marketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    categories: selectedCategories || [],
                    ...props?.subSelectors?.markets?.marketSections,
                });
                let response = subFoldMrktsResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[
                        index3
                    ].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets =
                        response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[
                    index3
                ].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showSpecialityRetailersSubfolderMarkets(index, index1, index2, index3) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp?.specialtyRetailers[index]?.companies[index1]?.market[index2]?.subFolders[index3]
                ?.mrkt_sel_typ || '';
        if (marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].expanded) {
            marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                index3
            ].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.specialtyRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.specialtyRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            let subFolderName =
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .raw_mrkt_name;
            if (
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .markets === undefined
            ) {
                setIsLoading(true);
                const speclRetlMrktSubFoldResp = props.requestFromDod ?
                await dodMarketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    ...props?.subSelectors?.markets?.marketSections,
                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    categories: selectedCategories || [],
                    ...props?.subSelectors?.markets?.marketSections,
                });
                let response = speclRetlMrktSubFoldResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                        index3
                    ].expanded = true;
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                        index3
                    ].markets = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                    index3
                ].expanded = true;
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                    index3
                ].markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showlvl2Markets(index, index1, index2, index3, index4) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[index4]
                ?.mrkt_sel_typ || '';
        if (
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[index4]
                .expanded
        ) {
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                index4
            ].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.FMCGRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            let subFolderName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .raw_mrkt_name;
            let lvl2marketName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].raw_mrkt_name;
            if (
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets === undefined
            ) {
                setIsLoading(true);
                const showLvl2MrktsResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = showLvl2MrktsResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                        index4
                    ].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                        index4
                    ].lvl2markets = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showlvl3Markets(index, index1, index2, index3, index4, index5) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        console.log('until index 4', marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[index4].lvl2markets[index5]);
        const marketSelType =
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[index4].lvl2markets[index5]
                ?.mrkt_sel_typ || '';
        if (
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[index4].lvl2markets[index5]
                .expanded
        ) {
            marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                index4
            ].lvl2markets[index5].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.FMCGRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.FMCGRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            let subFolderName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .raw_mrkt_name;
            let lvl2marketName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].raw_mrkt_name;
            let lvl3marketName =
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets[index5].raw_mrkt_name;
            if (
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets[index5].lvl3markets === undefined
            ) {
                setIsLoading(true);
                const showLvl3MrktsResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}\\${lvl3marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}\\${lvl3marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                console.log('response', showLvl3MrktsResp.data)
                let response = showLvl3MrktsResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                        index4
                    ].lvl2markets[index5].expanded = true;
                    marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                        index4
                    ].lvl2markets[index5].lvl3markets = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets[index5].expanded = true;
                marketChannelTemp.FMCGRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets[index5].lvl3markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    async function showSpecialityRetailerslvl2Markets(index, index1, index2, index3, index4) {
        let allMarketData = getCurrentAllMarketData();
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        const marketSelType =
            marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                index4
            ]?.mrkt_sel_typ || '';
        if (
            marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                index4
            ].expanded
        ) {
            marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                index4
            ].expanded = false;
            setMarketChannel(marketChannelTemp);
        } else {
            let folderName = marketChannelTemp.specialtyRetailers[index].raw_mrkt_name;
            let companyName = marketChannelTemp.specialtyRetailers[index].companies[index1].raw_mrkt_name;
            let marketName = marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].raw_mrkt_name;
            let subFolderName =
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3]
                    .raw_mrkt_name;
            let lvl2marketName =
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].raw_mrkt_name;
            if (
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].lvl2markets === undefined
            ) {
                setIsLoading(true);
                const speclRetlLvl2MrktsResp = props.requestFromDod ?
                    await dodMarketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        ...props?.subSelectors?.markets?.marketSections,
                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                    : await marketLookup({
                        reportType: props.reportType,
                        mrktHier: `${folderName}\\${companyName}\\${marketName}\\${subFolderName}\\${lvl2marketName}`,
                        mrktGrp: props.marketGroup.toUpperCase(),
                        mrktSelType: marketSelType || undefined,
                        categories: selectedCategories || [],
                        ...props?.subSelectors?.markets?.marketSections,
                    });
                let response = speclRetlLvl2MrktsResp.data;
                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            response.markets.forEach((val, ind) => {
                                if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedChannel = true;
                                }
                                if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                    val.selectedRetailer = true;
                                }
                                if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                    val.selected = true;
                                }
                            });
                        });
                    }
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                        index3
                    ].markets[index4].expanded = true;
                    marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                        index3
                    ].markets[index4].lvl2markets = response.markets;
                }
                setIsLoading(false);
                setMarketChannel(marketChannelTemp);
            } else {
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[index3].markets[
                    index4
                ].expanded = true;
                marketChannelTemp.specialtyRetailers[index].companies[index1].market[index2].subFolders[
                    index3
                ].markets[index4].lvl2markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setMarketChannel(marketChannelTemp);
            }
        }
    }

    const validateRemainingMarkets = (selected, secondlySelected = false) => {
        let validationMsg = 'Two remaining markets can not be selected for a retailer.';
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));

        if (props?.subSelectors?.markets?.requiredRemMarketForEachRetailer) {
            if (selected || secondlySelected) {
                // marketChannelTemp.FMCGRetailers[index].companies[index2].market[index3].selectedChannel = false;
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(validationMsg);
                setMarketChannel(marketChannelTemp);
                return true;
            }
        }
    };

    function marketSelected(fmValue = false, valueChecked, categories, index, index2, index3, index4, index5, index6, index7) {
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
        let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
        let category = categories.split('$$')[0];
        let marketType = categories.split('$$')[1];
        let validForm = false;
        let SelectedName = '';
        let SelectedMarketNameTemp;
        let fmcgData;
        let marketNameKey = '';
        if (category.includes('FMCGRetailers') && index >= 0 && index2 >= 0) {
            fmcgData = marketChannelTemp.FMCGRetailers[index]?.companies[index2];
        }

        let specialityMarketsTemp;
        if (category.includes('SpecialityRetailers') && index >= 0 && index2 >= 0 && index3 >= 0) {
            specialityMarketsTemp = marketChannelTemp.specialtyRetailers[index]?.companies[index2]?.market[index3];
        }

        let masterCompany = '';
        if (props?.subSelectors?.markets?.noSelection) {
            masterCompany = fmcgData?.master_company ?? specialityMarketsTemp?.master_company;

            if (category === 'FMCGRetailers-subfolders' && fmLimit === 1 && valueChecked && marketType === undefined) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(' You can only choose markets from the same parent company. This market is in a separate company than your prior selections.');
                setMarketChannel(marketChannelTemp);
                return true;
            }

            if (
                (marketType === 'channel' || marketType === 'retailer' || marketType === 'comparitive') &&
                !fmValue &&
                valueChecked &&
                marketType !== undefined
            ) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage('Only remaining market of the selected focus market can be chosen');
                return true;
            }

            //BYZ-5559: Market selection logic
            if (selectedMasterCompany !== '' && selectedMasterCompany !== masterCompany) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(' You can only choose markets from the same parent company. This market is in a separate company than your prior selections.');
                setMarketChannel(marketChannelTemp);
                return true;
            }
        }
        if (!props?.subSelectors?.markets?.noSelection && fmLimit === 1 && valueChecked && marketType === undefined) {
            setShowWarning(true);
            setContentType('warning');
            setWarningMessage(' You can only choose markets from the same parent company. This market is in a separate company than your prior selections.');
            setMarketChannel(marketChannelTemp);
            return true;
        }
        if (!props?.subSelectors?.markets?.noSelection && rmLimit === 1 && valueChecked && marketType !== undefined) {
            setShowWarning(true);
            setContentType('warning');
            setWarningMessage('Only remaining market of the selected focus market can be chosen');
            return true;
        }

        if (category === 'FMCGRetailers-folders') {
            marketChannelTemp.FMCGRetailers[index].selected = marketChannelTemp.FMCGRetailers[index].selected
                ? false
                : true;
            let selected = marketChannelTemp.FMCGRetailers[index].selected;
            SelectedMarketNameTemp = marketChannelTemp.FMCGRetailers[index].name;
            if (selected) {
                selectedMarketTemp.push(marketChannelTemp.FMCGRetailers[index].name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === marketChannelTemp.FMCGRetailers[index].name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-companies') {
            marketChannelTemp.FMCGRetailers[index].companies[index2].selected = marketChannelTemp.FMCGRetailers[index]
                .companies[index2].selected
                ? false
                : true;
            let selected = marketChannelTemp.FMCGRetailers[index].companies[index2].selected;
            SelectedMarketNameTemp = marketChannelTemp.FMCGRetailers[index].companies[index2].name;
            if (selected) {
                selectedMarketTemp.push(marketChannelTemp.FMCGRetailers[index].companies[index2].name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === marketChannelTemp.FMCGRetailers[index].companies[index2].name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-subfolders') {
            //Story category Review
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].selectedRetailer,
                    fmcgData.market[index3].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].selectedChannel ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                fmcgData.market[index3].selectedChannel = selected;
                name = fmcgData.market[index3].rm_channel_mrkt_disp_name;
                marketNameKey = 'rm_channel_mrkt_disp_name';
                SelectedName = fmcgData.market[index3].name;
                if (
                    selected ||
                    fmcgData.market[index3].selectedRetailer ||
                    fmcgData.market[index3].selectedComparitive
                ) {
                    if (fmcgData.market[index3].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].selectedChannel,
                    fmcgData.market[index3].selectedComparitive
                );
                if (validate) {
                    return true;
                }

                selected = fmcgData.market[index3].selectedRetailer ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                fmcgData.market[index3].selectedRetailer = selected;
                name = fmcgData.market[index3].rm_xaoc_mrkt_disp_name;
                marketNameKey = 'rm_xaoc_mrkt_disp_name';
                SelectedName = fmcgData.market[index3].name;
                if (
                    selected ||
                    fmcgData.market[index3].selectedChannel ||
                    fmcgData.market[index3].selectedComparitive
                ) {
                    if (fmcgData.market[index3].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].selectedChannel,
                    fmcgData.market[index3].selectedRetailer
                );
                if (validate) {
                    return true;
                }

                selected = fmcgData.market[index3].selectedComparitive ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                fmcgData.market[index3].selectedComparitive = selected;
                name = fmcgData.market[index3].comparative_mkt_disp_name;
                marketNameKey = 'comparative_mkt_disp_name';
                SelectedName = fmcgData.market[index3].name;
                if (selected || fmcgData.market[index3].selectedChannel || fmcgData.market[index3].selectedRetailer) {
                    if (fmcgData.market[index3].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = fmcgData.market[index3].selected ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setfmLimit(fmLimit + 1);
                } else {
                    setfmLimit(fmLimit - 1);
                }
                fmcgData.market[index3].selected = selected;
                name = fmcgData.market[index3].name;
                marketNameKey = 'name';

                if (
                    fmcgData.market[index3].selectedRetailer ||
                    fmcgData.market[index3].selectedChannel ||
                    fmcgData.market[index3].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-subfolder-markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].selectedRetailer,
                    fmcgData.market[index3].subFolders[index4].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].selectedChannel ? false : true;
                fmcgData.market[index3].subFolders[index4].selectedChannel = selected;
                name = fmcgData.market[index3].subFolders[index4].rm_channel_mrkt_disp_name;
                marketNameKey = 'rm_channel_mrkt_disp_name';
                SelectedName = fmcgData.market[index3].subFolders[index4].name;
                // const name = marketChannelTemp.FMCGRetailers[index].
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].selectedRetailer ? false : true;
                fmcgData.market[index3].subFolders[index4].selectedRetailer = selected;
                name = fmcgData.market[index3].subFolders[index4].rm_xaoc_mrkt_disp_name;
                marketNameKey = 'rm_xaoc_mrkt_disp_name';
                SelectedName = fmcgData.market[index3].subFolders[index4].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].selectedComparitive ? false : true;
                fmcgData.market[index3].subFolders[index4].selectedComparitive = selected;
                name = fmcgData.market[index3].subFolders[index4].comparative_mkt_disp_name;
                marketNameKey = 'comparative_mkt_disp_name';
                SelectedName = fmcgData.market[index3].subFolders[index4].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].selectedRetailer
                ) {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = fmcgData.market[index3].subFolders[index4].selected ? false : true;
                fmcgData.market[index3].subFolders[index4].selected = selected;
                name = fmcgData.market[index3].subFolders[index4].name;
                marketNameKey = 'name';

                if (
                    fmcgData.market[index3].subFolders[index4].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-lvl2markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer,
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel ? false : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].rm_channel_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer ? false : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].rm_xaoc_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].comparative_mkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].selected ? false : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].selected = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].name;

                if (
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-lvl3markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                    .selectedChannel
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].selectedChannel = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                        .rm_channel_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                    .selectedRetailer
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].selectedRetailer = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                        .rm_xaoc_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                    .selectedComparitive
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].selectedComparitive = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6]
                        .comparative_mkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selected = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].name;

                if (
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            // fmcgData.market[index3].subFolders[index4].markets[
            //     index5
            // ].lvl2markets[index6].selected = fmcgData.market[index3]
            //     .subFolders[index4].markets[index5].lvl2markets[index6].selected
            //     ? false
            //     : true;
            // let selected =
            //     fmcgData.market[index3].subFolders[index4].markets[
            //         index5
            //     ].lvl2markets[index6].selected;
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'FMCGRetailers-lvl4markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedRetailer,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                    .selectedChannel
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].lvl3markets[index7].selectedChannel = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                        .rm_channel_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                    .selectedRetailer
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].lvl3markets[index7].selectedRetailer = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                        .rm_xaoc_mrkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedComparitive
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedChannel,
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                    .selectedComparitive
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].lvl3markets[index7].selectedComparitive = selected;
                name =
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7]
                        .comparative_mkt_disp_name;
                SelectedName = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].name;
                if (
                    selected ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedRetailer
                ) {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected
                    ? false
                    : true;
                fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selected = selected;
                name = fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].name;

                if (
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedRetailer ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedChannel ||
                    fmcgData.market[index3].subFolders[index4].markets[index5].lvl2markets[index6].lvl3markets[index7].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            // fmcgData.market[index3].subFolders[index4].markets[
            //     index5
            // ].lvl2markets[index6].selected = fmcgData.market[index3]
            //     .subFolders[index4].markets[index5].lvl2markets[index6].selected
            //     ? false
            //     : true;
            // let selected =
            //     fmcgData.market[index3].subFolders[index4].markets[
            //         index5
            //     ].lvl2markets[index6].selected;
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-folders') {
            marketChannelTemp.FMCGRetailers[index].selected = marketChannelTemp.specialtyRetailers[index].selected
                ? false
                : true;
            let selected = marketChannelTemp.specialtyRetailers[index].selected;
            SelectedMarketNameTemp = marketChannelTemp.specialtyRetailers[index].name;
            if (selected) {
                selectedMarketTemp.push(marketChannelTemp.specialtyRetailers[index].name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === marketChannelTemp.specialtyRetailers[index].name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-companies') {
            marketChannelTemp.specialtyRetailers[index].companies[index2].selected = marketChannelTemp
                .specialtyRetailers[index].companies[index2].selected
                ? false
                : true;
            let selected = marketChannelTemp.specialtyRetailers[index].companies[index2].selected;
            SelectedMarketNameTemp = marketChannelTemp.specialtyRetailers[index].companies[index2].name;
            if (selected) {
                selectedMarketTemp.push(marketChannelTemp.specialtyRetailers[index].companies[index2].name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === marketChannelTemp.specialtyRetailers[index].companies[index2].name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-subfolders') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.selectedRetailer,
                    specialityMarketsTemp.selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.selectedChannel ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                specialityMarketsTemp.selectedChannel = selected;
                name = specialityMarketsTemp.rm_channel_mrkt_disp_name;
                marketNameKey = 'rm_channel_mrkt_disp_name';
                SelectedName = specialityMarketsTemp.name;
                if (selected || specialityMarketsTemp.selectedRetailer || specialityMarketsTemp.selectedComparitive) {
                    if (specialityMarketsTemp.selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.selectedChannel,
                    specialityMarketsTemp.selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.selectedRetailer ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                marketChannelTemp.specialtyRetailers[index].companies[index2].market[
                    index3
                ].selectedRetailer = selected;
                name = specialityMarketsTemp.rm_xaoc_mrkt_disp_name;
                marketNameKey = 'rm_xaoc_mrkt_disp_name';
                SelectedName = specialityMarketsTemp.name;
                if (selected || specialityMarketsTemp.selectedChannel || specialityMarketsTemp.selectedComparitive) {
                    if (specialityMarketsTemp.selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.selectedChannel,
                    specialityMarketsTemp.selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.selectedComparitive ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setrmLimit(rmLimit + 1);
                } else {
                    setrmLimit(rmLimit - 1);
                }
                specialityMarketsTemp.selectedComparitive = selected;
                name = specialityMarketsTemp.comparative_mkt_disp_name;
                marketNameKey = 'comparative_mkt_disp_name';
                SelectedName = specialityMarketsTemp.name;
                if (selected || specialityMarketsTemp.selectedChannel || specialityMarketsTemp.selectedRetailer) {
                    if (specialityMarketsTemp.selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = specialityMarketsTemp.selected ? false : true;
                if (props?.subSelectors?.markets?.marketoptions && selected) {
                    setfmLimit(fmLimit + 1);
                } else {
                    setfmLimit(fmLimit - 1);
                }
                specialityMarketsTemp.selected = selected;
                name = specialityMarketsTemp.name;
                marketNameKey = 'name';

                if (
                    specialityMarketsTemp.selectedRetailer ||
                    specialityMarketsTemp.selectedChannel ||
                    specialityMarketsTemp.selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-subfolder-markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].selectedRetailer,
                    specialityMarketsTemp.subFolders[index4].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].selectedChannel ? false : true;
                specialityMarketsTemp.subFolders[index4].selectedChannel = selected;
                name = specialityMarketsTemp.subFolders[index4].rm_channel_mrkt_disp_name;
                marketNameKey = 'rm_channel_mrkt_disp_name';
                SelectedName = specialityMarketsTemp.subFolders[index4].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].selectedRetailer ||
                    specialityMarketsTemp.subFolders[index4].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].selectedRetailer ? false : true;
                specialityMarketsTemp.subFolders[index4].selectedRetailer = selected;
                name = specialityMarketsTemp.subFolders[index4].rm_xaoc_mrkt_disp_name;
                marketNameKey = 'rm_xaoc_mrkt_disp_name';
                SelectedName = specialityMarketsTemp.subFolders[index4].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].selectedComparitive ? false : true;
                specialityMarketsTemp.subFolders[index4].selectedComparitive = selected;
                name = specialityMarketsTemp.subFolders[index4].comparative_mkt_disp_name;
                marketNameKey = 'comparative_mkt_disp_name';
                SelectedName = specialityMarketsTemp.subFolders[index4].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].selectedRetailer
                ) {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = specialityMarketsTemp.subFolders[index4].selected ? false : true;
                specialityMarketsTemp.subFolders[index4].selected = selected;
                name = specialityMarketsTemp.subFolders[index4].name;
                marketNameKey = 'name';

                if (
                    selected &&
                    (specialityMarketsTemp.subFolders[index4].selectedRetailer ||
                        specialityMarketsTemp.subFolders[index4].selectedChannel ||
                        specialityMarketsTemp.subFolders[index4].selectedComparitive)
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-lvl2markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer,
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel ? false : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel = selected;
                name = specialityMarketsTemp.subFolders[index4].markets[index5].rm_channel_mrkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer ? false : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer = selected;
                name = specialityMarketsTemp.subFolders[index4].markets[index5].rm_xaoc_mrkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive ? false : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive = selected;
                name = specialityMarketsTemp.subFolders[index4].markets[index5].comparative_mkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].selected ? false : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].selected = selected;
                name = specialityMarketsTemp.subFolders[index4].markets[index5].name;

                if (
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (selected) {
                        formValidationItems[name] = false;
                    } else {
                        delete formValidationItems[name];
                    }
                }
            }
            // specialityMarketsTemp.subFolders[index4].markets[
            //     index5
            // ].selected = specialityMarketsTemp.subFolders[index4]
            //     .markets[index5].selected
            //     ? false
            //     : true;
            // let selected =
            // specialityMarketsTemp.subFolders[index4].markets[index5].selected;
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        } else if (category === 'SpecialityRetailers-lvl3markets') {
            let selected = false;
            let name = '';
            if (marketType === 'channel') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer,
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel
                    ? false
                    : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel = selected;
                name =
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6]
                        .rm_channel_mrkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
                // const name = marketChannelTemp.FMCGRetailers[index].
            } else if (marketType === 'retailer') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer
                    ? false
                    : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].selectedRetailer = selected;
                name =
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].rm_xaoc_mrkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedComparitive
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else if (marketType === 'comparitive') {
                const validate = validateRemainingMarkets(
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel,
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer
                );
                if (validate) {
                    return true;
                }
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6]
                    .selectedComparitive
                    ? false
                    : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[
                    index6
                ].selectedComparitive = selected;
                name =
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6]
                        .comparative_mkt_disp_name;
                SelectedName = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].name;
                if (
                    selected ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selectedRetailer
                ) {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        validForm = true;
                        formValidationItems[SelectedName] = true;
                    } else {
                        validForm = false;
                        formValidationItems[SelectedName] = false;
                    }
                } else {
                    if (specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected) {
                        formValidationItems[SelectedName] = false;
                    } else {
                        delete formValidationItems[SelectedName];
                    }
                }
            } else {
                selected = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected
                    ? false
                    : true;
                specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].selected = selected;
                name = specialityMarketsTemp.subFolders[index4].markets[index5].lvl2markets[index6].name;

                if (
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedRetailer ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedChannel ||
                    specialityMarketsTemp.subFolders[index4].markets[index5].selectedComparitive
                ) {
                    if (selected) {
                        validForm = true;
                        formValidationItems[name] = true;
                    } else {
                        validForm = false;
                        formValidationItems[name] = false;
                    }
                } else {
                    if (!selected) {
                        delete formValidationItems[name];
                    } else {
                        formValidationItems[name] = false;
                    }
                }
            }
            SelectedMarketNameTemp = name;
            if (selected) {
                selectedMarketTemp.push(name);
            } else {
                selectedMarketTemp.forEach((market, marketNumber) => {
                    if (market === name) {
                        selectedMarketTemp.splice(marketNumber, 1);
                    }
                });
            }
        }

        if (validateExtractLimits(selectedMarketTemp.length || 0)) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        setMarketChannel(marketChannelTemp);
        setSelectedMarket(selectedMarketTemp);
        // setExtractsMarketSelection('By Channel', SelectedMarketNameTemp);
        let isMarketSelected = selectedMarketTemp.length > 0 ? true : false;
        setIsMarketSelected(isMarketSelected);
        setIsValidFormItems(formValidationItems);
        setShowUpdateButton();

        if (props?.subSelectors?.markets?.marketoptions && !props?.subSelectors?.markets?.noSelection) {
            if (category === 'FMCGRetailers-subfolders')
                loadMarkets(
                    marketChannelTemp.FMCGRetailers[index].companies[index2].market,
                    marketChannelTemp,
                    formValidationItems,
                    category,
                    index,
                    index2,
                    index3,
                    marketType,
                    selectedMarketTemp
                );
            if (category === 'SpecialityRetailers-subfolders')
                loadMarkets(
                    marketChannelTemp.specialtyRetailers[index].companies[index2].market,
                    marketChannelTemp,
                    formValidationItems,
                    category,
                    index,
                    index2,
                    index3,
                    marketType,
                    selectedMarketTemp
                );
        }
        //Added for stories feature to add information about selected market
        if (
            props.productType === 'story' &&
            props.marketType === 'focusMarket' &&
            selectedMarketTemp?.length !== 0 &&
            selectedMarketItemLvlVal?.length === undefined
        ) {
            let marketLevelInfo;
            if ((index4 === undefined || index4 === null) && index3 !== undefined && index3 !== null) {
                marketLevelInfo = {
                    marketName: selectedMarketTemp[selectedMarketTemp.length - 1],
                    marketLevel: 'level_1',
                    marketType: marketType,
                };
            } else if (
                index4 !== undefined &&
                index4 !== null &&
                index3 !== undefined &&
                index3 !== null &&
                selectedMarketItemLvlVal.length === undefined
            ) {
                marketLevelInfo = {
                    marketName: selectedMarketTemp[selectedMarketTemp.length - 1],
                    marketLevel: 'level_2',
                    marketType: marketType,
                };
            } else if (
                (index3 === undefined || index3 === null) &&
                index2 !== undefined &&
                index2 !== null &&
                index !== undefined &&
                index !== null
            ) {
                marketLevelInfo = {
                    marketName: selectedMarketTemp[selectedMarketTemp.length - 1],
                    marketLevel: 'level_0',
                    marketType: marketType,
                };
            }
            setSelectedMarketItemLvlVal(marketLevelInfo);
        } else if (
            props.productType === 'story' &&
            props.marketType === 'focusMarket' &&
            selectedMarketTemp.length === 0
        ) {
            setSelectedMarketItemLvlVal({});
        }

        if (
            props?.productType === 'story' &&
            valueChecked &&
            props.subSelectors?.markets?.checkCharacterLimit &&
            marketType !== marketTypeValues.CHANNEL &&
            marketType !== marketTypeValues.RETAILER
        ) {
            checkMarketCharacterLimit(selectedMarketTemp);
        }

        //BYZ-5559: Set selected master company
        if (props?.subSelectors?.markets?.noSelection) {
            if (selectedMarketTemp.length === 0) {
                setSelectedMasterCompany('');
            } else if (selectedMarketTemp.length === 1) {
                setSelectedMasterCompany(masterCompany);
            }
        }
        return false;
    }

    async function showGeographyMarkets(index) {
        let allMarketData = getCurrentAllMarketData();
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        const marketSelType = geographyTemp[index]?.mrkt_sel_typ || '';
        if (geographyTemp[index].expanded) {
            geographyTemp[index].expanded = false;
            setGeography(geographyTemp);
        } else {
            let folderName = geographyTemp[index].raw_mrkt_name;
            if (geographyTemp[index].markets === undefined) {
                setIsLoading(true);
                const geoMrktsResp = props.requestFromDod ?
                await dodMarketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    ...props?.subSelectors?.markets?.marketSections,
                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                : await marketLookup({
                    reportType: props.reportType,
                    mrktHier: `${folderName}`,
                    mrktGrp: props.marketGroup.toUpperCase(),
                    mrktSelType: marketSelType || undefined,
                    categories: selectedCategories || [],
                    ...props?.subSelectors?.markets?.marketSections,
                });
                let response = geoMrktsResp.data;

                if (response !== undefined) {
                    if (props?.productType === 'story' || alertWithAllMarketData) {
                        response.markets.forEach(addSelectedStatetoMarket);
                    } else {
                        response.markets.forEach((val, ind) => {
                            if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                                val.selected = true;
                            }
                            if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                                val.selectedChannel = true;
                            }
                            if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                                val.selectedRetailer = true;
                            }
                        });
                        geographyTemp[index].expanded = true;
                        geographyTemp[index].markets = response.markets;
                    }
                    geographyTemp[index].expanded = true;
                    geographyTemp[index].markets = response.markets;
                }
                setIsLoading(false);
                setGeography(geographyTemp);
            } else {
                geographyTemp[index].expanded = true;
                geographyTemp[index].markets?.forEach((val, ind) => {
                    if (selectedMarket.includes(val.rm_channel_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedChannel = true;
                    }
                    if (selectedMarket.includes(val.rm_xaoc_mrkt_disp_name) || extractsCheckCondition()) {
                        val.selectedRetailer = true;
                    }
                    if (selectedMarket.includes(val.raw_mrkt_name) || extractsCheckCondition()) {
                        val.selected = true;
                    }
                });
                setGeography(geographyTemp);
            }
        }
    }
    function geographySingleMarketSelected(index) {
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        geographyTemp[index].selected = geographyTemp[index].selected ? false : true;
        let selected = geographyTemp[index].selected;
        if (selected) {
            selectedMarketTemp.push(geographyTemp[index].name);
        } else {
            selectedMarketTemp.forEach((market, marketNumber) => {
                if (market === geographyTemp[index].name) {
                    selectedMarketTemp.splice(marketNumber, 1);
                }
            });
        }
        if (validateExtractLimits(selectedMarketTemp.length)) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        let isMarketSelected = selectedMarketTemp.length > 0 ? true : false;
        setSelectedMarket(selectedMarketTemp);
        setGeography(geographyTemp);
        // setExtractsMarketSelection('By Geography', geographyTemp[index].markets[index1].name);
        setIsMarketSelected(isMarketSelected);
        if (
            props?.productType === 'story' &&
            geographyTemp[index].selected &&
            props.subSelectors?.markets?.checkCharacterLimit
        ) {
            checkMarketCharacterLimit(selectedMarketTemp);
        }
    }
    function geographyMarketSelected(index, index1, type = 'market') {
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket || 0));
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
        let selected;
        let name;
        let marketlvl =
            geographyTemp[index].markets[index1]?.marketLevel ?? getMarketLevel(geographyTemp[index].markets[index1]);
        if (type === 'market') {
            geographyTemp[index].markets[index1].selected = geographyTemp[index].markets[index1].selected
                ? false
                : true;
            selected = geographyTemp[index].markets[index1].selected;
            name = geographyTemp[index].markets[index1].name;
            geographyTemp[index].markets[index1].marketType = marketTypeValues.REGULAR;
        } else if (type === 'comparative') {
            geographyTemp[index].markets[index1].selectedComparitive = geographyTemp[index].markets[index1]
                .selectedComparitive
                ? false
                : true;
            selected = geographyTemp[index].markets[index1].selectedComparitive;
            name = geographyTemp[index].markets[index1].comparative_mkt_disp_name;
            geographyTemp[index].markets[index1].marketType = marketTypeValues.COMPARATIVE;
        }
        // let selected = geographyTemp[index].markets[index1].selected;
        if (selected) {
            selectedMarketTemp.push(name);
        } else {
            selectedMarketTemp.forEach((market, marketNumber) => {
                if (market === name) {
                    selectedMarketTemp.splice(marketNumber, 1);
                }
            });
        }
        if (validateExtractLimits(selectedMarketTemp.length || 0)) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        let SelectedName = '';
        SelectedName = geographyTemp[index].markets[index1].name;
        if (
            geographyTemp[index].markets[index1].selected ||
            geographyTemp[index].markets[index1].selectedRetailer ||
            geographyTemp[index].markets[index1].selectedComparitive
        ) {
            if (geographyTemp[index].markets[index1].selected) {
                formValidationItems[SelectedName] = true;
                if (
                    props?.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
                    !geographyTemp[index].markets[index1].selectedRetailer &&
                    !geographyTemp[index].markets[index1].selectedComparitive
                ) {
                    formValidationItems[SelectedName] = false;
                }
            } else {
                formValidationItems[SelectedName] = false;
            }
        } else {
            if (geographyTemp[index].markets[index1].selected) {
                formValidationItems[SelectedName] = false;
            } else {
                delete formValidationItems[SelectedName];
            }
        }
        let isMarketSelected = selectedMarketTemp.length > 0 ? true : false;
        setIsValidFormItems(formValidationItems);
        setSelectedMarket(selectedMarketTemp);
        setGeography(geographyTemp);
        // setExtractsMarketSelection('By Geography', geographyTemp[index].markets[index1].name);
        setIsMarketSelected(isMarketSelected);
        setShowUpdateButton();
        setSelectedMarketItemLevelValue(
            selectedMarketTemp,
            name,
            marketlvl,
            geographyTemp[index].markets[index1].marketType
        );

        if (
            props?.productType === 'story' &&
            geographyTemp[index].markets[index1].selected &&
            props.subSelectors?.markets?.checkCharacterLimit
        ) {
            checkMarketCharacterLimit(selectedMarketTemp);
        }
    }
    const validateExtractLimits = (selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket || [])).length) => {
        if (props.extracts && props.extractsLimit && selectedMarketTemp > props.extractsLimit) {
            return true;
        }
        return false;
    };

    function totalUSMarketSelected(index, type = 'market') {
        let totalUSMarketTemp = JSON.parse(JSON.stringify(totalUSMarket));
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
        let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
        let selected;
        let name;
        let marketlvl = totalUSMarketTemp[index]?.marketLevel ?? getMarketLevel(totalUSMarketTemp[index]);
        if (type === 'market') {
            totalUSMarketTemp[index].selected = totalUSMarketTemp[index].selected ? false : true;
            selected = totalUSMarketTemp[index].selected;
            name = totalUSMarketTemp[index].name;
            totalUSMarketTemp[index].marketType = marketTypeValues.REGULAR;
        } else if (type === 'comparative') {
            totalUSMarketTemp[index].selectedComparitive = totalUSMarketTemp[index].selectedComparitive ? false : true;
            selected = totalUSMarketTemp[index].selectedComparitive;
            name = totalUSMarketTemp[index].comparative_mkt_disp_name;
            totalUSMarketTemp[index].marketType = marketTypeValues.COMPARATIVE;
        }
        if (selected) {
            selectedMarketTemp.push(name);
        } else {
            selectedMarketTemp.forEach((market, marketNumber) => {
                if (market === name) {
                    selectedMarketTemp.splice(marketNumber, 1);
                }
            });
        }
        if (validateExtractLimits(selectedMarketTemp.length || 0)) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        let SelectedName = '';
        SelectedName = totalUSMarketTemp[index].name;
        if (
            totalUSMarketTemp[index].selected ||
            totalUSMarketTemp[index].selectedRetailer ||
            totalUSMarketTemp[index].selectedComparitive
        ) {
            if (totalUSMarketTemp[index].selected) {
                formValidationItems[SelectedName] = true;
                if (
                    props?.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
                    !totalUSMarketTemp[index].selectedRetailer &&
                    !totalUSMarketTemp[index].selectedComparitive
                ) {
                    formValidationItems[SelectedName] = false;
                }
            } else {
                formValidationItems[SelectedName] = false;
            }
        } else {
            if (totalUSMarketTemp[index].selected) {
                formValidationItems[SelectedName] = false;
            } else {
                delete formValidationItems[SelectedName];
            }
        }
        let isMarketSelected = selectedMarketTemp.length > 0 ? true : false;
        // setExtractsMarketSelection('Total US Markets', totalUSMarketTemp[index].name);
        setIsValidFormItems(formValidationItems);
        setTotalUSMarket(totalUSMarketTemp);
        setSelectedMarket(selectedMarketTemp);
        setIsMarketSelected(isMarketSelected);
        setShowUpdateButton();
        setSelectedMarketItemLevelValue(selectedMarketTemp, name, marketlvl, totalUSMarketTemp[index].marketType);
        if (
            props?.productType === 'story' &&
            totalUSMarketTemp[index].selected &&
            props.subSelectors?.markets?.checkCharacterLimit
        ) {
            checkMarketCharacterLimit(selectedMarketTemp);
        }
    }

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState = JSON.parse(JSON.stringify(props?.selectorState));
        if (props?.productType === 'story') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            props.setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
            props.generateReport(selectionsToSelState);
        }
        if (props?.saveBtnType === 'alertSelector') {
            selectionsToSelState.selectorsStatus[props.stepNo].status = selectorStates.pending;
            props.setSelectorsData({ selectorsStatus: selectionsToSelState.selectorsStatus });
        }
    };

    //Added for stories feature to add information about selected market
    const setSelectedMarketItemLevelValue = (selectedMarketTemp, marketName, marketlvl, marketType) => {
        if (props.productType === 'story' && props.marketType === 'focusMarket') {
            let marketLevelInfo;
            if (selectedMarketTemp?.length > 0 && selectedMarketItemLvlVal?.marketLevel === undefined) {
                marketLevelInfo = {
                    marketName: marketName,
                    marketLevel: marketlvl,
                    marketType: marketType,
                };
                setSelectedMarketItemLvlVal(JSON.parse(JSON.stringify(marketLevelInfo)));
            } else if (selectedMarketTemp?.length === undefined || selectedMarketTemp?.length === 0) {
                setSelectedMarketItemLvlVal({});
            }
        }
    };

    const afterSearchMarketSelected = (
        searchResultMarketsTemp,
        selectedMarketTemp,
        marketType,
        index,
        allMarketData
    ) => {
        let count = 0;
        searchResultMarketsTemp.forEach((market) => {
            if (market.selected) {
                count++;
            } else {
                // if(!props.extracts){
                //     if (marketType === 'channel') {
                //         checkLimit(market.stores, market.selectedChannel, market.mrkt_sel_typ);
                //     } else if (marketType === 'retailer') {
                //         checkLimit(market.fmcgRet, market.selectedMarket, market.mrkt_sel_typ);
                //     } else {
                //         checkLimit(market.name, market.selected, market.mrkt_sel_typ);
                //     }
                // }
            }
        });

        if (props?.productType === 'story' || alertWithAllMarketData) {
            if (selectedMarketTemp?.length) {
                setsearchResultMarkets(searchResultMarketsTemp);
                setIsMarketSelected(true);
                setSelectedMarket(selectedMarketTemp);
            } else {
                setsearchResultMarkets(searchResultMarketsTemp);
                setIsMarketSelected(false);
                setSelectedMarket(selectedMarketTemp);
                if (props?.productType === 'story') {
                    props.selectorState.comparisonMarketSelections = selectedMarketTemp;
                }
            }
        } else {
            if (count > 0) {
                setIsSearchMarketSelected(true);
                setsearchResultMarkets(searchResultMarketsTemp);
                setIsMarketSelected(true);
                setSelectedMarket(selectedMarketTemp);
            } else {
                setIsSearchMarketSelected(false);
                setsearchResultMarkets(searchResultMarketsTemp);
                setIsMarketSelected(false);
                setSelectedMarket(selectedMarketTemp);
            }
        }
    };
    async function asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }
    const searchMarketSelected = async (checked, index, marketType) => {
        let searchResultMarketsTemp = JSON.parse(JSON.stringify(searchResultMarkets));
        let marketChannelTemp = JSON.parse(JSON.stringify(marketChannel));
        let totalUSMarketTemp = JSON.parse(JSON.stringify(totalUSMarket));
        let selectedMarketTemp = JSON.parse(JSON.stringify(selectedMarket));
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        let allMarketData = [];
        if ((props?.productType === 'story' && props?.marketType === 'focusMarket') || alertWithAllMarketData) {
            allMarketData = JSON.parse(JSON.stringify(focusMarketsData));
        } else if (props?.productType === 'story' && props?.marketType === 'comparisonMarket') {
            allMarketData = JSON.parse(JSON.stringify(comparisonMarketsData));
        }
        let formValidationItems = JSON.parse(JSON.stringify(isValidFormItems));
        let marketNameKey = '';
        let count = 0;

        let marketlvl = searchResultMarketsTemp[index].marketLevel;
        let market = searchResultMarketsTemp[index].name;
        searchResultMarketsTemp[index].marketType = marketType !== undefined ? marketType : marketTypeValues.REGULAR;
        let marketName;
        let marketKey;
        if (marketType === 'channel') {
            marketName = searchResultMarketsTemp[index].stores;
            marketNameKey = 'rm_channel_mrkt_disp_name'; //"stores";
            marketKey = searchResultMarketsTemp[index].rm_channel_mrkt_key;
        } else if (marketType === 'retailer') {
            marketName = searchResultMarketsTemp[index].fmcgRet;
            marketNameKey = 'rm_xaoc_mrkt_disp_name'; //"fmcgRet";
            marketKey = searchResultMarketsTemp[index].rm_xaoc_mrkt_key;
        } else if (marketType === 'comparative') {
            marketName =
                props?.productType === 'story' || alertWithAllMarketData
                    ? searchResultMarketsTemp[index].comparative_mkt_disp_name
                    : searchResultMarketsTemp[index].comparativeMarket;
            marketNameKey = 'comparative_mkt_disp_name'; //"comparative";
            marketKey = searchResultMarketsTemp[index].comparative_mrkt_key; // To be changed once comp key db column name declared
        } else {
            marketType = 'regular';
            marketName = market;
            marketNameKey = 'name';
            marketKey = searchResultMarketsTemp[index].mrkt_key;
        }

        //BYZ-5559: market selection logic
        let masterCompany = '';
        if (props?.subSelectors?.markets?.noSelection) {
            const pathFolders = searchResultMarketsTemp[index].parent_folder_path
                ? searchResultMarketsTemp[index].parent_folder_path.split('\\')
                : [];
            masterCompany = pathFolders.length >= 1 ? pathFolders[1] : market;

            if (selectedMasterCompany !== '' && selectedMasterCompany !== masterCompany) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(' You can only choose markets from the same parent company. This market is in a separate company than your prior selections.');
                setMarketChannel(marketChannelTemp);
                return true;
            }
        }

        // let category = categories.split('$$')[0];
        // let marketType = categories.split('$$')[1];
        const marketSelected = searchResultMarketsTemp[index].selected ? false : true;
        const selectedChannel = searchResultMarketsTemp[index].selectedChannel ? false : true;
        const selectedMarketRet = searchResultMarketsTemp[index].selectedMarket ? false : true;
        const selectedComparitive = searchResultMarketsTemp[index].selectedComparitive ? false : true;
        let length = checked ? selectedMarketTemp.length + 1 : selectedMarketTemp.length - 1;
        if (validateExtractLimits(length || 0)) {
            if (props.showErrorMsg) {
                props.showErrorMsg();
            }
            return true;
        }
        if (marketType === 'channel') {
            searchResultMarketsTemp[index].selectedChannel = JSON.parse(JSON.stringify(selectedChannel));
            if (validateRemainingMarkets(!selectedMarketRet, !selectedComparitive)) {
                return true;
            }
            marketKey = searchResultMarketsTemp[index].rm_channel_mrkt_key;
        } else if (marketType === 'retailer') {
            searchResultMarketsTemp[index].selectedMarket = JSON.parse(JSON.stringify(selectedMarketRet));
            if (validateRemainingMarkets(!selectedChannel, !selectedComparitive)) {
                return true;
            }
            marketKey = searchResultMarketsTemp[index].rm_xaoc_mrkt_key;
        } else if (marketType === 'comparative') {
            searchResultMarketsTemp[index].selectedComparitive = JSON.parse(JSON.stringify(selectedComparitive));
            if (validateRemainingMarkets(!selectedChannel, !selectedMarketRet)) {
                return true;
            }
        } else {
            searchResultMarketsTemp[index].selected = JSON.parse(JSON.stringify(marketSelected));
        }
        setIsLoading(true);
        const marketObj = {
            name: marketName,
            mrkt_key: marketKey,
        };

        if (Object.keys(formValidationItems).includes(searchResultMarketsTemp[index].name)) {
            if (searchResultMarketsTemp[index].selected) {
                if (
                    searchResultMarketsTemp[index].selectedMarket ||
                    searchResultMarketsTemp[index].selectedChannel ||
                    searchResultMarketsTemp[index].selectedComparitive
                ) {
                    formValidationItems[searchResultMarketsTemp[index].name] = true;
                } else {
                    formValidationItems[searchResultMarketsTemp[index].name] = false;
                }
            } else {
                if (
                    searchResultMarketsTemp[index].selectedMarket ||
                    searchResultMarketsTemp[index].selectedChannel ||
                    searchResultMarketsTemp[index].selectedComparitive
                ) {
                    formValidationItems[searchResultMarketsTemp[index].name] = false;
                } else {
                    delete formValidationItems[searchResultMarketsTemp[index].name];
                }
            }
        } else {
            if (searchResultMarketsTemp[index].selected) {
                if (
                    searchResultMarketsTemp[index].selectedMarket ||
                    searchResultMarketsTemp[index].selectedChannel ||
                    searchResultMarketsTemp[index].selectedComparitive
                ) {
                    formValidationItems[searchResultMarketsTemp[index].name] = true;
                } else {
                    formValidationItems[searchResultMarketsTemp[index].name] = false;
                }
            } else {
                if (
                    searchResultMarketsTemp[index].selectedMarket ||
                    searchResultMarketsTemp[index].selectedChannel ||
                    searchResultMarketsTemp[index].selectedComparitive
                ) {
                    formValidationItems[searchResultMarketsTemp[index].name] = false;
                }
            }
        }
        setIsValidFormItems(JSON.parse(JSON.stringify(formValidationItems)));

        if (
            props.folder === 'Statelines' ||
            props.folder === 'Major Markets' ||
            props.folder === 'Regions' ||
            props.folder === 'Divisions'
        ) {
            const geoIndex = geography.findIndex((val) => val.name === searchResultMarketsTemp[index].name);
            if (geoIndex !== -1) {
                setsearchResultMarkets(JSON.parse(JSON.stringify(searchResultMarketsTemp)));
                geographySingleMarketSelected(geoIndex);
                checkLimit(marketObj, checked, searchResultMarketsTemp[index].mrkt_sel_typ);
            }
            setIsLoading(false);
            return true;
        }
        // if (searchResultMarketsTemp[index].selected) {
        let searchResultMarketsTempCopy = JSON.parse(JSON.stringify(searchResultMarketsTemp[index]));
        // Adding 2 keys to preselect markets in comparison market panel
        searchResultMarketsTempCopy.rm_channel_mrkt_disp_name = searchResultMarketsTemp[index].stores;
        searchResultMarketsTempCopy.rm_xaoc_mrkt_disp_name = searchResultMarketsTemp[index].fmcgRet;

        let findIndex = selectedMarketTemp.findIndex((val) => val === marketName);
        if (findIndex === -1) {
            selectedMarketTemp.push(JSON.parse(JSON.stringify(marketName)));
        } else {
            selectedMarketTemp.splice(findIndex, 1);
        }
        let mainRes;
        if (!limitedMarketSubscription) {
            // For #3714, we reuse search function to render markets, so escaping ancestry calls
            mainRes = await marketAncestry(market, searchResultMarketsTemp[index].marketSelType);
        }
        if (!limitedMarketSubscription && mainRes?.status === 200) {
            let mainResData = mainRes.data;
            if (Array.isArray(mainResData)) {
                mainResData = mainResData[0];
            }
            if (mainResData.mrkt_sel_typ === 'By Geography') {
                let parent_folder_path = mainResData.parent_folder_path;
                geographyTemp.forEach(async (geo) => {
                    if (geo.name === parent_folder_path) {
                        let folderName = geo.name;
                        const geoLookupResp = props.requestFromDod ?
                            await dodMarketLookup({
                                reportType: props.reportType,
                                mrktHier: `${folderName}`,
                                mrktGrp: props.marketGroup.toUpperCase(),
                                mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                ...props?.subSelectors?.markets?.marketSections,
                            }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                                reportType: props.reportType,
                                mrktHier: `${folderName}`,
                                mrktGrp: props.marketGroup.toUpperCase(),
                                mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                categories: selectedCategories || [],
                                ...props?.subSelectors?.markets?.marketSections,
                            });
                        let response = geoLookupResp.data;
                        if (response !== undefined) {
                            geo.expanded = true;
                            geo.markets = geo.markets !== undefined ? geo.markets : response.markets;

                            // preselect any existing market for story
                            selectMarketsInArray(geo.markets);
                            if (props.productType == 'story' || alertWithAllMarketData) {
                                geo.markets.forEach((item) => {
                                    if (
                                        marketType === 'channel' &&
                                        item.rm_channel_mrkt_disp_name === marketName &&
                                        item.name === market
                                    ) {
                                        item.selectedChannel = selectedChannel;
                                    } else if (
                                        marketType === 'retailer' &&
                                        item.rm_xaoc_mrkt_disp_name === marketName &&
                                        item.name === market
                                    ) {
                                        item.selectedRetailer = selectedMarketRet;
                                    } else if (
                                        marketType === 'comparative' &&
                                        item.comparative_mkt_disp_name === marketName &&
                                        item.name === market
                                    ) {
                                        item.selectedComparitive = selectedComparitive;
                                    } else if (
                                        marketType === 'regular' &&
                                        item.raw_mrkt_name === marketName &&
                                        item.name === market
                                    ) {
                                        item.selected = marketSelected;
                                    }
                                });
                            } else {
                                geo.markets.forEach((item, ind, arr) => {
                                    // item.selected = item.selected ? true : false;
                                    // item.selectedChannel = item.selectedChannel ? true : false;
                                    // item.selectedMarket = item.selectedMarket ? true : false;
                                    if (marketType === 'channel') {
                                        if (item.rm_channel_mrkt_disp_name === marketName) {
                                            item.selectedChannel = selectedChannel;
                                        }
                                    } else if (marketType === 'retailer') {
                                        if (item.rm_xaoc_mrkt_disp_name === marketName) {
                                            item.selectedRetailer = selectedMarketRet;
                                        }
                                    } else if (marketType === 'comparative') {
                                        if (item.comparative_mkt_disp_name === marketName) {
                                            item.selectedComparitive = selectedComparitive;
                                        }
                                    } else {
                                        if (item.raw_mrkt_name === marketName) {
                                            item.selected = marketSelected;
                                        }
                                    }
                                });
                            }
                        }
                        setIsLoading(false);
                        setGeography(JSON.parse(JSON.stringify(geographyTemp)));
                        afterSearchMarketSelected(
                            JSON.parse(JSON.stringify(searchResultMarketsTemp)),
                            JSON.parse(JSON.stringify(selectedMarketTemp)),
                            marketType,
                            index,
                            JSON.parse(JSON.stringify(allMarketData))
                        );
                    }
                });
            } else if (
                mainResData.mrkt_sel_typ === 'By Channel FMCG' ||
                mainResData.mrkt_sel_typ === 'Panel By Channel'
            ) {
                let parent_folder_path = mainResData.parent_folder_path;
                let paths = parent_folder_path.split('\\');
                // let i = 0;
                // for (let folders of marketChannelTemp.FMCGRetailers) {
                // const foldersIndex = i;
                // asyncForEach()
                await asyncForEach(marketChannelTemp.FMCGRetailers, async (folders, foldersIndex, foldersArr) => {
                    if (folders.raw_mrkt_name === paths[0]) {
                        folders.expanded = true;
                        if (paths[1] !== undefined) {
                            const channelMrktLookupResp = props.requestFromDod ?
                                await dodMarketLookup({
                                    reportType: props.reportType,
                                    mrktHier: paths[0],
                                    mrktGrp: props.marketGroup.toUpperCase(),
                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                    ...props?.subSelectors?.markets?.marketSections,
                                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                                : await marketLookup({
                                    reportType: props.reportType,
                                    mrktHier: paths[0],
                                    mrktGrp: props.marketGroup.toUpperCase(),
                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                    categories: selectedCategories || [],
                                    ...props?.subSelectors?.markets?.marketSections,
                                });
                            let response = channelMrktLookupResp.data;
                            if (response !== undefined) {
                                folders.companies =
                                    folders.companies !== undefined ? folders.companies : response.markets;

                                selectMarketsInArray(folders.companies);
                                if (folders.companies !== undefined) {
                                    await asyncForEach(folders.companies, async (company) => {
                                        if (company.displayName === paths[1]) {
                                            company.expanded = true;
                                            const companyLookup = props.requestFromDod ?
                                                await dodMarketLookup({
                                                    reportType: props.reportType,
                                                    mrktHier: `${paths[0]}\\${paths[1]}`,
                                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                    mrktGrp: props.marketGroup.toUpperCase(),
                                                    ...props?.subSelectors?.markets?.marketSections,
                                                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                                                    reportType: props.reportType,
                                                    mrktHier: `${paths[0]}\\${paths[1]}`,
                                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                    mrktGrp: props.marketGroup.toUpperCase(),
                                                    categories: selectedCategories || [],
                                                    ...props?.subSelectors?.markets?.marketSections,
                                                });
                                            let response = companyLookup.data;
                                            if (response !== undefined) {
                                                if (response.markets !== undefined) {
                                                    company.market =
                                                        company.market !== undefined
                                                            ? company.market
                                                            : response.markets;

                                                    selectMarketsInArray(company.market);
                                                    await asyncForEach(company.market, async (marketIn) => {
                                                        let stts = false;
                                                        if (marketType === 'channel') {
                                                            if (
                                                                marketIn.rm_channel_mrkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedChannel = selectedChannel;
                                                            }
                                                        } else if (marketType === 'retailer') {
                                                            if (
                                                                marketIn.rm_xaoc_mrkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedRetailer = selectedMarketRet;
                                                            }
                                                        } else if (marketType === 'comparative') {
                                                            if (
                                                                (props.productType === 'story' ||
                                                                    alertWithAllMarketData) &&
                                                                marketIn.comparative_mkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedComparitive = selectedComparitive;
                                                            } else if (
                                                                `${marketIn.comparative_mkt_disp_name}$$${marketIn.comparative_mrkt_key}$$${marketIn.mrkt_key}` ===
                                                                marketName
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedComparitive = selectedComparitive;
                                                            }
                                                        } else {
                                                            if (marketIn.raw_mrkt_name === marketName) {
                                                                stts = true;
                                                                marketIn.selected = marketSelected;
                                                            }
                                                        }
                                                        if (stts) {
                                                            setMarketChannel(marketChannelTemp);
                                                        } else {
                                                            if (paths[2] !== undefined) {
                                                                const subfoldLookup = props.requestFromDod ?
                                                                    await dodMarketLookup({
                                                                        reportType: props.reportType,
                                                                        mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}`,
                                                                        mrktGrp: props.marketGroup.toUpperCase(),
                                                                        mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                                        ...props?.subSelectors?.markets?.marketSections,
                                                                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection)
                                                                    : await marketLookup({
                                                                        reportType: props.reportType,
                                                                        mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}`,
                                                                        mrktGrp: props.marketGroup.toUpperCase(),
                                                                        mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                                        categories: selectedCategories || [],
                                                                        ...props?.subSelectors?.markets?.marketSections,
                                                                    });
                                                                let response = subfoldLookup.data;
                                                                if (response !== undefined) {
                                                                    if (marketIn.raw_mrkt_name === paths[2]) {
                                                                        marketIn.expanded = true;
                                                                        marketIn.subFolders =
                                                                            marketIn.subFolders !== undefined
                                                                                ? marketIn.subFolders
                                                                                : response.markets;

                                                                        selectMarketsInArray(marketIn.subFolders);
                                                                        await asyncForEach(
                                                                            marketIn.subFolders,
                                                                            async (subfolder) => {
                                                                                let stts = false;
                                                                                if (marketType === 'channel') {
                                                                                    if (
                                                                                        subfolder.rm_channel_mrkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedChannel = selectedChannel;
                                                                                    }
                                                                                } else if (marketType === 'retailer') {
                                                                                    if (
                                                                                        subfolder.rm_xaoc_mrkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedRetailer = selectedMarketRet;
                                                                                    }
                                                                                } else if (
                                                                                    marketType === 'comparative'
                                                                                ) {
                                                                                    if (
                                                                                        (props.productType ===
                                                                                            'story' ||
                                                                                            alertWithAllMarketData) &&
                                                                                        subfolder.comparative_mkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedComparitive = selectedComparitive;
                                                                                    } else if (
                                                                                        `${subfolder.comparative_mkt_disp_name}$$${subfolder.comparative_mrkt_key}$$${subfolder.mrkt_key}` ===
                                                                                        marketName
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedComparitive = selectedComparitive;
                                                                                    }
                                                                                } else {
                                                                                    if (
                                                                                        subfolder.raw_mrkt_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selected = marketSelected;
                                                                                    }
                                                                                }
                                                                                if (stts) {
                                                                                    marketIn.expanded = true;
                                                                                    setMarketChannel(
                                                                                        JSON.parse(
                                                                                            JSON.stringify(
                                                                                                marketChannelTemp
                                                                                            )
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    if (paths[3] !== undefined) {
                                                                                        const lvl2marketLookupResp = props.requestFromDod ?
                                                                                        await dodMarketLookup(
                                                                                            {
                                                                                                reportType:
                                                                                                    props.reportType,
                                                                                                mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}\\${paths[3]}`,
                                                                                                mrktGrp: props.marketGroup.toUpperCase(),
                                                                                                mrktSelType:
                                                                                                    mainResData.mrkt_sel_typ ||
                                                                                                    undefined,
                                                                                                ...props?.subSelectors
                                                                                                    ?.markets
                                                                                                    ?.marketSections,
                                                                                            }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection
                                                                                        ) : await marketLookup(
                                                                                            {
                                                                                                reportType:
                                                                                                    props.reportType,
                                                                                                mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}\\${paths[3]}`,
                                                                                                mrktGrp: props.marketGroup.toUpperCase(),
                                                                                                mrktSelType:
                                                                                                    mainResData.mrkt_sel_typ ||
                                                                                                    undefined,
                                                                                                categories:
                                                                                                    selectedCategories ||
                                                                                                    [],
                                                                                                ...props?.subSelectors
                                                                                                    ?.markets
                                                                                                    ?.marketSections,
                                                                                            }
                                                                                        );
                                                                                        let response =
                                                                                            lvl2marketLookupResp.data;
                                                                                        if (response !== undefined) {
                                                                                            if (
                                                                                                subfolder.raw_mrkt_name ===
                                                                                                paths[3]
                                                                                            ) {
                                                                                                subfolder.expanded = true;
                                                                                                subfolder.markets =
                                                                                                    subfolder.markets !==
                                                                                                        undefined
                                                                                                        ? subfolder.markets
                                                                                                        : response.markets;

                                                                                                selectMarketsInArray(
                                                                                                    subfolder.markets
                                                                                                );
                                                                                                await asyncForEach(
                                                                                                    subfolder.markets,
                                                                                                    async (
                                                                                                        lvl2markets
                                                                                                    ) => {
                                                                                                        let stts = false;
                                                                                                        if (
                                                                                                            marketType ===
                                                                                                            'channel'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                lvl2markets.rm_channel_mrkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedChannel = selectedChannel;
                                                                                                            }
                                                                                                        } else if (
                                                                                                            marketType ===
                                                                                                            'retailer'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                lvl2markets.rm_xaoc_mrkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedRetailer = selectedMarketRet;
                                                                                                            }
                                                                                                        } else if (
                                                                                                            marketType ===
                                                                                                            'comparative'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                (props.productType ===
                                                                                                                    'story' ||
                                                                                                                    alertWithAllMarketData) &&
                                                                                                                lvl2markets.comparative_mkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedComparitive = selectedComparitive;
                                                                                                            } else if (
                                                                                                                `${lvl2markets.comparative_mkt_disp_name}$$${lvl2markets.comparative_mrkt_key}$$${lvl2markets.mrkt_key}` ===
                                                                                                                marketName
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedComparitive = selectedComparitive;
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (
                                                                                                                lvl2markets.raw_mrkt_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selected = marketSelected;
                                                                                                            }
                                                                                                        }
                                                                                                        if (stts) {
                                                                                                            subfolder.expanded = true;
                                                                                                            setMarketChannel(
                                                                                                                JSON.parse(
                                                                                                                    JSON.stringify(
                                                                                                                        marketChannelTemp
                                                                                                                    )
                                                                                                                )
                                                                                                            );
                                                                                                        } else {
                                                                                                            if (
                                                                                                                paths[4] !==
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                const lvl3marketLookupResp = props.requestFromDod ?
                                                                                                                    await dodMarketLookup(
                                                                                                                        {
                                                                                                                            reportType:
                                                                                                                                props.reportType,
                                                                                                                            mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}\\${paths[3]}\\${paths[4]}`,
                                                                                                                            mrktGrp: props.marketGroup.toUpperCase(),
                                                                                                                            mrktSelType:
                                                                                                                                mainResData.mrkt_sel_typ ||
                                                                                                                                undefined,
                                                                                                                            ...props
                                                                                                                                ?.subSelectors
                                                                                                                                ?.markets
                                                                                                                                ?.marketSections,
                                                                                                                        }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection
                                                                                                                    ) : await marketLookup(
                                                                                                                        {
                                                                                                                            reportType:
                                                                                                                                props.reportType,
                                                                                                                            mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}\\${paths[3]}\\${paths[4]}`,
                                                                                                                            mrktGrp: props.marketGroup.toUpperCase(),
                                                                                                                            mrktSelType:
                                                                                                                                mainResData.mrkt_sel_typ ||
                                                                                                                                undefined,
                                                                                                                            categories:
                                                                                                                                selectedCategories ||
                                                                                                                                [],
                                                                                                                            ...props
                                                                                                                                ?.subSelectors
                                                                                                                                ?.markets
                                                                                                                                ?.marketSections,
                                                                                                                        }
                                                                                                                    );
                                                                                                                let response =
                                                                                                                    lvl3marketLookupResp.data;
                                                                                                                if (
                                                                                                                    response !==
                                                                                                                    undefined
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        lvl2markets.raw_mrkt_name ===
                                                                                                                        paths[4]
                                                                                                                    ) {
                                                                                                                        lvl2markets.expanded = true;
                                                                                                                        lvl2markets.markets =
                                                                                                                            lvl2markets.markets !==
                                                                                                                                undefined
                                                                                                                                ? lvl2markets.markets
                                                                                                                                : response.markets;

                                                                                                                        selectMarketsInArray(
                                                                                                                            lvl2markets.markets
                                                                                                                        );
                                                                                                                        await asyncForEach(
                                                                                                                            lvl2markets.markets,
                                                                                                                            async (
                                                                                                                                lvl3markets
                                                                                                                            ) => {
                                                                                                                                let stts = false;
                                                                                                                                if (
                                                                                                                                    marketType ===
                                                                                                                                    'channel'
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        lvl3markets.rm_channel_mrkt_disp_name ===
                                                                                                                                        marketName &&
                                                                                                                                        lvl3markets.name ==
                                                                                                                                        market
                                                                                                                                    ) {
                                                                                                                                        stts = true;
                                                                                                                                        lvl3markets.selectedChannel = selectedChannel;
                                                                                                                                    }
                                                                                                                                } else if (
                                                                                                                                    marketType ===
                                                                                                                                    'retailer'
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        lvl3markets.rm_xaoc_mrkt_disp_name ===
                                                                                                                                        marketName &&
                                                                                                                                        lvl3markets.name ==
                                                                                                                                        market
                                                                                                                                    ) {
                                                                                                                                        stts = true;
                                                                                                                                        lvl3markets.selectedRetailer = selectedMarketRet;
                                                                                                                                    }
                                                                                                                                } else if (
                                                                                                                                    marketType ===
                                                                                                                                    'comparative'
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        (props.productType ===
                                                                                                                                            'story' ||
                                                                                                                                            alertWithAllMarketData) &&
                                                                                                                                        lvl3markets.comparative_mkt_disp_name ===
                                                                                                                                        marketName &&
                                                                                                                                        lvl3markets.name ==
                                                                                                                                        market
                                                                                                                                    ) {
                                                                                                                                        stts = true;
                                                                                                                                        lvl3markets.selectedComparitive = selectedComparitive;
                                                                                                                                    } else if (
                                                                                                                                        `${lvl3markets.comparative_mkt_disp_name}$$${lvl3markets.comparative_mrkt_key}$$${lvl3markets.mrkt_key}` ===
                                                                                                                                        marketName
                                                                                                                                    ) {
                                                                                                                                        stts = true;
                                                                                                                                        lvl3markets.selectedComparitive = selectedComparitive;
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    if (
                                                                                                                                        lvl3markets.raw_mrkt_name ===
                                                                                                                                        marketName &&
                                                                                                                                        lvl3markets.name ==
                                                                                                                                        market
                                                                                                                                    ) {
                                                                                                                                        stts = true;
                                                                                                                                        lvl3markets.selected = marketSelected;
                                                                                                                                    }
                                                                                                                                }
                                                                                                                                if (
                                                                                                                                    stts
                                                                                                                                ) {
                                                                                                                                    lvl2markets.lvl2markets = JSON.parse(
                                                                                                                                        JSON.stringify(
                                                                                                                                            lvl2markets.markets
                                                                                                                                        )
                                                                                                                                    );
                                                                                                                                    lvl2markets.expanded = true;
                                                                                                                                    setMarketChannel(
                                                                                                                                        JSON.parse(
                                                                                                                                            JSON.stringify(
                                                                                                                                                marketChannelTemp
                                                                                                                                            )
                                                                                                                                        )
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
                setIsLoading(false);
                afterSearchMarketSelected(
                    JSON.parse(JSON.stringify(searchResultMarketsTemp)),
                    JSON.parse(JSON.stringify(selectedMarketTemp)),
                    marketType,
                    index,
                    JSON.parse(JSON.stringify(allMarketData))
                );
            } else if (mainResData.mrkt_sel_typ === 'By Channel Speciality') {
                let parent_folder_path = mainResData.parent_folder_path;
                let paths = parent_folder_path.split('\\');
                await asyncForEach(marketChannelTemp.specialtyRetailers, async (folders, foldersIndex, foldersArr) => {
                    if (folders.raw_mrkt_name === paths[0]) {
                        folders.expanded = true;
                        if (paths[1] !== undefined) {
                            const specialityLookupResp = props.requestFromDod ?
                                await dodMarketLookup({
                                    reportType: props.reportType,
                                    mrktHier: `${paths[0]}`,
                                    mrktGrp: props.marketGroup.toUpperCase(),
                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                    ...props?.subSelectors?.markets?.marketSections,
                                }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                                    reportType: props.reportType,
                                    mrktHier: `${paths[0]}`,
                                    mrktGrp: props.marketGroup.toUpperCase(),
                                    mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                    categories: selectedCategories || [],
                                    ...props?.subSelectors?.markets?.marketSections,
                                });
                            let response = specialityLookupResp.data;
                            if (response !== undefined) {
                                folders.companies =
                                    folders.companies !== undefined ? folders.companies : response.markets;

                                selectMarketsInArray(folders.companies);
                                if (folders.companies !== undefined) {
                                    await asyncForEach(folders.companies, async (company) => {
                                        if (company.displayName === paths[1]) {
                                            company.expanded = true;
                                            const speclCompLookupResp = props.requestFromDod ?
                                            await dodMarketLookup({
                                                reportType: props.reportType,
                                                mrktHier: `${paths[0]}\\${paths[1]}`,
                                                mrktGrp: props.marketGroup.toUpperCase(),
                                                mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                ...props?.subSelectors?.markets?.marketSections,
                                            }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                                                reportType: props.reportType,
                                                mrktHier: `${paths[0]}\\${paths[1]}`,
                                                mrktGrp: props.marketGroup.toUpperCase(),
                                                mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                categories: selectedCategories || [],
                                                ...props?.subSelectors?.markets?.marketSections,
                                            });
                                            let response = speclCompLookupResp.data;
                                            if (response !== undefined) {
                                                if (response.markets !== undefined) {
                                                    company.market =
                                                        company.market !== undefined
                                                            ? company.market
                                                            : response.markets;

                                                    selectMarketsInArray(company.market);
                                                    await asyncForEach(company.market, async (marketIn) => {
                                                        let stts = false;
                                                        if (marketType === 'channel') {
                                                            if (
                                                                marketIn.rm_channel_mrkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedChannel = selectedChannel;
                                                            }
                                                        } else if (marketType === 'retailer') {
                                                            if (
                                                                marketIn.rm_xaoc_mrkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedRetailer = selectedMarketRet;
                                                            }
                                                        } else if (marketType === 'comparative') {
                                                            if (
                                                                (props.productType === 'story' ||
                                                                    alertWithAllMarketData) &&
                                                                marketIn.comparative_mkt_disp_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedComparitive = selectedComparitive;
                                                            } else if (
                                                                `${marketIn.comparative_mkt_disp_name}$$${marketIn.comparative_mrkt_key}$$${marketIn.mrkt_key}` ===
                                                                marketName
                                                            ) {
                                                                stts = true;
                                                                marketIn.selectedComparitive = selectedComparitive;
                                                            }
                                                        } else {
                                                            if (
                                                                marketIn.raw_mrkt_name === marketName &&
                                                                marketIn.name == market
                                                            ) {
                                                                stts = true;
                                                                marketIn.selected = marketSelected;
                                                            }
                                                        }
                                                        if (stts) {
                                                            setMarketChannel(
                                                                JSON.parse(JSON.stringify(marketChannelTemp))
                                                            );
                                                        } else {
                                                            if (paths[2] !== undefined) {
                                                                const speclSubfoldLookupResp = props.requestFromDod ?
                                                                    await dodMarketLookup({
                                                                        reportType: props.reportType,
                                                                        mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}`,
                                                                        mrktGrp: props.marketGroup.toUpperCase(),
                                                                        mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                                        ...props?.subSelectors?.markets?.marketSections,
                                                                    }, props.normalCategories, props.summedCategories, props.summedCategoryObjects, props.isSumSelection) : await marketLookup({
                                                                        reportType: props.reportType,
                                                                        mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}`,
                                                                        mrktGrp: props.marketGroup.toUpperCase(),
                                                                        mrktSelType: mainResData.mrkt_sel_typ || undefined,
                                                                        categories: selectedCategories || [],
                                                                        ...props?.subSelectors?.markets?.marketSections,
                                                                    });
                                                                let response = speclSubfoldLookupResp.data;
                                                                if (response !== undefined) {
                                                                    if (marketIn.raw_mrkt_name === paths[2]) {
                                                                        marketIn.expanded = true;
                                                                        marketIn.subFolders =
                                                                            marketIn.subFolders !== undefined
                                                                                ? marketIn.subFolders
                                                                                : response.markets;

                                                                        selectMarketsInArray(marketIn.subFolders);
                                                                        await asyncForEach(
                                                                            marketIn.subFolders,
                                                                            async (subfolder) => {
                                                                                let stts = false;
                                                                                if (marketType === 'channel') {
                                                                                    if (
                                                                                        subfolder.rm_channel_mrkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedChannel = selectedChannel;
                                                                                    }
                                                                                } else if (marketType === 'retailer') {
                                                                                    if (
                                                                                        subfolder.rm_xaoc_mrkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedRetailer = selectedMarketRet;
                                                                                    }
                                                                                } else if (
                                                                                    marketType === 'comparative'
                                                                                ) {
                                                                                    if (
                                                                                        (props.productType ===
                                                                                            'story' ||
                                                                                            alertWithAllMarketData) &&
                                                                                        subfolder.comparative_mkt_disp_name ===
                                                                                        marketName &&
                                                                                        subfolder.name == market
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedComparitive = selectedComparitive;
                                                                                    } else if (
                                                                                        `${subfolder.comparative_mkt_disp_name}$$${subfolder.comparative_mrkt_key}$$${subfolder.mrkt_key}` ===
                                                                                        marketName
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selectedComparitive = selectedComparitive;
                                                                                    }
                                                                                } else {
                                                                                    if (
                                                                                        subfolder.raw_mrkt_name ===
                                                                                        marketName
                                                                                    ) {
                                                                                        stts = true;
                                                                                        subfolder.selected = marketSelected;
                                                                                    }
                                                                                }
                                                                                if (stts) {
                                                                                    marketIn.expanded = true;
                                                                                    setMarketChannel(
                                                                                        JSON.parse(
                                                                                            JSON.stringify(
                                                                                                marketChannelTemp
                                                                                            )
                                                                                        )
                                                                                    );
                                                                                } else {
                                                                                    if (paths[3] !== undefined) {
                                                                                        const spclLvl2MrktLookupResp = await marketLookup(
                                                                                            {
                                                                                                reportType:
                                                                                                    props.reportType,
                                                                                                mrktHier: `${paths[0]}\\${paths[1]}\\${paths[2]}\\${paths[3]}`,
                                                                                                mrktGrp: props.marketGroup.toUpperCase(),
                                                                                                mrktSelType:
                                                                                                    mainResData.mrkt_sel_typ ||
                                                                                                    undefined,
                                                                                                ...props?.subSelectors
                                                                                                    ?.markets
                                                                                                    ?.marketSections,
                                                                                            }
                                                                                        );
                                                                                        let response =
                                                                                            spclLvl2MrktLookupResp.data;
                                                                                        if (response !== undefined) {
                                                                                            if (
                                                                                                subfolder.raw_mrkt_name ===
                                                                                                paths[3]
                                                                                            ) {
                                                                                                subfolder.expanded = true;
                                                                                                subfolder.markets =
                                                                                                    subfolder.markets !==
                                                                                                        undefined
                                                                                                        ? subfolder.markets
                                                                                                        : response.markets;

                                                                                                selectMarketsInArray(
                                                                                                    subfolder.markets
                                                                                                );
                                                                                                await asyncForEach(
                                                                                                    subfolder.markets,
                                                                                                    async (
                                                                                                        lvl2markets
                                                                                                    ) => {
                                                                                                        let stts = false;
                                                                                                        if (
                                                                                                            marketType ===
                                                                                                            'channel'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                lvl2markets.rm_channel_mrkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedChannel = selectedChannel;
                                                                                                            }
                                                                                                        } else if (
                                                                                                            marketType ===
                                                                                                            'retailer'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                lvl2markets.rm_xaoc_mrkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedRetailer = selectedMarketRet;
                                                                                                            }
                                                                                                        } else if (
                                                                                                            marketType ===
                                                                                                            'comparative'
                                                                                                        ) {
                                                                                                            if (
                                                                                                                (props.productType ===
                                                                                                                    'story' ||
                                                                                                                    alertWithAllMarketData) &&
                                                                                                                lvl2markets.comparative_mkt_disp_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedComparitive = selectedComparitive;
                                                                                                            } else if (
                                                                                                                `${lvl2markets.comparative_mkt_disp_name}$$${lvl2markets.comparative_mrkt_key}$$${lvl2markets.mrkt_key}` ===
                                                                                                                marketName
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selectedComparitive = selectedComparitive;
                                                                                                            }
                                                                                                        } else {
                                                                                                            if (
                                                                                                                lvl2markets.raw_mrkt_name ===
                                                                                                                marketName &&
                                                                                                                lvl2markets.name ==
                                                                                                                market
                                                                                                            ) {
                                                                                                                stts = true;
                                                                                                                lvl2markets.selected = marketSelected;
                                                                                                            }
                                                                                                        }
                                                                                                        if (stts) {
                                                                                                            subfolder.expanded = true;
                                                                                                            setMarketChannel(
                                                                                                                JSON.parse(
                                                                                                                    JSON.stringify(
                                                                                                                        marketChannelTemp
                                                                                                                    )
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
                setIsLoading(false);
                afterSearchMarketSelected(
                    JSON.parse(JSON.stringify(searchResultMarketsTemp)),
                    JSON.parse(JSON.stringify(selectedMarketTemp)),
                    marketType,
                    index,
                    JSON.parse(JSON.stringify(allMarketData))
                );
            } else if (
                mainResData.mrkt_sel_typ === 'Total US' ||
                mainResData.mrkt_sel_typ === 'Panel Total US Markets'
            ) {
                if (props.productType === 'story' || alertWithAllMarketData) {
                    totalUSMarketTemp.forEach((item) => {
                        if (
                            marketType === 'channel' &&
                            item.rm_channel_mrkt_disp_name === marketName &&
                            item.name === market
                        ) {
                            item.selectedChannel = selectedChannel;
                        } else if (
                            marketType === 'retailer' &&
                            item.rm_xaoc_mrkt_disp_name === marketName &&
                            item.name === market
                        ) {
                            item.selectedRetailer = selectedMarketRet;
                        } else if (
                            marketType === 'comparative' &&
                            item.comparative_mkt_disp_name === marketName &&
                            item.name === market
                        ) {
                            item.selectedComparitive = selectedComparitive;
                        } else if (
                            marketType === 'regular' &&
                            item.raw_mrkt_name === marketName &&
                            item.name === market
                        ) {
                            item.selected = marketSelected;
                        }
                    });
                } else {
                    totalUSMarketTemp.forEach((item) => {
                        if (item.name === market) {
                            item.selected = marketSelected;
                        }
                    });
                }
                setIsLoading(false);
                setTotalUSMarket(JSON.parse(JSON.stringify(totalUSMarketTemp)));
                afterSearchMarketSelected(
                    JSON.parse(JSON.stringify(searchResultMarketsTemp)),
                    JSON.parse(JSON.stringify(selectedMarketTemp)),
                    marketType,
                    index,
                    JSON.parse(JSON.stringify(allMarketData))
                );
            }
        }

        // Added for stories feature to add information about selected market
        if (props.productType === 'story' && props.marketType === 'focusMarket') {
            let marketLevelInfo;
            if (selectedMarketTemp?.length > 0 && selectedMarketItemLvlVal?.marketLevel === undefined) {
                marketLevelInfo = {
                    marketName: marketName,
                    marketLevel: marketlvl,
                    marketType: marketType,
                };
                setSelectedMarketItemLvlVal(JSON.parse(JSON.stringify(marketLevelInfo)));
            } else if (selectedMarketTemp?.length === undefined || selectedMarketTemp?.length === 0) {
                setSelectedMarketItemLvlVal({});
            }
        }

        if (
            props?.productType === 'story' &&
            checked &&
            props.subSelectors?.markets?.checkCharacterLimit &&
            marketType !== marketTypeValues.CHANNEL &&
            marketType !== marketTypeValues.RETAILER
        ) {
            checkMarketCharacterLimit(selectedMarketTemp);
        }
        if (limitedMarketSubscription) {
            // #3714 code addition
            afterSearchMarketSelected(
                JSON.parse(JSON.stringify(searchResultMarketsTemp)),
                JSON.parse(JSON.stringify(selectedMarketTemp)),
                marketType,
                index,
                JSON.parse(JSON.stringify(allMarketData))
            );
            setIsLoading(false);
        }

        if (props.saveBtnType === 'alertSelector' || props.productType === 'story') {
            setShowUpdateButton();
        }

        // BYZ-5559: Set selected master company
        if (props?.subSelectors?.markets?.noSelection) {
            if (selectedMarketTemp.length === 0) {
                setSelectedMasterCompany('');
            } else if (selectedMarketTemp.length === 1) {
                setSelectedMasterCompany(masterCompany);
            }
        }

        setSelectedItems([
            {
                name: marketName,
                marketKey,
            },
        ]);
        return false;
    };

    const searchMarketSubmit = () => {
        setDisplayMarketTree(props.marketGroup !== 'omni');
        setMarketSearchKeyword('');
        if (props.marketGroup === 'omni') {
            const blankVal = {
                // Mock event value being passed to reuse the same onchange function
                target: {
                    value: '',
                },
            };
            searchMarketItems(blankVal);
        }
    };

    const singleMarketSelectFromSearch = (marketName, marketKey, marketSelType) => {
        if (props.from === 'reportSelector') {
            if (props.marketGroup === 'omni') {
                props.setSelectorsData(
                    isSubMarket ? { subMarket: { marketName, marketKey } } : { totalMarket: { marketName, marketKey } }
                );
            } else {
                props.setSelectorsData({ focusMarket: marketName });
            }
        } else {
            props.setPreferenceState({ primaryMarket: marketName, marketSelType });
        }
        if (props.marketGroup !== 'omni') {
            setSelectedItems([
                {
                    name: marketName,
                    marketKey,
                },
            ]);
        }
        setIsSearchMarketSelected(true);
        setIsMarketSelected(true);
        if (props.productType === 'story' || props.saveBtnType === 'alertSelector') {
            setShowUpdateButton();
        }
    };

    const submitMarketSelections = (submitType = '') => {
        // let selectedMarkets = selectedMarket.map(value => value.split('$$')[0]);
        if (
            props?.subSelectors?.markets?.requiredRemMarketForEachRetailer &&
            (props.productType !== 'story' ||
                (props.productType === 'story' && props?.subSelectors?.markets?.warnSelectMarketInPairs))
        ) {
            if (
                (Object.values(isValidFormItems).length === 0 || Object.values(isValidFormItems).includes(false)) &&
                ((!props?.subSelectors?.markets?.requiredRemMarketForTotalMarkets &&
                    !props?.subSelectors?.markets?.requiredRemMarketForGeography &&
                    !props?.subSelectors?.markets?.smartAccount) ||
                    props?.subSelectors?.markets?.smartAccount)
            ) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(
                    'Please make sure to select remaining/comparative market for each markets you selected.'
                );
                return true;
            }
        }

        if (props.saveBtnType === 'alertSelector' || props.saveBtnType === 'scorecardSelector') {
            props.setSelectorsData({
                focusMarketSelections: props.selectorState.focusMarket
                    ? [props.selectorState.focusMarket]
                    : selectedItems.map((val) => val.name.split('$$')[0]) || [],
                status: selectorStates.completed,
                selectedItems: selectedItems,
                focusMarketsData: focusMarketsData,
                isMarketValidFormItems: isValidFormItems,
            });
            // let selectionsToSelState = { ...props.selectorState };
            // selectionsToSelState.focusMarketSelections = marketData;
            // props.generateReport(selectionsToSelState);
            return true;
        }
        if (props.from === 'reportSelector') {
            const marketData =
                props.marketGroup === 'omni'
                    ? {
                        omniMarketKeys: selectedItems.map((item) => item.marketKey),
                        omniTotalMarketKey: props.selectorState?.totalMarket?.marketKey ?? '',
                        omniSubMarketKey: props.selectorState?.subMarket?.marketKey ?? '',
                    }
                    : {
                        focusMarket: {
                            byMarketName:
                                subSelectorTypeVal !== 'marketTypeReport' &&
                                    (props.marketGroup === 'cps' || props.productType === 'story')
                                    ? {
                                        // For options 1/2/3/4
                                        marketGroup: props.marketGroup, // RMS for options 1/2, CPS for options 3/4
                                        markets: props.subSelectors?.markets?.multi
                                            ? selectedItems.map((mrktData) => mrktData.name.split('$$')[0])
                                            : [props.selectorState.focusMarket?.split('<-')[0]],
                                    }
                                    : null,
                            byMarketType:
                                subSelectorTypeVal === 'marketTypeReport'
                                    ? {
                                        // For Option5
                                        // marketType: "across different retailers",
                                        // channel: "All FMCG"
                                    }
                                    : null,
                            byMarketKey:
                                props.marketGroup !== 'cps'
                                    ? {
                                        keys:
                                            selectedItems.length > 0
                                                ? selectedItems.map((item) => item.marketKey)
                                                : [],
                                    }
                                    : null,
                        },
                    };
            let currentSelectorStatusState = JSON.parse(JSON.stringify(props.selectorState.selectorsStatus));
            if (currentSelectorStatusState[props.stepNo]) {
                currentSelectorStatusState[props.stepNo].status = selectorStates.completed;
            }

            // Added for Stories Feature
            if (props.productType === 'story') {
                let mrktKeys = [];
                let allMarketData =
                    props?.marketType === 'focusMarket'
                        ? JSON.parse(JSON.stringify(focusMarketsData))
                        : JSON.parse(JSON.stringify(comparisonMarketsData));

                allMarketData?.forEach((market) => {
                    if (market?.marketType === marketTypeValues.REGULAR) {
                        mrktKeys.push(market?.mrkt_key);
                    } else if (market?.marketType === marketTypeValues.CHANNEL) {
                        mrktKeys.push(market?.rm_channel_mrkt_key);
                    } else if (market?.marketType === marketTypeValues.RETAILER) {
                        mrktKeys.push(market?.rm_xaoc_mrkt_key);
                    } else if (
                        market?.marketType === marketTypeValues.COMPARATIVE ||
                        market?.marketType === marketTypeValues.COMPARITIVE
                    ) {
                        mrktKeys.push(market?.comparative_mrkt_key);
                    }
                });

                // add alias data
                let marketDataNew = {
                    marketAlias: focusMarketAlias,
                    selectedItemsList: selectedItems,
                    isMarketValidFormItems: isValidFormItems,
                    showRemainingMarkets,
                    marketKeys: props.multiSelect ? mrktKeys : (selectedItems.length > 0 ? selectedItems.map((item) => item.marketKey) : []),
                    // key need to modify
                    keys: selectedItems.length > 0 ? selectedItems.map((item) => item.marketKey) : [],
                };
                marketDataNew = Object.assign(marketDataNew, marketData);

                if (props.marketType && props.marketType === 'focusMarket' && !isMultiMarketsStory) {
                    let marketLevelInfo = {
                        marketName: selectedMarketItemLvlVal?.marketName,
                        marketLevel: selectedMarketItemLvlVal?.marketLevel,
                        marketType: selectedMarketItemLvlVal?.marketType,
                    };
                    props.setSelectorsData({
                        selectorsStatus: currentSelectorStatusState,
                        activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
                        focusMarketSelections: marketDataNew, // add alias here
                        refreshComparisonMarkets: true,
                        focusMarketsSelectedData: focusMarketsData, // markets data
                    });

                    // update
                    if (props.fromBack === true) {
                        let selectionsToSelState = { ...props.selectorState };
                        selectionsToSelState.focusMarketSelections = marketDataNew;
                        selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                        selectionsToSelState.refreshComparisonMarkets = true;
                        selectionsToSelState.focusMarketsSelectedData = focusMarketsData; // all markets data
                        // selectionsToSelState.activeCollapsePanel = [];
                        props.generateReport(selectionsToSelState);
                    }
                    props.setSelectorsData({
                        selectedMarketItemLvlData: marketLevelInfo,
                    });

                    if (submitType === 'Generate') {
                        let selectionsToSelState = { ...props.selectorState };
                        selectionsToSelState.focusMarketSelections = marketDataNew; //marketDataNew
                        selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                        selectionsToSelState.focusMarketsSelectedData = focusMarketsData;
                        props.generateReport(selectionsToSelState);
                    }
                }
                if (props.marketType && props.marketType === 'comparisonMarket') {
                    props.setSelectorsData({
                        selectorsStatus: currentSelectorStatusState,
                        activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
                        comparisonMarketSelections: marketDataNew, // add alias here
                        comparisonMarketsSelectedData: comparisonMarketsData,
                    });

                    let selectionsToSelState = { ...props.selectorState };
                    selectionsToSelState.comparisonMarketSelections = marketDataNew;
                    selectionsToSelState.comparisonMarketsSelectedData = comparisonMarketsData;
                    // selectionsToSelState.activeCollapsePanel = [];
                    selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                    props.generateReport(selectionsToSelState);

                    if (submitType === 'Generate') {
                        let selectionsToSelState = { ...props.selectorState };
                        selectionsToSelState.comparisonMarketSelections = marketDataNew; //marketDataNew
                        selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                        props.generateReport(selectionsToSelState);
                    }
                }
                if (props.marketType && props.marketType === 'focusMarket' && isMultiMarketsStory) {
                    let marketLevelInfo = {
                        marketName: selectedMarketItemLvlVal?.marketName,
                        marketLevel: selectedMarketItemLvlVal?.marketLevel,
                        marketType: selectedMarketItemLvlVal?.marketType,
                    };

                    props.setSelectorsData({
                        selectorsStatus: currentSelectorStatusState,
                        activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
                        marketPerformanceSelections: marketDataNew, // add alias here
                        refreshComparisonMarkets: true,
                        marketsToWatchSelection: {},
                        marketSelectionsSummary: {},
                        focusMarketsPerformanceSelectedData: focusMarketsData, // markets data
                    });

                    // update
                    if (props.fromBack === true) {
                        let selectionsToSelState = { ...props.selectorState };
                        selectionsToSelState.marketPerformanceSelections = marketDataNew;
                        selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                        selectionsToSelState.refreshComparisonMarkets = true;
                        selectionsToSelState.focusMarketsPerformanceSelectedData = focusMarketsData; // all markets data
                        selectionsToSelState.marketsToWatchSelection = {};
                        selectionsToSelState.marketSelectionsSummary = {};
                        // selectionsToSelState.activeCollapsePanel = [];
                        props.generateReport(selectionsToSelState);
                    }
                    props.setSelectorsData({
                        selectedMarketPerformanceItemLvlData: marketLevelInfo,
                    });

                    if (submitType === 'Generate') {
                        let selectionsToSelState = { ...props.selectorState };
                        selectionsToSelState.marketPerformanceSelections = marketDataNew; //marketDataNew
                        selectionsToSelState.selectorsStatus = currentSelectorStatusState;
                        selectionsToSelState.focusMarketsPerformanceSelectedData = focusMarketsData;
                        selectionsToSelState.marketsToWatchSelection = {};
                        selectionsToSelState.marketSelectionsSummary = {};
                        props.generateReport(selectionsToSelState);
                    }
                }
                setRefreshComparisonMarketStatus(false);
            }
            // for reports
            else {
                props.setSelectorsData({
                    selectorsStatus: currentSelectorStatusState,
                    activeCollapsePanel: submitType !== 'Generate' ? [props.stepNo + 1] : [],
                    focusMarketSelections: marketData,
                    marketSelectionsSummary: {
                        ...marketData.focusMarket,
                        ...{
                            byKeyMarketNames: props.subSelectors?.markets?.multi
                                ? marketSelectionsWithRemainingMarkets()
                                : [props.selectorState.focusMarket?.split('<-')[0]],
                        },
                        ...{
                            omniMarketMultiSelections:
                                props.marketGroup === 'omni' ? selectedItems.map((item) => item.name) ?? [] : null,
                            omniTotalMarketSelection:
                                props.marketGroup === 'omni'
                                    ? props.selectorState?.totalMarket?.marketName ?? ''
                                    : null,
                            omniSubMarketSelection:
                                props.marketGroup === 'omni' ? props.selectorState?.subMarket?.marketName ?? '' : null,
                        },
                    },
                });
            }

            if (props?.productType !== 'story' && submitType === 'Generate') {
                let selectionsToSelState = { ...props.selectorState };
                selectionsToSelState.focusMarketSelections = marketData;
                props.generateReport(selectionsToSelState);
            }
        }
    };

    const enableMultiMarketSelection = (mrktItem) => {
        let enableFlag = true;
        if (props.marketGroup === 'cps') {
            const checkChannelMrktSel = selectedItems.filter(
                (selItem) => selItem.marketSelType !== mrktItem.marketSelType && selItem.name !== mrktItem.name
            );
            if (checkChannelMrktSel && checkChannelMrktSel.length > 0) {
                enableFlag = false;
            }
            const allOutletsSel =
                mrktItem.name !== 'All Outlets' && selectedItems.map((selItem) => selItem.name).includes('All Outlets');
            const othersExceptAllOutletsSel =
                mrktItem.name === 'All Outlets' &&
                selectedItems.filter(
                    (selItem) => selItem.marketSelType === 'Panel Total US Markets' && selItem.name !== 'All Outlets'
                );
            if (
                (checkChannelMrktSel && checkChannelMrktSel.length > 0) ||
                allOutletsSel ||
                (othersExceptAllOutletsSel && othersExceptAllOutletsSel.length > 0)
            ) {
                enableFlag = false;
            }
        }
        if (
            selectedItems.length >= props.maxLimit &&
            !selectedItems.map((selItem) => selItem.name).includes(mrktItem.name)
        ) {
            enableFlag = false;
        }
        return enableFlag;
    };

    const wrapperClasses = classNames('multi-select-markets');
    const marketTreeClasses = classNames('multi-select-markets__tree');
    const btnLabel = () => {
        if (props.saveBtnType === 'Generate') {
            return props?.productType === 'story' ? 'Next' : 'Generate My Report';
        }
        if (props.saveBtnType === 'alertSelector') {
            return props.editButton ? 'Update' : 'Next';
        }
        return props.from === 'reportSelector' ? 'Next' : 'Submit';
        // `${props.saveBtnType === 'Generate' ? 'Generate My Report' : 'Submit'}`
    };

    // STORIES -------------
    const onChangeFocusMarketAlias = ({ target }) => {
        setFocusMarketAlias(target.value);
        setShowUpdateButton();
    };

    const getMarketAliasInput = () => {
        if (props?.productType === 'story' && !isMultiMarketsStory && props.subSelectors.selectorFunction !== 'marketPerformance') {
            return (
                <>
                    <div className="multi-select-markets__title multi-select-markets__title-story">
                        <div className="multi-select-markets__title-caption">{`Name your Market`}</div>
                    </div>

                    {props?.productType === 'story' && (
                        <div className="multi-select-markets__subtitle">{`${props.subSelectors.subSelectorDescription.marketAlias}`}</div>
                    )}

                    <ByzzerInput
                        className={'marketSelectionAlias'}
                        name={'marketSelectionAlias'}
                        placeholder={props.marketType === 'comparisonMarket' ? 'Comparison Market Name' : 'Market Name'}
                        value={focusMarketAlias}
                        onChange={onChangeFocusMarketAlias}
                        required
                    ></ByzzerInput>
                </>
            );
        }
    };

    const getPanelButton = () => {
        let selectedMarket = selectedItems.map((val) => {
            return val.name.split('$$')[0];
        }); // unique markets

        const disableOnEdit = isMultiMarketsStory
            ? props.from === 'reportSelector' &&
            !isMarketSelected && !selectedMarket?.length > 0
            : props.from === 'reportSelector' &&
            !props.selectorState.focusMarket &&
            !isMarketSelected && !selectedMarket?.length > 0;

        let disableOnObjectivesUnchangedEdit = false;
        if (isMultiMarketsStory) {
            disableOnObjectivesUnchangedEdit = showUpdate
                ? props?.marketType === 'focusMarket'
                    ? props.from === 'reportSelector' && !isMarketSelected && !selectedMarket?.length > 0
                    : props.from === 'reportSelector' &&
                    !isMarketSelected && !selectedMarket?.length > 0
                : true
        } else {
            disableOnObjectivesUnchangedEdit = showUpdate
                ? props?.marketType === 'focusMarket'
                    ? props.from === 'reportSelector' && !isMarketSelected && !selectedMarket?.length > 0
                    : props.from === 'reportSelector' &&
                    !props.selectorState.focusMarket &&
                    !isMarketSelected && !selectedMarket?.length > 0
                : true
        }

        const disableOnObjectivesEdit =
            props?.marketType === 'focusMarket'
                ? props.from === 'reportSelector' && !isMarketSelected && !selectedMarket?.length > 0
                : props.from === 'reportSelector' &&
                !props.selectorState.focusMarket &&
                !isMarketSelected &&
                !selectedMarket?.length > 0;

        return (
            <>
                {!props.extracts && props?.productType === 'story' && (
                    <>
                        {!props?.fromBack && (
                            <LegacyByzzerButton
                                className={'multi-select-markets__submit-btn-container-button'}
                                label={'Next'}
                                onClick={() => submitMarketSelections(props.saveBtnType)}
                                disabled={disableOnEdit}
                                style={{ width: '30px' }}
                            />
                        )}

                        {/* button for stories update   */}
                        {props?.fromBack && !props?.objectivesHasChanged && (
                            <LegacyByzzerButton
                                className={'multi-select-markets__submit-btn-container-button'}
                                label={showUpdate ? 'Update' : 'Next'}
                                onClick={() => submitMarketSelections(props.saveBtnType)}
                                disabled={disableOnObjectivesUnchangedEdit}
                                style={{ width: '30px' }}
                            />
                        )}
                        {/* button after objectives are changed   */}
                        {props?.fromBack && props?.objectivesHasChanged && (
                            <LegacyByzzerButton
                                className={'multi-select-markets__submit-btn-container-button'}
                                label={showUpdate ? 'Update' : 'Next'}
                                onClick={() => submitMarketSelections(props.saveBtnType)}
                                disabled={disableOnObjectivesEdit}
                                style={{ width: '30px' }}
                            />
                        )}
                    </>
                )}
            </>
        );
    };

    //Added for stories feature to check if market is selectable
    const isMarketSelectable = (marketToFilter, marketType = '') => {
        let selectionFlag = true;
        if (props.subSelectors?.markets?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (props.productType === 'story' && props.marketType === 'comparisonMarket') {
                const markerName =
                    marketType === marketTypeValues.CHANNEL
                        ? marketToFilter?.stores
                        : marketType === marketTypeValues.RETAILER
                            ? marketToFilter.fmcgRet
                            : marketToFilter.name;

                if (
                    props.selectorState?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.length > 0 &&
                    props.selectorState.focusMarketSelections.focusMarket.byMarketName.markets.includes(markerName)
                ) {
                    selectionFlag = false;
                } else {
                    selectionFlag = true;
                }
            } else if (props.productType === 'story' && props.marketType === 'focusMarket') {
                if (marketToFilter?.marketLevel === 'level_1' || marketToFilter?.marketLevel === 'level_2') {
                    if (selectedMarketItemLvlVal?.marketLevel === undefined) {
                        selectionFlag = true;
                    } else {
                        selectionFlag =
                            selectedMarketItemLvlVal?.marketLevel === 'level_2' &&
                                marketToFilter?.marketLevel === 'level_2'
                                ? true
                                : selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                                    marketToFilter.marketLevel === 'level_1' &&
                                    (selectedMarketItemLvlVal?.marketName === marketToFilter.marketName ||
                                        selectedMarketItemLvlVal?.marketName === marketToFilter.name)
                                    ? true
                                    : false;
                    }
                } else {
                    selectionFlag = false;
                }
            }
        } else {
            if (marketToFilter.is_releasable_mrkt === false) {
                return false;
            }
        }
        return selectionFlag;
    };

    //Added for stories feature, filter only level 1 and 2 markets in search results
    const filterMarketLevel = (marketToFilter) => {
        if (
            (props.productType === 'story' || alertWithAllMarketData) &&
            props.subSelectors?.markets?.allowedMarketLevels <= 3
        ) {
            if (marketToFilter.marketLevel === 'level_1' || marketToFilter.marketLevel === 'level_2') {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const filterByMarketName = (market) => {
        if (
            (props.productType === 'story' &&
                !props?.subSelectors?.markets?.showAllMarkets &&
                market.name === 'Total FMCG Retailers') ||
            (alertWithAllMarketData &&
                !props?.subSelectors?.markets?.showAllMarkets &&
                market.name === 'Total FMCG Retailers')
        ) {
            return false;
        } else {
            return true;
        }
    };

    //Added for stories feature, check if remaining channel or market retailer is selectable
    const isRemainingMarketSelectable = (marketToFilter, marketType) => {
        let selectableFlag = true;
        if (props.subSelectors?.markets?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                (props.productType === 'story' &&
                    props.marketType === 'focusMarket' &&
                    selectedMarketItemLvlVal.marketLevel === 'level_1') ||
                selectedMarketItemLvlVal.marketLevel === 'level_2'
            ) {
                if (selectedMarketItemLvlVal.marketLevel === 'level_1') {
                    if (marketType === marketTypeValues.CHANNEL) {
                        selectableFlag =
                            marketToFilter.marketLevel === 'level_1' &&
                                selectedMarketItemLvlVal?.marketName === marketToFilter.stores &&
                                selectedMarketItemLvlVal.marketType === marketTypeValues.CHANNEL
                                ? true
                                : false;
                    } else if (marketType === marketTypeValues.RETAILER) {
                        selectableFlag =
                            selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                                selectedMarketItemLvlVal?.marketName === marketToFilter.fmcgRet &&
                                selectedMarketItemLvlVal.marketType === marketTypeValues.RETAILER
                                ? true
                                : false;
                    } else if (marketType === 'comparative') {
                        selectableFlag =
                            selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                                selectedMarketItemLvlVal?.marketName === marketToFilter.comparativeMarket &&
                                selectedMarketItemLvlVal.marketType === 'comparative'
                                ? true
                                : false;
                    }
                } else {
                    selectableFlag = marketToFilter.marketLevel === 'level_2' ? true : false;
                }
            } else if (props.productType === 'story' && props.marketType === 'comparisonMarket') {
                selectableFlag = isMarketSelectable(marketToFilter, marketType);
            }
        }
        return selectableFlag;
    };

    //Added for stories feature to check market name character length and display warning message
    const checkMarketCharacterLimit = (market) => {
        let MarketString = storyAppendMore(market, focusMarketAlias.trim(), props?.sku, true, 'market');
        if (MarketString?.length >= 25 && focusMarketAlias.trim() === '' && !isWarning1Displayed) {
            setShowWarning1(true);
            setIsWarning1Displayed(true);
            setContentType1('warning');
            setWarningMessage1(
                'You have selected a market name which has too many characters. This may result in overlapping of content in your story. We suggest renaming the market in the input box provided.'
            );
        }
    };

    //Added for Stories feature to check if focus market is selectable based on level check
    const isMarketLevelSelectable = (item) => {
        let isLvl1MarketSelectable = true;
        if (props?.subSelectors?.markets?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                props.productType === 'story' &&
                props.marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isLvl1MarketSelectable =
                    selectedMarketItemLvlVal.marketLevel === 'level_2'
                        ? false
                        : selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                            selectedMarketItemLvlVal?.marketName !== item.name
                            ? false
                            : true;
            }
        }
        return isLvl1MarketSelectable;
    };

    const isComparativeMarketsEnabled = (market) => {
        return props?.productType === 'story'
            ? !(selectedItems.length >= props.maxLimit && !market?.selectedComparitive)
            : (selectedItems.length <= props?.maxLimit &&
                selectedItems.map((item) => item.name).includes(market.comparativeMarket)) ||
            selectedItems.length < props?.maxLimit ||
            !props.maxLimit;
    };

    const isComparativeMarketsDisabled = (market) => {
        return props?.productType === 'story'
            ? !market.comparativeMarket || (selectedItems.length >= props?.maxLimit && !market?.selectedComparitive)
            : !market.comparativeMarket ||
            (selectedItems.length >= props?.maxLimit &&
                !selectedItems.map((item) => item.name).includes(market.comparativeMarket));
    };
    /**
     * For markets selection summary in Reports
     */
    function marketSelectionsWithRemainingMarkets() {
        let marketSelWithRemaining = [];
        if (selectedItems?.length > 0) {
            let fullMarketSelections = [...selectedItems];
            let regularMarkets = fullMarketSelections.filter(
                (item) => !item.marketType || item.marketType === 'regular'
            );
            if (regularMarkets.length > 0) {
                regularMarkets.forEach((mrkt) => {
                    const remainingItems = fullMarketSelections.filter(
                        (fullMrkt) =>
                            fullMrkt.name?.split('$$')?.length > 1 &&
                            fullMrkt.name?.split('$$')[2]?.includes(mrkt.marketKey)
                    );
                    marketSelWithRemaining.push(
                        `${mrkt.name} ${remainingItems?.length > 0
                            ? ', ' + remainingAndComparativeMarketsSelected(remainingItems)
                            : ''
                        }`
                    );
                });
            }
        }
        return marketSelWithRemaining;
    }

    function remainingAndComparativeMarketsSelected(remainingCompMarkets) {
        return remainingCompMarkets
            ?.map(
                (rem) => (rem.marketType === 'comparative' ? 'Comparative ' : 'Remaining ') + rem.name?.split('$$')[0]
            )
            .join(', ');
    }

    function selectedSingleMarketFromSearch(market) {
        if (props.from === 'reportSelector') {
            if (props.marketGroup === 'omni') {
                return (
                    (isSubMarket
                        ? props.selectorState?.subMarket?.marketName
                        : props.selectorState?.totalMarket?.marketName) === market.name
                );
            } else {
                return props.selectorState.focusMarket === market.name;
            }
        } else {
            return props.userPrefState.primaryMarket === market.name;
        }
    }

    const handleAllMarketCheck = (selectedAll) => {
        let totalUSMarketTemp = JSON.parse(JSON.stringify(totalUSMarket));
        let geographyTemp = JSON.parse(JSON.stringify(geography));
        setSelectAll(selectedAll);
        if (selectedAll) {
            let totalUSMarkets = [];
            let marketsTemp = [];
            let marketNamesTemp = [];
            allMarketList.map(val => {
                
                if ((searchResultMarkets.length > 0 && searchResultMarkets.findIndex(val1 => val1.name === val.name) !== -1) || !searchResultMarkets.length) {
                    if (val?.is_releasable_mrkt) {
                        val.selected = true
                        if (val.rm_channel_mrkt_disp_name && showRemainingMarkets) {
                            val.selectedComparitive = true
                        }
                        if (val.rm_xaoc_mrkt_disp_name && val?.mrkt_sel_typ !== "Total US" && showRemainingMarkets) {
                            val.selectedRetailer = true
                        }

                        if (val.rm_channel_mrkt_disp_name && showRemainingMarkets) {
                            marketNamesTemp.push(val.rm_channel_mrkt_disp_name);
                        }
                        if (val.rm_xaoc_mrkt_disp_name && showRemainingMarkets) {
                            marketNamesTemp.push(val.rm_xaoc_mrkt_disp_name);
                        }

                        if (setMarketType(props.folder) === "All" || setMarketType(props.folder) === val?.sub_mrkt_type || props?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.includes(val?.name)) {
                            marketsTemp.push({
                                ...val,
                                marketKey: val.mrkt_key,
                                marketSelType: val.mrkt_sel_typ

                            })


                            if (val.rm_channel_mrkt_disp_name && showRemainingMarkets) {
                                marketsTemp.push({
                                    ...val,
                                    name: `${val.rm_channel_mrkt_disp_name}`,
                                    marketKey: val.mrkt_key,
                                    marketSelType: val.mrkt_sel_typ

                                });
                            }
                            if (val.rm_xaoc_mrkt_disp_name && val?.mrkt_sel_typ !== "Total US" && showRemainingMarkets) {
                                marketsTemp.push({
                                    ...val,
                                    name: `${val.rm_xaoc_mrkt_disp_name}`,
                                    marketKey: val.mrkt_key,
                                    marketSelType: val.mrkt_sel_typ

                                });
                            }

                            marketNamesTemp.push(val.name);
                        }
                    }
                    if (val?.mrkt_sel_typ === "Total US") {

                        totalUSMarkets.push(val.name);

                    }

                }



            })
            setSelectedItems(() => {
                return marketsTemp
            })
            if (props.folder === 'All' || props.folder === 'Channels') {
                totalUSMarketTemp.forEach((item) => {
                    if (totalUSMarkets.includes(item.name)) {
                        item.selected = true;
                    }
                });
                setTotalUSMarket(totalUSMarketTemp);
            }
            if (searchResultMarkets.length > 0) {
                let searchResultMarketsTemp = JSON.parse(JSON.stringify(searchResultMarkets))
                searchResultMarketsTemp.forEach((item) => {
                    item.selected = true;
                    if (showRemainingMarkets) {
                        item.selectedMarket = true
                        item.selectedChannel = true
                    }
                    return item;
                });
                setsearchResultMarkets(searchResultMarketsTemp)
            }
            retainDataForMarketChannel(marketNamesTemp);
            if (props.folder === 'All' || setMarketType(props.folder) === 'Account') {
                geographyTemp.forEach((val) => {
                    val?.markets?.forEach((e) => {
                        if (!marketNamesTemp.includes(e.name)) {
                            if (e.selected === true) {
                                e.selected = false;
                            }
                        } else {
                            e.selected = true;
                        }
                    });
                });
            } else {
                geographyTemp.forEach((e) => {
                    if (!marketNamesTemp.includes(e.name)) {
                        if (e.selected === true) {
                            e.selected = false;
                        }
                    } else {
                        e.selected = true;
                    }
                });
            }
            setGeography(geographyTemp);
            if (props.setExtractsMarketSelection) {
                let extractItems = [];
                marketsTemp.map((val) => {
                    let marketName = val.name?.split('$$');
                    marketName = marketName.length > 0 ? marketName[0] : marketName;
                    let mrktType = val.marketSelType;
                    if (!mrktType) {
                        const treeViewIndex = props.extractsMarketTreeView.findIndex((val2) =>
                            val2.selections.includes(marketName)
                        );
                        if (treeViewIndex !== -1) {
                            mrktType = props.extractsMarketTreeView[treeViewIndex].folder;
                        }
                    }
                    const findIndex = extractItems.findIndex((val1) => val1.folder === mrktType);
                    if (findIndex === -1) {
                        if (mrktType) {
                            // let selections = [marketName];
                            // if(val.rm_channel_mrkt_disp_name){
                            //     selections.push(val.rm_channel_mrkt_disp_name);
                            // }
                            // if(val.rm_xaoc_mrkt_disp_name){
                            //     selections.push(val.rm_xaoc_mrkt_disp_name);
                            // }
                            extractItems.push({
                                folder: mrktType,
                                selections: [marketName]
                            });
                        }
                    } else {
                        extractItems[findIndex].selections.push(marketName);
                        // if(val.rm_channel_mrkt_disp_name){
                        //     extractItems[findIndex].selections.push(val.rm_channel_mrkt_disp_name);
                        // }
                        // if(val.rm_xaoc_mrkt_disp_name){
                        //     extractItems[findIndex].selections.push(val.rm_xaoc_mrkt_disp_name);
                        // }
                    }
                });
                props.setExtractsMarketSelection(extractItems);
            }
        } else {
            setTotalUSMarket((prev) => prev.map(val => {
                val.selected = false
                val.selectedComparitive = false
                val.selectedRetailer = false
                return val
            }

            ))
            setSelectedItems([]);
            if (props.folder === 'All' || setMarketType(props.folder) === 'Account') {
                geographyTemp.forEach((val) => {
                    val?.markets?.forEach((e) => {
                        e.selected = false;
                    });
                });
            } else {
                geographyTemp.forEach((e) => {
                    e.selected = false;
                });
            }
            setGeography(geographyTemp);
            if (props.setExtractsMarketSelection) {
                let extractItems = [];
                props.setExtractsMarketSelection(extractItems);
            }
            if (searchResultMarkets.length > 0) {
                let searchResultMarketsTemp = JSON.parse(JSON.stringify(searchResultMarkets))
                searchResultMarketsTemp.forEach((item) => {
                    item.selected = false;
                    if (showRemainingMarkets) {
                        item.selectedMarket = false
                        item.selectedChannel = false
                    }
                    return item;
                });
                setsearchResultMarkets(searchResultMarketsTemp)
            }
            retainDataForMarketChannel([]);
        }
    }
    return (
        <div className={`${props.extracts ? wrapperClasses + ' ' + wrapperClasses + '_extracts' : wrapperClasses}`}>
            <ByzzerMask loading={isLoading} />
            {/* Added for Stories Feature           */}
            {props.productType === 'story' && props?.multiSelect &&
                props.subSelectors?.subSelectorDescription?.market !== '' &&
                props.subSelectors?.subSelectorDescription?.market !== undefined && (
                    <div className="multi-select-markets__subtitle">{props.subSelectors?.subSelectorDescription?.market}</div>
                )}
            {!props.extracts && (
                <div className="multi-select-markets__title">
                    {!(props.productType === 'story' && isMultiMarketsStory) &&
                        <div className="multi-select-markets__title-caption">
                            {`Select ${props.marketGroup === 'omni' && props.multiSelect ? 'up to' : 'the'} 
                                ${props.productType === 'story' && props.marketType === 'comparisonMarket'
                                    ? 'Comparison'
                                    : `${props.from === 'userPref'
                                        ? 'Primary'
                                        : props.marketGroup === 'omni'
                                            ? props.multiSelect
                                                ? props.maxLimit
                                                : isSubMarket
                                                    ? 'Sub'
                                                    : 'Total'
                                            : 'Focus'
                                    }`
                                }
                                Market${props.multiSelect ? 's' : ''} for your
                                ${props.productType === 'story'
                                    ? `story`
                                    : `${props.saveBtnType === 'alertSelector'
                                        ? 'alert'
                                        : props.fromFilter ?
                                            'Filter'
                                            : props.saveBtnType === 'scorecardSelector'
                                                ? 'ScoreCard'
                                                : 'report'
                                    }`
                                }.`}
                        </div>
                    }

                    {props.productType === 'story' && isMultiMarketsStory &&
                        <div className="multi-select-markets__title-caption">
                            Select the Markets for your story.
                        </div>
                    }

                    {((props.from !== 'userPref' && props.multiSelect && subSelectorTypeVal !== 'smartReportMarkets') ||
                        (props.multiSelect && props.saveBtnType !== 'scorecardSelector')) &&
                        props?.productType !== 'story' &&
                        (props.maxLimit ? (
                            <div className="multi-select-markets__title-count">
                                {props?.subSelectors?.markets?.requiredRemMarketForTotalMarkets ||
                                    alertWithAllMarketData
                                    ? selectedItems?.length
                                    : selectedMarket?.length}
                                /{props.maxLimit} selected.
                            </div>
                        ) : null)}

                    {((props.from !== 'userPref' && props.multiSelect && subSelectorTypeVal !== 'smartReportMarkets') ||
                        (props.multiSelect && props.saveBtnType !== 'scorecardSelector')) &&
                        props?.productType === 'story' &&
                        props.subSelectors?.markets?.showSelectedMarketsCount && (
                            <div className="multi-select-markets__title-count">
                                {/* {selectedMarket.length}/{props.maxLimit} selected. */}
                                Number of market(s) selected: {selectedItems.length}
                            </div>
                        )}
                </div>
            )}
            {props.fromMarketTypeSelector && <div className="multi-select-markets__to-marketype">
                <ByzzerLink label="Choose by market type" onClick={() => switchMarketSelector('market:option5', props)} />
            </div>}
            {props.marketGroup !== 'omni' && (
                !(
                    subSelectorTypeVal &&
                    (subSelectorTypeVal === 'cpsMarketsReport' || subSelectorTypeVal === 'smartReportMarkets')
                ) || (props?.productType === 'story' && subSelectorTypeVal === 'smartReportMarkets')
            ) &&
                (props?.productType !== 'story' || (props?.productType === 'story' && isMultiMarketsStory)) &&
                props.marketType !== 'comparisonMarket' &&
                !props.extracts && (
                    <div className="multi-select-markets__subtitle">
                        {props.saveBtnType !== 'scorecardSelector' &&
                            `${props.multiSelect ? `Up to ${props.maxLimit}` : 'Only one'} 
                    ${props.productType === 'story' && props.marketType === 'comparisonMarket'
                                ? `Comparison`
                                : `${props.from === 'userPref' ? 'Primary' : 'Focus'}`
                            }
                    Market${props.multiSelect ? 's' : ''} can be selected.`}
                    </div>
                )}
            {props.marketGroup === 'omni' && !props.multiSelect && (
                <div className="multi-select-markets__subtitle accessible">
                    {isSubMarket ? subMarketSubHeadline : totalMarketSubHeadline}
                </div>
            )}
            {props?.productType !== 'omni' &&
                props?.productType !== 'story' &&
                subSelectorTypeVal === 'smartReportMarkets' && (
                    <div className="multi-select-markets__subtitle">
                        {props?.subSelectors?.markets?.smartAccount
                            ? `Select up to ${props.maxLimit / 2
                            } accounts and one remaining market for each account, for a total of ${props.maxLimit
                            } selections. A remaining market must be chosen for each account for it to populate in the report.`
                            : `${props?.subSelectors?.markets?.marketoptions
                                ? `Select your Focus Retailer and the corresponding Remaining Market of interest. The associated sub-markets will be automatically selected. The remaining market selection for each of the sub-markets will correspond to your remaining market selection at the Retailer Total level unless only one remaining market is releasable. In this instance, the releasable remaining market will be chosen by default.`
                                : `${props.multiSelect ? `Select up to ${props.maxLimit}` : 'Only one'} 
                                     market${props.multiSelect ? 's' : ''}.`
                            }`}
                    </div>
                )}
            {/* <ByzzerSearch placeholder="Search" value={marketSearchKeyword}/> */}
            {/* {(!props.extracts || (props?.extracts && props?.folder === 'All')) && ( */}
            {props?.summedMarkets && (
                <div className="multi-select-markets__summed-markets">
                    <span className="multi-select-markets__summed-markets-content">
                        You are making a summed selection. Hit cancel if you do not wish to proceed.
                    </span>
                </div>
            )}
            <div
                className={`multi-select-markets__search ${props?.extracts && 'multi-select-markets__search__extracts'
                    }  ${limitedMarketSubscription && props.marketGroup !== 'omni' && 'multi-select-markets__search__hidden'
                    }`}
            >
                {/* <img src={searchImg} alt="search" style={{ height: '24px', width: '24px', marginRight: '11px', marginLeft: '12px' }} /> */}
                <i className={`byzzer-search-icon ${props.extracts && ' byzzer-search-icon-extracts'}`}>
                    <InlineSVG src={searchIcon} />
                </i>
                <DebounceInput
                    minLength={2}
                    maxLength={20}
                    debounceTimeout={1000}
                    onChange={searchMarketItems}
                    className="multi-select-markets__search-input"
                    placeholder="Search by Market Name"
                    value={marketSearchKeyword}
                />
                {!displayMarketTree && marketSearchKeyword && (
                    <span
                        className={`byzzer-search-icon ${props.extracts && ' byzzer-search-icon-extracts'}`}
                        onClick={searchMarketSubmit}
                    >
                        <InlineSVG src={closeIcon} />
                    </span>
                )}
                {/* <img src={microphoneImg} alt="speaker" style={{ height: '24px', width: '24px', marginRight: '14px', }} /> */}
            </div>
            {/* )} */}
            {
                props?.extracts &&
                <div className={`show-remaining-markets ${props.extracts && 'show-remaining-markets-extracts'}`}>
                    <LegacyByzzerCheckbox
                        label={'Select All'}
                        name={'Select All'}
                        value={'All'}
                        checked={props.isFreeUser ? false : selectAll}
                        // disabled={!marketListOptions.length}
                        onChange={(e) => {
                            if (props.isFreeUser) {
                                props.showErrorMsg();
                            } else {
                                handleAllMarketCheck(e.target.checked)
                            }
                        }}
                        children={null}
                        className={null}
                    />
                    <MarketSelectionLayoutOption
                        layout={layout}
                        setMarketLayout={setMarketLayoutSelection}
                        disableView={[ViewEnums.ListView]}
                    />
                </div>
            }


            {props.marketGroup !== 'omni' &&
                props.marketGroup !== 'cps' &&
                props.saveBtnType !== 'scorecardSelector' &&
                props.from !== 'userPref' &&
                (props?.productType !== 'story' ||
                    (props?.productType === 'story' &&
                        props?.subSelectors?.markets?.showRemainingMarketsSwitch)
                ) &&
                (!props.extracts) && (
                    <div className={`show-remaining-markets ${props.extracts && 'show-remaining-markets-extracts'}`}>
                        {(props?.productType !== 'story' ||
                            (props?.productType === 'story' &&
                                props?.subSelectors?.markets?.showRemainingMarketsSwitch)) && (
                                <div
                                    className={`remaining-market-width ${props.extracts && 'remaining-market-width-extracts'
                                        }`}
                                >
                                    <span className={`show-remaining-markets-text`}>Show Remaining Markets</span>
                                    <Switch
                                        checked={showRemainingMarkets}
                                        size="default"
                                        disabled={
                                            props?.subSelectors?.markets?.requiredRemMarketForEachRetailer ? true : false
                                        }
                                        onChange={() => setRemainingMarketsVisibility(!showRemainingMarkets)}
                                        style={{ marginRight: '7px', marginLeft: '7px' }}
                                    />
                                    <span className="remaining-market-width-info">
                                        <i>
                                            <InlineSVG
                                                src={infoIcon}
                                                onClick={() => {
                                                    setStatusInfo(!statusInfo);
                                                }}
                                            />
                                        </i>
                                    </span>
                                </div>
                            )}
                        {!displayMarketTree &&
                            !props.extracts &&
                            searchResultMarkets !== undefined &&
                            searchResultMarkets.length > 0 &&
                            showRemainingMarkets && (
                                <div className={'multi-select-markets-by-channel__remaining_markets_retailers'}>
                                    <div className={'multi-select-markets-by-channel__remaining_markets'}>
                                        Remaining Channel
                                    </div>
                                    <div className={'multi-select-markets-by-channel__remaining_markets'}>
                                        Remaining Market
                                    </div>
                                    {(props?.saveBtnType !== 'alertSelector' || alertWithAllMarketData) &&
                                        props?.subSelectors?.markets?.requiredRemMarketForEachRetailer && (
                                            <div className={'multi-select-markets-by-channel__remaining_markets'}>
                                                Comparative Market
                                            </div>
                                        )}
                                </div>
                            )}
                    </div>
                )}
            {props?.productType === 'story' && props?.multiSelect && (
                <div className={`show-remaining-markets `}>
                    {props?.productType === 'story' &&
                        props?.marketType === 'focusMarket' &&
                        props?.subSelectors?.markets.multi && (
                            <div>
                                <span className={`show-remaining-markets-text `}>
                                    Selected Market(s):&nbsp;
                                    <span className={`show-remaining-markets-list `}>
                                        {selectedItems
                                            ?.map((val) => {
                                                return val.name.split('$$')[0];
                                            })
                                            .join(', ')}
                                    </span>
                                </span>
                            </div>
                        )}
                    {props?.productType === 'story' &&
                        props?.marketType === 'focusMarket' &&
                        !props?.subSelectors?.markets.multi && subSelectorTypeVal !== 'smartReportMarkets' && (
                            <div>
                                <span className={`show-remaining-markets-text `}>
                                    Selected Market:&nbsp;
                                    <span className={`show-remaining-markets-list `}>
                                        {
                                            selectedItems?.map((val) => {
                                                return val.name.split('$$')[0];
                                            }).join(', ')
                                        }</span>
                                </span>
                            </div>
                        )}
                    {props?.productType === 'story' &&
                        props?.marketType === 'comparisonMarket' &&
                        props?.subSelectors?.markets.multi && (
                            <div>
                                <span className={`show-remaining-markets-text `}>
                                    Selected Comparison Market(s):&nbsp;
                                    <span className={`show-remaining-markets-list `}>
                                        {selectedItems
                                            .map((val) => {
                                                return val.name.split('$$')[0];
                                            })
                                            .join(', ')}
                                        {/* {selectedMarket.join(', ')} */}
                                    </span>
                                </span>
                            </div>
                        )}
                    {props?.productType === 'story' &&
                        props?.marketType === 'comparisonMarket' &&
                        !props?.subSelectors?.markets.multi && (
                            <div>
                                <span className={`show-remaining-markets-text `}>
                                    Selected Comparison Market(s):&nbsp;
                                    <span className={`show-remaining-markets-list `}>
                                        {props?.selectorState?.focusMarket}
                                    </span>
                                </span>
                            </div>
                        )}
                </div>
            )}

            {((props?.productType === 'Alert' && subSelectorTypeVal === 'smartReportMarkets') ||
                props?.saveBtnType === 'alertSelector') && (
                    <div className={`show-remaining-markets `}>
                        <div>
                            <span className={`show-remaining-markets-text `}>
                                {props?.multiSelect && selectedMarket && (
                                    <>
                                        Selected Focus Market(s):&nbsp;
                                        <span className={`show-remaining-markets-list `}>
                                            {alertWithAllMarketData
                                                ? selectedItems
                                                    ?.map((val) => {
                                                        return val.name.split('$$')[0];
                                                    })
                                                    .join(', ')
                                                : selectedMarket?.join(', ')}
                                        </span>
                                    </>
                                )}
                                {!props?.multiSelect && (
                                    <>
                                        Selected Focus Market:&nbsp;
                                        <span className={`show-remaining-markets-list `}>
                                            {props?.selectorState?.focusMarket?.split('<-')[0]}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                )}
            {totalUSMarket.length > 0 ||
                marketChannel?.FMCGRetailers?.length > 0 ||
                marketChannel?.specialtyRetailers?.length > 0 ||
                geography.length > 0
                ? displayMarketTree && (
                    <div
                        className={`${props.extracts
                            ? marketTreeClasses + ' ' + marketTreeClasses + '_extracts'
                            : marketTreeClasses
                            }`}
                    >
                        {!props?.subSelectors?.markets?.marketoptions && (
                            <TotalUSMarket
                                totalUSMarket={totalUSMarket}
                                totalUSMarketSelected={(index, type) => {
                                    totalUSMarketSelected(index, type);
                                }}
                                selectedMarket={selectedMarket}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                checkLimit={checkLimit}
                                multiSelect={props.multiSelect}
                                maxLimit={props.maxLimit ? props.maxLimit : 1000}
                                selectorState={props.selectorState || {}}
                                setSelectorsData={props.setSelectorsData || {}}
                                from={props.from}
                                userPrefState={props.userPrefState}
                                setPreferenceState={props.setPreferenceState}
                                marketChannel={marketChannel}
                                geography={geography}
                                marketGroup={props.marketGroup}
                                showRemainingMarkets={showRemainingMarkets}
                                selectedMarketItemLvlVal={selectedMarketItemLvlVal}
                                setShowUpdateButton={() => {
                                    setShowUpdateButton();
                                }}
                                isMarketLevelSelectable={(e) => isMarketLevelSelectable(e)}
                                filterComparisonMarket={(e) => filterComparisonMarket(e)}
                                marketConfigs={props?.subSelectors?.markets}
                                saveBtnType={props.saveBtnType || ''}
                                productType={props.productType || ''}
                                marketType={props.marketType || ''}
                                alertWithAllMarketData={alertWithAllMarketData}
                                toolTipMessage={toolTipMessage}
                                showTooltip={showTooltip}
                                checkIfMarketCanBeLoaded={checkIfMarketCanBeLoaded}
                            // selectAll={selectAll}
                            />
                        )}
                        {/* checkLimit */}
                        <MarketChannel
                            marketChannel={marketChannel}
                            selectedMarket={selectedMarket}
                            showCompanies={(index) => showCompanies(index)}
                            showSpecialityRetailersCompanies={(index) => showSpecialityRetailersCompanies(index)}
                            showMarkets={(index, index1) => {
                                showMarkets(index, index1);
                            }}
                            showSpecialityRetailersMarkets={(index, index1) => {
                                showSpecialityRetailersMarkets(index, index1);
                            }}
                            showSubFolders={(index, index1, index2) => {
                                showSubFolders(index, index1, index2);
                            }}
                            showSpecialityRetailersSubFolders={(index, index1, index2) => {
                                showSpecialityRetailersSubFolders(index, index1, index2);
                            }}
                            showSubfolderMarkets={(index, index1, index2, index3) => {
                                showSubfolderMarkets(index, index1, index2, index3);
                            }}
                            showSpecialityRetailersSubfolderMarkets={(index, index1, index2, index3) => {
                                showSpecialityRetailersSubfolderMarkets(index, index1, index2, index3);
                            }}
                            showlvl2Markets={(index, index1, index2, index3, index4) => {
                                showlvl2Markets(index, index1, index2, index3, index4);
                            }}
                            showlvl3Markets={(index, index1, index2, index3, index4, index5) => {
                                showlvl3Markets(index, index1, index2, index3, index4, index5);
                            }}
                            showSpecialityRetailerslvl2Markets={(index, index1, index2, index3, index4) => {
                                showSpecialityRetailerslvl2Markets(index, index1, index2, index3, index4);
                            }}
                            marketSelected={marketSelected}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            checkLimit={checkLimit}
                            multiSelect={props.multiSelect}
                            maxLimit={props.maxLimit ? props.maxLimit : 1000}
                            selectorState={props.selectorState || {}}
                            setSelectorsData={props.setSelectorsData || {}}
                            from={props.from}
                            userPrefState={props.userPrefState}
                            setPreferenceState={props.setPreferenceState}
                            marketGroup={props.marketGroup}
                            totalUSMarket={totalUSMarket}
                            geography={geography}
                            showRemainingMarkets={showRemainingMarkets}
                            focusMarketsData={focusMarketsData}
                            productType={props.productType}
                            marketType={props.marketType}
                            selectedMarketItemLvlVal={selectedMarketItemLvlVal}
                            option2c={
                                props?.subSelectors?.markets?.marketoptions &&
                                !props?.subSelectors?.markets?.noSelection
                            }
                            setShowUpdateButton={() => {
                                setShowUpdateButton();
                            }}
                            filterComparisonMarket={(e) => filterComparisonMarket(e)}
                            productSku={props.productSku}
                            marketConfigs={props?.subSelectors?.markets}
                            saveBtnType={props?.saveBtnType || ''}
                            toolTipMessage={toolTipMessage}
                            showTooltip={showTooltip}
                            checkIfMarketCanBeLoaded={checkIfMarketCanBeLoaded}
                        // selectAll={selectAll}
                        />
                        {!props?.subSelectors?.markets?.marketoptions && (
                            <Geography
                                geography={geography}
                                selectedMarket={selectedMarket}
                                showGeographyMarkets={(index) => {
                                    showGeographyMarkets(index);
                                }}
                                geographyMarketSelected={(index, index1, type) => {
                                    geographyMarketSelected(index, index1, type);
                                }}
                                geographySingleMarketSelected={(index) => {
                                    geographySingleMarketSelected(index);
                                }}
                                selectedItems={selectedItems}
                                setSelectedItems={setSelectedItems}
                                checkLimit={checkLimit}
                                multiSelect={props.multiSelect}
                                maxLimit={props.maxLimit ? props.maxLimit : 1000}
                                selectorState={props.selectorState || {}}
                                setSelectorsData={props.setSelectorsData || {}}
                                from={props.from}
                                userPrefState={props.userPrefState}
                                setPreferenceState={props.setPreferenceState}
                                totalUSMarket={totalUSMarket}
                                marketChannel={marketChannel}
                                marketGroup={props.marketGroup}
                                showRemainingMarkets={showRemainingMarkets}
                                selectedMarketItemLvlVal={selectedMarketItemLvlVal}
                                isMarketLevelSelectable={(e) => isMarketLevelSelectable(e)}
                                filterComparisonMarket={(e) => filterComparisonMarket(e)}
                                productType={props.productType}
                                marketType={props.marketType}
                                extracts={props.extracts}
                                marketConfigs={props.subSelectors.markets}
                                alertWithAllMarketData={alertWithAllMarketData}
                                setShowUpdateButton={() => {
                                    setShowUpdateButton();
                                }}
                                toolTipMessage={toolTipMessage}
                                showTooltip={showTooltip}
                                checkIfMarketCanBeLoaded={checkIfMarketCanBeLoaded}
                            // selectAll={selectAll}
                            />
                        )}
                    </div>
                )
                : displayMarketTree &&
                !isLoading && (
                    <div
                        className={`multi-select-markets__tree-search-results-error ${props.extracts && 'multi-select-markets__tree-search-results-error-extracts'
                            }`}
                    >
                        <span className="multi-select-market__tree-search-results-error-main">
                            No markets to list.
                        </span>
                    </div>
                )}
            {searchResultMarkets.has_more_hits && (
                <div className="multi-select-market__tree-search-results-has-more-hits">
                    <span className="multi-select-market__tree-search-results-has-more-hits-error-main">
                        Your search generated a lot of results, We are showing the top results.
                    </span>
                    <span className="multi-select-market__tree-search-results-has-more-hits-error-sub">
                        Refine your search if your product is not listed.
                    </span>
                </div>
            )}
            {!displayMarketTree && (
                <div
                    className={`${props.extracts ? marketTreeClasses + ' ' + marketTreeClasses + '_extracts' : marketTreeClasses
                        }`}
                >
                    {searchError && (
                        <div
                            className={`multi-select-markets__tree-search-results-error ${props.extracts && 'multi-select-markets__tree-search-results-error-extracts'
                                }`}
                        >
                            {/* {typeof (searchError === 'string') && <span className="multi-select-market__tree-search-results-error-main">No match found.</span>} */}
                            <span className="multi-select-market__tree-search-results-error-sub">
                                {typeof searchError !== 'string' ? noMatchMessage : searchError}
                            </span>
                        </div>
                    )}
                    {searchResultMarkets !== undefined &&
                        searchResultMarkets.length > 0 &&
                        props.extracts &&
                        showRemainingMarkets && (
                            <div key={'Headers'} className={`multi-select-markets-by-channel__remaining`}>
                                <div className="multi-select-markets__search-container">
                                    <span className="multi-select-markets__search-container__market">Market</span>
                                </div>
                                <div className={'multi-select-markets-by-channel__remaining_markets_retailers'}>
                                    <div className={'multi-select-markets-by-channel__remaining_markets'}>
                                        Remaining Channel
                                    </div>
                                    <div className={'multi-select-markets-by-channel__remaining_markets'}>
                                        Remaining Market
                                    </div>
                                </div>
                            </div>
                        )}
                    {searchResultMarkets !== undefined &&
                        searchResultMarkets.length > 0 &&
                        searchResultMarkets.map((market, index) => {
                            return (
                                <React.Fragment key={market.name + index}>
                                    {filterMarketLevel(market) && filterByMarketName(market) && checkIfMarketCanBeLoaded(market) && (
                                        <div key={index} className={classNames(
                                            `multi-select-markets-by-channel__remaining`, {
                                            [`multi-select-markets-by-channel__remaining__disabledFlag`]: !market.is_releasable_mrkt
                                        }
                                        )}>
                                            <div
                                                className={`multi-select-markets__search-container ${props.marketGroup === 'omni' ? 'omni-list' : ''} ${props.marketGroup === 'omni' && !marketSearchKeyword
                                                    ? 'accessible'
                                                    : ''
                                                    }`}
                                            >
                                                {isMarketSelectable(market) && props.multiSelect === true
                                                    ? enableMultiMarketSelection(market) &&
                                                    !filterComparisonMarket(market.name) && (
                                                            <LegacyByzzerCheckbox
                                                                disabled={!enableMultiMarketSelection(market)}
                                                                className={`${props.marketGroup === 'omni' && !(market?.mrkt_entitlements === undefined ||
                                                                    market?.is_releasable_mrkt) ? '_disabled' : ''}`}
                                                                onChange={async (e) => {
                                                                    const checked = e.target.checked;
                                                                    if (!(await searchMarketSelected(checked, index))) {
                                                                        checkLimit(
                                                                            {
                                                                                ...market,
                                                                                uniqueMarketName: market.name,
                                                                                mrkt_key_selected: market.mrkt_key,
                                                                                marketType: 'regular',
                                                                            },
                                                                            checked,
                                                                            props.folder === 'Statelines' ||
                                                                                props.folder === 'Major Markets' ||
                                                                                props.folder === 'Regions' ||
                                                                                props.folder === 'Divisions'
                                                                                ? market.mrkt_sel_typ
                                                                                : market.marketSelType
                                                                        );
                                                                    }
                                                                }}
                                                                checked={market.selected}
                                                                key={`search-${Date.now()}`}
                                                            />
                                                    )
                                                    : isMarketSelectable(market) && (
                                                        <LegacyByzzerRadio
                                                            checked={selectedSingleMarketFromSearch(market)}
                                                            className={`${props.marketGroup === 'omni' && !(market?.mrkt_entitlements === undefined ||
                                                                market?.is_releasable_mrkt) ? '_disabled' : ''}`}
                                                            onChange={() =>
                                                                singleMarketSelectFromSearch(
                                                                    market.name,
                                                                    market.mrkt_key,
                                                                    market.marketSelType
                                                                )
                                                            }
                                                        />
                                                    )}
                                                <span className={classnames(
                                                    `multi-select-markets__market-label`, {
                                                    [`multi-select-markets__market-label-disabled`]: !isMarketSelectable(market)
                                                }
                                                )}>{market.name}</span>
                                                {<div className='hover-icons-wrapper'>
                                                    {showTooltip(market)}
                                                </div>}

                                                { /** I may have to revisit this */}
                                            </div>
                                            {showRemainingMarkets && (
                                                <div
                                                    className={
                                                        'multi-select-markets-by-channel__remaining_markets_retailers'
                                                    }
                                                >
                                                    <div
                                                        className={`multi-select-markets-by-channel__remaining_markets${!market.stores ? '_disabled' : ''
                                                            }`}
                                                    >
                                                        {isRemainingMarketSelectable(market, 'channel') &&
                                                            props.multiSelect === true
                                                            ? ((selectedItems.length <= props?.maxLimit &&
                                                                selectedItems
                                                                    .map((item) => item.name)
                                                                    .includes(
                                                                        `${market.stores}$$${market.rm_channel_mrkt_key}$$${market.mrkt_key}`
                                                                    )) ||
                                                                selectedItems.length < props?.maxLimit ||
                                                                !props.maxLimit) && (
                                                                <LegacyByzzerCheckbox
                                                                    disabled={
                                                                        !market.stores ||
                                                                        (selectedItems.length >= props?.maxLimit &&
                                                                            !selectedItems
                                                                                .map((item) => item.name)
                                                                                .includes(
                                                                                    `${market.stores}$$${market.rm_channel_mrkt_key}$$${market.mrkt_key}`
                                                                                ))
                                                                    }
                                                                    onChange={async (e) => {
                                                                        const checked = e.target.checked;
                                                                        if (
                                                                            !(await searchMarketSelected(
                                                                                checked,
                                                                                index,
                                                                                'channel'
                                                                            ))
                                                                        ) {
                                                                            checkLimit(
                                                                                {
                                                                                    ...market,
                                                                                    uniqueMarketName: `${market.stores}$$${market.rm_channel_mrkt_key}$$${market.mrkt_key}`,
                                                                                    mrkt_key_selected:
                                                                                        market.rm_channel_mrkt_key,
                                                                                    marketType: 'channel',
                                                                                },
                                                                                checked,
                                                                                market.marketSelType
                                                                            );
                                                                        }
                                                                    }}
                                                                    checked={market.selectedChannel}
                                                                    key={`search-channel-${Date.now()}`}
                                                                />
                                                            )
                                                            : isRemainingMarketSelectable(market, 'channel') && (
                                                                <LegacyByzzerRadio
                                                                    checked={
                                                                        props.from === 'reportSelector' &&
                                                                            props.saveBtnType !== 'alertSelector'
                                                                            ? props.selectorState.focusMarket ===
                                                                            `${market.stores + '<-' + market.name}`
                                                                            : props.saveBtnType === 'alertSelector'
                                                                                ? props.selectorState.focusMarket?.split(
                                                                                    '<-'
                                                                                )[0] === `${market.stores}`
                                                                                : props.userPrefState.primaryMarket ===
                                                                                `${market.stores + '<-' + market.name}`
                                                                    }
                                                                    onChange={() =>
                                                                        singleMarketSelectFromSearch(
                                                                            `${market.stores + '<-' + market.name}`,
                                                                            market.rm_channel_mrkt_key,
                                                                            market.marketSelType
                                                                        )
                                                                    }
                                                                    disabled={!market.stores}
                                                                />
                                                            )}
                                                    </div>
                                                    <div
                                                        className={`multi-select-markets-by-channel__remaining_markets${!market.fmcgRet ? '_disabled' : ''
                                                            }`}
                                                    >
                                                        {isRemainingMarketSelectable(market, 'retailer') &&
                                                            props.multiSelect === true
                                                            ? ((selectedItems.length <= props?.maxLimit &&
                                                                selectedItems
                                                                    .map((item) => item.name)
                                                                    .includes(
                                                                        `${market.fmcgRet}$$${market.rm_xaoc_mrkt_key}$$${market.mrkt_key}`
                                                                    )) ||
                                                                selectedItems.length < props?.maxLimit ||
                                                                !props.maxLimit) && (
                                                                <LegacyByzzerCheckbox
                                                                    disabled={
                                                                        !market.fmcgRet ||
                                                                        (selectedItems.length >= props?.maxLimit &&
                                                                            !selectedItems
                                                                                .map((item) => item.name)
                                                                                .includes(
                                                                                    `${market.fmcgRet}$$${market.rm_xaoc_mrkt_key}$$${market.mrkt_key}`
                                                                                ))
                                                                    }
                                                                    onChange={async (e) => {
                                                                        const checked = e.target.checked;
                                                                        if (
                                                                            !(await searchMarketSelected(
                                                                                checked,
                                                                                index,
                                                                                'retailer'
                                                                            ))
                                                                        ) {
                                                                            checkLimit(
                                                                                {
                                                                                    ...market,
                                                                                    uniqueMarketName: `${market.fmcgRet}$$${market.rm_xaoc_mrkt_key}$$${market.mrkt_key}`,
                                                                                    mrkt_key_selected:
                                                                                        market.rm_xaoc_mrkt_key,
                                                                                    marketType: 'retailer',
                                                                                },
                                                                                checked,
                                                                                market.marketSelType
                                                                            );
                                                                        }
                                                                    }}
                                                                    checked={market.selectedMarket}
                                                                    key={`search-retailer-${Date.now()}`}
                                                                />
                                                            )
                                                            : isRemainingMarketSelectable(market, 'retailer') && (
                                                                <LegacyByzzerRadio
                                                                    disabled={!market.fmcgRet}
                                                                    checked={
                                                                        props.from === 'reportSelector' &&
                                                                            props.saveBtnType !== 'alertSelector'
                                                                            ? props.selectorState.focusMarket ===
                                                                            `${market.fmcgRet + '<-' + market.name}`
                                                                            : props.saveBtnType === 'alertSelector'
                                                                                ? props.selectorState.focusMarket?.split(
                                                                                    '<-'
                                                                                )[0] === `${market.fmcgRet}`
                                                                                : props.userPrefState.primaryMarket ===
                                                                                `${market.fmcgRet + '<-' + market.name}`
                                                                    }
                                                                    onChange={() =>
                                                                        singleMarketSelectFromSearch(
                                                                            `${market.fmcgRet + '<-' + market.name}`,
                                                                            market.rm_xaoc_mrkt_key,
                                                                            market.marketSelType
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    {(props?.saveBtnType !== 'alertSelector' ||
                                                        alertWithAllMarketData) &&
                                                        props?.subSelectors?.markets
                                                            ?.requiredRemMarketForEachRetailer &&
                                                        !(
                                                            props.productType === 'story' &&
                                                            props.marketType === 'comparisonMarket' &&
                                                            market.name === 'Total FMCG Retailers'
                                                        ) &&
                                                        !(
                                                            alertWithAllMarketData &&
                                                            !props?.subSelectors?.markets?.showAllMarkets &&
                                                            market.name === 'Total FMCG Retailers'
                                                        ) && (
                                                            <div
                                                                className={`multi-select-markets-by-channel__remaining_markets${!market.comparativeMarket ? '_disabled' : ''
                                                                    }`}
                                                            >
                                                                {isRemainingMarketSelectable(market, 'comparative') &&
                                                                    props.multiSelect === true
                                                                    ? isComparativeMarketsEnabled(market) && (
                                                                        <LegacyByzzerCheckbox
                                                                            disabled={isComparativeMarketsDisabled(
                                                                                market
                                                                            )}
                                                                            onChange={async (e) => {
                                                                                const checked = e.target.checked;
                                                                                if (
                                                                                    !(await searchMarketSelected(
                                                                                        checked,
                                                                                        index,
                                                                                        'comparative'
                                                                                    ))
                                                                                ) {
                                                                                    checkLimit(
                                                                                        {
                                                                                            ...market,
                                                                                            uniqueMarketName: `${market.comparative_mkt_disp_name}$$${market.comparative_mrkt_key}$$${market.mrkt_key}`,
                                                                                            mrkt_key_selected:
                                                                                                market.comparative_mrkt_key,
                                                                                            marketType: 'comparative',
                                                                                        },
                                                                                        checked,
                                                                                        market.marketSelType
                                                                                    );
                                                                                }
                                                                            }}
                                                                            checked={market.selectedComparitive}
                                                                            key={`search-comparative-${Date.now()}`}
                                                                        />
                                                                    )
                                                                    : isRemainingMarketSelectable(
                                                                        market,
                                                                        'comparative'
                                                                    ) && (
                                                                        <LegacyByzzerRadio
                                                                            disabled={!market.comparativeMarket}
                                                                            checked={
                                                                                props.from === 'reportSelector' &&
                                                                                    props.saveBtnType !== 'alertSelector'
                                                                                    ? props.selectorState
                                                                                        .focusMarket ===
                                                                                    `${market.comparativeMarket +
                                                                                    '<-' +
                                                                                    market.name
                                                                                    }`
                                                                                    : props.saveBtnType ===
                                                                                        'alertSelector'
                                                                                        ? props.selectorState.focusMarket?.split(
                                                                                            '<-'
                                                                                        )[0] ===
                                                                                        `${market.comparativeMarket}`
                                                                                        : props.userPrefState
                                                                                            .primaryMarket ===
                                                                                        `${market.comparativeMarket +
                                                                                        '<-' +
                                                                                        market.name
                                                                                        }`
                                                                            }
                                                                            onChange={() =>
                                                                                singleMarketSelectFromSearch(
                                                                                    `${market.comparativeMarket +
                                                                                    '<-' +
                                                                                    market.name
                                                                                    }`,
                                                                                    market.comparative_mrkt_key,
                                                                                    market.marketSelType
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                </div>
            )}
            {!displayMarketTree && !props.extracts && (
                <>
                    {getMarketAliasInput()}
                    <div
                        className={
                            props?.productType === 'story'
                                ? 'multi-select-markets__submit-btn-container alias-container'
                                : 'multi-select-markets__submit-btn-container'
                        }
                    >
                        {props.saveBtnType !== 'alertSelector' && props.productType !== 'story' && !props.hideSubmitBtn && (
                            <LegacyByzzerButton
                                className={'multi-select-markets__submit-btn-container-button'}
                                label={btnLabel()}
                                onClick={() => submitMarketSelections(props.saveBtnType)}
                                disabled={
                                    props.from === 'reportSelector' &&
                                    !props.selectorState.focusMarket &&
                                    !isMarketSelected &&
                                    !(props.marketGroup === 'omni' && selectedItems.length > 0)
                                }
                            />
                        )}

                        {/* Buttons for Stories */}
                        {getPanelButton()}

                        {/* Buttons for Alerts */}
                        {props.saveBtnType === 'alertSelector' && (
                            <>
                                {!props.fromBack && (
                                    <LegacyByzzerButton
                                        className={'multi-select-markets__submit-btn-container-button'}
                                        label={btnLabel()}
                                        onClick={() => submitMarketSelections(props.saveBtnType)}
                                        disabled={
                                            // TODO : commented code to be removed later once markets to watch change is stable
                                            // props?.subSelectors?.markets?.requireComparisonMarketType
                                            //     ? props.selectorState.comparisonMarketTypeSelection === '' ||
                                            //         (!props.selectorState.focusMarket && !isMarketSelected)
                                            // : 
                                            props.from === 'reportSelector' &&
                                            !props.selectorState.focusMarket &&
                                            !isMarketSelected
                                        }
                                    />
                                )}
                                {props.fromBack && (
                                    <LegacyByzzerButton
                                        className={`multi-select-markets__submit-btn-container-button`}
                                        label={showUpdate || props.selectorState.marketTypeUpdated ? 'Update' : 'Next'}
                                        onClick={() => submitMarketSelections(props.saveBtnType)}
                                        disabled={
                                            showUpdate ?
                                                // TODO : commented code to be removed later once markets to watch change is stable
                                                // props?.subSelectors?.markets?.requireComparisonMarketType
                                                //     ? props.selectorState.comparisonMarketTypeSelection === '' ||
                                                //   (!props.selectorState.focusMarket && !isMarketSelected)
                                                // : 
                                                props.from === 'reportSelector' &&
                                                !props.selectorState.focusMarket &&
                                                !isMarketSelected
                                                : true
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            {displayMarketTree && props.from !== 'userPref' && (
                // Added for Stories Feature
                <>
                    {getMarketAliasInput()}
                    <div
                        className={
                            props.productType === 'story'
                                ? `alias-container ${props.saveBtnType === 'alertSelector'
                                    ? 'multi-select-markets__submit-btn-container-alert'
                                    : 'multi-select-markets__submit-btn-container'
                                }`
                                : `${props.saveBtnType === 'alertSelector'
                                    ? 'multi-select-markets__submit-btn-container'
                                    : 'multi-select-markets__submit-btn-container'
                                }`
                        }
                    >
                        {/* button for reports   */}
                        {!props.extracts && props?.productType !== 'story' && props.saveBtnType !== 'alertSelector' && (
                            <>
                                <LegacyByzzerButton
                                    className={'multi-select-markets__submit-btn-container-button'}
                                    label={btnLabel()}
                                    onClick={() => submitMarketSelections(props.saveBtnType)}
                                    disabled={
                                        props.from === 'reportSelector' &&
                                        !props.selectorState.focusMarket &&
                                        !isMarketSelected
                                    }
                                    style={{ width: '30px' }}
                                />
                                {props?.canGoBack === true && (
                                    <div className={'alert-market-selector-back-button'}>
                                        <div
                                            className={'alert-market-selector-back-button-text'}
                                            onClick={() => props.changeStep(false)}
                                        >
                                            Back
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {/* button for stories   */}
                        {getPanelButton()}

                        {/* Buttons for Alerts */}
                        {props.saveBtnType === 'alertSelector' && (
                            <>
                                {!props.fromBack && (
                                    <LegacyByzzerButton
                                        className={'multi-select-markets__submit-btn-container-button'}
                                        label={btnLabel()}
                                        onClick={() => submitMarketSelections(props.saveBtnType)}
                                        disabled={
                                            // TODO : commented code to be removed later once markets to watch change is stable
                                            // props?.subSelectors?.markets?.requireComparisonMarketType
                                            //     ? props.selectorState.comparisonMarketTypeSelection === '' ||
                                            //       (!props.selectorState.focusMarket && !isMarketSelected)
                                            //     :
                                            props.from === 'reportSelector' &&
                                            !props.selectorState.focusMarket &&
                                            !isMarketSelected
                                        }
                                    />
                                )}
                                {props.fromBack && (
                                    <LegacyByzzerButton
                                        className={`${props.saveBtnType === 'alertSelector'
                                            ? 'multi-select-markets__submit-btn-container-button'
                                            : 'multi-select-markets__submit-btn-container-button'
                                            }`}
                                        label={showUpdate || props.selectorState.marketTypeUpdated ? 'Update' : 'Next'}
                                        onClick={() => submitMarketSelections(props.saveBtnType)}
                                        disabled={
                                            showUpdate || props.selectorState.marketTypeUpdated
                                                ?
                                                // TODO : commented code to be removed later once markets to watch change is stable
                                                // props?.subSelectors?.markets?.requireComparisonMarketType
                                                //     ? props.selectorState.comparisonMarketTypeSelection === '' ||
                                                //       (!props.selectorState.focusMarket && !isMarketSelected)
                                                //     :
                                                props.from === 'reportSelector' &&
                                                !props.selectorState.focusMarket &&
                                                !isMarketSelected
                                                : true
                                        }
                                    />
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
            {showWarning1 && (
                <ByzzerErrorModal
                    showWarning={showWarning1}
                    setShowWarning={setShowWarning1}
                    headerType={'none'}
                    contentType={contentType1}
                    warningMessage={warningMessage1}
                    size={'small'}
                />
            )}
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
            <ByzzerModal
                show={statusInfo}
                onClose={() => setStatusInfo(!statusInfo)}
                size={'medium'}
                headerType={'none'}
                className="byzzer-model-position"
            >
                <div className="prior-approved-form__request-status-table-info">
                    {/* <span className="prior-approved-form__request-status-table-info-title">Pending:</span> */}
                    &nbsp;Selecting the remaining market allows you to choose how to compare the focus retailer to its
                    competitors. For example, if you are going to run a report on a Grocery retailer, you will often
                    have two choices for Remaining market: Remaining Grocery, and Remaining FMCG. If you compete in a
                    category that is typically sold in grocery, and other FMCG channels like Mass or Drug aren't as
                    relevant, you can choose Remaining Grocery to make sure the competitive market is applicable. On the
                    other hand, if sales in other channels like Mass, Drug, Club or Dollar are relevant, you can choose
                    Remaining FMCG for a more complete picture of the total competitive market.
                </div>
            </ByzzerModal>
        </div>
    );
}

export default MultiSelectMarkets;