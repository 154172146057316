import './TimePeriodPicker.scss';
import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { ByzzerDatePicker, ByzzerRadio, ByzzerRadioGroup, ByzzerSelect, WithValue } from '@byzzer/ui-components';
import {
    MonthEndingTimePeriod,
    OMNI_TIME_PERIODS,
    RELATIVE_TIME_PERIODS,
    TimePeriod,
    TimePeriodType,
    WeekEndingTimePeriod,
    CustomTimePeriod,
    RelativeTimePeriod,
} from '@/types/ReportRun';
import {useApp} from "@/contexts/UserContext";
import {addMinutes, format, subWeeks} from "date-fns";
import {useTenantApi} from '@/hooks/useTenantApi';
import { SelectorLabelInfo } from '../SelectorLabelInfo/SelectorLabelInfo';

// create an array with range 1-52 as strings for non-omni reprots
export const WEEK_OPTIONS = Array.from({ length: 52 }).map((_, i) => `${i + 1}`);

// create an array with range 1-24 as strings for omni reprots
export const MONTH_OPTIONS = Array.from({ length: 24 }).map((_, i) => `${i + 1}`);

export type TimePeriodPickerProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<TimePeriod>) => void;
    value?: any;
    summary?: ReactNode;
    datatype?: datatype;
    maxDataDates?: MaxDataDates;
    sku?: string;
    hideLatestDate?: boolean;
    hideCustomeDate?: boolean;
    timePeriodOptions?: readonly string[];
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'time-period-picker';

export const TimePeriodPicker = forwardRef<WithValue<TimePeriod>, TimePeriodPickerProps>(
    ({ onChange, name, datatype = 'rms', className, maxDataDates, sku, value, hideLatestDate, hideCustomeDate: hideCustomDate, timePeriodOptions, ...props }, ref) => {
        const { getOmniDates } = useTenantApi();

        const { maxDataDates: contextMaxDataDates, features } = useApp();
        const preDefinedTimePeriods: readonly string[] =
            timePeriodOptions || (datatype === 'omni' ? OMNI_TIME_PERIODS : RELATIVE_TIME_PERIODS);
        const includeCustomTimePeriod = datatype !== 'omni';
        const latestDate = (maxDataDates ?? contextMaxDataDates)?.[datatype];
        const [timePeriod, setTimePeriod] = useState<TimePeriod>({
            type: includeCustomTimePeriod ? 'relative' : 'omni',
        });
        let formattedLatestDate = latestDate ? format(new Date(latestDate), 'yyyy-MM-dd') : 'Unknown';
        const [omniDates, setOmniDates] = useState<any>();
        
        const PRESET_TP_LABEL = 'Use A Preset';
        const CUSTOM_TP_LABEL = 'Create A Custom Time Period';        

        /** pull omni dates from the api*/
        async function fetchOmniDates() {
            try {
                let omniActiveDates: string[] = await getOmniDates();
                setOmniDates(omniActiveDates);
            } catch (err) {
                console.log(err);
            }
        }

        //fetch the omni dates from the api only for omni reports
        useEffect(() => {
            if (!includeCustomTimePeriod) fetchOmniDates();
        }, []);

        useEffect(() => {
            setTimePeriod(value);
        }, [value]);
        //the below method is not needed as we replaced the date with ByzzerDate component
        // useEffect(() => {
        //     const isRelativeTp: boolean = ['relative' ,'omni' ].includes( value?.type );

        //     if (isRelativeTp) {
        //         const relativeTpValue = value as RelativeTimePeriod;
        //         setTimePeriod({
        //             type: relativeTpValue?.type,
        //             period: relativeTpValue?.period,
        //         })
        //     } else {
        //         const customTpValue = value as CustomTimePeriod;
        //         const customEndDate = customTpValue?.period?.endDate;

        //         const formattedCustomEndDate = typeof customEndDate === 'string' ? new Date(addMinutes(new Date(customEndDate), new Date(customEndDate).getTimezoneOffset())) : customEndDate;

        //         setTimePeriod({
        //             type: customTpValue.type,
        //             period: {
        //                 ...customTpValue.period,
        //                 endDate: formattedCustomEndDate,
        //             },

        //         })
        //     }
        //     }, [value]);

        function handleWeeksChange(e: ByzzerChangeEvent<number>) {
            onChange?.({
                name,
                value: {
                    ...timePeriod,
                    period: {
                        ...(timePeriod as any).period,
                        weeks: Number(e.value),
                    },
                },
            });
            setTimePeriod((timePeriod) => ({
                ...timePeriod,
                period: {
                    ...(timePeriod as any).period,
                    weeks: Number(e.value),
                },
            }));
        }
        function handleMonthsChange(e: ByzzerChangeEvent<number>) {
            onChange?.({
                name,
                value: {
                    ...timePeriod,
                    period: {
                        ...(timePeriod as any).period,
                        months: Number(e.value),
                    },
                },
            });
            setTimePeriod((timePeriod) => ({
                ...timePeriod,
                period: {
                    ...(timePeriod as any).period,
                    months: Number(e.value),
                },
            }));
        }

        function handleEndDateChange(e: ByzzerChangeEvent<Date | null>) {
            onChange?.({
                name,
                value: {
                    ...timePeriod,
                    period: {
                        ...(timePeriod as any).period,
                        endDate: e.value,
                    },
                },
            });
            setTimePeriod((timePeriod) => ({
                ...timePeriod,
                period: {
                    ...(timePeriod as any).period,
                    endDate: e.value,
                },
            }));
        }

        function handlePreDefinedChange(e: ByzzerChangeEvent<string>) {
            onChange?.({
                name,
                value: {
                    ...timePeriod,
                    period: e.value as any,
                },
            });

            setTimePeriod({
                ...timePeriod,
                period: e.value as any,
            });
        }

        function handleTypeChange(e: ByzzerChangeEvent<TimePeriodType>) {
            onChange?.({
                name,
                value: {
                    type: e.value,
                },
            });
            setTimePeriod({
                type: e.value,
            });
        }

        function dateFilter(date: Date): boolean {                        
            // Calculate the total number of weeks allowed
            /** This change is regarding BYZ-13779, user can select date upto 104 or 208 weeks back from latestDate */
            const totalWeeks = (features?.extendedDataYears ? features.extendedDataYears - 1 : 2) * 52; // Calculate weeks based on feature data
            const maxAllowedDate = subWeeks(new Date(latestDate!), totalWeeks); // Calculate the date `totalWeeks` ago from today
                    
            if (includeCustomTimePeriod) {
                //filter for non-omni reports
                const isSaturday = date.getDay() === 6;
                const isBeforeMaxDate = date.getTime() <= Number(new Date(latestDate!).getTime());
                const isAfterMaxAllowedDate = date.getTime() >= maxAllowedDate.getTime(); // Ensure date is within the allowed weeks range
                
                return isSaturday && isBeforeMaxDate && isAfterMaxAllowedDate;
            } else {
                //filter for omni reports
                const _omniDatesArr = omniDates?.map((odate) => JSON.stringify(odate));
                return _omniDatesArr.includes(JSON.stringify(date));
            }
        }

        return (
            <>
                <div className={classnames(baseClassName, className)}>
                    <ByzzerRadioGroup value={timePeriod.type} onChange={handleTypeChange}>
                        <div className={`${baseClassName}__time-period`}>
                            {!hideCustomDate && <ByzzerRadio
                                value={includeCustomTimePeriod ? 'relative' : 'omni'}
                                label={Number(sku) ? (
                                    <SelectorLabelInfo 
                                        selectorCode={'presetTp'} 
                                        sku={sku as string}
                                        defaultLabel={PRESET_TP_LABEL}
                                    />
                                    ) : (
                                        PRESET_TP_LABEL
                                    )
                                }
                            />}

                            <div
                                className={classnames(`${baseClassName}__value`, `${baseClassName}__preset-value`, {
                                    [`${baseClassName}__value--active`]: ['relative', 'omni'].includes(timePeriod.type),
                                })}
                            >
                                <ByzzerSelect
                                    value={timePeriod.period as string}
                                    label={
                                        <SelectorLabelInfo 
                                            selectorCode={'timePeriod'} 
                                            sku={sku as string} 
                                            defaultLabel={'Time Periods'} 
                                        />
                                    }
                                    onChange={handlePreDefinedChange}
                                    options={preDefinedTimePeriods as string[]}
                                />
                            </div>
                            {!hideLatestDate && (
                                <span className={`${baseClassName}__latest-date`}>Latest Date: {formattedLatestDate}</span>
                            )}
                        </div>

                        {!hideCustomDate && <>
                            <div className={`${baseClassName}__separator`}>Or</div>
                            <div className={`${baseClassName}__time-period`}>
                                <ByzzerRadio
                                    label={ Number(sku) ? (<SelectorLabelInfo selectorCode={'customTp'} sku={sku as string} />) : CUSTOM_TP_LABEL }
                                    value={includeCustomTimePeriod ? 'week_ending' : 'omni_custom'}
                                />
                                <div
                                    className={classnames(`${baseClassName}__value`, `${baseClassName}__custom-value`, {
                                        [`${baseClassName}__value--active`]: ['week_ending', 'omni_custom'].includes(
                                            timePeriod.type
                                        ),
                                    })}
                                >
                                    {includeCustomTimePeriod ? (
                                        <ByzzerSelect
                                            label={'Weeks'}
                                            name={'weeks'}
                                            allowClear={false}
                                            className={`${baseClassName}__weeks`}
                                            value={(timePeriod as WeekEndingTimePeriod).period?.weeks?.toString()}
                                            options={WEEK_OPTIONS}
                                            onChange={handleWeeksChange}
                                        />
                                    ) : (
                                        <ByzzerSelect
                                            label={'Months'}
                                            name={'months'}
                                            allowClear={false}
                                            className={`${baseClassName}__months`}
                                            value={(timePeriod as MonthEndingTimePeriod).period?.months?.toString()}
                                            options={MONTH_OPTIONS}
                                            onChange={handleMonthsChange}
                                        />
                                    )}

                                    <ByzzerDatePicker
                                        label={'Ending'}
                                        name={'endDate'}
                                        className={`${baseClassName}__end-date`}
                                        value={
                                            includeCustomTimePeriod
                                                ? (timePeriod as WeekEndingTimePeriod).period?.endDate
                                                : (timePeriod as MonthEndingTimePeriod).period?.endDate
                                        }
                                        onChange={handleEndDateChange}
                                        filterDate={dateFilter}
                                        timeZone="America/New_York"
                                        truncateTime={true}
                                        pickerPlacement="right"
                                    />
                                </div>
                            </div>
                        </>}
                    </ByzzerRadioGroup>
                </div>
            </>
        );
    }
);

export default TimePeriodPicker;
