import './DodMarketFiltersStep.scss';
import React, { ReactNode, useMemo, useState } from 'react';
import classnames from 'classnames';
import { StepRef, WizardContent, WizardStep } from '@byzzer/ui-components';
import { DodMarketFilterBuilder, DodMarketFilterBuilderProps } from '@/components/DodConfigEditor/builders';
import { DodWizardActions } from '../DodWizardActions';
import { uniqBy } from 'lodash';

export type DodMarketFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
    helpIconTip: string | ReactNode;
} & DodMarketFilterBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

export const DodMarketFiltersStep = React.forwardRef<StepRef, DodMarketFiltersStepProps>(
    ({ className, title = 'Markets', enabled, onValidityChange, exclude, helpIconTip, ...props }, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const { values, summedSelections } = props.value.markets;
            const allValues = [...values, ...summedSelections.map((ss) => ss.values)].flat();
            const count = uniqBy(allValues, 'key').length;
            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                ref={ref}
                id={'markets'}
                title={`${title} ${count}`}
                enabled={enabled}
                exclude={exclude}
            >
                <WizardContent>
                    <DodMarketFilterBuilder {...props} onValidityChange={handleValidationChange} />
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    helpIconTip={helpIconTip}
                    nextDisabledTip={'Please choose at least one market to continue.'}
                />
            </WizardStep>
        );
    }
);

DodMarketFiltersStep.displayName = 'DodMarketFiltersStep';
