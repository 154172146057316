import './ForgotPassword.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LegacyByzzerButton, ByzzerInput } from '@/components/form';
import {useTenantApi} from '@/hooks/useTenantApi';
import { openModal } from '@/components/form/ByzzerModal';

function ForgotPassword({ possibleEmail, props }) {
    const { sendResetPasswordLink } = useTenantApi();
    useEffect(() => {
        if (possibleEmail) {
            setEmail(possibleEmail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleEmail]);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [busy, setBusy] = useState(false);

    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    function updateEmail({ target }) {
        setEmail(target.value);
    }

    async function showCheckEmailModal() {
        await openModal({
            title: 'Please check your email.',
            content: (
                <>
                    <p>
                        If this email address is associated with a Byzzer account, you'll receive an email with a
                        reset link. If you don't receive an email, please check your spelling or look in your spam
                        folder.
                    </p>
                </>
            ),
        });
    }

    async function sendForgotPassword() {
        try {
            setBusy(true);
            await sendResetPasswordLink(email);
            await showCheckEmailModal();
            navigate('sign_in', {replace: true});
        } catch (err) {
            await showCheckEmailModal();
            navigate('sign_in', {replace: true});
        } finally {
            setBusy(false);
        }
    }

    function checkBtnDiabled() {
        return !email.length || !emailRegex.test(email);
    }

    function redirectToSignIn() {
        navigate('sign_in', {replace: true})
    }

    const baseClassName = 'forgot-password';

    return (
        <div className={baseClassName}>
            <header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>Forgot Password</h1>
                <h2 className={`${baseClassName}__sub-title`}>
                    Enter your email address and we'll send you a reset link.
                </h2>
            </header>
            <main className={`${baseClassName}__content`}>
                <ByzzerInput
                    className={'email'}
                    name={'email'}
                    value={email}
                    type={'email'}
                    onChange={updateEmail}
                    placeholder={'example@mail.com'}
                    required
                    variant={'secondary'}
                />
            </main>
            <footer className={`${baseClassName}__footer`}>
                <LegacyByzzerButton
                    onClick={sendForgotPassword}
                    label={'Reset Password'}
                    disabled={checkBtnDiabled()}
                    busy={busy}
                />
                <LegacyByzzerButton className={`${baseClassName}__footer-cancel`} onClick={redirectToSignIn} label={'Cancel'} />
            </footer>
        </div>
    );
}

export default ForgotPassword;
