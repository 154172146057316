import './ScheduleReportStep.scss';
import React, { ReactNode, useImperativeHandle, useRef } from 'react';
import classnames from 'classnames';
import { ByzzerButton, ByzzerChangeEventHandler, WithUid } from '@byzzer/ui-components';
import { WizardContent, WizardHeader, WizardStep } from '@byzzer/ui-components';
import ReportScheduleRunBuilder from '../builders/ReportScheduleRunBuilder/ReportScheduleRunBuilder';
import { ConfigOptionsType } from '@/types/RunConfigOptions';

export type ScheduleReportStepProps = {
    title?: ReactNode;
    className?: string;
    id?: ConfigOptionsType;
    enable?: boolean;
    exclude: boolean;
    value: Schedule;
    initialSchedule: Schedule | undefined;
    onChange: ByzzerChangeEventHandler<Schedule>;
    onNext: (value: Schedule) => void;
    isEditScheduleMode?: boolean;
};

const baseClassName = 'schedule-report-step';

const ScheduleReportStep = React.forwardRef<WithUid, ScheduleReportStepProps>(
    (
        {
            className,
            title = 'Schedule',
            enable,
            exclude,
            onNext,
            value,
            initialSchedule,
            isEditScheduleMode,
            onChange,
            id,
            ...props
        },
        ref
    ) => {
        const stepRef = useRef<any>();

        // this is required to allow multiple refs to the step.  needs the dependency array or will cause infinite loop
        useImperativeHandle(ref, () => stepRef.current, []);

        const handleScheduleChange = (e: ByzzerChangeEvent<Schedule>): void => {
            onChange(e);
        };

        const handleNext = (): boolean => {
            onNext?.(value);
            return true;
        };

        const hasMissingRequiredFields = (): boolean => {
            return Boolean(
                !value.frequency ||
                    (!value.timePeriods?.length && !value.startingTimePeriod) ||
                    !value.deliveryDay ||
                    !value.deliveryGroupType ||
                    !value.deliverToUserIds?.length ||
                    (value.frequency !== 'one_time' && value.frequency !== 'custom' && !value.duration)
            );
        };

        const hasValuesChanged = (): boolean => {
            return JSON.stringify(initialSchedule) !== JSON.stringify(value);
        }

        const getDisabledTip = () => {
            let tip = 'Please select all required fields to continue.';

            if (
                hasMissingRequiredFields() &&
                !value.deliverToUserIds.length &&
                ['team', 'everyone'].includes(value.deliveryGroupType)
            ) {
                tip = 'There are no active members in company subscription to notify about completed runs.';
            } else if (!hasValuesChanged()) {
                tip = 'Please make any change in existing schedule to continue.';
            }

            return tip;
        };

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                byzRef={stepRef}
                enabled={enable}
                title={title}
                exclude={exclude}
                id={id}
            >
                <WizardHeader>
                    <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
                </WizardHeader>
                <WizardContent>
                    <ReportScheduleRunBuilder value={value} onChange={handleScheduleChange} />
                </WizardContent>
                <footer className={`${baseClassName}__actions`}>
                    <span className={`${baseClassName}__actions_button`}>
                        <ByzzerButton
                            className={`${baseClassName}__schedule-btn`}
                            disabled={hasMissingRequiredFields() || !hasValuesChanged()}
                            disabledTip={getDisabledTip()}
                            onClick={handleNext}
                        >
                            {isEditScheduleMode ? 'Apply' : 'Create Schedule'}
                        </ByzzerButton>
                    </span>
                </footer>
            </WizardStep>
        );
    }
);

export default ScheduleReportStep;
