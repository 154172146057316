import './ReportScheduleRunBuilder.scss';
import React, { ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import SchedulePicker from '@/components/Schedule/SchedulePicker';
import SchedulePreview from '@/components/Schedule/SchedulePreview';
import {
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    ByzzerSelectValueTypes,
} from '@byzzer/ui-components';
import { useUser } from '@/contexts/UserContext';
import { isValidArray } from '@/utils';

const baseClassName = 'report-schedule-run-builder';

export type ReportScheduleRunBuilderProps = {
    className?: string;
    name?: string;
    value: Schedule;
    tip?: ReactNode;
    onChange: ByzzerChangeEventHandler<Schedule>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type RepetitionOption = {
    display: string;
    value: ScheduleDeliveryGroupType;
};

const NOTIFY_OPTIONS: RepetitionOption[] = [
    {
        display: 'Only Me',
        value: 'me',
    },
    {
        display: 'My Team',
        value: 'team',
    },
    {
        display: 'Everyone on my Subscription',
        value: 'everyone',
    },
    {
        display: 'Pick By Member',
        value: 'custom',
    },
];

const ReportScheduleRunBuilder = ({
    className,
    name,
    onChange,
    onValidityChange,
    value,
    tip,
    ...props
}: ReportScheduleRunBuilderProps) => {
    const [userOptions, setUserOptions] = useState<ByzzerSelectOption[]>([]);
    const [deliveryGroupType] = useState<ScheduleDeliveryGroupType>(NOTIFY_OPTIONS[0].value);
    const { teamMembers: allUsersInCompany, user } = useUser();

    useEffect(() => {
        const userIds = getSelectedUserIds(value.deliveryGroupType || deliveryGroupType);
        if (!isValidArray(value.deliverToUserIds)) {
            onChange?.({
                value: {
                    ...value,
                    deliverToUserIds: userIds,
                },
            });
        }
    }, []);

    function getSelectedUserIds(deliveryGroupType: ScheduleDeliveryGroupType) {
        switch (deliveryGroupType) {
            case 'me':
                setUserOptions([{ display: `${user?.firstName} ${user?.lastName}`, value: user?.id }]);
                return [user?.id];
            case 'custom':
                const members = allUsersInCompany
                    .filter((member) => member.type === 'user')
                    .map((user) => {
                        return { display: `${user.firstName} ${user.lastName}`, value: user.id };
                    });
                setUserOptions(members);
                return [];
            case 'team':
                const team = allUsersInCompany
                    .filter((member) => member.teamId === user?.teamId && member.type === 'user')
                    .map((user) => {
                        return { display: `${user.firstName} ${user.lastName}`, value: user.id };
                    });
                setUserOptions(team);
                return team.map((member) => member.value);
            case 'everyone':
                const everyone = allUsersInCompany
                    .filter((member) => member.type === 'user')
                    .map((user) => {
                        return { display: `${user.firstName} ${user.lastName}`, value: user.id };
                    });
                setUserOptions(everyone);
                return everyone.map((member) => member.value);
            default:
                return [];
        }
    }

    function handleDeliveryGroupTypeChange(e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>) {
        const deliveryGroupType = e.value as ScheduleDeliveryGroupType;
        const userIds = getSelectedUserIds(deliveryGroupType);
        onChange?.({
            value: {
                ...value,
                deliveryGroupType: e.value as ScheduleDeliveryGroupType,
                deliverToUserIds: userIds,
            },
        });
    }

    function handleDeliveryMemberChange(e: ByzzerChangeEvent<ByzzerSelectValueTypes | undefined>) {
        onChange?.({
            value: {
                ...value,
                deliverToUserIds: e.value as number[],
            },
        });
    }

    function handleSchedulePickerChange(e: ByzzerChangeEvent<Schedule>) {  
        onChange?.({value: e.value});
    }

    function handleSchedulePreviewChange(e: ByzzerChangeEvent<Schedule>) {        
        onChange?.({value: e.value});
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <SchedulePicker value={value} onChange={handleSchedulePickerChange} className={`${baseClassName}__timepicker-section`} />
            <SchedulePreview value={value} onChange={handleSchedulePreviewChange} className={`${baseClassName}__delivery-schedule`} />
            <div className={`${baseClassName}__notify-section`}>
                <ByzzerSelect
                    label="Who do you want to notify each time the run is ready?"
                    value={value.deliveryGroupType}
                    options={NOTIFY_OPTIONS}
                    allowClear={false}
                    onChange={handleDeliveryGroupTypeChange}
                />
                {value.deliveryGroupType === 'custom' && (
                    <ByzzerSelect
                        label="Selected Members"
                        value={value.deliverToUserIds}
                        options={userOptions}
                        onChange={handleDeliveryMemberChange}
                        allowMultiple={true}
                    />
                )}
            </div>
        </div>
    );
};

export default ReportScheduleRunBuilder;
