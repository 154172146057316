import './DodProductFiltersStep.scss';
import React, { ReactNode, useMemo, useState } from 'react';
import classnames from 'classnames';
import { StepRef, WizardContent, WizardStep } from '@byzzer/ui-components';
import { DodTimePeriodFilterBuilder, DodTimePeriodFilterBuilderProps } from '@/components/DodConfigEditor/builders';
import { DodWizardActions } from '../DodWizardActions';

export type DodTimePeriodFiltersStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
    helpIconTip: string | ReactNode;
} & DodTimePeriodFilterBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

export const DodTimePeriodFiltersStep = React.forwardRef<StepRef, DodTimePeriodFiltersStepProps>(
    ({ className, enabled, title = 'Time Periods', exclude, onValidityChange, helpIconTip, ...props }, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const { values, summedSelections } = props.value.timePeriods;
            const count = values.length + summedSelections.length;
            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                ref={ref}
                enabled={enabled}
                id={'timePeriods'}
                exclude={exclude}
                title={`${title} ${count}`}
            >
                <WizardContent>
                    <DodTimePeriodFilterBuilder {...props} onValidityChange={handleValidationChange} />
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    helpIconTip={helpIconTip}
                    nextDisabledTip={'Please choose at least one time period to continue.'}
                />
            </WizardStep>
        );
    }
);
