import './DodFactSetBuilder.scss';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { ByzzerChangeEventHandler } from '@byzzer/ui-components';
import DodFactValuePickerTable from '@/components/DodConfigEditor/builders/DodFactSetBuilder/DodFactValuePickerTable/DodFactValuePickerTable';
import { DodPanel, DodPanelRef } from '@/components/DodConfigEditor/common/DodPanel';
import { alert as byzzerAlert } from '@/components/form';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { DodFactTypePicker } from './DodFactTypePicker';
import { DodFactSetPreview } from './DodFactSetPreview';
import { DodFactSet, DodFilters } from '@/types/DodRun';
import { useUser, useApp } from '@/contexts/UserContext';
import { useEventDataWithUserInfo, useTrackEvent } from '@/analytics/AnalyticsContext';
import { DodPreset } from '@/types/ApiTypes';
import { DodFactFilterType, DodFilterType } from '../../types';
import { filterHasValues, isWorkInProgressFactsDisabled } from '../../common/utils';

const baseClassName = 'dod-fact-set-builder';

export type DodFactSetBuilderProps = {
    className?: string;
    name?: string;
    tip?: ReactNode;
    filters: DodFilters;
    value: DodFactSet[];
    onChange: ByzzerChangeEventHandler<DodFactSet[]>;
    onValidityChange?(e: ByzzerValidityChangeEvent): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

export function DodFactSetBuilder({
                                      className,
                                      name,
                                      value,
                                      onChange,
                                      filters,
                                      onValidityChange,
                                      tip,
                                      ...props
                                  }: DodFactSetBuilderProps) {

    const {subscription} = useUser()
    const selectionLimit = subscription?.metadata?.extractLimits?.facts ?? Infinity
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const { factConfig } = useApp();
    const factTriggerRef = useRef<HTMLDivElement>(null);

    const [filterText, setFilterText] = useState<string>('');
    const [filterType, setFilterType] = useState<DodFilterType<DodFactFilterType>>({
        type: 'All Facts',
        data: {
            type: "core"
        }
    });
    const valuePanelRef = useRef<DodPanelRef>(null);

    const { runConfig, presets, rowColConfigs, deletePreset, setRowColConfigs } = useDodWizard();

    useEffect(() => {
        const atleastOneFactSelected: boolean = value.some(
            (fact) =>
                fact.fact ||
                fact.yearAgo ||
                fact.changeVsYearAgo ||
                fact.percentChangeVsYearAgo ||
                fact.category ||
                fact.categoryYearAgo ||
                fact.brand ||
                fact.brandYearAgo ||
                fact.productSelections ||
                fact.productSelectionsYearAgo
        );
        onValidityChange?.({
            name,
            isValid: Boolean(value.length && atleastOneFactSelected),
        });
    }, [value]);

    function handleFilterTypeChange(e) {
        if (e.value?.type?.toLowerCase() !== 'all facts') {
            trackEvent({
                type: 'click',
                name: 'dod_filter_type_click',
                data: getEventData({
                    dodWizardStep: 'fact',
                    panel: 'value picker',
                    filterType: e.value?.type,
                    folder: e.value?.data?.type,
                }),
            });
        }
        if (e.value.type === "mySavedFacts") {
            onChange({name, value: e.value.data})
        } else {
            setFilterType(e.value);
        }

        valuePanelRef.current?.resetFilter();
    }

    function handleFilterValueChange(e: ByzzerChangeEvent<DodFactSet[]>, keepPresets?: boolean) {
        // * remove selected preset values
        if (presets?.fact && !keepPresets) {
            deletePreset('fact');
        }

        validateLayoutHideCheckbox(e.value);

        let updatedValue: DodFactSet[] = value ?? [];
        updatedValue = e.value;
        onChange({
            name,
            value: updatedValue,
        });
    }

    function validateLayoutHideCheckbox(facts: DodFactSet[]) {
        let isCategoryFact = false;
        let isBrandFact = false;
        let isUPCFact = false;
        let isUPCHidden = false;

        for (const fact of facts) {
            if (fact.category || fact.categoryYearAgo) isCategoryFact = true;
            if (fact.brand || fact.brandYearAgo) isBrandFact = true;
            if (fact.isUPCrequired) isUPCFact = true;

            if (isCategoryFact && isBrandFact && isUPCFact) break;
        }
        if (isCategoryFact || isBrandFact || isUPCFact) {
            setRowColConfigs((rowColConfigs) => {
                let updated = false;
                const newConfigs = rowColConfigs.map((d) => {
                    if (d.type === 'products') {
                        if (d.dim === 'categories' && isCategoryFact && d.hide) {
                            d.hide = false;
                            updated = true;
                        }
                        if (d.dim === 'brands' && isBrandFact && d.hide) {
                            d.hide = false;
                            updated = true;
                        }
                        if (d.dim === 'upcs' && isUPCFact && d.hide) {
                            d.hide = false;
                            updated = true;
                            isUPCHidden = true;
                        }
                    }
                    return d;
                });

                return updated ? newConfigs : rowColConfigs;
            });
        }
        if (isUPCHidden) {
            byzzerAlert({
                content: <div>Adding this fact will unhide UPC in your layout selections panel.</div>,
            });
        }
    }

    function validateFactNames(facts: DodFactSet[]): DodFactSet[] {
        let validatedFacts: DodFactSet[] = [];
        const allFacts: DodFactSet[] = factConfig.core.find((v) => v.value === 'All Facts').factLists;
        validatedFacts = facts
            .map((fact) => {
                let foundFact = allFacts.find((ft) => ft.id === Number(fact.id));
                if (foundFact) {
                    return { ...fact, display: foundFact.display };
                } else {
                    return undefined;
                }
            })
            .filter((fact) => fact !== undefined);
        return validatedFacts;
    }

    function handlePresetSelect({ value }: ByzzerChangeEvent<DodPreset<DodFactSet[]>>) {
        // upc facts need only to check if upc is selected or not
        const upcFactsWithoutUPCSelection =
            value.values.some((fact) => fact.isUPCrequired) && !filterHasValues(filters.upcs);
        // brand facts need that either brand or upc be selected, hence checking filter values of both brand and upcs
        const brandFactsWithoutBrandOrUPCSelection =
            value.values.some((fact) => fact.brand || fact.brandYearAgo) &&
            !filterHasValues(filters.brands) &&
            !filterHasValues(filters.upcs);

        let updatedFactValues = value.values.filter((fact) =>
            !isWorkInProgressFactsDisabled({ fact, runConfig, rowColConfigs })
        );

        if (upcFactsWithoutUPCSelection || brandFactsWithoutBrandOrUPCSelection) {
            let alertMessage = '';

            if (upcFactsWithoutUPCSelection && brandFactsWithoutBrandOrUPCSelection) {
                alertMessage =
                    'This saved facts list contains Brand-level and UPC-level only facts, but you do not have UPC or Brand included in your selections. These facts will not be added.';
            } else if (upcFactsWithoutUPCSelection) {
                alertMessage =
                    'This saved facts list contains UPC-level only facts, but you do not have UPC included in your selections. These facts will not be added.';
            } else {
                alertMessage =
                    'This saved facts list contains Brand-level facts, but you do not have Brand or UPC included in your selections. These facts will not be added.';
            }

            byzzerAlert({ content: <div>{alertMessage}</div> });

            // if only upc facts were selected without upc being selected OR
            // if both brand and upc level facts are selected without brand or upc selection
            if ((upcFactsWithoutUPCSelection && brandFactsWithoutBrandOrUPCSelection) || upcFactsWithoutUPCSelection) {
                // remove upc level facts
                updatedFactValues = updatedFactValues.filter((fact) => !fact.isUPCrequired);
                // if no brand selection, remove brand level facts also (since upc selection can enable to select brand facts also)
                if (!filterHasValues(runConfig.filters.brands)) {
                    updatedFactValues = updatedFactValues.map((fact) => ({
                        ...fact,
                        brand: false,
                        brandYearAgo: false,
                    }));
                }
            } else { // if only brand facts were selected without brand or upc being selected
                updatedFactValues = updatedFactValues.map((fact) => ({
                    ...fact,
                    brand: false,
                    brandYearAgo: false,
                }));
            }
        }

        // remove all facts that are not valid
        updatedFactValues = updatedFactValues.filter(
            (fact) =>
                fact.fact ||
                fact.yearAgo ||
                fact.changeVsYearAgo ||
                fact.percentChangeVsYearAgo ||
                fact.category ||
                fact.categoryYearAgo ||
                fact.brand ||
                fact.brandYearAgo ||
                fact.productSelections ||
                fact.productSelectionsYearAgo
        );

        handleFilterValueChange(
            {
                name,
                value: validateFactNames(updatedFactValues),
            },
            true
        );
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <DodFactTypePicker value={filterType}
                           onChange={handleFilterTypeChange}
                           showPresetWarning={Boolean(value.length)}
                           onPresetSelect={handlePresetSelect}/>
        <DodPanel expandable={true}
                  byzRef={valuePanelRef}
                  filterPlaceholder={'Search Facts'}
                  onFilterChange={setFilterText}
                  name={'dod-facts-picker'}
                  enableColumnHideShow={true}
                  factTriggerRef={factTriggerRef}
                  trackClick={{ data: { dodWizardStep: "fact", panel: "value picker" } }}
        >
            <DodFactValuePickerTable
                value={value}
                onApply={handleFilterValueChange}
                filterType={filterType}
                filterText={filterText}
                filters={filters}
                limit = {selectionLimit}
                factTriggerRef={factTriggerRef}
                />

        </DodPanel>
        <DodFactSetPreview
            value={value}
            onChange={onChange}
            name={name}
            emptyStateContent={'No Facts Selected'}
        />
    </div>
}

export default DodFactSetBuilder;
