import './MyScorecardPage.scss';

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTenantApi} from '@/hooks/useTenantApi';
import {LegacyByzzerButton, ByzzerErrorModal, ByzzerLink, ByzzerModal} from '@/components/form';
import classnames from 'classnames';
import informationIcon from '@images/icons/InformationIcon.svg';
import {marketTypes} from '@/config/globalVars';
import {useSetState} from '@/utils/utils';
import 'firebase/compat/auth';
import {useLocation} from "react-router-dom";
import DashboardContent from "@/components/dashboard/DashboardContent";
import OutletWithProps from "@/components/OutletWithProps";
import {clone, cloneDeep} from "lodash";
import {openModal} from "@/components/form/ByzzerModal";
import {TipIcon} from "@/components/icons";
import {useSuiteCommerceLink} from "@/components/SuiteCommerceSSO";
import InlineSVG from "react-inlinesvg";
import ScoreInfoImage from "@images/ScoreInfoImage.svg";
import {useUser} from "@/contexts/UserContext";
import { useEventDataWithUserInfo, useTrackEvent } from '@/analytics';

const baseClassName = classnames('my-scorecard');

export function MyScorecardPage(props) {
    const { getCategoriesByBrands, getCompanyPreferences, getUserPreferences, isScorecardEnabled } = useTenantApi();

    const {categories:contextCategories, features, subscription} = useUser();
    const categInFocusBrand = contextCategories;
    const hasScorecardAccess = features.enableScorecardAccess;
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const [loading, setLoading] = useState(false);
    const [allCategories, setAllCategories] = useState([]);
    const [categories, setCategories] = useState(contextCategories);
    const [activeKey, setActiveKey] = useState('1');
    const [gradeDetails, setDefaultGradeDetails] = useState(null);
    const [viewByKey, setViewByKey] = useState('');
    const [filters, setFilters] = useState();
    const [initialized, setInitialized] = useState(false);
    const filtersRef = useRef(filters);
    const [selectedTab, setSelectedTab] = useState("promotion");


    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filtersRef.current = filters;
    }, [filters]);

    function mergeFilters(filters) {
        setFilters(oldFilters => ({
            ...oldFilters,
            ...cloneDeep(filters)
        }))
    }

    /**
     * to fetch categories for company
     */
    async function fetchCategories() {
        try {
            setLoading(true);
            let categoryList = contextCategories;
            const prodSetArray = contextCategories.map((item) => {
                    return {
                        hierarchy: 'CATEGORY',
                        name: item,
                    };
                });
            setAllCategories(prodSetArray);
            if (props?.location?.userData) {
                const userData = props?.location?.userData;
                setGradeDetails(userData?.currentTab);
                setViewByKey(userData?.currentSelectionTab);
                let marketData = [];
                userData?.markets?.forEach((market) => {
                    marketData.push(market?.name);
                });
                setFilters({
                    markets: marketData,
                    categories: userData?.categories,
                    brands: userData?.brands
                });
                setActiveKey(userData?.key);
            } else {
                await fetchUserPref(categoryList);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    /**
     to fetch score cards
     */
    async function initialize() {
        try {
            setLoading(true);
            await fetchCategories();
            setInitialized(true);
        } finally {
            setLoading(false)
        }

    }

    /**
     * to fetch user info
     */
    const fetchUserPref = async (categoryList) => {
        const userPrefResp = await getUserPreferences();
        const brands = userPrefResp?.focusBrand ? userPrefResp?.focusBrand : [];
        if (userPrefResp) {
            // setCategories(userPrefResp?.primaryCategory?.length > 0
            //     ? userPrefResp?.primaryCategory
            //     : [JSON.parse(JSON.stringify(categoryList))])
            mergeFilters({
                brands: Array.isArray(brands) ? brands : [brands] ,
                categories:
                    userPrefResp?.primaryCategory?.length > 0
                        ? userPrefResp?.primaryCategory
                        : [],
                /* this is just a temp fix, we need to make a change in getUserPreferences api which is 
                 dependent on the preferences of users i.e there should a market selected in preferences having a
                 selType == account,  to populate the market type filter as account by default" */       
                marketTypes:'Account',
            });
        }
    };
    /**
     * triggers when brand selector changes
     *
     */

    const changeBrand = async (value, status) => {
        setLoading(true);
        let brands = clone(filtersRef.current?.brands);
        if (status) {
            brands.push(value);
        } else {
            const index = brands.indexOf(value);
            if (index !== -1) {
                brands.splice(index, 1);
            }
        }
        if (brands) {
            const categories = await getCategoriesByBrands(brands);
            let categoriesForBrand = categories.filter(name => categInFocusBrand.includes(name));
            setCategories(categoriesForBrand);
        } else {
            setCategories([])
        }
        mergeFilters({
            brands,
            categories: []
        });
        setLoading(false);
    }

    const unselectBrand = (value) => {
        changeBrand(value, false);
    }
    /**
     * triggers when category selector changes
     *
     */
    const changeCategory = (e) => {
        mergeFilters({categories: e, inBound: true});
    };

    const changeSingleMarketSearch = async (market) => {
        if (market) {
            mergeFilters({markets: [market], inBound: true, marketTypes: ''});
        }
    };

    const changeMarketSearch = async (market, addMarket, type) => {
        if (type === 'multi') {
            mergeFilters({markets: market, inBound: true, marketTypes: ''});
        } else {
            let markets = clone(filtersRef.current?.markets);
            if (addMarket) {
                markets.push(market);
            } else {
                const index = markets.indexOf(market);
                if (index !== -1) {
                    markets.splice(index, 1);
                }
            }
            mergeFilters({markets, marketTypes: ''});
        }
    };

    const onMarketTypeChange = (marketTypes) => {
        if (marketTypes) {
            mergeFilters({marketTypes, markets: []});
        } else {
            mergeFilters({marketTypes: ''});
        }
    };
    /**
     * triggers when market selector changes
     *
     */
    const changeMarket = async (market, type) => {
        changeMarketSearch(market, false, type);
    };
    const onFiltersChange = filters => {
        mergeFilters(filters);
    };
    const setGradeDetails = (details) => {
        setDefaultGradeDetails(details);
    };
    const setViewBy = (type) => {
        setViewByKey(type);
    };
    const setTabActiveKey = (key) => {
        if (key === '2' || key === '3') {
            if (activeKey === '1') {
                if (
                    (filters?.markets?.length === 1 && filters?.markets[0] === 'Total FMCG Retailers') ||
                    !filters?.markets?.length > 0
                ) {
                    mergeFilters({marketTypes: marketTypes[1], markets: []});
                }
            } else {
                setViewByKey('Markets');
                if (filters.marketTypes) {
                    mergeFilters({markets: []});
                } else if (!filters?.markets?.length > 0) {
                    mergeFilters({marketTypes: marketTypes[1], markets: []});
                }
            }
        } else if (key === '1') {
            if (filters?.markets?.length === 0) {
                mergeFilters({markets: [], marketTypes: ''});
            }
        } else {
            mergeFilters({marketTypes: ''});
        }
        // setFilters({});
        setActiveKey(key);
    };

    const showTabInfo = useCallback((index) => {

        const {text, description} = menuItems[index];
        openModal({
            closeOnClickOutside: true,
            title: text,
            content: description,
            className: `${baseClassName}__tab-modal`,
            headerType: 'normal'
        });
    }, [])

    const menuItems = [
        {
            text: 'Overall Scores',
            subText: 'Understand Overall Performance',
            description: (
                <>
                    <p>
                        This provides a high-level perspective on your brand’s performance through one simple score.
                        Your selected brand's overall score is based on performance across four function areas.
                    </p>
                    <br/>
                    <span>
                        For more information checkout{' '}
                        <ByzzerLink>
                            <a
                                href="https://learn.byzzer.ai/en/articles/6292256-navigating-your-overall-scores"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Navigating your Overall Scores
                            </a>
                        </ByzzerLink>
                    </span>
                </>
            )
        },
        {
            text: 'Function Insights',
            subText: 'Investigate Markets and Categories',
            description: (
                <>
                    <p>
                        The overall score is based equally on Pricing, Promotion, Assortment and New Product launches.
                        Each of the Function Scores are based on your brand’s performance compared to the rest of the
                        category on 4-6 metrics or actions.
                    </p>
                    <br/>
                    <span>
                        For more information checkout{' '}
                        <ByzzerLink>
                            <a
                                href="https://learn.byzzer.ai/en/articles/6292297-navigating-your-function-insights"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Navigating your Functional Insights
                            </a>
                        </ByzzerLink>
                    </span>
                </>
            ),
        },
        {
            text: 'Scorecards',
            subText: 'Identify Specific Action',
            description: (
                <>
                    <p>
                        Scorecards allow to dive deeper on the 4-6 actions associated with each function (e.g.,
                        Promotion Efficiency for the promotion function). From the Scorecards you can also render a
                        dashboard for each action, download a complete scorecard for the function or skip right to the
                        action plan with item level recommendations custom to your brand’s performance in the market.
                    </p>
                    <br/>
                    <span>
                        For more information checkout{' '}
                        <ByzzerLink>
                            <a
                                href="https://learn.byzzer.ai/en/articles/6292301-navigating-your-scorecards "
                                rel="noreferrer"
                                target="_blank"
                            >
                                Navigating your Scorecards
                            </a>
                        </ByzzerLink>
                    </span>
                </>
            ),
        },
        {
            text: 'NIQ Brand Score',
            description: (
                <>
                    <p>
                        NIQ Brand Score helps you quickly gauge your brand's performance within your category and
                        market and against the competition. By distilling several metrics into a single score, you get a
                        concise evaluation of where your brand is winning, where you need improvement, and how you stack
                        up to your competitors.
                    </p>
                    <br/>
                    <span>
                        For an overview of NIQ Brand Score take a look at the{' '}
                        <ByzzerLink>
                            <a
                                href="https://learn.byzzer.ai/scorecards-overview"
                                rel="noreferrer"
                                target="_blank"
                            >
                                NIQ Brand Scorecards Overview
                            </a>
                        </ByzzerLink>
                    </span>
                </>
            ),
        },
    ];
    const links = useMemo(
        () =>
            [
                {
                    text: 'Overall Scores',
                    subText: 'Understand Overall Performance',
                    url: 'overall_scorecard',
                },
                {
                    text: 'Function Insights',
                    subText: 'Investigate Markets and Categories',
                    url: 'identify_opportunity',
                    disabled: hasScorecardAccess !== true,
                },
                {
                    text: 'Scorecards',
                    subText: 'Identify Specific Action',
                    url: 'assess_levers',
                    disabled: hasScorecardAccess !== true,
                },
                {
                    text: 'Top Opportunities',
                    subText: 'Review top actions',
                    url: 'opportunities',                    
                    disabled: !(hasScorecardAccess === true || subscription?.metadata?.enableTopOppsBrandScore === true)
                },
            ].map(({ text, subText, url, disabled }, index) => ({
                disabled,
                url,
                text: (
                    <div className={`${baseClassName}__link`}>
                        <div className={`${baseClassName}__link-text`}>
                            {text}
                            {text !== 'Top Opportunities' && (
                                <TipIcon className={`${baseClassName}__link-icon`} onClick={() => showTabInfo(index)} />
                            )}
                        </div>
                        <div className={`${baseClassName}__link-subtext`}>{subText}</div>
                    </div>
                ),
            })),
        [hasScorecardAccess]
    );

    const setActiveTab = (tab) =>{
        setSelectedTab(tab)
    }
    
    const handleLinkClick = (url) => {        
        if (url === "opportunities") {
            // Tracking the link clicks
            trackEvent({
                type: 'click',
                name: 'brand_score_top_opps_tab_click',
                data: getEventData({ tabName: 'Top Opportunities'})
            });
        }
    };

    return (
        <DashboardContent links={links} title={'NIQ Brand Score'} className={baseClassName} onLinkClick={handleLinkClick}>
            {initialized === true && <OutletWithProps
                changeMarket={changeMarket}
                changeSingleMarketSearch={changeSingleMarketSearch}
                changeMarketSearch={changeMarketSearch}
                productSet={allCategories}
                changeBrand={changeBrand}
                unselectBrand={unselectBrand}
                changeCategory={changeCategory}
                categInFocusBrand={categInFocusBrand}
                categories={categories}
                filters={filters}
                loading={loading}
                setActiveKey={setTabActiveKey}
                onMarketTypeChange={onMarketTypeChange}
                onFiltersChange={onFiltersChange}
                activeKey={activeKey}
                gradeDetails={gradeDetails}
                viewByKey={viewByKey}
                setViewBy={setViewBy}
                hasScorecardAccess={hasScorecardAccess}
                activeTab = {selectedTab}
                setOpportunityType = {setActiveTab}
            />}

        </DashboardContent>
    );
}

// todo: move this to it's file and possibly create reusable empty state component (is this really empty state?)
export const ScorecardEmptyState = React.memo(({className}) => {
    const baseClassName = 'scorecard-empty-state';
    const sca = useSuiteCommerceLink();

    function handlePurchaseClick() {
        sca.openPathInNewTab('/analytics-package')
    }

    function handleInfoClick() {
        openModal({
            closeOnClickOutside: true,
            size: 'medium',
            type: 'info',
            headerType: 'normal',
            title: 'What does my score mean?',
            content: <div className={'my-scorecard__subclass-title-link-info'}>
                <div className={'my-scorecard__subclass-title-link-info-description'}>
                    Your score is based on your brand’s performance within the category and market. It is relative
                    to all other brands within the same category, and selling in the same market. The same scale is
                    used to score your performance at the overall level, all the way down to the individual actions.
                    Your score is on a scale from 100-600, with the highest brand in the category receiving a 600
                    and the lowest receiving a 100.
                </div>
                <div className={'my-scorecard__subclass-title-link-info-image'}>
                    <InlineSVG src={ScoreInfoImage}/>
                </div>
            </div>
        })
    }

    return (
        <div className={classnames(baseClassName, className)}>
            <div className={`${baseClassName}__cta`}>Unlock Your Brand Scorecards and Action Plans</div>
            <ByzzerLink onClick={handleInfoClick}>What does my score mean?</ByzzerLink>
            To purchase the Analytics Package, please reach out to your Byzzer sales representative
        </div>
    );
});

export default MyScorecardPage;
