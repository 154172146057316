import './DodLayoutStep.scss';
import '../DodWizardActions/DodWizardActions.scss'
import React, {ReactNode, useEffect, useState} from 'react';
import classnames from 'classnames';
import MarkdownEditor from '@uiw/react-markdown-editor';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerTipIcon,
    StepRef,
    WizardContent,
    WizardStep,
} from '@byzzer/ui-components';
import {useUser} from '@/contexts/UserContext';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {filterHasExplicitValues, filterValuesToStrings, isSelectedMarketNotAccessible} from '@/components/DodConfigEditor/common/utils';
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import { DodCancelActionButton } from '@/components/DodConfigEditor/common/DodCancelActionButton';
import {DodLayoutBuilder, DodLayoutBuilderProps} from '../../builders';
import { DATA_POINTS_LIMIT } from '@/constants/dod.constants';

export type DodLayoutStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    onCreate?(): void;
    onSchedule?(): void;
    onModifySchedule?(): void;
    onRunNameChange?: ByzzerChangeEventHandler<string>;
    busy?: boolean;
    submitDisabled?: boolean;
    hideSchedule?: boolean;
    exclude?: boolean;
    isEditScheduleMode?: boolean;
    helpIconTip: string | ReactNode;
} & DodLayoutBuilderProps;

const baseClassName = 'dod-layout-step';
const wizardActionsClassName = 'dod-wizard-actions'

export const DodLayoutStep = React.forwardRef<StepRef, DodLayoutStepProps>(
    ({
         className,
         enabled,
         title = 'Layout',
         onSchedule,
         onCreate,
         onRunNameChange,
         onModifySchedule,
         busy,
         submitDisabled,
         exclude,
         hideSchedule,
         isEditScheduleMode = false,
         helpIconTip,
         ...props
     }, ref) => {

        const {dataPointCount, calculatingDataPoints, isColumnsExceedingLimit} = useDodWizard();
        const {categories: subscribedCategories} = useUser();
        const trackEvent = useTrackEvent();
        const getEventData = useEventDataWithUserInfo();
        const [disabledTip, setDisabledTip] = useState<string>('');

        const dataPointsLimitExceeded: boolean = dataPointCount > DATA_POINTS_LIMIT;
        const helpTip = typeof helpIconTip === 'string' ? <MarkdownEditor.Markdown source={helpIconTip} /> : helpIconTip;

        function handleSchedule() {
            onSchedule?.();
            trackEvent({
                type: 'click',
                name: 'dod_schedule_later_click',
                data: getEventData({ dodWizardStep: 'layout' }),
            });
        }

        const handleRunNow = async () => {
            onCreate?.();
            trackEvent({
                type: 'click',
                name: 'dod_run_now_click',
                data: getEventData({ dodWizardStep: 'layout' }),
            });
        };

        const checkIfExtractCategoriesAreUnsubscribed = () => {
            if (!filterHasExplicitValues(props.value.filters.categories)) {
                return false;
            }
            const extractCategories = filterValuesToStrings(props.value.filters.categories);
            const difference = extractCategories.filter((x) => !subscribedCategories.includes(x));
            return difference.length > 0;
        };

        useEffect(() => {
            if (busy) {
                setDisabledTip('Creating extract');
            } else if (calculatingDataPoints) {
                setDisabledTip('Waiting for data point calculation to complete');
            } else if (dataPointsLimitExceeded) {
              setDisabledTip('Your selections exceed 1M data points. Please reduce your selections to run the report.');
            } else if (submitDisabled) {
                setDisabledTip('Your run does not contain any data');
            } else if (checkIfExtractCategoriesAreUnsubscribed()) {
                setDisabledTip('Selected categories are no longer in the subscription.');
            }else if(isSelectedMarketNotAccessible(props.value)){
                setDisabledTip('The selected market(s) are no longer accessible for the current rerun. Please select new market(s).');
            }else if(isColumnsExceedingLimit) {
                setDisabledTip("Your selections have exceeded the maximum number of columns in a standard Excel file which means we won't be able to process your run without cutting off some of the data. Please reduce your selections or move your most granular field(s) into the rows.");
            } else {
                setDisabledTip('');
            }
        }, [busy, submitDisabled, calculatingDataPoints, isColumnsExceedingLimit])

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                ref={ref}
                id={'layout'}
                exclude={exclude}
                enabled={enabled}
                title={title}
            >
                <WizardContent>
                    <DodLayoutBuilder {...props} />
                </WizardContent>
                <footer className={`${baseClassName}__actions`}>
                    <div className={classnames(baseClassName, wizardActionsClassName)}>
                        <ByzzerTipIcon
                            type={'help'}
                            interactive={true}
                            preventDefaultClickEvent={true}
                            tipTrigger={'click'}
                            tipLocation={'top-start' as any}
                            tip={<div className={`${wizardActionsClassName}__help-tip`}>{helpTip}</div>}
                        />
                    </div>
                    <div className={`${baseClassName}__schedule-btn-container`}>
                        <DodCancelActionButton />
                        {isEditScheduleMode ? (
                            <ByzzerButton
                                className={`${baseClassName}__schedule-btn`}
                                disabled={
                                    busy ||
                                    submitDisabled ||
                                    calculatingDataPoints ||
                                    checkIfExtractCategoriesAreUnsubscribed()
                                }
                                disabledTip={disabledTip}
                                onClick={onModifySchedule}
                            >
                                Apply
                            </ByzzerButton>
                        ) : (
                            <>
                                <ByzzerButton
                                    className={`${baseClassName}__schedule-btn`}
                                    disabled={
                                        busy ||
                                        submitDisabled ||
                                        calculatingDataPoints ||
                                        checkIfExtractCategoriesAreUnsubscribed() ||
                                        isSelectedMarketNotAccessible(props.value) ||
                                        isColumnsExceedingLimit
                                    }
                                    disabledTip={disabledTip}
                                    onClick={handleRunNow}
                                >
                                    Run Now
                                </ByzzerButton>
                                {!hideSchedule && (
                                    <ByzzerButton
                                        className={`${baseClassName}__schedule-btn`}
                                        disabled={
                                            busy ||
                                            submitDisabled ||
                                            calculatingDataPoints ||
                                            dataPointsLimitExceeded ||
                                            checkIfExtractCategoriesAreUnsubscribed() ||
                                            isSelectedMarketNotAccessible(props.value) ||
                                            isColumnsExceedingLimit
                                        }
                                        disabledTip={disabledTip}
                                        onClick={handleSchedule}
                                    >
                                        Schedule For Later
                                    </ByzzerButton>
                                )}
                            </>
                        )}
                    </div>
                </footer>
            </WizardStep>
        );
    }
);
