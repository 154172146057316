import './HomePageHeader.scss';
import React, { useContext } from 'react';
import classnames from 'classnames';
import { ByzzerLink } from '@/components/form';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import { HomepageContext } from '@/contexts/HomepageContext';
import { Spinner } from '@/components/Spinner/Spinner';
import { useMyHomePageContext, MyHomepageContext } from '@/contexts/MyHomePageContext';
import { isEmpty } from 'lodash';

const baseClassName = 'home-page-header';

export function HomePageHeader({
    className,
    tileUpdate = false,
    onFilterPanelClose,
    onFilterPanelOpen,
    enableFilterPanel,
}) {
    const { refreshReason, isContextLoading } = useContext(MyHomepageContext);
    const { tiles, reportletData } = useMyHomePageContext() ?? {};

    const { user, categories, company, preSales } = useUser();

    const navigate = useNavigate();
    const goToSubscription = () => {
        navigate('/dashboard/my_subscription/subscription');
    };
    const goToProfile = () => {
        navigate('/dashboard/user_profile/preferences');
    };
    const categoryBool = !categories || categories?.length === 0;
    const tileBool = !tiles || tiles?.length === 0;
    let mainCardClass = '_hide';
    let titleCard = '';
    let titleContent = '';
    const reportletMessage = reportletData?.parameterValues?.message;
    const reportletCategories = reportletData?.parameterValues?.primaryCategory;
    // Added for free user experience, BYZ-10746 Preferences are not editable for free Group B users
    const isPreferenceEditable = preSales && company?.features?.freeAccountGroup === 'B' ? false : true;


    if (categoryBool && !tileUpdate && !isContextLoading && isPreferenceEditable) {
        mainCardClass = '_default';
        titleCard = 'Account configuration is not complete.';
        titleContent =
            'You still need to choose your subscription categories before you can use Byzzer. You or an account admin can do this in the';
    } else if (!categoryBool && tileBool && !isContextLoading && isPreferenceEditable) {
        mainCardClass = '_default';
        titleCard = 'Your default selections have no data.';
        titleContent = "You may have selected a market or category that doesn't carry your brand. Go to your";
    } else if (refreshReason === 'defaultSync') {
        mainCardClass = `_default`;
        titleCard = 'Your preference has been updated.';
        titleContent = 'Your dashboard is being refreshed with the latest data.';
    } else if (refreshReason === 'preferenceChange') {
        mainCardClass = '_default';
        titleCard = 'Your preference have changed.';
        titleContent = 'Updating data with your new preferences.';
    } else if (refreshReason === 'subscriptionChange') {
        mainCardClass = '_default';
        titleCard = 'Your subscription categories have changed.';
        titleContent = "We're updating your Homepage with your new categories.";
    } else if (refreshReason === 'dataRefresh') {
        mainCardClass = '_default';
        titleCard = 'The Byzzer data has refreshed.';
        titleContent = 'We’re updating your Homepage with the latest data.';
    } else if(!isEmpty(reportletMessage) && isEmpty(reportletCategories) ){
        mainCardClass = `_default`;
        titleCard = 'Your preference has been updated.';
        titleContent = reportletMessage.value;
    }
    
    return (
        <div className={classnames(baseClassName, className)} key={categories?.length}>
            <div className={`${baseClassName}__title_card`}>
                <span className={`${baseClassName}__title`}>Welcome{`, ${user?.firstName}`}!</span>
                { !preSales && <div className="filter_panel__dropdown__link">
                    {!isContextLoading && (
                        <>
                            <div>{enableFilterPanel ? 'Collapse when done' : 'Expand to apply new data '}</div>
                            <div
                                className={enableFilterPanel ? 'filter-options-collapse' : 'filter-options-expand'}
                                onClick={enableFilterPanel ? onFilterPanelClose : onFilterPanelOpen}
                            />
                        </>
                    )}
                    {isContextLoading && (
                        <div className={`${baseClassName}__title_card--spinner`}>
                            <Spinner />
                        </div>
                    )}
                </div>}
            </div>
            <div className={`${baseClassName}__title_card${mainCardClass}`} key="tile-card">
                <h2 className={`${baseClassName}__title_default`}>{titleCard}</h2>
                <div className={`${baseClassName}__title_subtext`}>                    
                    {`${titleContent} \u00A0` }
                    {categoryBool && !tileUpdate && !isContextLoading && (
                        <>
                            {/* @ts-ignore */}
                            <ByzzerLink label={'Subscription'} onClick={goToSubscription} />
                            {"\u00A0 page."}
                            
                        </>
                    )}
                    {!categoryBool && tileBool && !isContextLoading && (
                        <>
                            <ByzzerLink
                                label={'Profile'}
                                onClick={goToProfile}
                                children={undefined}
                                trackClick={undefined}
                                className={undefined}
                                href={undefined}
                                target={undefined}
                            />
                            {"\u00A0 to change your selections.\u00A0"}
                        </>
                        
                    )}
                    
                    
                </div>
            </div>
        </div>
    );
}
