export const ReportValidationResultMessages: Record<string, string> = {
    unauthorized_user_role: "Your access level is view only. Reach out to your account's admin if you wish to upgrade your member type in order to rerun reports.",
    subscription_not_found: "This report is no longer a part of your subscription. Reach out to your account executive if you wish to upgrade your subscription.",
    category_subscription_not_found: "This report cannot be run due to the following reason: category(s) no longer in subscription. Please select a new category to rerun this report.",
    category_subscription_not_found_multi: "This report cannot be run due to the following reason: category(s) removed and market access validations need rerun. Please review your selected categories and reselect your market(s) before rerunning this report.",
    not_prior_approval: "This report cannot be run due to the following reason: market(s) require prior approval. Please select a new market to rerun this report or reach out to your account executive to obtain approval.",
    not_rev_share: "This report cannot be run due to the following reason: premium market purchase required. Please select a new market to rerun this report or reach out to your account executive to purchase the market.",
    market_not_accessible: "This report cannot be run due to the following reason: market(s) no longer in subscription. Please select a new market to rerun this report.",
    market_not_found: "This report cannot be run due to the following reason: market(s) no longer exists. Please select a new market to rerun this report.",
    attribute_group_not_found: "This report cannot be run due to the following reason: Attribute Group(s) no longer in subscription. Please select a new Attribute Group to rerun this report.",
    market_not_found_multi: "Your market access has changed since you last ran this report. Please navigate to the Markets step to verify your selections.",
    custom_char_not_found: "This report cannot be run due to the following reason: Characteristic filter selected no longer exists. Please review your selections before rerunning this report.",
    default: "You cannot run this report because you have not selected all of the required values."
};
