import {ReactNode, createContext, useContext, useEffect} from "react";
import {DodRunConfig} from "@/types/DodRun";
import {DodPresetSummary, PresetMap} from "@/types/ApiTypes";
import {RowColConfig} from "@/components/DodConfigEditor/types";


export const MAX_DATA_POINTS = 1_000_000;

export type DodWizardContextValue = {
    runConfig: DodRunConfig;
    setRunConfig: React.Dispatch<React.SetStateAction<DodRunConfig>>;
    runType?: RunType;
    setPreset(type: keyof PresetMap, value: DodPresetSummary | undefined);
    deletePreset(type: keyof PresetMap);
    presets: PresetMap;
    rowColConfigs: RowColConfig[];
    setRowColConfigs: React.Dispatch<React.SetStateAction<RowColConfig[]>>;
    updateRowColConfigs(): void;
    quickLayoutCode?: string;
    templateId?: number;
    dataPointCount: number;
    setDataPointCount(count: number): void;
    calculatingDataPoints?: boolean;
    setCalculatingDataPoints(calculating: boolean): void;
    applyValues(values: ((prevState: DodWizardContextValue) => DodWizardContextValue) | Partial<DodWizardContextValue>): void;
    suppressQuickLayoutWarning: boolean;
    setSuppressQuickLayoutWarning(suppress: boolean): void;
    timePeriodsReordered: boolean;
    setTimePeriodsReordered(reordered: boolean): void;
    seriesNames: {
        [key: string]: Set<string>
    };
    individualRunSeriesNames: string[];
    setSeriesNames(seriesNames: { [key: string]: Set<string> }): void;
    allExceptSelectionFields: string[];
    addAllExceptSelectionField(field: string | string[] ): void;
    removeAllExceptSelectionField(field: string | string[]): void;
    isAllExceptSelectionField(field: string): boolean;
    isColumnsExceedingLimit?: boolean;
    setIsColumnsExceedingLimit(exceeded: boolean): void;
    helpIconInfo: { [key: string]: string | ReactNode };
}

export const DodWizardContext = createContext<DodWizardContextValue>(null as any);

export const useDodWizard = () => useContext(DodWizardContext);

export function useAutoUpdateRowColConfigs() {
    const {updateRowColConfigs} = useDodWizard();
    useEffect(() => {
        return () => updateRowColConfigs()
    }, []);
}
DodWizardContext.displayName = 'DodWizardContext'
