import './DodLayoutOptions.scss';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerCheckableChangeEvent } from '@byzzer/ui-components';
import { ByzzerCheckbox } from '@byzzer/ui-components';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {DodLayoutConfig} from '@/types/DodRun';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { intersection } from 'lodash';
import { useMemo } from 'react';
import { isWorkInProgressFact } from '@/components/DodConfigEditor/common/utils';

const baseClassName = 'dod-layout-options';

export type DodLayoutOptionsProps = {
    className?: string;
    name?: string;
    value: DodLayoutConfig;
    onChange: ByzzerChangeEventHandler<DodLayoutConfig>;
    mode: string | null;
};

export function DodLayoutOptions({ className, name, value, mode,  onChange, ...props }: DodLayoutOptionsProps) {
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const isLayoutMode = mode === 'layout';
    const categoryTotalDisabledTip = 'You cannot include Category Totals when you hide Category from your run.';
    const { runConfig } = useDodWizard();
    const disabledByInProgressFacts = useMemo(() => {
        return isWorkInProgressFact(runConfig.facts);
    }, [runConfig.facts]);

    function handleValueChange(e: ByzzerCheckableChangeEvent<string>) {
        onChange({
            name,
            value: {
                ...value,
                [e.name!]: e.checked
            }
        });

        // layout options change tracking
        trackEvent({
            type: 'click',
            name: 'dod_layout_options_change',
            data: getEventData({ dodWizardStep: 'layout', option: e.name }),
        });
    }

    function isCategoryHidden(): boolean {
        // disable categoryTotals if category is hidden in layout
        return [...value.columns, ...value.rows].some((axisDef) => axisDef.dim === 'categories' && axisDef.hide);
    }

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <span className={`${baseClassName}__title`}>Layout Options:</span>
            <div className={`${baseClassName}__options`}>
                <ByzzerCheckbox
                    name={'includeSubTotals'}
                    onChange={handleValueChange}
                    checked={value.includeSubTotals}
                    label={'Include Subtotals'}
                    disabled={isLayoutMode || disabledByInProgressFacts.disabled}
                    disabledTip={disabledByInProgressFacts.disabled ? disabledByInProgressFacts.hoverText : ''}
                />
                <ByzzerCheckbox
                    name={'includeCategoryTotals'}
                    onChange={handleValueChange}
                    checked={value.includeCategoryTotals}
                    label={'Include Category Totals'}
                    disabled={isLayoutMode || isCategoryHidden() || disabledByInProgressFacts.disabled}
                    disabledTip={
                        disabledByInProgressFacts.disabled
                            ? disabledByInProgressFacts.hoverText
                            : Boolean(isCategoryHidden())
                            ? categoryTotalDisabledTip
                            : ''
                    }
                />
                <ByzzerCheckbox
                    name={'includeExtractDate'}
                    onChange={handleValueChange}
                    checked={value.includeExtractDate}
                    label={'Extract Date'}
                    disabled={isLayoutMode}
                />
            </div>
        </div>
    );
}

export default DodLayoutOptions;
