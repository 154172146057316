import { LegacyByzzerButton, ByzzerInput } from "@/components/form";
import React, { useState } from "react";
import DeleteIcon from '@/images/icons/DeleteIcon.svg';
import EditIcon from '@/images/icons/new-edit-icon.svg';
import { create as createModal } from "react-modal-promise";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import { TrackClick } from '@/analytics';

export function ManageTeamEditor({ teamOptions, onResolve, ...props }) {
    const [loading, setLoading] = useState(false);

    function handleEditTeam(team) {
        onResolve({ function: "edit", team: team });
    }

    async function handleDeleteTeam(team) {
        onResolve({ function: "delete", team: team });
    }

    async function handleAddTeam() {
        onResolve({ function: "add" });
    }

    function onCloseClick() {
        onResolve({ function: "close" });
    }

    return (
        <ByzzerModal2>
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>Manage Teams</ByzzerModal2.Header>
            <ByzzerModal2.Content>
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <div className="subscription-users__modal-display">
                    <div className="subscription-users__modal-display-content">
                        {teamOptions.length > 0 &&
                            teamOptions.map((item, index) => {
                                return (
                                    <div key={index} className="subscription-users__modal-display-item">
                                        <div>
                                            <ByzzerInput
                                                className={"subscription-users__modal-display-item-input"}
                                                placeholder={item.display}
                                                value={item.display}
                                                type={'text'}
                                                name={item.display}
                                                readOnly
                                                variant={'secondary'}
                                            />
                                            <img className="subscription-users__modal-add-item-icon"
                                                onClick={() => { handleEditTeam(item) }}
                                                src={EditIcon} alt={"edit"} />
                                            <img className="subscription-users__modal-add-item-icon"
                                                onClick={() => handleDeleteTeam(item)}
                                                src={DeleteIcon} alt={"delete"} />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className="subscription-users__modal-add-section">
                        <div className="subscription-users__modal-add-team"
                            onClick={handleAddTeam}>
                            +Add a Team
                        </div>
                    </div>
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className="subscription-users__modal-footer">
                <TrackClick name={'Sub Members MngTeamDone clicked'}>
                    <LegacyByzzerButton onClick={onCloseClick} label="Done" />
                </TrackClick>
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}

export const openManageTeamEditor = createModal(ManageTeamEditor);