import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { openModal } from '@/components/form';
import { ReportRunConfig } from '@/types/ReportRun';
import { ByzzerTextInput, ByzzerTipIcon } from '@byzzer/ui-components';
import './ScheduleModal.scss';
import { CreateReportSchedulePayload } from '@/types/ApiTypes';

const baseClassName = 'schedule-report-run-now-modal';

function isDuplicateSeries({ seriesName, seriesNames }: { seriesName?: string; seriesNames: string[] }): boolean {
    // Normalize series names by trimming and converting to lowercase, then convert to Set
    const normalizedSeriesNames = new Set(
        seriesNames.map(name => name.trim().toLowerCase())
    );

    // Normalize the input series name by trimming and converting to lowercase
    const normalizedSeriesName = (seriesName || '').trim().toLowerCase();

    return normalizedSeriesNames.has(normalizedSeriesName);
}

interface CreateReportScheduleModalParams {
    runConfig: ReportRunConfig;
    schedule: Schedule;
    seriesName: string;
    seriesNames: string[];
    sku: string;
    createScheduleReport: Function;
}

interface ModifySeriesNameModalParams {
    seriesId: number;
    seriesName: string;
    seriesNames: string[];
    updateScheduledReportSeriesName: Function;
}

interface ModifyReportReScheduleModalParams {
    scheduleId: number;
    scheduleInfo: Schedule;
    rescheduleReport: Function;
}

interface ModifyReportScheduleConfigModalParams {
    seriesId: number;
    runConfig: ReportRunConfig;
    updateScheduledReportConfig: Function;
}

export async function openCreateReportScheduleModal({
    runConfig,
    sku,
    seriesNames,
    schedule,
    createScheduleReport,
    seriesName
}: CreateReportScheduleModalParams) {
    return openModal<Omit<CreateReportScheduleModalParams, 'seriesNames' | 'createScheduleReport'>>({
        className: baseClassName,
        title: 'Create Report Schedule',
        initialState: {
            seriesName,
            runConfig,
            schedule,
            sku,
        },
        showCloseOption: false,
        content: ({ busy, state, setState }) => {
            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState((state) => ({
                    ...state,
                    seriesName: e.value,
                }));
            }

            return (
                <>
                    <ByzzerMask loading={busy}>Creating Your Schedule</ByzzerMask>
                    <ByzzerTextInput
                        className={`${baseClassName}__name`}
                        name={'name'}
                        placeholder={'Enter a title'}
                        label={
                            <>
                                Name your Report series{' '}
                                <ByzzerTipIcon>
                                    The series name will allow you to quickly find all runs in the same series or setup.
                                    Each run will have the week ending date added to differentiate each run in the
                                    series.
                                </ByzzerTipIcon>
                            </>
                        }
                        value={state.seriesName}
                        onChange={handleDisplayNameChange}
                    />
                </>
            );
        },
        actions: [
            {
                key: 'cancel',
                type: 'negative',
                label: 'Cancel',
                disableIf({ busy }) {
                    return busy;
                },
                action({ resolve }) {
                    resolve();
                },
            },
            {
                key: 'create',
                label: 'Create',
                getDisableTip({ state: { seriesName } }) {
                    return isDuplicateSeries({ seriesName, seriesNames })
                        ? 'You already have a series with this name. Please choose a different series name.'
                        : '';
                },
                disableIf({ busy, state: { seriesName } }) {
                    return isDuplicateSeries({ seriesName, seriesNames }) || !seriesName || busy;
                },
                async action({ resolve, reject, setBusy, state }) {
                    try {
                        if (!isDuplicateSeries({ seriesName: state.seriesName, seriesNames })) {
                            setBusy(true);

                            const payload: CreateReportSchedulePayload = {
                                seriesName: state.seriesName,
                                runConfig: state.runConfig,
                                sku: state.sku,
                                scheduleInfo: {
                                    frequency: state.schedule.frequency,
                                    deliveryDay: state.schedule.deliveryDay,
                                    deliveryGroupType: state.schedule.deliveryGroupType,
                                    deliverToUserIds: state.schedule.deliverToUserIds,
                                    startingTimePeriod: state.schedule.startingTimePeriod,
                                    timePeriods: state.schedule.timePeriods,
                                    deliveryCount: state.schedule.duration,
                                    scheduleDates: state.schedule.scheduleDates?.map((dt) => ({
                                        reportWeekEnding: dt.endDate,
                                        reportDeliverAt: dt.deliveryDate,
                                    })),
                                },
                            };
                            
                            resolve(await createScheduleReport(payload));
                        } else {
                            reject('You already have a series with this name. Please choose a different series name.');
                        }
                    } catch (err) {
                        reject(err);
                    } finally {
                        setBusy(false);
                    }
                },
            },
        ],
    });
}

export async function openRenameReportSeriesName({
    seriesId,
    seriesName,
    seriesNames,
    updateScheduledReportSeriesName,
}: ModifySeriesNameModalParams) {
    return openModal({
        className: baseClassName,
        title: (
            <div className={`${baseClassName}__templatize`}>
                Rename across the entire series{' '}
                <ByzzerTipIcon>
                    You are editing the name of a scheduled run. Because scheduled runs are a concatenation of the
                    series name and the week ending date, you will be updating the run names across the entire scheduled
                    series.
                </ByzzerTipIcon>
            </div>
        ),
        initialState: {
            seriesId,
            seriesName: seriesName,
            oldSeriesName: seriesName,
        },
        showCloseOption: false,
        content: ({ busy, state, setState }) => {
            function handleDisplayNameChange(e: ByzzerChangeEvent<string>) {
                setState((state) => ({
                    ...state,
                    seriesName: e.value,
                }));
            }

            return (
                <>
                    <ByzzerMask loading={busy}>Renaming your report</ByzzerMask>
                    <ByzzerTextInput
                        className={`${baseClassName}__name`}
                        name={'name'}
                        placeholder={'Enter a title'}
                        value={state.seriesName}
                        onChange={handleDisplayNameChange}
                    />
                </>
            );
        },
        actions: [
            {
                key: 'cancel',
                type: 'negative',
                label: 'Cancel',
                disableIf({ busy }) {
                    return busy;
                },
                action({ resolve }) {
                    resolve();
                },
            },
            {
                key: 'update',
                label: 'Update',
                getDisableTip({ state: { seriesName } }) {
                    if (!seriesName) {
                        return 'Series Name is required';
                    }
                    return isDuplicateSeries({ seriesName, seriesNames })
                        ? 'You already have a series with this name. Please choose a different series name.'
                        : '';
                },
                disableIf({ busy, state: { seriesName } }) {                    
                    return isDuplicateSeries({ seriesName, seriesNames }) || !seriesName || busy;
                },
                async action({ resolve, reject, setBusy, state }) {
                    try {
                        if (state.seriesName) {
                            setBusy(true);
                            const response = await updateScheduledReportSeriesName(state.seriesId, state.seriesName);
                            if (response) {
                                resolve(state.seriesName);
                            } else {
                                reject('unable to update');
                            }
                        } else {
                            reject('Series Name is required.');
                        }
                    } catch (err) {
                        reject(err);
                    } finally {
                        setBusy(false);
                    }
                },
            },
        ],
    });
}

export async function openModifyReportRescheduleModal({
    scheduleId,
    scheduleInfo,
    rescheduleReport,
}: ModifyReportReScheduleModalParams) {
    return openModal<Omit<ModifyReportReScheduleModalParams, 'rescheduleReport'>>({
        className: baseClassName,
        initialState: { scheduleId, scheduleInfo },
        showCloseOption: false,
        content: ({ busy }) => (
            <>
                <ByzzerMask loading={busy}>Updating Your Schedule</ByzzerMask>
                <p>Are you sure you want to modify all scheduled runs for this series?</p>
            </>
        ),
        actions: [
            {
                key: 'no',
                type: 'negative',
                label: 'No',
                disableIf({ busy }) {
                    return busy;
                },
                action({ resolve }) {
                    resolve();
                },
            },
            {
                key: 'yes',
                label: 'Yes',
                disableIf({ busy }) {
                    return busy;
                },
                async action({ resolve, reject, setBusy, state }) {
                    try {
                        setBusy(true);

                        const scheduleInfo = {
                            frequency: state.scheduleInfo.frequency,
                            deliveryDay: state.scheduleInfo.deliveryDay,
                            deliveryGroupType: state.scheduleInfo.deliveryGroupType,
                            deliverToUserIds: state.scheduleInfo.deliverToUserIds,
                            startingTimePeriod: state.scheduleInfo.startingTimePeriod,
                            timePeriods: state.scheduleInfo.timePeriods,
                            deliveryCount: state.scheduleInfo.duration,
                            scheduleDates: state.scheduleInfo.scheduleDates?.map((dt) => ({
                                reportWeekEnding: dt.endDate,
                                reportDeliverAt: dt.deliveryDate,
                            })),
                        };

                        resolve(await rescheduleReport(state.scheduleId, scheduleInfo));
                    } catch (err) {
                        reject(err);
                    } finally {
                        setBusy(false);
                    }
                },
            },
        ],
    });
}

export async function openModifyReportScheduleConfigModal({
    seriesId,
    runConfig,
    updateScheduledReportConfig,
}: ModifyReportScheduleConfigModalParams) {
    return openModal<Omit<ModifyReportScheduleConfigModalParams, 'updateScheduledReportConfig'>>({
        className: baseClassName,
        initialState: { seriesId, runConfig },
        showCloseOption: false,
        content: ({ busy }) => (
            <>
                <ByzzerMask loading={busy}>Updating Your Schedule</ByzzerMask>
                <p>Are you sure you want to modify all scheduled runs for this series?</p>
            </>
        ),
        actions: [
            {
                key: 'no',
                type: 'negative',
                label: 'No',
                disableIf({ busy }) {
                    return busy;
                },
                action({ resolve }) {
                    resolve();
                },
            },
            {
                key: 'yes',
                label: 'Yes',
                disableIf({ busy }) {
                    return busy;
                },
                async action({ resolve, reject, setBusy, state }) {
                    try {
                        setBusy(true);
                        resolve(await updateScheduledReportConfig(state.seriesId, state.runConfig));
                    } catch (err) {
                        reject(err);
                    } finally {
                        setBusy(false);
                    }
                },
            },
        ],
    });
}
