import './DodScheduleStep.scss';
import React, {ReactNode, useState} from 'react';
import classnames from 'classnames';
import hashObject from 'object-hash';
import MarkdownEditor from '@uiw/react-markdown-editor';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerTipIcon,
    StepRef,
    WizardContent,
    WizardStep
} from '@byzzer/ui-components';
import {DodSchedule} from '@/types/DodRun';
import {useDodWizard} from "@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext";
import DodScheduleRunBuilder from '../../builders/DodScheduleRunBuilder/DodScheduleRunBuilder';
import {DodCancelActionButton} from '../../common/DodCancelActionButton';

export type DodScheduleStepProps = {
    title?: ReactNode;
    className?: string;
    value: DodSchedule;
    submitDisabled?: boolean;
    onChange: ByzzerChangeEventHandler<DodSchedule>;
    onCreate(): void;
    onModifySchedule?(): void;
    exclude?: boolean;
    enable?: boolean;
    isEditScheduleMode?: boolean;
    initialSchedule?: DodSchedule;
    helpIconTip?: ReactNode;
};

const baseClassName = 'dod-run-config-filters-step';
export const DodScheduleStep = React.forwardRef<StepRef, DodScheduleStepProps>(
    ({
         className,
         title = 'Schedule',
         value,
         initialSchedule,
         onCreate,
         onChange,
         onModifySchedule,
         exclude,
         submitDisabled,
         enable,
         isEditScheduleMode,
         helpIconTip
     }, ref) => {
        const {calculatingDataPoints} = useDodWizard();
        const helpTip = typeof helpIconTip === 'string' ? <MarkdownEditor.Markdown source={helpIconTip} /> : helpIconTip;

        function handleBeforeNext(): boolean {
            onCreate();
            return false;
        }

        function handleScheduleChange(e: ByzzerChangeEvent<DodSchedule>): void {
            onChange(e);
        }

        function hasFormValuesChanged() {
            if (isEditScheduleMode) {
                const initialValueHash = hashObject(
                    initialSchedule!,
                    {
                        excludeKeys(key: string) {
                            switch (key) {
                                case 'timePeriods':
                                    return initialSchedule?.frequency !== 'custom';
                                case 'startingTimePeriod':
                                    return initialSchedule?.frequency === 'custom';
                                default:
                                    return false;
                            }
                        },
                    }
                );
                const valueHash = hashObject(
                    value,
                    {
                        excludeKeys(key: string) {
                            switch (key) {
                                case 'timePeriods':
                                    return value.frequency !== 'custom';
                                case 'startingTimePeriod':
                                    return value.frequency === 'custom';
                                default:
                                    return false;
                            }
                        },
                    }
                );
                if (initialValueHash === valueHash) return true;
            }
            return false;
        }

        function isNextDisabled(): boolean {
            return Boolean(
                submitDisabled ||
                !value.frequency ||
                (!value.timePeriods?.length && !value.startingTimePeriod)||
                !value.deliveryDay ||
                !value.deliveryGroupType ||
                !value.deliverToUserIds.length ||
                (value.frequency !== 'one_time' && value.frequency !== 'custom' && !value.duration)
            );
        }

        function getDisabledTip() {
            let tip = 'Please select all required fields to continue.';
            if (calculatingDataPoints) {
                tip = 'Waiting For Data Point Calculation To Complete';
            } else if (submitDisabled) {
                tip = 'Your Run Does Not Contain Any Data';
            } else if (
                isNextDisabled() &&
                !value.deliverToUserIds.length &&
                ['team', 'everyone'].includes(value.deliveryGroupType)
            ) {
                tip = 'There are no active members in company subscription to notify about completed runs.';
            } else if (hasFormValuesChanged()) {
                tip = 'Please make any change in existing schedule to continue.';
            }
            return tip;
        }

        return (
            <WizardStep
                id={'scheduler'}
                className={classnames(baseClassName, className)}
                ref={ref}
                exclude={exclude}
                title={title}
                header={<>Test</>}
                enabled={enable}
            >
                <WizardContent>
                    <DodScheduleRunBuilder
                        value={value}
                        onChange={handleScheduleChange}
                    />
                </WizardContent>
                <footer className={`${baseClassName}__actions`}>
                    <span>
                        <ByzzerTipIcon type={'help'} interactive={true} preventDefaultClickEvent={true} tipTrigger={"click"} tipLocation={'top-start' as any} tip={
                            <div className={`${baseClassName}__help-tip`}>{helpTip}</div>
                        }/>
                    </span>
                    <span className={`${baseClassName}__actions_button`}>
                        {!isEditScheduleMode &&<DodCancelActionButton/> }
                        <ByzzerButton
                            className={`${baseClassName}__schedule-btn`}
                            disabled={calculatingDataPoints || hasFormValuesChanged() || isNextDisabled()}
                            disabledTip={getDisabledTip()}
                            onClick={isEditScheduleMode ? onModifySchedule : handleBeforeNext}
                        >
                            {isEditScheduleMode ? 'Apply' : 'Create Schedule'}
                        </ByzzerButton>
                    </span>

                </footer>
            </WizardStep>
        );
    }
);
