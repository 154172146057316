import { LegacyByzzerButton, ByzzerInput } from "@/components/form";
import React, { useEffect, useState } from "react";
import { create as createModal } from "react-modal-promise";
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import DeleteIcon from '@/images/icons/DeleteIcon.svg';

const baseClassName = '';
export function AddTeamEditor({ teamOptions, onResolve, ...props }) {

    const [loading, setLoading] = useState(false);
    const [newTeamCount, setNewTeamCount] = useState(1);
    const [newTeam, setNewTeam] = useState([]);
    const [errors, setErrors] = useState({});


    function addNewTeam(e, index) {
        newTeam[index] = e.target.value;
        setNewTeam([...newTeam])
    }

    const handleNameValidation = () => {
        newTeam.map((name, idx) => {
            const nameExists = teamOptions.find(team => {
                if (name) {
                    return team.display.trim().toLowerCase() === name.trim().toLowerCase()
                }
            }
            );
            setErrors(oldState => ({
                ...oldState,
                [idx]: Boolean(nameExists)
            }))
        })
    }

    function deleteTeam(item) {
        setErrors(errs => {
            const copy = { ...errs };
            delete copy[item - 1];
            return copy;
        })
        if (item === newTeam.length) {
            newTeam.pop();
        }
        setNewTeam([...newTeam])
    }

    function sumbitNewTeam() {
        let uniqueNewTeam = [...new Set(newTeam)];
        if (!Object.values(errors).includes(true)) {
            const newTeams = uniqueNewTeam.filter(ar => !teamOptions.find(rm => (rm.display === ar)))
            onResolve({ function: "add", newTeams: newTeams });
        }
    }

    function onCloseClick() {
        onResolve({ function: "close" });
    }

    function disableAddTeam(count) {
        if (count === 0) {
            return !newTeam[0]
        }
        else if (count === 1) {
            return !newTeam[1] || !newTeam[0]
        }
        else {
            return !newTeam[2] || !newTeam[1] || !newTeam[0]
        }
    }

    return (
        <ByzzerModal2 className="subscription-users__modal-editor">
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>Add Team</ByzzerModal2.Header>
            <ByzzerModal2.Content>
                <ByzzerModal2.Mask show={loading}>{loading.message}</ByzzerModal2.Mask>
                <div className="subscription-users__modal-display">
                    <div className="subscription-users__modal-display-item">
                        <ByzzerInput
                            className="subscription-users__modal-edit-team-input"
                            value={newTeam[0]}
                            type={'text'}
                            placeholder={'New Team Name'}
                            onChange={(e) => addNewTeam(e, 0)}
                            key={0}
                            required
                            onBlur={handleNameValidation}
                        />
                        {
                            errors && errors[0] && (
                                <span className={'byzzer-input__error'}>Team name already exists</span>
                            )
                        }
                    </div>
                    {newTeamCount > 1 && <div className="subscription-users__modal-display-item">
                        <div>
                            <ByzzerInput
                                className="subscription-users__modal-edit-team-input"
                                value={newTeam[1]}
                                type={'text'}
                                placeholder={'New Team Name'}
                                onChange={(e) => addNewTeam(e, 1)}
                                key={1}
                                required
                                onBlur={handleNameValidation}
                            />
                            {newTeamCount === 2 && <img
                                className="subscription-users__modal-edit-team-icon"
                                onClick={() => { setNewTeamCount(1); deleteTeam(2) }}
                                src={DeleteIcon} alt={"delete"} />}
                        </div>
                        {
                            errors && errors[1] && (
                                <span className={'byzzer-input__error'}>Team name already exists</span>
                            )
                        }
                    </div>}
                    {newTeamCount > 2 && <div className="subscription-users__modal-display-item">
                        <div>
                            <ByzzerInput
                                className="subscription-users__modal-edit-team-input"
                                value={newTeam[2]}
                                type={'text'}
                                placeholder={'New Team Name'}
                                onChange={(e) => addNewTeam(e, 2)}
                                key={2}
                                required
                                onBlur={handleNameValidation}
                            />
                            <img
                                className="subscription-users__modal-edit-team-icon"
                                onClick={() => { setNewTeamCount(2); deleteTeam(3) }}
                                src={DeleteIcon} alt={"delete"} />
                        </div>
                        {
                            errors && errors[2] && (
                                <span className={'byzzer-input__error'}>Team name already exists</span>
                            )
                        }
                    </div>}
                    {newTeamCount < 3 && <div className="subscription-users__modal-add-section">
                        <div className="subscription-users__modal-add-team" onClick={() => {
                            newTeamCount === 1 ? setNewTeamCount(2) : setNewTeamCount(3)
                        }}>+Add a Team
                        </div>
                    </div>}
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className="subscription-users__modal-footer">
                <LegacyByzzerButton disabled={disableAddTeam(newTeamCount - 1) || Object.values(errors).includes(true)} onClick={sumbitNewTeam} label="Done" />
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}

export const openAddTeamEditor = createModal(AddTeamEditor);
