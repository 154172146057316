import { ReactNode } from 'react';
import { DodResourceLink } from '@/components/DodConfigEditor/common/DodResourceLink';
import { ByzzerLink } from '@/components/form';
import React from 'react';

const productsHelpIcon: ReactNode = (
    <>
        <p>Select which products to include in your run.</p>
        <p>
            Clicking the field name (such as Department, Category, or Brand) will allow you to choose certain values
            from the field in the center panel.
        </p>
        <p>This will filter the run to those selections, as well as include a column in the output for that field.</p>
        <DodResourceLink />
    </>
);

const marketsHelpIcon: ReactNode = (
    <>
        <p>
            Select which markets to include in your run. Clicking the folder (such as Channels or Accounts) will allow
            you to choose specific markets of that type in the center panel.
        </p>
        <p>
            Selecting markets in the center panel will filter the run to those markets, as well as include the selected
            markets in the output in the location you designate based on your layout.
        </p>
        <DodResourceLink />
    </>
);

const timePeriodsHelpIcon: ReactNode = (
    <>
        <p>Select which time periods to include in your run.</p>
        <p>
            Syndicated Time Periods will provide common selections, such as Latest X Weeks or set calendar time frames
            like months.
        </p>
        <p>Use the Sum Selections button to create custom time period aggregations.</p>
        <DodResourceLink />
    </>
);

const factsHelpIcon: ReactNode = (
    <>
        <p>Select which facts to include in your run.</p>
        <p>
            Clicking the folder name (such as Total Facts or All TDP & Distribution Facts) will allow you to choose
            specific facts in the center panel. This will include the selected facts in your run for all of the product,
            market, and time period dimensions already selected.
        </p>
        <p>
            If you would like more information on what a fact is and how it is calculated, check out the&nbsp;
            <ByzzerLink
                href="https://learn.byzzer.ai/en/articles/6310950-data-on-demand-fact-list-and-definitions."
                target="_blank"
            >
                fact definitions file!
            </ByzzerLink>
        </p>
        <DodResourceLink />
    </>
);

const layoutHelpIcon: ReactNode = (
    <>
        <p>Select the layout for your run.</p>
        <p>The Quick Layout options will allow you to choose from the most common Data On Demand layout designs.</p>
        <p>Use Design Your Layout to build a custom layout.</p>
        <p>
            The Excel Template option allows you to attach any custom data visualizations (e.g. pivot tables, charts,
            etc.) that you have built in Excel and want to update with the new data.
        </p>
        <DodResourceLink />
    </>
);

const scheduleHelpIcon: ReactNode = (
    <>
        <p>
            If you are looking to schedule a report to run one time on a date that isn’t available yet, select the first
            option, one-time.
        </p>
        <p>
            If you are looking to run data weekly for a period of time (e.g. every week for a quarter or a year), select
            the second option, weekly.
        </p>
        <p>
            If you are looking for another cadence, review our monthly and quarterly options to see if any of those
            cadences meet your needs. Otherwise select the custom option to create your own custom cadence.
        </p>
        <DodResourceLink />
    </>
);
export const helpIconContent = {
    products_help_icon: productsHelpIcon,
    markets_help_icon: marketsHelpIcon,
    time_periods_help_icon: timePeriodsHelpIcon,
    fact_help_icon: factsHelpIcon,
    layout_help_icon: layoutHelpIcon,
    schedule_help_icon: scheduleHelpIcon,
};
