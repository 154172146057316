import './SegmentRunConfigFiltersStep.scss';
import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import { SegmentRunConfigFilters, SegmentRunConfigFiltersProps } from './SegmentRunConfigFilters';
import { AccordionWizard, StepRef } from '@byzzer/ui-components';
import { ConfigOptionsType } from '@/types/RunConfigOptions';

export type SegmentRunConfigFiltersStepProps = {
    title?: ReactNode;
    exclude?: boolean;
    id?: ConfigOptionsType;
} & SegmentRunConfigFiltersProps;

const baseClassName = 'segment-run-config-filters-step';

export const SegmentRunConfigFiltersStep = React.forwardRef<StepRef, SegmentRunConfigFiltersStepProps>(
    ({ className, title, id, exclude, ...props }, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
        }

        return (
            <AccordionWizard.Step
                className={classnames(baseClassName, className)}
                ref={ref}
                title={title}
                id={id}
                exclude={exclude}
            >
                <AccordionWizard.Header
                    className={classnames({
                        'report-run-config-wizard-header--valid': filtersValid,
                    })}
                >
                    <h1 className={'report-run-config-wizard__step-title'}>{title}</h1>
                </AccordionWizard.Header>
                <AccordionWizard.Content>
                    <SegmentRunConfigFilters {...props} onValidityChange={handleValidationChange} />
                </AccordionWizard.Content>
                <AccordionWizard.Actions
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                />
            </AccordionWizard.Step>
        );
    }
);
