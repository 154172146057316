import React, {useState} from 'react';
import classNames from 'classnames';
import {LegacyByzzerButton, ByzzerLink, ByzzerModal} from '@/components/form';
import {TipIcon} from '@/components/icons';
import {TrackClick} from "@/analytics";
import { openModal } from '@/components/form/ByzzerModal';

export function PPGDefinition({ className, onCreatePPG }) {
    const rootClassNames = classNames(className, 'ppg-definition');
    const [tipsInfo, setTipsInfo] = useState(false);

    const infoItems = [
        {
            heading: 'What is a PPG?',
            description: (
                <p>
                    A PPG is a line of items that are priced and promoted together. These are especially important when
                    assessing pricing & promotions strategies and opportunities, so that insights & recommendations will
                    be aligned across the entire product line.
                </p>
            ),
        },
        {
            heading: 'Tips for defining PPGs',
            description: (
                <>
                    <p>
                        The main objective for defining PPGs is to ensure that they are broken up by price
                        differentiators. Typically, Brand, Size, and Pack Size will be the basic differentiators for
                        PPGs. Depending on your category, the relevant characteristics will vary.
                    </p>
                    <p>
                        For example, in beverage categories, often the package material (glass, plastic, aluminum) will
                        be a differentiator; you may want to create one PPG for every combination of size, pack size,
                        and package material.
                    </p>
                    <p>
                        In other categories, health & wellness values such as Gluten Free may be important
                        differentiators. In that case, you may want every brand to be split into PPGs based on size and
                        whether or not they are Gluten Free.
                    </p>
                    <p>
                        You may also want to combine groups of values within a characteristic. For example, if you have
                        products between 8-10 OZ that are all priced together, you can choose multiple sizes from the
                        Total Size characteristic (such as 8 OZ, 8.4 OZ, and 10 OZ) to combine all UPCs of that size
                        range together.
                    </p>
                </>
            ),
        },
    ];

    async function onPPGInfoClick() {
        await openModal({
            title: `${infoItems[0].heading}`,
            content: (
                <>
                    <p>{infoItems[0].description}</p>
                </>
            ),
        });
    }

    async function onUploadClick() {
        onCreatePPG?.(true);
    }

    async function onCategoryPGClick() {
        onCreatePPG?.(false);
    }

    function onPPGTipLinkClick() {
        setTipsInfo(true);
    }

    return (
        <>
            <div className={rootClassNames}>
                <h2 className={'ppg-definition__header'}>
                    PPGs{' '}
                    <TrackClick name={`PPG Info clicked`}>
                        {/* @ts-ignore */}
                        <TipIcon type={'info'} onClick={onPPGInfoClick} />{' '}
                    </TrackClick>
                </h2>
                <p className={'ppg-definition__text'}>
                    Defining your Promoted Product Groups (PPGs) allows Byzzer to make recommendations at the line-group
                    level (e.g. all flavors of the same size grouped together), so that your insights are always
                    actionable. There are two ways you can define PPGs:
                </p>
                <h2 className={'ppg-definition__title'}>Create a New PPG List</h2>
                <ol className={'ppg-creation-options'}>
                    <li className={'ppg-creation-option'}>
                        <span className={'ppg-creation-option__description'}>
                            Upload a file with the UPCs for each PPG
                        </span>
                        <TrackClick name={`PPG Upload clicked`}>
                            <LegacyByzzerButton className="ppg-creation-option__button" onClick={onUploadClick}>
                                Upload PPGs
                            </LegacyByzzerButton>
                        </TrackClick>
                    </li>
                    <li className={'ppg-creation-option'}>
                        <span className={'ppg-creation-option__description'}>
                            Set Characteristics to group all items within a category (e.g. create brand-size-package
                            type groups).
                        </span>
                        <LegacyByzzerButton className="ppg-creation-option__button" onClick={onCategoryPGClick}>
                            Set Category PPGs
                        </LegacyByzzerButton>
                    </li>
                </ol>
                <TrackClick name={`PPG Tips clicked`}>
                    <ByzzerLink
                        className={'ppg-definition__tip-link'}
                        onClick={onPPGTipLinkClick}
                        label="Tips for defining PPGs"
                    />
                </TrackClick>
            </div>
            <ByzzerModal
                show={tipsInfo}
                onClose={() => setTipsInfo(!tipsInfo)}
                closeOnClickOutside={true}
                size={'medium'}
                heading={infoItems[1]?.heading}
                type={'info'}
                className={'customModal'}
                headerType={'normal'}
            >
                {infoItems[1]?.description}
            </ByzzerModal>
        </>
    );
}
