import './CategorySelector.scss';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Checkbox, Tag, Tooltip } from 'antd';
import { ByzzerSwitch } from '@/components/form';
import ReactHtmlParser from 'react-html-parser';
import {useTenantApi} from '@/hooks/useTenantApi';
import classnames from 'classnames';
import * as _ from 'lodash';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import LegacyByzzerCheckbox from '@/components/form/LegacyByzzerCheckbox';
import { useUser } from '@/contexts/UserContext';
import { parseHiddenCharacters } from '@/utils';

export function CategorySelector({
    className,
    maxCategoryCount = Infinity,
    categories = [],
    onSelectionChange,
    uneditableCategories = [],
    ...props
}) {
    const { findRetailProducts, getHierarchyData } = useTenantApi();
    const baseClassName = 'category-selector';
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [showTree, setShowTree] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchUsingBrand, setSearchUsingBrand] = useState(true);
    const [state, setState] = useState({
        searchResults: {},
        displayTree: true,
        brand: [],
        searchErrorEmpty: false,
        searchError: false,
        isSearchProductScopeSelected: false,
        errorMessage: props.errorMessage,
        displayModal: false,
        modaltitle: '',
        modalMessage: '',
        categoryData: props.categoryData,
    });
    const { categoriesEditable } = useUser();

    useEffect(() => {
        setShowTree(searchText?.length < 3);
    }, [searchText]);

    useEffect(() => {
        if(props?.searchByHierarchy) {
            setSearchUsingBrand(!searchByBrand)
        }
        // Mapping Department data to tree structure
        loadDepartments();
    }, []);

    async function loadDepartments() {
        try {
            setLoading(true);
            const { departments } = await getHierarchyData();
            const treeData = departments.map((department) => ({
                title: department.name,
                key: department.name,
                // selected: false,
                expanded: false,
                childrenSelected: false,
            }));
            setTreeData(treeData);
        } finally {
            setLoading(false);
        }
    }

    async function toggleDepartment(departmentIndex) {
        const _treeData = _.cloneDeep(treeData);
        const node = _treeData[departmentIndex];

        // populate children if necessary
        if (!node.children) {
            try {
                setLoading(true);
                const { supercategories } = await getHierarchyData(`department`, node.title);
                node.children = supercategories.map((superCategory) => ({
                    //Pushing super categories
                    title: superCategory.name,
                    popular_products: superCategory.popular_products?.toLowerCase?.(),
                    key: superCategory.name,
                    // selected: Boolean(node.selected),
                    expanded: false,
                    childrenSelected: false,
                }));
            } finally {
                setLoading(false);
            }
        }

        node.expanded = !node.expanded;
        setTreeData(_treeData);
    }

    async function toggleSuperCategory(departmentIndex, superCategoryIndex) {
        const _treeData = _.cloneDeep(treeData);
        const node = _treeData[departmentIndex].children[superCategoryIndex];

        if (!node.children) {
            try {
                setLoading(true);
                const { categories: hierarchyCategories } = await getHierarchyData(`supercategory`, node.title);

                node.children = hierarchyCategories.filter(isEditableCategory).map((category) => ({
                    title: category.name,
                    popular_products: category.popular_products?.toLowerCase?.(),
                    key: category.name,
                    // selected: categories.includes(category.name),
                    expanded: false,
                    childrenSelected: false,
                }));
            } finally {
                setLoading(false);
            }
        }

        node.expanded = !node.expanded;
        setTreeData(_treeData);
    }

    async function toggleCategory(departmentIndex, superCategoryIndex, categoryIndex) {
        const _treeData = _.cloneDeep(treeData);
        const node = _treeData[departmentIndex].children[superCategoryIndex].children[categoryIndex];

        if (!node.children) {
            try {
                setLoading(true);
                const { subcategories } = await getHierarchyData(`category`, node.title);

                node.children = subcategories.filter(isEditableCategory).map((subCategory) => ({
                    title: subCategory.name,
                    popular_products: subCategory.popular_products?.toLowerCase?.(),
                    key: subCategory.name,
                    // selected: Boolean(node.selected),
                    expanded: false,
                    childrenSelected: false,
                }));
            } finally {
                setLoading(false);
            }
        }

        // node.selected = categories.includes(node.title);
        node.expanded = !node.expanded;
        setTreeData(_treeData);
    }

    function isEditableCategory({ name }) {
        return !uneditableCategories?.includes?.(name);
    }

    const onCategorySelectionChange = (category, selected) => {
        // clone it so we don't effect the shared value
        const _categories = categories.slice();

        if (!selected) {
            // node.selected = false;
            const categoryIndex = categories.findIndex((title) => title === category);
            if (categoryIndex !== -1) {
                _categories.splice(categoryIndex, 1);
            }
        } else {
            _categories.push(category);
        }

        const uniqueSet = _.uniq(_categories);
        if (uniqueSet.length <= maxCategoryCount) {
            // node.selected = !node.selected;
            // setTreeData(_treeData);
            onSelectionChange(uniqueSet);
        }
    };

    const getHighlightedText = (text, highlights) => {
        // convert the array to a regular expression that looks for any word that is found in the list, regardless of case (i), over all the string (g)
        const regexp = new RegExp(`\\b(${highlights.join('|')})\\b`, 'gi');
        // replace the found words with a span that contains each word
        return text.replace(regexp, `<span style="text-decoration: underline"}>$&</span>`);
    };

    const getSearchKeywordHighlighted = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        let parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span
                        key={i}
                        style={
                            part.toLowerCase() === (highlight.toLowerCase() || searchText.toLowerCase())
                                ? { textDecoration: 'underline' }
                                : {}
                        }
                    >
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    async function searchByBrand(searchText) {
        const searchResults = await findRetailProducts('brand', searchText); // Search api - w.r.t Brand

        if (searchResults.error) {
            setState((state) => ({
                ...state,
                searchErrorEmpty: true,
                searchResults: [],
                searchError: false,
            }));
        } else if (searchResults.departments?.length === 0) {
            setState((state) => ({
                ...state,
                searchError: true,
                isLoading: false,
                searchResults: {},
                searchErrorEmpty: false,
            }));
        } else {
            if (searchResults.departments !== undefined) {
                // Adding isSelected to departments
                searchResults.departments.forEach((department) => {
                    department.isSelected = false;
                    department.childrenSelected = false;
                    if (department.supercategories !== undefined) {
                        // Creating short description for department starts
                        let supercategoriesNameMap = [];
                        let shortDescription = ``;
                        let slicedSuperCategories = department.supercategories.slice(0, 3);
                        supercategoriesNameMap = slicedSuperCategories.map((supercategory, supercategoryIndex) => {
                            return supercategoryIndex <= 1 ? ` "${supercategory.name}"` : ` and other super categories`;
                        });
                        let slicedBrands = department.matching_brands.slice(0, 3);
                        let brands = slicedBrands.map((brand, brandIndex) => {
                            return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                        });
                        shortDescription = `contains ${[...brands]} brand in ${[...supercategoriesNameMap]}`;
                        shortDescription = getSearchKeywordHighlighted(shortDescription, searchText);
                        // Creating short description for department ends
                        department.shortDescription = shortDescription;
                        // Adding isSelected to supercategories
                        department.supercategories.forEach((supercategory) => {
                            supercategory.isSelected = false;
                            supercategory.childrenSelected = false;
                            if (supercategory.categories !== undefined) {
                                // Creating short description for super category starts
                                let categoriesNameMap = [];
                                shortDescription = ``;
                                let slicedCategories = supercategory.categories.slice(0, 3);
                                categoriesNameMap = slicedCategories.map((category, categoryIndex) => {
                                    return categoryIndex <= 1 ? ` "${category.name}"` : ` and other categories`;
                                });
                                let slicedBrands = supercategory.matching_brands.slice(0, 3);
                                let brands = slicedBrands.map((brand, brandIndex) => {
                                    return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                                });
                                shortDescription = `contains ${[...brands]} brand in ${[...categoriesNameMap]}`;
                                shortDescription = getSearchKeywordHighlighted(shortDescription, searchText);
                                // Creating short description for super category ends
                                supercategory.shortDescription = shortDescription;
                                // Adding isSelected to categories
                                supercategory.categories.forEach((category) => {
                                    // const itemSelected = state.categoryData.find(val => val === category.name)?.length > 0 ? true : false;
                                    category.isSelected = false;
                                    category.childrenSelected = false;
                                    if (category.subcategories !== undefined) {
                                        // Creating short description for category starts
                                        let subcategoriesNameMap = [];
                                        shortDescription = ``;
                                        let slicedSubcategories = category.subcategories.slice(0, 3);
                                        subcategoriesNameMap = slicedSubcategories.map(
                                            (subcategory, subcategoryIndex) => {
                                                return subcategoryIndex <= 1
                                                    ? ` "${subcategory.name}"`
                                                    : ` and other sub categories`;
                                            }
                                        );
                                        let slicedBrands = category.matching_brands.slice(0, 3);
                                        let brands = slicedBrands.map((brand, brandIndex) => {
                                            return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                                        });
                                        shortDescription = `contains ${[...brands]} brand in ${[
                                            ...subcategoriesNameMap,
                                        ]}`;
                                        shortDescription = getSearchKeywordHighlighted(shortDescription, searchText);
                                        // Creating short description for category ends
                                        category.shortDescription = shortDescription;
                                        // Adding isSelected to subcategories
                                        category.subcategories.forEach((subcategory) => {
                                            subcategory.isSelected = false;
                                            subcategory.childrenSelected = false;
                                            // Creating short description for subcategory starts
                                            let slicedBrands = subcategory.matching_brands.slice(0, 3);
                                            let brands = slicedBrands.map((brand, brandIndex) => {
                                                return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                                            });
                                            shortDescription = `contains ${[...brands]} brand`;
                                            shortDescription = getSearchKeywordHighlighted(
                                                shortDescription,
                                                searchText
                                            );
                                            // Creating short description for subcategory ends
                                            subcategory.shortDescription = shortDescription;
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }

            setSearchText(searchText);
            setState((state) => ({
                ...state,
                searchResults,
                searchError: false,
                searchErrorEmpty: false,
            }));
        }
    }

    async function searchHierarchy(searchText) {
        try {
            setLoading(true);
            const searchResults = await findRetailProducts('hierarchy', searchText); // Search api - w.r.t Hierarchy

            if (searchResults.error) {
                setState((state) => ({
                    ...state,
                    searchErrorEmpty: true,
                    isLoading: false,
                    searchResults: {},
                    searchError: false,
                }));
            } else if (searchResults.departments?.length === 0) {
                setState((state) => ({
                    ...state,
                    searchError: true,
                    isLoading: false,
                    searchResults: {},
                    searchErrorEmpty: false,
                }));
            } else {
                if (Object.keys(searchResults).length > 0) {
                    if (searchResults.departments !== undefined) {
                        // Adding isSelected to departments
                        searchResults.departments.forEach((department) => {
                            department.isSelected = false;
                            department.childrenSelected = false;
                            searchResults.synonyms.push(searchText);
                            if (searchResults.synonyms !== undefined && searchResults.synonyms.length > 0) {
                                department.displayName = getHighlightedText(department.name, searchResults.synonyms);
                            }
                            if (department.popular_products !== undefined) {
                                department.popular_products =
                                    department.popular_products !== undefined
                                        ? department.popular_products.toLowerCase()
                                        : null;
                            }
                            if (department.supercategories !== undefined) {
                                // Adding isSelected to supercategories
                                department.supercategories.forEach((supercategory) => {
                                    supercategory.isSelected = false;
                                    supercategory.childrenSelected = false;
                                    searchResults.synonyms.push(searchText);
                                    if (searchResults.synonyms !== undefined && searchResults.synonyms.length > 0) {
                                        supercategory.displayName = getHighlightedText(
                                            supercategory.name,
                                            searchResults.synonyms
                                        );
                                    }
                                    if (supercategory.popular_products !== undefined) {
                                        supercategory.popular_products =
                                            supercategory.popular_products !== undefined
                                                ? supercategory.popular_products.toLowerCase()
                                                : null;
                                    }
                                    if (supercategory.categories !== undefined) {
                                        // Adding isSelected to categories
                                        supercategory.categories.forEach((category) => {
                                            category.isSelected = false;
                                            category.childrenSelected = false;
                                            searchResults.synonyms.push(searchText);
                                            if (
                                                searchResults.synonyms !== undefined &&
                                                searchResults.synonyms.length > 0
                                            ) {
                                                category.displayName = getHighlightedText(
                                                    category.name,
                                                    searchResults.synonyms
                                                );
                                            }
                                            if (category.popular_products !== undefined) {
                                                category.popular_products =
                                                    category.popular_products !== undefined
                                                        ? category.popular_products.toLowerCase()
                                                        : null;
                                            }
                                            if (category.subcategories !== undefined) {
                                                // Adding isSelected to subcategories
                                                category.subcategories.forEach((subcategory) => {
                                                    subcategory.isSelected = false;
                                                    subcategory.childrenSelected = false;
                                                    searchResults.synonyms.push(searchText);
                                                    if (
                                                        searchResults.synonyms !== undefined &&
                                                        searchResults.synonyms.length > 0
                                                    ) {
                                                        subcategory.displayName = getHighlightedText(
                                                            subcategory.name,
                                                            searchResults.synonyms
                                                        );
                                                    }
                                                    if (subcategory.popular_products !== undefined) {
                                                        subcategory.popular_products =
                                                            subcategory.popular_products !== undefined
                                                                ? subcategory.popular_products.toLowerCase()
                                                                : null;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
                setSearchText(searchText);
                setState((state) => ({
                    ...state,
                    isLoading: false,
                    searchResults,
                    searchError: false,
                    searchErrorEmpty: false,
                }));
            }
        } finally {
            setLoading(false);
        }
    }

    function searchItems({ target }) {
        const searchText = target.value;
        setSearchText(searchText);
        if (searchText.length > 2) {
            // Search only when we have atleast 3 characters to search
            if (searchUsingBrand) {
                searchByBrand(searchText);
            } else {
                searchHierarchy(searchText);
            }
        }
    }

    function onRemoveTag(index) {
        const value = categories[index];

        if (uneditableCategories.includes(value) && !categoriesEditable && (import.meta.env.REACT_APP_SUBSCRIPTION_CATEGORIES_ALWAYS_EDITABLE !== 'true')) {
            return;
        }

        const _categories = categories.slice();
        const _treeData = _.cloneDeep(treeData);
        _categories.splice(index, 1);
        onSelectionChange(_categories);

        let category;
        _treeData.forEach(({ children }) =>
            children?.forEach?.(({ children }) => {
                const match = children?.find?.(({ title }) => title === value);
                if (match) {
                    category = match;
                }
            })
        );
        if (category) {
            // category.selected = false;
            setTreeData(_treeData);
        }

        if (state.searchResults) {
            const searchResults = _.cloneDeep(state.searchResults);
            let searchCategory;
            searchResults.departments?.forEach?.(({ supercategories }) =>
                supercategories?.forEach?.(({ categories }) => {
                    const match = categories.find(({ name }) => name?.toUpperCase() === value);
                    if (match) {
                        searchCategory = match;
                    }
                })
            );
            if (searchCategory) {
                searchCategory.isSelected = false;
                setState((state) => ({
                    ...state,
                    searchResults,
                }));
            }
        }
    }

    const onSearchTypeChange = ({ target }) => {
        setSearchUsingBrand(target.checked);
        setSearchText('');
        setState((state) => ({
            searchResults: {},
            displayTree: true,
        }));
    };

    const enableshowModal = () => {
        setState((state) => ({
            ...state,
            displayModal: false,
        }));
    };

    const handlePaste = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const clipboardData = event.clipboardData.getData('text');

        const delimitedString = parseHiddenCharacters({clipboardData, returnAsDelimitedString: true});

        searchItems({target: {value: 
            delimitedString
        }})
    };

    return (
        <div className={classnames(baseClassName, className)}>
            <div className={`${baseClassName}__search`}>
                <div className={`${baseClassName}__search-input`} onPaste={handlePaste}>
                    <DebounceInput
                        minLength={2}
                        maxLength={20}
                        debounceTimeout={500}
                        onChange={searchItems}
                        placeholder={searchUsingBrand ? 'Search for a Brand' : 'Search for a Category'}
                        value={searchText}
                    />
                </div>
                <div className={`${baseClassName}__search-toggle`}>
                    <span
                        className={classnames(`${baseClassName}__search-type`, {
                            [`${baseClassName}__search-type--active`]: searchUsingBrand,
                        })}
                    >
                        {' '}
                        By Hierarchy
                    </span>
                    <ByzzerSwitch
                        className={`${baseClassName}__search-type-switch`}
                        value={searchUsingBrand}
                        onChange={onSearchTypeChange}
                    />
                    <span
                        className={classnames(`${baseClassName}__search-type`, {
                            [`${baseClassName}__search-type--active`]: !searchUsingBrand,
                        })}
                    >
                        By Brand
                    </span>
                </div>
            </div>
            <div className={`${baseClassName}__categories`}>
                {categories.map((category, index) => (
                    <Tag
                        key={category}
                        className={classnames(`${baseClassName}__category`, {
                            [`${baseClassName}__category--locked`]:
                                uneditableCategories.includes(category) && !categoriesEditable && (import.meta.env.REACT_APP_SUBSCRIPTION_CATEGORIES_ALWAYS_EDITABLE !== 'true'),
                        })}
                    >
                        <div className={`${baseClassName}__tag-title`}>
                            {category}
                        <span className={`${baseClassName}__tag-close-icon`} onClick={() => onRemoveTag(index)}></span>
                        </div>
                    </Tag>
                ))}
                {!categories.length && (
                    <div className={`${baseClassName}__categories-placeholder`}>
                        You have not selected any categories
                    </div>
                )}
            </div>
            {showTree && (
                <div className="product-scope-content-tree-select">
                    <ByzzerMask show={loading} loading={loading} />
                    <div className="product-scope-content-tree-container">
                        {treeData.map?.((department, departmentIndex) => (
                            <DepartmentNode key={department.name} index={departmentIndex} {...department} />
                        ))}
                    </div>
                </div>
            )}
            {!state.displayProducts && !showTree && (
                <div className="product-scope-content-search-results">
                    <ByzzerMask show={loading} loading={loading} />
                    {state.searchError && !state.searchErrorEmpty && (
                        <div className="product-scope-content-search-results-error">
                            <span className="product-scope-content-search-results-error-main">No match found.</span>
                            <span className="product-scope-content-search-results-error-sub">
                                Please refine your search.
                            </span>
                        </div>
                    )}
                    {state.searchErrorEmpty && !state.searchError && (
                        <div className="product-scope-content-search-results-error">
                            <span className="product-scope-content-search-results-error-main">
                                Your search generated a lot of results.
                            </span>
                            <span className="product-scope-content-search-results-error-sub">
                                Please refine your search.
                            </span>
                        </div>
                    )}
                    {state.searchResults?.has_more_hits && (
                        <div className="product-scope-content-search-results-has-more-hits">
                            <span className="product-scope-content-search-results-error-main">
                                Your search generated a lot of results, We are showing the top results.
                            </span>
                            <span className="product-scope-content-search-results-error-sub">
                                Refine your search if your {searchUsingBrand ? 'product' : 'hierarchy'} is not listed.
                            </span>
                        </div>
                    )}
                    {Object.keys(state.searchResults ?? {}).length > 0 &&
                        state.searchResults.departments !== undefined &&
                        state.searchResults.departments.map((department, departmentIndex) => {
                            return (
                                <div key={departmentIndex + 1}>
                                    <div
                                        style={{ color: department.isSelected ? '#000000' : '#707070' }}
                                        className="product-scope-content-search-results-department-container"
                                    >
                                        <div className="product-scope-content-search-results-department-holder">
                                            <div className="product-scope-content-search-results-department-value">
                                                <div className="product-scope-content-search-results-department-name">
                                                    <Tooltip
                                                        placement="bottomRight"
                                                        title={department.popular_products}
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                wordBreak: 'break-word',
                                                                width: 'auto',
                                                            }}
                                                        >
                                                            {department.displayName !== undefined
                                                                ? ReactHtmlParser(department.displayName)
                                                                : department.name}
                                                        </span>
                                                    </Tooltip>
                                                    <div className="product-scope-content-search-results-department-label">
                                                        Department
                                                    </div>
                                                </div>
                                            </div>
                                            {department.matching_brands !== undefined &&
                                                department.matching_brands.length > 0 && (
                                                    <div className="product-scope-content-search-results-department-description">
                                                        {department.shortDescription.length > 60 ? (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    flexWrap: 'wrap',
                                                                    wordBreak: 'break-word',
                                                                }}
                                                            >
                                                                {`${department.shortDescription
                                                                    .toString()
                                                                    .substring(0, 60)}`}
                                                                <span>more...</span>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    flexWrap: 'wrap',
                                                                    wordBreak: 'break-word',
                                                                }}
                                                            >
                                                                {department.shortDescription}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                    {department.supercategories !== undefined &&
                                        department.supercategories.length > 0 &&
                                        department.supercategories.map((superCategory, superCategoryIndex) => {
                                            return (
                                                <div
                                                    key={superCategoryIndex}
                                                    style={{ color: superCategory.isSelected ? '#000000' : '#707070' }}
                                                    className="product-scope-content-search-results-supercategory-container"
                                                >
                                                    <div className="product-scope-content-search-results-supercategory-holder">
                                                        <div className="product-scope-content-search-results-supercategory-value">
                                                            <div className="product-scope-content-search-results-supercategory-name">
                                                                <Tooltip
                                                                    placement="bottomRight"
                                                                    title={superCategory.popular_products}
                                                                >
                                                                    <span
                                                                        style={{
                                                                            flexWrap: 'wrap',
                                                                            wordBreak: 'break-word',
                                                                            width: 'auto',
                                                                        }}
                                                                    >
                                                                        {superCategory.displayName !== undefined
                                                                            ? ReactHtmlParser(superCategory.displayName)
                                                                            : superCategory.name}
                                                                    </span>
                                                                </Tooltip>
                                                                <div className="product-scope-content-search-results-supercategory-label">
                                                                    Super Category
                                                                </div>
                                                            </div>
                                                            {superCategory.matching_brands !== undefined &&
                                                                superCategory.matching_brands.length > 0 && (
                                                                    <div className="product-scope-content-search-results-supercategory-description">
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                flexWrap: 'wrap',
                                                                                wordBreak: 'break-word',
                                                                            }}
                                                                        >
                                                                            {superCategory.shortDescription}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>

                                                    {superCategory.categories?.map?.((category, categoryIndex) => {
                                                        if (isEditableCategory(category)) {
                                                            return (
                                                                <div
                                                                    key={categoryIndex}
                                                                    style={{
                                                                        color: category.isSelected
                                                                            ? '#000000'
                                                                            : '#707070',
                                                                    }}
                                                                    className="product-scope-content-search-results-category-container"
                                                                >
                                                                    <div className="product-scope-content-search-results-category-holder">
                                                                        <div className="product-scope-content-search-results-category-value">
                                                                            <div className="product-scope-content-search-results-category-name">
                                                                                <Checkbox
                                                                                    onClick={({ target }) =>
                                                                                        onCategorySelectionChange(
                                                                                            category.name,
                                                                                            target.checked
                                                                                        )
                                                                                    }
                                                                                    indeterminate={
                                                                                        category.childrenSelected
                                                                                    }
                                                                                    checked={categories.includes(
                                                                                        category.name
                                                                                    )}
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        width: '1.5vw',
                                                                                    }}
                                                                                />
                                                                                <Tooltip
                                                                                    placement="bottomRight"
                                                                                    title={category.popular_products}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            flexWrap: 'wrap',
                                                                                            wordBreak: 'break-word',
                                                                                            width: 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {category.displayName !==
                                                                                        undefined
                                                                                            ? ReactHtmlParser(
                                                                                                  category.displayName
                                                                                              )
                                                                                            : category.name}
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <div className="product-scope-content-search-results-category-label">
                                                                                    Category
                                                                                </div>
                                                                            </div>
                                                                            {category.matching_brands !== undefined &&
                                                                                category.matching_brands.length > 0 && (
                                                                                    <div className="product-scope-content-search-results-category-description">
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'row',
                                                                                                flexWrap: 'wrap',
                                                                                                wordBreak: 'break-word',
                                                                                            }}
                                                                                        >
                                                                                            {category.shortDescription}
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                    {category.subcategories !== undefined &&
                                                                        category.subcategories.length > 0 &&
                                                                        category.subcategories.map((item4, index4) => {
                                                                            return (
                                                                                <div
                                                                                    key={index4}
                                                                                    style={{
                                                                                        color: item4.isSelected
                                                                                            ? '#000000'
                                                                                            : '#707070',
                                                                                        paddingLeft: '25px',
                                                                                    }}
                                                                                    className="product-scope-content-search-results-subcategory-container"
                                                                                >
                                                                                    <div className="product-scope-content-search-results-subcategory-holder">
                                                                                        <div className="product-scope-content-search-results-subcategory-label"></div>
                                                                                        <div className="product-scope-content-search-results-subcategory-value">
                                                                                            <div className="product-scope-content-search-results-subcategory-name">
                                                                                                <Tooltip
                                                                                                    placement="bottomRight"
                                                                                                    title={
                                                                                                        item4.popular_products
                                                                                                    }
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            dispaly:
                                                                                                                'flex',
                                                                                                            flexWrap:
                                                                                                                'wrap',
                                                                                                            wordBreak:
                                                                                                                'break-word',
                                                                                                            width:
                                                                                                                'auto',
                                                                                                        }}
                                                                                                    >
                                                                                                        {item4.displayName !==
                                                                                                        undefined
                                                                                                            ? ReactHtmlParser(
                                                                                                                  item4.displayName
                                                                                                              )
                                                                                                            : item4.name}
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                                <div className="product-scope-content-search-results-subcategory-label">
                                                                                                    Sub Category
                                                                                                </div>
                                                                                            </div>
                                                                                            {item4.matching_brands !==
                                                                                                undefined &&
                                                                                                item4.matching_brands
                                                                                                    .length > 0 && (
                                                                                                    <div className="product-scope-content-search-results-subcategory-description">
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                flexDirection:
                                                                                                                    'row',
                                                                                                                flexWrap:
                                                                                                                    'wrap',
                                                                                                                wordBreak:
                                                                                                                    'break-word',
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                item4.shortDescription
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );

    function DepartmentNode({ children = [], index, ...props }) {
        return (
            <TreeNode {...props} onClick={() => toggleDepartment(index)}>
                {children.map((superCategory, superCategoryIndex) => (
                    <SuperCategoryNode departmentIndex={index} index={superCategoryIndex} {...superCategory} />
                ))}
            </TreeNode>
        );
    }

    function SuperCategoryNode({ children = [], departmentIndex, index, ...props }) {
        return (
            <TreeNode {...props} onClick={() => toggleSuperCategory(departmentIndex, index)}>
                {children.map((category, categoryIndex) => {
                    return (
                        <CategoryNode
                            departmentIndex={departmentIndex}
                            superCategoryIndex={index}
                            selectable
                            index={categoryIndex}
                            {...category}
                        />
                    );
                })}
            </TreeNode>
        );
    }

    function CategoryNode({ children = [], departmentIndex, superCategoryIndex, index, ...props }) {
        function onSelectionChange({ target }) {
            onCategorySelectionChange(props.title, target.checked);
        }

        return (
            <TreeNode
                {...props}
                selected={categories.includes(props.title)}
                onClick={() => toggleCategory(departmentIndex, superCategoryIndex, index)}
                onSelectionChange={onSelectionChange}
            >
                {children.map((subCategory, subCategoryIndex) => (
                    <SubCategoryNode
                        departmentIndex={departmentIndex}
                        superCategoryIndex={superCategoryIndex}
                        categoryIndex={index}
                        index={subCategoryIndex}
                        {...subCategory}
                    />
                ))}
            </TreeNode>
        );
    }

    function SubCategoryNode({ children = [], departmentIndex, superCategoryIndex, categoryIndex, index, ...props }) {
        return <TreeNode {...props} leaf />;
    }
}

function TreeNode({
    title,
    level,
    selected = false,
    onSelectionChange,
    selectable = false,
    expanded = false,
    className,
    leaf = false,
    onClick,
    children,
    ...props
}) {
    const classNames = classnames('tree-node', `tree_node--level-${level}`, {
        'tree-node--expanded': expanded,
        'tree-node--selected': selected,
        'tree-node--leaf': leaf,
    });

    function onCheckboxChange(checked) {
        onSelectionChange?.(checked);
    }

    function onTriggerClick(e) {
        if (e.target.matches('.tree-node__checkbox, .tree-node__checkbox *')) {
            return;
        }
        onClick?.();
    }

    return (
        <div className={classNames} {...props}>
            <div className="tree-node__trigger" onClick={onTriggerClick}>
                {selectable && (
                    <LegacyByzzerCheckbox className={'tree-node__checkbox'} onChange={onCheckboxChange} checked={selected} />
                )}
                {/*<Tooltip placement="bottomRight" title={item.popular_products}>*/}
                <span>{title}</span>
                {/*</Tooltip>*/}
            </div>
            <div className={'tree-node__children'}>{children}</div>
        </div>
    );
}

export default CategorySelector;
