import './CSRSignIn.scss';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@/contexts/UserContext';
import { LegacyByzzerButton, LegacyByzzerSelect } from '@/components/form';
import ByzzerInput from '@/components/form/ByzzerInput';
import { CSR_OBO_ROLES } from '@/constants/csrOboRoles.constants';
import { useAccountService } from '@/hooks/useAccountService';

const baseClassName = 'csr-signin';

export function CSRSignIn() {
    const navigate = useNavigate();
    const {clearOboUserInfo, getByzzerUserEmail, getIsCsrObo, setLocalOboUser, setOboUserInRequestHeaders} = useAccountService();

    const [loginError, setLoginError] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [byzzerUserEmail, setByzzerUserEmail] = useState({ isLoading: false, data: '', error: null });
    const [isCsrObo, setIsCsrObo] = useState({ isLoading: false, data: '', error: null });
    const { user } = useUser();

    const [selectedRole, setSelectedRole] = useState(null);

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    function goToDashboard() {
        setSigningIn(false); // eg: done
        setLoginError(false);
        navigate('/dashboard', { replace: true });
    }

    useEffect(() => {
        if (user) {
            goToDashboard();
        }
        // eslint-disable-next-line
    }, [user]);

    function updateUsername(e) {
        setLoginError(false);
        credentials.username = e.target.value;
        setIsCsrObo({ ...isCsrObo, data: '', error: null });
        setCredentials({ ...credentials });
    }

    function updatePassword(e) {
        setLoginError(false);
        credentials.password = e.target.value;
        setCredentials({ ...credentials });
    }

    function updateUserEmail(e) {
        const userEmail = e.target.value;
        // Resetting the status
        setByzzerUserEmail({ ...byzzerUserEmail, data: '', error: null });
        setUserEmail(userEmail);
    }

    function signinButtonDisabled() {
        const allRequiredFieldsPresent =
            !signingIn &&
            credentials.username.length > 0 &&
            credentials.password.length > 0 &&
            userEmail?.length > 0 &&
            selectedRole &&
            !isCsrObo.isLoading &&
            !isCsrObo.error &&
            !byzzerUserEmail.isLoading &&
            !byzzerUserEmail.error
        return !allRequiredFieldsPresent;
    }

    async function signInIfCsrObo() {
        setIsCsrObo({
            isLoading: true,
            data: '',
            error: null,
        });
        try {
            const { isObo } = await getIsCsrObo(credentials.username);
            if (isObo) {
                setIsCsrObo({
                    isLoading: false,
                    data: 'Valid CSR user',
                    error: null,
                });
                signInIfUserExist();
            } else {
                setIsCsrObo({
                    isLoading: false,
                    data: '',
                    error: "Invalid CSR user. Please check email.",
                });
            }
        } catch (error) {
            setIsCsrObo({
                isLoading: false,
                error: 'something unexpected happened please try again',
            });
        }
    }

    async function signInIfUserExist() {
        setByzzerUserEmail({
            isLoading: true,
            data: '',
            error: null,
        });
        try {
            const byzzerUserEmail = await getByzzerUserEmail(userEmail);
            if (byzzerUserEmail) {
                setByzzerUserEmail({
                    isLoading: false,
                    data: byzzerUserEmail,
                    error: null,
                });
                signIn(byzzerUserEmail);
            } else {
                setByzzerUserEmail({
                    isLoading: false,
                    data: '',
                    error: "User doesn't exist or isn't verified yet.",
                });
            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.code === 'impersonation_disabled'
                    ? 'OBO is disable for this company due to their NielsenIQ contract. Please reach out to customer support for assistance'
                    : error?.response?.data?.message ?? 'something unexpected happened please try again';
            setByzzerUserEmail({
                isLoading: false,
                data: '',
                error: errorMessage,
            });
        }
    }

    async function signIn(userEmail) {
        try {
            setSigningIn(true);
            setLoginError(false);
            setLocalOboUser(userEmail, selectedRole.value, credentials.username);
            setOboUserInRequestHeaders();
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
            // See: https://firebase.google.com/docs/auth/web/auth-state-persistence#modifying_the_auth_state_persistence
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            await firebase.auth().signInWithEmailAndPassword(credentials.username, credentials.password);
            // We want to use the email that firebase auth returns since it knows the correct 'case' (upper/lower).  This
            // will have been populated by the time this code gets executed.
        } catch (error) {
            console.log('login error', error);
            clearOboUserInfo();
            setSigningIn(false);
            setLoginError(true);
        }
    }

    return (
        <div className={baseClassName}>
            <header className={`${baseClassName}__header`}>
                <h1 className={`${baseClassName}__title`}>CSR Sign In</h1>
            </header>

            <div className={`csr-signin__instruction`}>
                You should only be using OBO for your clients and you should always logout immediately after you are
                done. OBO should only be used for the following reasons
                <ul className={`csr-signin__instruction__list`}>
                    <li className={`csr-signin__instruction__list__item`}>
                        Configure an account by inviting users and/or selecting categories
                    </li>
                    <li className={`csr-signin__instruction__list__item`}>Confirming configuration or access</li>
                    <li className={`csr-signin__instruction__list__item`}>Troubleshooting issues for the client</li>
                    <li className={`csr-signin__instruction__list__item`}>
                        Executing a request of the client (ie setup a custom product group or help me setup this alert)
                    </li>
                </ul>
            </div>

            <main className={`${baseClassName}__content`}>
                <div className={`${baseClassName}__input-group ${baseClassName}__email`}>
                    <ByzzerInput
                        label="CSR Email"
                        name="username"
                        type="email"
                        className={`${baseClassName}__input`}
                        value={credentials.username}
                        onChange={updateUsername}
                        placeholder="CSR Username/Email"
                        required
                        disabled={signingIn}
                        data-test-csr-email
                    />
                    {(isCsrObo.error || isCsrObo.isLoading || isCsrObo.data) && (
                        <p className={'csr-signin validation'}>
                            {isCsrObo.error ||
                                (isCsrObo.isLoading ? 'Checking for CSR....' : isCsrObo.data)}
                        </p>
                    )}
                </div>

                <div className={`${baseClassName}__input-group ${baseClassName}__email`}>
                    <ByzzerInput
                        label="CSR Password"
                        name="password"
                        className={`${baseClassName}__input`}
                        value={credentials.password}
                        onChange={updatePassword}
                        placeholder="Password"
                        type="password"
                        validate={false}
                        required
                        disabled={signingIn}
                        data-test-csr-password
                    />
                    {loginError && <p className={'validation'}>Error logging in. Please check credentials</p>}
                </div>
                <div className={`${baseClassName}__input-group ${baseClassName}__email`}>
                    <ByzzerInput
                        label="Byzzer User Email"
                        name="useremail"
                        className={`${baseClassName}__input`}
                        value={userEmail}
                        onChange={updateUserEmail}
                        placeholder="Byzzer User Email"
                        type="email"
                        required
                        disabled={signingIn}
                        data-test-user-email
                    />
                    {(byzzerUserEmail.error || byzzerUserEmail.isLoading || byzzerUserEmail.data) && (
                        <p className={'csr-signin validation'}>
                            {byzzerUserEmail.error ||
                                (byzzerUserEmail.isLoading ? 'searching for user....' : byzzerUserEmail.data)}
                        </p>
                    )}
                </div>
                <div className={`${baseClassName}__input-group ${baseClassName}__email`}>
                    <LegacyByzzerSelect
                        name={'role'}
                        label={'CSR Role'}
                        className={`${baseClassName}__input`}
                        onChange={(selectedRoleId, selectedRole) => setSelectedRole(selectedRole)}
                        placeholder={'Select the CSR Role'}
                        options={CSR_OBO_ROLES}
                        value={selectedRole?.value}
                        disabled={signingIn}
                    />
                </div>
            </main>
            <footer className={`${baseClassName}__actions csr-signin__actions`}>
                <LegacyByzzerButton
                    onClick={signInIfCsrObo}
                    disabled={signinButtonDisabled()}
                    busy={signingIn}
                    data-test-signin-button
                >
                    CSR Sign In
                </LegacyByzzerButton>
            </footer>
        </div>
    );
}

export default CSRSignIn;
