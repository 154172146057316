import './DodProductFiltersStep.scss';
import React, { ReactNode, useMemo, useState } from 'react';
import classnames from 'classnames';
import { StepRef, WizardContent, WizardStep } from '@byzzer/ui-components';
import { DodFactSetBuilder, DodFactSetBuilderProps } from '@/components/DodConfigEditor/builders/DodFactSetBuilder';
import { DodWizardActions } from '../DodWizardActions';

export type DodFactsStepProps = {
    title?: ReactNode;
    enabled?: boolean;
    exclude?: boolean;
    helpIconTip: string | ReactNode;
} & DodFactSetBuilderProps;

const baseClassName = 'dod-run-config-filters-step';

export const DodFactsStep = React.forwardRef<StepRef, DodFactsStepProps>(
    ({ className, enabled, title = 'Facts', onValidityChange, exclude, helpIconTip, ...props }, ref) => {
        const [filtersValid, setFiltersValid] = useState<boolean>(false);
        const count = useMemo<string>(() => {
            const count = props.value.reduce(
                (
                    sum,
                    {
                        yearAgo,
                        fact,
                        productSelectionsYearAgo,
                        productSelections,
                        brand,
                        brandYearAgo,
                        categoryYearAgo,
                        category,
                        changeVsYearAgo,
                        percentChangeVsYearAgo,
                    }
                ) => {
                    return (
                        sum +
                        [
                            yearAgo,
                            fact,
                            productSelectionsYearAgo,
                            productSelections,
                            brand,
                            brandYearAgo,
                            categoryYearAgo,
                            category,
                            changeVsYearAgo,
                            percentChangeVsYearAgo,
                        ].filter(Boolean).length
                    );
                },
                0
            );

            return count ? `(${count})` : '';
        }, [props.value]);

        function handleValidationChange(e: ByzzerValidityChangeEvent) {
            setFiltersValid(e.isValid);
            onValidityChange?.(e);
        }

        return (
            <WizardStep
                className={classnames(baseClassName, className)}
                ref={ref}
                enabled={enabled}
                exclude={exclude}
                id={'facts'}
                title={`${title} ${count}`}
            >
                <WizardContent>
                    <DodFactSetBuilder {...props} onValidityChange={handleValidationChange} />
                </WizardContent>
                <DodWizardActions
                    disableNext={!filtersValid}
                    nextDisabledTip={'You must choose all required values to continue.'}
                    helpIconTip={helpIconTip}
                />
            </WizardStep>
        );
    }
);
