import './ReportFilters.scss';
import React, { useState, useEffect, useRef } from 'react';
import { LegacyByzzerSelect, ByzzerDateRange, ByzzerMultiSelect, LegacyByzzerButton } from '@/components/form';
import { businessQuestions, reportTypes, reportSectionOptions } from '@/config/globalVars';
import { productSkuInfo } from '@/config/productSkuInfo.config';
import { filterReport, oneDayLess } from '@/utils';
import {useTenantApi} from '@/hooks/useTenantApi';
import TableView from '@images/icons/TableView.svg';
import ListView from '@images/icons/ListView.svg';
import TableViewEnabled from '@images/icons/TableViewEnabled.svg';
import ListViewDisabled from '@images/icons/ListViewDisabled.svg';
import moment from 'moment';
import classnames from 'classnames';
import { ToggleGroup } from '@/components/ToggleGrouo/ToggleGroup';

const baseClassName = 'report-filters';

function ReportFilters({
    onFilter,
    from,
    reportHistory,
    onChange = () => {},
    onChangeHistoryFilter = (reports) => {},
    layoutType,
    handleLayoutChange = layoutType,
    changeReport = false,
}) {
    const { getUserFavorite } = useTenantApi();
    const [bQuest, setBQuest] = useState(0);
    const [rType, setRType] = useState();
    const [historyRTypeSelected, setHistoryRTypeSelected] = useState([]);
    const [reportSection, setReportSection] = useState(0);
    const [focusCategory, setFocusCategory] = useState([]);
    const [focusBrand, setFocusBrand] = useState([]);
    const [focusMarket, setFocusMarket] = useState([]);
    const [user, setUser] = useState([]);
    const [reportName, setReportName] = useState([]);
    const [reportCategory, setReportCategory] = useState('all');
    const [timeSpan, setTimeSpan] = useState('all');
    const [dateSelected, setDateSelected] = useState(false);
    const [clearDateRange, setclearDateRange] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dupReports, setDupReports] = useState([]);
    const [period, setPeriod] = useState([]);
    const [uniqueReportTypes, setUniqueReportTypes] = useState([]);
    const byzzerDateRange = useRef();

    const layoutToggleOptions = [
        { value: 'table', display: <img src={layoutType === 'table' ? TableViewEnabled : TableView} alt="table" /> },
        { value: 'list', display: <img src={layoutType === 'list' ? ListView : ListViewDisabled} alt="list" /> },
    ];

    const timePeriodOptions = [
        { value: 'all', display: 'All' },
        { value: 'thisWeek', display: 'This Week' },
        { value: 'lastWeek', display: 'Last Week' },
        { value: 'thisMonth', display: 'This Month' },
    ];

    const reportTypeOptions = [
        { value: 'all', display: 'All' },
        { value: 'subscription', display: 'Subscription Only' },
        { value: 'adhoc', display: 'Adhoc Only' },
    ];

    useEffect(() => {
        setDupReports(reportHistory);
    }, []);

    useEffect(() => {
        filterItems();
    }, [
        dupReports,
        focusCategory,
        focusBrand,
        focusMarket,
        user,
        reportName,
        reportCategory,
        timeSpan,
        startDate,
        endDate,
        rType,
        historyRTypeSelected,
        period,
    ]);

    const getBusinessQuestions = () => {
        const options = [];
        businessQuestions.forEach((val) => {
            const optionValues = { display: val.value, value: val.key };
            options.push(optionValues);
        });
        return options;
    };

    const getReportTypes = () => {
        const options = [];
        reportTypes.forEach((val, index) => {
            const optionValues = { display: val.value, value: val.key };
            options.push(optionValues);
        });
        if (from === 'report-history') {
            let types = [];
            //update the report type option with available types based on filtered results
            reportTypes.forEach((item) => {
                if (uniqueReportTypes.includes(item.key)) {
                    types.push(item.value);
                }
            });
            return types;
        } else return options;
    };

    // common filter for subscription & adhoc report
    const onChangeFilter = async (quest, rType, section) => {
        let filteredData = [];
        if (rType === 4) {
            //filter by favorites
            let filteredData1 = filterReport(quest, 0, section)?.map((val) => {
                return val.sku;
            });
            if (from === 'subscription-reports') filteredData = await getUserFavorite('subscription');
            if (from === 'adhoc-reports') filteredData = await getUserFavorite('adhoc');
            filteredData = filteredData1.filter((item) => filteredData.includes(item));
        } else {
            filteredData = filterReport(quest, rType, section)?.map((val) => {
                return val.sku;
            });
        }
        onFilter(filteredData);
    };

    //Enable multiple filters in report history at a time
    const filterItems = async () => {
        reportHistory = dupReports;
        if (focusCategory?.length > 0) reportHistory = filterCategory(focusCategory);
        if (focusBrand?.length > 0) reportHistory = filterBrand(focusBrand);
        if (focusMarket?.length > 0) reportHistory = filterMarket(focusMarket);
        if (user?.length > 0) reportHistory = filterUser(user);
        if (reportName?.length > 0) reportHistory = filterReportName(reportName);
        if (reportCategory !== '') reportHistory = filterReportCategory(reportCategory);
        if (timeSpan !== '') reportHistory = filterTimeSpan(timeSpan);
        if (startDate !== '' || endDate !== '') reportHistory = filterDateRange(startDate, endDate);
        if (period?.length > 0) reportHistory = filterPeriod(period);
        if (historyRTypeSelected?.length > 0) {
            let skus = await filterReportType(bQuest, historyRTypeSelected);
            reportHistory = reportHistory.filter((report) => skus.includes(report?.productSku));
        }
        updateReportTypes(reportHistory);
        onChangeHistoryFilter(reportHistory);
    };

    //update report types based on filtered reports
    const updateReportTypes = async (reportHistory) => {
        let resultSku = [];
        let productSku = [];
        if (reportHistory?.length > 0) {
            Object.keys(reportHistory).forEach((i) => {
                resultSku.push(reportHistory[i].productSku);
            });
        }
        if (productSkuInfo?.length > 0) {
            Object.keys(productSkuInfo).forEach((i) => {
                productSku.push(productSkuInfo[i].sku);
            });
        }
        //pick report types with respect to common skus (resultSku is common)
        var productInfos = productSkuInfo.filter((item) => resultSku.includes(item.sku));
        var types = [];
        productInfos.forEach((item) => {
            types.push(item.type);
        });
        types.push(0); //push 'All' option

        //pick common favorites
        let favSku = await getUserFavorite('subscription');
        let fav = [];
        resultSku.forEach((item) => {
            if (favSku.includes(item)) fav.push(item);
        });
        if (fav.length > 0) {
            types.push(4);
        }
        const uniqueTypes = Array.from(new Set(types));
        setUniqueReportTypes(uniqueTypes);
    };

    //filter data for report history filters
    const getFocusCategories = () => {
        let categoryArray = [];
        reportHistory?.forEach((item) => {
            try {
                item?.actionConfig?.focusProductSelections?.competitiveProductSet?.categories?.forEach((category) => {
                    categoryArray.push(category);
                });
            } catch (err) {
                console.warn(
                    `invalid focus categories ${item.id} ${item?.actionConfig?.focusProductSelections?.competitiveProductSet?.categories}`
                );
            }
        });
        const focusCategories = Array.from(new Set(categoryArray));
        focusCategories.sort();
        focusCategories.unshift('All');
        return focusCategories;
    };

    const getFocusBrands = () => {
        let brandArray = [];
        let fBrand;
        reportHistory?.forEach((item) => {
            fBrand = item?.actionConfig?.focusProductSelections?.focusBrand;
            fBrand = Array.isArray(fBrand) ? fBrand : [fBrand];
            fBrand.forEach((brand) => {
                brandArray.push(brand);
            });
        });
        const focusBrands = Array.from(new Set(brandArray));
        focusBrands.sort();
        focusBrands.unshift('All');
        return focusBrands;
    };

    const getFocusMarkets = () => {
        let marketArray = [];
        reportHistory?.forEach((item) => {
            item?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.forEach((market) => {
                marketArray.push(market);
            });
        });
        const focusMarkets = Array.from(new Set(marketArray));
        focusMarkets.sort();
        focusMarkets.unshift('All');
        return focusMarkets;
    };

    const getUsers = () => {
        let userArray = reportHistory.map(({runBy}) => `${runBy.firstName} ${runBy.lastName}`);
        const users = Array.from(new Set(userArray));
        users.sort();
        users.unshift('All');
        return users;
    };

    const getReportNames = () => {
        let reportArray = [];
        dupReports?.forEach((item) => {
            reportArray.push(item?.product?.title);
        });
        const reportNames = Array.from(new Set(reportArray));
        reportNames.sort();
        reportNames.unshift('All');
        return reportNames;
    };

    const getPeriod = () => {
        let periodArray = [];
        dupReports?.forEach((item) => {
            periodArray.push(
                getTimePeriod(
                    item?.actionConfig?.timePeriodSelections?.timePeriod,
                    item?.actionConfig?.timePeriodSelections?.endDate,
                    item?.actionConfig?.timePeriodSelections?.selectionType,
                    item?.actionConfig?.timePeriodSelections?.weeks
                )
            );
        });
        let timePeriods = Array.from(new Set(periodArray));
        timePeriods.unshift('All');
        timePeriods = timePeriods.filter((item) => item);
        return timePeriods;
    };

    const getTimePeriod = (timePeriod, endDate, selectionType, customWeeks) => {
        let weeks;
        let lastDate;
        if (timePeriod !== undefined && timePeriod !== '' && endDate !== undefined && endDate !== '') {
            weeks = timePeriod?.substr(7, 14);
            lastDate = dateToDMY(endDate?.substr(14, 23));
            return weeks + ' ending ' + lastDate;
        } else if (timePeriod === '' && selectionType === 'custom') {
            weeks = customWeeks;
            lastDate = dateToDMY(endDate?.substr(14, 23));
            return weeks + ' Weeks ending ' + lastDate;
        } else return '';
    };

    function dateToDMY(date) {
        if (date !== undefined) {
            let date1 = date?.split('/');
            let month = date1[1];
            let day = date1[2];
            let year = date1[0];
            return oneDayLess(month + '/' + day + '/' + year);
        }
    }

    //report history filters
    const filterCategory = (categories) => {
        let reports = [];
        if (categories?.includes('All') && categories.length === 1) {
            reports = reportHistory;
        } else if (categories.length > 0) {
            categories.forEach((category) => {
                var temp = reportHistory.filter((item) => {
                    return item?.actionConfig?.focusProductSelections?.competitiveProductSet?.categories?.includes(
                        category
                    );
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    const filterBrand = (brands) => {
        let reports = [];
        if (brands?.includes('All') && brands.length === 1) {
            reports = reportHistory;
        } else if (brands.length > 0) {
            brands.forEach((brand) => {
                var temp = reportHistory.filter((item) => {
                    return item?.actionConfig?.focusProductSelections?.focusBrand?.includes(brand);
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    const filterMarket = (markets) => {
        let reports = [];
        if (markets?.includes('All') && markets.length === 1) {
            reports = reportHistory;
        } else if (markets.length > 0) {
            markets.forEach((market) => {
                var temp = reportHistory.filter((item) => {
                    return item?.actionConfig?.focusMarketSelections?.focusMarket?.byMarketName?.markets?.includes(
                        market
                    );
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    const filterReportType = async (quest, rTypes, section) => {
        let filteredData = null;
        let filteredDatas = [];
        let keys = [];
        let data1 = await getUserFavorite('subscription');

        rTypes?.forEach((rType) => {
            keys.push((reportTypes?.filter((item) => item.value === rType))[0].key);
        });
        if (keys.includes(0) && keys.length === 1) {
            let filteredData1 = filterReport(quest, 0, section)?.map((val) => {
                return val.sku;
            });
            filteredData = filteredData1?.filter((item) => filteredData?.includes(item));
            filteredDatas.push(...filteredData);
        }

        if (keys?.length > 0) {
            keys.forEach((rType) => {
                if (rType === 4) {
                    //filter by favorites
                    let filteredData1 = filterReport(quest, 0, section)?.map((val) => {
                        return val.sku;
                    });
                    filteredData = data1;
                    filteredData = filteredData1.filter((item) => filteredData?.includes(item));
                } else {
                    filteredData = filterReport(quest, rType, section)?.map((val) => {
                        return val.sku;
                    });
                }
                filteredDatas.push(...filteredData);
            });
        } else {
            let filteredData1 = filterReport(quest, 0, section)?.map((val) => {
                return val.sku;
            });
            filteredData = filteredData1?.filter((item) => filteredData?.includes(item));
            filteredDatas.push(...filteredData);
        }
        return filteredDatas;
    };

    const filterUser = (users) => {
        let reports = [];
        if (users?.includes('All') && users.length === 1) {
            reports = reportHistory;
        } else if (users.length > 0) {
            users.forEach((user) => {
                var temp = reportHistory.filter((item) => {
                    return (item?.user?.firstName + ' ' + item?.user?.lastName).includes(user);
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    const filterReportName = (reportNames) => {
        let reports = [];
        if (reportNames?.includes('All') && reportNames.length === 1) {
            reports = reportHistory;
        } else if (reportNames.length > 0) {
            reportNames.forEach((reportName) => {
                var temp = reportHistory.filter((item) => {
                    return item?.product?.title === reportName;
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    const filterTimeSpan = (timeSpan) => {
        let reports = [];
        switch (timeSpan) {
            case 'all':
                setTimeSpan('all');
                reports = reportHistory;
                break;
            case 'thisWeek':
                setTimeSpan('thisWeek');
                reports = reportHistory?.filter((item) => {
                    return moment(item?.actionDtm).isSame(new Date(), 'week');
                });
                break;
            case 'lastWeek':
                setTimeSpan('lastWeek');
                reports = reportHistory?.filter((item) => {
                    return moment(item?.actionDtm).isSame(moment(new Date()).subtract(1, 'weeks'), 'week');
                });
                break;
            case 'thisMonth':
                setTimeSpan('thisMonth');
                reports = reportHistory?.filter((item) => {
                    return moment(item?.actionDtm).isSame(new Date(), 'month');
                });
                break;
            default:
        }
        return reports;
    };

    const filterDateRange = (startDate, endDate) => {
        let reports = [];
        if (startDate === null && endDate === null) reports = reportHistory;
        else
            reports = reportHistory?.filter((item) => {
                return moment(item?.actionDtm).isBetween(startDate, endDate, 'days', '[]');
            });
        return reports;
    };

    const setDateSelectedFn = (val) => {
        setDateSelected(val);
    };

    const onChangeDateRange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
        setclearDateRange(false);
    };

    const filterReportCategory = (reportCategory) => {
        let reports = [];
        switch (reportCategory) {
            case 'all':
                setReportCategory('all');
                reports = reportHistory?.filter((item) => {
                    return (
                        item?.actionConfig?.reportCategory === 'subscription' ||
                        item?.actionConfig?.reportCategory === 'ad-hoc' ||
                        item?.actionConfig?.reportCategory === undefined
                    );
                });
                break;
            case 'subscription':
                setReportCategory('subscription');
                reports = reportHistory?.filter((item) => {
                    return item?.actionConfig?.reportCategory === 'subscription';
                });
                break;
            case 'adhoc':
                setReportCategory('adhoc');
                reports = reportHistory?.filter((item) => {
                    return item?.actionConfig?.reportCategory === 'ad-hoc';
                });
                break;
            default:
                setReportCategory('all');
                break;
        }
        return reports;
    };

    const handleReportHistoryFilter = (value, type) => {
        switch (type) {
            case 'category':
                if (value[value.length - 1] === 'All') {
                    setFocusCategory(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setFocusCategory([...value]);
                } else {
                    setFocusCategory([]);
                }
                break;
            case 'brand':
                if (value[value.length - 1] === 'All') {
                    setFocusBrand(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setFocusBrand([...value]);
                } else {
                    setFocusBrand([]);
                }
                break;
            case 'market':
                if (value[value.length - 1] === 'All') {
                    setFocusMarket(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setFocusMarket([...value]);
                } else {
                    setFocusMarket([]);
                }
                break;
            case 'user':
                if (value[value.length - 1] === 'All') {
                    setUser(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setUser([...value]);
                } else {
                    setUser([]);
                }
                break;
            case 'report':
                if (value[value.length - 1] === 'All') {
                    setReportName(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setReportName([...value]);
                } else {
                    setReportName([]);
                }
                break;
            case 'rType':
                if (value[value.length - 1] === 'All') {
                    setHistoryRTypeSelected(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setHistoryRTypeSelected([...value]);
                } else {
                    setHistoryRTypeSelected([]);
                }
                break;
            default:
                // period
                if (value[value.length - 1] === 'All') {
                    setPeriod(['All']);
                } else if (value[0] === 'All' || value.length > 0) {
                    value = value.filter((val) => val !== 'All');
                    setPeriod([...value]);
                } else {
                    setPeriod([]);
                }
                break;
        }
    };

    const filterPeriod = (timePeriods) => {
        let reports = [];
        if (timePeriods?.includes('All') && timePeriods.length === 1) {
            reports = reportHistory;
        } else if (timePeriods.length > 0) {
            timePeriods.forEach((timePeriod) => {
                var temp = reportHistory.filter((item) => {
                    //predefined time period
                    if (item?.actionConfig?.timePeriodSelections?.timePeriod !== '') {
                        return (
                            item?.actionConfig?.timePeriodSelections?.timePeriod?.substr(7)?.trim() ===
                                (timePeriod?.split(' ')[0] + ' ' + timePeriod?.split(' ')[1])?.trim() &&
                            dateToDMY(item?.actionConfig?.timePeriodSelections?.endDate?.substr(14))?.trim() ===
                                (timePeriod?.includes('Weeks')
                                    ? timePeriod?.substr(15)?.trim()
                                    : timePeriod?.includes('Date')
                                    ? timePeriod?.substr(13)?.trim()
                                    : timePeriod?.substr(14)?.trim())
                        );
                    }
                    //custom time period
                    if (
                        item?.actionConfig?.timePeriodSelections?.timePeriod === '' &&
                        item?.actionConfig?.timePeriodSelections?.selectionType === 'custom'
                    ) {
                        return (
                            item?.actionConfig?.timePeriodSelections?.weeks + ' Weeks' ===
                                (timePeriod?.split(' ')[0] + ' ' + timePeriod?.split(' ')[1])?.trim() &&
                            dateToDMY(item?.actionConfig?.timePeriodSelections?.endDate?.substr(14))?.trim() ===
                                (timePeriod?.includes('Weeks')
                                    ? timePeriod?.substr(15)?.trim()
                                    : timePeriod?.includes('Date')
                                    ? timePeriod?.substr(13)?.trim()
                                    : timePeriod?.substr(14)?.trim())
                        );
                    }
                    return false;
                });
                reports.push(...temp);
            });
        }
        return reports;
    };

    //handle clear all filters
    const onClickClear = () => {
        setFocusCategory([]);
        setFocusBrand([]);
        setFocusMarket([]);
        setHistoryRTypeSelected([]);
        setUser([]);
        setReportName([]);
        setReportCategory('all');
        setTimeSpan('all');
        setDateSelected(false);
        setStartDate('');
        setEndDate('');
        setclearDateRange(true);
        setPeriod([]);
        // byzzerDateRange?.current?.showAll();
    };

    return (
        <>
            {from !== 'report-history' && (
                <div className={baseClassName}>
                    {from === 'subscription-reports' && (
                        <LegacyByzzerSelect
                            label="Filter by section"
                            placeholder={'Select from the list'}
                            value={reportSection}
                            onChange={(e) => {
                                setReportSection(e);
                                onChangeFilter(bQuest, rType, e);
                            }}
                            options={reportSectionOptions}
                            name={'sections'}
                            enableToolTip={true}
                            allowClear={true}
                        />
                    )}
                    {from === 'adhoc-reports' && (
                        <LegacyByzzerSelect
                            label="Filter by business question"
                            placeholder={'Select from the list'}
                            value={bQuest}
                            onChange={(e) => {
                                setBQuest(e);
                                onChangeFilter(e, rType, reportSection);
                            }}
                            options={getBusinessQuestions()}
                            name={'questions'}
                            enableToolTip={true}
                            allowClear={true}
                        />
                    )}
                    <LegacyByzzerSelect
                        label="Filter by report type"
                        placeholder={'Select from the list'}
                        value={rType}
                        onChange={(e) => {
                            setRType(e);
                            onChangeFilter(bQuest, e, reportSection);
                            onChange(e);
                        }}
                        options={getReportTypes()}
                        name={'type'}
                        enableToolTip={true}
                        allowClear={true}
                    />
                    {from === 'subscription-reports' && !changeReport && (
                        <LegacyByzzerSelect
                            className={'report-filters__question'}
                            label="Filter by business question"
                            placeholder={'Select from the list'}
                            value={bQuest}
                            onChange={(e) => {
                                setBQuest(e);
                                onChangeFilter(e, rType, reportSection);
                            }}
                            options={getBusinessQuestions()}
                            name={'questions'}
                            enableToolTip={true}
                            allowClear={true}
                        />
                    )}
                </div>
            )}

            {/* report history filters */}
            {from === 'report-history' && (
                <div className={baseClassName}>
                    <ToggleGroup options={reportTypeOptions} value={reportCategory} onChange={setReportCategory} />
                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Report type'}
                        value={historyRTypeSelected}
                        selectedOptions={historyRTypeSelected}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'rType');
                        }}
                        options={getReportTypes()}
                        name={'type'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Report name'}
                        value={reportName}
                        selectedOptions={reportName}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'report');
                        }}
                        options={getReportNames()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'User'}
                        value={user}
                        selectedOptions={user}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'user');
                        }}
                        options={getUsers()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    <ByzzerDateRange
                        className={'report-history-daterange-dropdown'}
                        initialMonths={1}
                        ref={byzzerDateRange}
                        value={dateSelected}
                        placeholder="Date run"
                        onChange={onChangeDateRange}
                        clearDateRange={clearDateRange}
                    />
                    {/* <ToggleGroup options={timePeriodOptions} onChange={setTimeSpan} value={timeSpan} /> */}

                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Focus category'}
                        value={focusCategory}
                        selectedOptions={focusCategory}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'category');
                        }}
                        options={getFocusCategories()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Focus brand'}
                        value={focusBrand}
                        selectedOptions={focusBrand}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'brand');
                        }}
                        options={getFocusBrands()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Focus market'}
                        value={focusMarket}
                        selectedOptions={focusMarket}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'market');
                        }}
                        options={getFocusMarkets()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />
                    <ByzzerMultiSelect
                        className={'report-history-multiselect-dropdown'}
                        placeholder={'Period'}
                        value={period}
                        selectedOptions={period}
                        multiSelectWithCheckBox
                        onChange={(e) => {
                            handleReportHistoryFilter(e, 'period');
                        }}
                        options={getPeriod()}
                        name={'questions'}
                        enableToolTip={true}
                        // allowClear={true}
                    />

                    {/* layout & clear */}

                    {/* <ByzzerButton
                        className={`${baseClassName}__clear`}
                        type="default"
                        label={'Clear Filters'}
                        onClick={onClickClear}
                    />
                    {/* change layout */}
                    {/* <ToggleGroup options={layoutToggleOptions} onChange={handleLayoutChange} value={layoutType} /> */}
                </div>
            )}
        </>
    );
}
export default ReportFilters;
