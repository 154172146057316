





{/* TODO: Refactor this component, include adding types for props.  State needs to be broken up; doesnt need to be centralized */}









import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Tooltip, Tag, Checkbox, Switch } from 'antd';
import { LegacyByzzerButton, ByzzerModal } from '@/components/form';
import ReactHtmlParser from 'react-html-parser';
import './ProductScope.scss';
import {useTenantApi} from '@/hooks/useTenantApi';
import searchImg from '@images/search.png';
import closeIcon from '@images/icons/close-icon.svg';
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import { ByzzerRadio } from '@byzzer/ui-components';
import MinusOutlined from '@/components/icons/MinusOutlined';
import PlusOutlined from '@/components/icons/PlusOutlined';

export function ProductScopeNew({
    maxCategoryCount,
    errorMessage,
    categoryData,
    nonEditableCategories,
    productScopeType,
    confirmSubmission,
    updateProductScope,
    signUp,
    hideProductScopeModal,
    productScopeTitle,
    productScopeSubtitle,
    busy,
    displayTreeButtonLabel,
    submitButtonLabel,
    category
}: any) {
    const { lookup, search } = useTenantApi();
    const defaultState = {
        searchResult: {},
        displayTree: true,
        tagsValue: [],
        brand: [],
        treeData: [],
        searchKeyword: '',
        searchByBrand: true,
        searchByHierarchy: false,
        searchErrorEmpty: false,
        searchError: false,
        isSearchProductScopeSelected: false,
        isLoading: true,
        maxCategoryCount: maxCategoryCount,
        errorMessage: errorMessage,
        displayModal: false,
        modaltitle: '',
        modalMessage: '',
        categoryData: categoryData,
        nonEditableCategories: nonEditableCategories ?? [],
        isMultiSelect: !maxCategoryCount || maxCategoryCount > 1,
    }
    const [state, setState] = useState<any>({
        ...defaultState,
        selectedSingleCategory: category ?? ''
    })

    useEffect(() => {
        // Mapping Department data to tree structure
        let { treeData } = state;
        lookup().then(({data}) => {
            let department = data.departments;
            department.forEach((item, index) => {
                treeData.push({
                    title: item.name,
                    key: index,
                    selected: false,
                    expanded: false,
                    childrenSelected: false,
                });
            });
            setState((currState) => ({
                ...currState,
                treeData,
                isLoading: false,
            }));
        });
    }, [])


    const showSuperCategory =(index) => {
        let { treeData } = state;
        if (treeData[index].expanded) {
            treeData[index].expanded = false;
            setState((currState) => ({
                ...currState,
                treeData 
            }));
        } else {
            if (treeData[index].options === undefined) {
                setState((currState) => ({
                    ...currState,
                    isLoading: true,
                }));
                lookup({ hierarchy: `department`, value: treeData[index].title }).then(({data}) => {
                    let superCategory = data.supercategories;
                    let options: any[] = [];
                    superCategory.forEach((itemIn, indexIn) => {
                        //Pushing super categories
                        options.push({
                            title: itemIn.name,
                            popular_products:
                                itemIn.popular_products !== undefined ? itemIn.popular_products.toLowerCase() : null,
                            key: index.toString() + indexIn.toString(),
                            selected: Boolean(treeData[index].selected),
                            expanded: false,
                            childrenSelected: false,
                        });
                    });
                    treeData[index].options = options;
                    treeData[index].expanded = true;
                    setState((currState) => ({
                        ...currState,
                        treeData,
                        isLoading: false,
                    }));
                });
            } else {
                treeData[index].expanded = true;
                setState((currState) => ({
                    ...currState,
                    treeData 
                }));
            }
        }
    };

    const showCategory = (index, superCategoryIndex) => {
        let { treeData } = state;
        if (treeData[index].options[superCategoryIndex].expanded) {
            treeData[index].options[superCategoryIndex].expanded = false;
            setState((currState) => ({
                ...currState,
                treeData 
            }));
        } else {
            if (treeData[index].options[superCategoryIndex].options === undefined) {
                setState((currState) => ({
                    ...currState,
                    isLoading: true,
                }));
                lookup({
                    hierarchy: `supercategory`,
                    value: treeData[index].options[superCategoryIndex].title,
                }).then(({data}) => {
                    let category = data.categories;
                    let options: any[] = [];
                    category.forEach((itemIn, indexIn) => {
                        // const itemSelected = state.categoryData.find(val => val === itemIn.name)?.length > 0 ? true : false;
                        // nonEditableCategories was undefined and causing a black screen
                        if (Boolean(itemIn?.name) && !state?.nonEditableCategories?.includes(itemIn.name)) {
                            options.push({
                                title: itemIn.name,
                                popular_products:
                                    itemIn.popular_products !== undefined
                                        ? itemIn.popular_products.toLowerCase()
                                        : null,
                                key: index.toString() + indexIn.toString(),
                                selected: Boolean(treeData[index].options[superCategoryIndex].selected),
                                expanded: false,
                                childrenSelected: false,
                            });
                        }
                    });
                    treeData[index].options[superCategoryIndex].options = options;
                    treeData[index].options[superCategoryIndex].expanded = true;
                    setState((currState) => ({
                        ...currState,
                        treeData,
                        isLoading: false,
                    }));
                });
            } else {
                treeData[index].options[superCategoryIndex].expanded = true;
                setState((currState) => ({
                    ...currState,
                    treeData 
                }));
            }
        }
    };

    const categorySelected = (index, superCategoryIndex, categoryIndex) => {
        let { treeData } = state;
        let { tagsValue } = state;
        let tagsCount = tagsValue.length;
        treeData[index].options[superCategoryIndex].options[categoryIndex].childrenSelected = false; // Disable intermediate for selected category
        if (!state.isMultiSelect) {
            treeData[index].options[superCategoryIndex].options[categoryIndex].selected = true;
            setState((currState) => ({
                ...currState,
                selectedSingleCategory: treeData[index].options[superCategoryIndex].options[categoryIndex].title
            }))
        } else {
            treeData[index].options[superCategoryIndex].options[categoryIndex].selected = treeData[index].options[
                superCategoryIndex
            ].options[categoryIndex].selected
                ? false
                : true;
        }
        if (
            tagsCount === maxCategoryCount && state.isMultiSelect && 
            treeData[index].options[superCategoryIndex].options[categoryIndex].selected
        ) {
            treeData[index].options[superCategoryIndex].options[categoryIndex].selected = false;
            setState((currState) => ({
                ...currState,
                displayModal: true,
                modaltitle: 'Unable to select category',
                modalMessage: state.errorMessage,
            }));
        } else {
            if (treeData[index].options[superCategoryIndex].options[categoryIndex].selected) {
                let tag = {};
                if (!state.isMultiSelect) {
                    tagsValue = [];
                }
                tag = {
                    category: 'Category',
                    value: treeData[index].options[superCategoryIndex].options[categoryIndex].title,
                };
                tagsValue.push(tag);
            } else {
                tagsValue.forEach((tags, tagNumber) => {
                    if (
                        tags.category === 'Category' &&
                        tags.value === treeData[index].options[superCategoryIndex].options[categoryIndex].title
                    ) {
                        tagsValue.splice(tagNumber, 1);
                    }
                });
            }
        }
        setState((currState) => ({
            ...currState,
            treeData, 
            tagsValue 
        }));
    };

    const showSubCategory = (index, superCategoryIndex, categoryIndex) => {
        let { treeData } = state;
        if (treeData[index].options[superCategoryIndex].options[categoryIndex].expanded) {
            treeData[index].options[superCategoryIndex].options[categoryIndex].expanded = false;
            setState((currState) => ({
                ...currState,
                treeData 
            }));
        } else {
            if (treeData[index].options[superCategoryIndex].options[categoryIndex].options === undefined) {
                setState((currState) => ({
                    ...currState,
                    isLoading: true,
                }));
                lookup({
                    hierarchy: `category`,
                    value: treeData[index].options[superCategoryIndex].options[categoryIndex].title,
                }).then(({data}) => {
                    let subCategory = data.subcategories;
                    let options: any[] = [];
                    subCategory.forEach((itemIn, indexIn) => {
                        options.push({
                            title: itemIn.name,
                            popular_products:
                                itemIn.popular_products !== undefined ? itemIn.popular_products.toLowerCase() : null,
                            key:
                                index.toString() +
                                superCategoryIndex.toString() +
                                categoryIndex.toString() +
                                indexIn.toString(),
                            selected: treeData[index].options[superCategoryIndex].options[categoryIndex].selected
                                ? true
                                : false,
                            expanded: false,
                            childrenSelected: false,
                        });
                    });
                    treeData[index].options[superCategoryIndex].options[categoryIndex].options = options;
                    treeData[index].options[superCategoryIndex].options[categoryIndex].expanded = true;
                    setState((currState) => ({
                        ...currState,
                        treeData,
                        isLoading: false,
                    }));
                });
            } else {
                treeData[index].options[superCategoryIndex].options[categoryIndex].expanded = true;
                setState((currState) => ({
                    ...currState,
                    treeData 
                }));
            }
        }
    };

    const getHighlightedText = (text, highlights) => {
        // convert the array to a regular expression that looks for any word that is found in the list, regardless of case (i), over all the string (g)
        const regexp = new RegExp(`\\b(${highlights.join('|')})\\b`, 'gi');
        // replace the found words with a span that contains each word
        return text.replace(regexp, `<span style="text-decoration: underline"}>$&</span>`);
    };

    const getSearchKeywordHighlighted = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        let parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                {' '}
                {parts.map((part, i) => (
                    <span
                        key={i}
                        style={
                            part.toLowerCase() === (highlight.toLowerCase() || state.searchKeyword.toLowerCase())
                                ? { textDecoration: 'underline' }
                                : {}
                        }
                    >
                        {part}
                    </span>
                ))}{' '}
            </span>
        );
    };

    const searchItems = (e) => {
        let { searchResult } = state;

        let searchTerm = e.target.value;

        if (!searchTerm) {
            setState({
                ...defaultState,
                isLoading: false,
                tagsValue: state?.tagsValue,
                treeData: state?.treeData,
                searchByBrand: state.searchByBrand,
                searchByHierarchy: state.searchByHierarchy,
                selectedSingleCategory: state.selectedSingleCategory
            })
            return;
        }
        setState((currState) => ({
            ...currState,
            searchKeyword: searchTerm 
        }));
        setState((currState) => ({
            ...currState,
            displayTree: searchTerm.length >= 3 
        }))
        if (searchTerm.length > 2) {
            // Search only when we have atleast 3 characters to search
            setState((currState) => ({
                ...currState,
                isLoading: true 
            }));
            if (state.searchByBrand) {
                search(`brand/${searchTerm}`) // Search api - w.r.t Brand
                    .then(({data}) => {
                        // If some error occurs
                        if (Boolean(data?.error)) {
                            setState((currState) => ({
                                ...currState,
                                searchErrorEmpty: true,
                                isLoading: false,
                                searchResult: [],
                                searchError: false,
                                displayTree: false,
                            }));
                        } 
                        // If no departments found
                        else if (data.departments !== undefined && data.departments.length === 0) {
                            setState((currState) => ({
                                ...currState,
                                searchError: true,
                                isLoading: false,
                                searchResult: [],
                                searchErrorEmpty: false,
                                displayTree: false,
                            }));
                        } 
                        // If departments found
                        else {
                            searchResult = data;
                            if (Object.keys(searchResult).length > 0) {
                                if (searchResult.departments !== undefined) {
                                    // Adding isSelected to departments
                                    searchResult.departments.forEach((department) => {
                                        department.isSelected = false;
                                        department.childrenSelected = false;
                                        if (department.supercategories !== undefined) {
                                            // Creating short description for department starts
                                            let supercategoriesNameMap = [];
                                            let shortDescription: any = ``;
                                            let slicedSuperCategories = department.supercategories.slice(0, 3);
                                            supercategoriesNameMap = slicedSuperCategories.map(
                                                (supercategory, supercategoryIndex) => {
                                                    return supercategoryIndex <= 1
                                                        ? ` "${supercategory.name}"`
                                                        : ` and other super categories`;
                                                }
                                            );
                                            let slicedBrands = department.matching_brands.slice(0, 3);
                                            let brands = slicedBrands.map((brand, brandIndex) => {
                                                return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                                            });
                                            shortDescription = `contains ${[...brands]} brand in ${[
                                                ...supercategoriesNameMap,
                                            ]}`;
                                            shortDescription = getSearchKeywordHighlighted(
                                                shortDescription,
                                                state.searchKeyword
                                            );
                                            // Creating short description for department ends
                                            department.shortDescription = shortDescription;
                                            // Adding isSelected to supercategories
                                            department.supercategories.forEach((supercategory) => {
                                                supercategory.isSelected = false;
                                                supercategory.childrenSelected = false;
                                                if (supercategory.categories !== undefined) {
                                                    // Creating short description for super category starts
                                                    let categoriesNameMap = [];
                                                    shortDescription = ``;
                                                    let slicedCategories = supercategory.categories.slice(0, 3);
                                                    categoriesNameMap = slicedCategories.map(
                                                        (category, categoryIndex) => {
                                                            return categoryIndex <= 1
                                                                ? ` "${category.name}"`
                                                                : ` and other categories`;
                                                        }
                                                    );
                                                    let slicedBrands = supercategory.matching_brands.slice(0, 3);
                                                    let brands = slicedBrands.map((brand, brandIndex) => {
                                                        return brandIndex <= 1 ? ` "${brand}"` : ` and other matching`;
                                                    });
                                                    shortDescription = `contains ${[...brands]} brand in ${[
                                                        ...categoriesNameMap,
                                                    ]}`;
                                                    shortDescription = getSearchKeywordHighlighted(
                                                        shortDescription,
                                                        state.searchKeyword
                                                    );
                                                    // Creating short description for super category ends
                                                    supercategory.shortDescription = shortDescription;
                                                    // Adding isSelected to categories
                                                    supercategory.categories.forEach((category) => {
                                                        // const itemSelected = state.categoryData.find(val => val === category.name)?.length > 0 ? true : false;
                                                        category.isSelected = false;
                                                        category.childrenSelected = false;
                                                        if (category.subcategories !== undefined) {
                                                            // Creating short description for category starts
                                                            let subcategoriesNameMap = [];
                                                            shortDescription = ``;
                                                            let slicedSubcategories = category.subcategories.slice(
                                                                0,
                                                                3
                                                            );
                                                            subcategoriesNameMap = slicedSubcategories.map(
                                                                (subcategory, subcategoryIndex) => {
                                                                    return subcategoryIndex <= 1
                                                                        ? ` "${subcategory.name}"`
                                                                        : ` and other sub categories`;
                                                                }
                                                            );
                                                            let slicedBrands = category.matching_brands.slice(0, 3);
                                                            let brands = slicedBrands.map((brand, brandIndex) => {
                                                                return brandIndex <= 1
                                                                    ? ` "${brand}"`
                                                                    : ` and other matching`;
                                                            });
                                                            shortDescription = `contains ${[...brands]} brand in ${[
                                                                ...subcategoriesNameMap,
                                                            ]}`;
                                                            shortDescription = getSearchKeywordHighlighted(
                                                                shortDescription,
                                                                state.searchKeyword
                                                            );
                                                            // Creating short description for category ends
                                                            category.shortDescription = shortDescription;
                                                            // Adding isSelected to subcategories
                                                            category.subcategories.forEach((subcategory) => {
                                                                subcategory.isSelected = false;
                                                                subcategory.childrenSelected = false;
                                                                // Creating short description for subcategory starts
                                                                let slicedBrands = subcategory.matching_brands.slice(
                                                                    0,
                                                                    3
                                                                );
                                                                let brands = slicedBrands.map((brand, brandIndex) => {
                                                                    return brandIndex <= 1
                                                                        ? ` "${brand}"`
                                                                        : ` and other matching`;
                                                                });
                                                                shortDescription = `contains ${[...brands]} brand`;
                                                                shortDescription = getSearchKeywordHighlighted(
                                                                    shortDescription,
                                                                    state.searchKeyword
                                                                );
                                                                // Creating short description for subcategory ends
                                                                subcategory.shortDescription = shortDescription;
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                            setState((currState) => ({
                                ...currState,
                                isLoading: false,
                                searchResult,
                                searchError: false,
                                searchKeyword: searchTerm,
                                searchErrorEmpty: false,
                                displayTree: false,
                            }));
                        }
                    });
            } else {
                search(`hierarchy/${searchTerm}`) // Search api - w.r.t Hierarchy
                    .then(({data}) => {
                        if (Boolean(data?.error)) {
                            setState((currState) => ({
                                ...currState,
                                searchErrorEmpty: true,
                                isLoading: false,
                                searchResult: [],
                                searchError: false,
                                displayTree: false,
                            }));
                        } else if (data.departments.length === 0) {
                            setState((currState) => ({
                                ...currState,
                                searchError: true,
                                isLoading: false,
                                searchResult: [],
                                searchErrorEmpty: false,
                                displayTree: false,
                            }));
                        } else {
                            searchResult = data;
                            if (Object.keys(searchResult).length > 0) {
                                if (searchResult.departments !== undefined) {
                                    // Adding isSelected to departments
                                    searchResult.departments.forEach((department) => {
                                        department.isSelected = false;
                                        department.childrenSelected = false;
                                        searchResult.synonyms.push(state.searchKeyword);
                                        if (searchResult.synonyms !== undefined && searchResult.synonyms.length > 0) {
                                            department.displayName = getHighlightedText(
                                                department.name,
                                                searchResult.synonyms
                                            );
                                        }
                                        if (department.popular_products !== undefined) {
                                            department.popular_products =
                                                department.popular_products !== undefined
                                                    ? department.popular_products.toLowerCase()
                                                    : null;
                                        }
                                        if (department.supercategories !== undefined) {
                                            // Adding isSelected to supercategories
                                            department.supercategories.forEach((supercategory) => {
                                                supercategory.isSelected = false;
                                                supercategory.childrenSelected = false;
                                                searchResult.synonyms.push(state.searchKeyword);
                                                if (
                                                    searchResult.synonyms !== undefined &&
                                                    searchResult.synonyms.length > 0
                                                ) {
                                                    supercategory.displayName = getHighlightedText(
                                                        supercategory.name,
                                                        searchResult.synonyms
                                                    );
                                                }
                                                if (supercategory.popular_products !== undefined) {
                                                    supercategory.popular_products =
                                                        supercategory.popular_products !== undefined
                                                            ? supercategory.popular_products.toLowerCase()
                                                            : null;
                                                }
                                                if (supercategory.categories !== undefined) {
                                                    // Adding isSelected to categories
                                                    supercategory.categories.forEach((category) => {
                                                        category.isSelected = false;
                                                        category.childrenSelected = false;
                                                        searchResult.synonyms.push(state.searchKeyword);
                                                        if (
                                                            searchResult.synonyms !== undefined &&
                                                            searchResult.synonyms.length > 0
                                                        ) {
                                                            category.displayName = getHighlightedText(
                                                                category.name,
                                                                searchResult.synonyms
                                                            );
                                                        }
                                                        if (category.popular_products !== undefined) {
                                                            category.popular_products =
                                                                category.popular_products !== undefined
                                                                    ? category.popular_products.toLowerCase()
                                                                    : null;
                                                        }
                                                        if (category.subcategories !== undefined) {
                                                            // Adding isSelected to subcategories
                                                            category.subcategories.forEach((subcategory) => {
                                                                subcategory.isSelected = false;
                                                                subcategory.childrenSelected = false;
                                                                searchResult.synonyms.push(state.searchKeyword);
                                                                if (
                                                                    searchResult.synonyms !== undefined &&
                                                                    searchResult.synonyms.length > 0
                                                                ) {
                                                                    subcategory.displayName = getHighlightedText(
                                                                        subcategory.name,
                                                                        searchResult.synonyms
                                                                    );
                                                                }
                                                                if (subcategory.popular_products !== undefined) {
                                                                    subcategory.popular_products =
                                                                        subcategory.popular_products !== undefined
                                                                            ? subcategory.popular_products.toLowerCase()
                                                                            : null;
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                    });
                                }
                            }
                            setState((currState) => ({
                                ...currState,
                                isLoading: false,
                                searchResult,
                                searchError: false,
                                searchKeyword: searchTerm,
                                searchErrorEmpty: false,
                                displayTree: false,
                            }));
                        }
                    });
            }
        }
    };

    const onTagClosed = (index) => {
        let { tagsValue } = state;
        let { treeData } = state;
        let hierarchyClosed = tagsValue[index].category;
        let valueClosed = tagsValue[index].value;
        let selectedTreeIndex = '';
        let selectedSuperCategoryIndex = '';
        let selectedCategoryIndex = '';
        setState((currState) => ({
            ...currState,
            // Commenting this out since it causes the radio/check box to be unselected when this modal is reopened
            // selectedSingleCategory: '' 
        }));
        if (hierarchyClosed === 'Department') {
            treeData.forEach((treeTags, treeIndex) => {
                if (treeTags.title === valueClosed) {
                    treeTags.selected = false;
                    if (treeTags.options !== undefined) {
                        treeTags.options.forEach((superCategories) => {
                            superCategories.selected = false;
                            if (superCategories.options !== undefined) {
                                superCategories.options.forEach((categories) => {
                                    categories.selected = false;
                                    if (categories.options !== undefined) {
                                        categories.options.forEach((subCategories) => {
                                            subCategories.selected = false;
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
        } else if (hierarchyClosed === 'Super-Category') {
            treeData.forEach((treeTags, treeIndex) => {
                if (treeTags.options !== undefined) {
                    treeTags.options.forEach((superCategories) => {
                        if (superCategories.title === valueClosed) {
                            selectedTreeIndex = treeIndex;
                            superCategories.selected = false;
                            if (superCategories.title === valueClosed) {
                                if (superCategories.options !== undefined) {
                                    superCategories.options.forEach((categories) => {
                                        categories.selected = false;
                                        if (categories.options !== undefined) {
                                            categories.options.forEach((subCategories) => {
                                                subCategories.selected = false;
                                            });
                                        }
                                    });
                                }
                            }
                        }
                    });
                }
            });
            // Intermediate state of Department
            let supercategoryCount = treeData[selectedTreeIndex].options.length;
            treeData[selectedTreeIndex].options.forEach((supercategory) => {
                if (!supercategory.selected) {
                    supercategoryCount--;
                }
            });
            if (supercategoryCount === 0) {
                treeData[selectedTreeIndex].childrenSelected = false;
            }
        } else if (hierarchyClosed === 'Category') {
            treeData.forEach((treeTags, treeIndex) => {
                if (treeTags.options !== undefined) {
                    treeTags.options.forEach((superCategories, supercategoryIndex) => {
                        if (superCategories.options !== undefined) {
                            superCategories.options.forEach((categories) => {
                                if (categories.title === valueClosed) {
                                    selectedTreeIndex = treeIndex;
                                    selectedSuperCategoryIndex = supercategoryIndex;
                                    categories.selected = false;
                                    if (categories.options !== undefined) {
                                        categories.options.forEach((subCategories) => {
                                            subCategories.selected = false;
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            });
            // Intermediate state of Supercategory and Department
            let categoryCount = treeData[selectedTreeIndex]?.options[selectedSuperCategoryIndex]?.options?.length;
            treeData[selectedTreeIndex]?.options[selectedSuperCategoryIndex]?.options?.forEach((category) => {
                if (!category.selected) {
                    categoryCount--;
                }
            });
            if (categoryCount === 0) {
                treeData[selectedTreeIndex].childrenSelected = false;
                treeData[selectedTreeIndex].selected = false;
                treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].childrenSelected = false;
                treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].selected = false;
            }
        } else if (hierarchyClosed === 'Sub-Category') {
            treeData.forEach((treeTags, treeIndex) => {
                if (treeTags.options !== undefined) {
                    treeTags.options.forEach((superCategories, supercategoryIndex) => {
                        superCategories.selected = false;
                        if (superCategories.options !== undefined) {
                            superCategories.options.forEach((categories, categoryIndex) => {
                                categories.selected = false;
                                if (categories.options !== undefined) {
                                    categories.options.forEach((subCategories) => {
                                        if (subCategories.title === valueClosed) {
                                            selectedTreeIndex = treeIndex;
                                            selectedSuperCategoryIndex = supercategoryIndex;
                                            selectedCategoryIndex = categoryIndex;
                                            subCategories.selected = false;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            // Intermediate state of Category, Supercategory and Department
            let subcategoryCount =
                treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].options[selectedCategoryIndex]
                    .options.length;
            treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].options[
                selectedCategoryIndex
            ].options.forEach((subcategory) => {
                if (!subcategory.selected) {
                    subcategoryCount--;
                }
            });
            if (subcategoryCount === 0) {
                treeData[selectedTreeIndex].childrenSelected = false;
                treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].childrenSelected = false;
                treeData[selectedTreeIndex].options[selectedSuperCategoryIndex].options[
                    selectedCategoryIndex
                ].childrenSelected = false;
            }
        }
        let heirarchy = tagsValue[index].category;
        if (heirarchy === 'Sub-Category') {
            heirarchy = 'subCategory';
        } else if (heirarchy === 'Brand High') {
            heirarchy = 'brand';
        }
        tagsValue.splice(index, 1);
        setState((currState) => ({
            ...currState,
            tagsValue, 
            treeData 
        }));
    };

    const searchCategorySelected = (index, index2, index3) => {
        let { searchResult } = state;
        let { tagsValue } = state;
        let { treeData } = state;
        let tagsCount = tagsValue.length;
        let isSearchProductScopeSelected = state.isSearchProductScopeSelected;
        let popular_products = '';
        let selectedDepartment = searchResult.departments[index].name;
        let selectedSuperCategory = searchResult.departments[index].supercategories[index2].name;
        let selectedCategory = searchResult.departments[index].supercategories[index2].categories[index3].name;
        if (!state.isMultiSelect) {
            searchResult.departments[index].supercategories[index2].categories[index3].isSelected = true;
            setState((currState) => ({
                ...currState,
                selectedSingleCategory : searchResult.departments[index].supercategories[index2].categories[index3].name
            }))
        } else {
            searchResult.departments[index].supercategories[index2].categories[index3].isSelected = searchResult
                .departments[index].supercategories[index2].categories[index3].isSelected
                ? false
                : true; // Marking selected category as selected
        }
        searchResult.departments[index].supercategories[index2].categories[index3].childrenSelected = false;
        if (
            tagsCount === maxCategoryCount && state.isMultiSelect && 
            searchResult.departments[index].supercategories[index2].categories[index3].isSelected
        ) {
            searchResult.departments[index].supercategories[index2].categories[index3].isSelected = false;
            setState((currState) => ({
                ...currState,
                displayModal: true,
                modaltitle: 'Unable to select category',
                modalMessage: state.errorMessage,
            }));
        } else {
            // Marking selected true in search results
            searchResult.departments.forEach((department) => {
                department.supercategories.forEach((supercategory) => {
                    supercategory.categories.forEach((category) => {
                        if (category.name.toString().toLowerCase() === selectedCategory.toString().toLowerCase()) {
                            category.isSelected =
                                searchResult.departments[index].supercategories[index2].categories[index3].isSelected;
                            category.childrenSelected = false;
                            popular_products =
                                category.popular_products !== undefined
                                    ? category.popular_products.toLowerCase()
                                    : null;
                            if (category.subcategories !== undefined) {
                                category.subcategories.forEach((subcategory) => {
                                    subcategory.isSelected =
                                        searchResult.departments[index].supercategories[index2].categories[
                                            index3
                                        ].isSelected; // Marking all subcategory under selected Category
                                    subcategory.childrenSelected = false;
                                });
                            }
                        }
                    });
                });
            });
            //Creating a tag
            let tag = {
                category: 'Category',
                value: selectedCategory,
            };
            if (!state.isMultiSelect) {
                tagsValue = [];
            }
            //Pushing selected category into tags or removing from tags
            if (searchResult.departments[index].supercategories[index2].categories[index3].isSelected) {
                let isAlreadyPresent = false;
                tagsValue.forEach((tag) => {
                    if (tag.category === 'Category' && tag.value === selectedCategory) {
                        isAlreadyPresent = true;
                    }
                });
                if (!isAlreadyPresent) {
                    tagsValue.push(tag);
                }
            } else {
                tagsValue.forEach((tags, tagNumber) => {
                    if (tags.category === 'Category' && tags.value === selectedCategory) {
                        tagsValue.splice(tagNumber, 1);
                    }
                });
            }
            // Marking the selected super category as checked in Tree structure starts
            // Calling lookup API to get supercategories
            lookup({
                hierarchy: `department`,
                value: selectedDepartment,
            }).then(({data}) => {
                let superCategory = data.supercategories;
                let options: any[] = [];
                superCategory.forEach((itemIn, indexIn) => {
                    //Pushing super categories
                    options.push({
                        title: itemIn.name,
                        key: index.toString() + indexIn.toString(),
                        selected: false,
                        expanded: false,
                    });
                });
                // Marking the selected department as checked in Tree structure
                treeData.forEach((department) => {
                    if (department.title === selectedDepartment) {
                        department.options = options;
                        department.expanded = true;
                        department.childrenSelected = true;
                    }
                });
                // Calling lookup API to get categories
                lookup({
                    hierarchy: `supercategory`,
                    value: selectedSuperCategory,
                }).then(({data}) => {
                    let category = data.categories;
                    let options: any[] = [];
                    let isSelected = false;
                    category.forEach((itemIn, indexIn) => {
                        // Parsing tagsValue array to find the categories selected already
                        tagsValue.forEach((tag) => {
                            if (tag.category === 'Category' && itemIn.name === tag.value) {
                                isSelected = true;
                            }
                        });
                        options.push({
                            title: itemIn.name,
                            key: index.toString() + indexIn.toString(),
                            selected: isSelected,
                            expanded: false,
                            popular_products: popular_products !== undefined ? popular_products : null,
                        });
                        isSelected = false;
                    });
                    // Marking the selected category as checked in Tree structure
                    treeData.forEach((department) => {
                        if (department.title === selectedDepartment) {
                            department.options.forEach((superCategory) => {
                                if (superCategory.title === selectedSuperCategory) {
                                    superCategory.options = options;
                                    superCategory.expanded = true;
                                    superCategory.childrenSelected = true;
                                }
                            });
                        }
                    });
                });
            });
            // Checking search values selected
            let countofmarkersearch = 0;
            searchResult.departments.forEach((department) => {
                if (department !== undefined) {
                    countofmarkersearch++;
                    if (department.supercategories !== undefined) {
                        department.supercategories.forEach((supercategory) => {
                            if (supercategory.isSelected || supercategory.childrenSelected) {
                                countofmarkersearch++;
                                if (supercategory.categories !== undefined) {
                                    supercategory.categories.forEach((category) => {
                                        if (category.isSelected || category.childrenSelected) {
                                            countofmarkersearch++;
                                            if (category.subcategories !== undefined) {
                                                category.subcategories.forEach((subcategory) => {
                                                    if (subcategory.isSelected || subcategory.childrenSelected) {
                                                        countofmarkersearch++;
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
            if (countofmarkersearch > 0) {
                isSearchProductScopeSelected = true;
            } else {
                isSearchProductScopeSelected = false;
            }
        }
        setState((currState) => ({
            ...currState,
            searchResult, tagsValue, treeData, isSearchProductScopeSelected 
        }));
    };

    const onSwitchChange = () => {
        let searchByBrand = state.searchByBrand;
        let searchByHierarchy = state.searchByBrand;
        searchByBrand = searchByBrand ? false : true;
        searchByHierarchy = searchByBrand ? false : true;
        setState((currState) => ({
            ...currState,
            searchByBrand,
            searchByHierarchy,
            searchKeyword: '',
            searchResult: [],
            displayTree: true,
            tags: [],
        }));
    };

    const submitDataContext = () => {
        let { tagsValue } = state;
        let categories: any[] = [];
        tagsValue.forEach((tag) => {
            if (tag.category === 'Category') {
                categories.push(tag.value);
            }
        });
        //updateProductScope(categories);
        if (productScopeType === 'Product Coding Form') {
            onTagClosed(0);
        }
        if (signUp) {
            confirmSubmission(categories);
        } else {
            updateProductScope(categories);
        }
    };

    const submitProductScopeSearch = () => {
        let { tagsValue } = state;
        let categories: any[] = [];
        if (productScopeType === 'Product Coding Form') {
            tagsValue.forEach((tag) => {
                if (tag.category === 'Category') {
                    categories.push(tag.value);
                }
            });
            updateProductScope(categories);
            onTagClosed(0);
        } 
        else if (productScopeType === 'Panel Releasability'){
            tagsValue.forEach((tag) => {
                if (tag.category === 'Category') {
                    categories.push(tag.value);
                }
            });
            updateProductScope(categories);
        }
        else {
            setState((currState) => ({
                ...currState,
                displayTree: true,
                searchKeyword: '',
                isSearchProductScopeSelected: false,
            }));
        }
    };
    const enableshowModal = () => {
        setState((currState) => ({
            ...currState,
            displayModal: false,
        }));
    };

    return (
        <div className="byzzer-component__product-scope-content-container">
            {!signUp && (
                <div style={{ display: 'flex', width: '60vw', paddingRight: '1vw', justifyContent: 'flex-end' }}>
                    {hideProductScopeModal && (
                        <img
                            src={closeIcon}
                            alt="close"
                            onClick={() => hideProductScopeModal()}
                            style={{ cursor: 'pointer', height: '16px', width: '16px' }}
                        />
                    )}
                </div>
            )}
            <ByzzerMask loading={state.isLoading}/>
            <div className="byzzer-component__product-scope-content-question">
                {productScopeTitle ? productScopeTitle : 'Which categories matter to you?'}
            </div>
            <div className="byzzer-component__product-scope-content-answers-label">
                {productScopeSubtitle
                    ? productScopeSubtitle
                    : 'This will set up the category scope for all users on your team.'}
            </div>
            <div className="byzzer-component__product-scope-content-search">
                <img
                    src={searchImg}
                    alt="search"
                    style={{ height: '17px', paddingRight: '0.5vw', paddingLeft: '0.5vw' }}
                />
                <DebounceInput
                    minLength={2}
                    maxLength={20}
                    debounceTimeout={1000}
                    onChange={searchItems}
                    className="byzzer-component__product-scope-content-search-input"
                    placeholder={signUp ? 'Choose a category' : 'Search'}
                    value={state.searchKeyword}
                />
                {/* <img src={speaker} alt="speaker" style={{ height: '70%', paddingRight: '0.5vw', paddingLeft: '0.5vw' }} /> */}
            </div>
            <div className="byzzer-component__product-scope-content-toggle">
                <span className="byzzer-component__product-scope-content-toggle-heading">Search by :</span>
                <span className="byzzer-component__product-scope-content-toggle-options">Hierarchy</span>
                <Switch
                    checked={state.searchByBrand}
                    size="small"
                    onChange={onSwitchChange}
                    style={{ marginRight: '0.5vw', marginLeft: '0.5vw' }}
                />
                <span className="byzzer-component__product-scope-content-toggle-options">Brand</span>
            </div>
            <div
                className="byzzer-component__product-scope-content-tree-select"
                style={{ marginBottom: state.displayTree ? '2vh' : 0 }}
            >
                <div className="byzzer-component__product-scope-content-tree-select-component">
                    {state.displayTree && (
                        <div className="byzzer-component__product-scope-content-tree-container">
                            <div className="byzzer-component__product-scope-content-search-results-tags">
                                {state?.tagsValue?.map((item, index) => {
                                    return (
                                        <Tag
                                            key={index + 1}
                                            icon={<span className="byzzer-component__tag-close-icon" onClick={() => onTagClosed(index)}></span>}
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <span
                                                    style={{
                                                        display: 'flex',
                                                        flex: 1,
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    {item.category.toLowerCase()}
                                                </span>
                                                :&nbsp;
                                                <span
                                                    style={{
                                                        display: 'block',
                                                        width: '30vw',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                >
                                                    {item.value}
                                                </span>
                                            </div>
                                        </Tag>
                                    );
                                })}
                            </div>
                            {state?.treeData?.length > 0 &&
                                state?.treeData?.map((item, index) => {
                                    return (
                                        <div
                                            key={index + 1}
                                            className="byzzer-component__product-scope-content-tree-levels"
                                        >
                                            <div
                                                style={{ color: item.selected ? '#000000' : '#707070' }}
                                                className="byzzer-component__product-scope-content-tree-lvl1 himanshu1"
                                            >
                                                <span
                                                    onClick={() => showSuperCategory(index)}
                                                    style={{ display: 'flex', cursor: 'pointer', padding: '0.5vw' }}
                                                >
                                                    {item.expanded ? <MinusOutlined /> : <PlusOutlined />}
                                                </span>
                                                <Tooltip placement="bottomRight" title={item.popular_products}>
                                                    <span style={{ display: 'flex', padding: '0.5vw' }}>
                                                        {item.title}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            {item.options && item.expanded && (
                                                <div
                                                    key={1}
                                                    style={{ color: item.selected ? '#000000' : '#707070' }}
                                                    className="byzzer-component__product-scope-content-tree-lvl2-container"
                                                >
                                                    {item.options.map((superCategories, superCategoryIndex) => {
                                                        return (
                                                            <div key={superCategoryIndex + 1}>
                                                                <div
                                                                    className="byzzer-component__product-scope-content-tree-lvl2 himanshu2"
                                                                    style={{
                                                                        color: superCategories.selected
                                                                            ? '#000000'
                                                                            : '#707070',
                                                                    }}
                                                                >
                                                                    <span
                                                                        onClick={() =>
                                                                            showCategory(
                                                                                index,
                                                                                superCategoryIndex
                                                                            )
                                                                        }
                                                                        style={{
                                                                            display: 'flex',
                                                                            cursor: 'pointer',
                                                                            padding: '0.5vw',
                                                                        }}
                                                                    >
                                                                        {superCategories.expanded ? (
                                                                            <MinusOutlined />
                                                                        ) : (
                                                                            <PlusOutlined />
                                                                        )}
                                                                    </span>
                                                                    <Tooltip
                                                                        placement="bottomRight"
                                                                        title={superCategories.popular_products}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                display: 'flex',
                                                                                padding: '0.5vw',
                                                                            }}
                                                                        >
                                                                            {superCategories.title}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                {superCategories.options &&
                                                                    superCategories.expanded && (
                                                                        <div>
                                                                            <div
                                                                                style={{
                                                                                    color: superCategories.selected
                                                                                        ? '#000000'
                                                                                        : '#707070',
                                                                                }}
                                                                                className="byzzer-component__product-scope-content-tree-lvl3-container"
                                                                            >
                                                                                {superCategories.options.map(
                                                                                    (categories, categoryIndex) => {
                                                                                        return (
                                                                                            <div
                                                                                                key={
                                                                                                    categoryIndex +
                                                                                                    1
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className="byzzer-component__product-scope-content-tree-lvl3 himanshu3"
                                                                                                    style={{
                                                                                                        color: categories.selected
                                                                                                            ? '#000000'
                                                                                                            : '#707070',
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        onClick={() =>
                                                                                                            showSubCategory(
                                                                                                                index,
                                                                                                                superCategoryIndex,
                                                                                                                categoryIndex
                                                                                                            )
                                                                                                        }
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'flex',
                                                                                                            cursor:
                                                                                                                'pointer',
                                                                                                            padding:
                                                                                                                '0.5vw',
                                                                                                        }}
                                                                                                    >
                                                                                                        {categories.expanded ? (
                                                                                                            <MinusOutlined />
                                                                                                        ) : (
                                                                                                            <PlusOutlined />
                                                                                                        )}
                                                                                                    </span>
                                                                                                    {!state.isMultiSelect ? (
                                                                                                    <ByzzerRadio name={'productScopeCategory'}
                                                                                                        value={categories.title}
                                                                                                        key={index + superCategoryIndex + categoryIndex}
                                                                                                        onChange={() =>
                                                                                                            categorySelected(
                                                                                                                index,
                                                                                                                superCategoryIndex,
                                                                                                                categoryIndex
                                                                                                            )
                                                                                                        }
                                                                                                        checked={categories.title === state.selectedSingleCategory}
                                                                                                        />
                                                                                                    ) : (<Checkbox
                                                                                                        style={{
                                                                                                            display:
                                                                                                                'flex',
                                                                                                        }}
                                                                                                        onChange={() =>
                                                                                                            categorySelected(
                                                                                                                index,
                                                                                                                superCategoryIndex,
                                                                                                                categoryIndex
                                                                                                            )
                                                                                                        }
                                                                                                        indeterminate={
                                                                                                            categories.childrenSelected
                                                                                                        }
                                                                                                        checked={
                                                                                                            categories.selected
                                                                                                        }
                                                                                                    /> )}
                                                                                                    <Tooltip
                                                                                                        placement="bottomRight"
                                                                                                        title={
                                                                                                            categories.popular_products
                                                                                                        }
                                                                                                    >
                                                                                                        <span className='byzzer-component__product-scope-content-tree-lvl3-tip'>
                                                                                                            {
                                                                                                                categories.title
                                                                                                            }
                                                                                                        </span>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                                {categories.options &&
                                                                                                    categories.expanded && (
                                                                                                        <div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    color: categories.selected
                                                                                                                        ? '#000000'
                                                                                                                        : '#707070',
                                                                                                                }}
                                                                                                                className="byzzer-component__product-scope-content-tree-lvl4-container"
                                                                                                            >
                                                                                                                {categories.options.map(
                                                                                                                    (
                                                                                                                        subCategories,
                                                                                                                        subCategoryIndex
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <div
                                                                                                                                key={
                                                                                                                                    subCategoryIndex +
                                                                                                                                    1
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <div
                                                                                                                                    className="byzzer-component__product-scope-content-tree-lvl4"
                                                                                                                                    style={{
                                                                                                                                        color: subCategories.selected
                                                                                                                                            ? '#000000'
                                                                                                                                            : '#707070',
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <Tooltip
                                                                                                                                        placement="bottomRight"
                                                                                                                                        title={
                                                                                                                                            subCategories.popular_products
                                                                                                                                        }
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                display:
                                                                                                                                                    'flex',
                                                                                                                                                padding:
                                                                                                                                                    '0.5vw',
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            {
                                                                                                                                                subCategories.title
                                                                                                                                            }
                                                                                                                                        </span>
                                                                                                                                    </Tooltip>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    )}
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
            {!state.displayProducts && !state.displayTree && (
                <div className="byzzer-component__product-scope-content-search-results">
                    {state.searchError && !state.searchErrorEmpty && (
                        <div className="byzzer-component__product-scope-content-search-results-error">
                            <span className="byzzer-component__product-scope-content-search-results-error-main">
                                No match found.
                            </span>
                            <span className="byzzer-component__product-scope-content-search-results-error-sub">
                                Please refine your search.
                            </span>
                        </div>
                    )}
                    {state.searchErrorEmpty && !state.searchError && (
                        <div className="byzzer-component__product-scope-content-search-results-error">
                            <span className="byzzer-component__product-scope-content-search-results-error-main">
                                Your search generated a lot of results.
                            </span>
                            <span className="byzzer-component__product-scope-content-search-results-error-sub">
                                Please refine your search.
                            </span>
                        </div>
                    )}
                    {Boolean(state.searchResult?.has_more_hits) && (
                        <div className="byzzer-component__product-scope-content-search-results-has-more-hits">
                            <span className="byzzer-component__product-scope-content-search-results-error-main">
                                Your search generated a lot of results, We are showing the top results.
                            </span>
                            <span className="byzzer-component__product-scope-content-search-results-error-sub">
                                Refine your search if your {state.searchByHierarchy ? 'hierarchy' : 'product'}{' '}
                                is not listed.
                            </span>
                        </div>
                    )}
                    {Boolean(state?.searchResult) && Object.keys(state.searchResult).length > 0 &&
                        state.searchResult?.departments !== undefined &&
                        state?.searchResult?.departments?.map((item, index) => {
                            return (
                                <div
                                    key={index + 1}
                                    style={{
                                        marginRight: '1.5vw',
                                        marginLeft: '1.5vw',
                                        paddingBottom: '1.5vh',
                                        paddingTop: '1.5vh',
                                        borderBottom:
                                            index === state.searchResult?.departments.length - 1
                                                ? 'none'
                                                : '1px solid #707070',
                                    }}
                                >
                                    <div
                                        style={{ color: item.isSelected ? '#000000' : '#707070' }}
                                        className="byzzer-component__product-scope-content-search-results-department-container"
                                    >
                                        <div className="byzzer-component__product-scope-content-search-results-department-holder">
                                            <div className="byzzer-component__product-scope-content-search-results-department-value">
                                                <div className="byzzer-component__product-scope-content-search-results-department-name">
                                                    <Tooltip placement="bottomRight" title={item.popular_products}>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap: 'wrap',
                                                                wordBreak: 'break-word',
                                                                width: 'auto',
                                                            }}
                                                        >
                                                            {item.displayName !== undefined
                                                                ? ReactHtmlParser(item.displayName)
                                                                : item.name}
                                                        </span>
                                                    </Tooltip>
                                                    <div className="byzzer-component__product-scope-content-search-results-department-label">
                                                        Department
                                                    </div>
                                                </div>
                                            </div>
                                            {item.matching_brands !== undefined && item.matching_brands.length > 0 && (
                                                <div className="byzzer-component__product-scope-content-search-results-department-description">
                                                    {item.shortDescription.length > 60 ? (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                flexWrap: 'wrap',
                                                                wordBreak: 'break-word',
                                                            }}
                                                        >
                                                            {`${item.shortDescription.toString().substring(0, 60)}`}
                                                            <span>more...</span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                flexWrap: 'wrap',
                                                                wordBreak: 'break-word',
                                                            }}
                                                        >
                                                            {item.shortDescription}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {item.supercategories !== undefined &&
                                        item.supercategories.length > 0 &&
                                        item.supercategories.map((item2, index2) => {
                                            return (
                                                <div
                                                    key={index2}
                                                    style={{ color: item2.isSelected ? '#000000' : '#707070' }}
                                                    className="byzzer-component__product-scope-content-search-results-supercategory-container"
                                                >
                                                    <div className="byzzer-component__product-scope-content-search-results-supercategory-holder">
                                                        <div className="byzzer-component__product-scope-content-search-results-supercategory-value">
                                                            <div className="byzzer-component__product-scope-content-search-results-supercategory-name">
                                                                <Tooltip
                                                                    placement="bottomRight"
                                                                    title={item2.popular_products}
                                                                >
                                                                    <span
                                                                        className='byzzer-component__product-scope-content-search-results-supercategory-spacing'>
                                                                        {item2.displayName !== undefined
                                                                            ? ReactHtmlParser(item2.displayName)
                                                                            : item2.name}
                                                                    </span>
                                                                </Tooltip>
                                                                <div className="byzzer-component__product-scope-content-search-results-supercategory-label">
                                                                    Super Category
                                                                </div>
                                                            </div>
                                                            {item2.matching_brands !== undefined &&
                                                                item2.matching_brands.length > 0 && (
                                                                    <div className="byzzer-component__product-scope-content-search-results-supercategory-description">
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'row',
                                                                                flexWrap: 'wrap',
                                                                                wordBreak: 'break-word',
                                                                            }}
                                                                        >
                                                                            {item2.shortDescription}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                    {item2.categories !== undefined &&
                                                        item2.categories.length > 0 &&
                                                        item2.categories.map((item3, index3) => {
                                                            if (
                                                                Boolean(item3?.name) && !state?.nonEditableCategories?.includes(
                                                                    item3?.name
                                                                )
                                                            )
                                                                return (
                                                                    <div
                                                                        key={index3}
                                                                        style={{
                                                                            color: item3.isSelected
                                                                                ? '#000000'
                                                                                : '#707070',
                                                                        }}
                                                                        className="byzzer-component__product-scope-content-search-results-category-container"
                                                                    >
                                                                        <div className="byzzer-component__product-scope-content-search-results-category-holder">
                                                                            <div className="byzzer-component__product-scope-content-search-results-category-value">
                                                                                <div className="byzzer-component__product-scope-content-search-results-category-name">
                                                                                {!state.isMultiSelect ? (
                                                                                    <ByzzerRadio name={'productScopeCategory'}
                                                                                        value={item3.name}
                                                                                        key={index + index2 + index3}
                                                                                        onChange={() =>
                                                                                            searchCategorySelected(
                                                                                                index,
                                                                                                index2,
                                                                                                index3
                                                                                            )
                                                                                        }
                                                                                        checked={item3.name === state.selectedSingleCategory}
                                                                                        />
                                                                                    ) : (
                                                                                    <Checkbox
                                                                                        onClick={() =>
                                                                                            searchCategorySelected(
                                                                                                index,
                                                                                                index2,
                                                                                                index3
                                                                                            )
                                                                                        }
                                                                                        indeterminate={
                                                                                            item3.childrenSelected
                                                                                        }
                                                                                        checked={item3.isSelected}
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            width: '1.5vw',
                                                                                        }}
                                                                                    />)}
                                                                                    <Tooltip
                                                                                        placement="bottomRight"
                                                                                        title={
                                                                                            item3.popular_products
                                                                                        }
                                                                                    >
                                                                                        <span className="byzzer-component__product-scope-content-search-results-category-name-label">
                                                                                            {item3.displayName !==
                                                                                            undefined
                                                                                                ? ReactHtmlParser(
                                                                                                        item3.displayName
                                                                                                    )
                                                                                                : item3.name}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                    <div className="byzzer-component__product-scope-content-search-results-category-label">
                                                                                        Category
                                                                                    </div>
                                                                                </div>
                                                                                {item3.matching_brands !==
                                                                                    undefined &&
                                                                                    item3.matching_brands.length >
                                                                                        0 && (
                                                                                        <div className="byzzer-component__product-scope-content-search-results-category-description">
                                                                                            <div
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    flexDirection:
                                                                                                        'row',
                                                                                                    flexWrap:
                                                                                                        'wrap',
                                                                                                    wordBreak:
                                                                                                        'break-word',
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    item3.shortDescription
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                        {item3.subcategories !== undefined &&
                                                                            item3.subcategories.length > 0 &&
                                                                            item3.subcategories.map(
                                                                                (item4, index4) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={index4}
                                                                                            style={{
                                                                                                color: item4.isSelected
                                                                                                    ? '#000000'
                                                                                                    : '#707070',
                                                                                                paddingLeft: '25px',
                                                                                            }}
                                                                                            className="byzzer-component__product-scope-content-search-results-subcategory-container"
                                                                                        >
                                                                                            <div className="byzzer-component__product-scope-content-search-results-subcategory-holder">
                                                                                                <div className="byzzer-component__product-scope-content-search-results-subcategory-label"></div>
                                                                                                <div className="byzzer-component__product-scope-content-search-results-subcategory-value">
                                                                                                    <div className="byzzer-component__product-scope-content-search-results-subcategory-name">
                                                                                                        <Tooltip
                                                                                                            placement="bottomRight"
                                                                                                            title={
                                                                                                                item4.popular_products
                                                                                                            }
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    display:
                                                                                                                        'flex',
                                                                                                                    flexWrap:
                                                                                                                        'wrap',
                                                                                                                    wordBreak:
                                                                                                                        'break-word',
                                                                                                                    width:
                                                                                                                        'auto',
                                                                                                                }}
                                                                                                            >
                                                                                                                {item4.displayName !==
                                                                                                                undefined
                                                                                                                    ? ReactHtmlParser(
                                                                                                                            item4.displayName
                                                                                                                        )
                                                                                                                    : item4.name}
                                                                                                            </span>
                                                                                                        </Tooltip>
                                                                                                        <div className="byzzer-component__product-scope-content-search-results-subcategory-label">
                                                                                                            Sub
                                                                                                            Category
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {item4.matching_brands !==
                                                                                                        undefined &&
                                                                                                        item4
                                                                                                            .matching_brands
                                                                                                            .length >
                                                                                                            0 && (
                                                                                                            <div className="byzzer-component__product-scope-content-search-results-subcategory-description">
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        display:
                                                                                                                            'flex',
                                                                                                                        flexDirection:
                                                                                                                            'row',
                                                                                                                        flexWrap:
                                                                                                                            'wrap',
                                                                                                                        wordBreak:
                                                                                                                            'break-word',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {
                                                                                                                        item4.shortDescription
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </div>
                                                                );
                                                        })}
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                </div>
            )}
            {state.displayTree && (
                <div className="byzzer-component__product-scope-content-submit-btn">
                    <LegacyByzzerButton
                        disabled={state.tagsValue.length > 0 ? false : true}
                        onClick={() => submitDataContext()}
                        busy={busy}
                        className="configure-scope__button-btn"
                        label={displayTreeButtonLabel ? displayTreeButtonLabel : 'Submit'}
                    />
                </div>
            )}
            {!state.displayTree && (
                <div className="byzzer-component__product-scope-content-submit-btn">
                    <LegacyByzzerButton
                        disabled={state.isSearchProductScopeSelected ? false : true}
                        onClick={() => submitProductScopeSearch()}
                        className="configure-scope__button-btn"
                        busy={busy}
                        label={submitButtonLabel ? submitButtonLabel : 'Submit'}
                    />
                </div>
            )}
            {!signUp && state.displayModal && (
                <ByzzerModal
                    show={state.displayModal}
                    heading={state.modaltitle}
                    onClick={() => enableshowModal()}
                >
                    <label className="byzzer-label">{state.modalMessage}</label>
                </ByzzerModal>
            )}
        </div>
    );
    
}
export default ProductScopeNew;