import {useUser} from "@/contexts/UserContext";
import React, {useEffect, useState} from "react";
import {IntercomProps, useIntercom} from 'react-use-intercom';
import {useAnalyticsEvent} from "@/analytics/AnalyticsContext";
import _ from "lodash";
import {baseAppUrl} from "@/config";

export function useIntercomAnalytics() {
    // Fixing merge conflict.  focusBrand has been renamed to brands, also needed to add canEditCategories
    const {user, subscription, categories, company, brandNotRetailingYet, brands, canEditCategories, preSales, tenantId} = useUser();
    const {update, trackEvent, boot, showNewMessages, hide, shutdown,getVisitorId} = useIntercom();
    const event = useAnalyticsEvent();
    const [hasBooted, setHasBooted] = useState(false);
    useEffect(() => {
        let props: IntercomProps;
        if (user) {
            const {
                id,
                email,
                companyId,
                role,
                firstName,
                lastName,
                teamId,
            } = user;
            const name = `${firstName} ${lastName}`;
            props = {
                userId: String(id), // was getting type error when id was not converted to string
                name,
                email,
                company: {
                    companyId: String(companyId), // was getting type error when id was not converted to string
                    name: company?.displayName,
                    customAttributes: {
                        isFree: subscription?.metadata?.isFree,
                        subscription: subscription?.title,
                    }
                },
                customLauncherSelector: '.intercom-chat',
                customAttributes: {
                    role,
                    Categories: categories?.join(", ").toLowerCase(),
                    isSubsriptionUnlocked: canEditCategories,
                    Subscription: subscription?.metadata?.isFree === true ? 'free' : subscription?.title,
                    teamId: teamId,
                    isCompanyAdmin: ['admin', 'user'].includes(user?.role!),
                    baseurl: baseAppUrl,
                    presales: preSales ?? false,
                    subscriptionBrand : _.isEmpty(brands)?'N':'Y',
                    notSellingInRetailers: brandNotRetailingYet ? 'Y': 'N',
                    currentTenantId: tenantId // hashed tenant id
                }
            }
        } else {
            props = {
                customLauncherSelector: '.intercom-chat',
            }
        }
        if (!hasBooted) {
            boot(props);
            showNewMessages();
            hide();
            setHasBooted(true);
        } else {
            update(props);
        }
    }, [
        user?.id,
        subscription?.metadata?.isFree,
        subscription?.title,
        categories,
        company?.id,
        brands,
        brandNotRetailingYet,
        canEditCategories,
        preSales,
        tenantId
    ]);

    useEffect(() => {
        return () => {
            shutdown();
        };
    }, [shutdown]);

    useEffect(() => {
        if (event && event.type !== 'navigation') {
            trackEvent(event.name!, event.data);
        }
    }, [event, trackEvent]);
}
