//@ts-nocheck
//todo: this component needs more changes later
import './ReportViewer.scss';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {useTenantApi} from '@/hooks/useTenantApi';
import { ByzzerErrorModal, ByzzerLink } from '@/components/form';
import { alert, openModal } from '@/components/form';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import ReportFiltersOverlay from '@/pages/ReportViewer/ReportFilterOverlay';
import ReportInsights from '@/pages/ReportInsights';
import classnames from 'classnames';
import { openDownloadReport } from '@/views/ReportViewer/DownloadReport';
import { TrackClick } from '@/analytics';
import { useUser } from '@/contexts/UserContext';
import { Popover } from 'antd';
import { ReportRunOverlayConfigEditor } from '../ReportRunOverlayConfigEditor';
import { FilterOverlay } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigFilters/ReportOverlayFilters';
import { ReportRunConfig } from '@/types/ReportRun';
import { ReportRunConfigFiltersEditor } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigFilters/ReportRunConfigFilters';
import { focusKPIOptions } from '@/components/FocusKPISelector/focusKPI.constants';
import { ByzzerTipIcon } from '@byzzer/ui-components';

const EMPTY_LOOKER_REPORT_URL = 'about:blank';

export default function ReportViewer() {
    const { getActionHistory,    getDrillDownReportLink,    getReportEmbedFromActionHistory,    getMySubscriptionUsage,} = useTenantApi();
    const { user, maxDataDates, subscription } = useUser();
    const { reportId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [lookerReportUrl, setLookerReportUrl] = useState<string>(EMPTY_LOOKER_REPORT_URL);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [warningMessage, setWarningMessage] = useState<string>('');
    const [contentType, setContentType] = useState<string>('warning');
    const [reportSku, setReportSku] = useState<string>('');
    const [reportConfig, setReportConfig] = useState<any>({});
    const [selectorData, setSelectorData] = useState({});
    const [disableFiltering, setDisableFitlering] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>('Loading Your Report');
    const [actionId, setActionId] = useState({});
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [showNewReport, setShowNewReport] = useState<boolean>(false);
    const [showReportInsight, setReportInsight] = useState<boolean>(false);
    const [remainingCoreRuns, setRemainingCoreRuns] = useState<number>();
    const [remainingSmartRuns, setRemainingSmartRuns] = useState<number>();
    const [reportRunConfig, setReportRunConfig] = useState<Partial<ReportRunConfig>>();
    const [enableScheduleReportStep, setEnableScheduleReportStep] = useState<boolean>(false);
    const [showScheduleReport, setShowScheduleReport] = useState<false>(false);

    const baseClassName = 'report-viewer';
    const showReportSteps = showNewReport || enableScheduleReportStep;

    useEffect(() => {
        loadReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setDisableFitlering(
            !reportConfig?.focusProductSelections?.showFocusBrand &&
                reportConfig?.focusMarketSelections?.focusMarket?.byMarketName?.marketGroup === 'cps'
        );
    }, [reportConfig]);

    async function loadReport() {
        try {
            setLoadingText('Loading Your Report');
            setLoading(true);
            setActionId(reportId ?? '');

            const [url, { productSku, actionConfig }, { basicReports, premiumReports }] = await Promise.all([
                getReportEmbedFromActionHistory(reportId),
                getActionHistory(reportId),
                getMySubscriptionUsage(),
            ]);
            setReportSku(productSku);
            setReportConfig(actionConfig);
            setLookerReportUrl(url);
            setRemainingCoreRuns(Math.max(0, basicReports?.limit - basicReports.used));
            setRemainingSmartRuns(Math.max(0, premiumReports?.limit - premiumReports?.used));
        } catch (err) {
            console.error(err);
            // todo handle error with meaningful message
            setLoading(false);
        }
    }

    const hoverMsgCallBack = () => {
        if (!isOmniReport()) {
            if( subscription.active ){
                if (remainingCoreRuns === 0 && remainingSmartRuns === 0) {
                    return <div className="report-viewer-hover_msg">You have no core/smart report runs left.</div>;
                } else if (remainingCoreRuns && remainingCoreRuns > 0 && remainingSmartRuns === 0) {
                    return <div className="report-viewer-hover_msg">You have no smart report runs left.</div>;
                } else if (remainingCoreRuns === 0 && remainingSmartRuns && remainingSmartRuns > 0) {
                    return <div className="report-viewer-hover_msg">You have no core report runs left.</div>;
                }
            }else{
                return <div className="report-viewer-hover_msg">Your contract has expired</div>;
            }            
        }          
    };

    function goBack() {
        navigate(-1);
    }

    function goToDashboard() {
        navigate(`/dashboard`, { replace: true });
    }

    const downloadReport = async () => {
        await openDownloadReport({ actionId });
    };
    
    //in the drilldown payload, we send the display and not the value of focusKPIOptions, say EQ Units and not eqUnits today
    function getModifiedFilters(selectedFilters: any) {
        if (selectedFilters.focusKPI) {
            selectedFilters.focusKPI = focusKPIOptions.find(
                (option) => option.value === selectedFilters.focusKPI
            )?.display;
        }
        return selectedFilters;
    }

    async function applyFilters(filters) {
        try {
            setLoadingText('Applying Filters');
            setLoading(true);
            setShowFilters(false);
            setLookerReportUrl(EMPTY_LOOKER_REPORT_URL);

            // setReportConfig(filters);
            setReportRunConfig(filters);
            const { url, actionID } = await getDrillDownReportLink({
                reportSku,
                actionId: reportId,
                // contractItemInstanceId: selectorData?.contractItemInstanceId,
                reportConfig: getModifiedFilters(filters),
            });
            setLookerReportUrl(url);
            setActionId(actionID);
        } catch (err: any) {
            alert(err?.message);
            setLoading(false);
        }
    }

    const toggleFilterPanel = () => {
        setShowFilters(!showFilters);
        setShowNewReport(false);
        setReportInsight(false);
        setShowDropDown(false);
        setEnableScheduleReportStep(false);
    };

    const toggleNewReportPanel = () => {
        if (user?.role === 'viewer') {
            openModal({
                closeOnClickOutside: true,
                title: "Sorry! You don't have access to do that.",
                content: (
                    <>
                        <p>As a viewer, you don't have access to run a new report. </p>
                        <p>Reach out to your account admin to upgrade your member type to user.</p>
                    </>
                ),
                className: `${baseClassName}__viewer-access-modal`,
                headerType: 'normal',
            });
            return;
        }
        setShowFilters(false);
        setShowNewReport(!showNewReport);  // Toggling the visibility of New Report
        setEnableScheduleReportStep(false);  // Ensure schedule step is false
        setReportInsight(false);
        setShowDropDown(false);
    };

    const toggleReportInsightPanel = () => {
        setReportInsight(!showReportInsight);
        setShowFilters(false);
        setShowNewReport(false);
        setEnableScheduleReportStep(false);
        setShowDropDown(false);
    };

    const toggleScheduleReport = () => {
        if (user?.role === 'viewer') {
            openModal({
                closeOnClickOutside: true,
                title: "Sorry! You don't have access to do that.",
                content: (
                    <>
                        <p>As a viewer, you don't have access to run a new report. </p>
                        <p>Reach out to your account admin to upgrade your member type to user.</p>
                    </>
                ),
                className: `${baseClassName}__viewer-access-modal`,
                headerType: 'normal',
            });
            return;
        }
        setShowFilters(false);
        setEnableScheduleReportStep(!enableScheduleReportStep);  // Toggle whether it's schedule or new
        setShowNewReport(false);  // Close the New Report if it's open
        setReportInsight(false);
        setShowDropDown(false);
    }

    async function onApplyFilters(config) {
        await applyFilters(config);
    }

    function onCancelFilters() {
        setShowFilters(false);
    }

    function onScorecardLoad() {
        if (lookerReportUrl !== EMPTY_LOOKER_REPORT_URL) {
            setLoading(false);
        }
    }

    function isOmniReport() {
        return (
            reportConfig?.focusProductSelections?.omniProductLevel ||
            reportConfig?.focusMarketSelections?.omniMarketKeys?.length ||
            reportConfig?.focusMarketSelections?.omniTotalMarketKey ||
            reportConfig?.timePeriodSelections?.selectionType === 'omni'
        );
    }

    function onComplete(reportId, isScheduleReportComplete = false) {
        if (isScheduleReportComplete) {
            toggleScheduleReport();
            return;
        }

        //Setting default looker URL value to stop loading of old report URL from cache
        setLookerReportUrl(EMPTY_LOOKER_REPORT_URL);
        setShowNewReport(false);
        setEnableScheduleReportStep(false);
        setReportRunConfig();
        navigate(`/report/${reportId}`);
    }

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}__header`}>
                <TrackClick name={`ReportViewer logo clicked`}>
                    <span className={classnames(`${baseClassName}-header__logo`)} onClick={() => goToDashboard()} />
                    <span className={classnames(`${baseClassName}-header__brandName`)} onClick={() => goToDashboard()}>
                        Byzzer
                    </span>
                </TrackClick>
                <div className={`${baseClassName}__menu`} />
                <div
                    className={classnames(`${baseClassName}__filter-toggle`, {
                        [`${baseClassName}__filter-toggle--disabled`]: disableFiltering,
                        [`${baseClassName}__filter-toggle--open`]: showFilters,
                    })}
                />
                <div className={`${baseClassName}__header-actions`}>
                    <TrackClick name={`ReportViewer download clicked`}>
                        <span className={`download-report`} onClick={() => downloadReport()} /* target={'_blank'} */>
                            <ByzzerTipIcon type={'info'} tipLocation={'bottom'} tip={'Download'} />
                        </span>
                    </TrackClick>
                    <TrackClick name={`ReportViewer back clicked`}>
                        <span className={`exit-report`} onClick={() => goBack()}>
                            <ByzzerTipIcon type={'info'} tipLocation={'bottom'} tip={'Back'} />
                        </span>
                    </TrackClick>

                    {!subscription.active?  (
                        <Popover className="info-popover" content={hoverMsgCallBack()} placement="left">
                            <span className="dropDown-disabled"  />
                        </Popover>
                    ) : (
                        <span className="dropDown" onClick={() => setShowDropDown(!showDropDown)} />
                    )}
                </div>
            </div>

            <div className={`${baseClassName}__menuItems`} style={{ display: showDropDown ? 'block' : 'none' }}>
                <div className={`${baseClassName}__underlineStyle`}>Report Menu</div>
                <div className={`${baseClassName}__menuItemstyle`}>
                    <TrackClick name={`ReportViewer filter report clicked`}>
                        <ByzzerLink
                            className={`${baseClassName}__menuItemstyle-item`}
                            label={'Filter current report'}
                            onClick={() => toggleFilterPanel()}
                        />
                    </TrackClick>
                    {hoverMsgCallBack() ? (
                        <Popover className="info-popover" content={hoverMsgCallBack()} placement="left">
                            <div>
                                <TrackClick name={`ReportViewer run a new report clicked`}>
                                    <ByzzerLink
                                        className={`${baseClassName}__menuItemstyle-item`}
                                        label={'Run a new report'}
                                        onClick={toggleNewReportPanel}
                                    />
                                </TrackClick>
                            </div>
                        </Popover>
                    ) : (
                        <TrackClick name={`ReportViewer run a new report clicked`}>
                            <ByzzerLink
                                className={`${baseClassName}__menuItemstyle-item`}
                                label={'Run a new report'}
                                onClick={toggleNewReportPanel}
                            />
                        </TrackClick>
                    )}
                    <TrackClick name={`ReportViewer view report info clicked`}>
                        <ByzzerLink
                            className={`${baseClassName}__menuItemstyle-item`}
                            label={'View report info'}
                            onClick={toggleReportInsightPanel}
                        />
                    </TrackClick>
                    {!isOmniReport() && (
                        <TrackClick name={`reportviewer_schedule_report_clicked`}>
                            <ByzzerLink
                                className={`${baseClassName}__menuItemstyle-item`}
                                label={'Schedule report'}
                                onClick={toggleScheduleReport}
                            />
                        </TrackClick>
                    )}
                </div>
            </div>
            <div className={`${baseClassName}__content`}>
                <ByzzerMask loading={loading}>{loadingText}</ByzzerMask>
                {lookerReportUrl !== EMPTY_LOOKER_REPORT_URL && (
                    <iframe
                        title={reportId}
                        src={lookerReportUrl}
                        className={`${baseClassName}__looker-report`}
                        onLoad={onScorecardLoad}
                    />
                )}
                {showFilters && (
                    // <ReportFiltersOverlay
                    //     title="Filter Current Report"
                    //     subtitle="Applying filters will not use a report run"
                    //     show={showFilters}
                    //     reportConfig={reportConfig}
                    //     maxDataDate={maxDataDates.rms}
                    //     onCancel={onCancelFilters}
                    //     onApply={onApplyFilters}
                    //     sku={reportSku}
                    //     toggleFilterPanel={toggleFilterPanel}
                    //     isOmniReport={isOmniReport()}
                    // />

                    <FilterOverlay
                        closeOverlay={() => {
                            setShowFilters(false);
                        }}
                    >
                        <ReportRunConfigFiltersEditor
                            reportId={reportId ? Number(reportId) : undefined}
                            reportSku={reportSku}
                            onCancel={onCancelFilters}
                            onApply={onApplyFilters}
                            reportRunConfig={reportRunConfig}
                        />
                    </FilterOverlay>
                )}
                {showReportSteps && (
                    // <NewReportRun
                    //     reportConfig={reportConfig}
                    //     sku={reportSku}
                    //     lookerReportUrl={lookerReportUrl}
                    //     show={showNewReport}
                    //     toggleNewReportPanel={toggleNewReportPanel}
                    // />
                    <FilterOverlay
                        closeOverlay={() => {
                            setShowNewReport(false);
                            setEnableScheduleReportStep(false);
                        }}
                    >
                        <ReportRunOverlayConfigEditor
                            reportId={reportId ? Number(reportId) : undefined}
                            reportSku={reportSku}
                            onComplete={onComplete}
                            reportRunConfig={reportRunConfig}
                            enableScheduleReportStep={enableScheduleReportStep}
                        />
                    </FilterOverlay>
                )}

                {showReportInsight && (
                    <ReportInsights
                        reportConfig={reportConfig}
                        sku={reportSku}
                        lookerReportUrl={lookerReportUrl}
                        show={showReportInsight}
                        toggleReportInsightPanel={toggleReportInsightPanel}
                    />
                )}
            </div>
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
        </div>
    );
}
