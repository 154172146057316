import React from 'react';
import { LegacyByzzerButton } from '@/components/form';

export function ChooseEditActionStep({ onComplete, ppg }) {
    let actions;

    if (ppg?.type === 'UPC') {
        actions = (
            <>
                <LegacyByzzerButton onClick={() => onComplete('upload-upcs')}>Upload New UPC-PPG List</LegacyByzzerButton>
                <LegacyByzzerButton onClick={() => onComplete('change-rules')}>Change Rules For Remaining UPCs</LegacyByzzerButton>
                <LegacyByzzerButton onClick={() => onComplete('remove-upcs')}>
                    Change To Characteristic-Based PPGs
                </LegacyByzzerButton>
            </>
        );
    } else {
        actions = (
            <>
                <LegacyByzzerButton onClick={() => onComplete('change-rules')}>Change Characteristic Rules</LegacyByzzerButton>
                <LegacyByzzerButton onClick={() => onComplete('upload-upcs')}>Change To UPC Upload</LegacyByzzerButton>
            </>
        );
    }

    return (
        <div className={'ppg-editor__content'}>
            <div className="ppg-editor__actions">{actions}</div>
        </div>
    );
}
