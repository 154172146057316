import React, { useDebugValue, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import papa from 'papaparse';
import xlsx from 'xlsx';
import { LegacyByzzerButton, ByzzerLink, ByzzerSwitch } from '@/components/form';
import classNames from 'classnames';
import { CategorySelector } from '@/views/PPG/editor/CategorySelector';
import * as _ from 'lodash';

export function UploadStep({
    onActionClick,
    categories,
    upcs,
    onCategoriesChange,
    onUpcsChange,
    onComplete,
    busy,
    isComma,
}) {
    const [canComplete, setCanComplete] = useState(false);
    const [importedUpcs, setImportedUpcs] = useState([]);
    const [file, setFile] = useState<File>();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: [
            '.xls',
            '.xlsx',
            '.csv',
            'text/csv',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
        ],
    });
    const [canRemoveCheckDigits, setCanRemoveCheckDigits] = useState(false);
    const [removeCheckDigits, setRemoveCheckDigits] = useState(false);
    const [containsComma, setContainsComma] = useState(false);

    useDebugValue(importedUpcs);
    useDebugValue(removeCheckDigits);
    useDebugValue(canRemoveCheckDigits);

    useEffect(() => {
        setCanComplete(Boolean(categories.length && importedUpcs.filter(hasValidUpc).length));
        checkComma(upcs);
    }, [categories, upcs]);

    useEffect(() => {
        if (removeCheckDigits) {
            onUpcsChange(stripCheckDigits(importedUpcs));
        } else {
            onUpcsChange(importedUpcs);
        }
    }, [removeCheckDigits, importedUpcs]);

    useEffect(() => {
        const [file] = acceptedFiles;
        if (file) {
            processFile(file);
        }
        setFile(file);
    }, [acceptedFiles]);

    async function processFile(file) {
        let upcs;
        const extension = file.name.split('.').pop();
        switch (file.type || extension) {
            case 'text/csv':
            case 'csv':
            case 'txt':
                upcs = await parseCsv(file);
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'xlsx':
            case 'xls':
                upcs = await parseSheet(file);
                break;
        }

        if (upcs?.length) {
            // pad all upcs with leading 0s
            upcs = upcs.map(({ upc, group }) => ({
                upc: `${upc}`.padStart(12, '0'),
                group,
            }));
            setCanRemoveCheckDigits(doAnyContainCheckDigits(upcs));
            setImportedUpcs(upcs);
        }
    }

    async function parseCsv(file) {
        return new Promise((resolve) => {
            papa.parse(file, {
                worker: true,
                complete(results) {
                    resolve(
                        results.data
                            // @ts-ignore
                            .filter(([upc]) => upc?.trim())
                            // @ts-ignore
                            .map(([upc, group]) => ({
                                upc,
                                group,
                            }))
                    );
                },
            });
        });
    }

    async function parseSheet(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                // @ts-ignore
                const data = new Uint8Array(reader?.result);
                const workbook = xlsx.read(data, { type: 'buffer' });
                resolve(
                    xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
                        header: ['upc', 'group'],
                    })
                );
            });
            reader.readAsArrayBuffer(file);
        });
    }

    function doAnyContainCheckDigits(upcs) {
        return upcs.some(({ upc }) => containsCheckDigit(upc));
    }

    function checkComma(upcs) {
        Object.keys(upcs).forEach((i) => {
            if (upcs[i].upc.includes(',')) {
                setContainsComma(true);
                isComma(true);
            } else isComma(false);
        });
    }

    function hasValidUpc({ upc }) {
        return /^\d+$/.test(upc);
    }

    function stripCheckDigits(upcs) {
        return _.cloneDeep(upcs).map((entry) => {
            const upc = entry.upc;
            // strip the check digit if it's included
            if (hasValidUpc(entry) && containsCheckDigit(upc)) {
                entry.upc = upc.substr(0, 11).padStart(12, '0');
            }
            return entry;
        });
    }

    function containsCheckDigit(upc) {
        const lastDigit = +upc.substr(-1, 1);
        const leadingDigits = upc.substr(0, 11).split('');
        // multiplying every digit in an odd numbered position by 3, then sum all of the values up
        const sum = leadingDigits.reduce((total, value, i) => total + value * (i % 2 ? 1 : 3), 0);
        // mod the result by 10, if it's 0 use 0, otherwise subtract the value from 10
        const checkDigit = sum % 10 ? 10 - (sum % 10) : 0;
        return checkDigit === lastDigit;
    }

    function onGuidelinesClick() {
        onActionClick('showGuidelines');
    }

    function onRemoveCheckDigitsChange({ target }) {
        setRemoveCheckDigits(target.checked);
    }

    return (
        <div className="ppg-editor__do">
            <div className="ppg-editor__do-caption">
                Upload your PPG definition. Go back to the previous screen to review the upload guidelines again.
            </div>
            <div className={'ppg-editor__info-link'}>
                <ByzzerLink label={'View Upload Guidelines'} onClick={onGuidelinesClick} />
            </div>
            <CategorySelector onChange={onCategoriesChange} categories={categories} />
            <div className="ppg-editor__file">
                <div
                    {...getRootProps({
                        className: classNames('ppg-editor__target', {
                            'ppg-editor__target--has-file': file,
                        }),
                    })}
                >
                    <input {...getInputProps()} />
                    <span>{file ? file.name : 'Drop CSV file or spreadsheet here or click to upload.'}</span>
                </div>
                {canRemoveCheckDigits && (
                    <div className={'ppg-editor__check-digit-warning'}>
                        <p>Some of your UPCs appear to contain check digits, which is not supported but enabling the option below will remove them for you.</p>
                        <p>It is possible to detect false check-digits, so If you are sure you did not include check digits, do not enable this option.</p>

                        <ByzzerSwitch value={removeCheckDigits} onChange={onRemoveCheckDigitsChange} />
                        <span className={'ppg-editor__check-digit-warning-label'}>Remove Check Digits</span>
                    </div>
                )}
            </div>
            <div className="ppg-editor__actions">
                <LegacyByzzerButton
                    label={'Next'}
                    disabled={!canComplete && !containsComma}
                    onClick={onComplete}
                    busy={busy}
                />
            </div>
        </div>
    );
}
