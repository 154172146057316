import React, { useEffect, useMemo, useState } from "react";
import classnames from 'classnames';
import './LegalTermsAndConditions.scss';
import { ByzzerButton, ByzzerCheckableChangeEvent, ByzzerCheckbox } from "@byzzer/ui-components";
import {useTenantApi} from "@/hooks/useTenantApi";
import { Spinner } from "@/components/Spinner/Spinner";
import { useUser } from "@/contexts/UserContext";
import { ByzzerLogo } from "@/components/ByzzerLogo";
import { ByzzerLink } from "@/components/form";
import { openTermsAndConditions } from "@/components/modals/TermsAndConditions";
import { useSharedApi } from "@/hooks/useSharedApi";
import { renderMarkdownOrRawContent } from "@/utils";

export type ConfigValueType = {
    description: string;
    checkboxLabel: string;
}

export type TcsContentType = {
    config_value: ConfigValueType;
}

export function LegalTermsAndConditions() {
    const { saveTcsSelection } = useTenantApi();
    const { getTcsContent } = useSharedApi();

    const baseClassName = 'byz-legal-terms-and-conditions';

    const [agreeToTcsChecked, setAgreeToTcsChecked] = useState<boolean>(false);
    const [description, setDescription] = useState('');
    const [checkboxLabel, setCheckboxLabel] = useState('');
    const [busy, setBusy] = useState<boolean>(false);
    const { refreshUser } = useUser();

    useEffect(() => {
        loadTcsContent();
    }, [])

    async function loadTcsContent() {
        const content: TcsContentType = await getTcsContent();
        setDescription(content.config_value.description);
        setCheckboxLabel(content.config_value.checkboxLabel);
    }

    function handleCheck(e: ByzzerCheckableChangeEvent<string>) {
        setAgreeToTcsChecked(e.checked);
    }

    async function handleAgreeClick() {
        setBusy(true);

        try {
            await saveTcsSelection(true);
            await refreshUser({
                tcsAccepted: true
            });
        } catch (err) {
            
        }
    }

    const descriptionContent = useMemo(() => renderMarkdownOrRawContent(description), [description]);
    const labelCheckboxContent = useMemo(() => renderMarkdownOrRawContent(checkboxLabel), [checkboxLabel]);

    return (
        <div className={classnames(baseClassName)}>
            <ByzzerLogo />

            <main className={classnames(`${baseClassName}__content`)}>
                <div className={classnames(`${baseClassName}__title`)}>
                    <p>Important Reminder of Critical Usage Requirements</p>
                </div>

                <div className={classnames(`${baseClassName}__description`)}>{descriptionContent}</div>

                <section className={classnames(`${baseClassName}__more-info`)}>
                    <p>For more information, please review the <ByzzerLink label={'NielsenIQ Portal Services Terms & Conditions'} onClick={openTermsAndConditions}/>.</p>
                </section>

                {busy && <Spinner />}

                {!busy && (<>
                    <section className={classnames(`${baseClassName}__actions`)}>
                        <div className={classnames(`${baseClassName}__input`)}>
                            <ByzzerCheckbox checked={agreeToTcsChecked} onChange={handleCheck}/>
                            <p className={classnames(`${baseClassName}__fine-print`)}>
                                {labelCheckboxContent}
                            </p>
                        </div>
                        <div className={classnames(`${baseClassName}__button-wrapper`)}>                        
                            <ByzzerButton label={'Accept'} disabled={!agreeToTcsChecked} onClick={handleAgreeClick} />
                        </div>
                    </section>
                </>)}
            </main>
        </div>
    );

};

export default LegalTermsAndConditions;

LegalTermsAndConditions.displayName = 'LegalTermsAndConditions';