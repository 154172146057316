export type DomainMismatchReasonCode = 'anotherDomain' | 'personal' | 'externalMember';

export type DomainMismatchOption = {value: DomainMismatchReasonCode, display: string};

export type UserInvite = {
    firstName: string;
    lastName: string;
    email: string;
    role: TeamRole;
    teamId: number | undefined;
    domainMismatchReasonCode?: DomainMismatchReasonCode | undefined;
}

export type ExternalUser = Pick<UserInvite, 'email' | 'role' | 'teamId'>;

export type TeamRole = 'admin' | 'user' | 'viewer' | '';

export type TeamMember = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    type: 'user' | 'invitation';
    isPending: boolean;
    role: TeamRole;
    teamId?: number;
    teamName: string;
    fullName: string;
    isConsultant?: boolean;
    multiTenantEnabled?: boolean;
    primaryCompanyId?: number;
    metadata: Record<string, any>;
    configurationCounts?: UserConfigurationCounts;
}

export type UserConfigurationCounts = {
    countOfScheduledSeries: number;
    countOfAlertsWithOtherRecipients: number;
}

export type CompanyOption = {
    id: number;
    name: string;
    active?: boolean;
    retailer: boolean;
    subscribed: boolean;
    type: number;
}

export type Team = {
    id: number;
    tenantId: number;
    displayName: string;
    isDefault: boolean;
    metadata: Record<string, any>;
    createdDtm: string; // ex: '2023-02-28T14:37:26.690Z
    updatedDtm: string; // ex: '2023-02-28T14:37:26.690Z
    usage: {
        basicReports: {
            limit: number;
            used: number;
        };
        premiumReports: {
            limit: number;
            used: number;
        };
    };
}

export enum CompanyType {
    CPG_MANUFACTURER_OR_BRAND = 1,
    CPG_SUPPLIER = 2,
    CPG_RETAILER = 3,
    RESTAURANT_OR_FOOD_SERVICE = 4,
    FINANCIAL_SERVICES = 5,
    PROFESSIONAL_SERVICES = 6,
    GOVERNMENT_OR_NON_PROFIT = 7,
    EDUCATION_OR_UNIVERSITIES = 8,
    OTHER = 9,
}
