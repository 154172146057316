import './SelectorLabelInfo.scss';
import { ByzzerTipIcon } from '@byzzer/ui-components';
import { OptionalRequired, OptionalRequiredProps } from '../OptionalRequired';
import { useUser } from '@/contexts/UserContext';
import { defaultSelectorLabels as hardCodedDefaultSelectorLabels } from '@/constants/defaultLabels.constants';
import { ReactNode, useMemo } from 'react';

type SelectorLabelProps = {
    required?: boolean;
    includeSuffix?: boolean;
    optionalSuffix?: string;
    showLabel?: boolean;
    showTipInfo?: boolean;
    max?: number;
    min?: number;
    selectorCode?: string;
    sku: string;
    tipDelay?: number | [number | null, number | null];
    isLabelBold?: boolean;
    defaultLabel?: ReactNode;
    stepCode?: string;
} & OptionalRequiredProps;

export function SelectorLabelInfo({
    required,
    includeSuffix,
    optionalSuffix,
    showLabel = true,
    showTipInfo = true,
    max,
    min,
    selectorCode,
    sku,
    tipDelay,
    isLabelBold = false,
    defaultLabel,
    ...props
}: SelectorLabelProps) {
    const baseClassName = 'selector-label-info';

    const {allProducts, defaultLabelsAndTips} = useUser();

    // const runConfigOptions:RunConfigOptions[] = getRunConfigOptionsBySku(sku);  , may need this in future

    const MIN_HOLDER: string = '${minValue}';
    const MAX_HOLDER: string = '${maxValue}';

    const productLabelOptions = allProducts?.find((product) => product.sku === sku)?.labelOptions;
    const albyDefaultLabelsAndTips = defaultLabelsAndTips?.find(({code}) => code === selectorCode);

    const albyDefaultLabel = albyDefaultLabelsAndTips?.defaultLabelValue?.trim();
    const albyDefaultTip = albyDefaultLabelsAndTips?.defaultTipValue?.trim();

    const label = productLabelOptions?.find((labelOption) => labelOption.labelCode === selectorCode)?.label?.trim();
    const toolTipInfo = productLabelOptions?.find((labelOption) => labelOption.labelCode === selectorCode)?.tip?.trim();
    //TODO: handle the undefined and null response as well for labels and tips in MVP-2
    /**
     * @param selectorResponse - for labels, it will be either populated from alby or undefined
     * and for tooltip, it will be null from api if not set on ALby or undefined
     * @returns string
     */
    function handleSelectorDynamicValue(selectorResponse: string) {
        if (selectorResponse?.includes(MIN_HOLDER)) {
            return selectorResponse.replace(MIN_HOLDER, Number(min).toString());
        }
        if (selectorResponse?.includes(MAX_HOLDER)) {
            return selectorResponse.replace(MAX_HOLDER, Number(max).toString());
        }

        return selectorResponse;
    }

    const finalLabel = useMemo(() => {
        if (!showLabel) return '';
        //CHECKS to replace the empty string with the default value, else render the values set on alby for labels
        if (label) {
            return handleSelectorDynamicValue(label);
        }

        return albyDefaultLabel || defaultLabel || (selectorCode ? hardCodedDefaultSelectorLabels?.[selectorCode]?.label : '');
    }, [label, defaultLabel, hardCodedDefaultSelectorLabels, defaultLabelsAndTips]);

    const finalTooltip = useMemo(() => {
        if (!showTipInfo || !selectorCode) return;

        if (toolTipInfo) {
            const dynamicTooltip = handleSelectorDynamicValue(toolTipInfo)?.trim();
            //CHECKS to replace the empty string with the default value, else render the values set on alby for info
            if (dynamicTooltip?.replace(/\s/g, '').length) {
                return dynamicTooltip;
            }
        }

        const hardCodeDefaultSelectorTip: string | JSX.Element | undefined = hardCodedDefaultSelectorLabels?.[selectorCode]?.tip;

        const defaultTip = typeof hardCodeDefaultSelectorTip === 'string' ? hardCodeDefaultSelectorTip?.trim() : hardCodeDefaultSelectorTip;

        return albyDefaultTip || defaultTip;
    }, [toolTipInfo, albyDefaultTip, selectorCode, hardCodedDefaultSelectorLabels, showTipInfo]);

    /*
     * the component will render the label of the selector, reuired/optional , tipinfo based on alby settings, if not found will render a default value from FE
     */
    return (
        <>
            <span className={baseClassName}>
                <OptionalRequired
                    required={required}
                    label={finalLabel}
                    includeSuffix={includeSuffix}
                    optionalSuffix={optionalSuffix}
                    isLabelBold = {isLabelBold}
                    defaultLabel={defaultLabel}
                    {...props}
                />
                {/* todo: add a fix to combine defaultToolTipInfo & toolTipInfo logic. Also check if showTipInfo as true is really required */}
                {showTipInfo && Boolean(finalTooltip) && (
                    <ByzzerTipIcon tip={finalTooltip} tipDelay={tipDelay}/>
                )}
            </span>
        </>
    );
}
