// 
import './AdHocReports.scss';
import React, {Fragment, useEffect, useState} from 'react';
import className from 'classnames';
import classnames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';
import ReportInformation from '@/views/ReportInformation';
import {LegacyByzzerButton, ByzzerLink, ByzzerModal} from '@/components/form';
import { openModal } from '@/components/form/ByzzerModal';
import ReportFilters from './ReportFilters';
import {orderedSectionHeaders, reportDescriptionUrl} from '@/config/globalVars';
import {useEvents, useUser} from '@/contexts/UserContext';
import {ByzzerMask} from '@/components/ByzzerMask/ByzzerMask';
import {useBetterNavigate} from '@/utils';
import {TrackClick} from '@/analytics';
import {TipIcon} from '@/components/icons';
import SmartReportBadge from '@images/badges/smart-report.svg';
import CoreAdvancedBadge from '@images/badges/core-advanced-report.svg';
import FavoriteIcon from '@/components/icons/FavoriteIcon';
import {ReportCardBadge} from "@/views/MyReports/ReportCardBadge";

const baseClassName = 'ad-hoc-reports';

function AdHocReports() {
    const {createUserFavorite, deleteUserFavorite, getMyProducts, getUserFavorite} = useTenantApi();
    const {user} = useUser();
    const navigate = useBetterNavigate();
    const [loading, setLoading] = useState(false);
    const [favoriteSkus, setFavoriteSkus] = useState([]);
    const [filterType, setFilterType] = useState(0);
    const [allReports, setAllReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [skuFilter, setSkuFilter] = useState();
    const [canRunReports, setCanRunReports] = useState(false);
    const [selectedReport, setSelectedReport] = useState();
    const [showReportInfo, setShowReportInfo] = useState(false);

    const events = useEvents();
    const wrapperClass = className('ad-hoc-reports', {
        'ad-hoc-reports--readonly': !canRunReports,
        'ad-hoc-reports--runnable': canRunReports,
    });

    useEffect(() => {
        if (user?.role) {
            setCanRunReports(['user', 'admin'].includes(user?.role));
        }    
    }, [user]);

    useEffect(() => {
        loadReports();
    }, []);

    // apply filters when the list of reports or the skuFilter changes
    useEffect(() => {
        if (skuFilter) {
            filterBySkus(skuFilter);
        }
    }, [allReports, skuFilter]);

    useEffect(() => {
        const [event] = events;
        if (['contract_items_changed', 'adhoc_report_run'].includes(event?.type)) {
            loadReports(false);
        }
    }, [events]);

    async function loadReports(showLoadingIndicator = true) {
        try {
            if (showLoadingIndicator) setLoading(true);
            const [reports] = await Promise.all([getMyProducts({include: 'report'}), getFavorites()]);

            const allReports = reports
                .map(({usage, ...report}) => ({
                    ...report,
                    group: report.metadata?.reportOptions?.sectionHeader,
                    runnable: usage.limit - usage.used > 0,
                    reportType: report.metadata?.reportOptions?.reportType
                }))
                .sort((a, b) => a.title.localeCompare(b.title));

            setAllReports(allReports);
            setFilteredReports(allReports);
            // reportCfgs = appendSampleReportsForNewSelector(reportCfgs, reports); // #416 Epic stories sample reports for testing new selectors being built
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    async function onReportClick(report) {
        if (!canRunReports || !report.runnable) {
            if (user?.role === 'viewer') {
                openModal({
                    closeOnClickOutside: true,
                    title: "Sorry! You don't have access to do that.",
                    content: (
                        <>
                            <p>As a viewer, you don't have access to run a new report. </p>
                            <p>Reach out to your account admin to upgrade your member type to user.</p>
                        </>
                    ),
                    className: `${baseClassName}__viewer-access-modal`,
                    headerType: 'normal'
                });    
            }            
            return;
        }

        navigate(`/dashboard/configure_report`, {
            search: {
                runType: 'adhoc',
                creditId: report.instanceId,
                sku: report.sku,
            },
            state: {
                addons: report.addons ?? [],
            },
        });
    }

    async function getFavorites() {
        let favorites = await getUserFavorite('adhoc');
        setFavoriteSkus(favorites);
        if (filterType === 4) {
            setSkuFilter(favorites);
        }
    }

    function filterBySkus(skus) {
        const filteredReports = allReports?.filter(({sku}) => skus?.includes(sku));
        setFilteredReports(filteredReports);
    }

    async function onFavoriteClick(sku) {
        /** Delete If Available */
        if (favoriteSkus.includes(sku)) {
            favoriteSkus.splice(favoriteSkus.indexOf(sku), 1);
            // expanding the array before setting is necessary to change the reference and force react to update
            setFavoriteSkus([...favoriteSkus]);
            // we only need to this check when removing a favorite because it wouldn't be visible to toggle otherwise
            if (filterType === 4) {
                setSkuFilter([...favoriteSkus]);
            }
            await deleteUserFavorite('adhoc', sku);
        } else {
            /** Add If not available */
            favoriteSkus.push(sku);
            setFavoriteSkus([...favoriteSkus]);
            await createUserFavorite('adhoc', sku);
        }
        getFavorites();
    }

    function disabledReportClassName(item) {
        return item?.usage.limit <= item?.usage.used ? 'disable-content' : '';
    }

    function onFilterChange(type) {
        setFilterType(type);
    }

    function onFilterDataChange(skus) {
        setSkuFilter(skus);
    }

    function onBuyClick(report) {
        // todo: navigate to the specific report in the shop
        window.open('https://store.byzzer.com/search?custitem_purchase_type=Ad~hoc', '_buyAgain');
    }

    function onUpgradeClick() {
        window.open('https://store.byzzer.com/upgrades', '_upgradeSubscription');
    }

    return (
        <div className={wrapperClass}>
            <ByzzerMask show={loading} loading={loading}/>
            {/* empty state */}
            {allReports.length === 0 && !loading && (
                <div className="ad-hoc-reports__empty-state">No Ad-hoc reports found</div>
            )}
            {Boolean(allReports.length) && (
                <>
                <ReportFilters onChange={onFilterChange} onFilter={onFilterDataChange} from="adhoc-reports"/>
                <div className="report-groups">
                    {filteredReports.length === 0 && (
                        <div className="ad-hoc-reports__empty-state">No matching reports found</div>
                    )}
                    {Boolean(filteredReports.length) &&orderedSectionHeaders.map((group, index) => {
                        const reports = filteredReports.filter((report) => group === report.group);
                        if (!reports.length) {
                            return <Fragment key={group}/>;
                        }

                        return (
                            <div className={'report-group'} key={group}>
                                <div key={index} className="report-group__title">
                                    {group}
                                </div>
                                <div className={'report-group__cards'}>
                                {reports.map((report) => (
                                    <div
                                        className={classnames('report-card', {
                                            'report-card--disabled': !report.runnable,
                                        })}
                                        key={report.instanceId}
                                    >
                                        <div className="report-card__header">
                                            <TrackClick name={`My Reports Report (${report.title}) clicked`}>
                                                <div
                                                    className="report-card__title"
                                                    onClick={() => onReportClick(report)}
                                                >
                                                    {report.title}
                                                </div>
                                            </TrackClick>
                                            <FavoriteIcon
                                                trackClick={`Favorited Report clicked`}
                                                selected={favoriteSkus?.includes(report.sku)}
                                                onClick={() => onFavoriteClick(report.sku)}
                                            />
                                            <TipIcon
                                                onClick={() => {
                                                    setShowReportInfo(!showReportInfo);
                                                    setSelectedReport(report);
                                                }}
                                                trackClick={`Report Info (${report.title}) clicked`}
                                            />
                                        </div>
                                        {!report.runnable && (
                                            <div className={`report-card__links`}>
                                                <ByzzerLink
                                                    trackClick={`My Reports Ad Hoc Report Buy ${report.title} clicked`}
                                                    label={'Buy Again'}
                                                    className="ad-hoc-reports-list__title__item-btn"
                                                    onClick={() => onBuyClick(report)}
                                                />
                                                <ByzzerLink
                                                    trackClick={`My Reports Ad Hoc Upgrade Sub ${report.title} clicked`}
                                                    label={'Upgrade Subscription'}
                                                    className="ad-hoc-reports-list__title__item-btn"
                                                    onClick={onUpgradeClick}
                                                />
                                            </div>
                                        )}
                                        <div
                                            className="report-card__thumbnail"
                                            onClick={() => onReportClick(report)}
                                        >
                                            <div className="report-card__thumbnail-image" style={{ backgroundImage: `url(${report.thumbnailUrl})` }}></div>
                                            <ReportCardBadge 
                                                reportType={report.reportType}
                                                sectionHeader={report.sectionHeader}
                                                className={'report-card__badge'}
                                            />
                                        </div>

                                        <div className="report-card__description">
                                            {report.description}
                                        </div>
                                        <ReportAddons addons={report.addons}/>
                                    </div>
                                ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
                </>
            )}
            {selectedReport && (
                <ByzzerModal
                    show={showReportInfo}
                    onClose={() => setShowReportInfo(!showReportInfo)}
                    heading={selectedReport?.title}
                    size={'large'}
                    headerType={'normal'}
                    extraIcon={
                        canRunReports && (
                            <LegacyByzzerButton label={'Run Report'} onClick={() => onReportClick(selectedReport)}/>
                        )
                    }
                >
                    <ReportInformation
                        sku={selectedReport?.sku}
                        url={selectedReport?.detailsUrl || `${reportDescriptionUrl}/${selectedReport?.sku}.html`}
                        readonly={!canRunReports}
                        runReport={() => onReportClick(selectedReport)}
                    />
                </ByzzerModal>
            )}
        </div>
    );
}

function categoryAddonsCount(addonsObj) {
    let categAddonsCount = (addonsObj?.filter((item) => item.type === 'product_addon_category')?.length ?? []) + 1;
    return `${categAddonsCount} ${categAddonsCount > 1 ? `categories` : `category`}`;
}

function ReportAddons({addons = [], className, ...props}) {
    let themeCharsAddonCount =
        addons?.filter((item) => item.type === 'product_addon_characteristic_theme')?.length ?? 0;
    return (
        <div className={classnames("report-card__addons", className)}>
            Your ad hoc report run includes {categoryAddonsCount(addons)}
            {(addons?.filter((item) => item.type !== 'product_addon_characteristic_theme') ?? []).map((addon, index) => (
                <Fragment key={index}>{addon.type !== 'product_addon_category' && ` and ${addon.title}`}</Fragment>
            ))}
            {(addons?.filter((item) => item.type === 'product_addon_characteristic_theme') ?? []).map((addon, index) => (
                <Fragment key={index}>{`${index === 0 ? ` for ` : index === themeCharsAddonCount - 1 ? ` and` : `, `} ${addon.title}${
                    themeCharsAddonCount === index + 1 ? `.` : ``
                }`}</Fragment>
            ))}
        </div>
    );
}

export default AdHocReports;
