import React, { forwardRef, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './ProductRunConfigFilters.scss';
import classnames from 'classnames';
import { FilterGroup, FilterSection } from '@/components/ConfigurationEditors/FilterGroup';
import { AlertRunConfig } from '@/types/AlertRun';
import {
    AlertRunConfigWizardContext,
} from '@/components/ConfigurationEditors/AlertConfigurationEditor/AlertRunConfigWizard/AlertRunConfigWizardContext';
import { ProductRunConfigOptions } from '@/types/RunConfigOptions';
import { ByzzerTextInput, ByzzerTipIcon } from '@byzzer/ui-components';
import { CharacteristicCriteriaBuilder } from '@/components/CharacteristicCriteriaBuilder';
import { BrandCategoriesComboBuilder } from '@/components/BrandCategoriesComboBuilder';
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useCharacteristicService } from '@/services/characteristic.service';
import { SelectorLabelInfo } from '@/components/SelectorLabelInfo';

type ProductRunConfigFiltersValue = Partial<AlertRunConfig>;

export type ProductRunConfigFiltersProps = {
    name?: string;
    onChange?: (e: ByzzerChangeEvent<ProductRunConfigFiltersValue>) => void;
    onValidityChange?: (e: ByzzerValidityChangeEvent) => void;
    value?: ProductRunConfigFiltersValue;
    summary?: ReactNode;
    includeCategoryName?: boolean;
    requireCategoryName?: boolean;
    minProductSelections?: number;
    maxProductSelections?: number;
    requireProductSelections?: boolean;
    includeProductSelections?: boolean;
} & Partial<Omit<ProductRunConfigOptions, 'type' | 'title'>> &
    Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'alert-product-filters';

export type ProductRunConfigFiltersRef = {
    value: ProductRunConfigFiltersValue;
    isValid: boolean;
};

export const MESSAGE_NO_CATEGORY_MATCH =
    'The categories you have selected do not contain any of your focus brands. Please select at least one category with a green dot.';

export const ProductRunConfigFilters = forwardRef<ProductRunConfigFiltersRef, ProductRunConfigFiltersProps>(({
    className,
    value,
    onChange,
    onValidityChange,
    datatype,
    summary,
    name,
    includeFocusBrands,
    includeBrands,
    includeCategories,
    includeAggregationLevel,
    includeProductSubcategory,
    maxFocusBrands = 1,    
    minFocusBrands,
    requireFocusBrands,
    requireFocusBrand,
    minCategories,
    maxCategories,
    requireCategories,
    includeCharacteristics,
    maxCharacteristics = 5,
    includeCategoryName,
    requireCategoryName,
    minProductSelections,
    maxProductSelections,
    requireProductSelections,
    includeProductSelections,
    requireCharacteristics,
    ...props
}, ref) => {
    const { value: contextValue, onChange: onContextChange, state: contextState, runType, sku } = useContext(
        AlertRunConfigWizardContext
    );
    const { validateCharacteristicSelections } = useCharacteristicService();
    const { getCategoriesByBrands } = useTenantApi();
    const [internalValue, setInternalValue] = useState<ProductRunConfigFiltersValue>({});
    const [categoriesToCheckForIntersect, setCategoriesToCheckForIntersect] = useState<string[]>([]);
    const wasValid = useRef<boolean>(false);

    useEffect(() => {
        (async () => {
            const { focusBrands } = internalValue;
            const categories = await Promise.all([
                focusBrands?.length ? await getCategoriesByBrands(focusBrands) : [],
            ]);
            setCategoriesToCheckForIntersect(categories.flat());
        })();
    }, [internalValue.focusBrands]);

    useEffect(() => {
        const isValid = checkValidity();

        if (wasValid.current !== isValid) {
            onValidityChange?.({
                name,
                isValid
            });

            wasValid.current = isValid;
        }
    }, [internalValue]);

    useEffect(() => {
        setInternalValue(contextValue ?? {});
    }, [contextValue]);

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    function handleChange(e: ByzzerChangeEvent<unknown>) {
        onContextChange?.(e.name as keyof AlertRunConfig, e.value, e.data);
    }

    function handleFocusBrandChange(e: ByzzerChangeEvent<string[]>) {
        if (!e.value.length) {
            onContextChange?.('categories', [], []);
            onContextChange?.('characteristics', [], []);
        }
        onContextChange?.(e.name as keyof AlertRunConfig, e.value, e.data);
    }

    function handleCategoriesChange(e: ByzzerChangeEvent<string[]>) {
        if (!e.value.length) {
            onContextChange?.('characteristics', [], []);
        }
        onContextChange?.(e.name as keyof AlertRunConfig, e.value, e.data);
    }

    function checkValidity(): boolean {
        const characteristicsAndOrKeyCharacteristicsAreValid = validateCharacteristicSelections(
            internalValue.characteristics!,
            { requireCharacteristics }
        );

        return Boolean(
            characteristicsAndOrKeyCharacteristicsAreValid &&
            (!requireFocusBrands || Boolean(internalValue?.focusBrands?.length)) &&
            (!requireCategories || internalValue.categories?.length) &&
            (!requireProductSelections || internalValue.productSelections?.every((ps) => Boolean(ps?.categories?.length) && Boolean(ps?.focusBrands?.length)))
        );
    }

    return (
        <FilterGroup className={classnames(baseClassName, className)}>
            {summary && <div className={`${baseClassName}__summary`}>{summary}</div>}
            <FilterSection onlyRenderIf={includeProductSelections}>
                <BrandCategoriesComboBuilder
                    name={'productSelections'}
                    value={internalValue?.productSelections ?? []}
                    onChange={handleChange}
                    requireFocusBrands={requireFocusBrands}
                    requireCategories={requireCategories}
                    minFocusBrands={minFocusBrands}
                    maxFocusBrands={maxFocusBrands}
                    minCategories={minCategories}
                    maxCategories={maxCategories}
                    minProductSelections={minProductSelections}
                    maxProductSelections={maxProductSelections}
                    requireProductSelections={requireProductSelections}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeFocusBrands || includeCategories}>
                <ByzzerBrandSearch
                    onlyRenderIf={includeFocusBrands}
                    name={'focusBrands'}
                    maxSelections={maxFocusBrands}
                    value={internalValue.focusBrands}
                    onChange={handleFocusBrandChange}
                    label={
                        <SelectorLabelInfo
                            selectorCode={'focusBrands'}
                            max={maxFocusBrands}
                            min={minFocusBrands}
                            required={requireFocusBrands}
                            includeSuffix={!requireFocusBrands}
                            sku={sku!}
                        />
                    }
                />
                <ByzzerCategorySelect
                    name={'categories'}
                    onlyRenderIf={includeCategories}
                    value={internalValue.categories}
                    onChange={handleCategoriesChange}
                    placeholder={'Select from the list'}
                    allowClear={true}
                    disabled={!internalValue?.focusBrands?.length}
                    categorySelectionAggregationLevel={'category'}
                    categoriesToCheckForIntersect={categoriesToCheckForIntersect}
                    label={
                        <SelectorLabelInfo
                            required={requireCategories}
                            includeSuffix={!requireCategories}
                            sku={sku!}
                            selectorCode={'categories'}
                            max={maxCategories}
                        />
                    }
                    shouldDisplayIntersectIndicators={
                        requireFocusBrand || Boolean(categoriesToCheckForIntersect?.length)
                    }
                    maxSelections={maxCategories}
                    brands={internalValue?.focusBrands}
                    groupOptionsByBrandCoverage={requireFocusBrand || Boolean(categoriesToCheckForIntersect?.length)}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeCharacteristics}>
                <div className={`${baseClassName}__chars-filters`}>
                    <SelectorLabelInfo
                        required={requireCharacteristics}
                        includeSuffix={true}
                        sku={sku!}
                        selectorCode={'characteristicFilters'}
                        max={maxCharacteristics}
                    />
                </div>
                <CharacteristicCriteriaBuilder
                    name={'characteristics'}
                    value={internalValue.characteristics}
                    categories={
                        Boolean(internalValue?.categories?.length)
                            ? internalValue?.categories
                            : internalValue?.productSelections?.[0]?.categories
                    }
                    maxConditions={maxCharacteristics}
                    onChange={handleChange}
                    includeUpcOption={true} // should eventually be set in Alby
                    // brand={internalValue.focusBrands?.length === 1 ? internalValue.focusBrands?.[0] : undefined}
                />
            </FilterSection>
            <FilterSection onlyRenderIf={includeCategoryName}>
                <ByzzerTextInput
                    onChange={handleChange}
                    value={internalValue.categoryAlias ?? ''}
                    label={
                        <SelectorLabelInfo
                            required={requireCategoryName}
                            includeSuffix={true}
                            sku={sku!}
                            selectorCode={'categoryAlias'}
                        />
                    }
                    name={'categoryAlias'}
                    placeholder={'Category Name'}
                    className={`${baseClassName}__categoryName`}
                />
            </FilterSection>
        </FilterGroup>
    );
});

ProductRunConfigFilters.displayName = 'ProductRunConfigFilters';
