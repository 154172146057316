import './ScheduledReports.scss';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { AgGridReact } from 'ag-grid-react';
import { FilterChangedEvent, GridReadyEvent } from 'ag-grid-community';
import classnames from 'classnames';
import { formatInTimeZone } from 'date-fns-tz';
import { intersection } from 'lodash';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { ByzzerMenu } from '@/components/ByzzerMenu';
import { ByzzerTable } from '@/components/ByzzerTable';
import { useUser } from '@/contexts/UserContext';
import { useBetterNavigate } from '@/utils';
import DeleteIcon from '@/components/icons/DeleteIcon';
import EditIcon from '@/components/icons/EditIcon';
import { ScheduledReportsResponse } from '@/types/ApiTypes';
import { ReportRunConfig, RunConfigMarket } from '@/types/ReportRun';
import { ByzColDef } from '@/types/TableTypes';
import { confirm, openErrorModal, alert as byzzerAlert, ByzzerModal } from '@/components/form';
import { useTenantApi } from '@/hooks';
import { openRenameReportSeriesName } from '@/components/ConfigurationEditors/ReportConfigurationEditor/ScheduleReportStep/ScheduleModal';
import { triggerToast } from '@/notifications/ByzzerToast';
import EditModal from './ScheduledReports/EditModal';
import { getProductBySku } from '@/services/product.service';

/**
 * Useful Links -
 * https://www.ag-grid.com/react-data-grid/
 * https://www.ag-grid.com/react-data-grid/component-cell-renderer/
 * https://www.ag-grid.com/react-data-grid/cell-editing/
 * https://www.ag-grid.com/react-data-grid/cell-editing-start-stop/
 * https://www.ag-grid.com/react-data-grid/videos/
 * https://www.ag-grid.com/react-data-grid/change-detection/
 */

type ScheduledReportColdId =
    | 'id'
    | 'reportTitle'
    | 'seriesName'
    | 'status'
    | 'scheduledDate'
    | 'weekEndingDate'
    | 'scheduledBy'
    | 'brandsAndManufacturers'
    | 'categories'
    | 'timePeriod'
    | 'markets'
    | 'charDimensions'
    | 'characteristics'
    | 'menu';

type Status = 'Scheduled' | 'Not Delivered' | 'Delivered';

type ScheduledReport = {
    id: number;
    seriesId: number;
    sku: string;
    reportTitle: string;
    seriesName: string;
    status: Status;
    runConfig: ReportRunConfig;
    scheduledDate: string;
    weekEndingDate: string;
    scheduledBy: string;
    brands: string[];
    markets: string[];
    categories: string[];
    timePeriod: string;
    charDimension: string[];
    omniFocusProducts?: string[];
    charFilters: string;
    userId: number;
};

const defaultDateFormat = 'yyyy-MM-dd';
const baseClassName = 'scheduled-reports';

function ScheduledReports() {
    const { user, categories: subscriptionCategories, omniCategories: subscriptionOmniCategories } = useUser();
    const {
        getScheduledReports,
        deleteScheduledReport,
        getScheduledReportSeriesNames,
        updateScheduledReportSeriesName,
    } = useTenantApi();
    const [searchParams, setSearchParams] = useSearchParams();
    const [scheduledReports, setScheduledReports] = useState<ScheduledReport[]>([]);
    const navigate = useBetterNavigate();
    const [loading, setLoading] = useState(false);
    const [canRunReports, setCanRunReports] = useState(false);
    const [showEditDataModal, setShowEditDataModal] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<ScheduledReport | undefined>(undefined);
    const [editOption, setEditOption] = useState<ScheduleModifyMode | undefined>(undefined);
    const gridRef = useRef<AgGridReact>(null);
    const menuRefs = useRef<any[]>([]);
    const [_, setScheduledSeriesNamesRef, scheduledSeriesNamesRef] = useStateRef<string[]>([]);

    const userRole = user?.role ?? 'user';
    const userIsNonViewer = userRole !== 'viewer';

    useEffect(() => {
        loadScheduledReports();
        fetchScheduledSeriesNames();
    }, []);

    const loadScheduledReports = async () => {
        try {
            setLoading(true);
            const scheduledRepResponse: ScheduledReportsResponse[] = await getScheduledReports();
            const updatedScheduledReports = getModifiedScheduledReports(scheduledRepResponse);
            setScheduledReports(updatedScheduledReports);
        } catch (err: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchScheduledSeriesNames = async (): Promise<void> => {
        try {
            const response = await getScheduledReportSeriesNames();
            setScheduledSeriesNamesRef(response.scheduledSeriesNames);
        } catch (error) {
            console.error('error in fetchScheduledSeriesNames');
        }
    };

    const getCharactericticFilters = (charArray: any[]): string => {
        return charArray
            .map((item) => `${item.characteristicDisplayValue} ${item.condition} ${item.value.join(',')}`)
            .join('; ');
    };

    const getModifiedScheduledReports = (data: ScheduledReportsResponse[]): ScheduledReport[] => {
        return data.map(
            ({
                id,
                seriesId,
                sku,
                userId,
                weekEndingDate,
                seriesName,
                status,
                runConfig,
                scheduledDtm,
                scheduledBy,
                scheduleInfo,
            }) => {
                return {
                    id,
                    userId,
                    seriesId,
                    sku,
                    reportTitle: getProductBySku(sku)?.title,
                    seriesName,
                    status: getScheduleStatus(status),
                    runConfig,
                    scheduledDate: formatInTimeZone(new Date(scheduledDtm), 'GMT', defaultDateFormat),
                    weekEndingDate: formatInTimeZone(new Date(weekEndingDate), 'GMT', defaultDateFormat),
                    scheduledBy,
                    brands: [...(runConfig.focusBrands ?? []), ...(runConfig?.omniFocusProducts ?? [])],
                    markets: runConfig?.markets?.map((m: RunConfigMarket) => m.name) ?? [],
                    categories: runConfig.categories ?? [],
                    timePeriod: getTimeperiod(runConfig.timePeriod, scheduleInfo?.scheduleDates ?? []),
                    charDimension: runConfig?.productDimensions?.map((item) => item.characteristic) ?? [],
                    charFilters: getCharactericticFilters(runConfig?.characteristics ?? []),
                };
            }
        );
    };

    const getTimeperiod = (timePeriod: any, scheduleDates: any[]) => {
        const period = timePeriod.period;
        const formattedDates = scheduleDates.map((dateObj) => {
            const reportDeliverDate = new Date(dateObj.reportWeekEnding);
            const reportWeekEndingDate = new Date(dateObj.reportWeekEnding);
            const month = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ][reportDeliverDate.getMonth()];
            const year = reportDeliverDate.getFullYear();
            const weekEnding = reportWeekEndingDate.toLocaleDateString('en-GB').replace(/\//g, '-');
            return `${month} ${year} - W/E ${weekEnding}`;
        });

        return `${period}: ${formattedDates.join(', ')}`;
    };

    const getScheduleStatus = (status: ScheduleStatus): Status => {
        switch (status) {
            case 'pending':
            case 'in_progress':
            case 'scheduled':
                return 'Scheduled';
            case 'failed':
                return 'Not Delivered';
            default:
                return 'Delivered';
        }
    };

    const onDeleteRun = async (id: number) => {
        try {
            if (
                !(await confirm({
                    title: 'Delete Scheduled Run',
                    content: <div className="byzzer-allocation-warning">Do you want to delete the selected item ?</div>,
                    yesLabel: 'Yes',
                    noLabel: 'No',
                }))
            ) {
                return;
            }
            setLoading(true);
            let resp = await deleteScheduledReport(id);
            let alertContent = resp ? 'Report Schedule deleted successfully!' : 'Something went wrong';
            setLoading(false);
            byzzerAlert({
                // @ts-ignore
                content: <div className="report_modal_alertText">{alertContent}</div>,
            });
            if (resp) {
                loadScheduledReports();
            }
        } catch (err: any) {
            openErrorModal({
                title: `Something Unexpected Happened`,
                content: (
                    <>
                        <p>Fear not our engineering team is on the job.</p>
                    </>
                ),
                errorId: err.id,
            });
            setLoading(false);
        }
    };

    // Function to remove properties starting with 'condition', but keep 'conditions' array
    const filterJson = (json: object): object => {
        const filtered = {};

        Object.keys(json).forEach((key) => {
            const value = json[key];

            // Skip properties that start with 'condition' except 'conditions'
            if (key.startsWith('condition') && key !== 'conditions') {
                return;
            }

            if (typeof value === 'object' && value !== null) {
                // Recursively filter objects
                filtered[key] = Array.isArray(value)
                    ? value.map((item) => (typeof item === 'object' && item !== null ? filterJson(item) : item))
                    : filterJson(value);
            } else {
                // Copy primitive values
                filtered[key] = value;
            }
        });

        return filtered;
    };

    const queryStringToJson = (filtersString: string | null): Record<string, any> => {
        if (!filtersString) {
            return {}; // Return an empty object if filtersString is null
        }

        try {
            const decodedString = decodeURIComponent(filtersString);
            return JSON.parse(decodedString);
        } catch (error) {
            console.error('Error parsing query string to JSON:', error);
            return {}; // Return an empty object if parsing fails
        }
    };

    const jsonToQueryString = (json: Record<string, any>): string => {
        try {
            const jsonString = JSON.stringify(json);
            return encodeURIComponent(jsonString);
        } catch (error) {
            console.error('Error converting JSON to query string:', error);
            return ''; // Return an empty string if conversion fails
        }
    };

    const stringArrayToString = (params: string[]): string => {
        return params.join('; ');
    };

    const handleFilterChanges = (e: FilterChangedEvent<any>) => {
        let filterModel = e.api.getFilterModel();
        // Convert filter model to query string
        const queryString = jsonToQueryString(filterJson(filterModel));

        setSearchParams({ filters: queryString });
    };

    const closeEditModal = () => {
        setSelectedRowData(undefined);
        setShowEditDataModal(false);
        setEditOption(undefined);
    };

    const handleNextOnEdit = () => {
        navigate(`/dashboard/configure_report`, {
            search: {
                sku: selectedRowData?.sku,
                runType: 'subscription',
                scheduleId: selectedRowData?.id,
                scheduleSeriesId: selectedRowData?.seriesId,
                scheduleMode: editOption,
            },
        });
    };

    const handleEditOptionChange = (option: ScheduleModifyMode) => {
        setEditOption(option);
    };

    // Handle Grid Ready Event
    const handleGridReady = (e: GridReadyEvent) => {
        let queryString = searchParams.get('filters');
        const generatedFilterModelFromParams = queryStringToJson(queryString);

        // Set filter model on grid
        e.api.setFilterModel(generatedFilterModelFromParams);
    };

    const defaultColDef = useMemo(
        () => ({
            filter: true, // make every column use 'text' filter by default
            sortable: true,
            floatingFilter: true, // enable floating filters by default
            resizable: true, //set each col resizable
            width: 170, // set every column width
            flex: 0,
            autoHeight: false,
            wrapText: false,
            suppressMenu: true,
            lockVisible: true,
        }),
        []
    );

    const columnDefs: Array<ByzColDef<ScheduledReport, ScheduledReportColdId>> = useMemo(() => {
        return [
            {
                colId: 'reportTitle',
                headerName: 'Report',
                pinned: 'left',
                valueGetter: ({ data }) => data?.reportTitle,
                tooltipValueGetter: ({ data }) => data?.reportTitle,
                disableHide: true,
            },
            ...(window.localStorage['show-report-schedule-id'] === 'true'
                ? ([
                      {
                          colId: 'id' as const,
                          headerName: 'Report ID',
                          valueGetter: ({ data }) => {
                              return data.id;
                          },
                      },
                  ] as ByzColDef<ScheduledReport, ScheduledReportColdId>[])
                : []),
            {
                colId: 'seriesName',
                headerName: 'Series Name',
                valueGetter: ({ data }) => data.seriesName,
                tooltipValueGetter: ({ data }) => data.seriesName,
            },
            {
                colId: 'status',
                headerName: 'Status',
                valueGetter: ({ data }) => data.status,
                tooltipValueGetter: ({ data }) => data.status,
            },
            {
                colId: 'scheduledDate',
                headerName: 'Scheduled Date',
                valueGetter: ({ data }) => data.scheduledDate,
                tooltipValueGetter: ({ data }) => data.scheduledDate,
            },
            {
                colId: 'weekEndingDate',
                headerName: 'W/E Date',
                valueGetter: ({ data }) => data.weekEndingDate,
                tooltipValueGetter: ({ data }) => data.weekEndingDate,
            },
            {
                colId: 'scheduledBy',
                headerName: 'Scheduled By',
                valueGetter: ({ data }) => data.scheduledBy,
                tooltipValueGetter: ({ data }) => data.scheduledBy,
            },
            {
                colId: 'brandsAndManufacturers',
                headerName: 'Brand(s) / Manufacturer(s)',
                valueGetter: ({ data }) => stringArrayToString([...data.brands]),
                tooltipValueGetter: ({ data }) => stringArrayToString([...data.brands]),
                width: 300,
            },
            {
                colId: 'categories',
                headerName: 'Category(s)',
                tooltipField: 'categories',
                valueGetter: ({ data }) => stringArrayToString(data.categories),
                cellRenderer: ({ data }) => {
                    return (
                        <span
                            className={classnames({
                                [`${baseClassName}__warning`]: !intersection(data?.categories, subscriptionCategories)
                                    .length,
                            })}
                        >
                            {stringArrayToString(data.categories)}
                        </span>
                    );
                },
            },
            {
                colId: 'timePeriod',
                headerName: 'Time Period',
                valueGetter: ({ data }) => data.timePeriod,
                tooltipValueGetter: ({ data }) => data.timePeriod,
            },
            {
                colId: 'markets',
                headerName: 'Market(s)',
                tooltipField: 'markets',
                valueGetter: ({ data }) => stringArrayToString(data.markets),
            },
            {
                colId: 'charDimensions',
                headerName: 'Characteristic Dimension(s)',
                valueGetter: ({ data }) => data.charDimension,
                tooltipValueGetter: ({ data }) => data.charDimension,
                width: 300,
            },
            {
                colId: 'characteristics',
                headerName: 'Characteristic(s)',
                valueGetter: ({ data }) => data.charFilters,
                tooltipValueGetter: ({ data }) => data.charFilters,
                width: 300,
            },
            {
                headerName: 'Actions',
                colId: 'menu',
                suppressMenu: true,
                sortable: false,
                filter: false,
                floatingFilter: false,
                resizable: false,
                pinned: 'right',
                width: 150,
                cellRenderer: ({ data }) => {
                    const handleModify = () => {
                        setSelectedRowData(data);
                        setShowEditDataModal(true);
                    };

                    return (
                        <div className={`${baseClassName}__actions`}>
                            {userIsNonViewer && (
                                <>
                                    <EditIcon className={`${baseClassName}`} onClick={handleModify} />
                                    <DeleteIcon className={`${baseClassName}`} onClick={() => onDeleteRun(data.id)} />
                                </>
                            )}
                            <div className={`${baseClassName}__actions-dropdown`}>
                                <div
                                    key={data.id}
                                    className={classnames(`${baseClassName}__menu-trigger`)}
                                    ref={(thisElement) => menuRefs.current[String(data.id)] = { current: thisElement }}
                                />
                                <ByzzerMenu
                                    className={`${baseClassName}__menu`}
                                    reference={menuRefs.current[String(data.id)]}
                                    offset={[-90, -5]}
                                    triggerTarget={menuRefs.current[String(data.id)]?.current}
                                    highlightItemOnHover={true}
                                    items={[
                                        {
                                            onClick: async () => {                                                
                                                if (!data.seriesName || !userIsNonViewer) return;

                                                if(data.userId !== user?.id) {
                                                    byzzerAlert({
                                                        content: <>
                                                            <p>You cannot edit this series name because you did not create it.</p>
                                                        </>
                                                    })
                                                    return;
                                                }

                                                try {
                                                    const updatedReportName = await openRenameReportSeriesName({
                                                        seriesId: data.seriesId,
                                                        seriesName: data.seriesName,
                                                        seriesNames: scheduledSeriesNamesRef.current,
                                                        updateScheduledReportSeriesName,
                                                    });

                                                    if (updatedReportName) {
                                                        await loadScheduledReports();
                                                        await fetchScheduledSeriesNames();
                                                        triggerToast({
                                                            content: 'series name updated successfully',
                                                        });
                                                    }
                                                } catch (error) {
                                                    byzzerAlert({
                                                        type: 'error',
                                                        title: 'Series Name Update Failed',
                                                        content: (
                                                            <>
                                                                <p>
                                                                    There was an unexpected error during this series
                                                                    name update.
                                                                </p>
                                                                <p>
                                                                    Please contact the support team for additional
                                                                    assistance.
                                                                </p>
                                                            </>
                                                        ),
                                                    });
                                                }
                                            },
                                            content: 'Rename series',
                                            disabled: !data.seriesName || !userIsNonViewer,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    );
                },
                disableHide: true,
            },
        ];
    }, [subscriptionCategories, subscriptionOmniCategories]);

    return (
        <div
            className={classnames(baseClassName, {
                [`${baseClassName}--readonly`]: !canRunReports,
                [`${baseClassName}--runnable`]: canRunReports,
            })}
        >
            <ByzzerMask show={loading} loading={loading} />

            {Boolean(!scheduledReports?.length) && !loading && (
                <div className={`${baseClassName}__empty-state`}>You Haven't Scheduled Any Reports Yet.</div>
            )}

            {showEditDataModal && (
                <EditModal
                    show={showEditDataModal}
                    onClose={closeEditModal}
                    size="small"
                    type="info"
                    heading="What do you want to modify?"
                    editOption={editOption!}
                    seriesName={selectedRowData?.seriesName!}
                    onNextClicked={handleNextOnEdit}
                    onEditOptionChange={handleEditOptionChange}
                    isEditRestricted={selectedRowData?.userId !== user?.id}
                />
            )}

            {Boolean(scheduledReports?.length) && (
                <ByzzerTable
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={scheduledReports} // Row Data for Rows
                    columnDefs={columnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Column Defs for Columns
                    readOnlyEdit={true}
                    singleClickEdit={true}
                    onFilterChanged={handleFilterChanges}
                    onGridReady={handleGridReady}
                    tableArea={'scheduledReports'}
                    enableColumnHideShow={true}
                    enableSaveLayout={true}
                    enableSaveSort={true}
                />
            )}
        </div>
    );
}

export default ScheduledReports;
