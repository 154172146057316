import {ByzzerInput, LegacyByzzerSelect} from "@/components/form";
import React, {useEffect, useState} from "react";
import {create as createModal} from "react-modal-promise";
import {TipIcon} from "@/components/icons";
import LockIcon from "@/images/icons/LockIcon.svg"
import ByzzerModal2 from "@/components/modals/ByzzerModal2";
import {useTenantApi} from "@/hooks/useTenantApi";
import {openErrorModal} from "@/components/form/ByzzerModal";
import {openTeamRoleInfo} from "@/components/TeamManager/TeamRoleInfo";
import {openTeamInfo} from "@/components/TeamManager/TeamInfo";
import {useUser} from "@/contexts/UserContext";
import { TeamMember } from "@/types/InvitationTypes";
import { ByzzerButton } from "@byzzer/ui-components";
import { emailDomainFoundInDomainList } from "@/utils";

const teamRoleOptions = [
    {value: 'admin', display: 'Admin'},
    {value: 'user', display: 'User'},
    {value: 'viewer', display: 'Viewer'}
];

function MemberEditor({onResolve, teamOptions = [], user, ...props}) {
    const {updateUser} = useTenantApi();
    const {nsCompany} = useUser();

    const [showingErrorModal, setShowingErrorModal] = useState(false);
    const [busy, setBusy] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updates, setUpdates] = useState<TeamMember>({
        ...user,
    });

    useEffect(() => {
        setCanSave(Boolean(
            updates.firstName?.trim()
            && updates.lastName?.trim()
            && updates?.teamId
            && updates.role
        ));
    }, [updates])

    useEffect(() => {
        // const {id, ...rest} = user;
        // setUpdates(rest);
    }, [user])

    async function onTeamRoleInfo() {
        openTeamRoleInfo()
    }

    async function onTeamInfo() {
        const result = await openTeamInfo()
        if (result.function === "redirect") {
            onCloseClick();
        }
    }

    async function onSaveClick() {
        try {
            setBusy(true)
            await updateUser(user.id, updates);
            onResolve('updated');
        } catch (err: any) {
            setShowingErrorModal(true);

            await openErrorModal({
                title: 'Uh Oh',
                content: <>
                    <p>Something unexpected happened while we were updating the member.</p>
                </>,
                errorId: err.id
            });
            setShowingErrorModal(false);
        } finally {
            setBusy(false);
        }
    }

    async function onDeleteClick() {
        onResolve('delete');
    }

    function addUpdate({target}) {
        setUpdates(current => ({
            ...current,
            [target.name]: target.value,
        }));
    }

    function setTeamRole(role) {
        setUpdates(current => ({
            ...current,
            role
        }));
    }

    function setTeamId(teamId) {
        setUpdates(current => ({
            ...current,
            teamId
        }));
    }

    function onCloseClick() {
        onResolve(false);
    }

    return (
        // @ts-ignore
        <ByzzerModal2 show={!showingErrorModal} className={'subscription-users__member-editor'}>
            <ByzzerModal2.Header className="subscription-users__modal-title" onClose={onCloseClick}>
                Edit Member Details
            </ByzzerModal2.Header>
            <ByzzerModal2.Content className="member-editor__content">
                {/* @ts-ignore */}
                <ByzzerModal2.Mask show={loading} />
                {emailDomainFoundInDomainList(updates.email, nsCompany?.domains) && (
                    <>
                        {/* @ts-ignore */}
                        <ByzzerInput
                            label={'First Name'}
                            className="subscription-users__modal-add-item-input"
                            name="firstName"
                            value={updates.firstName}
                            onChange={addUpdate}
                            variant={'secondary'}
                        />
                        {/* @ts-ignore */}
                        <ByzzerInput
                            label={'Last Name'}
                            className="subscription-users__modal-add-item-input"
                            name="lastName"
                            value={updates.lastName}
                            onChange={addUpdate}
                            variant={'secondary'}
                        /> 
                    </>
                )}
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Email'}
                    className="member-editor__input-locked"
                    name="email"
                    type={'email'}
                    placeholder={updates.email}
                    readOnly
                    variant={'secondary'}
                />

                <div className="member-editor__form-group">
                    <h2>
                        {/* @ts-ignore */}
                        Member Type <TipIcon onClick={onTeamRoleInfo}></TipIcon>
                    </h2>
                    {/* @ts-ignore */}
                    <LegacyByzzerSelect
                        className="subscription-users__modal-add-item-input"
                        name={'role'}
                        onChange={setTeamRole}
                        value={updates.role}
                        placeholder={'Membe\n' +
                            '                <ByzzerButton label={"Save"} disabled={!canSave}\n' +
                            '                    busy={busy}\n' +
                            '                    onClick={onSaveClick} />r Type'}
                        options={teamRoleOptions}/>
                </div>
                <div className="member-editor__form-group">
                    {/* @ts-ignore */}
                    <h2>Choose a Team <TipIcon onClick={onTeamInfo}/></h2>
                    {/* @ts-ignore */}
                    <LegacyByzzerSelect
                        className="subscription-users__modal-add-item-input"
                        readonly={true}
                        name={'team'}
                        onChange={setTeamId}
                        value={updates?.teamId}
                        placeholder={'Choose a Team'}
                        options={teamOptions}
                        variant={'secondary'}
                    />
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className="subscription-users__modal-footer">
                <ByzzerButton className="subscription-users__modal-footer-remove" type="negative"
                              onClick={onDeleteClick} label="Remove Member" disabled={busy}/>

                <ByzzerButton label={"Save"} disabled={!canSave || busy}
                              onClick={onSaveClick} />
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
                                        // @ts-ignore
export const openMemberEditor = createModal(MemberEditor)