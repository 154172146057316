import './DownloadableProducts.scss';
import React, {useState} from 'react';
import classnames from 'classnames';
import {useUser} from "@/contexts/UserContext";
import {LegacyByzzerButton} from "@/components/form";
import {TrackClick} from "@/analytics";

const baseClassName = 'downloadable-products';

export default function DownloadableProducts({className, ...props}) {

    const {downloadableProducts} = useUser();

    return <div className={classnames(baseClassName, className)} {...props}>
        {downloadableProducts.map(download => (
            <div className={`${baseClassName}__product`}>
                <h1 className={`${baseClassName}__title`}>{download.displayName}</h1>
                <p className={`${baseClassName}__description`}>{download.description}</p>
                <TrackClick name={`Canned Reports - ${download.displayName} downloaded`}>
                    <LegacyByzzerButton>
                        <a href={download.url}>Download It</a>
                    </LegacyByzzerButton>
                </TrackClick>
            </div>
        ))}
    </div>
}