import { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import './UserPersonalInfo.scss';
import ByzzerInput from '@/components/form/ByzzerInput';
import { LegacyByzzerButton, ByzzerErrorModal } from './form';
import {useTenantApi} from '@/hooks/useTenantApi';
import { useUser } from '@/contexts/UserContext';
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { disableProfileSave } from '@/config'
import classnames from 'classnames';
import { OTHER_PERSONA_CODE, PersonaSelections } from '@/pages/Onboarding/OnboardingSteps/AboutYou/AboutYouContent';
import { snakeCaseToTitleCase } from '@/utils';
import { openPersonasEditor } from '@/pages/HomePageConfigEditor/PersonasEditor';


// todo: move this to a common location
const memberTypeDisplayMapping = {
    'admin': 'Admin',
    'viewer': 'Viewer',
    'user': 'User',
}

type ProfileData = {
    firstName: string;
    lastName: string;
    email?: string;
    company?: string;
    role?: string;
    personaSelections?: PersonaSelections;
}

function PersonasLabel({ onClick }: {onClick: () => void}) {
    function handleClick() {
        onClick();
    }
    return (
        <span className={classnames(`${baseClassName}__personas-label`)}>
            <span>Persona(s)</span> 
            <span className={classnames(`${baseClassName}__personas-edit`)} onClick={handleClick}/>
        </span>
    )
}

const baseClassName = 'user-personal-info';

const UserPersonalInfo = (props) => {
    const { updateMe } = useTenantApi();
    const { user, company, refreshUser, isCsrObo, oboRole } = useUser();
    const [profileData, setProfileData] = useState<ProfileData>({
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.email ?? '',
        company: company?.displayName ?? company?.name ?? '',
        role: memberTypeDisplayMapping[user?.role!] ?? ''
    });
    const [loading, setLoading] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [contentType, setContentType] = useState("warning");
    const canEdit = !isCsrObo || oboRole !== 'sales';
    const isFinancialServicesAccessLevel = ['banks_finance'].includes(company?.accessLevel!);

    useEffect(() => {
        if (user) {
            const {
                firstName,
                lastName,
                email,
                role
            } = user;
            setProfileData({
                firstName,
                lastName,
                email,
                role: memberTypeDisplayMapping[role],
            })
        }
    }, [user?.email, user?.firstName, user?.lastName]);

    useEffect(() => {
        if (company) {
            const {
                displayName,
                name,
            } = company;
    
            setProfileData((currProfile) => ({
                ...currProfile,
                company: displayName ?? name
            }))
        }
    }, [company?.displayName, company?.name]);

    useEffect(() => {
        if (profileData.firstName === user?.firstName && profileData.lastName === user?.lastName) {
            setSaveEnabled(false)
        } else {
            setSaveEnabled(Boolean(profileData.firstName?.trim().length) && Boolean(profileData.lastName?.trim().length));
        }
    }, [profileData?.firstName, profileData?.lastName])

    const handleSaveUserDetails = async () => {
        try {
            if (!disableProfileSave) { // restrict api call if it's a DEMO or UAT env
                setLoading(true);
                const res = await updateMe({
                    firstName: profileData?.firstName, 
                    lastName: profileData?.lastName
                });
                if (res) {
                    message.success('User details saved successfully.');
                    setSaveEnabled(false);
                }
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setShowWarning(true);
            setContentType("error");
            setWarningMessage("Unable to save the user details. Something went wrong.");

        }
    };

    function updatePersonalInfo({ target }) {
        setProfileData({
            ...profileData,
            [target.name]: target.value.trim(),
        });
    }

    async function handleEditPersonasClick() {
        // @ts-ignore
        await openPersonasEditor({includeSubmit: true});
    }

    const personasText = useMemo(() => user?.metadata?.personaCodes?.map(persona => {
        const casedPersona = snakeCaseToTitleCase(persona);
        if (user?.metadata?.otherPersonaDesc && casedPersona.toLowerCase() === OTHER_PERSONA_CODE.toLowerCase()) {
            return `${casedPersona} (${user?.metadata?.otherPersonaDesc})`;
        }
        return casedPersona;
    })?.join(', '), [user?.metadata?.otherPersonaDesc, user?.metadata?.personaCodes]);

    const showPersonas = !isFinancialServicesAccessLevel;

    return (
        <div className={classnames(`${baseClassName}`)}>
            <ByzzerMask show={loading} loading={loading} />
            <h2 className={classnames(`${baseClassName}__title`)}>Your Personal Info</h2>
            <div className={classnames(`${baseClassName}__form`)}>
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'First Name'}
                    name="firstName"
                    disabled={!canEdit || disableProfileSave}
                    className={canEdit ? '' : 'input--locked'}
                    value={profileData?.firstName}
                    onChange={updatePersonalInfo}
                />
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Last Name'}
                    name="lastName"
                    disabled={!canEdit || disableProfileSave}
                    className={canEdit ? '' : 'input--locked'}
                    value={profileData?.lastName}
                    onChange={updatePersonalInfo} />

                {/* @ts-ignore */}
                <ByzzerInput
                    className={'input--locked'}
                    label={'Email'}
                    name="email"
                    disabled={true}
                    value={profileData?.email} />
                {/* @ts-ignore */}
                <ByzzerInput
                    className={'input--locked'}
                    label={'Role'}
                    name="role"
                    disabled={true}
                    value={profileData?.role} />
                    {/* @ts-ignore */}
                <ByzzerInput
                    className={'input--locked'}
                    label={'Company'}
                    name="company"
                    disabled={true}
                    value={profileData?.company} />
                {showPersonas && (
                    // @ts-ignore
                    <ByzzerInput // TODO: Come up with a better component for displaying this kind of information
                        className={'edit'}
                        label={<PersonasLabel onClick={handleEditPersonasClick}/>}
                        name={'personas'}
                        disabled={true}
                        value={personasText}
                    />
                )}
            </div>
            {(canEdit && !disableProfileSave) && (
                <div className={classnames(`${baseClassName}__actions`)}>
                    <LegacyByzzerButton label={'Save'} disabled={disableProfileSave || !saveEnabled} onClick={handleSaveUserDetails} />
                </div>
            )}
            {showWarning && (
                <ByzzerErrorModal
                    showWarning={showWarning}
                    setShowWarning={setShowWarning}
                    headerType={'none'}
                    contentType={contentType}
                    warningMessage={warningMessage}
                    size={'small'}
                />
            )}
        </div>
    );
};

export default UserPersonalInfo;
