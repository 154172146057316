import './EditModal.scss';
import React from 'react';
import { ByzzerModal } from '@/components/form';
import { ByzzerButton, ByzzerSelectOption, ByzzerRadio } from '@byzzer/ui-components';

interface EditModalProps {
    show: boolean;
    onClose: Function;
    size: string;
    type: string;
    heading: string;
    editOption: ScheduleModifyMode;
    seriesName: string;
    onNextClicked: (value: ScheduleModifyMode) => void;
    onEditOptionChange: (value: ScheduleModifyMode) => void;
    isEditRestricted: boolean;
}

const baseClassName = 'scheduled-report-edit-modal';
const DISABLED_TIP = 'Please select the appropriate option to modify the schedule.';
const DISABLED_OPTION_TIP = "You cannot modify this scheduled series because you did not create it";

const EditModal: React.FC<EditModalProps> = ({
    show,
    onClose,
    size,
    type,
    heading,
    editOption,
    seriesName,
    isEditRestricted,
    onNextClicked,
    onEditOptionChange,
}) => {
    const modifyOptions: ByzzerSelectOption<any, ScheduleModifyMode>[] = [
        {
            display: `Edit the selections for the entire series "${seriesName}"`,
            value: 'editScheduleSeries',
            data: {
                disabled: isEditRestricted,
                disabledTip: DISABLED_OPTION_TIP,
                
            }
        },
        {
            display: `Edit only the schedule for the series "${seriesName}"`,
            value: 'editSchedule',
            data: {
                disabled: isEditRestricted,
                disabledTip: DISABLED_OPTION_TIP,
            }
        },
        {
            display: `Create a new run by copying the selections from "${seriesName}"`,
            value: 'copySchedule',
        },
    ];

    const radioChanged = (modifyMode: ScheduleModifyMode) => {        
        onEditOptionChange(modifyMode);
    };

    const isRadioChecked = (value: ScheduleModifyMode) => {
        return value === editOption;
    };

    const nextClickedHandler = () => {
        onNextClicked(editOption);
    };

    const handleModalClose = () => {
        onClose();
    };

    return (
        <ByzzerModal
            show={show} // Controlled modal visibility
            onClose={handleModalClose} // Close the modal when triggered
            size={size}
            type={type}
            heading={heading}
        >
            <div className={baseClassName}>
                {modifyOptions.map((val) => (
                    <div className={`${baseClassName}__option`} key={val.value}>
                        <ByzzerRadio
                            className={`${baseClassName}__radio`}
                            key={val.value}
                            label={val.display}
                            value={val.value}
                            onChange={() => radioChanged(val.value)}
                            checked={isRadioChecked(val.value)}
                            disabled={val.data?.disabled}
                            disabledTip={val.data?.disabledTip}
                            disabledTipTrigger={'hover'}
                        />
                    </div>
                ))}
                <div className={`${baseClassName}__action`}>
                    <ByzzerButton
                        label="Next"
                        onClick={nextClickedHandler}
                        disabled={!editOption}
                        disabledTip={DISABLED_TIP}
                    />
                </div>
            </div>
        </ByzzerModal>
    );
};

export default React.memo(EditModal);
