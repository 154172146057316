import {useTenantApi} from '@/hooks/useTenantApi';
import { Dispatch, FC, ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useEvents, useUser } from './UserContext';
import { MOCK_CHARACTERISTICS } from '@/components/CharacteristicCriteriaBuilder/CharacteristicCriteriaBuilderConstants';
import { isEmpty } from 'lodash';
import { getAllReports } from '@/services/product.service';

export type MyHomePageContext = {
    homePageState: HomePageContextValue;
    setHomePageState: Dispatch<any>;
    isContextLoading: Boolean;
    handleFilterApply: (payloadFilterValue) => void;
    resyncWithDefaultTiles: (reportletId) => void;
    refreshReportlet: () => void;
    tilesLoadingState: any;
    refreshReason: string;
    handleTilesLoadingState: (tilesState) => void;
};
export type HomePageContextValue = {
    userPreferenceDefault: any;
    userPreferenceCustom: any;
    filterValues: any;
    reportletData: any;
    tiles: any[];
};

interface HomePageProps {
    children?: ReactNode;
    tilesData?: any;
    initial?: any;
}

export const MyHomepageContext = createContext<MyHomePageContext>({} as any);

export const useMyHomePageContext = () => useContext(MyHomepageContext).homePageState;

export const TILE_TYPES = {
    STATIC: 'static',
    DYNAMIC: 'dynamic',
};

export const MyHomepageContextProvider: FC<HomePageProps> = ({ children }) => {
    const sceneA_withBrandReportSkus = ['242', '484'];
    const sceneA_withoutBrandReportSkus = ['484'];
    const { categories, maxDataDates, company, defaultRunConfig, subscribedSkus, preSales, brands } = useUser();
    const allReports = getAllReports();
    const [isContextLoading, setIsLoading] = useState<boolean>(true);
    const [tilesLoadingState, setTilesLoadingState] = useState<object>({});
    const [refreshReason, setRefreshReason] = useState<string>('');
    const { fetchReportlet, getCompanyReportRuns, getUserPreferences, homePageTileById, homePageTiles, resetReportlet, updateReportlet } = useTenantApi();

    const events = useEvents();

    let freeTileVideoDescription = 'Schedule a demo with live data';
    let freeTileVideoLink = 'https://meetings.salesloft.com/nielseniq/nevemarlowe';
    let callActionType = 'meet';
    if (
        company?.features.freeAccountGroup === 'A' &&
        defaultRunConfig?.brands?.length &&
        defaultRunConfig?.categories?.length
    ) {
        freeTileVideoDescription = 'Take the Byzzer tour';
        freeTileVideoLink = '492458';
        callActionType = 'tour';
    }
    const FREE_USER_HOMEPAGE_TILES = [
        {
            isError: false,
            id: 0,
            vizType: 'video',
            type: TILE_TYPES.STATIC,
            content: {
                type: 'video',
                link: 'cl9frwokzz',
            },
            callToAction: {
                type: callActionType,
                description: freeTileVideoDescription,
                link: freeTileVideoLink,
            },
        },
        {
            isError: false,
            id: 1,
            vizType: 'pdf',
            type: TILE_TYPES.STATIC,
            content: {
                //   "title": "Byzzer Guide",
                type: 'pdf',
                link: 'https://nielseniq.com/global/en/landing-page/smb-us-byzzer-features/',
                image: 'https://nielseniq.com/wp-content/uploads/sites/4/2022/01/Frame-94.png',
            },
            callToAction: {
                description: 'Take the Byzzer tour',
                type: 'tour',
                link: '492458'
            },
        },
    ];
    const initialState = {
        tiles: [],
        userPreferenceDefault: {},
        userPreferenceCustom: {},
        reportletData: {},
        filterValues: {},
    };

    const reducer = (state, action) => {
        const newState = state;

        if (action.type) {
            switch (action.type) {
                case 'tile_update':
                    const updatedTiles = newState.tiles.map((tile) => {
                        if (tile.vizType === action.vizType) {
                            return { ...tile, ...action.updateTileObject };
                        }
                        return tile;
                    });
                    return { ...state, tiles: updatedTiles };
                case 'filter_update':
                    const filterState = newState.filterValues;
                    const updateFilter = { ...filterState, [action.filterKey]: action.filterUpdateValue };
                    return { ...newState, filterValues: updateFilter };
                case 'tiles_refresh_all':
                    const loadingState = state.tiles.map((tile) => {
                        return { ...tile, isLoadingFilter: true };
                    });
                    return { ...state, tiles: loadingState };
                case 'tile_loading_state_update':
                    const updateLoadingState = state.tiles.map((tile) => {
                        if (tile.id === action.updateTileId) {
                            return { ...tile, isLoading: action.updateTileState };
                        }
                        return tile;
                    });
                    return { ...state, tiles: updateLoadingState };
                default:
                    break;
            }
        } else {
            const updateData = { ...newState, ...action };
            return updateData;
        }
    };

    const [homePageState, setHomePageState] = useReducer(reducer, initialState);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        const event = events?.[0];
        if (event) {
            switch (event?.type) {
                case 'reportlet_data_refreshed': {
                    const body: { reportletId: number } = event.body;
                    const reportletId = body.reportletId;
                    if (reportletId) {
                        updateTile(reportletId);
                        const tileRefreshState = { ...tilesLoadingState };
                        tileRefreshState[reportletId] = false;
                        setTilesLoadingState((prevState) => {
                            return {
                                ...prevState,
                                ...tileRefreshState,
                            };
                        });
                    }   
                    break;
                }
                case 'report_defaults_configured':{
                    const tileState = Object.keys(tilesLoadingState).reduce((result, tile) => {
                        result[tile] = true;
                        return result;
                    }, {});
                    setTilesLoadingState(tileState);
                    break;
                }
                case 'reportlet_data_initiated': {
                    const body: { updateReason: string } = event.body;
                    if (body.updateReason) {
                        setRefreshReason(body.updateReason ?? '');
                    }
                   refreshReportlet();
                    break;
                }
                case 'reportlet_data_error':
                    initialize();
                    break;
            }
        }
    }, [events]);

    useEffect(() => {
        if (!(Object.values(tilesLoadingState).length>0) || Object.values(tilesLoadingState).some((value) => value === true)) {
            const checkAndRefresh = () => {
                for (const key in tilesLoadingState) {
                    if (tilesLoadingState[key] === true) {
                        updateTile(key);
                        const tileRefreshState = { ...tilesLoadingState };
                        tileRefreshState[key] = false;
                        setTilesLoadingState((prevState) => {
                            return { ...prevState, ...tileRefreshState };
                        });
                    } else {
                        clearInterval(refreshInterval);
                    }
                }
            };
            const refreshInterval = setInterval(checkAndRefresh, 60000);
            return () => clearInterval(refreshInterval);
        } else {
            setRefreshReason('Completed');
        }
    }, [tilesLoadingState]);

    const updateTile = async (tileId) => {
        try {
            const tileInfo = await homePageTileById(tileId);

            const endDate = maxDataDates?.rms;
            setHomePageState({
                type: 'tile_update',
                vizType: tileInfo.vizType,
                updateTileObject: { ...tileInfo, endDate: endDate },
            });
        } catch (error) {}
    };
    const getHomePageReportTiles = (reportSkus, reportRunSkus) => {
        let homePageReportTiles = allReports
            .filter((report) => reportSkus.includes(report.sku))
            .map((report) => {
                return {
                    isError: false,
                    vizType: 'report',
                    type: TILE_TYPES.STATIC,
                    id: report.id,
                    callToAction: {
                        description:
                        reportRunSkus.includes(report.sku) ? 'Analyze your Category' : 'Run your first retail report with us',
                    },
                    reportData: { ...report },
                };
            });
        return homePageReportTiles;
    };
    async function initialize() {
        try {
            setIsLoading(true);
            const reportletData = await fetchReportlet();
            let [reportRuns] = await Promise.all([getCompanyReportRuns(subscribedSkus)]);
            let reportRunSkus = reportRuns.map((reportRun) => reportRun.sku);
            const userPreferenceDefault = await getUserPreferences();
            const userHomePageTiles = await homePageTiles();
            let filteredTiles;
            if ( preSales && company?.features?.freeAccountGroup === 'B') {
                filteredTiles = FREE_USER_HOMEPAGE_TILES;
            } else if (preSales &&   company?.features?.freeAccountGroup === 'A') {
                if (defaultRunConfig?.brands?.length) {
                    filteredTiles = [
                        // Commented code to be removed later after getting confirmation on BYZ-12069
                        // ...userHomePageTiles
                        //     .filter((tile) => tile.vizType === 'trend')
                        //     .map((tile) => {
                        //         return {
                        //             ...tile,
                        //             callToAction: {
                        //                 type: 'meet',
                        //                 description: 'Schedule a demo with live data',
                        //                 link: 'https://meetings.salesloft.com/nielseniq/aaronmacklin1',
                        //             },
                        //         };
                        //     }),
                        ...FREE_USER_HOMEPAGE_TILES,
                        ...getHomePageReportTiles(sceneA_withBrandReportSkus,reportRunSkus),
                    ];
                  
                } else {
                    filteredTiles = [
                        ...FREE_USER_HOMEPAGE_TILES,
                        ...getHomePageReportTiles(sceneA_withoutBrandReportSkus,reportRunSkus),
                        ...userHomePageTiles
                            .filter((tile) => tile.vizType === 'trend')
                            .map((tile) => {
                                return {
                                    ...tile,
                                    callToAction: {
                                        type: 'meet',
                                        description: 'Schedule a demo with live data',
                                        link: 'https://meetings.salesloft.com/nielseniq/nevemarlowe',
                                    },
                                };
                            }),
                    ];
                }
            } else {
                filteredTiles = [
                    ...userHomePageTiles.filter((tile) => tile.vizType === 'trend' || tile.vizType === 'line'),
                ];
            }

            const userPreferenceCustom = await checkCustomReportletPreference(reportletData);
            // setFilterValues(userPreferences);
            const emptyDefaultCategory = (reportletData.parameterValues && reportletData.parameterValues.length > 0) ? reportletData.parameterValues?.primaryCategory?.length === 0 : true;


            const endDate = maxDataDates?.rms;
            const filterValuesContent =
                (!reportletData.linkToDefault || emptyDefaultCategory) && !isEmpty(userPreferenceCustom)
                    ? userPreferenceCustom
                    : convertCharacteristics(userPreferenceDefault);
            const fetchedTiles = filteredTiles.map((tileData) => {
                const updatedTileData:object = {
                    ...tileData,
                    isLoading: false,
                    isDefault: false,
                    isError: false, // Maintain individual tile loading state
                    dataContext: filterValuesContent,
                    endDate: endDate,
                };
                return updatedTileData;
            });

            const tiles = [...fetchedTiles];
            const tilesLoadingState = tiles.reduce((result, tile) => {
                result[tile.id] = false;
                return result;
            }, {});
            setTilesLoadingState(tilesLoadingState);

            setHomePageState({
                userPreferenceDefault,
                userPreferenceCustom,
                tiles,
                reportletData,
                filterValues: filterValuesContent,
            });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }

    /*BYZ-10906: For now had to explicitly change the characteristics since the value returned from api is in old format.
     Ideally we should move this logic to backend or use defaultRunConfig value from context.*/
     function convertCharacteristics(defaultValue) {
        if (defaultRunConfig?.characteristics) {
            defaultValue.characteristicFilters = defaultRunConfig.characteristics;
        }
        return defaultValue;
    }
    const checkCustomReportletPreference = async (reportletData) => {
        // let userPreferenceCustom;
        if (reportletData && reportletData.parameterValues) {
            const userPrefCharacterFilter = await generateCharacteristicDisplayValues(
                reportletData?.parameterValues?.characteristicFilters
            );
            const userPreferenceCustom = {
                focusBrand: !isEmpty(reportletData?.parameterValues?.focusBrand)
                    ? reportletData?.parameterValues?.focusBrand
                    : [],
                primaryCategory: !isEmpty(reportletData?.parameterValues?.primaryCategory)
                    ? reportletData?.parameterValues?.primaryCategory
                    : [],
                companyCategory: categories,
                primaryMarket: reportletData?.parameterValues?.primaryMarket
                    ? reportletData.parameterValues.primaryMarket
                    : 'Total FMCG Retailers',
                characteristicFilters: userPrefCharacterFilter,
                defaultTimePeriod: reportletData?.parameterValues?.defaultTimePeriod
                    ? reportletData.parameterValues.defaultTimePeriod
                    : 'Latest 52 Weeks',
            };
            return userPreferenceCustom;
        }
        return {};
    };

    const generateCharacteristicDisplayValues = async (characteristicFilters) => {
        if (characteristicFilters?.length > 0) {
            characteristicFilters.forEach((element) => {
                element.chr_display_name = element.chr_display_name ? element.chr_display_name : element.characteristic;
                MOCK_CHARACTERISTICS.characteristics.forEach((item) => {
                    if (item.chr_display_name === element.characteristic) {
                        element.characteristic = item.chr_code;
                        element.chr_display_name = item.chr_display_name;
                    }
                });
            });
        }
        return characteristicFilters;
    };

    async function handleTilesLoadingState(tilesState) {
        let tileStatus = { ...tilesLoadingState };
        for (const key in tileStatus) {
            if (tileStatus.hasOwnProperty(key)) {
                tileStatus[key] = tilesState;
            }
        }
        setTilesLoadingState({ ...tilesLoadingState, ...tileStatus });
    }

    async function refreshReportlet() {
        try {
            // const {userPreferenceDefault, tiles} = homePageState;
            const refreshedReportletData = await fetchReportlet();
            const refreshedUserPreferenceCustom = await checkCustomReportletPreference(refreshedReportletData);
            const filterValuesContent =
                !refreshedReportletData.linkToDefault && !isEmpty(refreshedUserPreferenceCustom)
                    ? refreshedUserPreferenceCustom
                    : homePageState.userPreferenceDefault;
            const updatedTilesDataContext = homePageState.tiles.map((tileData) => {
                const updatedTileData = {
                    ...tileData,
                    dataContext: filterValuesContent,
                };
                return updatedTileData;
            });

            setHomePageState({
                reportletData: refreshedReportletData,
                userPreferenceCustom: refreshedUserPreferenceCustom,
                tiles: updatedTilesDataContext,
                filterValues:filterValuesContent 
            });
        } catch (error) {}
    }

    async function handleFilterApply(filterValues): Promise<void> {
        try {
            const tileState = Object.keys(tilesLoadingState).reduce((result, tile) => {
                result[tile] = true;
                return result;
            }, {});
            setTilesLoadingState(tileState);
            const tileContextUpdate = homePageState.tiles.map((tile) => {
                return { ...tile, dataContext: filterValues };
            });
            setHomePageState({ tiles: tileContextUpdate });
            const reqBody = {
                parameterValues: filterValues,
            };
            await updateReportlet(homePageState.reportletData.id, reqBody); //reportletData.id
        } catch (error) {}
    }

    async function resyncWithDefaultTiles(reportletId): Promise<void> {
        try {
            handleTilesLoadingState(true);
            await resetReportlet(reportletId);
        } catch (error) {
            handleTilesLoadingState(false);
        }
    }

    return (
        <MyHomepageContext.Provider
            value={{
                homePageState,
                setHomePageState,
                isContextLoading,
                handleFilterApply,
                resyncWithDefaultTiles,
                refreshReportlet,
                tilesLoadingState,
                handleTilesLoadingState,
                refreshReason,
            }}
        >
            {children}
        </MyHomepageContext.Provider>
    );
};
