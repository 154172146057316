import { useEffect, useState } from 'react';
import { LegacyByzzerButton, ByzzerLink } from '@/components/form';
import XLSX from 'xlsx';
interface ErrorItem {
    upc: string;
    category: string;
    wrongCategory?: boolean;
    original?: any;
    corrected?: any;
}
interface ErrorResponseData {
    data: ErrorItem[];
    message: string;
    type: string;
}

type FixIssuesStepProps = {
    issues: ErrorResponseData[];
    onComplete: (value: string) => void;
    proceed: boolean;
}

export function FixIssuesStep({ issues, onComplete, proceed }: FixIssuesStepProps) {
    const [errors, setErrors] = useState<ErrorResponseData[]>([]);
    const [warnings, setWarnings] = useState<ErrorResponseData[]>([]);

    useEffect(() => {
        setErrors(issues.filter(({ type }) => type !== 'check-digit'));
        setWarnings(issues.filter(({ type }) => type === 'check-digit'));
    }, [issues]);

    const handleDownloadUPCs = () => {
        var data: { UPC: string; Category: string }[] = []
        if(errors[0].data[0]?.upc !== undefined){
            errors[0].data?.forEach((error)=> {
                data.push({UPC: error.upc, Category: error.category})
            })
        }
        else{
            errors[1].data?.forEach((error)=> {
                data.push({UPC: error.upc, Category: error.category})
            })
        }
        var ws = XLSX.utils.json_to_sheet(data, {
            header: ['UPC', 'Category'],
        });
        var title = "UPCs"
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws,title);
        const s = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const buf = new ArrayBuffer(s.length); 
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) 
            view[i] = s.charCodeAt(i) & 0xff;
        const blob = new Blob([buf], { type: 'applications/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = title + '.xlsx';
        link.href = url;
        link.onclick = function (e) {
            setTimeout(function () {
                window.URL.revokeObjectURL(url);
            }, 1500);
        };
        link.click();
        link.remove();
    };

    return (
        <div className="char-editor__validation">
            {errors.length > 0 && (
                <>
                    <div className="char-editor__validation-header">
                        <i className="char-editor__validation-header-err-icon"/>
                        <span className="char-editor__validation-header-title">Errors ({errors.length})</span>
                    </div>
                    <div className="char-editor__validation-header-subtitle">
                        Errors must be resolved before your file can be uploaded.
                    </div>
                    {errors.map((item: ErrorResponseData, index) => (
                        <div className="char-editor__validation-error">
                            {item.type === 'badUpc' && (
                                <span className="char-editor__validation-type" key={index}>
                                    Invalid UPC Format:{' '}
                                </span>
                            )}
                            <span>{item.message} 
                            {item.type === 'upc-category' && ( <ByzzerLink label={`Download UPCs`} onClick={() => handleDownloadUPCs()}/>)}</span>
                        </div>
                    ))}
                </>
            )}

            {warnings.length > 0 && (
                <>
                    <div className="char-editor__validation-header">
                        <i className="char-editor__validation-header-war-icon"/>
                        <span className="char-editor__validation-header-title">Warnings ({warnings.length})</span>
                    </div>
                    <div className="char-editor__validation-header-subtitle">
                        Your file can be uploaded with these warnings, but you should be aware of how they will impact
                        your CustomChars.
                    </div>
                    {warnings.map((item) => (
                        <div className="char-editor__validation-warning">
                            <span>{item.message}</span>
                            <div className="char-editor__validation-warning-table">
                                <table>
                                    <tr>
                                        <th className="char-editor__validation-warning-table-th-original-upc">
                                            Uploaded UPC
                                        </th>
                                        <th className="char-editor__validation-warning-table-th-corrected-upc">
                                            Modified UPC
                                        </th>
                                    </tr>
                                    {item.data.map((i) => {
                                        return (
                                            <tr>
                                                <td className="char-editor__validation-warning-table-td">
                                                    {i.original}
                                                </td>
                                                <td className="char-editor__validation-warning-table-td">
                                                    {i.corrected}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            </div>
                        </div>
                    ))}
                </>
            )}
            <div className={'char-editor__actions'}>
                {!errors.length && (
                    <LegacyByzzerButton
                        className={'char-editor__button'}
                        label={'Discard & Proceed'}
                        onClick={() => onComplete('discard')}
                    />
                )}
                {/* less than half of the UPCs in the upload are not in the selected category */}
                { errors.length === 1 &&
                    proceed &&
                    Object.keys(errors).map((i) => {
                        if (errors[i].type === 'upc-category') {
                            return (
                                <LegacyByzzerButton
                                    className={'char-editor__button'}
                                    label={'Remove UPCs and Proceed'}
                                    onClick={() => onComplete('discard')}
                                />
                            );
                        }
                    })}
                <LegacyByzzerButton
                    className={'char-editor__button'}
                    label={'Fix File and Upload Again'}
                    onClick={() => onComplete('re-upload')}
                />
            </div>
        </div>
    );
}
