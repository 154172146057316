import {
    DODProductSelection,
    ProductBrandAndManufacturer,
    ProductHierarchy,
    ProductNestedCollection,
    eDisplayStrings,
    eProductNestedProperties,
} from '@/views/DataOnDemand/Overall/DODConfig';

export function getDimOrder(ExtractSelection, dimKey, yrs, allFactFilter) {
    let arrResult: string[] = [];
    switch (dimKey) {
        case 'Products':
            ProductHierarchy.concat(ProductBrandAndManufacturer).forEach((hierarchy) => {
                const product = (ExtractSelection.products as DODProductSelection)[hierarchy.property];
                if (
                    (product && Array.isArray(product.selections) && product.selections.length) ||
                    (product && typeof product.selections === 'boolean' && product.selections) ||
                    (product &&
                        Array.isArray(product.summedSelections) &&
                        product.summedSelections.length &&
                        product.summedSelections.some((v) =>
                            Array.isArray(v.selections) ? v.selections.length : Boolean(v.selections)
                        ))
                ) {
                    arrResult.push(hierarchy.display);
                    if (hierarchy.display === eDisplayStrings.UPCS) {
                        arrResult.push(eDisplayStrings.DESCRIPTIONS);
                    }
                }
            });
            arrResult = arrResult.filter((v, i, a) => a.indexOf(v) === i);
            let upcIndex = arrResult.indexOf('UPC');
            if(upcIndex > -1) {
                arrResult.splice(upcIndex, 1);
                arrResult.push('UPC')
            }
            let descIndex = arrResult.indexOf('Product Description');
            if((descIndex > -1) || (upcIndex > -1)) {
                arrResult.splice(descIndex, 1);
                arrResult.push('Product Description')
            }
            ProductNestedCollection.forEach((hierarchy) => {
                const product = (ExtractSelection.products as DODProductSelection)[hierarchy.property];
                if (Array.isArray(product) && product.length) {
                    product.forEach((item) => {
                        if (
                            (Array.isArray(item.selections) && item.selections.length) ||
                            (typeof item.selections === 'boolean' && item.selections) ||
                            (Array.isArray(item.summedSelections) &&
                                item.summedSelections.some((v) =>
                                    Array.isArray(v.selections) ? v.selections.length : v.selections
                                ))
                        ) {
                            if (hierarchy.property === eProductNestedProperties.CUSTOM_CHARACTERISTICS) {
                                arrResult.push(`${item.characteristic}&;&CC`);
                            } else {
                                arrResult.push(
                                    item.characteristic === 'Sub-Category' ? 'Sub Category' : item.characteristic
                                );
                            }
                        }
                    });
                }
            });
            arrResult = arrResult.filter((v, i, a) => a.indexOf(v) === i);
            break;
        case 'Markets':
            arrResult = (ExtractSelection?.markets ?? [])
                .map((item) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.selections;
                    }
                })
                .concat(ExtractSelection?.summedMarkets?.map((item) => item.name) ?? [])
                .flat(Infinity);
            break;
        case 'TimePeriods':
            arrResult = frameTimePeriod(ExtractSelection, yrs);
            break;
        default:
            const sortedFact = ExtractSelection.facts
                ?.map((item) => item.selections)
                .flat(Infinity)
                .sort((a, b) => {
                    return allFactFilter.indexOf(+a.id) - allFactFilter.indexOf(+b.id);
                });
            arrResult = sortedFact
                ?.map((sel) => {
                    const tempArr: string[] = [];
                    if (sel.fact) tempArr.push(`${sel.display}`);
                    if (sel.yearAgo) tempArr.push(`${sel.display} YA`);
                    if (sel.cngYearAgo) tempArr.push(`${sel.display} Change vs Year-Ago`);
                    if (sel.changeYearAgo) tempArr.push(`${sel.display} % Change vs Year-Ago`);
                    if (sel.shareCategory) tempArr.push(`${sel.display} % Share By Category`);
                    if (sel.shareCategoryYa) tempArr.push(`${sel.display} % Share By Category Year-Ago`);
                    if (sel.shareBrand) tempArr.push(`${sel.display} % Share By Brand`);
                    if (sel.shareBrandYa) tempArr.push(`${sel.display} % Share By Brand Year-Ago`);
                    if (sel.shareProductSel) tempArr.push(`${sel.display} % Share By Product Selections`);
                    if (sel.shareProductSelYa) tempArr.push(`${sel.display} % Share By Product Selections Year-Ago`);
                    return tempArr;
                })
                .flat(Infinity);
            break;
    }
    return Object.assign(
        [],
        arrResult?.filter((v, i, a) => a.indexOf(v) === i)
    );
}

export function frameTimePeriod(ExtractSelection, yrs) {
    let timePeriodOptionsForFolder = [
        'Latest 1 Week',
        'Latest 4 Weeks',
        'Latest 12 Weeks',
        'Latest 13 Weeks',
        'Latest 26 Weeks',
        'Latest 52 Weeks',
        'Year to Date',
    ];
    let timePeriodOptionsForPreviousFolder = [
        'Previous 1 Week',
        'Previous 4 Weeks',
        'Previous 12 Weeks',
        'Previous 13 Weeks',
        'Previous 26 Weeks',
        'Previous 52 Weeks',
    ];
    let folderValues: string[] = [];
    let previousFolderValues: string[] = [];
    for (let index = 0; index < yrs; index++) {
        timePeriodOptionsForFolder.map((val) => {
            if (index === 0) {
                folderValues.push(val);
            } else if (index === 1) {
                folderValues.push(`${val} Year-Ago`);
            } else {
                folderValues.push(`${val} ${index}-Year-Ago`);
            }
            return folderValues;
        });

        timePeriodOptionsForPreviousFolder.map((val) => {
            if (index === 0) {
                previousFolderValues.push(val);
            } else if (index === 1) {
                previousFolderValues.push(`${val} Year-Ago`);
            } else {
                previousFolderValues.push(`${val} ${index}-Year-Ago`);
            }
            return previousFolderValues;
        });
    }
    timePeriodOptionsForFolder = folderValues.length > 0 ? folderValues : timePeriodOptionsForFolder;
    timePeriodOptionsForPreviousFolder = previousFolderValues.length > 0 ? previousFolderValues : timePeriodOptionsForPreviousFolder;
    return (
        (ExtractSelection?.timePeriods?.data || ExtractSelection?.timePeriods)
            ?.map((item) => {
                if (item) {
                    const type = item?.folder?.value || item?.type;
                    switch (type) {
                        case 'Periods ending latest week':
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        timePeriodOptionsForFolder.indexOf(a) - timePeriodOptionsForFolder.indexOf(b)
                                );
                        case 'Periods ending previous week':
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        timePeriodOptionsForPreviousFolder.indexOf(a) - timePeriodOptionsForPreviousFolder.indexOf(b)
                                );
                        case 'Weeks':
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((item) => {
                                    return `1 Week - W/E ${item}`;
                                });
                        case '4 Weeks':
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((item) => {
                                    return `4 Wks - W/E ${item}`;
                                });
                        case 'Months':
                            const monthNames = [
                                'January',
                                'February',
                                'March',
                                'April',
                                'May',
                                'June',
                                'July',
                                'August',
                                'September',
                                'October',
                                'November',
                                'December',
                            ];
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((date) => {
                                    const refDate = new Date(
                                        parseInt(date.split('-')[2]),
                                        parseInt(date.split('-')[0]) - 1,
                                        parseInt(date.split('-')[1])
                                    );
                                    refDate.setDate(refDate.getDate() - 7);
                                    return `${monthNames[refDate.getMonth()]} ${refDate.getFullYear()} - W/E ${date}`;
                                });
                        case 'Quarters':
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((item) => {
                                    return `Quarter - W/E ${item}`;
                                });
                        case 'Custom Time Period':
                            return item.selections
                                ?.sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((date) => {
                                    return `Custom ${item?.config?.period + ' Week' || 'Time'} - W/E ${date}`;
                                });
                        default:
                            //Years
                            return item.selections
                                ?.filter((val) => val !== 'all')
                                .sort(
                                    (a, b) =>
                                        new Date(
                                            parseInt(b.split('-')[2]),
                                            parseInt(b.split('-')[0]) - 1,
                                            parseInt(b.split('-')[1])
                                        ).getTime() -
                                        new Date(
                                            parseInt(a.split('-')[2]),
                                            parseInt(a.split('-')[0]) - 1,
                                            parseInt(a.split('-')[1])
                                        ).getTime()
                                )
                                .map((item) => {
                                    const dateObj = new Date(item);
                                    const day = dateObj.getDate();
                                    let year = dateObj.getFullYear();
                                    if (day <= 3) {
                                        year = year - 1;
                                    }
                                    return `Year ${year} - W/E ${item}`;
                                });
                    }
                }
                return null;
            })
            .filter((val) => val)
            .flat(Infinity)
            .filter((val) => val !== 'all') ?? []
    ).concat(
            ExtractSelection?.timePeriods?.summedPeriods?.map((item) => {
                return item.name;
            }) ?? []
        ).flat(Infinity);
}

/** Pass Layout and Seletion Data to Frame UI Keys */
export function getTableStruct(ExtractSelection, layout, yrs, allFactFilter) {
    let rowKeys: string[] = [];
    let colKeys: string[] = [];
    let pageKeys: string[] = [];
    layout.row.forEach((dimension) => {
        switch (dimension) {
            case 'Products':
                const arrProduct = getDimOrder(ExtractSelection, dimension, yrs, allFactFilter)
                    .filter((val) => !(layout.stackBy ?? []).includes(val))
                    .filter(
                        (val) => !(layout.row.includes(val) || layout.column.includes(val) || layout.page.includes(val))
                    )
                    .sort((a, b) => {
                        return (
                            layout?.config?.sortOrder['Products']?.indexOf(a) -
                            layout?.config?.sortOrder['Products']?.indexOf(b)
                        );
                    });
                rowKeys = rowKeys.concat(arrProduct.filter((val) => !layout?.hideProduct?.includes(val)));
                if ((layout.stackBy ?? []).length) {
                    rowKeys = rowKeys.concat('Stacked Products&::&Stacked Product Details');
                }
                break;
            case 'TimePeriods':
                rowKeys.push('Time Periods');
                break;
            default:
                rowKeys.push(dimension);
                break;
        }
    });
    layout.column.forEach((dimension) => {
        switch (dimension) {
            case 'Products':
                const arrProduct = getDimOrder(ExtractSelection, dimension, yrs, allFactFilter)
                    .filter(
                        (val) => !(layout.row.includes(val) || layout.column.includes(val) || layout.page.includes(val))
                    )
                    .sort((a, b) => {
                        return (
                            layout?.config?.sortOrder['Products']?.indexOf(a) -
                            layout?.config?.sortOrder['Products']?.indexOf(b)
                        );
                    });
                colKeys = colKeys.concat(arrProduct.filter((val) => !layout?.hideProduct?.includes(val)));
                break;
            case 'TimePeriods':
                colKeys.push('Time Periods');
                break;
            default:
                colKeys.push(dimension);
                break;
        }
    });
    layout.page.forEach((dimension) => {
        switch (dimension) {
            case 'Products':
                const arrProduct = getDimOrder(ExtractSelection, dimension, yrs, allFactFilter)
                    .filter(
                        (val) => !(layout.row.includes(val) || layout.column.includes(val) || layout.page.includes(val))
                    )
                    .sort((a, b) => {
                        return (
                            layout?.config?.sortOrder['Products']?.indexOf(a) -
                            layout?.config?.sortOrder['Products']?.indexOf(b)
                        );
                    });
                pageKeys = pageKeys.concat(arrProduct.filter((val) => !layout?.hideProduct?.includes(val)));
                break;
            case 'TimePeriods':
                pageKeys.push('Time Periods');
                break;
            default:
                pageKeys.push(dimension);
                break;
        }
    });
    return { rowKeys, colKeys, pageKeys };
}

export const getDimValueArray = (ExtractSelection, ExtractLayout, dim) => {
    switch (dim) {
        case 'Markets':
            return (
                ExtractSelection?.markets?.map((item) => {
                    return item.selections ?? item;
                }) ?? []
            )
                .concat(
                    ExtractSelection?.summedMarkets?.map((item) => {
                        return item.name;
                    }) ?? []
                )
                .flat(Infinity)
                .sort((a, b) => {
                    return (
                        ExtractLayout?.config?.sortOrder['Markets']?.indexOf(a) -
                        ExtractLayout?.config?.sortOrder['Markets']?.indexOf(b)
                    );
                });
        case 'Time Periods':
            if (ExtractSelection?.timePeriods?.summedPeriods) {
                ExtractSelection.timePeriods = [];
            } //TODO: find out why this is getting written to database with summed periods as part of timeperiods
            return frameTimePeriod(ExtractSelection, 3).sort((a, b) => {
                return (
                    ExtractLayout?.config?.sortOrder['TimePeriods']?.indexOf(a) -
                    ExtractLayout?.config?.sortOrder['TimePeriods']?.indexOf(b)
                );
            });
        case 'Facts':
            return ExtractSelection?.facts
                ?.map((item) => {
                    return item.selections.map((i) => {
                        const tempArr: string[] = [];
                        if (i.fact) {
                            tempArr.push(i.display);
                        }
                        if (i.yearAgo) {
                            tempArr.push(`${i.display} YA`);
                        }
                        if (i.cngYearAgo) {
                            tempArr.push(`${i.display} Change vs Year-Ago`);
                        }
                        if (i.changeYearAgo) {
                            tempArr.push(`${i.display} % Change vs Year-Ago`);
                        }
                        if (i.shareCategory) {
                            tempArr.push(`${i.display} % Share By Category`);
                        }
                        if (i.shareCategoryYa) {
                            tempArr.push(`${i.display} % Share By Category Year-Ago`);
                        }
                        if (i.shareBrand) {
                            tempArr.push(`${i.display} % Share By Brand`);
                        }
                        if (i.shareBrandYa) {
                            tempArr.push(`${i.display} % Share By Brand Year-Ago`);
                        }
                        if (i.shareProductSel) {
                            tempArr.push(`${i.display} % Share By Product Selections`);
                        }
                        if (i.shareProductSelYa) {
                            tempArr.push(`${i.display} % Share By Product Selections Year-Ago`);
                        }
                        return tempArr;
                    });
                })
                .flat(Infinity)
                .sort((a, b) => {
                    return (
                        ExtractLayout?.config?.sortOrder['Facts']?.indexOf(a) -
                        ExtractLayout?.config?.sortOrder['Facts']?.indexOf(b)
                    );
                });
        case 'Stacked Products&::&Stacked Product Details':
            return (ExtractLayout.stackBy ?? [])
                .map((stack) =>
                    getDimValueArray(ExtractSelection, ExtractLayout, stack).map((item) => `${item}:${stack}`)
                )
                .flat(Infinity);
        case eDisplayStrings.BRANDS:
        case eDisplayStrings.CATEGORIES:
        case eDisplayStrings.DEPARTMENTS:
        case eDisplayStrings.MANUFACTURER:
        case eDisplayStrings.PARENT_COMPANY:
        case eDisplayStrings.SUB_CATEGORIES:
        case eDisplayStrings.SUPER_CATEGORIES:
        case eDisplayStrings.UPCS:
            const prop = ProductHierarchy.concat(ProductBrandAndManufacturer).find((v) => v.display === dim)?.property;
            if (prop && typeof ExtractSelection.products[prop].selections === 'boolean') {
                return ['All'].concat(
                    (prop ? ExtractSelection.products[prop].summedSelections ?? [] : []).map((v) => v.display)
                );
            }
            if (prop && typeof ExtractSelection.products[prop].summedSelections === 'boolean') {
                return ['All'];
            }
            return (
                (prop ? ExtractSelection.products[prop].selections ?? [] : [])
                    .concat((prop ? ExtractSelection.products[prop].summedSelections ?? [] : []).map((v) => v.display))
                    .map((item) => item.split('&;&')[0]) ?? []
            );
        case eDisplayStrings.DESCRIPTIONS:
            const descProp = ProductHierarchy.concat(ProductBrandAndManufacturer).find((v) => v.display === dim)
                ?.property;

            if (
                ExtractSelection.products['upcs'].summedSelections.length > 0 &&
                ExtractSelection.products['descriptions'].summedSelections.length > 0
            ) {
                return (
                    (descProp
                        ? ExtractSelection.products[descProp].selections.map((description) => {
                              let [upcNumber, ...upcDescription] = description.split(' ');
                              return upcDescription.join(' ') + ' ' + upcNumber;
                          }) ?? []
                        : []
                    )
                        .concat(
                            (descProp ? ExtractSelection.products[descProp].summedSelections ?? [] : []).map(
                                (v) => v.display
                            )
                        )
                        .map((item) => item.split('&;&')[0]) ?? []
                );
            }

            if (
                (descProp && typeof ExtractSelection.products[descProp].selections === 'boolean') ||
                (typeof ExtractSelection.products['upcs'].selections === 'boolean' &&
                    ExtractSelection.products['upcs'].selections)
            ) {
                return ['All'];
            } else if (ExtractSelection.products['upcs'].selections.length) {
                return [''];
            }

            if (
                (descProp && typeof ExtractSelection.products[descProp].summedSelections === 'boolean') ||
                (typeof ExtractSelection.products['upcs'].summedSelections === 'boolean' &&
                    ExtractSelection.products['upcs'].summedSelections)
            ) {
                return ['All'];
            } else if (ExtractSelection.products['upcs'].summedSelections.length) {
                return [''];
            }

            return (
                (descProp
                    ? ExtractSelection.products[descProp].selections.map((description) => {
                          let [upcNumber, ...upcDescription] = description.split(' ');
                          return upcDescription.join(' ') + ' ' + upcNumber;
                      }) ?? []
                    : []
                )
                    .concat(
                        (descProp ? ExtractSelection.products[descProp].summedSelections ?? [] : []).map(
                            (v) => v.display
                        )
                    )
                    .map((item) => item.split('&;&')[0]) ?? []
            );
        default:
            const characteristics = (ExtractSelection?.products?.characteristics ?? [])
                .concat(ExtractSelection?.products?.customCharacteristics)
                .find((val) => val?.characteristic === dim?.split('&;&')[0]);
            return typeof characteristics?.selections === 'boolean'
                ? ['All']
                : (characteristics?.selections ?? []).concat(characteristics?.summedSelections?.map((v) => v.display));
    }
};

export const combinateHeading = (ExtractSelection, ExtractLayout, keys, limit = 0, isColumn) => {
    let finalArray: any[] = [];
    keys.forEach((item) => {
        if (finalArray.length) {
            let tempArray = getDimValueArray(ExtractSelection, ExtractLayout, item);
            if (limit) tempArray = Object.assign([], tempArray).splice(0, limit);
            if (ExtractLayout?.stackBy?.length > 0 && isColumn) {
                finalArray = finalArray
                    .map((i) => {
                        return tempArray.map((j) => {
                            return `${i}*${j}`;
                        });
                    })
                    .flat(Infinity);
            } else {
                finalArray = finalArray
                    .map((i) => {
                        return tempArray.map((j) => {
                            return `${i}:${j}`;
                        });
                    })
                    .flat(Infinity);
            }

            if (limit) finalArray = finalArray.splice(0, limit);
        } else {
            let tempArray = getDimValueArray(ExtractSelection, ExtractLayout, item);
            if (limit) tempArray = Object.assign([], tempArray).splice(0, limit);
            finalArray = tempArray;
        }
    });
    return finalArray;
};
