import './ConfigureStep.scss';
import { LegacyByzzerButton, ByzzerInput } from '@/components/form';
import React, { useEffect, useState } from 'react';
import { ProductHierarchySelector } from '@/views/CustomCharacteristics/editor/ProductHierarchySelector';
import {useTenantApi} from '@/hooks/useTenantApi';
import SegmentEditor from './segmentEditor/SegmentEditor';
import DeleteIcon from '@/images/icons/DeleteIcon.svg';
import EditIcon from '@/images/icons/new-edit-icon.svg';

const baseClassName = 'char-builder';

export function ConfigureStep({
    title,
    categories,
    segment,
    onCategoriesChange,
    onTitleChange,
    onComplete,
    setLoading,
    busy,
    error,
    setProductHierarchy,
}) {
    const { getProductChars } = useTenantApi();
    const [characteristicOptions, setCharacteristicOptions] = useState<any[]>([]);
    const [characteristicsForCategories, setCharacteristicsForCategories] = useState({});

    const [segments, setSegments] = useState<any[]>([]);
    const [segmentCreator, setSegmentCreator] = useState<boolean>(true);
    const [editSegmentIndex, setEditSegmentIndex] = useState<number | null>(null);
    const [editSegmentData, setEditSegmentData] = useState<any[]>([]);
    const [showSegmentEditor, setShowSegmentEditor] = useState<boolean>(false);
    const themePurchaseType = 'subscription_addon_characteristic_theme';

    useEffect(() => {
        loadCharacteristicsForCategories(categories);
    }, [categories]);

    useEffect(() => {
        if (segment) {
            setSegments(segment);
            setSegmentCreator(false);
        }
    }, [segment]);

    useEffect(() => {
        setShowSegmentEditor(segments.length === 0 || editSegmentIndex !== null);
    }, [segments, editSegmentIndex]);

    async function loadCharacteristicsForCategories(categories) {
        setLoading(true);
        try {
            if (categories.length) {
                const productSetObj = {
                    categories: categories.map((item) => {
                        return {
                            hierarchy: 'CATEGORY',
                            name: item,
                        };
                    }),
                    themePurchaseType,
                };
                const characteristicsData = await getProductChars(productSetObj); 
                setCharacteristicsForCategories(characteristicsData?.characteristics);
                setCharacteristicOptions([
                    ...characteristicsData?.characteristics?.map((item) => item.chr_display_name),
                    'UPC',
                ]);
            }
        } catch (e) {
            console.error('Error loading characteristics for categories', e);
        } finally { 
            setLoading(false);
        }
    }

    async function onCreateSegment(value) {
        // throw an error if the name is a duplicate by checking for match with a different index
        if (
            segments.some(({ name }, index) => {
                return name.toLowerCase() === value.name.toLowerCase() && index !== editSegmentIndex;
            })
        ) {
            return error(value.name);
        }

        if (segments.length > Number(editSegmentIndex)) {
            segments.splice(Number(editSegmentIndex), 1, value);
        } else {
            segments.push(value);
        }
        setSegments([...segments]);
        setSegmentCreator(false);
        setEditSegmentIndex(null);
    }

    function editSegment(item, index) {
        setEditSegmentIndex(index);
        setEditSegmentData(item);
        setSegmentCreator(false);
    }

    function deleteSegment(item, index) {
        segments.splice(index, 1);
        setSegments([...segments]);
    }

    function onEditorCancel() {
        setEditSegmentIndex(null);
        setSegmentCreator(false);
    }

    function onCreateNewSegmentClick() {
        // @ts-ignore
        setEditSegmentIndex(segments?.length);
    }

    return (
        <>
            <div className="char-builder__char-details">
                {/*<div className="char-builder-section1-title">*/}
                {/*    Create New Characteristic Using Groupings Of Existing Characteristics*/}
                {/*</div>*/}
                {/*<div className="char-builder-section1-description">*/}
                {/*    Use custom characteristics to create new segments of the data that apply uniquely to your business.*/}
                {/*</div>*/}
                {/* @ts-ignore */}
                <ByzzerInput
                    label={'Characteristic Name'}
                    className={'char-builder-section1-name-input'}
                    type={'text'}
                    value={title}
                    onChange={(e) => onTitleChange(e.target.value.trim())}
                    variant={'secondary'}
                />
                <ProductHierarchySelector onChange={onCategoriesChange}
                    categories={categories} setLoading={setLoading} setProductHierarchy={setProductHierarchy} />
            </div>
            <div className="char-builder__segments">
                <div className="char-builder__title">Segments</div>

                {segments.map((item, index) => (
                    <div key={index} className={`${baseClassName}__segment`}>
                        <div className={`${baseClassName}__segment-label`}>Segment {index + 1}:</div>
                        <div className={`${baseClassName}__segment-name`}>{item.name}</div>
                        <img
                            className={`${baseClassName}__segment-action`}
                            onClick={() => editSegment(item, index)}
                            src={EditIcon}
                            alt={'edit'}
                        />
                        <img
                            className={`${baseClassName}__segment-action`}
                            onClick={() => deleteSegment(item, index)}
                            src={DeleteIcon}
                            alt={'delete'}
                        />
                    </div>
                ))}
                {segments.length > 0 && (
                    <div className={`${baseClassName}__segment`}>
                        <div className={`${baseClassName}__segment-label`}>Remaining items:</div>
                        <div className={`${baseClassName}__segment-name`}>NOT APPLICABLE</div>
                    </div>
                )}
                {showSegmentEditor && (
                    <SegmentEditor
                        cancelable={segments.length > 0}
                        onCancel={onEditorCancel}
                        setLoading={setLoading}
                        selectedCategories={categories}
                        characteristicsForCategories={characteristicsForCategories}
                        characteristicOptions={characteristicOptions}
                        index={editSegmentIndex}
                        onCreateSegment={onCreateSegment}
                        segment={segments[editSegmentIndex!]}
                    />
                )}
            </div>

            <div className={'char-builder__actions'}>
                <LegacyByzzerButton
                    disabled={!segments.length || busy}
                    onClick={onCreateNewSegmentClick}
                    label={'Add New Segment'}
                />
                <LegacyByzzerButton
                    disabled={segmentCreator || categories.length === 0 || title === '' || segments.length === 0}
                    onClick={() => onComplete(segments, categories, title)}
                    label={'Save Characteristic'}
                    busy={busy}
                />
            </div>
        </>
    );
}
