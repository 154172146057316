import React, {ReactNode, useEffect, useState} from 'react';
import './PPGEditor.scss';
import classNames from 'classnames';
import {useTenantApi} from '@/hooks/useTenantApi';
import {FixIssuesStep} from './FixIssuesStep';
import {create as createModal} from "react-modal-promise";
import {ByzzerModalMask, openErrorModal} from "@/components/form/ByzzerModal";
import {PreUploadStep} from "@/views/PPG/editor/PreUploadStep";
import {UploadStep} from "@/views/PPG/editor/UploadStep";
import {ConfigureStep} from "@/views/PPG/editor/ConfigureStep";
import {ChooseEditActionStep} from "@/views/PPG/editor/ChooseEditActionStep";
import {LegacyByzzerButton} from '@/components/form';
import {ppgPreUploadHeading } from '@/config/globalVars';
import { PpgInfo } from '@/types/ApiTypes';

const defaultCharacteristics = [
    'brand',
    'total_size',
    'number_in_multipack'
]

const baseClassName = 'ppg-editor';

type PPGEditorProps = {
    ppgId?: number;
    onResolve: (result?: boolean) => void;
    allowUpload?: boolean;
}

type EditorStep = 'pre-upload' | 'configure' | 'upload' | 'errors' | 'choose-edit';

export function PPGEditor({
    ppgId,
    onResolve, 
    allowUpload = true
}: PPGEditorProps) {
    const { checkUpcsInCategories, createPPG, getPPGById, updatePPG } = useTenantApi();
    const ppguploadHeading = 'Make sure a UPC-upload is the right choice';
    const [currentStep, setCurrentStep] = useState<EditorStep>(allowUpload ? 'pre-upload' : 'configure');
    const [changeType, setChangeType] = useState<'to_upc' | 'to_characteristics'>();
    const [loading, setLoading] = useState<boolean>(false);
    const [useDefaultCharacteristics, setUseDefaultCharacteristics] = useState<boolean>(false);
    const [characteristics, setCharacteristics] = useState<any[]>([]);
    const [title, setTitle] = useState<string>('');
    const [categories, setCategories] = useState<string[]>([]);
    const [uploadHeading,setUploadHeading] = useState(ppguploadHeading);
    const [upcGroups, setUpcGroups] = useState<any[]>([]);
    const [busy, setBusy] = useState<boolean>(false);
    const [heading, setHeading] = useState<ReactNode>(<>&nbsp;</>);
    const [ppgErrorList, setPPGErrorList] = useState<any[]>([]);
    const [upcs, setUpcs] = useState<any[]>([]);
    const [configInstructions, setConfigInstructions] = useState<string>('');
    const [issues, setIssues] = useState<any[]>([]);
    const [showingErrorModal, setShowingErrorModal] = useState<boolean>(false);
    const [mode, setMode] = useState('create');
    const [ppg, setPPG] = useState<PpgInfo>();
    const [isInvalidUPC, setIsInvalidUPC] = useState<boolean>(false);
    const [proceed, setProceed] = useState<boolean>(false);
    const classes = classNames('byzzer-modal-wrapper', 'ppg-editor-wrapper', {
        'ppg-editor-wrapper--with-error-modal': showingErrorModal,
    });

    // todo: move this to a global state there is no point in loading this everytime the component loads
    useEffect(() => {
        loadReferenceData();
        setCurrentStep(allowUpload ? 'pre-upload' : 'configure');
    }, []);

    useEffect(() => {
        if (ppgId) {
            setMode('edit');
            setCurrentStep('choose-edit');
            loadPpg(ppgId);
        } else {
            setMode('create');
        }
    }, [ppgId]);

    useEffect(() => {
        if (allowUpload) {
            // setCurrentStep(allowUpload ? 'pre-upload');
            setConfigInstructions(
                `How would you like UPCs in the category that were not included in your mapping file to be defined?`
            );
            setUseDefaultCharacteristics(true);
        } else {
            // setCurrentStep('configure');
            setConfigInstructions(
                `Choose a set of characteristics to aggregate all items across the category into consistently-defined product groups.`
            );
            setUseDefaultCharacteristics(false);
            setCharacteristics([null]);
        }
    }, [allowUpload]);

    useEffect(() => {
        if (ppg) {
            setCategories(ppg.categories ?? []);
            setCharacteristics(ppg.characteristics ?? []);
            setUseDefaultCharacteristics(false);
            setTitle(ppg.title);
        }
    }, [ppg]);

    async function enableUploadStep(allowPreUpload) {
        if (
            await allowPreUpload== true
        ){
           setUploadHeading(ppgPreUploadHeading);
        }
    }

    useEffect(() => {
        let heading: ReactNode = <>&nbsp;</>;
        switch (currentStep) {
            case 'pre-upload':
                heading = uploadHeading;
                break;
            case 'upload':
                heading = ppgPreUploadHeading;
                break;
            case 'configure':
                heading = allowUpload ? 'Configure Characteristics' : 'Configure Category PPG';
                break;
            case 'errors':
                heading = 'We Found Some Issues';
                break;
            case 'choose-edit':
                heading = ppg?.title ?? <>&nbsp;</>;
                break;
        }
        setHeading(heading);
    }, [currentStep, ppg, uploadHeading]);

    async function loadPpg(id: number) {
        const ppg = await getPPGById(id);
        setPPG(ppg);
    }

    async function loadReferenceData() {
        // try {
        //     // todo: move this loading indicator to the component the data is being loaded for so it doesn't block the whole UI
        //     setLoading({
        //         message: 'Loading Categories',
        //     });
        //     const {categories} = await getMyCompanyPreferences();
        //     setAllCategories(categories);
        //     setLoading(false);
        // } catch (err: any) {
        //     onResolve(false);
        //     openErrorModal({
        //         title: 'PPGs Unavailable',
        //         content: (
        //             <>
        //                 <p>We could not load the required information to create a new PPG.</p>
        //                 <p>Please try again later.</p>
        //             </>
        //         ),
        //     });
        // }
    }

    const isComma = (containsComma) => {
        setIsInvalidUPC(containsComma);
    };

    async function detectIssues(categories, upcs) {
        // todo: properly handle errors
        try {
            const issues: any[] = [];
            const {upcGroups, corrections, badUpcs} = validateAndGroup(upcs);
            // todo: deal with the possibility of this call failing
            if ((upcGroups.map(({upcs}) => upcs).flat()).length > 0) {
                const notFound = await checkUpcsInCategories({
                    categories,
                    upcs: upcGroups.map(({upcs}) => upcs).flat(),
                });

                if (isInvalidUPC) {
                    setProceed(false);
                    issues.push({
                        type: 'badUpc',
                        message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                    });
                }
                if (badUpcs.length) {
                    setProceed(false);
                    issues.push({
                        type: 'badUpc',
                        message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                        data: badUpcs,
                    });
                }
                if (corrections.length) {
                    setProceed(false);
                    issues.push({
                        type: 'check-digit',
                        message: `We found check-digits in ${corrections.length} of the UPCs in your upload, so we removed them.`,
                        data: corrections,
                    });
                }
                if (notFound.length) {
                    if (upcs.length / 2 <= notFound.length) {
                        setProceed(false);
                        issues.push({
                            type: 'upc-category',
                            message: `${notFound.length} UPCs in your upload are not in the selected category. Fix your file or your category selections and try again.`,
                            data: notFound,
                        });
                    }
                    if (upcs.length / 2 > notFound.length) {
                        issues.push({
                            type: 'upc-category',
                            message: `${notFound.length} UPCs in your upload are not in the selected category. If you proceed, those UPCs will be removed from the PPG definition.`,
                            data: notFound,
                        });

                        //check if notFound && upcGroup includes same UPCs : if yes remove notFound UPCs & setUpcGroups()
                        let upcGroupsTemp = upcGroups;
                        notFound.map((notFoundUpc) => {
                            upcGroupsTemp.map((upcGroup) => {
                                let upcTemp: any[] = [];
                                upcGroup.upcs.map((upc) => {
                                    if (upc !== notFoundUpc.upc) upcTemp.push(upc);
                                });
                                upcGroup.upcs = upcTemp;
                            });
                        });
                        upcGroupsTemp = upcGroupsTemp.filter((upcGroup) => upcGroup.upcs?.length > 0);
                        setProceed(true);
                        setUpcGroups(upcGroupsTemp);
                    }
                }
            } else {
                issues.push({
                    type: 'badUpc',
                    message: 'UPC column must be formatted as integers. Please update your file and re-upload.',
                });
                setCurrentStep('errors');
            }
            return issues;
        } catch (err: any) {
        }
    }

    function validateAndGroup(records) {
        // group upcs based on group name and remove duplicates
        const upcsByGroupName = records.reduce((groups, {upc, group}) => {
            let grpName = group?.trim();
            groups[grpName] = groups[grpName] ?? new Set();
            groups[grpName].add(upc)
            return groups;
        }, {});

        // convert the map into an array of objects
        const upcGroups: any[] = [];
        const allCorrections: any[] = [];
        const allBadUpcs: any[] = [];

        Object.entries(upcsByGroupName).forEach(([name, upcs]) => {
            const {cleanUpcs, corrections, badUpcs} = cleanseUpcs(upcs);
            allCorrections.push(...corrections);
            allBadUpcs.push(...badUpcs);

            upcGroups.push({
                name,
                upcs: cleanUpcs,
            });
        });

        // make sure the upcs are mutually exclusive to a group
        const allUpcs = upcGroups.map(({upcs}) => upcs).flat();
        const crossGroupUpcs = allUpcs.filter((upc) => allUpcs.filter((v) => upc === v).length > 1);
        if (crossGroupUpcs.length) {
            // todo: handle this error condition
            var temp = ppgErrorList;
            temp.push({
                type: 'crossGroupUpcs',
                message: 'Same UPCs are present in multiple PPGs. Please update your file and re-upload.',
                data: crossGroupUpcs,
            });
            setPPGErrorList(temp);
        }

        setUpcGroups(upcGroups);

        return {
            upcGroups,
            corrections: allCorrections,
            badUpcs: allBadUpcs,
        };
    }

    function cleanseUpcs(upcs) {
        const corrections: any[] = [];
        const badUpcs: any[] = [];
        const cleanUpcs: any[] = Array.from(upcs)
            .filter((v: any) => {
                if (/\D/.test(v)) {
                    badUpcs.push(v);
                    return false;
                }
                return true;
        });

        return {
            cleanUpcs,
            corrections,
            badUpcs,
        };
    }

    const onFixIssuesComplete = (action) => {
        switch (action) {
            case 'discard':
                setCurrentStep('configure');
                break;
            case 're-upload':
                setCurrentStep('upload');
                setIssues([]);
                break;
        }
    };

    function onUpcsChange(upcs) {
        setUpcs([...upcs]);
    }

    function onCategoriesChange(categories) {
        setCategories([...categories]);
    }

    function onCloseClick() {
        onResolve(false);
    }

    function onActionClick(action) {
        switch (action) {
            case 'showGuidelines':
                setCurrentStep('pre-upload');
                break;
        }
    }

    function onPreUploadStepComplete() {
        setCurrentStep('upload');
    }

    async function onUploadStepComplete() {
        try {
            setBusy(true);
            const issues = await detectIssues(categories, upcs);

            if (issues?.length) {
                setHeading(<>&nbsp;</>);
                setCurrentStep('errors');
                setIssues(issues);
            } else {
                setCurrentStep('configure');
            }
        } catch (err: any) {
        } finally {
            setBusy(false);
        }

        try {
            setBusy(true);
        } finally {
            setBusy(false);
        }
    }

    function onChooseEditActionComplete(action) {
        switch (action) {
            case 'upload-upcs':
                setChangeType('to_upc')
                setCurrentStep('upload');
                break;
            case 'change-rules':
                setCurrentStep('configure');
                break;
            case 'remove-upcs':
                setUpcGroups([]);
                setChangeType('to_characteristics');
                setCurrentStep('configure');
                break;
        }
    }

    async function onConfigureStepComplete() {
        try {
            setBusy(true);
            if (ppgId) {
                if (!ppg) {
                    // todo: handle this error condition, shouldn't happen?
                    throw new Error('ppg_not_found');
                }
                const type = ( // checking if the type is changing
                    ppg.type === 'Characteristics' && changeType === 'to_upc' ? 'UPC' : 
                    ppg.type === 'UPC' && changeType === 'to_characteristics' ? 'Characteristics' : 
                    ppg.type
                )
                await updatePPG(ppgId, {
                    title,
                    categories,
                    characteristics: useDefaultCharacteristics
                        ? defaultCharacteristics
                        : characteristics.filter(Boolean),
                    groups: upcGroups,
                    type
                });
            } else {
                await createPPG({
                    title,
                    categories,
                    characteristics: useDefaultCharacteristics
                        ? defaultCharacteristics
                        : characteristics.filter(Boolean),
                    groups: upcGroups,
                });
            }
            onResolve(true);
        } catch (err: any) {
            setShowingErrorModal(true);
            switch (err.code) {
                case 'duplicate_ppg_title':
                    await openErrorModal({
                        title: `That Name is Taken`,
                        // @ts-ignore
                        className: 'ppg-error-modal',
                        content: () => (
                            <>
                                <p>There is already a PPG List defined with the name you selected. Rename your PPG List
                                    to save it.</p>
                            </>
                        ),
                        actions: [{
                            label: 'Rename & Save',
                            key: 'renameAndSave',
                            action({resolve}) {
                                resolve('rename and save')
                            } 
                        }]
                    });
                    break;
                case 'ppg_access_denied':
                    await openErrorModal({
                        title: `Oops - Inactive PPG List`,
                        // @ts-ignore
                        className: 'ppg-error-modal',
                        content: () => (
                            <>
                                <p>The PPG you're trying to edit isn't active in your company. You may want to delete
                                    this version and recreate it, {title}.</p>
                                <p>You can share this ID in the chat for further assistance.</p>
                            </>
                        ),
                        errorId: err.id,
                        actions: [{
                            label: 'Close',
                            key: 'close',
                            action({resolve}) {
                                resolve('close')
                            } 
                        }]
                    });
                    break;
                case 'ppg_not_found':
                    await openErrorModal({
                        title: `Oops - Inactive PPG List`,
                        // @ts-ignore
                        className: 'ppg-error-modal',
                        content: () => (
                            <>
                                <p>The PPG you're trying to edit isn't active. You may want to delete this version and
                                    recreate it.</p>
                                <p>You can share this ID in the chat for further assistance.</p>
                            </>
                        ),
                        errorId: err.id,
                        actions: [{
                            label: 'Close',
                            key: 'close',
                            action({resolve}) {
                                resolve('close')
                            } 
                        }]
                    });
                    break;
                case 'unknown_error':
                    await openErrorModal({
                        title: `We're Having Some Trouble`,
                        // @ts-ignore
                        className: 'ppg-error-modal',
                        content: () => (
                            <>
                                <p>A database error occurred that prevented us from saving your PPG.</p>
                                <p>Share this ID with customer service via the chat for further assistance.</p>
                            </>
                        ),
                        errorId: err.id,
                        actions: [{
                            label: 'Close',
                            key: 'close',
                            action({resolve}) {
                                resolve('close')
                            } 
                        }]
                    });
                    break;
                default:
                    await openErrorModal({
                        title: `Uh Oh! Something Unexpected Happened`,
                        // @ts-ignore
                        className: 'ppg-error-modal',
                        content: (
                            <>
                                <p>Fear not our engineering team is on the job.</p>
                            </>
                        ),
                        errorId: err.id
                    });
            }
            setShowingErrorModal(false);

            setBusy(false);
        }
    }

    function onUseDefaultCharacteristicsChange(useDefault) {
        setUseDefaultCharacteristics(useDefault);
        setCharacteristics(useDefault ? [] : [null]);
    }

    function onCharacteristicsChange(v) {
        setCharacteristics(v);
    }

    function renderStep() {
        switch (currentStep) {
            case 'choose-edit':
                return <ChooseEditActionStep ppg={ppg} onComplete={onChooseEditActionComplete}/>;
            case 'upload':
                return (
                    <UploadStep
                        categories={categories}
                        upcs={upcs}
                        onUpcsChange={onUpcsChange}
                        onCategoriesChange={onCategoriesChange}
                        onComplete={onUploadStepComplete}
                        busy={busy}
                        isComma={isComma}
                        onActionClick={onActionClick}
                    />
                );
            case 'configure':
                return (
                    <ConfigureStep
                        categories={categories}
                        instructions={configInstructions}
                        useDefaultCharacteristics={useDefaultCharacteristics}
                        characteristics={characteristics}
                        includeCategorySelector={!allowUpload}
                        includeDefaultCharacteristics={allowUpload}
                        title={title}
                        busy={busy}
                        onTitleChange={setTitle}
                        onCategoriesChange={onCategoriesChange}
                        onUseDefaultCharacteristicsChange={onUseDefaultCharacteristicsChange}
                        onCharacteristicsChange={onCharacteristicsChange}
                        onComplete={onConfigureStepComplete}
                    />
                );
            case 'errors':
                return <FixIssuesStep issues={issues} onComplete={onFixIssuesComplete} proceed={proceed}/>;
            case 'pre-upload':
            default:
                // return <PreUploadStep onComplete={onPreUploadStepComplete} busy={busy}/>;
                return (
                    <PreUploadStep 
                        uploadHeading={uploadHeading} 
                        enableUploadStep={enableUploadStep} 
                        onComplete={onPreUploadStepComplete} 
                        // busy={busy} todo: see if this is needed
                    />
                )
        }
    }

    return (
        <div className={classes}>
            <div className="ppg-editor">
                <header className={'ppg-editor-header'}>
                    <h1 className={'ppg-editor-header__title'}>{heading}</h1>
                    <i className={`${baseClassName}__close`} onClick={onCloseClick}/>
                </header>
                <main className={'ppg-editor-content'}>
                    {/* @ts-ignore */}
                    <ByzzerModalMask show={loading}>{loading?.message}</ByzzerModalMask>
                    {renderStep()}
                </main>
            </div>
        </div>
    );
}
                                    // @ts-ignore
export const openPPGEditor = createModal(PPGEditor);
