import { ByzzerButton } from "@byzzer/ui-components";
import React, { useMemo, useState } from 'react';
import { create as createModal } from 'react-modal-promise';
import ByzzerModal2 from '@/components/modals/ByzzerModal2';
import { ByzzerLink } from "@/components/form";
import { TrackClick } from '@/analytics';
import { ByzzerRadio } from '@byzzer/ui-components';
import { DomainMismatchOption, DomainMismatchReasonCode, UserInvite } from '@/types/InvitationTypes';
import { openTermsAndConditions } from "@/components/modals/TermsAndConditions";
import './DomainMismatchWarning.scss';
import classnames from "classnames";

const baseClassName = 'domain-mismatch-warning';

export function DomainMismatchWarning({ 
    invitees, 
    onResolve, 
    domainMismatchInvitees,
    proceedLabel = 'Submit',
    title = `${domainMismatchInvitees?.length > 1 ? 'Emails do' : 'The Email does'} not match your company domain`,
    content,
    requireTextDetails
}: {
    invitees:  UserInvite[];
    onResolve?: (data?: any) => void;
    domainMismatchInvitees: UserInvite[];
    proceedLabel?: string;
    title?: string;
    content: string;
    requireTextDetails?: boolean;
}) {
    const [busy, setBusy] = useState(false);
    const [reason, setReason] = useState<DomainMismatchReasonCode | ''>('');
    const [invitationExplanation, setInvitationExplanation] = useState('');

    const domainMismatchOptions: DomainMismatchOption[] = [
        {
            value: 'anotherDomain',
            display: `${domainMismatchInvitees?.length > 1 ? 'They use' : 'This email uses'} another domain that also belongs to your company.`,
        },
        {
            value: 'personal',
            display: `${domainMismatchInvitees?.length > 1 ? 'These are their personal emails' : 'This is their personal email'} because your company email isn't ready yet.`,
        },
        {
            value: 'externalMember',
            display: `${domainMismatchInvitees?.length > 1 ? 'They are external team members who are' : 'This is an external team member who is'} not a part of your company.`,
        },
    ];

    function handleReasonSelect({value}: {value: DomainMismatchReasonCode}) {
        setReason(value);
    }

    function handleDescriptionTextChange({target}) {
        const value = target?.value;
        setInvitationExplanation(value);
    }

    function onCloseClick() {
        onResolve?.(false);
    }

    const domainMismatchReasonCode = reason;

    const handleSendClick = async () => {
        setBusy(true);
        try {
            setBusy(false);
            onResolve?.({
                type: 'domainMismatch',
                data: {
                    domainMismatchReasonCode,
                    invitationExplanation
                },
            });
        } catch (e) {
            console.error(e);
        } finally {
            setBusy(false);
        }
    };

    const modalHeader = title ?? `${domainMismatchInvitees?.length > 1 ? 'Emails do' : 'The Email does'} not match your company domain`;

    const modalContent = (
        <p className={`${baseClassName}__modal-description`}>
            {content ?? <>
                That's no problem, they can still be invited. Tell us a little more about{' '}
                {domainMismatchInvitees?.length > 1 ? 'them' : 'this user'}.
            </>}
        </p>
    );

    const disableSend = useMemo(
        () => !reason || (requireTextDetails && invitationExplanation?.trim()?.length < 5) || busy,
        [reason, invitationExplanation, requireTextDetails, busy]
    );

    return (
        // @ts-ignore
        <ByzzerModal2 className={`${baseClassName}__modal`}>
            {/* @ts-ignore */}
            <ByzzerModal2.Header className={`${baseClassName}__modal-title`} onClose={onCloseClick}>
                {modalHeader}
            </ByzzerModal2.Header>
            {/* @ts-ignore */}
            <ByzzerModal2.Content>
                {modalContent}
                    <div className={`${baseClassName}__modal-inputs`}>
                        <div className={`${baseClassName}__modal-options`}>
                            {domainMismatchOptions.map((option) => {
                                const { value, display } = option;
                                return (
                                    <ByzzerRadio
                                        name={'reason'}
                                        key={value}
                                        value={value}
                                        label={display}
                                        checked={reason === value}
                                        onChange={handleReasonSelect}
                                    />
                                );
                            })}
                        </div>
                        {requireTextDetails && (
                            <div className={classnames(`${baseClassName}__description`)}>
                                <p>
                                    To ensure your invitation gets approved, please provide details on the role this member
                                    plays at your company.
                                </p>
                                <div className={classnames(`${baseClassName}__validation-message`)}>
                                    {invitationExplanation?.trim()?.length < 5 ? 'Minimum 5 characters required.' : ''}
                                </div>
                                <textarea 
                                    className={classnames(`${baseClassName}__description-text`)}
                                    placeholder="Enter details here" 
                                    value={invitationExplanation} 
                                    onChange={handleDescriptionTextChange}
                                />
                            </div>
                        )}
                    </div>
                <div className={`${baseClassName}__modal-tnc`}>
                    Please review the {` `}
                    <ByzzerLink label={'Terms & Conditions'} onClick={openTermsAndConditions} /> to make sure this user
                    can be added to your account per the NielsenIQ data-sharing guidelines.
                </div>
            </ByzzerModal2.Content>
            <ByzzerModal2.Footer className={`${baseClassName}__modal-footer`}>
                <ByzzerButton
                    className={`${baseClassName}__modal-footer-cancel`}
                    type="negative"
                    onClick={() => onResolve?.(false)}
                    label="Cancel"
                />
                <TrackClick // todo: may be able to remove track click if we choose to store data in invitation schema
                    name={'invitation_for_mismatched_domain'}
                    data={domainMismatchReasonCode}
                >
                    <ByzzerButton
                        label={busy ? 'Loading...' : proceedLabel}
                        disabled={disableSend}
                        className={`${baseClassName}__modal-footer-invite`}
                        onClick={handleSendClick}
                    />
                </TrackClick>
            </ByzzerModal2.Footer>
        </ByzzerModal2>
    );
}
// @ts-ignore
export const openDomainMismatchWarning = createModal(DomainMismatchWarning);