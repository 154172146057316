import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import {
    LegacyByzzerButton,
    ByzzerLink,
    ByzzerMultiSelect,
    LegacyByzzerSearch,
    LegacyByzzerSelect,
    ByzzerErrorModal,
    LegacyByzzerRadio,
    ByzzerInput,
} from '../form';
import './AlertProductSelector.scss';
import {useTenantApi} from '@/hooks/useTenantApi';
import { selectorLabels, selectorStates } from '@/config/globalVars';
import PlusOutlined from '@/components/icons/PlusOutlined';
import { useSetState } from '@/utils';
import classnames from 'classnames';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { useUser } from "@/contexts/UserContext";
import { ByzzerButton } from '@byzzer/ui-components';

const AlertProductSelector = (props) => {
    const { getCustomCharWithNASegment, getCategoryByBrand, getCompanyPreferences, getProductChars, getCustomChars, getCharacteristicValues } = useTenantApi();
    const [charConditionValuesData, setCharConditionValuesData] = useState<any>([]);
    // todo: This is a quick fix for resolving characteristics retention issue which needs to be handled properly later
    let charConditionValuesDataRef = useRef<any>()
    charConditionValuesDataRef.current = charConditionValuesData;
    const [formData, setFormData] = useState<any>([
        {
            brand: '',
            category: [] || '',
        },
    ]);
    const formDataRef = useRef<any>();
    const [showCharacteristicFilters, setShowCharacteristicFilters] = useState(false);
    const [characteristicsMapData, setCharacteristicsMapData] = useState({});
    const [productSet, setProductSet] = useState([]);
    const [customChars, setCustomChars] = useState([]);
    const [categoryAlias, setCategoryAlias] = useState('');
    const [loading, setLoading] = useState(false);
    const charConditionOptions = ['is', 'is not'];
    const [competitiveProductSetList, setCompetitiveProductSetList] = useState([]);
    const optionalBrandSku = ['237'];
    // const [subCategoriesList, setSubCategoriesList] = useState([]);
    const [singleFormData, setSingleFormData] = useSetState({
        brand: '',
        category: [] || '',
    });
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [contentType, setContentType] = useState('warning');
    const [showUpdate, setShowUpdate] = useState(false);
    const CUSTOM_CHAR_EXTENSION = ' (Custom)';
    const themePurchaseType = 'subscription_addon_characteristic_theme';
    const [charCodesData, setCharCodesData] = useState([]);
    const { defaultRunConfig } = useUser();

    useEffect(() => {
        let userPrefBrand = Array.isArray(defaultRunConfig?.brands)
            ? defaultRunConfig?.brands?.length < 1
                ? ''
                : defaultRunConfig?.brands[0]
            : defaultRunConfig?.brands;
        setFormData([
            {
                brand: userPrefBrand,
                category: defaultRunConfig?.categories ?? [],
            },
        ]);

        let brand = '';
        if (props?.selectorState?.focusProductSelections?.length > 0) {
            brand = props?.selectorState?.focusProductSelections[0].brand;
        }
        setCategoryAlias(props?.selectorState?.categoryAlias);
        fetchCompetitiveProductSetList(brand);
        // NOTE: Commenting the below line, will remove after edit alerts characteristics issue get fixed
        // if (!props?.fromBack) fetchUserPref();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        formDataRef.current = formData;
    }, [formData])

    const resetFocusMarket = () => {  // resetting focusMarket on brand change originally part of BYZ-5169; changed approach to use function instead of state as error was received in console
        let selectorStateTemp = { ...props.selectorState };
        let clearFlag = false;
        let currentStepIndex = selectorStateTemp.selectorsStatus.findIndex(
            (elem) => elem.labelName === selectorLabels.product
        );
        selectorStateTemp.selectorsStatus?.forEach((statusItem, index) => {
            if (index === currentStepIndex || statusItem.selectorName === selectorLabels.focusMarket) {
                selectorStateTemp.selectorsStatus[index].status = selectorStates.pending;
                clearFlag = true;
            }
        });
        selectorStateTemp.clearFocusMarket = true;
        if (clearFlag) {
            props.setSelectorData(selectorStateTemp);
        }
    }

    /**
     * to fetch user info   
     * NOTE: Moved this logic to initial useEffect, so fetching user config will run everytime by default. Might need to check this logic in future
     */
    const fetchUserPref = async (categoryList) => {
        if (defaultRunConfig?.brands?.length) { // If multiple default brands, do not use else use
            let userPrefBrand = Array.isArray(defaultRunConfig?.brands)
                ? defaultRunConfig?.brands?.length < 1
                    ? ''
                    : defaultRunConfig?.brands[0]
                : defaultRunConfig?.brands;
            setFormData([
                {
                    brand: userPrefBrand,
                    category: defaultRunConfig?.categories ?? [],
                },
            ]);
            fetchCompetitiveProductSetList(userPrefBrand);
        }
    };

    const submitProductSelector = () => {
        if (alertConfigs?.multi) {
            const checkDup =
                formData?.length > 0
                    ? formData.filter(
                        (v, i, a) => a.findIndex((t) => t.brand === v.brand && t.category === v.category) === i
                    )
                    : [];
            if (checkDup?.length < formData?.length) {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage('Selections should not be identical.');
            } else {
                // This could be a quick fix only.
                // Instead of checking for "showCharacteristicFilters", we might resolve this at "handleCharacteristicFiltersDisplay()" function "handleCharacteristicFiltersDisplay()"
                const characteristicFilters =
                    showCharacteristicFilters && charConditionValuesData.length > 0 ? extractCharFiltersSelection(charConditionValuesData) : [];
                let selectorStateTemp = JSON.parse(JSON.stringify(props.selectorState));
                let index = selectorStateTemp.selectorsStatus.findIndex(
                    (elem) => elem.labelName === selectorLabels.product
                );
                selectorStateTemp.selectorsStatus[index].status = selectorStates.completed;
                selectorStateTemp.activeCollapsePanel = [index + 1];
                selectorStateTemp.focusProductSelections = formData;
                selectorStateTemp.characteristicFilters = characteristicFilters;
                selectorStateTemp.categoryAlias = categoryAlias;
                selectorStateTemp.refreshMarkets = true;
                selectorStateTemp.clearFocusMarket = true;
                selectorStateTemp.clearMarketsToWatch = true;
                props.setSelectorData(selectorStateTemp);
            }
        } else {
            // This could be a quick fix only.
            // Instead of checking for "showCharacteristicFilters", we might resolve this at "handleCharacteristicFiltersDisplay()" function "handleCharacteristicFiltersDisplay()"
            const characteristicFilters =
                showCharacteristicFilters && charConditionValuesData.length > 0 ? extractCharFiltersSelection(charConditionValuesData) : [];
            let selectorStateTemp = JSON.parse(JSON.stringify(props.selectorState));
            let index = selectorStateTemp.selectorsStatus.findIndex(
                (elem) => elem.labelName === selectorLabels.product
            );
            selectorStateTemp.selectorsStatus[index].status = selectorStates.completed;
            selectorStateTemp.activeCollapsePanel = [index + 1];
            selectorStateTemp.focusProductSelections = formData;
            selectorStateTemp.characteristicFilters = characteristicFilters;
            selectorStateTemp.categoryAlias = categoryAlias;
            selectorStateTemp.refreshMarkets = true;
            selectorStateTemp.clearFocusMarket = true;
            selectorStateTemp.clearMarketsToWatch = true;
            props.setSelectorData(selectorStateTemp);
        }
    };

    const getAlertConfigs = () => {
        return props.selectorState.alertConfigurations.productSelectors;
    };

    const changeBrand = async (brandValue, index) => {
        resetFocusMarket();
        const oldFormData = [...formData];
        oldFormData[index].brand = brandValue;
        oldFormData[index].category = [];
        await fetchCompetitiveProductSetList(brandValue);
        setFormData(oldFormData);
        setSingleFormData({ brand: brandValue });
        setCharConditionValuesData([]);
        charConditionValuesDataRef.current = [];
        handleCharacteristicFiltersDisplay(false);
        setShowUpdateButton();
    };

    const changeCategory = (categoryValue, index) => {
        resetFocusMarket();
        setCharacteristicsMapData({}); // Reset Characteristics
        setCharConditionValuesData([]);
        charConditionValuesDataRef.current = [];
        handleCharacteristicFiltersDisplay(false);
        const oldFormData = [...formData];
        oldFormData[index].category = categoryValue;
        oldFormData[index].brand = oldFormData[index]?.brand;
        setFormData(oldFormData);
        setSingleFormData({ category: categoryValue });
        renderCharFiltersRows([], [], 'initial');
        setShowUpdateButton();
    };

    const clearSingleFormData = () => {
        setSingleFormData({
            brand: '',
            category: [] || '',
        });
    };

    const validateSingleFormData = (singleFormDat) => {
        if (singleFormDat.brand && singleFormDat.category && singleFormDat.category?.length > 0) {
            return true;
        }
        return false;
    };
    const validateForm = () => {
        let status = false;
        for (let index = 0; index < formData.length; index++) {
            const element = formData[index];
            if (validateSingleFormData(element)) {
                status = true;
            } else {
                status = false;
                break;
            }
        }
        return status;
    };
    const fetchCompetitiveProductSetList = async (prefBrand = '') => {
        let categList: any = [];
        let companyPrefCateg: any = [];
        setLoading(true);
        const categListResp = await getCompanyPreferences();
        if (categListResp && Object.keys(categListResp).length > 0) {
            const catResp = typeof categListResp === 'string' ? JSON.parse(categListResp) : categListResp;
            companyPrefCateg = catResp.categories || [];
        }
        const brandName = prefBrand;
        if (Boolean(brandName)) {
            const res = await getCategoryByBrand(brandName);
            let responseCategList = res?.categories || [];
            if (responseCategList) {
                categList = responseCategList.map((item) => item.name);
                {/* @ts-ignore */}
                categList = categList.filter((item) => companyPrefCateg.includes(item));
            }
        } else {
            categList = companyPrefCateg;
        }
        // await fetchSubCategories(categList);
        setCompetitiveProductSetList(categList);
        const prodSetArray = categList
            ? categList.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            })
            : [];
        setProductSet(prodSetArray);
        if (props?.selectorState?.focusProductSelections?.length > 0) {
            let finalForm = [...props?.selectorState?.focusProductSelections];
            finalForm.forEach((val) => {
                let categ = typeof val.category === 'string' ? [val.category] : val.category;
                categ.map((category, index) => {
                    return prodSetArray.findIndex((item) => category === item.name) !== -1;
                });
            });

            setFormData(finalForm);
        }

        if (
            props?.fromBack &&
            props.selectorState?.characteristicFilters?.length > 0 &&
            props.selectorState?.characteristicFilters.every((elem) => elem.characteristic !== '')
        ) {
            handleCharacteristicFiltersDisplay(true, true);
        }

        setLoading(false);
    };

    const preloadCharFilters = async (historyCharFilters, categList) => {
        setLoading(true);
        let characteristicsMap: any = [];
        let categoriesData =
            (formData?.length > 0
                ? [].concat.apply(
                    [],
                    formData?.map((item) => item.category)
                )
                : categList) || competitiveProductSetList;
        const productSetObj = {
            categories: categoriesData.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            }),
            themePurchaseType,
        };
        characteristicsMap = await getAllProductCharacteristics(productSetObj);

        if (characteristicsMap) {
            let characteristicFiltersData: any = [];
            historyCharFilters.forEach(async (charFil) => {
                let charValuesOptions = [];
                charValuesOptions = await fetchCharsValues(charFil.characteristic, categoriesData);
                characteristicFiltersData.push({
                    characteristicValue: charFil.characteristic,
                    characteristicOptions: [...characteristicsMap],
                    conditionValue: charFil.condition,
                    conditionOptions: charConditionOptions,
                    valueItemValue: charFil.value,
                    valueItemOptions: charValuesOptions ?? [],
                });
            });
            setCharConditionValuesData(characteristicFiltersData);
            charConditionValuesDataRef.current = characteristicFiltersData;
        }
        setLoading(false);
    };

    /**
     *
     * commented due to BYZ-2122
     */
    // const fetchSubCategories = async(categList) => {
    //     const subCatList = [];
    //     for (let index = 0; index < categList.length; index++) {
    //         const val = categList[index];
    //         const fetchSubCategories = await lookup({
    //             hierarchy: `category`,
    //             value: val,
    //         });
    //         const subCategories = fetchSubCategories?.data?.subcategories;
    //         if(subCategories){
    //             subCatList.push({'parent': val, 'child': subCategories?.map(subCat => {return subCat.name})});
    //         }

    //     }

    //     setSubCategoriesList(subCatList);

    // }
    const renderBrand = (selectedBrand = '', index = 0) => {
        const alertConfigs = getAlertConfigs();
        if (alertConfigs?.brand) {
            return (
                <>
                    <div className="product-selector__subset-option-text-two">
                        {`The focus product controls the universal products across the alert. If you want to filter the alert to a subset of the category or categories, use the characteristic filters to do so.`}
                    </div>

                    <div className="product-selector__focus-brand">
                        <div className="product-selector__focus-brand-item">
                            <label className="product-selector__focus-brand-item-label">
                                Select your brand{optionalBrandSku.includes(props?.sku) ? ' (Optional)' : ''}:
                            </label>
                        </div>
                        <div className="product-selector__focus-brand-select">
                            {/* @ts-ignore */}
                            <LegacyByzzerSearch
                                key={`brand-${selectedBrand}`}
                                onChange={(e) => changeBrand(e, index)}
                                productSet={productSet}
                                type={'searchBrandForProductSet'}
                                value={selectedBrand || formData[index]?.brand}
                                placeholder="Search for a brand"
                            />
                        </div>
                    </div>
                </>
            );
        }
    };
    // const renderSingleCategory = (selectedCategory = null) => {

    // }
    const renderCategory = (selectedCategory = '', index = 0) => {
        const alertConfigs = getAlertConfigs();
        if (alertConfigs?.categories) {
            return (
                <div className="product-selector__competitive-set-advance-search">
                    <div className="product-selector__competitive-set">
                        <div className="product-selector__competitive-set-item">
                            <label className="product-selector__competitive-set-item-label">
                                Select your Category(s):
                            </label>
                        </div>
                        <div className="product-selector__competitive-set-select">
                            {alertConfigs?.categories?.multi ? (
                                // @ts-ignore
                                <ByzzerMultiSelect
                                    inlineFontStyle={'normal'}
                                    // selectedOptions={
                                    //     productSelectorData.primaryCategory ? [productSelectorData.primaryCategory] : productSelectorData.category
                                    // }
                                    // disabled={showSelector('focusBrand') && !productSelectorData.focusBrand}
                                    name={'competitive-productset'}
                                    placeholder={'Select from the list'}
                                    multiSelectWithCheckBox
                                    onChange={(e) => changeCategory(e, index)}
                                    options={competitiveProductSetList || []}
                                    seperator={'OR'}
                                    selectedOptions={formData[index]?.category || []}
                                // subOptions={subCategoriesList}
                                // key={`category-${formData[index]?.category}`}
                                />
                            ) : (
                                // @ts-ignore
                                <LegacyByzzerSelect
                                    value={selectedCategory}
                                    placeholder={'Select from the list'}
                                    options={competitiveProductSetList || []}
                                    onChange={(e) => changeCategory(e, index)}
                                // onChange={(e) => selectCharFilterData(e, 'characteristic', index)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    };
    const addCharFiltersRow = () => {
        renderCharFiltersRows();
    };

    const renderCharFiltersRows = async (charMap = [], compProdSet = [], triggerType = '', retainData = false) => {
        setLoading(true);
        let characteristicsMap: any = [];
        if (charMap && charMap.length > 0) {
            characteristicsMap = charMap;
        } else {
            const formDatacategories = formDataRef.current?.map((item) => item.category);
            const productSetObj = {
                categories: (typeof formDataRef.current === 'object' && formDataRef.current?.length > 0
                    ? [].concat.apply(
                        [],
                        formDatacategories
                    )
                    : competitiveProductSetList
                ).map((item) => {
                    return {
                        hierarchy: 'CATEGORY',
                        name: item,
                    };
                }),
                themePurchaseType,
            };
            characteristicsMap = await getAllProductCharacteristics(productSetObj);
        }
        if (characteristicsMap) {
            const charConditionValuesObj = {
                characteristicValue: '',
                characteristicOptions: [...characteristicsMap],
                conditionValue: charConditionOptions[0],
                conditionOptions: charConditionOptions,
                valueItemValue: [],
                valueItemOptions: [],
            };
            if (triggerType === 'initial') {
                setCharConditionValuesData([charConditionValuesObj]);
                charConditionValuesDataRef.current = [charConditionValuesObj];

            } else {
                setCharConditionValuesData((prevState) => [...prevState, charConditionValuesObj]);
                charConditionValuesDataRef.current = [...charConditionValuesDataRef.current, charConditionValuesObj];

            }
            if (props?.fromBack && retainData) {
                fetchCharacteristicsData(charConditionValuesObj);
            }
        }
        setLoading(false);
    };

    const fetchCharacteristicsData = async (charConditionValuesObj) => {
        let characteristicsFiltersDataTemp: any = [];
        setLoading(true);
        for (let element of props.selectorState?.characteristicFilters) {
            // fetch characteristics values options
            let charValuesOptions = await fetchCharsValues(
                element?.characteristic,
                formDataRef.current?.length > 0
                    ? [].concat.apply(
                        [],
                        formDataRef.current?.map((item) => item.category)
                    )
                    : []
            );

            let characteristicsFiltersDataObj = {
                characteristicValue: element.characteristic,
                conditionValue: element.condition,
                valueItemValue: element.value,
                characteristicOptions: charConditionValuesObj?.characteristicOptions,
                conditionOptions: charConditionValuesObj?.conditionOptions,
                valueItemOptions: charValuesOptions ?? [],
            };
            characteristicsFiltersDataTemp.push(characteristicsFiltersDataObj);
        }
        setCharConditionValuesData(characteristicsFiltersDataTemp);
        charConditionValuesDataRef.current = characteristicsFiltersDataTemp;

        setLoading(false);
    };

    const getAllProductCharacteristics = useCallback(async (productSet) => {
        const [charsResp, customCharacteristics] = await Promise.all([getProductChars(productSet), getCustomChars()]);
        if (charsResp) {
            setCharCodesData(charsResp?.characteristics);
        }
        setCustomChars(customCharacteristics);
        const customCharsForCategories = customCharacteristics.filter((item) =>
            productSet?.categories?.map((categ) => categ.name)?.some((i) => item.categories.includes(i))
        );
        const allProdChars = [
            ...customCharsForCategories?.map((item) => item?.label + CUSTOM_CHAR_EXTENSION)?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase())),
            ...charsResp?.characteristics?.map((item) => item?.chr_display_name)?.sort((a, b) => a?.trim()?.toLowerCase()?.localeCompare(b?.trim()?.toLowerCase()))
        ];
        setCharacteristicsMapData(allProdChars); // data for characteristics dropdown
        return allProdChars;
    }, [productSet]);

    const selectCharFilterData = async (e, fieldName, index, selectedVal = null) => {
        let currentCharConditionValuesData = [...charConditionValuesDataRef.current]
        let currentCharConditionValuesDefData = [...charConditionValuesDataRef.current];
        switch (fieldName) {
            case 'characteristic':
                currentCharConditionValuesData[index].characteristicValue = e;
                currentCharConditionValuesData[index].valueItemOptions = [];
                currentCharConditionValuesData[index].valueItemValue = [];
                currentCharConditionValuesData[index].conditionValue = charConditionOptions[0];
                const charValuesOptions = await fetchCharsValues(
                    e,
                    formData?.length > 0
                        ? [].concat.apply(
                            [],
                            formData?.map((item) => item.category)
                        )
                        : []
                );
                currentCharConditionValuesData[index].valueItemOptions = charValuesOptions ?? [];
                setLoading(false);
                if (e === 'Manufacturer' || e === 'Brand') {
                    setShowWarning(true);
                    setContentType('warning');
                    setWarningMessage(
                        'When applying a brand or manufacturer filter, make sure to include Focus Brand/Manufacturer in your selections.'
                    );
                }
                break;
            case 'condition':
                currentCharConditionValuesData[index].conditionValue = e;
                break;
            case 'value':
                currentCharConditionValuesData[index].valueItemValue = e;
                break;
            default:
                break;
        }
        const checkValidation = CharacteristicFormValidation(currentCharConditionValuesData);
        if (checkValidation) {
            const charValsList = currentCharConditionValuesData.map((item) => item.characteristicValue);
            if (charValsList.length > new Set(charValsList).size) {
                setShowWarning(true);
                setWarningMessage(
                    'To select multiple values of the same characteristic, use the check boxes instead of another filter.'
                );
                setContentType('warning');
            } else {
                setCharConditionValuesData(currentCharConditionValuesData);
                charConditionValuesDataRef.current = currentCharConditionValuesData;

            }
        } else {
            currentCharConditionValuesDefData[index]?.valueItemValue?.splice(
                currentCharConditionValuesDefData[index]?.valueItemValue?.indexOf(selectedVal),
                1
            );
            setCharConditionValuesData(currentCharConditionValuesDefData);
            charConditionValuesDataRef.current = currentCharConditionValuesDefData;

            setShowWarning(true);
            setContentType('warning');
            setWarningMessage('Filters should not be identical.');
        }
        setShowUpdateButton();
    };
    const removeCharac = (index) => {
        if (index > 0) {
            let currentCharConditionValuesData = JSON.parse(JSON.stringify(charConditionValuesData));
            currentCharConditionValuesData.splice(index, 1);
            setCharConditionValuesData(currentCharConditionValuesData);
            charConditionValuesDataRef.current = currentCharConditionValuesData;

            setShowUpdateButton();
        }
    };

    const CharacteristicFormValidation = (currentCharConditionValuesData) => {
        let status = true;
        for (let index = 0; index < currentCharConditionValuesData.length; index++) {
            const findData = currentCharConditionValuesData.filter((val) => {
                return (
                    val.characteristicValue === currentCharConditionValuesData[index].characteristicValue &&
                    (val.valueItemValue.join() === currentCharConditionValuesData[index].valueItemValue.join() ||
                        val.valueItemValue.filter((value) => {
                            return currentCharConditionValuesData[index].valueItemValue.includes(value);
                        }).length > 0)
                );
            });
            if (findData.length > 1) {
                status = false;
                break;
            }
        }
        return status;
    };
    const renderCharFiltersOptions = () => {
        return (
            <>
                {charConditionValuesData.length > 0 &&
                    charConditionValuesData.map((item, index) => {
                        return (
                            <>
                                {index > 0 && (
                                    <div key={index} className="product-selector__characteristics-and">
                                        And
                                        {/* <ByzzerButton label={'x'} onClick={() => removeCharac(index)} /> */}
                                        {/* <div className={'alert-product-selector-multiform-remove'}>
                                            <span onClick={() => removeCharac(index)}>X Remove</span>
                                        </div> */}
                                    </div>
                                )}

                                <div
                                    className={classnames(`product-selector__characteristics`, {
                                        [`product-selector__characteristics--removable`]: index > 0,
                                    })}
                                >
                                    {/* @ts-ignore */}
                                    <LegacyByzzerSelect
                                        className="product-selector__characteristics-item"
                                        label={'Characteristic'}
                                        value={item.characteristicValue}
                                        placeholder={item.characteristicValue || 'Select from the list'}
                                        options={item.characteristicOptions}
                                        onChange={(e) => selectCharFilterData(e, 'characteristic', index)}
                                        allowClear={true}
                                    />
                                    {/* @ts-ignore */}
                                    <LegacyByzzerSelect
                                        label={'Condition'}
                                        className="product-selector__characteristics-condition"
                                        value={item.conditionValue}
                                        placeholder={item.conditionValue || ''}
                                        options={item.conditionOptions}
                                        onChange={(e) => selectCharFilterData(e, 'condition', index)}
                                    />
                                    {/* @ts-ignore */}
                                    <ByzzerMultiSelect
                                        label={'Value'}
                                        className="product-selector__characteristics-value"
                                        inlineFontStyle={'normal'}
                                        selectedOptions={item.valueItemValue}
                                        name={'char-value'}
                                        value={item.valueItemValue}
                                        multiSelectWithCheckBox
                                        onChange={(e, selectedVal) =>
                                            selectCharFilterData(e, 'value', index, selectedVal)
                                        }
                                        options={item.valueItemOptions || []}
                                        seperator={'OR'}
                                    />

                                    {index > 0 && (
                                        <ByzzerButton 
                                            className={`'product-selector'__characteristics-remove`}
                                            type={'negative'}
                                            onClick={() => removeCharac(index)}
                                        >
                                            x
                                        </ByzzerButton>
                                    )}

                                    {/* <div className="alert-product-selector__characteristics-action"> */}
                                    {/* <label>1</label> */}
                                    {/* {index > 0 && (
                                                <ByzzerButton
                                                    label={'x'}
                                                    onClick={() => removeCharac(index)}
                                                />
                                            )} */}
                                </div>
                            </>
                        );
                    })}
            </>
        );
    };
    const renderCharacteristics = () => {
        const alertConfigs = getAlertConfigs();
        if (alertConfigs?.characteristicFilter) {
            return (
                <>
                    <div className="product-selector__subset-option-text-two">
                        Would you like to filter the alert to a subset of comparison?
                    </div>
                    <div className="product-selector__subset-option-text-two">
                        Are you focused only on a particular product size, or specific brands? Do you belong to a niche
                        subset such as Organic or Natural only? Apply characteristic filters to narrow your alert to
                        only the products that matter to you.
                    </div>

                    <div className="product-selector__subset-option-radio">
                        {/* @ts-ignore */}
                        <LegacyByzzerRadio
                            checked={!showCharacteristicFilters}
                            name="subset-option"
                            label={`No, configure my alert with the selected comparison set.`}
                            onChange={() => handleCharacteristicFiltersDisplay(false)}
                        />
                    </div>

                    <div className="product-selector__subset-option-radio">
                        {/* @ts-ignore */}
                        <LegacyByzzerRadio
                            checked={showCharacteristicFilters}
                            name="subset-option"
                            label={`Yes, add more filters to my alert.`}
                            onChange={() => handleCharacteristicFiltersDisplay(true)}
                        />
                    </div>

                    {showCharacteristicFilters && (
                        <div className="product-selector__characteristics-container">
                            {charConditionValuesData.length > 0 && (
                                <span>Select up to {alertConfigs?.characteristicFilter?.maxLimit} Characteristics</span>
                            )}
                            {renderCharFiltersOptions()}
                            {charConditionValuesData.length > 0 &&
                                charConditionValuesData.length < alertConfigs?.characteristicFilter?.maxLimit && (
                                    <div className="product-selector__characteristics-add">
                                        <span
                                            onClick={() => addCharFiltersRow()}
                                            className="product-selector__characteristics-add-icon"
                                        >
                                            <PlusOutlined />
                                        </span>
                                        <span onClick={() => addCharFiltersRow()}>Add another condition</span>
                                    </div>
                                )}
                        </div>
                    )}
                </>
            );
        }
    };

    const onChangecategoryAlias = ({ target }) => {
        setCategoryAlias(target.value);
        setShowUpdateButton();
    };

    const renderCategoryAlias = (selectedBrand = '', index = 0) => {
        return (
            <>
                <div className="product-selector__focus-brand-item-story">
                    <label className="product-selector__focus-brand-item-label">
                        <b>Name your Category</b>
                    </label>
                </div>

                <div className="product-selector__subset-option-text-two">
                    {`Rename the product to something that will be relevant to you and the retailer. For example, the categories like Baby Food and Infant Formula with characteristic filter Organic would be renamed to "Organic Baby Feeding".`}
                </div>

                <div className="product-selector__submit-btn-container__alias-container">
                    {/* @ts-ignore */}
                    <ByzzerInput
                        style={{ width: '20em' }}
                        className={'product-selector__alias-input'}
                        name={'productSelectionAlias'}
                        value={categoryAlias}
                        placeholder={'Category Name'}
                        onChange={onChangecategoryAlias}
                        required
                    ></ByzzerInput>
                </div>
            </>
        );
    };
    const handleCharacteristicFiltersDisplay = (val, retainData = false) => {
        if (val === true) {
            if (formData.filter((item) => item.category.length > 0).length > 0 || retainData) {
                renderCharFiltersRows([], [], 'initial', retainData);
                setShowCharacteristicFilters(val);
            } else {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage('Please choose atleast one competitive/product set.');
            }
        } else {
            setShowCharacteristicFilters(val);
        }
        if (!retainData) {
            setShowUpdateButton();
        }
    };

    const removeForm = (formIndex) => {
        const oldFormData = JSON.parse(JSON.stringify(formData));
        if (oldFormData[formIndex]) {
            oldFormData.splice(formIndex, 1);
            setFormData(oldFormData);
            setShowUpdateButton();
        }
    };
    const extractCharFiltersSelection = (charFiltersData) => {
        let tempArray = [];
        const charFiltersTemp = JSON.parse(JSON.stringify(charFiltersData));
        tempArray = charFiltersTemp?.map(function (item) {
            return {
                characteristic: item?.characteristicValue,
                condition: item?.conditionValue,
                value: item?.valueItemValue,
            };
        });
        return tempArray;
    };
    const renderMultiForm = () => {
        return (
            <>
                <div className="product-selector alert-product-selector-subheader_container">
                    <div className="alert-product-selector-subheader">{`Select up to ${alertConfigs?.limit} brand category combinations`}</div>
                </div>
                {formData &&
                    formData.length > 0 &&
                    formData.map((val, ind) => {
                        return (
                            <>
                                <div className="product-selector">
                                    {renderBrand(val.brand, ind)}
                                    {renderCategory(val.category, ind)}
                                </div>
                                {formData.length > 1 && (
                                    <div className={'alert-product-selector-multiform-remove'}>
                                        <span onClick={() => removeForm(ind)}>X Remove</span>
                                    </div>
                                )}
                            </>
                        );
                    })}
            </>
        );
    };
    const addForm = () => {
        const alertConfigs = getAlertConfigs();
        if (validateForm()) {
            if (formData.length < alertConfigs?.limit) {
                const oldFormData = JSON.parse(JSON.stringify(formData));
                oldFormData.push({
                    brand: '',
                    category: [] || '',
                });
                setFormData(oldFormData);
                clearSingleFormData();
            } else {
                setShowWarning(true);
                setContentType('warning');
                setWarningMessage(
                    `You can not select more than ${alertConfigs?.limit} in combination of brand and category.`
                );
            }
        } else {
            setShowWarning(true);
            setContentType('warning');
            setWarningMessage('Please Fill Brand and Category.');
        }
    };
    const renderForm = () => {
        const alertConfigs = getAlertConfigs();
        if (alertConfigs?.multi) {
            return (
                <>
                    <div className={'alert-product-selector-multiform'}>
                        {renderMultiForm()}
                        <div className={'alert-product-selector-multiform-add'}>
                            {formData.length < alertConfigs?.limit && (
                                <span onClick={() => addForm()}>+ Add Another Brand</span>
                            )}
                        </div>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="product-selector">
                    {renderBrand()}
                    {renderCategory()}
                    {renderCharacteristics()}
                    {renderCategoryAlias()}
                </div>
            </>
        );
    };

    const isNotFormValidated = () => {
        let alertConfigs = getAlertConfigs();
        let formValidation = true;
        if (alertConfigs?.multi) {
            return !validateForm();
        }
        for (let index = 0; index < formData.length; index++) {
            const element = formData[index];

            if (alertConfigs?.categories?.required && alertConfigs?.brand?.required) {
                if (element.category.length > 0 && element.category && element.brand && element.brand !== '') {
                    formValidation = false;
                    break;
                }
            }
            if (alertConfigs?.brand?.required && !alertConfigs?.categories?.required) {
                if (element.brand && element.brand !== '') {
                    formValidation = false;
                    break;
                }
            }
            if (!alertConfigs?.brand?.required && alertConfigs?.categories?.required) {
                if (element.category && element.category.length > 0) {
                    formValidation = false;
                    break;
                }
            }
        }
        if (showCharacteristicFilters) {
            const charFiltersStatus =
                charConditionValuesData.length > 0 &&
                charConditionValuesData.filter(function (el) {
                    return (
                        el.characteristicValue !== '' &&
                        el.conditionValue !== '' &&
                        el.valueItemValue !== '' &&
                        el.valueItemValue.length > 0
                    );
                });
            const charValidation = charFiltersStatus && charFiltersStatus.length !== charConditionValuesData.length;
            formValidation = formValidation || charValidation;
        }
        return formValidation;
    };

    const setShowUpdateButton = () => {
        setShowUpdate(true);
        let selectionsToSelState = JSON.parse(JSON.stringify(props?.selectorState));
        let index = selectionsToSelState.selectorsStatus.findIndex((elem) => elem.labelName === selectorLabels.product);
        selectionsToSelState.selectorsStatus[index].status = selectorStates.pending;
        props.setSelectorData({ selectorsStatus: selectionsToSelState.selectorsStatus });
    };

    async function fetchCharsValues(charItem, categoriesList = []) {
        setLoading(true);
        let charsValueOptions: any = [];
        let customCharacteristics: any = [];
        if (customChars?.length === 0) {
            customCharacteristics = await getCustomChars();
            setCustomChars(customCharacteristics);
        }
        if (charItem?.includes(CUSTOM_CHAR_EXTENSION)) {
            // Custom Chars
            customCharacteristics = customChars?.length > 0 ? customChars : customCharacteristics;
            const customCharId = customCharacteristics
                ?.find((item) => item.label + CUSTOM_CHAR_EXTENSION === charItem)
                ?.id;
            charsValueOptions = await getCustomCharWithNASegment(customCharId);
        } else {
            const productSet = categoriesList?.map((item) => {
                return {
                    hierarchy: 'CATEGORY',
                    name: item,
                };
            });
            const productSetObj = {
                categories: productSet,
                themePurchaseType,
            };
            let charsResp: any = [];
            let charsAndCodes: any = [];
            if (charCodesData?.length === 0) {
                charsResp = await getProductChars(productSetObj);
                if (charsResp) {
                    charsAndCodes = [...charsResp?.characteristics];
                    setCharCodesData(charsResp?.characteristics);
                }
            } else {
                charsAndCodes = [...charCodesData];
            }
            const charValsResp = await getCharacteristicValues({
                categories: productSet,
                charCode: charsAndCodes?.find((item) => item.chr_display_name === charItem)?.chr_code,
            });
            charsValueOptions = [...charValsResp];
        }
        setLoading(false);
        return charsValueOptions;
    }

    const alertConfigs = getAlertConfigs();
    return (
        <>
            <ByzzerMask loading={loading} />
            <div>
                {renderForm()}
                <div className={'product-selector__submit-btn-container alias-container'}>
                    {!props?.fromBack && (
                        <LegacyByzzerButton
                            label={'Next'}
                            disabled={isNotFormValidated()}
                            onClick={() => submitProductSelector()}
                        />
                    )}
                    {props?.fromBack && (
                        <LegacyByzzerButton
                            label={showUpdate ? 'Update' : 'Next'}
                            disabled={showUpdate ? isNotFormValidated() : true}
                            onClick={() => submitProductSelector()}
                        />
                    )}
                </div>
                {showWarning && (
                    <ByzzerErrorModal
                        showWarning={showWarning}
                        setShowWarning={setShowWarning}
                        headerType={'none'}
                        contentType={contentType}
                        warningMessage={warningMessage}
                        size={'small'}
                    />
                )}
            </div>
        </>
    );
};
export default AlertProductSelector;

AlertProductSelector.displayName = 'AlertProductSelector';