import React, { useState, useEffect, useContext } from 'react';
import { ByzzerBrandSearch } from '@/components/ByzzerBrandSearch';
import { ByzzerCategorySelect } from '@/components/ByzzerCategorySelect';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import { CharacteristicCriteriaBuilder } from '@/components/CharacteristicCriteriaBuilder';
import { ByzzerButton, ByzzerSelect } from '@byzzer/ui-components';
import {useTenantApi} from '@/hooks/useTenantApi';
import { timePeriodOptions } from '@/config/globalVars';
import { HomepageContext } from '@/contexts/HomepageContext';
import FilterMessageModal from './FilterMessageModal';
import { MyHomepageContext } from '@/contexts/MyHomePageContext';
import MinusOutlined from '@/components/icons/MinusOutlined';
import PlusOutlined from '@/components/icons/PlusOutlined';

const filterBaseClassName = 'filter_panel';

export type initialFilterValue = {
    focusBrand: any[];
    primaryCategory: any[];
    companyCategory: any[];
    primaryMarket: any | null;
    characteristicFilters: any[];
    defaultTimePeriod: string;
};

export type HomePageProps = {
    className;
    filterValues1;
    handleApply;
    setUserUpdatedFilterValues;
    reportletID;
    closeFilterPanel;
    enableFilterPanel;
    handleConfirmClose;
    showWarningMessagePopup;
    onModalFilterPanelClose;
    handleFilterState;
};

export const HomePageFilter = React.memo(({
    className,
    closeFilterPanel,
    enableFilterPanel,
    handleConfirmClose,
    showWarningMessagePopup,
    onModalFilterPanelClose,
    handleFilterState,
}: HomePageProps) => {
    const { getCategoriesByBrands } = useTenantApi();
    const { reportletData, filterValues } = useContext(MyHomepageContext).homePageState ?? {};

    const initialState = {
        focusBrand: [],
        primaryCategory: [],
        companyCategory: [],
        primaryMarket: [],
        characteristicFilters: [],
        defaultTimePeriod: '',
    };
    const { handleFilterApply, resyncWithDefaultTiles } = useContext(
        MyHomepageContext
    );
    const [categoriesOptions, setCategoriesOptions] = useState<string[]>([]);
    const [userSelectedValue, setUserSelectedValue] = useState(false);
    const [enableApply, setEnableApply] = useState(true);
    const [enableCharacteristics, setEnableCharacteristics] = useState(false);
    const [filterPanelValues, setFilterPanelValues] = useState<initialFilterValue>(filterValues);


    useEffect(() => {
        setFilterPanelValues(filterValues);
        if(filterValues?.characteristicFilters?.length) setEnableCharacteristics(true);
    }, [filterValues]);
    useEffect(() => {
        const hasPrimaryCategory = filterPanelValues?.primaryCategory?.length > 0;
        const hasPrimaryMarket = filterPanelValues?.primaryMarket;
        if (hasPrimaryCategory && hasPrimaryMarket && userSelectedValue) {
            setEnableApply(false);
            handleConfirmClose(true);
        } else {
            setEnableApply(true);
            handleConfirmClose(false);
        }
    }, [userSelectedValue, filterPanelValues]);

    useEffect(() => {
        const fetchCategories = async () => {
            if (filterPanelValues?.focusBrand?.length > 0) {
                const categories = await getCategoriesByBrands(filterPanelValues.focusBrand);
                setCategoriesOptions(categories.flat());
            }
        };

        fetchCategories();
    }, [filterPanelValues?.focusBrand]);

    const handleFilterChange = (event) => {
        const { name, value } = event;
        setFilterPanelValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setUserSelectedValue(true);
    };

    const handleEnableCharacteristicsDisplayChange = (e) => {
        if (!e) {
            setFilterPanelValues({ ...filterValues, characteristicFilters: [] });
            // setHomePageState({
            //     type: 'filter_update',
            //     filterKey: 'characteristicFilters',
            //     filterUpdateValue: [],
            // });
            setUserSelectedValue(true);
        }
        setEnableCharacteristics(e);
    };

    const handleResync = async () => {
        handleConfirmClose(false);
        setEnableApply(true);
        setUserSelectedValue(false);
        closeFilterPanel();
        resyncWithDefaultTiles(reportletData.id);
        setFilterPanelValues(filterValues);
    };

    const handleMarketSearchChange = async (market) => {
        setFilterPanelValues({ ...filterPanelValues, primaryMarket: market });
        // setHomePageState({
        //     type: 'filter_update',
        //     filterKey: 'primaryMarket',
        //     filterUpdateValue: market,
        // });
        setUserSelectedValue(true);
    };

    const renderCharacteristicsDisplayOptions = () => {
        if (enableCharacteristics) {
            return (
                <div style={{ display: 'flex' }} >
                    <span onClick={() => handleEnableCharacteristicsDisplayChange(false)}>
                        <MinusOutlined  />
                    </span>
                    <span>Remove filter condition</span>
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex' }} >
                    <span onClick={() => handleEnableCharacteristicsDisplayChange(true)}>
                        <PlusOutlined  />
                    </span>
                    <span>Add filter condition</span>
                </div>
            );
        }
    };

    const handleApply = async() => {
        const filterValue = await processFilterValues();
        setEnableApply(true);
        handleFilterState();
        await handleFilterApply(filterValue);
    }

    const processFilterValues = async () => {
        const filterValue = {
            ...filterPanelValues,
        };
        if (!filterValue.defaultTimePeriod) {
            filterValue.defaultTimePeriod = 'Latest 52 Weeks';
        }
        if (!filterValue.primaryMarket) {
            filterValue.primaryMarket = 'Total FMCG Retailers';
        }
        return filterValue;
    };

    return (
        <>
            {enableFilterPanel && (
                <div className={`${className}`}>
                    <div className={`${filterBaseClassName}__content`}>
                        <div className={`${filterBaseClassName}__group`}>
                            <div className={`${filterBaseClassName}__brand`}>
                                <ByzzerBrandSearch
                                    name="focusBrand"
                                    value={filterPanelValues?.focusBrand ?? []}
                                    onChange={handleFilterChange}
                                    label="Brand"
                                />
                            </div>
                            <div className={`${filterBaseClassName}__category`}>
                                <ByzzerCategorySelect
                                    name="primaryCategory"
                                    value={filterPanelValues?.primaryCategory ?? []}
                                    categoriesToCheckForIntersect={categoriesOptions}
                                    categorySelectionAggregationLevel="category"
                                    shouldDisplayIntersectIndicators={
                                        filterPanelValues?.focusBrand?.length > 0 || false
                                    }
                                    restrictToSubscriptionCategories={true}
                                    onChange={handleFilterChange}
                                    placeholder="Select from the list"
                                    brands={filterPanelValues?.focusBrand ?? []}
                                    allowClear={true}
                                    label="Category"
                                    groupOptionsByBrandCoverage={filterPanelValues?.focusBrand?.length > 0 || false}
                                />
                            </div>
                        </div>

                        <div className={`${filterBaseClassName}__group`}>
                            <div className={`${filterBaseClassName}__market`}>
                                <ByzzerSearchableSelect
                                    name="primaryMarket"
                                    className={`${filterBaseClassName}__market_search`}
                                    disabled={false}
                                    value={[filterPanelValues?.primaryMarket ?? []]}
                                    placeholder="All"
                                    label="Market"
                                    options={filterPanelValues?.primaryMarket ? filterPanelValues.primaryMarket : []}
                                    searchPlaceholder="Search for Market"
                                    onChange={handleFilterChange}
                                    searchOnChange={handleMarketSearchChange}
                                    searchType="marketSingleSearch"
                                    categories={filterPanelValues?.primaryCategory ?? []}
                                    searchValue={
                                        filterPanelValues?.primaryMarket ? filterPanelValues?.primaryMarket : ''
                                    }
                                    optionKey={undefined}
                                    seperator={undefined}
                                    selectedMarkets={undefined}
                                    productType={undefined}
                                    multiBrandSelect={undefined}
                                    fromFilter={true}
                                />
                            </div>
                            <div className={`${filterBaseClassName}__timeperiod`}>
                                <ByzzerSelect
                                    value={filterPanelValues?.defaultTimePeriod ?? []}
                                    placeholder="Select Time Period"
                                    options={timePeriodOptions}
                                    onChange={handleFilterChange}
                                    name="defaultTimePeriod"
                                    label="Time Period"
                                />
                            </div>
                        </div>

                        <div className={`${filterBaseClassName}__char_display_options`}>
                            {renderCharacteristicsDisplayOptions()}
                        </div>

                        <div className={`${filterBaseClassName}__char`}>
                            {enableCharacteristics && (
                                <CharacteristicCriteriaBuilder
                                    name="characteristicFilters"
                                    onlyRenderIf={enableCharacteristics}
                                    value={filterPanelValues.characteristicFilters}
                                    categories={filterPanelValues.primaryCategory}
                                    joinText="And"
                                    maxConditions={5}
                                    onChange={handleFilterChange}
                                />
                            )}
                        </div>

                        <div className={`${filterBaseClassName}__group`}>
                            <div className={`${filterBaseClassName}__apply`}>
                                <ByzzerButton
                                    className={`${filterBaseClassName}__apply_button`}
                                    onClick={() => {
                                        handleApply();
                                    }}
                                    disabled={enableApply}
                                >
                                    Apply
                                </ByzzerButton>
                            </div>

                            <div className={`${filterBaseClassName}__resync`}>
                                <ByzzerButton
                                    className={`${filterBaseClassName}__resync_button`}
                                    type="negative"
                                    label="Sync with Defaults"
                                    onClick={handleResync}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showWarningMessagePopup && (
                <FilterMessageModal
                    showWarningMessagePopup={showWarningMessagePopup}
                    handleApply={() => {
                        handleApply();
                    }}
                    closeFilterPanel={() => {
                        onModalFilterPanelClose();
                        setFilterPanelValues(filterValues);
                    }}
                />
            )}
        </>
    );
});

export default HomePageFilter;
