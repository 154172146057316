import './LegacyByzzerButton.scss';
import React from 'react';
import classnames from 'classnames';
import { Popover } from 'antd';
import { trackEvent } from "@/components/SiteAnalytics";
import _ from "lodash";


type ByzzerButtonProps = { // TODO: replace instances of this with the ui-components version or at least define these types to something other than 'any' 
    label?: any;
    trackClick?: any;
    onClick?: any;
    onDisabledClick?: any;
    disabled?: any;
    className?: any;
    busy?: any;
    // todo: change default type to "default" b/c that makes a hell of a lot more sense
    type?: any;
    children?: any;
    objPopover?: any;
}

/**
 * @deprecated - This is deprecated and should not be used.  Use component from our UI library instead.  Add functionality to that if there's a gap, but do not install ant design
 */
export function LegacyByzzerButton({
    label,
    trackClick,
    onClick,
    onDisabledClick,
    disabled,
    className,
    busy = false,
    // todo: change default type to "default" b/c that makes a hell of a lot more sense
    type = 'primary',
    children = label,
    objPopover,
    ...props
}: ByzzerButtonProps) {

    const baseClassName = 'byzzer-button'
    const classNames = classnames(baseClassName, className, `${baseClassName}--${type}`, {
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--busy`]: busy
    });

    function handleClick() {
        if (disabled || busy) {
            return onDisabledClick?.();
        }
        onClick?.()
        if (trackClick) {
            trackEvent({
                type: 'click',
                name: _.isString(trackClick) ? trackClick : trackClick.name,
                data: trackClick.data
            })
        }
    }

    return (
        <>
            <div className={classNames} onClick={handleClick}>
                <svg
                    className={classnames(`${baseClassName}__spinner`)}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle pathLength={1} cx="50" cy="50" r="45" />
                </svg>
                <div data-test={props['data-test']} className={`${baseClassName}__content`}>
                    {children}
                </div>
            </div>

            {objPopover && (
                <Popover content={objPopover?.callbackMsg()} placement={objPopover?.position ?? 'top'} trigger="hover">

                </Popover>
            )}
        </>
    );
}

export default LegacyByzzerButton;
