import React, { useState } from 'react';
import { default as Checkbox } from '@/components/form/LegacyByzzerCheckbox';
import './MultiSelectMarketsByChannel.scss';
import classnames from 'classnames';
import { LegacyByzzerRadio } from './form';
import { MrktEntitlementType } from '@/utils';
import MinusOutlined from '@/components/icons/MinusOutlined';
import PlusOutlined from '@/components/icons/PlusOutlined';

export const FolderOpenOutlined = ({ className = "" }) => {
    return <span className={`${className}__folderOpenOutlined`} />;
};

export const FolderOutlined = ({ className = "" }) => {
    return <span className={`${className}__folderOutlined`} />;
};


const MultiSelectMarketsByChannel = ({
    checkLimit,
    filterComparisonMarket,
    from,
    marketChannel,
    marketConfigs,
    marketGroup,
    marketSelected,
    maxLimit,
    multiSelect,
    productType,
    saveBtnType,
    selectedItems,
    selectorState,
    setPreferenceState,
    setSelectorsData,
    setShowUpdateButton,
    showCompanies,
    showlvl2Markets,
    showlvl3Markets,
    showMarkets,
    showSpecialityRetailersCompanies,
    showSpecialityRetailerslvl2Markets,
    showSpecialityRetailersMarkets,
    showSpecialityRetailersSubfolderMarkets,
    showSpecialityRetailersSubFolders,
    showSubfolderMarkets,
    showSubFolders,
    userPrefState,
    marketType,
    option2c,
    selectedMarketItemLvlVal,
    setSelectedItems,
    showRemainingMarkets,
    toolTipMessage,
    showTooltip,
    checkIfMarketCanBeLoaded
}) => {
    const baseClassName = 'multi-select-markets-by-channel';
    const wrapperClasses = classnames(`${baseClassName}`);
    const marketChannelListClasses = classnames(`${baseClassName}__lists`);
    const [isHidePromoData, setHideNonPromoData] = useState(false); //TODO post Suresh code commit BYZ-7230

    const setSingleSelectMarketData = (marketName, marketKey, marketSelType) => {
        if (from === 'reportSelector') {
            setSelectorsData({ focusMarket: marketName });
        } else {
            setPreferenceState({ primaryMarket: marketName, marketSelType });
        }
        setSelectedItems([
            {
                name: marketName,
                marketKey,
            },
        ]);
        setShowUpdateButton();
    };

    //Added for Stories feature to check if remaining market is selectable
    const isRemainingMarketSelectable = (marketName, marketType, isMarketLevel1 = false) => {
        let isSelectable = true;
        if (marketConfigs?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                isMarketLevel1 &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isSelectable =
                    selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                        selectedMarketItemLvlVal?.marketName === marketName &&
                        selectedMarketItemLvlVal?.marketType === marketType
                        ? true
                        : false;
            } else if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isSelectable = selectedMarketItemLvlVal.marketLevel === 'level_2' ? true : false;
            }

            if (productType === 'story' && marketType === 'comparisonMarket') {
                //Added for Stories to check if market is included in focus market list
                isSelectable = !filterComparisonMarket(marketName);
            }
        }
        return isSelectable;
    };

    const renderRemainingMarkets = (
        category,
        item,
        index = null,
        index1 = null,
        index2 = null,
        index3 = null,
        index4 = null,
        index5 = null,
        index6 = null,
        mrktEntitlement = true
    ) => {
        const isMarketLevel1 =
            index2 !== null && index2 !== undefined && (index3 === null || index3 === undefined) ? true : false;

        if (item?.is_mrkt === 'Y' && item.master_company !== 'Nielsen' && showRemainingMarkets) {
            if (
                item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) ||
                item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE) ||
                item?.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED)
            ) {
                mrktEntitlement = false;
            }
            return (
                <div className={classnames(`${baseClassName}__remaining_markets_retailers`,
                    { [`${baseClassName}__disabledFlag`]: !item.is_releasable_mrkt })}>
                    <div className={classnames(`${baseClassName}__remaining_markets`)}>
                        {item.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                            <>
                                {isRemainingMarketSelectable(
                                    item.rm_channel_mrkt_disp_name,
                                    'channel',
                                    isMarketLevel1
                                ) &&
                                    !(
                                        !enableMultiMarketSelection(
                                            `${item.rm_channel_mrkt_disp_name}$$${item.rm_channel_mrkt_key}$$${item.mrkt_key}`
                                        ) || !item.rm_channel_mrkt_disp_name
                                    ) && (
                                        <Checkbox
                                            disabled={
                                                !enableMultiMarketSelection(
                                                    `${item.rm_channel_mrkt_disp_name}$$${item.rm_channel_mrkt_key}$$${item.mrkt_key}`
                                                ) ||
                                                !item.rm_channel_mrkt_disp_name ||
                                                (option2c && (index3 !== null || index4 !== null || index5 !== null || index6 !== null))
                                            }
                                            style={{
                                                display: 'flex',
                                                paddingRight: '3.4px',
                                                alignItems: 'center',
                                            }}
                                            key={`channel-${Date.now()}`}
                                            onChange={(e) => {
                                                const checked = e.target.checked;
                                                const valid = marketSelected(
                                                    item.selected,
                                                    checked,
                                                    `${category}$$channel`,
                                                    index,
                                                    index1,
                                                    index2,
                                                    index3,
                                                    index4,
                                                    index5,
                                                    index6
                                                );
                                                if (!valid) {
                                                    checkLimit(
                                                        {
                                                            ...item,
                                                            uniqueMarketName: `${item.rm_channel_mrkt_disp_name}$$${item.rm_channel_mrkt_key}$$${item.mrkt_key}`,
                                                            mrkt_key_selected: item.rm_channel_mrkt_key,
                                                            marketType: 'channel',
                                                        },
                                                        checked,
                                                        item.mrkt_sel_typ
                                                    );
                                                }
                                            }}
                                            checked={item?.selectedChannel}
                                        />
                                    )}
                            </>
                        )}
                        {item.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                            <>
                                {isRemainingMarketSelectable(
                                    item.rm_channel_mrkt_disp_name,
                                    'channel',
                                    isMarketLevel1
                                ) &&
                                    item.rm_channel_mrkt_disp_name && (
                                        <LegacyByzzerRadio
                                            disabled={!item.rm_channel_mrkt_disp_name}
                                            checked={
                                                from === 'reportSelector' && saveBtnType !== 'alertSelector' && productType !== 'story'
                                                    ? selectorState.focusMarket ===
                                                    `${item.rm_channel_mrkt_disp_name + '<-' + item.name}`
                                                    : saveBtnType === 'alertSelector' || productType === 'story'
                                                        ? selectorState.focusMarket?.split('<-')[0] ===
                                                        `${item.rm_channel_mrkt_disp_name}`
                                                        : userPrefState.primaryMarket ===
                                                        `${item.rm_channel_mrkt_disp_name + '<-' + item.name}`
                                            }
                                            onChange={() =>
                                                setSingleSelectMarketData(
                                                    `${item.rm_channel_mrkt_disp_name + '<-' + item.name}`,
                                                    item.rm_channel_mrkt_key,
                                                    item.mrkt_sel_typ
                                                )
                                            }
                                        />
                                    )}
                            </>
                        )}
                    </div>
                    <div className={classnames(`${baseClassName}__remaining_retailers`)}>
                        {item.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                            <>
                                {isRemainingMarketSelectable(item.rm_xaoc_mrkt_disp_name, 'retailer', isMarketLevel1) &&
                                    !(
                                        !enableMultiMarketSelection(
                                            `${item.rm_xaoc_mrkt_disp_name}$$${item.rm_xaoc_mrkt_key}$$${item.mrkt_key}`
                                        ) || !item.rm_xaoc_mrkt_disp_name
                                    ) && (
                                        <Checkbox
                                            disabled={
                                                !enableMultiMarketSelection(
                                                    `${item.rm_xaoc_mrkt_disp_name}$$${item.rm_xaoc_mrkt_key}$$${item.mrkt_key}`
                                                ) ||
                                                !item.rm_xaoc_mrkt_disp_name ||
                                                (option2c && (index3 !== null || index4 !== null || index5 !== null || index6 !== null))
                                            }
                                            style={{
                                                display: 'flex',
                                                paddingRight: '3.4px',
                                                alignItems: 'center',
                                            }}
                                            onChange={async (e) => {
                                                const checked = e.target.checked;
                                                const valid = await marketSelected(
                                                    item.selected,
                                                    checked,
                                                    `${category}$$retailer`,
                                                    index,
                                                    index1,
                                                    index2,
                                                    index3,
                                                    index4,
                                                    index5,
                                                    index6
                                                );
                                                if (!valid) {
                                                    checkLimit(
                                                        {
                                                            ...item,
                                                            uniqueMarketName: `${item.rm_xaoc_mrkt_disp_name}$$${item.rm_xaoc_mrkt_key}$$${item.mrkt_key}`,
                                                            mrkt_key_selected: item.rm_xaoc_mrkt_key,
                                                            marketType: 'retailer',
                                                        },
                                                        checked,
                                                        item.mrkt_sel_typ
                                                    );
                                                }
                                            }}
                                            checked={item?.selectedRetailer}
                                            key={`retailer-${Date.now()}`}
                                        />
                                    )}
                            </>
                        )}
                        {item.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                            <>
                                {isRemainingMarketSelectable(item.rm_xaoc_mrkt_disp_name, 'retailer', isMarketLevel1) &&
                                    item.rm_xaoc_mrkt_disp_name && (
                                        <LegacyByzzerRadio
                                            disabled={!item.rm_xaoc_mrkt_disp_name || option2c}
                                            checked={
                                                from === 'reportSelector' && saveBtnType !== 'alertSelector' && productType !== 'story'
                                                    ? selectorState.focusMarket ===
                                                    `${item.rm_xaoc_mrkt_disp_name + '<-' + item.name}`
                                                    : saveBtnType === 'alertSelector' || productType === 'story'
                                                        ? selectorState.focusMarket?.split('<-')[0] ===
                                                        `${item.rm_xaoc_mrkt_disp_name}`
                                                        : userPrefState.primaryMarket ===
                                                        `${item.rm_xaoc_mrkt_disp_name + '<-' + item.name}`
                                            }
                                            onChange={() =>
                                                setSingleSelectMarketData(
                                                    `${item.rm_xaoc_mrkt_disp_name + '<-' + item.name}`,
                                                    item.rm_xaoc_mrkt_key,
                                                    item.mrkt_sel_typ
                                                )
                                            }
                                        />
                                    )}
                            </>
                        )}
                    </div>
                    {((productType !== 'story' && saveBtnType !== 'alertSelector') ||
                        ((productType === 'story' || saveBtnType === 'alertSelector') &&
                            marketConfigs?.comparativeMarketsRequired)) &&
                        marketConfigs?.requiredRemMarketForEachRetailer && (
                            <div className={classnames(`${baseClassName}__remaining_comparitive`)}>
                                {item.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                                    <>
                                        {isRemainingMarketSelectable(
                                            item.comparative_mkt_disp_name,
                                            'comparitive',
                                            isMarketLevel1
                                        ) &&
                                            !(
                                                !enableMultiMarketSelection(
                                                    `${item.comparative_mkt_disp_name}$$${item.comparative_mrkt_key}$$${item.mrkt_key}`
                                                ) || !item.comparative_mkt_disp_name
                                            ) && (
                                                <Checkbox
                                                    disabled={
                                                        !enableMultiMarketSelection(
                                                            `${item.comparative_mkt_disp_name}$$${item.comparative_mrkt_key}$$${item.mrkt_key}`
                                                        ) ||
                                                        !item.comparative_mkt_disp_name ||
                                                        (option2c &&
                                                            (index3 !== null || index4 !== null || index5 !== null || index6 !== null))
                                                    }
                                                    style={{
                                                        display: 'flex',
                                                        paddingRight: '3.4px',
                                                        alignItems: 'center',
                                                    }}
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;
                                                        const valid = marketSelected(
                                                            item.selected,
                                                            checked,
                                                            `${category}$$comparitive`,
                                                            index,
                                                            index1,
                                                            index2,
                                                            index3,
                                                            index4,
                                                            index5,
                                                            index6
                                                        );
                                                        if (!valid) {
                                                            checkLimit(
                                                                {
                                                                    ...item,
                                                                    uniqueMarketName: `${item.comparative_mkt_disp_name}$$${item.comparative_mrkt_key}$$${item.mrkt_key}`,
                                                                    mrkt_key_selected: item.comparative_mrkt_key,
                                                                    marketType: 'comparative',
                                                                },
                                                                checked,
                                                                item.mrkt_sel_typ
                                                            );
                                                        }
                                                    }}
                                                    checked={item?.selectedComparitive}
                                                    key={`comparitive-${Date.now()}`}
                                                />
                                            )}
                                    </>
                                )}
                                {item.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                                    <>
                                        {isRemainingMarketSelectable(
                                            item.comparative_mkt_disp_name,
                                            'comparitive',
                                            isMarketLevel1
                                        ) &&
                                            item.comparative_mkt_disp_name && (
                                                <LegacyByzzerRadio
                                                    disabled={!item.comparative_mkt_disp_name || option2c}
                                                    checked={
                                                        from === 'reportSelector' && saveBtnType !== 'alertSelector' && productType !== 'story'
                                                            ? selectorState.focusMarket ===
                                                            `${item.comparative_mkt_disp_name + '<-' + item.name}`
                                                            : saveBtnType === 'alertSelector' || productType === 'story'
                                                                ? selectorState.focusMarket?.split('<-')[0] ===
                                                                `${item.comparative_mkt_disp_name}`
                                                                : userPrefState.primaryMarket ===
                                                                `${item.comparative_mkt_disp_name + '<-' + item.name}`
                                                    }
                                                    onChange={() =>
                                                        setSingleSelectMarketData(
                                                            `${item.comparative_mkt_disp_name + '<-' + item.name}`,
                                                            item.comparative_mrkt_key,
                                                            item.mrkt_sel_typ
                                                        )
                                                    }
                                                />
                                            )}
                                    </>
                                )}
                            </div>
                        )}
                </div>
            );
        }
    };
    const renderRemainingMarketHeaders = (item) => {
        if (
            item.expanded &&
            showRemainingMarkets &&
            item?.companies?.length > 0 &&
            item?.companies[0]?.master_company !== 'Nielsen'
        ) {
            return (
                <div className={classnames(`${baseClassName}__remaining_markets_retailers`)}>
                    <div className={classnames(`${baseClassName}__remaining_markets`)}>
                        Remaining {item.name} Market
                    </div>
                    <div className={classnames(`${baseClassName}__remaining_retailers`)}>Remaining FMCG Market</div>
                    {((productType !== 'story' && saveBtnType !== 'alertSelector') ||
                        ((productType === 'story' || saveBtnType === 'alertSelector') &&
                            marketConfigs?.comparativeMarketsRequired)) &&
                        marketConfigs?.requiredRemMarketForEachRetailer && (
                            <div className={classnames(`${baseClassName}__remaining_comparitive`)}>
                                Comparative Market
                            </div>
                        )}
                </div>
            );
        }
    };
    const enableMultiMarketSelection = (mrktName) => {
        let enableFlag = true;
        if (marketGroup === 'cps') {
            const checkChannelMrktSel = selectedItems.filter(
                (selItem) => selItem.marketSelType === 'Panel Total US Markets'
            );
            if (checkChannelMrktSel && checkChannelMrktSel.length > 0) {
                enableFlag = false;
            }
        }
        if (selectedItems?.length >= maxLimit && !((selectedItems.map((item) => item.name).includes(mrktName)) || selectedItems.map((item) => item.name).includes(mrktName.split("$$")[0]))) {
            enableFlag = false;
        }
        return enableFlag;
    };

    const showToolTipCondition = (entitlement, item) => {
        return showTooltip(item);
    };
    const marketItem1 = (item1, index, index1, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item1.name) : false;

        return (
            <div className={`${classNameForRemainingChannels(item1)} 123`}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {item1.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showMarkets(index, index1)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#EC4571',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item1.expanded ? (
                                <MinusOutlined />
                            ) : (
                                <PlusOutlined />
                            )}
                        </span>
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item1.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item1.name)}
                                    style={{ display: 'flex', paddingRight: '3.4px' }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'FMCGRetailers-companies',
                                            index,
                                            index1
                                        );
                                        checkLimit(item1, e.target.checked, item1.mrkt_sel_typ);
                                    }}
                                    checked={item1.selected}
                                />
                            )}
                        </>
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                        <LegacyByzzerRadio
                            label={item1.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item1.name
                                    : userPrefState.primaryMarket === item1.name
                            }
                            onChange={() => setSingleSelectMarketData(item1.name, item1.mrkt_key, item1.mrkt_sel_typ)}
                        />
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                        ''
                    ) : (
                        <span
                            style={{
                                display: 'flex',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item1.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item1)}
                    </div>
                </div>
                {renderRemainingMarkets('FMCGRetailers-companies', item1, index, index1)}
            </div>
        );
    };

    const classNameForRemainingChannels = (item) => {
        if (item?.is_mrkt === 'Y') {
            return `${baseClassName}__remaining`;
        }
        if (item?.is_releasable_mrkt === false) {
            return `${baseClassName}__disabledFlag`;
        }
        return `${baseClassName}__remaining-not-market`;
    };
    const marketItem2 = (item2, index, index1, index2, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item2.name) : false;
        //Added for Stories feature to check if focus market is selectable
        let isLvl3MarketSelectable = true;
        if (marketConfigs?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isLvl3MarketSelectable =
                    selectedMarketItemLvlVal.marketLevel === 'level_2'
                        ? false
                        : selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                            selectedMarketItemLvlVal?.marketName !== item2.name
                            ? false
                            : true;
            }
        }

        return (
            <div className={classNameForRemainingChannels(item2)}>
                <div key={index2} className={classnames(`${baseClassName}__lists-fmcg-market-folder-companies-sub`)}>
                    <div className={classnames(`${baseClassName}__lists-fmcg-market-folder-companies-section`, {
                        [`${baseClassName}__disabledFlag`]: !item2.is_releasable_mrkt
                    }
                    )}>
                        {item2.has_children === 'Y' && mrktEntitlement && (
                            <span
                                className={classnames(`${baseClassName}__lists-fmcg-market-folder-companies-group`)}
                                onClick={() => showSubFolders(index, index1, index2)}
                            >
                                {item2.is_mrkt === 'Y' && mrktEntitlement ? (
                                    item2.expanded ? (
                                        <MinusOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )
                                ) : item2.expanded ? (
                                    <FolderOpenOutlined className={baseClassName} />
                                ) : (
                                    <FolderOutlined className={baseClassName} />
                                )}
                            </span>
                        )}
                        {item2.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                            <>
                                {enableMultiMarketSelection(item2.name) && isLvl3MarketSelectable && !isMarketIncluded && (
                                    <Checkbox
                                        disabled={!enableMultiMarketSelection(item2.name)}
                                        style={{
                                            display: 'flex',
                                            paddingRight: '3.4px',
                                        }}
                                        onChange={(e) => {
                                            marketSelected(
                                                false,
                                                e.target.checked,
                                                'FMCGRetailers-subfolders',
                                                index,
                                                index1,
                                                index2
                                            );
                                            checkLimit(item2, e.target.checked, item2.mrkt_sel_typ);
                                        }}
                                        checked={item2.selected}
                                        key={`FMCGRetailers-subfolders-${Date.now()}`}
                                    />
                                )}
                            </>
                        )}
                        {item2.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                            <LegacyByzzerRadio
                                label={item2.name}
                                checked={
                                    from === 'reportSelector'
                                        ? selectorState.focusMarket === item2.name
                                        : userPrefState.primaryMarket === item2.name
                                }
                                onChange={() =>
                                    setSingleSelectMarketData(item2.name, item2.mrkt_key, item2.mrkt_sel_typ)
                                }
                            />
                        )}
                        {item2.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                            ''
                        ) : (
                            <span
                                style={{
                                    display: 'flex',
                                    padding: '3.4px',
                                    paddingLeft: '5px',
                                }}
                            >
                                {item2.name}
                            </span>
                        )}
                        <div className='hover-icons-wrapper'>
                            {showToolTipCondition(mrktEntitlement, item2)}
                        </div>
                    </div>
                    {item2.expanded &&
                        item2.subFolders !== undefined &&
                        item2.subFolders.length > 0 &&
                        item2.subFolders.map((item3, index3) => {
                            return (
                                <div
                                    key={index3}
                                    className={classnames(
                                        `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder`
                                    )}
                                >
                                    {showTooltipFMCGRetailers(item3, index, index1, index2, index3)}
                                    {item3.expanded &&
                                        item3.markets !== undefined &&
                                        item3.markets.length > 0 &&
                                        item3.markets.map((item4, index4) => {
                                            return (
                                                <div
                                                    key={index4}
                                                    className={classnames(
                                                        `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder-markets`
                                                    )}
                                                >
                                                    {showTooltipFMCGRetailers(
                                                        item4,
                                                        index,
                                                        index1,
                                                        index2,
                                                        index3,
                                                        index4
                                                    )}
                                                    {item4.expanded &&
                                                        item4.lvl2markets !== undefined &&
                                                        item4.lvl2markets.length > 0 &&
                                                        item4.lvl2markets.map((item5, index5) => {
                                                            return (
                                                                <div
                                                                    key={index5}
                                                                    className={classnames(
                                                                        `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder-markets-lvl2market`
                                                                    )}
                                                                >
                                                                    {showTooltipFMCGRetailers(
                                                                        item5,
                                                                        index,
                                                                        index1,
                                                                        index2,
                                                                        index3,
                                                                        index4,
                                                                        index5
                                                                    )}
                                                                    {item5.expanded &&
                                                                        item5.lvl3markets !== undefined &&
                                                                        item5.lvl3markets.length > 0 &&
                                                                        item5.lvl3markets.map((item6, index6) => {
                                                                            return (
                                                                                <div
                                                                                    key={index6}
                                                                                    className={classnames(
                                                                                        `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder-markets-lvl2market`
                                                                                    )}
                                                                                >
                                                                                    {showTooltipFMCGRetailers(
                                                                                        item6,
                                                                                        index,
                                                                                        index1,
                                                                                        index2,
                                                                                        index3,
                                                                                        index4,
                                                                                        index5,
                                                                                        index6
                                                                                    )}

                                                                                </div>
                                                                            );
                                                                        })}
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            );
                                        })}
                                </div>
                            );
                        })}
                </div>

                {renderRemainingMarkets('FMCGRetailers-subfolders', item2, index, index1, index2)}
            </div>
        );
    };

    const marketItem3 = (item3, index, index1, index2, index3, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item3.name) : false;
        //Added for Stories feature to check if focus market is selectable
        let isLvl4MarketSelectable = true;
        if (marketConfigs?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isLvl4MarketSelectable = selectedMarketItemLvlVal.marketLevel === 'level_1' ? false : true;
            }
        }

        return (
            <div className={classNameForRemainingChannels(item3)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '380px',
                    }}
                >
                    {(productType !== 'story' || (productType === 'story' && (marketConfigs?.allowedMarketLevels === undefined || marketConfigs?.allowedMarketLevels > 3))) &&
                        item3.has_children === 'Y' &&
                        mrktEntitlement && (
                            <span
                                onClick={() => showSubfolderMarkets(index, index1, index2, index3)}
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    color: '#000000',
                                    padding: '3.4px',
                                    paddingLeft: '6px',
                                }}
                            >
                                {item3.is_mrkt === 'Y' && mrktEntitlement ? (
                                    item3.expanded ? (
                                        <MinusOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )
                                ) : item3.expanded ? (
                                    <FolderOpenOutlined className={baseClassName} />
                                ) : (
                                    <FolderOutlined className={baseClassName} />
                                )}
                            </span>
                        )}
                    {(productType === 'story' || item3.has_children === 'N') && (
                        <span
                            style={{
                                paddingLeft: '25px',
                            }}
                        />
                    )}
                    {item3.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item3.name) && isLvl4MarketSelectable && !isMarketIncluded && (
                                <Checkbox
                                    disabled={
                                        (!enableMultiMarketSelection(item3.name) && isLvl4MarketSelectable) || option2c
                                    }
                                    style={{
                                        display: 'flex',
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'FMCGRetailers-subfolder-markets',
                                            index,
                                            index1,
                                            index2,
                                            index3
                                        );
                                        checkLimit(item3, e.target.checked, item3.mrkt_sel_typ);
                                    }}
                                    checked={item3.selected}
                                />
                            )}
                        </>
                    )}
                    {item3.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                        <LegacyByzzerRadio
                            label={item3.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item3.name
                                    : userPrefState.primaryMarket === item3.name
                            }
                            onChange={() => setSingleSelectMarketData(item3.name, item3.mrkt_key, item3.mrkt_sel_typ)}
                        />
                    )}
                    {item3.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                        ''
                    ) : (
                        <span
                            style={{
                                display: 'flex',
                                padding: '3.4px',
                                paddingLeft: mrktEntitlement ? '5px' : '28px',
                            }}
                        >
                            {item3.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item3)}
                    </div>
                </div>
                {renderRemainingMarkets('FMCGRetailers-subfolder-markets', item3, index, index1, index2, index3)}
            </div>
        );
    };

    const marketItem4 = (item4, index, index1, index2, index3, index4, mrktEntitlement = true) => {
        return (
            <div className={classNameForRemainingChannels(item4)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '332px',
                    }}
                >
                    {item4.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showlvl2Markets(index, index1, index2, index3, index4)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#000000',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item4.is_mrkt === 'Y' ? (
                                item4.expanded ? (
                                    <MinusOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            ) : item4.expanded ? (
                                <FolderOpenOutlined className={baseClassName} />
                            ) : (
                                <FolderOutlined className={baseClassName} />
                            )}
                        </span>
                    )}

                    {item4.has_children !== 'Y' && (
                        <span
                            style={{
                                display: 'flex',
                                paddingLeft: '25px',
                            }}
                        ></span>
                    )}

                    {item4.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item4.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item4.name) || option2c}
                                    style={{
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'FMCGRetailers-lvl2markets',
                                            index,
                                            index1,
                                            index2,
                                            index3,
                                            index4
                                        );
                                        checkLimit(item4, e.target.checked, item4.mrkt_sel_typ);
                                    }}
                                    checked={item4.selected}
                                />
                            )}
                        </>
                    )}
                    {item4.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                        <LegacyByzzerRadio
                            label={item4.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item4.name
                                    : userPrefState.primaryMarket === item4.name
                            }
                            onChange={() => setSingleSelectMarketData(item4.name, item4.mrkt_key, item4.mrkt_sel_typ)}
                        />
                    )}
                    {item4.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement ? (
                        ''
                    ) : (
                        <span
                            style={{
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item4.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item4)}
                    </div>
                </div>
                {renderRemainingMarkets('FMCGRetailers-lvl2markets', item4, index, index1, index2, index3, index4)}
            </div>
        );
    };

    const marketItem5 = (item5, index, index1, index2, index3, index4, index5, mrktEntitlement = true) => {
        return (
            <div className={classNameForRemainingChannels(item5)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '292px',
                    }}
                >
                    {item5.has_children === 'N' && (
                        <span
                        />
                    )}
                    {item5.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showlvl3Markets(index, index1, index2, index3, index4, index5)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#000000',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item5.is_mrkt === 'Y' ? (
                                item5.expanded ? (
                                    <MinusOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            ) : item5.expanded ? (
                                <FolderOpenOutlined className={baseClassName} />
                            ) : (
                                <FolderOutlined className={baseClassName} />
                            )}
                        </span>
                    )}

                    {item5.has_children !== 'Y' && (
                        <span
                            style={{
                                display: 'flex',
                                paddingLeft: '25px',
                            }}
                        ></span>
                    )}
                    {item5.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item5.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item5.name) || option2c}
                                    style={{
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'FMCGRetailers-lvl3markets',
                                            index,
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5
                                        );
                                        checkLimit(item5, e.target.checked, item5.mrkt_sel_typ);
                                    }}
                                    checked={item5.selected}
                                />
                            )}
                        </>
                    )}
                    {item5.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                        <LegacyByzzerRadio
                            label={item5.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item5.name
                                    : userPrefState.primaryMarket === item5.name
                            }
                            onChange={() => setSingleSelectMarketData(item5.name, item5.mrkt_key, item5.mrkt_sel_typ)}
                        />
                    )}
                    {item5.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement ? (
                        ''
                    ) : (
                        <span
                            style={{
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item5.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item5)}
                    </div>
                </div>
                {renderRemainingMarkets(
                    'FMCGRetailers-lvl3markets',
                    item5,
                    index,
                    index1,
                    index2,
                    index3,
                    index4,
                    index5
                )}
            </div>
        );
    };

    const marketItem6 = (item6, index, index1, index2, index3, index4, index5, index6, mrktEntitlement = true) => {
        return (
            <div className={classNameForRemainingChannels(item6)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '292px',
                    }}
                >
                    {item6.has_children === 'N' && (
                        <span
                            style={{
                                paddingLeft: '1.5vw',
                            }}
                        />
                    )}
                    {/* {item6.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showlvl3Markets(index, index1, index2, index3, index4, index5, index6)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#000000',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item6.is_mrkt === 'Y' ? (
                                item6.expanded ? (
                                    <MinusOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            ) : item6.expanded ? (
                                <FolderOpenOutlined className={baseClassName} />
                            ) : (
                                <FolderOutlined className={baseClassName} />
                            )}
                        </span>
                    )} */}

                    {/* {item6.has_children !== 'Y' && (
                        <span
                            style={{
                                display: 'flex',
                                paddingLeft: '25px',
                            }}
                        ></span>
                    )} */}
                    {item6.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item6.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item6.name) || option2c}
                                    style={{
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'FMCGRetailers-lvl4markets',
                                            index,
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5,
                                            index6
                                        );
                                        checkLimit(item6, e.target.checked, item6.mrkt_sel_typ);
                                    }}
                                    checked={item6.selected}
                                />
                            )}
                        </>
                    )}
                    {item6.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                        <LegacyByzzerRadio
                            label={item6.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item6.name
                                    : userPrefState.primaryMarket === item6.name
                            }
                            onChange={() => setSingleSelectMarketData(item6.name, item6.mrkt_key, item6.mrkt_sel_typ)}
                        />
                    )}
                    {item6.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement ? (
                        ''
                    ) : (
                        <span
                            style={{
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item6.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item6)}
                    </div>
                </div>

                {renderRemainingMarkets(
                    'FMCGRetailers-lvl4markets',
                    item6,
                    index,
                    index1,
                    index2,
                    index3,
                    index4,
                    index5,
                    index6
                )}
            </div>
        );
    };

    function showTooltipFMCGRetailers(item, index, index1, index2, index3, index4, index5, index6) {
        if (!checkIfMarketCanBeLoaded(item)) {
            return <></>;
        }
        const toolTipInnerVerbiage = (entitled) => {
            const argLen = arguments.length;
            // console.log(argLen, item, entitled);
            if (argLen === 3) return marketItem1(item, index, index1, entitled);
            if (argLen === 4) return marketItem2(item, index, index1, index2, entitled);
            if (argLen === 5) return marketItem3(item, index, index1, index2, index3, entitled);
            if (argLen === 6) return marketItem4(item, index, index1, index2, index3, index4, entitled);
            if (argLen === 7) return marketItem5(item, index, index1, index2, index3, index4, index5, entitled);
            if (argLen === 8) return marketItem6(item, index, index1, index2, index3, index4, index5, index6, entitled);
        };

        if (item.master_company === 'Amazon' || item.master_company == 'SuperValu' && item?.mrkt_type === 'Company') {
            return <>{toolTipInnerVerbiage(true)}</>;
        } else if (item?.mrkt_entitlements === undefined || item?.is_releasable_mrkt) {
            return toolTipInnerVerbiage(true);
        } else if (
            item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) ||
            item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE) ||
            item?.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED)
        ) {
            return <>{toolTipInnerVerbiage(false)}</>;
        }
    }

    const specialityMarketItem1 = (item1, index, index1, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item1.name) : false;

        return (
            <div className={classNameForRemainingChannels(item1)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {item1.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showSpecialityRetailersMarkets(index, index1)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#000000',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item1.expanded ? (
                                <MinusOutlined />
                            ) : (
                                <PlusOutlined />
                            )}
                        </span>
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item1.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item1.name)}
                                    style={{ display: 'flex', paddingRight: '3.4px' }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'SpecialityRetailers-companies',
                                            index,
                                            index1
                                        );
                                        checkLimit(item1, e.target.checked, item1.mrkt_sel_typ);
                                    }}
                                    checked={item1.selected}
                                />
                            )}
                        </>
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                        <LegacyByzzerRadio
                            label={item1.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item1.name
                                    : userPrefState.primaryMarket === item1.name
                            }
                            onChange={() => setSingleSelectMarketData(item1.name, item1.mrkt_key, item1.mrkt_sel_typ)}
                        />
                    )}
                    {item1.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                        ''
                    ) : (
                        <span
                            style={{
                                display: 'flex',
                                padding: '3.4px',
                                paddingLeft: mrktEntitlement ? '0px' : '10px',
                            }}
                        >
                            {item1.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item1)}
                    </div>
                </div>
            </div>
        );
    };

    const specialityMarketItem2 = (item2, index, index1, index2, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item2.name) : false;

        //Added for Stories feature to check if focus market is selectable
        let isLvl3MarketSelectable = true;
        if (marketConfigs?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isLvl3MarketSelectable =
                    selectedMarketItemLvlVal.marketLevel === 'level_2'
                        ? false
                        : selectedMarketItemLvlVal.marketLevel === 'level_1' &&
                            selectedMarketItemLvlVal?.marketName !== item2.name
                            ? false
                            : true;
            }
        }

        return (
            <div className={classNameForRemainingChannels(item2)}>
                <div className={classnames(`${baseClassName}__lists-fmcg-market-folder-companies-section`, { [`${baseClassName}__disabledFlag`]: !item2.is_releasable_mrkt })}>
                    {item2.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showSpecialityRetailersSubFolders(index, index1, index2)}
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                                color: '#000000',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item2.is_mrkt === 'Y' ? (
                                item2.expanded ? (
                                    <MinusOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            ) : item2.expanded ? (
                                <FolderOpenOutlined className={baseClassName} />
                            ) : (
                                <FolderOutlined className={baseClassName} />
                            )}
                        </span>
                    )}
                    {item2.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item2.name) && isLvl3MarketSelectable && !isMarketIncluded && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item2.name)}
                                    style={{
                                        display: 'flex',
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'SpecialityRetailers-subfolders',
                                            index,
                                            index1,
                                            index2
                                        );
                                        checkLimit(item2, e.target.checked, item2.mrkt_sel_typ);
                                    }}
                                    checked={item2.selected}
                                    key={`SpecialityRetailers-subfolders-${Date.now()}`}
                                />
                            )}
                        </>
                    )}
                    {item2.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                        <LegacyByzzerRadio
                            label={item2.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item2.name
                                    : userPrefState.primaryMarket === item2.name
                            }
                            onChange={() => setSingleSelectMarketData(item2.name, item2.mrkt_key, item2.mrkt_sel_typ)}
                        />
                    )}
                    {item2.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                        ''
                    ) : (
                        <span
                            style={{
                                display: 'flex',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item2.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item2)}
                    </div>
                </div>
                {renderRemainingMarkets('SpecialityRetailers-subfolders', item2, index, index1, index2)}
            </div>
        );
    };

    const specialityMarketItem3 = (item3, index, index1, index2, index3, mrktEntitlement = true) => {
        //Added for Stories to check if market is included in focus market list
        const isMarketIncluded =
            productType === 'story' && marketType === 'comparisonMarket' ? filterComparisonMarket(item3.name) : false;

        //Added for Stories feature to check if focus market is selectable
        let isLvl4MarketSelectable = true;
        if (marketConfigs?.marketBehavior?.hide_Lv1_Markets_if_lv2_selected) {
            if (
                productType === 'story' &&
                marketType === 'focusMarket' &&
                selectedMarketItemLvlVal.marketLevel !== undefined
            ) {
                isLvl4MarketSelectable = selectedMarketItemLvlVal.marketLevel === 'level_1' ? false : true;
            }
        }

        return (
            <div className={classNameForRemainingChannels(item3)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '380px',
                    }}
                >
                    {(productType !== 'story' || (productType === 'story' && (marketConfigs?.allowedMarketLevels === undefined || marketConfigs?.allowedMarketLevels > 3))) &&
                        item3.has_children === 'Y' &&
                        mrktEntitlement && (
                            <span
                                onClick={() => showSpecialityRetailersSubfolderMarkets(index, index1, index2, index3)}
                                style={{
                                    display: 'flex',
                                    cursor: 'pointer',
                                    color: '#000000',
                                    padding: '3.4px',
                                    paddingLeft: '5px',
                                }}
                            >
                                {item3.is_mrkt === 'Y' && mrktEntitlement ? (
                                    item3.expanded ? (
                                        <MinusOutlined />
                                    ) : (
                                        <PlusOutlined />
                                    )
                                ) : item3.expanded ? (
                                    <FolderOpenOutlined className={baseClassName} />
                                ) : (
                                    <FolderOutlined className={baseClassName} />
                                )}
                            </span>
                        )}
                    {item3.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item3.name) && isLvl4MarketSelectable && !isMarketIncluded && (
                                <Checkbox
                                    disabled={
                                        (!enableMultiMarketSelection(item3.name) && isLvl4MarketSelectable) || option2c
                                    }
                                    style={{
                                        display: 'flex',
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'SpecialityRetailers-subfolder-markets',
                                            index,
                                            index1,
                                            index2,
                                            index3
                                        );
                                        checkLimit(item3, e.target.checked, item3.mrkt_sel_typ);
                                    }}
                                    checked={item3.selected}
                                />
                            )}
                        </>
                    )}
                    {item3.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded && (
                        <LegacyByzzerRadio
                            label={item3.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item3.name
                                    : userPrefState.primaryMarket === item3.name
                            }
                            onChange={() => setSingleSelectMarketData(item3.name, item3.mrkt_key, item3.mrkt_sel_typ)}
                        />
                    )}
                    {item3.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && !isMarketIncluded ? (
                        ''
                    ) : (
                        <span
                            style={{
                                display: 'flex',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item3.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item3)}
                    </div>
                </div>
                {renderRemainingMarkets('SpecialityRetailers-subfolder-markets', item3, index, index1, index2, index3)}
            </div>
        );
    };

    const specialityMarketItem4 = (item4, index, index1, index2, index3, index4, mrktEntitlement = true) => {
        return (
            <div className={classNameForRemainingChannels(item4)}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '332px',
                    }}
                >
                    {item4.has_children === 'Y' && mrktEntitlement && (
                        <span
                            onClick={() => showSpecialityRetailerslvl2Markets(index, index1, index2, index3, index4)}
                            style={{
                                cursor: 'pointer',
                                color: '#EC4571',
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item4.is_mrkt === 'Y' && mrktEntitlement ? (
                                item4.expanded ? (
                                    <MinusOutlined />
                                ) : (
                                    <PlusOutlined />
                                )
                            ) : item4.expanded ? (
                                <FolderOpenOutlined className={baseClassName} />
                            ) : (
                                <FolderOutlined className={baseClassName} />
                            )}
                        </span>
                    )}
                    {item4.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item4.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item4.name) || option2c}
                                    style={{
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'SpecialityRetailers-lvl2markets',
                                            index,
                                            index1,
                                            index2,
                                            index3,
                                            index4
                                        );
                                        checkLimit(item4, e.target.checked, item4.mrkt_sel_typ);
                                    }}
                                    checked={item4.selected}
                                />
                            )}
                        </>
                    )}
                    {item4.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                        <LegacyByzzerRadio
                            label={item4.name}
                            checked={
                                from === 'reportSelector'
                                    ? selectorState.focusMarket === item4.name
                                    : userPrefState.primaryMarket === item4.name
                            }
                            onChange={() => setSingleSelectMarketData(item4.name, item4.mrkt_key, item4.mrkt_sel_typ)}
                        />
                    )}
                    {item4.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement ? (
                        ''
                    ) : (
                        <span
                            style={{
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item4.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item4)}
                    </div>
                </div>
                {renderRemainingMarkets(
                    'SpecialityRetailers-lvl2markets',
                    item4,
                    index,
                    index1,
                    index2,
                    index3,
                    index4
                )}
            </div>
        );
    };

    const specialityMarketItem5 = (item5, index, index1, index2, index3, index4, index5, mrktEntitlement = true) => {
        return (
            <div className={classNameForRemainingChannels(item5)}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {item5.is_mrkt === 'Y' && multiSelect === true && mrktEntitlement && (
                        <>
                            {enableMultiMarketSelection(item5.name) && (
                                <Checkbox
                                    disabled={!enableMultiMarketSelection(item5.name) || option2c}
                                    style={{
                                        paddingRight: '3.4px',
                                    }}
                                    onChange={(e) => {
                                        marketSelected(
                                            false,
                                            e.target.checked,
                                            'SpecialityRetailers-lvl3markets',
                                            index,
                                            index1,
                                            index2,
                                            index3,
                                            index4,
                                            index5
                                        );
                                        checkLimit(item5, e.target.checked, item5.mrkt_sel_typ);
                                    }}
                                    checked={item5.selected}
                                />
                            )}
                        </>
                    )}
                    {item5.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement && (
                        <LegacyByzzerRadio
                            label={item5.name}
                            checked={from === 'reportSelector' ? selectorState.focusMarket === item5.name : 0}
                            onChange={() => setSingleSelectMarketData(item5.name, item5.mrkt_key, item5.mrkt_sel_typ)}
                        />
                    )}
                    {item5.is_mrkt === 'Y' && multiSelect === false && mrktEntitlement ? (
                        ''
                    ) : (
                        <span
                            style={{
                                padding: '3.4px',
                                paddingLeft: '5px',
                            }}
                        >
                            {item5.name}
                        </span>
                    )}
                    <div className='hover-icons-wrapper'>
                        {showToolTipCondition(mrktEntitlement, item5)}
                    </div>
                </div>
                {renderRemainingMarkets(
                    'SpecialityRetailers-lvl3markets',
                    item5,
                    index,
                    index1,
                    index2,
                    index3,
                    index4,
                    index5
                )}
            </div>
        );
    };

    function showTooltipSpecialityRetailers(item, index, index1, index2, index3, index4, index5) {
        const toolTipInnerVerbiage = (entitled) => {
            const argLen = arguments.length;
            if (argLen === 3) return specialityMarketItem1(item, index, index1, entitled);
            if (argLen === 4) return specialityMarketItem2(item, index, index1, index2, entitled);
            if (argLen === 5) return specialityMarketItem3(item, index, index1, index2, index3, entitled);
            if (argLen === 6) return specialityMarketItem4(item, index, index1, index2, index3, index4, entitled);
            if (argLen === 7) return specialityMarketItem5(item, index, index1, index2, index3, index4, index5, entitled);
        };

        if (item.master_company === '7-Eleven Defined Geography' && item?.mrkt_type === 'Company') {
            return <>{toolTipInnerVerbiage(true)}</>;
        } else if (item?.mrkt_entitlements === undefined || item?.is_releasable_mrkt) {
            return toolTipInnerVerbiage(true);
        } else if (
            item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_PRIOR_APPROVAL) ||
            item?.mrkt_entitlements?.includes(MrktEntitlementType.NOT_REV_SHARE) ||
            item?.mrkt_entitlements?.includes(MrktEntitlementType.CATEGORY_NOT_COVERED)
        ) {
            return <>{toolTipInnerVerbiage(false)}</>;
        }
    }

    return (marketChannel?.FMCGRetailers && marketChannel?.FMCGRetailers.length > 0) ||
        (marketChannel?.specialtyRetailers && marketChannel?.specialtyRetailers.length > 0) ? (
        <div className={wrapperClasses}>
            <div className={`${baseClassName}__heading`}>
                {marketGroup === 'cps' ? 'Select Outlets by Channel:' : 'Select by channel:'}
            </div>
            <div className={marketChannelListClasses}>
                <div className={classnames(`${baseClassName}__lists-fmcg`)}>
                    {marketGroup !== 'cps' && (
                        <div className={classnames(`${baseClassName}__subheading`)}>FMCG Retailers:</div>
                    )}
                    {Object.keys(marketChannel).length > 0 &&
                        marketChannel.FMCGRetailers.map((item, index) => {
                            return (
                                (checkIfMarketCanBeLoaded(item)) ? (
                                    <div key={index} className={classnames(`${baseClassName}__lists-fmcg-market-folder`)}>
                                        <div className={classnames(`${baseClassName}__remaining`)}>
                                            <div className={classnames(`${baseClassName}__remaining-wrapper`)}>
                                                <span
                                                    className={classnames(`${baseClassName}__expander-wrapper`)}
                                                    onClick={() => showCompanies(index)}
                                                >
                                                    {item.expanded ? (
                                                        <MinusOutlined />
                                                    ) : (
                                                        <PlusOutlined />
                                                    )}
                                                </span>
                                                {item.is_mrkt === 'Y' && multiSelect === true && (
                                                    <>
                                                        {enableMultiMarketSelection(item.name) && (
                                                            <Checkbox
                                                                disabled={!enableMultiMarketSelection(item.name)}
                                                                style={{ display: 'flex', paddingRight: '3.4px' }}
                                                                onChange={(e) => {
                                                                    marketSelected(
                                                                        false,
                                                                        e.target.checked,
                                                                        'FMCGRetailers-folders',
                                                                        index
                                                                    );
                                                                    checkLimit(item, e.target.checked, item.mrkt_sel_typ);
                                                                }}
                                                                checked={item.selected}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                {item.is_mrkt === 'Y' && multiSelect === false && (
                                                    <LegacyByzzerRadio
                                                        label={item.name}
                                                        checked={
                                                            from === 'reportSelector'
                                                                ? selectorState.focusMarket === item.name
                                                                : userPrefState.primaryMarket === item.name
                                                        }
                                                        onChange={() =>
                                                            setSingleSelectMarketData(
                                                                item.name,
                                                                item.mrkt_key,
                                                                item.mrkt_sel_typ
                                                            )
                                                        }
                                                    />
                                                )}
                                                {item.is_mrkt === 'Y' && multiSelect === false ? (
                                                    ''
                                                ) : (
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            padding: '3.4px',
                                                            justifyItems: 'center',
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                )}
                                            </div>
                                            {renderRemainingMarketHeaders(item)}
                                        </div>
                                        {item.expanded &&
                                            item.companies !== undefined &&
                                            item.companies.length > 0 &&
                                            item.companies.map((item1, index1) => {
                                                return (
                                                    <div
                                                        key={index1}
                                                        className={classnames(
                                                            `${baseClassName}__lists-fmcg-market-folder-companies`
                                                        )}
                                                    >
                                                        {showTooltipFMCGRetailers(item1, index, index1)}

                                                        {item1.expanded &&
                                                            item1.market !== undefined &&
                                                            item1.market.length > 0 &&
                                                            item1.market.map((item2, index2) => {
                                                                return (
                                                                    <>
                                                                        {showTooltipFMCGRetailers(
                                                                            item2,
                                                                            index,
                                                                            index1,
                                                                            index2
                                                                        )}
                                                                    </>
                                                                );
                                                            })}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : null
                            );
                        })}
                    {marketChannel &&
                        marketChannel.specialtyRetailers &&
                        Object.keys(marketChannel.specialtyRetailers).length > 0 && (
                            <div className={classnames(`${baseClassName}__subheading`)}>Specialty Retailers:</div>
                        )}
                    {marketChannel &&
                        marketChannel.specialtyRetailers &&
                        Object.keys(marketChannel.specialtyRetailers).length > 0 &&
                        marketChannel.specialtyRetailers.map((item, index) => {
                            return (
                                (checkIfMarketCanBeLoaded(item)) ? (
                                    <div key={index} className={classnames(`${baseClassName}__lists-fmcg-market-folder`)}>
                                        <div className={classnames(`${baseClassName}__remaining`)}>
                                            <div className={classnames(`${baseClassName}__remaining-wrapper`)}>
                                                <span
                                                    className={classnames(`${baseClassName}__expander-wrapper`)}
                                                    onClick={() => showSpecialityRetailersCompanies(index)}
                                                >
                                                    {item.expanded ? (
                                                        <MinusOutlined />
                                                    ) : (
                                                        <PlusOutlined />
                                                    )}
                                                </span>
                                                {item.is_mrkt === 'Y' && multiSelect === true && (
                                                    <>
                                                        {enableMultiMarketSelection(item.name) && (
                                                            <Checkbox
                                                                disabled={!enableMultiMarketSelection(item.name)}
                                                                style={{ display: 'flex', paddingRight: '3.4px' }}
                                                                onChange={(e) => {
                                                                    marketSelected(
                                                                        false,
                                                                        e.target.checked,
                                                                        'SpecialityRetailers-folders',
                                                                        index
                                                                    );
                                                                    checkLimit(item, e.target.checked, item.mrkt_sel_typ);
                                                                }}
                                                                checked={item.selected}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                                {item.is_mrkt === 'Y' && multiSelect === false && (
                                                    <LegacyByzzerRadio
                                                        label={item.name}
                                                        checked={
                                                            from === 'reportSelector'
                                                                ? selectorState.focusMarket === item.name
                                                                : userPrefState.primaryMarket === item.name
                                                        }
                                                        onChange={() =>
                                                            setSingleSelectMarketData(
                                                                item.name,
                                                                item.mrkt_key,
                                                                item.mrkt_sel_typ
                                                            )
                                                        }
                                                    />
                                                )}
                                                {item.is_mrkt === 'Y' && multiSelect === false ? (
                                                    ''
                                                ) : (
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            padding: '3.4px',
                                                            paddingLeft: '5px',
                                                            justifyItems: 'center',
                                                        }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                )}
                                            </div>
                                            {renderRemainingMarketHeaders(item)}
                                        </div>
                                        {item.expanded &&
                                            item.companies !== undefined &&
                                            item.companies.length > 0 &&
                                            item.companies.map((item1, index1) => {
                                                return (
                                                    <div
                                                        key={index1}
                                                        className={classnames(
                                                            `${baseClassName}__lists-fmcg-market-folder-companies`
                                                        )}
                                                    >
                                                        {showTooltipSpecialityRetailers(item1, index, index1)}
                                                        {item1.expanded &&
                                                            item1.market !== undefined &&
                                                            item1.market.length > 0 &&
                                                            item1.market.map((item2, index2) => {
                                                                return (
                                                                    <div
                                                                        key={index2}
                                                                        className={classnames(
                                                                            `${baseClassName}__lists-fmcg-market-folder-companies-sub`
                                                                        )}
                                                                    >
                                                                        {showTooltipSpecialityRetailers(
                                                                            item2,
                                                                            index,
                                                                            index1,
                                                                            index2
                                                                        )}
                                                                        {item2.expanded &&
                                                                            item2.subFolders !== undefined &&
                                                                            item2.subFolders.length > 0 &&
                                                                            item2.subFolders.map((item3, index3) => {
                                                                                return (
                                                                                    <div
                                                                                        key={index3}
                                                                                        className={classnames(
                                                                                            `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder`
                                                                                        )}
                                                                                    >
                                                                                        {showTooltipSpecialityRetailers(
                                                                                            item3,
                                                                                            index,
                                                                                            index1,
                                                                                            index2,
                                                                                            index3
                                                                                        )}
                                                                                        {item3.expanded &&
                                                                                            item3.markets !== undefined &&
                                                                                            item3.markets.length > 0 &&
                                                                                            item3.markets.map(
                                                                                                (item4, index4) => {
                                                                                                    return (
                                                                                                        <div
                                                                                                            key={index4}
                                                                                                            className={classnames(
                                                                                                                `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder-markets`
                                                                                                            )}
                                                                                                        >
                                                                                                            {showTooltipSpecialityRetailers(
                                                                                                                item4,
                                                                                                                index,
                                                                                                                index1,
                                                                                                                index2,
                                                                                                                index3,
                                                                                                                index4
                                                                                                            )}
                                                                                                            {item4.expanded &&
                                                                                                                item4.lvl2markets !==
                                                                                                                undefined &&
                                                                                                                item4
                                                                                                                    .lvl2markets
                                                                                                                    .length >
                                                                                                                0 &&
                                                                                                                item4.lvl2markets.map(
                                                                                                                    (
                                                                                                                        item5,
                                                                                                                        index5
                                                                                                                    ) => {
                                                                                                                        return (
                                                                                                                            <div
                                                                                                                                key={
                                                                                                                                    index5
                                                                                                                                }
                                                                                                                                className={classnames(
                                                                                                                                    `${baseClassName}__lists-fmcg-market-folder-companies-sub-subfolder-markets-lvl2market`
                                                                                                                                )}
                                                                                                                            >
                                                                                                                                {showTooltipSpecialityRetailers(
                                                                                                                                    item5,
                                                                                                                                    index,
                                                                                                                                    index1,
                                                                                                                                    index2,
                                                                                                                                    index3,
                                                                                                                                    index4,
                                                                                                                                    index5
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    }
                                                                                                                )}
                                                                                                        </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                ) : null
                            );
                        })}
                </div>
            </div>
        </div>
    ) : null;
};
export default MultiSelectMarketsByChannel;