import React, { useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './OnboardingWizard.scss';
import { OnboardingAction, OnboardingContextValue, OnboardingStep, OnboardingWizardContext, OnboardingWizardContextValue } from "./OnboardingWizardContext";
import useState from 'react-usestateref';
import { AccordionWizard, TabWizard, TabWizardRef } from "@byzzer/ui-components";
import {AboutYourCompany, AboutYou, AboutYourPortfolio, InviteMembers} from "./OnboardingSteps";
import { TeamRole } from "@/types/InvitationTypes";
import { useUser } from "@/contexts/UserContext";
import { useTenantApi } from "@/hooks";
import { CompanyCreditUsage, SubscriptionProduct, TenantCompany, TenantUser } from "@/types/ApiTypes";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { differenceInHours } from 'date-fns';
import { skipTimeGap } from "@/config/onBoarding.config";
import {useNavigate} from "react-router-dom";

export type OnboardingWizardProps = {
    className?: string;
    onComplete?: (onboardingValue: OnboardingContextValue) => void;
    // setLoading?: (loading: boolean) => void;
    busyCompleteButtonText?: string;
    busyCompleteButtonTip?: ReactNode;  
};

type FilterParams = {
    user: TenantUser | null, 
    usage: CompanyCreditUsage, 
    company: TenantCompany, 
    categories: string[], 
    subscription: SubscriptionProduct | null
};

export type OnboardingStepProps = {
    action: OnboardingAction;
    isLastStep: boolean;
    step: OnboardingStep;
    onNext: () => void;
    onSkip: () => void;
    setBusy: (busy: boolean) => void;
    title: string;
    nextText: string;
    busy?: boolean;
    usage?: CompanyCreditUsage;
    code: OnboardingStepCode;
};

export type OnboardingStepCode = 'persona_step' | 'company_config_step' | 'user_config_step' | 'invitation_step';

const onboardingSteps: {
    title: string;
    component: React.ComponentType<OnboardingStepProps>;
    step: OnboardingStep;
    nextText?: string;
    action: OnboardingAction;
    code: OnboardingStepCode;
}[] = [
    {
        title: 'Tell us who you are',
        component: AboutYou,
        step: 'personas',
        nextText: 'Submit',
        action: 'personasSelected',
        code: 'persona_step'
    }, 
    {
        title: 'Tell us about your company',
        component: AboutYourCompany,
        step: 'companyPortfolio',
        nextText: 'Submit',
        action: 'categoriesSelected',
        code: 'company_config_step'
    }, 
    {
        title: 'Tell us about Your Portfolio',
        component: AboutYourPortfolio,
        step: 'userDefaults',
        nextText: 'Set Defaults',
        action: 'reportDefaultsSelected',
        code: 'user_config_step'
    }, 
    {
        title: 'Invite new members',
        component: InviteMembers,
        step: 'invitations',
        nextText: 'Send Invitations',
        action: 'invitationsSent',
        code: 'invitation_step'
    }
]


export const OnboardingWizard = ({
    className,
    onComplete,
    // setLoading,
    ...props
}: OnboardingWizardProps) => {

    const baseClassName = 'byz-onboarding-wizard';

    const {user, subscription, company, categories, brands, refreshDefaultRunConfig, refreshUser, brandNotRetailingYet} = useUser();
    const {getMySubscriptionUsage, updateUserActions, saveFreeUserDefaultRunConfig} = useTenantApi();
    const navigate = useNavigate();
    const [usage, setUsage] = useState<CompanyCreditUsage>();
    const [busy, setBusy] = useState(false);

    const categoriesRef = useRef<typeof categories>(categories);
    const brandsRef = useRef<typeof brands>(brands);
    categoriesRef.current = categories;
    brandsRef.current = brands;

    useEffect(() => {
        ;(async () => {
            await getUsage();
        })()
    }, []);

    const usedUsers = Number(usage?.users?.used);
    const userLimit = Number(usage?.users?.limit);

    const { 
        personasSelected, categoriesSelected, reportDefaultsSelected, invitationsSent, 
        onboardingCompleted
    } = user?.settings ?? {};

    const isAdmin = user?.role === 'admin';
    const isViewer = user?.role === 'viewer';
    const userIsSetupAdmin = isAdmin && user?.features?.hidden;
    const isFreeSubscription = subscription?.metadata?.isFree;
    const isFinancialServicesAccessLevel = ['banks_finance'].includes(company?.accessLevel!);

    const personasStepComplete = personasSelected === 1; // can't skip personas step per initial req's
    const categoriesStepComplete = categoriesSelected === 1;
    const defaultsStepSkippedOrComplete = [-1, 1].includes(reportDefaultsSelected);
    const invitationsStepSkippedOrComplete = [-1, 1].includes(invitationsSent);

    const includePersonasStep = !personasStepComplete && !isFinancialServicesAccessLevel && !userIsSetupAdmin;
    const includeCompanyPortfolioStep = !categoriesStepComplete && isAdmin && !categories?.length;
    const includeUserDefaultsStep = !defaultsStepSkippedOrComplete && !isViewer && !userIsSetupAdmin && !(isAdmin && isFreeSubscription);
    const includeInvitationsStep = !invitationsStepSkippedOrComplete && isAdmin && !isFreeSubscription && (usedUsers < userLimit) && !userIsSetupAdmin && onboardingCompleted !== 1;

    function filterOnboardingStep(step: OnboardingStep) {
        switch (step) {
            case 'personas':
                return includePersonasStep;
            case 'companyPortfolio':
                return includeCompanyPortfolioStep;
            case 'userDefaults':
                return includeUserDefaultsStep;
            case 'invitations':
                return includeInvitationsStep;
            default:
                return false;
        }
    }

    async function getUsage() {
        setBusy(true);
        setUsage(await getMySubscriptionUsage());
        setBusy(false);
    }

    const disableStep = (step: OnboardingStep) => {
        wizardRef.current?.disableStep(step);
    }

    const goToStepByIndex = (index: number) => {
        wizardRef.current?.goToStepByIndex(index);
    }

    async function handleNext({step, action, isLastStep}: {step: OnboardingStep, action: OnboardingAction, isLastStep: boolean}) {
        const categories = categoriesRef.current;
        const brands = brandsRef.current;
        const canMarkComplete = !includeCompanyPortfolioStep || Boolean(categories.length);
        try {
            await updateUserActions({
                [action]: 1,
                ...(canMarkComplete ? {onboardingCompleted: 1} : {})
            })
            await refreshUser();
            if (action === 'categoriesSelected' && !includeUserDefaultsStep && Boolean(categories?.length) && !isFinancialServicesAccessLevel) {
                const saveResult = await saveFreeUserDefaultRunConfig({
                    selectedBrands: brands,
                    selectedCategories: categories,
                    brandNotRetailingYet,
                })
                refreshDefaultRunConfig(saveResult)
            }
            if (isLastStep) {
                await updateUserActions({
                    onboardingCompleted: 1,
                    onBoardingLastSkippedTime: new Date()
                })
                await refreshUser();
                navigate('/dashboard', {replace: true});
            } else {
                await updateUserActions({
                    ...(action === 'categoriesSelected' ? {onboardingCompleted: 1} : {})
                })
                await refreshUser();
                wizardRef.current?.activateNextStep();
            }
        } catch (err) {
            // tbd
            console.error(`OnboardingWizard - handleNext error ===>>`, err);
        } finally {
            setBusy(false);
        }
    }


    async function handleSkip({step, action, isLastStep}: {step: OnboardingStep, action: OnboardingAction, isLastStep: boolean}) {
        const categories = categoriesRef.current;
        const brands = brandsRef.current;
        const userDefaultsIsLastStep = steps.some(({props}, i) => props.action === 'reportDefaultsSelected' && i === steps.length - 1);
        try {
            await updateUserActions({
                [action]: -1
            })
            await refreshUser();
            if (action === 'reportDefaultsSelected' && Boolean(categories?.length) && !isFinancialServicesAccessLevel) {
                const saveResult = await saveFreeUserDefaultRunConfig({
                    selectedBrands: brands,
                    selectedCategories: categories,
                    brandNotRetailingYet,
                })
                refreshDefaultRunConfig(saveResult)
            }
            if (isLastStep || (action === 'categoriesSelected' && includeUserDefaultsStep && userDefaultsIsLastStep)) {
                await updateUserActions({
                    onboardingCompleted: 1,
                    ...(!categories?.length &&
                        !user?.settings?.onBoardingLastSkippedTime || (differenceInHours(new Date(), new Date(user?.settings?.onBoardingLastSkippedTime)) > skipTimeGap)
                            ? { onBoardingLastSkippedTime: new Date() }
                            : {}),
                })
                await refreshUser();
                navigate('/dashboard', {replace: true});
            } else {
                if (action === 'categoriesSelected') {
                    await updateUserActions({
                        onboardingCompleted: 1
                    })
                    await refreshUser();
                    goToStepByIndex(Number(steps?.length) - 1); // todo replace with enableStep, needs to be added to TabWizard
                    disableStep('userDefaults');
                } else {
                    wizardRef.current?.activateNextStep();
                }
            }
        } catch (err) {
            // tbd
            console.error(`OnboardingWizard - handleSkip error ===>>`, err);
        } finally {
            setBusy(false);
        }
    }

    const steps = useMemo(() => {
        if (!usage || !user || !company || !categories || !subscription) return [];
        const filteredSteps = onboardingSteps
            .filter(({step}) => filterOnboardingStep(step))
            .map(({component, step, action, ...baseOptions}, i, onboardingSteps) => {
                const Step = component;
                const isLastStep = onboardingSteps.length - 1 === i;
                const options: OnboardingStepProps = {
                    nextText: isLastStep ? 'Submit' : 'Next',
                    ...baseOptions,
                    action,
                    isLastStep,
                    step,
                    onNext: () => handleNext({step, action, isLastStep}),
                    onSkip: () => handleSkip({step, action, isLastStep}),
                    setBusy,
                    usage,
                }
                return <Step key={step} {...options}/>
            });
        return filteredSteps;
    }, [usage]);

    const [contextValue, setContextValue, contextRef] = useState<OnboardingWizardContextValue>({
        value: {},
        onChange({name, value}: {name: keyof OnboardingContextValue, value: any}): void {

            setContextValue((current) => ({
                ...current,
                value: {
                    ...current.value,
                    [name]: value,
                },
            })); 
        },
    });

    const wizardRef = useRef<TabWizardRef | null>(null);

    return (
        <>
            <ByzzerMask show={busy} loading={busy} />
            <OnboardingWizardContext.Provider value={contextValue}>
                <TabWizard
                    className={classnames(baseClassName, className)}
                    ref={wizardRef}
                >
                    {steps}
                </TabWizard>
            </OnboardingWizardContext.Provider>
        </>
   );
};

export default OnboardingWizard;

OnboardingWizard.displayName = 'OnboardingWizard';