import React, { useState } from 'react';
import { LegacyByzzerCheckbox, LegacyByzzerRadio } from '@/components/form';
import './MultiSelectMarketsByGeography.scss';
import classnames from 'classnames';
import { MrktEntitlementType } from '@/utils';
import MinusOutlined from '@/components/icons/MinusOutlined';
import PlusOutlined from '@/components/icons/PlusOutlined';


const Geography = (props) => {
    const { toolTipMessage, showTooltip, checkIfMarketCanBeLoaded } = props;
    const baseClassName = 'multi-select-markets-by-geography';
    const wrapperClasses = classnames(`${baseClassName}`);
    const geographyListClasses = classnames(`${baseClassName}__lists`);
    const [ isHidePromoData, setHideNonPromoData] = useState(false); //TODO post Suresh code commit BYZ-7230

    const setSingleSelectMarketData = (marketObj) => {
        if (props.from === 'reportSelector') {
            props.setSelectorsData({ focusMarket: marketObj.name });
        } else {
            props.setPreferenceState({ primaryMarket: marketObj.name, marketSelType: marketObj.mrkt_sel_typ });
        }
        props.setSelectedItems([
            {
                name: marketObj.name,
                marketKey: marketObj.mrkt_key,
            },
        ]);
        props?.setShowUpdateButton();
    };

    const enableGeographyMarketCheckbox = (item) => {
        if ((props.productType === 'story' && props?.marketType === 'focusMarket') || props?.alertWithAllMarketData) {
            //cat mngmt story, assortment alert
            return (
                !(props.selectedItems.length >= props.maxLimit && !item?.selected) &&
                props.isMarketLevelSelectable(item)
            );
        } else if (props?.productType === 'story' && props?.marketType === 'comparisonMarket') {
            return (
                !(props.selectedItems.length >= props.maxLimit && !item?.selected) &&
                props.isMarketLevelSelectable(item) &&
                !props?.filterComparisonMarket(item.name)
            );
        } else {
            // for reports/alerts
            return (
                !(props.selectedItems.length >= props.maxLimit && !(item?.selectedComparitive || item.selected)) &&
                item.is_releasable_mrkt
            );
        }
    };

    const showComparativeCheckbox = (item) => {
        if (props?.productType === 'story') {
            return !(props.selectedItems.length >= props.maxLimit && !item?.selectedComparitive);
        } else {
            return !(props.selectedItems.length >= props.maxLimit && !(item?.selectedComparitive || item.selected));
        }
    };

    const showOnlyMarketName = (marketName) => {
        let disableFlag =
            props.selectedItems.length >= props.maxLimit &&
            !props.selectedItems.map((selItem) => selItem.name).includes(marketName);
        let isNotSelectable = props?.filterComparisonMarket(marketName);
        return disableFlag || isNotSelectable;
    };

    const geographymarkets = (item, item1, index, index1) => {
        if(!checkIfMarketCanBeLoaded(item1)){
            return <></>;
        }
        return (
            <>
                {(item?.mrkt_entitlements === undefined || item1?.is_releasable_mrkt ) && (
                    <div key={index1} className={classnames(`${baseClassName}__lists-folders-container-companies`)}>
                        {item1.is_mrkt === 'Y' && props.multiSelect === true && (
                            <>
                                <div className={classnames(`${baseClassName}__lists-folders-container-list-item1`)}>
                                    {enableGeographyMarketCheckbox(item1) && (
                                            
                                        <LegacyByzzerCheckbox
                                            label={item1.name}
                                            disabled={
                                                props.selectedItems.length >= props.maxLimit &&
                                                !props.selectedItems.map((selItem) => selItem.name).includes(item1.name)
                                            }
                                            style={{
                                                display: 'flex',
                                                padding: '0.25vw',
                                                paddingLeft: '0px',
                                            }}
                                            onChange={(e) => {
                                                props.geographyMarketSelected(index, index1);
                                                props.checkLimit(
                                                    {
                                                        ...item1,
                                                        uniqueMarketName: item1?.name,
                                                        mrkt_key_selected: item1.mrkt_key,
                                                        marketType: 'regular',
                                                    },
                                                    e.target.checked,
                                                    item1.mrkt_sel_typ
                                                );
                                            }}
                                            checked={item1.selected}
                                            key={`geography-market-${index}-${Date.now()}`}
                                        />
                                        
                                        
                                    )}

                                    {!enableGeographyMarketCheckbox(item1) && (
                                        <>
                                            <span className={classnames(
                                                `multi-select-markets-by-geography__statelines`, {
                                                [`multi-select-markets-by-geography__statelines-disabled`]: !enableGeographyMarketCheckbox(item1)
                                            }
                                            )}>{item1.name}</span>
                                            <div className='hover-icons-wrapper'>
                                                {showTooltip(item1)}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {showComparativeCheckbox(item1) &&
                                    props?.marketConfigs?.requiredRemMarketForTotalMarkets && (
                                        <div
                                            className={classnames(
                                                `${baseClassName}__lists-folders-container-list-item2`
                                            )}
                                        >
                                            <LegacyByzzerCheckbox
                                                disabled={
                                                    !item1.comparative_mkt_disp_name ||
                                                    (props.selectedItems.length >= props.maxLimit &&
                                                        !item1?.selectedComparitive)
                                                }
                                                style={{
                                                    display: 'flex',
                                                    paddingRight: '3.4px',
                                                    alignItems: 'center',
                                                }}
                                                key={`comparitive-geography-${Date.now()}`}
                                                onChange={(e) => {
                                                    props?.geographyMarketSelected(index, index1, 'comparative');
                                                    props?.checkLimit(
                                                        {
                                                            ...item1,
                                                            uniqueMarketName: `${item1.comparative_mkt_disp_name}$$${item1.comparative_mrkt_key}$$${item1.mrkt_key}`,
                                                            mrkt_key_selected: item1.comparative_mrkt_key,
                                                            marketType: 'comparative',
                                                        },
                                                        e.target.checked,
                                                        item1.mrkt_sel_typ
                                                    );
                                                }}
                                                checked={item1?.selectedComparitive}
                                            />
                                        </div>
                                    )}
                            </>
                        )}
                        {item1.is_mrkt === 'Y' && props.multiSelect === false && (
                            <LegacyByzzerRadio
                                label={item1.name}
                                checked={
                                    props.from === 'reportSelector'
                                        ? props.selectorState.focusMarket === item1.name
                                        : props.userPrefState.primaryMarket === item1.name
                                }
                                onChange={() => setSingleSelectMarketData(item1)}
                            />
                        )}
                    </div>
                )}
                {( item?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_PRIOR_APPROVAL ) ||
                   item?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_REV_SHARE ) ||
                   item?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ) ) && (
                        <div key={index1} className={classnames(`${baseClassName}__lists-folders-container-companies`)}>
                            <span
                                className={classnames({
                                    [`${baseClassName}__lists-folders-name-wrapper--not-covered`]: item1?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ),
                                    [`${baseClassName}__lists-folders-name-wrapper--covered`]: item1?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_COVERED ),
                                })}
                            >
                                {item1.name}
                            </span>
                            <div className='hover-icons-wrapper'>
                                {showTooltip(item1)}
                            </div>
                        </div>
                    )}
            </>
        );
    };

    const showToolTipForSingleGeographyMarket = (item1) => {
        return (
            ( item1?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_PRIOR_APPROVAL ) ||
              item1?.mrkt_entitlements?.includes( MrktEntitlementType.NOT_REV_SHARE ) ||
              item1?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ) ) && (
                <div key={item1.name} className={classnames(`${baseClassName}__lists-folders-container-singlemarket`)}>
                    <span
                        className={classnames({
                            [`${baseClassName}__lists-folders-name-wrapper--not-covered`]: item1?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_NOT_COVERED ) ,
                            [`${baseClassName}__lists-folders-name-wrapper--covered`]: item1?.mrkt_entitlements?.includes( MrktEntitlementType.CATEGORY_COVERED ),
                        })}
                    >
                        {item1.name}
                    </span>
                    <div className='hover-icons-wrapper'>
                        {showTooltip(item1)}
                    </div>
                </div>
            )
        );
    };
    return props.geography && props.geography.length > 0 ? (
        <div className={wrapperClasses}>
            <div className={classnames(`${baseClassName}__heading`)}>
                <span>Select by geography:</span>
            </div>
            <div className={geographyListClasses}>
                {props.geography.length > 0 &&
                    props.geography.map((item, index) => {
                    if( checkIfMarketCanBeLoaded(item) ){
                        if (item.is_mrkt === 'Y') {
                            return (
                                <>
                                    <div
                                        key={index}
                                        className={classnames(`${baseClassName}__lists-folders-container`)}
                                    >
                                        {item.is_mrkt === 'Y' &&
                                            props.multiSelect === true &&
                                            item.is_releasable_mrkt && (
                                                <>
                                                    {!(
                                                        props.selectedItems.length >= props.maxLimit &&
                                                        !props.selectedItems
                                                            .map((selItem) => selItem.name)
                                                            .includes(item.name)
                                                    ) && (
                                                            <>
                                                                <LegacyByzzerCheckbox
                                                                    label={item.name}
                                                                    disabled={
                                                                        props.selectedItems.length >= props.maxLimit &&
                                                                        !props.selectedItems
                                                                            .map((selItem) => selItem.name)
                                                                            .includes(item.name)
                                                                    }
                                                                    style={{
                                                                        display: 'flex',
                                                                        padding: '0.25vw',
                                                                        paddingLeft: '0px',
                                                                    }}
                                                                    onChange={(e) => {
                                                                        props.geographySingleMarketSelected(index);
                                                                        props.checkLimit(
                                                                            {
                                                                                ...item,
                                                                                uniqueMarketName: item.name,
                                                                                mrkt_key_selected: item.mrkt_key,
                                                                                marketType: 'regular',
                                                                            },
                                                                            e.target.checked,
                                                                            item.mrkt_sel_typ
                                                                        );
                                                                    }}
                                                                    checked={item.selected}
                                                                    key={`geography-${index}-${Date.now()}`}
                                                                />
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        {showToolTipForSingleGeographyMarket(item)}
                                        {item.is_mrkt === 'Y' && props.multiSelect === false && (
                                            <LegacyByzzerRadio
                                                label={item.name}
                                                checked={
                                                    props.from === 'reportSelector'
                                                        ? props.selectorState.focusMarket === item.name
                                                        : props.userPrefState.primaryMarket === item.name
                                                }
                                                onChange={() => setSingleSelectMarketData(item)}
                                            />
                                        )}
                                    </div>
                                </>
                            );
                        } else {
                            return (
                                <div key={index} className={classnames(`${baseClassName}__lists-folders-container`)}>
                                    <div className={classnames(`${baseClassName}__remaining`)}>
                                        <div className={classnames(`${baseClassName}__remaining-wrapper`)}>
                                            <span
                                                className={classnames(`${baseClassName}__expander-wrapper`)}
                                                onClick={() => props.showGeographyMarkets(index)}
                                            >
                                                {item.expanded ? (
                                                    <MinusOutlined />
                                                ) : (
                                                    <PlusOutlined />
                                                )}
                                            </span>
                                            <span style={{ display: 'flex', padding: '0.25vw', paddingLeft: '5px' }}>
                                                {item.name}
                                            </span>
                                        </div>
                                        {props?.marketConfigs?.requiredRemMarketForTotalMarkets &&
                                            item.expanded &&
                                            props.productType !== 'story' && (
                                                <div className={classnames(`${baseClassName}__item2`)}>Comparative</div>
                                            )}
                                        {props?.marketConfigs?.requiredRemMarketForTotalMarkets &&
                                            item.expanded &&
                                            props.productType === 'story' && (
                                                <div
                                                    className={classnames(
                                                        `${baseClassName}__item2 ${baseClassName}__heading`
                                                    )}
                                                >
                                                    Comparative Market
                                                </div>
                                            )}
                                    </div>
                                    {/* <div className='multi-select-markets-by-geography__lists-folders-container-list-item'> */}
                                    {item.expanded &&
                                        item.markets !== undefined &&
                                        item.markets.length > 0 &&
                                        item.markets.map((item1, index1) => {
                                            return geographymarkets(item, item1, index, index1);
                                        })}
                                    {/* </div> */}
                                </div>
                            );
                        }
                    }
                    })}
            </div>
        </div>
    ) : null;
};
export default Geography;