// @ts-check
import './FunctionInsightScores.scss';
import {ByzzerLink, ByzzerModal, ByzzerMultiSelect, LegacyByzzerSelect} from '@/components/form';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Tabs} from 'antd';
import ByzzerSearchableSelect from '@/components/form/ByzzerSearchableSelect';
import InlineSVG from 'react-inlinesvg';
import OpportunityTable from '@/views/scorecard/IdentifyOppurtunityTable';
import {useTenantApi} from '@/hooks/useTenantApi';
import {marketTypes} from '@/config/globalVars';
import ScoreInfoImage from '@images/ScoreInfoImage.svg';
import {TrackClick} from "@/analytics";
import {useTrackEvent} from "@/analytics/AnalyticsContext";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import {useBetterNavigate} from "@/utils";
import {debounce} from 'lodash';
import classnames from 'classnames';
import {ScorecardEmptyState} from "@/pages/MyScorecardPage";
import {ByzzerTabs} from '@/components/ByzzerTabs';

export type TabTypes = 'markets' | 'categories' | 'brands';

export const tabsList: {display: string, value: TabTypes}[] = [
    {display: "Markets", value: "markets"},
    {display: "Categories", value: "categories"},
    {display: "Brands", value: "brands"},
]

type SelectionType = {markets?: any[], categories?: any[], brands?: any[], marketTypes?: any[]};

const baseClassName = 'my-scorecard-opportunity';


export function FunctionInsightScores({
                                          filters,
                                          onFiltersChange,
                                          hasScorecardAccess,
                                          ...props
                                      }) {
    const {calculateScores, calculateScoresCount} = useTenantApi();
    const mainClasses = classnames('my-scorecard-opportunity');
    const subClasses = classnames('my-scorecard-opportunity__subclass');
    const [loading, setLoading] = useState(false);
    const [marketTableData, setMarketTableData] = useState<any>([]);
    const [categoriesTableData, setCategoriesTableData] = useState<any>([]);
    const [brandsTableData, setBrandsTableData] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState<TabTypes>('markets');
    const [overallGradeByMarket, setOverallGradeByMarket] = useState('');
    const [overallGradeByCategory, setOverallGradeByCategory] = useState('');
    const [filterItem, setFilterItem] = useState(true);
    const [filterItemData, setFilterItemData] = useState<Record<string, string>>({key: 'sales'});
    const [sortMode, setSortMode] = useState('');
    const [scoreInfoModal, setScoreInfoModal] = useState(false);
    const trackEvent = useTrackEvent();
    const navigate = useBetterNavigate();
    const filtersRef = useRef(filters);
    const activeTabRef = useRef(activeTab);
    const [brandCount, setBrandsCount] = useState(0);

    useEffect(() => {
        const enableSearch = Boolean(filters.brands?.length && filters.categories?.length);
        clearData();
        filtersRef.current = filters;
        if (enableSearch) {
            loadScores();
        }
    }, [filters]);

    // useEffect(() => {
    //     if (activeTab === 'markets' &&
    //         (!filters.markets?.length || (filters.markets?.length === 1 && filters.markets[0] === 'Total FMCG Retailers'))) {
    //         onFiltersChange?.({markets: [], marketTypes: 'Account'});
    //     }
    // }, []);

    useEffect(() => {

        activeTabRef.current = activeTab;
        switch (activeTab) {
            case 'markets':
                if (filters.markets?.length === 0) {
                    onFiltersChange?.({markets: [], marketTypes: 'Account'});
                }
                break;
            case 'categories':
            case 'brands':
                if (filters.markets?.length !== 1) {
                    onFiltersChange?.({markets: [], marketTypes: ''});
                } else {
                    onFiltersChange?.({marketTypes: ''});
                }
                break;

        }
        // loadScores();
    }, [activeTab])

    // useEffect(() => {
    //     if (hasScorecardAccess && activeKey === 'markets' && !filters.markets?.length) {
    //         onFiltersChange?.({markets: [], marketTypes: 'Account'});
    //     }
    // },[hasScorecardAccess]);

    const loadScores = useMemo(() => debounce(async () => {

        if(!hasScorecardAccess) return;
        setBrandsCount(0);
        switch (activeTabRef.current) {
            case 'brands':
                await fetchDataByBrand();
                break;
            case 'categories':
                await fetchDataByCategory();
                break;
            case 'markets':
            default:
                await fetchDataByMarket();
        }
    }, 300), []);


    const filtertableData = (val, bool, type, mode) => {
        if ((bool && filterItem && !mode) || mode === 'asc') {
            if (val?.key === 'sales') {
                if (type === 'Brands') {
                    setBrandsTableData((data) => data.sort((a, b) => a?.sales - b?.sales));
                } else if (type === 'Categories') {
                    setCategoriesTableData((data) => data.sort((a, b) => a?.sales - b?.sales));
                } else if (type === 'Markets') {
                    setMarketTableData((data) => data.sort((a, b) => a?.sales - b?.sales));
                }
            } else {
                if (type === 'Brands') {
                    setBrandsTableData((data) => data.sort((a, b) => a[val?.key] - b[val?.key]));
                } else if (type === 'Categories') {
                    setCategoriesTableData((data) => data.sort((a, b) => a[val?.key] - b[val?.key]));
                } else if (type === 'Markets') {
                    setMarketTableData((data) => data.sort((a, b) => parseInt(a[val?.key]) - parseInt(b[val?.key])));
                }
            }
            setFilterItem(false);
            setSortMode('asc');
        } else if ((!filterItem && !mode) || mode === 'des') {
            if (val?.key === 'sales') {
                if (type === 'Brands') {
                    setBrandsTableData((data) => data.sort((a, b) => b?.sales - a?.sales));
                } else if (type === 'Categories') {
                    setCategoriesTableData((data) => data.sort((a, b) => b?.sales - a?.sales));
                } else if (type === 'Markets') {
                    setMarketTableData((data) => data.sort((a, b) => b?.sales - a?.sales));
                }
                // setCategoriesTableData((data) => data.sort((a, b) => b?.sales - a?.sales));
            } else {
                if (type === 'Brands') {
                    setBrandsTableData((data) => data.sort((a, b) => b[val?.key] - a[val?.key]));
                } else if (type === 'Categories') {
                    setCategoriesTableData((data) => data.sort((a, b) => b[val?.key] - a[val?.key]));
                } else if (type === 'Markets') {
                    setMarketTableData((data) => data.sort((a, b) => parseInt(b[val?.key]) - parseInt(a[val?.key])));
                }
                // setCategoriesTableData((data) => data.sort((a, b) => b[val?.key]['value'] - a[val?.key]['value']));
            }
            setFilterItem(true);
            setSortMode('des');
        }
        setFilterItemData(val);
    };
    const clearData = () => {
        setMarketTableData([]);
        setCategoriesTableData([]);
        setBrandsTableData([]);
        setOverallGradeByMarket('');
        setOverallGradeByCategory('');
    };

    const handleScoreClick = (grade, item, type) => {
        props.setViewBy(type);
        if (type === 'Markets') {
            onFiltersChange?.({marketTypes: '', markets: [item.market]});
        } else if (type === 'Categories') {
            props.changeCategory([item.category])
        } else {
            onFiltersChange?.({brands: [item.brand]});
        }
        navigate(`/dashboard/my_scorecard/assess_levers/${grade.key}`, {
            replace: true
        })
    };

    const onTabClick = (tab: TabTypes) => {

        switch (tab) {
            case 'markets':
                trackEvent({
                    type: 'click',
                    name: `Brand Score Function Market Tab clicked`,
                });
                break;
            case 'categories':
                trackEvent({
                    type: 'click',
                    name: `Brand Score Function Category Tab clicked`,
                });
                break;
            case 'brands':
                trackEvent({
                    type: 'click',
                    name: `Brand Score Function Brand Tab clicked`,
                });
        }
        setActiveTab(tab);
    };

    const fetchDataByMarket = async () => {
        try {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if(!filters?.markets?.length){
                filters.markets = [];
            }
            let allSelection;
            if (filters.marketTypes) {
                allSelection = [
                    {
                        categories: filters.categories,
                        brands: filters.brands,
                        marketTypes: [filters.marketTypes],
                    },
                ];
            } else {
                allSelection = [
                    {
                        markets: filters.markets,
                        categories: filters.categories,
                        brands: filters.brands,
                    },
                ];
            }
            const selectedParam = JSON.parse(JSON.stringify(allSelection));

            selectedParam[0]['aggregated'] = true;
            const resultByAll = await calculateScores('overall', selectedParam);

            setOverallGradeByMarket(resultByAll[0]?.overallScore?.grade);

            let selections: SelectionType[] = [];
            if (!filters.marketTypes) {
                if (filters.markets?.length > 0) {
                    filters.markets?.forEach((market) => {
                        selections.push({
                            markets: [market],
                            categories: filters.categories,
                            brands: filters.brands,
                        });
                    });
                } else {
                    selections = [
                        {
                            markets: [],
                            categories: filters.categories,
                            brands: filters.brands,
                        },
                    ];
                }
            } else {
                selections = [
                    {
                        marketTypes: [filters.marketTypes],
                        categories: filters.categories,
                        brands: filters.brands,
                    },
                ];
            }
            const resultByMarket = await calculateScores('overall', selections);
            setMarketTableData(resultByMarket.map((item) => ({
                market: item?.criteria?.markets.length > 0 ? item?.criteria?.markets[0] : 'All Market',
                sales: item?.sales === 'No Data' ? 0 : item?.sales,
                grade: item?.overallScore?.grade,
                gradeValue: item?.overallScore?.value,
                promotion: parseInt(item?.promotion?.value?.toFixed(0)),
                pricing: parseInt(item?.pricing?.value?.toFixed(0)),
                assortment: parseInt(item?.assortment?.value?.toFixed(0)),
                product: parseInt(item?.product?.value?.toFixed(0)),
                promotionNoData: item?.promotion?.noData,
                pricingNoData: item?.pricing?.noData,
                assortmentNoData: item?.assortment?.noData,
                newProductNoData: item?.product?.noData,
                noData: item?.overallScore?.noData,
            })));
        } catch (err) {

        } finally {
            setLoading(false);
        }
    };
    const fetchDataByCategory = async () => {
        try {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if(!filters?.markets?.length){
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            const resultByAll = await calculateScores('overall', [
                {
                    markets: filters.markets,
                    categories: filters.categories,
                    brands: filters.brands,
                    aggregated: true,
                },
            ]);
            setOverallGradeByCategory(resultByAll[0]?.overallScore?.grade);

            let selections: SelectionType[] = [];
            filters.categories?.forEach((category) => {
                selections.push({
                    markets: filters.markets,
                    categories: [category],
                    brands: filters.brands,
                });
            });

            const resultByCategory = await calculateScores('overall', selections);
            setCategoriesTableData(resultByCategory.map((item) => ({
                category: item?.criteria?.categories[0],
                sales: item?.sales === 'No Data' ? 0 : item?.sales,
                grade: item?.overallScore?.grade,
                gradeValue: item?.overallScore?.value,
                promotion: parseInt(item?.promotion?.value.toFixed(0)),
                pricing: parseInt(item?.pricing?.value.toFixed(0)),
                assortment: parseInt(item?.assortment?.value.toFixed(0)),
                product: parseInt(item?.product?.value.toFixed(0)),
                promotionNoData: item?.promotion?.noData,
                pricingNoData: item?.pricing?.noData,
                assortmentNoData: item?.assortment?.noData,
                newProductNoData: item?.product?.noData,
                noData: item?.overallScore?.noData,
            })));
        } finally {
            setLoading(false);
        }
    };

    const fetchDataByBrand = async () => {
        try {
            setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if(!filters?.markets?.length){
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            const resultByAll = await calculateScores('overall', [
                {
                    markets: filters.markets,
                    categories: filters.categories,
                    brands: filters.brands,
                    aggregated: true,
                },
            ]);
            setOverallGradeByCategory(resultByAll[0]?.overallScore?.grade);
            const countByBrand = await calculateScoresCount('brands', {
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
            });
            setBrandsCount(countByBrand.brandsCount);
            const resultByBrand = await calculateScores('brand', [{
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
                aggregated: true,
                offset: 0
            }]);
            
            setBrandsTableData(resultByBrand.map((item) => ({
                brand: item?.criteria?.brands[0],
                sales: item?.sales === 'No Data' ? 0 : item?.sales,
                grade: item?.overallScore?.grade,
                gradeValue: item?.overallScore?.value.toFixed(0),
                promotion: parseInt(item?.promotion?.value.toFixed(0)),
                pricing: parseInt(item?.pricing?.value.toFixed(0)),
                assortment: parseInt(item?.assortment?.value.toFixed(0)),
                product: parseInt(item?.product?.value.toFixed(0)),
                promotionNoData: item?.promotion?.noData,
                pricingNoData: item?.pricing?.noData,
                assortmentNoData: item?.assortment?.noData,
                newProductNoData: item?.product?.noData,
                noData: item?.overallScore?.noData,
            })));
        } finally {
            setLoading(false);
        }
    };

    const loadOnScrollEnd = async() => {
        // setLoading(true);
        const filters = filtersRef.current;
        const resultByBrand = await calculateScores('brand', [{
            markets: filters.markets,
            categories: filters.categories,
            brands: filters.brands,
            aggregated: true,
            offset: (brandsTableData.length/100)
        }]);

        const allBrandsResult = [...brandsTableData, ...resultByBrand.map((item) => ({
            brand: item?.criteria?.brands[0],
            sales: item?.sales === 'No Data' ? 0 : item?.sales,
            grade: item?.overallScore?.grade,
            gradeValue: item?.overallScore?.value.toFixed(0),
            promotion: parseInt(item?.promotion?.value.toFixed(0)),
            pricing: parseInt(item?.pricing?.value.toFixed(0)),
            assortment: parseInt(item?.assortment?.value.toFixed(0)),
            product: parseInt(item?.product?.value.toFixed(0)),
            promotionNoData: item?.promotion?.noData,
            pricingNoData: item?.pricing?.noData,
            assortmentNoData: item?.assortment?.noData,
            newProductNoData: item?.product?.noData,
            noData: item?.overallScore?.noData,
        }))]
        setTimeout(() => {
            setBrandsTableData(allBrandsResult);

            setLoading(false)
        }, 200);
    }
    const handleScroll = async (e, newLoading) => {

        if(brandCount > brandsTableData.length && !loading){

            !loading && setLoading(true);
            const filters = JSON.parse(JSON.stringify(filtersRef.current));
            if(!filters?.markets?.length){
                filters.markets = ['Total US NielsenIQ Brand Score'];
            }
            const resultByBrand = await calculateScores('brand', [{
                markets: filters.markets,
                categories: filters.categories,
                brands: filters.brands,
                aggregated: true,
                offset: Math.floor(brandsTableData.length/100)
            }]);

            const allBrandsResult = [...brandsTableData, ...resultByBrand.map((item) => ({
                brand: item?.criteria?.brands[0],
                sales: item?.sales === 'No Data' ? 0 : item?.sales,
                grade: item?.overallScore?.grade,
                gradeValue: item?.overallScore?.value.toFixed(0),
                promotion: parseInt(item?.promotion?.value.toFixed(0)),
                pricing: parseInt(item?.pricing?.value.toFixed(0)),
                assortment: parseInt(item?.assortment?.value.toFixed(0)),
                product: parseInt(item?.product?.value.toFixed(0)),
                promotionNoData: item?.promotion?.noData,
                pricingNoData: item?.pricing?.noData,
                assortmentNoData: item?.assortment?.noData,
                newProductNoData: item?.product?.noData,
                noData: item?.overallScore?.noData,
            }))]
            setTimeout(() => {
                setBrandsTableData(allBrandsResult);
                setLoading(false)
            }, 400);
        }

    }
    if(hasScorecardAccess === false) {
        return <ScorecardEmptyState/>
    }

    return (
        <div className={mainClasses}>
            <ByzzerMask loading={loading}/>
                <div className="my-scorecard-opportunity__subclass-info">
                    <span>
                        Drill into your scores by markets and categories to identify the biggest areas of opportunity
                        for your business across functions.
                    </span>
                    <TrackClick name={'Brand Score What Does My Score Mean clicked'}>
                        <ByzzerLink
                            className="my-scorecard-opportunity__subclass-info-link"
                            label={'What does my score mean?'}
                            onClick={() => {
                                setScoreInfoModal(true);
                            }}
                        />
                    </TrackClick>
                </div>
                <div className="my-scorecard-opportunity__subclass-info-tab">View By:</div>
                    <ByzzerTabs // https://www.figma.com/design/v3AFUy35pEiu6AsZ3uDqvS/Core?node-id=2896-21755&t=MhHBftPQkVkQaBpG-0
                        activeTab={activeTab}
                        tabsList={tabsList}
                        onClick={onTabClick}
                        type={'filled'} // should only be for page headers per figma?
                    />
                    <div className={classnames(`${baseClassName}__content`)}>
                    {activeTab === 'markets' && (
                        <>
                            {/* <div className='my-scorecard-opportunity__subclass-tab-section-grade'>
                                <ByzzerSelectOverallGrade type={'gradeInfo'} value={'Overall Score:'} grade={overallGradeByMarket}
                                    options={'Your overall grade is based on performance across 4 drivers: Promotion, Pricing, Assortment and New Product launches. Update the selections to see any brand performance for a market(s) or category. Next, compare markets and categories and then assess your score for the levers for each driver to find your opportunities.'}
                                    readonly />
                            </div> */}
                                <div className="my-scorecard-opportunity__subclass-tabcontent-selection">
                                    <div>
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Brand:{' '}
                                        </div>
                                        {/* @ts-ignore */}
                                        <ByzzerSearchableSelect
                                            seperator={','}
                                            enableToolTip={filters.brands?.length > 0 ? true : false}
                                            options={filters.brands?.length > 0 ? filters.brands : []}
                                            placeholder={
                                                filters.brands?.length > 0
                                                    ? filters.brands
                                                    : 'Select your focus brands'
                                            }
                                            searchPlaceholder={'Search for brand'}
                                            onChange={(e) => {
                                                props.unselectBrand(e);
                                            }}
                                            productSet={props.productSet}
                                            searchOnChange={(value, status) => props.changeBrand(value, status)}
                                            searchValue={
                                                filters.brands?.length > 0 ? filters.brands : ''
                                            }
                                            searchType={
                                                props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'
                                            }
                                            //productType={props.productType}
                                            // multiBrandSelect={true}
                                        />
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-categories">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Category(s):{' '}
                                        </div>
                                        <div>
                                            {/* @ts-ignore */}
                                            <ByzzerMultiSelect
                                                selectedOptions={filters.categories}
                                                name={'competitive-productset'}
                                                placeholder={'Select from the list'}
                                                multiSelectWithCheckBox
                                                onChange={(e) => props.changeCategory(e)}
                                                options={props.categories || []}
                                                seperator={','}
                                                inlineFontStyle="none"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className="my-scorecard-opportunity__subclass-tabcontent-selection-market-type">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Market Type:
                                        </div>
                                        <div >
                                            {/* @ts-ignore */}
                                            <LegacyByzzerSelect
                                                allowClear={true}
                                                placeholder={'Select Market Type'}
                                                options={marketTypes}
                                                onChange={(e) => props.onMarketTypeChange(e)}
                                                value={filters.marketTypes}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-market">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Market Search:{' '}
                                        </div>
                                        <div >
                                            {/* @ts-ignore */}
                                            <ByzzerSearchableSelect
                                                options={
                                                    filters.markets?.length > 0 ? filters.markets : []
                                                }
                                                placeholder={
                                                    filters.marketTypes
                                                        ? 'Please Select Market'
                                                        : (filters.markets?.length > 0
                                                            ? filters.markets
                                                            : 'All')
                                                }
                                                searchPlaceholder={'Search for Market'}
                                                onChange={(e, type = null) => {
                                                    props.changeMarket(e, type);
                                                }}
                                                searchOnChange={(value, status) => {
                                                    props.changeMarketSearch(value, status);
                                                }}
                                                searchType={'marketSearch'}
                                                searchValue={
                                                    filters.markets?.length > 0 ? filters.markets : ''
                                                }
                                                categories={filters.categories}
                                                marketType={filters.marketTypes}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* <ByzzerButton label={'Submit'} onClick={fetchDataByMarket} disabled={!searchDisabled} /> */}
                                {marketTableData.length > 0 ? (
                                        // @ts-ignore {/* @ts-ignore */}
                                        <OpportunityTable
                                            filterItemData={filterItemData}
                                            sortMode={sortMode}
                                            filtertableData={(val, bool, type, mode) =>
                                                filtertableData(val, bool, type, mode)
                                            }
                                            tableData={marketTableData}
                                            type={'Markets'}
                                            onScoreClick={(grade, item, type) => handleScoreClick(grade, item, type)}
                                            filterItem={filterItem}
                                            
                                        />
                                ) : (
                                    <div className="my-scorecard-opportunity__subclass-value"></div>
                                )}
                        </>
                    )}
                    {activeTab === 'categories' && (
                        <>
                            {/* <div className='my-scorecard-opportunity__subclass-tab-section-grade'>
                                <ByzzerSelectOverallGrade type={'gradeInfo'} value={'Overall Score:'} grade={overallGradeByCategory}
                                    options={'Your overall grade is based on performance across 4 drivers: Promotion, Pricing, Assortment and New Product launches. Update the selections to see any brand performance for a market(s) or category. Next, compare markets and categories and then assess your score for the levers for each driver to find your opportunities.'}
                                    readonly />
                            </div> */}
                                <div className="my-scorecard-opportunity__subclass-tabcontent-selection">
                                    <div>
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Brand:{' '}
                                        </div>
                                        {/* <ByzzerSearch
                                            placeholder={'Search for Brand'}
                                            onChange={(e) => {
                                                props.changeBrand(e);
                                            }}
                                            value={filters.brands}
                                            categories={props.categInFocusBrand}
                                            type={'brandSearch'}
                                        /> */}
                                        {/* @ts-ignore */}
                                        <ByzzerSearchableSelect
                                            seperator={','}
                                            enableToolTip={filters.brands?.length > 0 ? true : false}
                                            options={filters.brands?.length > 0 ? filters.brands : []}
                                            placeholder={
                                                filters.brands?.length > 0
                                                    ? filters.brands
                                                    : 'Select your focus brands'
                                            }
                                            searchPlaceholder={'Search for brand'}
                                            onChange={(e) => {
                                                props.unselectBrand(e);
                                            }}
                                            productSet={props.productSet}
                                            searchOnChange={(value, status) => props.changeBrand(value, status)}
                                            searchValue={
                                                filters.brands?.length > 0 ? filters.brands : ''
                                            }
                                            searchType={
                                                props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'
                                            }
                                            //productType={props.productType}
                                            // multiBrandSelect={true}
                                        />
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-categories">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Category(s):{' '}
                                        </div>
                                        <div>
                                            {/* @ts-ignore */}
                                            <ByzzerMultiSelect
                                                selectedOptions={filters.categories}
                                                name={'competitive-productset'}
                                                placeholder={'Select from the list'}
                                                multiSelectWithCheckBox
                                                onChange={(e) => props.changeCategory(e)}
                                                options={props.categories || []}
                                                seperator={','}
                                                inlineFontStyle="none"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-market">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Market Search:{' '}
                                        </div>
                                        {/* @ts-ignore */}
                                        <ByzzerSearchableSelect
                                            options={
                                                filters.markets?.length > 0 ? filters.markets : []
                                            }
                                            placeholder={
                                                filters.markets?.length > 0 ? filters.markets : 'All'
                                            }
                                            searchPlaceholder={'Search for Market'}
                                            onChange={(e, type = null) => {
                                                props.changeMarket(e, type);
                                            }}
                                            searchOnChange={(value) => {
                                                props.changeSingleMarketSearch(value);
                                            }}
                                            searchType={'marketSingleSearch'}
                                            categories={filters.categories}
                                        />
                                    </div>
                                </div>
                                {categoriesTableData.length > 0 ? (
                                        // @ts-ignore
                                        <OpportunityTable
                                            filterItemData={filterItemData}
                                            sortMode={sortMode}
                                            filtertableData={(val, bool, type, mode) =>
                                                filtertableData(val, bool, type, mode)
                                            }
                                            tableData={categoriesTableData}
                                            type={'Categories'}
                                            onScoreClick={(grade, item, type) => handleScoreClick(grade, item, type)}
                                            filterItem={filterItem}
                                        />
                                ) : (
                                    <div className="my-scorecard-opportunity__subclass-value"></div>
                                )}
                        </>
                    )}
                    {activeTab === 'brands' && (
                        <>
                                <div className="my-scorecard-opportunity__subclass-tabcontent-selection">
                                    <div>
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Brand:{' '}
                                        </div>
                                        {/* @ts-ignore */}
                                        <ByzzerSearchableSelect
                                            seperator={','}
                                            enableToolTip={filters.brands?.length > 0 ? true : false}
                                            options={filters.brands?.length > 0 ? filters.brands : []}
                                            placeholder={
                                                filters.brands?.length > 0
                                                    ? filters.brands
                                                    : 'Select your focus brands'
                                            }
                                            searchPlaceholder={'Search for brand'}
                                            onChange={(e) => {
                                                props.unselectBrand(e);
                                            }}
                                            productSet={props.productSet}
                                            searchOnChange={(value, status) => props.changeBrand(value, status)}
                                            searchValue={
                                                filters.brands?.length > 0 ? filters.brands : ''
                                            }
                                            searchType={
                                                props.showAll ? 'brandSearchMultiSelect' : 'searchBrandForProductSetMultiSelect'
                                            }
                                            //productType={props.productType}
                                            // multiBrandSelect={true}
                                        />
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-categories">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Category(s):{' '}
                                        </div>
                                        <div>
                                            {/* @ts-ignore */}
                                            <ByzzerMultiSelect
                                                selectedOptions={filters.categories}
                                                name={'competitive-productset'}
                                                placeholder={'Select from the list'}
                                                multiSelectWithCheckBox
                                                onChange={(e) => props.changeCategory(e)}
                                                options={props.categories || []}
                                                seperator={','}
                                                inlineFontStyle="none"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-scorecard-opportunity__subclass-tabcontent-selection-market">
                                        <div className="my-scorecard-opportunity__subclass-tabcontent-selection-title">
                                            Market Search:{' '}
                                        </div>
                                        {/* @ts-ignore */}
                                        <ByzzerSearchableSelect
                                            options={
                                                filters.markets?.length > 0 ? filters.markets : []
                                            }
                                            placeholder={
                                                filters.markets?.length > 0 ? filters.markets : 'All'
                                            }
                                            searchPlaceholder={'Search for Market'}
                                            onChange={(e, type = null) => {
                                                props.changeMarket(e, type);
                                            }}
                                            searchOnChange={(value) => {
                                                props.changeSingleMarketSearch(value);
                                            }}
                                            searchType={'marketSingleSearch'}
                                            categories={filters.categories}
                                        />
                                    </div>
                                </div>
                                {brandsTableData.length > 0 ? (
                                    <>
                                        {/* @ts-ignore */}
                                        <OpportunityTable
                                            filterItemData={filterItemData}
                                            sortMode={sortMode}
                                            filtertableData={(val, bool, type, mode) =>
                                                filtertableData(val, bool, type, mode)
                                            }
                                            tableData={brandsTableData}
                                            type={'Brands'}
                                            onScoreClick={(grade, item, type) => handleScoreClick(grade, item, type)}
                                            filterItem={filterItem}
                                            handleScroll={handleScroll}
                                        />{' '}
                                    </>
                                ) : (
                                    <div className="my-scorecard-opportunity__subclass-value"></div>
                                )}
                        </>
                    )}
            </div>
            <ByzzerModal
                show={scoreInfoModal}
                onClose={() => setScoreInfoModal(!scoreInfoModal)}
                closeOnClickOutside={true}
                size={'medium'}
                //heading={driverList[infoItem].name}
                type={'info'}
                headerType={'none'}
            >
                <div className={'my-scorecard__subclass-title-link-info'}>
                    <div className={'my-scorecard__subclass-title-link-info-description'}>
                        Your score is based on your brand’s performance within the category and market. It is relative
                        to all other brands within the same category, and selling in the same market. The same scale is
                        used to score your performance at the overall level, all the way down to the individual actions.
                        Your score is on a scale from 100-600, with the highest brand in the category receiving a 600
                        and the lowest receiving a 100.
                    </div>
                    <div className={'my-scorecard__subclass-title-link-info-image'}>
                        <InlineSVG src={ScoreInfoImage}/>
                    </div>
                </div>
            </ByzzerModal>
        </div>
    );
}

export default FunctionInsightScores;
