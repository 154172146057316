import React from 'react';
import classnames from "classnames";

/**
 * @deprecated - This is deprecated and should not be used.  Use the ByzzerRadio component from our UI library instead
 */
export function LegacyByzzerCheckbox({ children, label = children, checked, name, value, onChange, className, disabled }) {

    const handleChange = (e) => {

        onChange?.(e, value);
    }
    return (
        <label className={classnames(`byzzer-checkbox`, className, {
            'byzzer-checkbox--disabled': disabled
        })}>
            <input type={'checkbox'} onChange={handleChange} name={name} value={value} disabled={disabled} checked={checked} />
            <div className={'byzzer-checkbox__toggle'} />
            {label && <span className={'byzzer-checkbox__label'}>{label}</span>}
        </label>
    )

}

export default LegacyByzzerCheckbox;
