//Component needs to be refactored later
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductCodingRequest.scss';
import classNames from 'classnames';
import infoIcon from '@images/icons/InformationIcon.svg';
import closeIcon from '@images/icons/close_icon.svg';
import { LegacyByzzerSearch, LegacyByzzerSelect, ByzzerInput, LegacyByzzerButton, ByzzerModal, ByzzerLink } from '@/components/form';
import InlineSVG from 'react-inlinesvg';
import {useTenantApi} from '@/hooks/useTenantApi';
import ProductCodingMoreInfo from './ProductCodingMoreInfo';
import ProductScopeNew from '@/components/ProductScopeNew';
import { useClickOutsideHandler } from '@/utils/utils';
import DashboardContent from '@/components/dashboard/DashboardContent';
import { ByzzerMask } from '@/components/ByzzerMask/ByzzerMask';
import { TrackClick } from '@/analytics';
import { Tooltip } from 'antd';
import { additionalDetailsPlaceholder } from '@/config/productCodingFormConfig';
import ByzzerUPCSearch from '@/components/UPC/UPCSearch/ByzzerUPCSearch';

export function ProductCodingInfo({ onComplete }) {
    const { lookup, search, submitUpcCodingRequest } = useTenantApi();
    const ref = useRef();
    const navigate = useNavigate();
    const [upcData, setUpcData] = useState({ upcs: [], categories: [], subcategories: [], brands: [] });
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [subCategoryValue, setSubCategoryValue] = useState();
    const [brand, setBrand] = useState([]);
    const [additionalDetails, setAdditionDetails] = useState('');
    const [upcCheckInfo, setUpcCheckInfo] = useState(false);
    const [instructions, setInstructions] = useState(false);
    const [categorySelector, setCategorySelector] = useState(false);
    const [upcCode, setUpcCode] = useState('00000000000');
    const [upcs, setUpcs] = useState([]);
    const [upcError, setUpcError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [displaySubmissionError, setDisplaySubmissionError] = useState(false);
    const [selectedImagesName, setSelectedImagesName] = useState([]);
    const [selectedImagesMimeType, setSelectedImagesMimeType] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [formEnabled, setFormEnabled] = useState(false);
    // creating this new state to hold values compatible with new ByzzerUPCSearch
    const [upcDetails, setUpcDetails] = useState([]);

    const mainClasses = classNames('product-coding-request');
    const headerClasses = classNames('product-coding-request__header');
    const detailsClasses = classNames('product-coding-request__details');
    const dataClasses = classNames('product-coding-request__data');

    useEffect(() => {
        activateSubmit();
    }, [upcs, category, subCategoryValue, brand]);

    useEffect(() => {
        if (upcs.length > 0) setFormEnabled(true);
        else setFormEnabled(false);
    }, [upcs]);

    useClickOutsideHandler(ref, () => {
        setUpcError(false);
    });

    const handleUPCChange = async (e) => {
        const upcValue = e.value;
        setUpcDetails(JSON.parse(JSON.stringify(upcValue)));

        if (upcValue.length === 1) {
            changeCategory(upcValue[0].data.category);
            setSubCategory(upcValue[0].data.sub_category);
            setSubCategoryValue(upcValue[0].data.sub_category);
            changeBrand(upcValue[0].data.brand);
        } else {
            changeCategory('');
            setSubCategory('');
            setSubCategoryValue('');
            changeBrand('');
        }

        let currentUpc = upcValue?.map((value) => value.data.upc) ?? [];
        let currentUpcCategories = upcValue?.map((value) => value.data.category) ?? [];
        let currentUpcSubcategories = upcValue?.map((value) => value.data.sub_category) ?? [];
        let currentUpcBrands = upcValue?.map((value) => value.data.brand) ?? [];

        setUpcs(currentUpc);
        const newUpcData = {
            upcs: JSON.parse(JSON.stringify(currentUpc)),
            categories: JSON.parse(JSON.stringify(currentUpcCategories)),
            subcategories: JSON.parse(JSON.stringify(currentUpcSubcategories)),
            brands: JSON.parse(JSON.stringify(currentUpcBrands)),
        };
        setUpcData(newUpcData);
    };

    const onCategorySelected = async (category) => {
        changeCategory(category[0]);
        setCategorySelector(false);
    };

    const changeCategory = async (category) => {
        setCategory(category);
        setSubCategoryValue('');
        if (category !== undefined) {
            let res = await lookup({ hierarchy: `category`, value: category });
            let subCategory = res.data?.subcategories;
            let subCategory1 = [];
            subCategory.forEach((category) => {
                subCategory1.push({ display: category.name, value: category.name });
            });
            setSubCategory(subCategory1);
        }
    };

    const onChangeCategory = async (e) => {
        setSubCategoryValue('');
        if (e.openElasticSearch) setCategorySelector(true);
    };

    const changeSubCategory = (subCategory) => {
        setSubCategoryValue(subCategory);
    };

    const changeBrand = async (brand) => {
        setBrand(brand);
    };

    const handleAdditionDetails = (e) => {
        setAdditionDetails(e.target.value);
    };

    async function activateSubmit() {
        if (
            formEnabled &&
            upcs?.length > 0 &&
            category.length > 0 &&
            subCategoryValue !== undefined &&
            subCategoryValue?.length > 0 &&
            brand?.length > 0
        ) {
            setSaveEnabled(true);
        } else {
            setSaveEnabled(false);
        }
    }

    async function handleSubmitProductCodingRequest() {
        const upcInfo = {
            upcs: upcs,
            existingCategories: upcData.categories ? upcData.categories : null,
            existingSubcategories: upcData.subcategories ? upcData.subcategories : null,
            existingBrands: upcData.brands ? upcData.brands : null,
            requestedCategory: category,
            requestedSubcategory: subCategoryValue,
            requestedBrand: brand,
            additionalDetails: additionalDetails,
        };
        try {
            setLoading(true);
            const result = await submitUpcCodingRequest(upcInfo);
            if (result) {
                if (selectedImagesName.length > 0) {
                    let imageUploaded = 0;
                    for (let i = 0; i < selectedImagesName.length; i++) {
                        await fetch(result.itemImageUploadUrl[i], {
                            method: 'PUT',
                            headers: { 'Content-Type': selectedImagesMimeType[i] },
                            body: selectedImages[i],
                        })
                            .then(() => {
                                imageUploaded++;
                            })
                            .catch((error) => {
                                console.log(error, 'err');
                            });
                    }
                    if (imageUploaded === selectedImagesName.length) {
                        setLoading(false);
                        await onComplete('info');
                    } else {
                        setLoading(false);
                        setDisplaySubmissionError(true);
                    }
                } else {
                    setLoading(false);
                    await onComplete('info');
                }
            }
        } catch (err) {
            setLoading(false);
            setDisplaySubmissionError(true);
        }
    }

    function enableSubmissionErrorModal() {
        setDisplaySubmissionError(false);
    }

    const removeUpc = (index) => {
        let currentUpcs = upcs;
        let currentUpc = upcData.upcs;
        let currentUpcCategories = upcData.categories;
        let currentUpcSubcategories = upcData.subcategories;
        let currentUpcBrands = upcData.brands;
        currentUpcs.splice(index, 1);
        currentUpc.splice(index, 1);
        currentUpcCategories.splice(index, 1);
        currentUpcSubcategories.splice(index, 1);
        currentUpcBrands.splice(index, 1);
        const newUpcData = {
            upcs: JSON.parse(JSON.stringify(currentUpc)),
            categories: JSON.parse(JSON.stringify(currentUpcCategories)),
            subcategories: JSON.parse(JSON.stringify(currentUpcSubcategories)),
            brands: JSON.parse(JSON.stringify(currentUpcBrands)),
        };
        setUpcs(JSON.parse(JSON.stringify(currentUpcs)));
        setUpcData(newUpcData);
        if (index === 0) {
            changeCategory('');
            setSubCategory('');
            setSubCategoryValue('');
            changeBrand('');
        }
    };

    const multipleValues = (data, types, type) => {
        if (data.every((val) => val === data[0])) {
            return data[0];
        } else
            return (
                <div className="product-coding-request__value">
                    <strong>Multiple Values</strong>
                    <div className="product-coding-request__tooltip">
                        <Tooltip
                            placement="bottom"
                            title={`You selected multiple UPCs in different ${types}. This request will move them all to the same ${type}.`}
                        >
                            <i className="product-coding-request__multipleValuesInfo">
                                <InlineSVG src={infoIcon} />
                            </i>
                        </Tooltip>
                    </div>
                </div>
            );
    };

    return (
        <DashboardContent
            title={'Product Coding Form'}
            extras={<LegacyByzzerButton onClick={() => navigate(-1)}>Cancel</LegacyByzzerButton>}
        >
            <div className={mainClasses}>
                <ByzzerMask loading={loading} />
                <div className={headerClasses}>
                    <div className="product-coding-request__header-subtitle">
                        <span>
                            Use this form to submit a request to move UPCs to different <strong>Category</strong>,{' '}
                            <strong>Sub-Category</strong>, or <strong>Brand</strong> placement.
                        </span>
                    </div>
                    <div className="product-coding-request__header-subtitle">
                        To get a new UPC coded, reach out to New Product Coding to complete a new product coding
                        request.
                        <span className="product-coding-request__details-search-error-icon">
                            {/* <ByzzerLink label="Instructions" onClick={() => {
                                        setInstructions(!instructions);
                                    }}/> */}
                            {/* <i>
                                <InlineSVG
                                    src={infoIcon}
                                    onClick={() => {
                                        setInstructions(!instructions);
                                    }}
                                />
                            </i> */}
                        </span>
                    </div>
                    <div className="product-coding-request__header-subtitle">
                        To change other characteristics like Size, or to assign items to a new Brand or Manufacturer
                        that isn't yet coded, send your request through the Byzzer chat or to{' '}
                        <span className="product-coding-request__header-subtitle-email">
                            byzzer.support@smb.nielseniq.com
                        </span>
                        .
                    </div>
                </div>
                <div className={detailsClasses}>
                    <div className="product-coding-request__details-upc">Search for UPCs to update placement</div>
                    {/* <div className="product-coding-request__upcs">
                        {upcs.map((upc, index) => (
                            <div className="product-coding-request__upcs-selected" key={index}>
                                {upc}
                                <img className="product-coding-request__upcs-remove" src={closeIcon} alt={'X'} onClick={() => { removeUpc(index); }} />
                            </div>
                        ))}
                    </div> */}
                    <div className="product-coding-request__details-search">
                        {/* <ByzzerSearch
                                onChange={(e) => {
                                    handleUpcSearch(e);
                                }}
                                value={ '' }
                                upcs={ upcs }
                                type={'upcCodeMultiSelect'}
                                placeholder={'Search for UPC'}
                                disabled={false}

                            /> */}
                        <ByzzerUPCSearch onChange={handleUPCChange} placeholder="Search for UPC" value={upcDetails} />
                        {upcError && (
                            <div className="product-coding-request__details-search-error" ref={ref}>
                                <div className="product-coding-request__details-search-error-title">
                                    <span>
                                        UPC not found. &nbsp; For new product coding, reach out to New Product Coding
                                        using the instructions above.
                                    </span>
                                </div>
                                <div className="product-coding-request__details-search-error-description">
                                    If your UPC includes a check-digit, try deleting it.
                                    <span className="product-coding-request__details-search-error-icon">
                                        <i>
                                            <InlineSVG
                                                src={infoIcon}
                                                onClick={() => {
                                                    setUpcCheckInfo(!upcCheckInfo);
                                                }}
                                            />
                                        </i>
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={dataClasses}>
                    <div className="product-coding-request__data-headers">
                        <div className="product-coding-request__data-headers-empty"></div>
                        <div className="product-coding-request__data-headers-title">
                            {' '}
                            {upcs.length > 0 ? 'Current Coding' : ''}
                        </div>
                        <div className="product-coding-request__data-headers-title"> Requested Changes </div>
                    </div>
                    <div className="product-coding-request__data-seperator"></div>
                    <div className="product-coding-request__data-item">
                        <div className="product-coding-request__data-item-title">Category</div>
                        <div className="product-coding-request__data-item-title">Sub-Category</div>
                        <div className="product-coding-request__data-item-title">Brand</div>
                        <div className="product-coding-request__data-item-value">
                            {upcData.categories.length > 1
                                ? multipleValues(upcData.categories, 'categories', 'Category')
                                : upcData.categories[0] || ''}
                        </div>
                        <div className="product-coding-request__data-item-value">
                            {upcData.subcategories.length > 1
                                ? multipleValues(upcData.subcategories, 'sub-categories', 'Sub-Category')
                                : upcData.subcategories[0] || ''}
                        </div>
                        <div className="product-coding-request__data-item-value">
                            {upcData.brands.length > 1
                                ? multipleValues(upcData.brands, 'brands', 'Brand')
                                : upcData.brands[0] || ''}
                        </div>
                        <div className="product-coding-request__data-item-component">
                            <LegacyByzzerSearch
                                onChange={(e) => {
                                    onChangeCategory(e);
                                }}
                                value={category.length === 0 ? category[0] : category}
                                //categories={category}
                                type={'elasticSearch'}
                                placeholder={'Search for Category'}
                                disabled={!formEnabled}
                                readOnly={true}
                            />
                        </div>
                        <div className="product-coding-request__data-item-component">
                            <LegacyByzzerSelect
                                options={subCategory}
                                onChange={(e) => {
                                    changeSubCategory(e);
                                }}
                                placeholder={'Select Sub-Category'}
                                value={subCategoryValue}
                                disabled={!formEnabled}
                            />
                        </div>
                        <div className="product-coding-request__data-item-component">
                            <LegacyByzzerSearch
                                placeholder={'Search for Brand'}
                                onChange={(e) => {
                                    changeBrand(e);
                                }}
                                value={brand.length === 0 ? brand[0] : brand}
                                categories={[]}
                                type={'brandSearch'}
                                disabled={!formEnabled}
                            />
                        </div>
                    </div>
                </div>
                <div className="product-coding-request__additional-details">
                    <div className="product-coding-request__additional-details-title">Additional Details</div>
                    <div className="product-coding-request__additional-details-description">
                        Provide details so we can more quickly complete your address. For example, let us know if the
                        current brand values are too granular for your reporting needs, or there is a certain competitor
                        you are targeting by changing categories.
                    </div>
                    <textarea
                        disabled={!formEnabled}
                        onChange={handleAdditionDetails}
                        value={additionalDetails}
                        placeholder={additionalDetailsPlaceholder}
                        className={'product-coding-request__additional-details-inputbox'}
                    />
                </div>

                <div className="product-coding-request__submit">
                    <TrackClick name={`Prod Coding Submit clicked`}>
                        <LegacyByzzerButton
                            label="Submit"
                            disabled={!saveEnabled}
                            onClick={() => {
                                handleSubmitProductCodingRequest();
                            }}
                        />
                    </TrackClick>
                </div>
                <ByzzerModal
                    show={categorySelector}
                    onClose={() => setCategorySelector(!categorySelector)}
                    size={'large'}
                    headerType={'none'}
                >
                    <ProductScopeNew
                        productScopeTitle={'Product Coding Form'}
                        productScopeSubtitle={
                            'Enter a few details below to submit a request to add or change a product’s reference data.'
                        }
                        updateProductScope={(data) => onCategorySelected(data)}
                        maxCategoryCount={1}
                        errorMessage={'Unselect the previously selected category.'}
                        submitButtonLabel={'Select Category'}
                        displayTreeButtonLabel={'Select Category'}
                        productScopeType={'Product Coding Form'}
                    />
                </ByzzerModal>
                <ByzzerModal
                    show={upcCheckInfo}
                    onClose={() => {
                        setUpcCheckInfo(!upcCheckInfo);
                        setUpcError(true);
                    }}
                    closeOnClickOutside={true}
                    size={'extra-small'}
                    headerType={'none'}
                >
                    <ProductCodingMoreInfo type={'upcCheckingInfo'} />
                </ByzzerModal>

                <ByzzerModal
                    show={instructions}
                    onClose={() => {
                        setInstructions(!instructions);
                    }}
                    closeOnClickOutside={true}
                    size={'medium'}
                    headerType={'none'}
                >
                    <ProductCodingMoreInfo type={'instructions'} />
                </ByzzerModal>

                {displaySubmissionError && (
                    <ByzzerModal
                        show={displaySubmissionError}
                        heading={'An unexpected error occurred'}
                        onClick={() => enableSubmissionErrorModal(false)}
                    >
                        <label className="byzzer-label">
                            {
                                'Sorry for the inconvenience! Reach out to customer service through our chat or by email to complete your request.'
                            }
                        </label>
                    </ByzzerModal>
                )}
            </div>
        </DashboardContent>
    );
}
