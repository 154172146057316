import './PendingSubscription.scss';
import React, {useState} from 'react';
import classnames from 'classnames';
import {DashboardNav} from "@/components/dashboard";
import DashboardContent from "@/components/dashboard/DashboardContent";
import {LegacyByzzerButton} from "@/components/form";
import DashboardFooter from "../../components/dashboard/DashboardFooter";
import {ByzzerMask} from "@/components/ByzzerMask/ByzzerMask";
import DashboardHeader from '@/components/dashboard/DashboardHeader';


const baseClassName = 'pending-subscription'
export default function PendingSubscription({className, ...props}) {

    return (
        <div className="dashboard">
            <DashboardNav disabled={true}/>
            <DashboardContent title={'Preparing Your Subscription'} className={classnames(baseClassName, className)}>
                <ByzzerMask show={true} loading={true}>
                    Give Us A Moment To Finish Preparing Your Subscription
                </ByzzerMask>
            </DashboardContent>
            <DashboardHeader disabled={true}/>
        </div>
    );
}