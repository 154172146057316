import './SignUp.scss';
import React, {useEffect, useMemo, useState} from 'react';
import {useTenantApi} from "@/hooks/useTenantApi";
import {getSignUpAttribution} from "@/components/SiteAnalytics";
import {alert, openErrorModal} from "@/components/form";
import {Link, useNavigate} from "react-router-dom";
import {CustomerSupportLink} from "@/components/CustomerSupportLink";
import {LegacyByzzerButton, LegacyByzzerCheckbox, ByzzerInput, ByzzerLink, LegacyByzzerSelect} from "@/components/form";
import {openTermsAndConditions} from "@/components/modals/TermsAndConditions";
import {SwitchToSignIn} from "@/components/SwitchTo";
import classnames from "classnames";
import {clone} from "lodash";
import ByzzerPhoneInput from "@/components/form/ByzzerPhoneInput";
import {PhoneNumberUtil} from 'google-libphonenumber';
import {validate as isValidEmail} from 'email-validator';
import PasswordValidations, {findPasswordErrors} from "@/components/PasswordValidations/PasswordValidations";
import { useAccountService } from '@/hooks/useAccountService';
import {useTrackEvent} from "@/analytics/AnalyticsContext";
import { useSharedApi } from '@/hooks/useSharedApi';
import { CompanyOption, CompanyType } from '@/types/InvitationTypes';
import Benefits from './Benefits';
import { ByzzerLogo } from '@/components/ByzzerLogo';
import {parse} from 'qs';
import { ByzzerSelectOption } from '@byzzer/ui-components';

const phoneUtil = PhoneNumberUtil.getInstance();

const initialSignUpData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    verifyPassword: '',
    acceptedTcs: false,
    notRetailer: false,
    marketingEmailConsent: true
};

const baseClassName = 'signup';
const formClassName = 'signup-form';

/** User Signup page */
export function SignUp({step = 1}) {
    const {getReferenceData} = useTenantApi();
    const {signUp, validateEmail, findCompaniesByEmail, getIndustryOptions} = useSharedApi();

    const [busy, setBusy] = useState<boolean>(false);
    const [signUpData, setSignUpData] = useState(clone(initialSignUpData));
    const [matchingCompanies, setMatchingCompanies] = useState<CompanyOption[]>();
    const [companyOptions, setCompanyOptions] = useState<{
        display: string;
        value: number;
        data: CompanyOption;
    }[]>([]);
    const [companyTypeOptions, setCompanyTypeOptions] = useState<ByzzerSelectOption<any, string>[]>([]);
    const [companySelectorType, setCompanySelectorType] = useState<string>('');
    const [emailStatus, setEmailStatus] = useState<string>('');
    const [canUseEmailAddress, setCanUseEmailAddress] = useState<boolean>(true);
    const [company, setCompany] = useState<CompanyOption>();
    const [companyId, setCompanyId] = useState<number>();
    const [companyName, setCompanyName] = useState<string>('');
    const [companyType, setCompanyType] = useState<number>();
    const [invalidEmailCode, setInvalidEmailCode] = useState<string>('');
    const [industry, setIndustry] = useState<number>();
    const [industryTypeOptions, setIndustryTypeOptions] = useState<ByzzerSelectOption<any, string>[]>([]);
    const { login } = useAccountService();

    const [isExistingAccount, setIsExistingAccount] = useState<boolean>(false);
    const navigate = useNavigate();
    const trackEvent = useTrackEvent();
    let signupMailStatus;

    useEffect(() => {
        loadCompanyTypeOptions();
        loadIndustryTypeOptions()
    }, []);

    useEffect(() => {

        // if(companySelectorType === 'single') {
        setCompanyId(company?.id);
        setCompanyName(company?.name ?? '');
        setCompanyType(company?.type);
        // } else {
        //     setCompanyId();
        //     setCompany();
        //     setCompanyName();
        //     setCompanyType();
        // }
    }, [companySelectorType])

    useEffect(() => {
        setCompanyId(undefined);
        setIsExistingAccount(false);
        setCanUseEmailAddress(false);
        findMatchingCompanies();
    }, [signUpData.email]);

    const showIndustrySelector = useMemo(() => !isExistingAccount  && !company?.type && companyType === CompanyType.CPG_MANUFACTURER_OR_BRAND, [isExistingAccount, companyType]); 
    const filteredIndustryTypeOptions = useMemo(() => {        
        return companyType === CompanyType.CPG_MANUFACTURER_OR_BRAND
        ? industryTypeOptions.filter(option => !['financial_services', 'other', 'packaging'].includes(option.display.toLowerCase().replace(' ', '_')))
        : industryTypeOptions;
    }, [companyType]);

    async function loadCompanyTypeOptions() {

        const options = await getReferenceData('company_type');
        setCompanyTypeOptions(options.sort((a, b) => a.display.localeCompare(b.display)));
    }

    async function loadIndustryTypeOptions() {

        const options = await getIndustryOptions();
        setIndustryTypeOptions(options.sort((a, b) => a.display.localeCompare(b.display)));
    }



    function onDisabledClick() {
        const {password, verifyPassword, firstName, lastName, email, acceptedTcs, notRetailer} = signUpData;
        const errors: string[] = [];

        if (!firstName.trim()) {
            errors.push(`You did not enter your first name.`);
        }

        if (!lastName.trim()) {
            errors.push(`You did not enter your last name.`);
        }

        // Validate Email
        if (!isValidEmail(email)) {
            errors.push(`The email address you entered is not a valid email address.`);
        } else if (!canUseEmailAddress) {
            switch (invalidEmailCode) {
                case 'duplicate':
                    errors.push('The email address you entered is already associated with an exising Byzzer account.');
                    break;
                case 'domain_restricted.competitor':
                    errors.push(`The email address you entered is using a domain that Byzzer does not support.`);
                    break;
                case 'domain_restricted.generic':
                    errors.push(`The email address you entered doesn't seem to be a work address.`);
                    break;
                default:
                    errors.push(`There is an issue with the email address you entered. Reach out to the chat in the bottom-right corner of the screen for help.`);
                    break;
            }
        }

        if (!isValidPhoneNumber(signUpData.phone)) {
            errors.push('Double check your phone number.  It must be a valid US or international number.')
        }

        // Validate Password
        if (findPasswordErrors(password)) {
            errors.push('The password you chose is not valid. Be sure to check the password criteria.')
        }

        if (password !== verifyPassword) {
            errors.push('The "Password" and "Verify Password" must match.')
        }

        if (!companyName?.trim()) {
            errors.push(`You did not enter a company name.`);
        }

        // Validate company type
        if (!companyType) {
            errors.push('You have to choose a type for your company.')
        }

        if (!acceptedTcs || !notRetailer) {
            errors.push(`You must check both options at the end of the form to create an account.`);
        }

        let titleSuffix;
        switch(errors.length) {
            case 1:
                titleSuffix = 'An Issue';
                break;
            case 2:
                titleSuffix = 'A Couple Of Issues';
                break;
            default:
                titleSuffix = 'A Few Issues';
        }
        alert({
            type: 'error',
            className: `${formClassName}-error-pop`,
            title: `We Found ${titleSuffix}`,
            content: <ul className={`${formClassName}-error-pop__errors`}>
                {errors.map(error => <li className={`${formClassName}-error-pop__error`}>{error}</li>)}
            </ul>
        })
    }

    async function onSignUpClick() {
        if (isExistingAccount) {
            navigate('sign_in', {replace: true}); return;
        }
        if (busy) return;
        try {
            setBusy(true);
            // attribution recording should never impact the sign in process so ignore errors if they occur

            // sign in for the first time hurray!
            // This will change route to verify page with authsate change event
            //const { email, password } = signUpData;
            const {email, password, firstName, lastName, phone, notRetailer, acceptedTcs, marketingEmailConsent} = signUpData;
            // const {name: companyName, id: companyId, type: companyType} = company;
            const attribData = getSignUpAttribution();
            
            const queryParams = new URLSearchParams(window.location.search);
            // TODO: Consolodate payloads into one object (email, name, etc)
            const signUpResult = await signUp({
                email,
                password,
                firstName,
                lastName,
                phone,
                companyName: companyName!,
                companyId: companyId!,
                companyType: companyType!,
                isRetailer: !notRetailer,
                acceptedTcs,
                utm_campaign: attribData?.utm_campaign || '',
                utm_source: attribData?.utm_source || '',
                utm_medium: attribData?.utm_medium || '',
                utm_content: attribData?.utm_content || '',
                utm_term: attribData?.utm_term || '',
                previous_page_url: queryParams.get('prevUrl') || '',
                page_url: window.location.href.replace(/\?.*?#/, '#').replace(/\?.*$/, ''),
                marketingEmailConsent: marketingEmailConsent,
                ...(showIndustrySelector ? {industry_type: industry} : {})
            });

            trackEvent({
                type: 'user_sign_up',
                name: 'user_sign_up',
                data: {
                    email,
                    firstName,
                    lastName,
                    phone,
                    companyName,
                    companyId,
                    companyType: companyType!,
                    isRetailer: !notRetailer,
                    acceptedTcs,
                    marketingEmailConsent,
                    ...(showIndustrySelector ? {industry} : {}),
                    ...signUpResult,
                }
            })

            // @ts-ignore
            if (await login({
                email,
                password,
            })) {
                navigate('/verify_email', {replace: true});
            };
        } catch (err) {
            await onSignUpError(err);
        } finally {
            /** Enable Create Account BTN for retry */
            setBusy(false);
        }
    }

    async function onSignUpError(err) {
        let errorModalConfig
        switch (err.code) {
            case "duplicate_email":
                errorModalConfig = {
                    title: `Invalid E-Mail address`,
                    content: (
                        <>
                            <p>Please Enter Valid E-Mail.</p>
                        </>
                    ),
                    errorId: err.id
                };
                break;
            case 'company_not_found':
                // todo: add support for choosing a different company
                errorModalConfig = {
                    title: `Well That's Not Good`,
                    content: (
                        <>
                            <p>Not sure how this happened, but the company you chose doesn't exist in our system.</p>
                        </>
                    ),
                    errorId: err.id
                };
                break;
            case 'invalid_password':
                // todo: add support for correcting the password and resubmitting
                errorModalConfig = {
                    title: `We Want To Pump You Up!`,
                    content: (
                        <>
                            <p>
                                Well maybe not you, but your password. The password you chose is too weak. Please make
                                sure you included at least one number, capital letter and a special character. Oh and it
                                has to be at least 12 characters long but no more than 21. Because who really needs more
                                than 21 characters in a password?
                            </p>
                        </>
                    ),
                };
                break;
            case 'invalid_phone_number':
                // todo: add support for correcting the phone number and resubmitting
                errorModalConfig = {
                    title: `We Want To Call You, Maybe`,
                    content: (
                        <>
                            <p>But we can't because the phone number you entered isn't one that we can understand.</p>
                        </>
                    ),
                };
                break;
            case 'domain_restricted.competitor':
                errorModalConfig = {
                    title: `We've Got Bad News`,
                    content: (
                        <>
                            <p>Sorry, we're limiting the companies that can sign up for Byzzer at this time.</p>
                            <p>If you'd like more information please contact customer service.</p>
                        </>
                    ),
                };
                break;
            case 'domain_restricted.generic':
                errorModalConfig = {
                    title: `Please Use Your Work Email`,
                    content: (
                        <>
                            <p>A company email is required to sign up for Byzzer.</p>
                            <p>Please use your work email address to continue.</p>
                            <p>If you have additional questions please contact customer service.</p>
                        </>
                    ),
                };
                break;
            case 'domain_mismatch':
                errorModalConfig = {
                    title: `Your Email Doesn't Match Your Company`,
                    content: (
                        <>
                            <p>
                            To keep your company's reports and data safe, we only allow accounts to be created with an email address that matches the company's domain.
                            </p>
                        </>
                    ),
                    errorId: err.id
                };
                break;
            default:
                errorModalConfig = {
                    title: `Uh Oh! Something Unexpected Happened`,
                    content: (
                        <>
                            <p>Fear not our engineering team is on the job.</p>
                        </>
                    ),
                    errorId: err.id
                };
        }

        return openErrorModal(errorModalConfig);
    }

    function hasInvalidValues(signUpData) {

        const {password, verifyPassword, firstName, lastName, email, acceptedTcs, notRetailer} = signUpData;

        // Validate Password
        if (findPasswordErrors(password) || password !== verifyPassword) {
            return true;
        }
        // Validate Mail
        if (!isValidEmail(email) || !canUseEmailAddress) {
            return true;
        }
        // Validate company type
        if (!companyType) {
            return true;
        }
        if (!isValidPhoneNumber(signUpData.phone)) {
            return true;
        }

        return (!companyName || !acceptedTcs || !notRetailer || !firstName.trim() || !lastName.trim())

    }

    async function processEmail() {
        setEmailStatus('Loading ...');
        if (isValidEmail(signUpData.email)) {
            const emailValidationResult = await validateEmail(signUpData.email);
            if (signUpData.email && !emailValidationResult.valid) {
                setMatchingCompanies(undefined);
                signupMailStatus = emailValidationResult.reason;
                setInvalidEmailCode(emailValidationResult.reason);
                handleEmailValidationFailure(emailValidationResult.reason);
                if (emailValidationResult?.reason === 'duplicate') {
                    setIsExistingAccount(true);
                    setCompanyName(emailValidationResult?.companyName);
                }
            } else {
                setInvalidEmailCode('');
                setEmailStatus('Valid Email');
                setCanUseEmailAddress(true);
                setCompanyName(company?.name ?? "");
            }
        } else {
            setEmailStatus('Invalid Email.');
            if (companySelectorType) {
                setCompanySelectorType('');
            }
            if (company) {
                setCompany(undefined);
            }
        }
    }

    function onInputChange({target}) {
        if (!target?.name) return;
        setSignUpData((state) => ({
            ...state,
            [target.name]: target.value?.trim() ?? '',
        }));
    }

    function onCheckboxChange({target}) {
        setSignUpData((state) => ({
            ...state,
            [target.name]: target.checked,
        }));
    }

    function handleEmailValidationFailure(reason) {
        switch (reason) {
            case 'duplicate':
                setEmailStatus('Already associated with a Byzzer account');
                break;
            case 'domain_restricted.competitor':
                setEmailStatus(`Email Domain Not Allowed`);
                break;
            case 'domain_restricted.generic':
                setEmailStatus('Please Use Your Work Email Address');
                break;
            case 'invited':
                setEmailStatus('Invited User');
                findMatchingCompanies();
                break;
            default:
                setEmailStatus('Invalid Email Address.');
                break;
        }
    }

    function handleEmailValidationFailureModel() {
        let error;
        switch (signupMailStatus) {
            case 'duplicate':
                error = {
                    title: `You're Already Signed Up`,
                    content: (
                        <>
                            <p>
                                The email address <b>{signUpData.email}</b> is already associated with a Byzzer account.
                                <br/>
                                <Link to={`../../sign_in`}>Try Signing In</Link>
                            </p>
                            <p>
                                Don't remember your password?
                                <br/>
                                <Link to={`/auth/forgot_password`}>Reset Your Password</Link>
                            </p>
                            <p>
                                Didn't create the account?
                                <br/>
                                {/* @ts-ignore */}
                                <CustomerSupportLink>Contact Support For Assistance</CustomerSupportLink>
                            </p>
                        </>
                    ),
                };
                break;
            case 'domain_restricted.competitor':
                error = {
                    title: `We've Got Bad News`,
                    content: (
                        <>
                            <p>Sorry, we're limiting the companies that can sign up for Byzzer at this time.</p>
                        </>
                    ),
                };
                break;
            case 'domain_restricted.generic':
                error = {
                    title: `Please Use Your Work Email Address`,
                    content: (
                        <>
                            <p>A company email is required to sign up for Byzzer.</p>
                        </>
                    ),
                };
                break;
            default:
                error = {
                    title: `Uh Oh!`,
                    content: (
                        <>
                            <p>It looks like you entered an invalid email address.</p>
                            <p>Please correct the address to continue.</p>
                        </>
                    ),
                };
                break;
        }
        openErrorModal(error);
    }

    async function findMatchingCompanies() {
        const companies = await findCompaniesByEmail(signUpData.email);
        setMatchingCompanies(companies);
        if (companies?.length === 0) {
            setCompany(undefined)
            setCompanySelectorType('new');
            setSignUpData(state => ({
                ...state,
                companyName: '',
            }));
        } else if (companies?.length === 1) {
            setCompany(companies[0]);
            setCompanySelectorType('single');
        } else if (companies) {
            setCompany(undefined);
            setCompanyOptions(
                companies.map((company) => ({
                    display: company?.name,
                    value: company.id,
                    data: company
                }))
            );
            setCompanySelectorType('multi');
        }
    }

    function onCompanyTypeSelection(selectedType: typeof companyType) {        
        setCompanyType(selectedType);

        if (selectedType !== CompanyType.CPG_MANUFACTURER_OR_BRAND) {
            setIndustry(undefined);
        }
    }

    function onIndustrySelection(value: typeof industry) {
        setIndustry(value);
    }

    function onCompanyNameChange({target}) {
        setCompanyName(target.value);
    }

    function onCompanySelection(id, {display, value, data}: typeof companyOptions[0]) {
        setCompanyId(id);
        setCompanyName(display);
        setCompanyType(data?.type);
    }

    function renderCompanySelector(type) {
        if (isExistingAccount) {
            // @ts-ignore
            return <ByzzerInput
            label="Company Name"
            className={`${formClassName}__input ${formClassName}__company-name`}
            name={'companyName'}
            value={companyName}
            disabled={true}
            validate={false}
        />;
        } else {
            switch (type) {
                case 'single':
                    return <div className={`${formClassName}__static-value ${formClassName}__company-name`}>
                        {companyName}
                    </div>;
                case 'multi':
                    // @ts-ignore
                    return <LegacyByzzerSelect
                        className={`${formClassName}__input ${formClassName}__company-name`}
                        placeholder={'Choose Your Company'}
                        onChange={onCompanySelection}
                        value={companyId}
                        options={companyOptions}
                        variant={'secondary'}
                    />
                default: // @ts-ignore
                    return <ByzzerInput
                        label="Company Name"
                        className={`${formClassName}__input ${formClassName}__company-name`}
                        name={'companyName'}
                        value={companyName}
                        disabled={!isValidEmail(signUpData.email)}
                        validate={false}
                        onChange={onCompanyNameChange}
                        placeholder={'Your company name'}
                        required
                        variant={'secondary'}
                    />
            }
        }
    }

    function renderTypeSelector(type) {
        if (type) {
            return <></>;
        } else { // @ts-ignore
            return <LegacyByzzerSelect
                label="What type of company are you?"
                className={`${formClassName}__input ${formClassName}__company-type`}
                placeholder={'Select Your Company'}
                onChange={onCompanyTypeSelection}
                value={companyType}
                options={companyTypeOptions}
                variant={'secondary'}
            />
        }
    }

    const renderVerifyPasswordValidator = () => {
        const passwordMatch = signUpData.password && signUpData.password === signUpData.verifyPassword;
        return (
            <div className={'validations'}>
                <Validation className={'validation'} valid={passwordMatch}>Passwords Match</Validation>
            </div>
        );
    };

    /**
     *
     * @param {string} strNumber Input Mobile Number as string
     * @returns
     */
    const isValidPhoneNumber = (rawValue) => {
        try {
            const phoneNumber = phoneUtil.parse(rawValue);
            const isValid = phoneUtil.isValidNumber(phoneNumber);
            return isValid;
        } catch (err) {
            return false;
        }
    };

    function renderPhoneValidator() {
        const isValid = isValidPhoneNumber(signUpData.phone);
        return (
            <div className={`validations`}>             {/*  @ts-ignore */}
                 {signUpData.phone.trim().length > 0 && <Validation valid={isValid}>Valid Number</Validation>}
            </div>
        );
    }

    return (
        <div className={`${baseClassName}`}>
            <div className={`${baseClassName}-section`}>
                <Benefits baseClassName={baseClassName} />
                <div className={`${formClassName}`}>
                    <ByzzerLogo />
                    <div className={`${formClassName}__wrapper`}>
                        <header className={`${formClassName}__header`}>
                            <h1 className={`${formClassName}__title`}>Get started today</h1>
                            {/* @ts-ignore */}
                            {!isExistingAccount && <SwitchToSignIn />}
                        </header>
                        <main className={`${formClassName}__content`}>
                            {/* @ts-ignore */}
                            <ByzzerInput
                                className={`${formClassName}__input ${formClassName}__firstname`}
                                label="First Name"
                                name={'firstName'}
                                value={signUpData.firstName}
                                validate={false}
                                onChange={onInputChange}
                                placeholder={'John'}
                                required
                                variant={'secondary'}
                            />
                            {/* @ts-ignore */}
                            <ByzzerInput
                                className={`${formClassName}__input ${formClassName}__lastname`}
                                label="Last Name"
                                name={'lastName'}
                                value={signUpData.lastName}
                                validate={false}
                                onChange={onInputChange}
                                placeholder={'Doe'}
                                required
                                variant={'secondary'}
                            />
                            <div className={`${formClassName}__input-group ${formClassName}__email`}>
                                {/* @ts-ignore */}
                                <ByzzerInput
                                    className={`${formClassName}__input`}
                                    label="Work Email Address"
                                    name={'email'}
                                    value={signUpData.email}
                                    validate={false}
                                    onChange={onInputChange}
                                    onBlur={processEmail}
                                    type={'email'}
                                    placeholder={'john.doe@company.com'}
                                    required
                                    variant={'secondary'}
                                />
                                {!isExistingAccount && (
                                    <div className={'validations'}>
                                        {/* @ts-ignore */}
                                        <Validation valid={canUseEmailAddress && isValidEmail(signUpData.email)}>
                                            {emailStatus}
                                        </Validation>
                                    </div>
                                )}
                            </div>
                            <div className={`${formClassName}__input-group ${formClassName}__email`}>
                                {/* @ts-ignore */}
                                <ByzzerPhoneInput
                                    label="Phone Number"
                                    className={`${formClassName}__input ${formClassName}__phone`}
                                    name={'phone'}
                                    value={signUpData.phone}
                                    validate={false}
                                    onChange={onInputChange}
                                    placeholder={'404-475-8166'}
                                    required
                                    variant={'secondary'}
                                />
                                {renderPhoneValidator()}
                            </div>
                            {renderCompanySelector(companySelectorType)}
                            {!isExistingAccount && renderTypeSelector(company?.type)}
                            {showIndustrySelector && (
                                // @ts-ignore
                                <LegacyByzzerSelect
                                    label="What industry are you in? (Optional)"
                                    className={`${formClassName}__input ${formClassName}__industry`}
                                    placeholder={'Select Your Industry'}
                                    onChange={onIndustrySelection}
                                    value={industry}
                                    options={filteredIndustryTypeOptions as any}
                                    variant={'secondary'}
                                />
                            )}
                            {!isExistingAccount && (
                                <div className={`${formClassName}__input-group ${formClassName}__password`}>
                                    {/* @ts-ignore */}
                                    <ByzzerInput
                                        label="Password"
                                        className={`${formClassName}__input ${formClassName}__password`}
                                        type={'password'}
                                        name={'password'}
                                        validate={false}
                                        value={signUpData.password}
                                        onChange={onInputChange}
                                        placeholder={'Password'}
                                        required
                                        variant={'secondary'}
                                    />
                                    {/* @ts-ignore */}
                                    <PasswordValidations password={signUpData.password} />
                                </div>
                            )}
                            {!isExistingAccount && (
                                <div className={`${formClassName}__input-group ${formClassName}__verify-password`}>
                                    {/* @ts-ignore */}
                                    <ByzzerInput
                                        label="Verify Password"
                                        className={`${formClassName}__input ${formClassName}__verify-password`}
                                        type={'password'}
                                        name={'verifyPassword'}
                                        validate={false}
                                        value={signUpData.verifyPassword}
                                        onChange={onInputChange}
                                        placeholder={'Password'}
                                        required
                                        variant={'secondary'}
                                    />
                                    {renderVerifyPasswordValidator()}
                                </div>
                            )}
                            {!isExistingAccount && (
                                // @ts-ignore 
                                <LegacyByzzerCheckbox
                                    className={`${formClassName}__input ${formClassName}__retailer`}
                                    label={
                                        <>
                                            <p>I’m not a retailer</p>
                                            <p className={`${formClassName}__retailer-agreement`}>
                                                By checking this box, the respondent certifies that it is not a retailer
                                                (defined as any entity offering goods for sale to the public),
                                                representative or employee of a retailer.
                                            </p>
                                        </>
                                    }
                                    name={'notRetailer'}
                                    checked={signUpData.notRetailer}
                                    onChange={onCheckboxChange}
                                />
                            )}{' '}
                            {!isExistingAccount && (
                                // @ts-ignore
                                <LegacyByzzerCheckbox
                                    className={`${formClassName}__input ${formClassName}__terms`}
                                    label={
                                        <>
                                            <p>
                                                Accept{' '}
                                                <ByzzerLink
                                                    label={'Terms & Conditions'}
                                                    onClick={openTermsAndConditions}
                                                />{' '}
                                            </p>
                                            <p className={`${formClassName}__tcs`}>
                                                By accepting the terms and conditions, you consent to receive marketing
                                                and product information from NielsenIQ.
                                            </p>
                                        </>
                                    }
                                    name={'acceptedTcs'}
                                    checked={signUpData.acceptedTcs}
                                    onChange={onCheckboxChange}
                                />
                            )}{' '}
                            {!isExistingAccount && (
                                // @ts-ignore
                                <LegacyByzzerCheckbox
                                    className={`${formClassName}__input ${formClassName}__consent`}
                                    label={
                                        <>
                                            <p className={`${formClassName}__marketing-email-consent`}>
                                                By checking this box, you consent to receiving marketing & product
                                                information emails from NielsenIQ.
                                            </p>
                                        </>
                                    }
                                    name={'marketingEmailConsent'}
                                    checked={signUpData.marketingEmailConsent}
                                    onChange={onCheckboxChange}
                                />
                            )}
                        </main>
                        {isExistingAccount && (
                            <div className={`${formClassName}__warnings`}>
                                An account already exists for this email address
                            </div>
                        )}
                        <div className={classnames(`${formClassName}__actions`, { exists: isExistingAccount })}>
                            <LegacyByzzerButton
                                disabled={busy || (!isExistingAccount && hasInvalidValues(signUpData))}
                                label={isExistingAccount ? 'Go to Sign In' : 'Create account'}
                                onClick={onSignUpClick}
                                onDisabledClick={onDisabledClick}
                                busy={busy}
                            />
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    );
}

function Validation({valid, children, className, ...props}) {

    return <div className={classnames(`validation`, {
        'validation--valid': valid
    })} {...props}>{children}</div>
}

export default SignUp;
